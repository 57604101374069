import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';

import { IRecommendedCandidate } from '@/features/job-opening';
import {
  useHistory,
  useJobOpeningId,
  usePipelineRecommendedCandidateIdOrNull,
  useWorkspaceId,
} from '@/hooks/router';

import { RecommendedCandidateBox } from '../../candidate-box';

interface RecommendationsProps {
  candidates: IRecommendedCandidate[];
}

export function Recommendations({ candidates }: RecommendationsProps) {
  const history = useHistory();
  const jobOpeningId = useJobOpeningId();
  const workspaceId = useWorkspaceId();
  const recommendedCandidateId = usePipelineRecommendedCandidateIdOrNull();

  return (
    <List>
      {candidates.map(candidate => (
        <RecommendedCandidateBox
          key={candidate.id}
          candidate={candidate}
          active={recommendedCandidateId === candidate.id}
          onClick={() => {
            amplitude?.logEvent('candidate recommended/profile opened', {
              'workspace id': workspaceId,
              'job opening id': jobOpeningId,
              'candidate recommended id': candidate.id,
            });

            history.replace(
              `/admin/${workspaceId}/openings/${jobOpeningId}/pipeline/stage/recommended/candidate/${candidate.id}`
            );
          }}
        />
      ))}
    </List>
  );
}

const List = styled.ul`
  display: flex;
  flex-flow: column;
  gap: 12px;
  list-style: none;
  margin: 2px 0 0 0;
  padding: 0;
`;
