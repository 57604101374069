import React from 'react';
import styled from '@emotion/styled';

import { MethodologyIllustration } from './methodology-illustration';
import { Section } from './section';

export function Methodology() {
  return (
    <Container>
      <div>
        <h2>Toggl’s methodology</h2>
        <h3>What this test can measure</h3>
        <p>
          Skills tests are designed to objectively measure a candidate's
          knowledge of core topics. Questions are designed to be realistic and
          relevant to daily work. We recommend using this test to ensure a
          candidate meets the threshold skill level required to succeed in the
          role.
        </p>
        <h3>How we write the questions</h3>
        <p>
          We source our test questions from multiple industry
          experts to minimize unconscious bias and promote diverse thought.
          Questions are peer-reviewed by experts and subject to regular internal
          reviews by our Library team. We monitor the pass-rate of every
          question and adjust the difficulty level to match. We also continually
          improve our question library based on candidate feedback.
        </p>
      </div>
      <div>
        <MethodologyIllustration />
      </div>
    </Container>
  );
}

const Container = styled(Section)`
  background: ${({ theme }) => theme.colors.white};
  display: none;

  @media (min-width: 769px) {
    display: flex;
  }

  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 32px;
  }

  h3 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 16px;
  }

  & > div {
    flex: 1;
    padding: 36px;

    &:last-child {
      align-items: center;
      background: ${({ theme }) => theme.colors.orange[50]};
      display: flex;
      justify-content: center;

      & > svg {
        height: 100%;
        max-height: 150px;
        max-width: 374px;
        width: 100%;
      }
    }
  }
`;
