import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { loggedIn } from '@/actions/authentication';
import { setAuthToken } from '@/api/api';
import { authenticate, signup } from '@/api/authentication';
import { storeAuthToken } from '@/authentication';
import { SignUpAttributes } from '@/types/authentication';

type SignupHook = (attributes: SignUpAttributes) => Promise<void>;

export function useSignup(): SignupHook {
  const dispatch = useDispatch();

  return useCallback(
    async (attributes: SignUpAttributes) => {
      await signup(attributes);
      const token = await authenticate({
        email: attributes.email,
        password: attributes.password,
      });

      storeAuthToken(token);
      setAuthToken(token);

      dispatch(loggedIn());
    },
    [dispatch]
  );
}
