import React from 'react';
import styled from '@emotion/styled';

import { TQuestionGroup } from '@/features/candidate';

//region Main
export interface QuestionGroupHeader {
  group: TQuestionGroup;
}

export function QuestionGroupHeader({ group }: QuestionGroupHeader) {
  if (group === 'all') return null;

  return (
    <QuestionGroupHeaderContainer>
      <Header>
        {group
          .replace('code-input', 'coding')
          .split('-')
          .join(' ')}{' '}
        questions
      </Header>
      <Description>
        {group === 'smart'
          ? 'These are dynamic questions. Every candidate gets a different set of questions, pulled randomly from our library.'
          : group === 'static'
          ? 'Static Questions are automatically evaluated. Every candidate will get the same questions.'
          : group === 'free-text'
          ? 'Free Text Questions are meant for open-ended questions,like writing and  problem-solving. These answers need manual evaluation.'
          : group === 'code-input'
          ? 'Coding Questions are meant for code input. These answers need manual evaluation.'
          : null}
      </Description>
    </QuestionGroupHeaderContainer>
  );
}

//endregion

//region Styles
const QuestionGroupHeaderContainer = styled.div`
  margin-bottom: 24px;
  color: ${props => props.theme.typography.colorPrimary};

  &:not(:first-of-type) {
    margin-top: 48px;
  }
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
`;

const Description = styled.div`
  font-size: 14px;
  margin-top: 8px;
`;
//endregion
