import React, { useMemo } from 'react';
import { DSField, DSSelect } from '@hundred5/design-system';
import { useField } from 'formik';
import { uniqueId } from 'lodash';

type TItem = {
  id: string;
  label: React.ReactNode;
  labeltext?: string;
};

type Props = {
  icon?: React.ReactNode;
  name: string;
  items: TItem[];
  label?: React.ReactNode;
  required?: boolean;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  errorAbsolute?: boolean;
  onChange?: (value?) => void;
  Footer?: React.ReactNode;
};

export function FormikSelectField(props: Props) {
  const [field, meta, form] = useField<any>(props.name);
  const error = meta.touched && !!meta.error ? meta.error : null;
  const id = useMemo(() => props.id || uniqueId('formik-field-'), [props.id]);

  return (
    <DSField
      for={id}
      label={props.label}
      required={props.required}
      error={error}
      errorAbsolute={props.errorAbsolute}
    >
      <DSSelect
        id={id}
        {...field}
        Icon={props.icon}
        placeholder={props.placeholder}
        disabled={props.disabled}
        error={!!error}
        items={props.items}
        selectedItemId={field.value}
        onChange={item => {
          // Always update the form value using form.setValue
          form.setValue(item?.id || null);

          // Handle the custom onChange, if provided
          if (props.onChange) {
            props.onChange(item?.id);
          }
        }}
        Footer={props.Footer}
      />
    </DSField>
  );
}
