import React, { useCallback } from 'react';

import { TId, ConfirmModal } from '@/features/common';

interface DeleteStageModalProps {
  categoryId?: TId;
  onClose: () => void;
  onDelete: (categoryId: TId) => void;
  open: boolean;
}

export function DeleteStageModal({
  categoryId,
  onClose,
  onDelete,
  open,
}: DeleteStageModalProps) {
  const handleConfirm = useCallback(() => {
    if (!categoryId) {
      return;
    }

    onDelete(categoryId);
    onClose();
  }, [categoryId, onDelete, onClose]);

  return (
    <ConfirmModal
      open={open}
      action="Delete"
      onConfirm={handleConfirm}
      onClose={onClose}
    >
      <h3>Are you sure?</h3>
      <p>
        This pipeline stage is going to be deleted permanently with no backups.{' '}
        <br />
        Therefore be sure you really wish to do that.
      </p>
    </ConfirmModal>
  );
}
