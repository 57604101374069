import styled from '@emotion/styled';

export const WorkspaceSettingsIntegrationTable = styled.table<{
  disabled?: boolean;
}>`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  min-width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  thead {
    font-weight: 500;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.purple[60]};
    text-transform: uppercase;
    user-select: none;
    height: 48px;

    th {
      white-space: nowrap;
      padding: 0 16px;
    }
  }

  tbody {
    color: ${({ theme }) => theme.colors.purple[100]};

    td {
      padding: 0 16px;
    }

    tr:hover {
      background: ${({ theme }) => theme.colors.purple[5]};

      .tag {
        background: ${({ theme }) => theme.colors.white};
      }
    }
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.purple[10]};

    &:last-of-type {
      border-bottom: none;
    }
  }

  tr,
  th,
  td {
    height: 48px;
    text-align: left;
  }

  td:nth-of-type(2) {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  th:first-of-type,
  td:first-of-type {
    width: 320px;
    padding: 0 16px;

    & input {
      vertical-align: middle;
    }
  }

  th:nth-of-type(2) {
    width: 420px;
    white-space: nowrap;
  }

  th:nth-of-type(3),
  th:last-of-type {
    width: 56px;
    white-space: nowrap;
  }

  th:nth-of-type(4) {
    width: 144px;
    white-space: nowrap;
  }
`;
