import React, { useState } from 'react';
import { DSButton } from '@hundred5/design-system';

import { amplitude } from '@/features/amplitude';
import { BulkActionModal } from '@/features/bulk-actions/components/ui/bulk-action-modal';
import { useBulkAddTags } from '@/features/bulk-actions/queries';
import { BulkActionModalProps } from '@/features/bulk-actions/types';
import { TId, callAll } from '@/features/common';
import { TagsSelector } from '@/features/common/components/tags-selector';
import { useJobOpeningIdOrNull } from '@/hooks/router';

export const BulkAddTagsModal = ({
  open,
  onClose,
  onActionDone,
  candidates,
}: BulkActionModalProps) => {
  const [selectedTagIds, setSelectedTagIds] = useState<TId[]>([]);
  const bulkAddTagsMutation = useBulkAddTags({
    candidateIds: candidates.map(candidate => candidate.id),
    tagsIds: selectedTagIds,
    onSuccess: onActionDone,
  });
  const jobOpeningId = useJobOpeningIdOrNull();

  return (
    <BulkActionModal open={open} onClose={onClose}>
      <BulkActionModal.Header>
        <BulkActionModal.Title>Add tags</BulkActionModal.Title>
        <BulkActionModal.CloseButton onClick={onClose} fixed small />
      </BulkActionModal.Header>
      <BulkActionModal.Content>
        <TagsSelector
          selectedTagIds={selectedTagIds}
          onChange={setSelectedTagIds}
        />
      </BulkActionModal.Content>
      <BulkActionModal.Footer>
        <DSButton
          onClick={() => {
            callAll(bulkAddTagsMutation.mutate, onClose);
            amplitude?.logEvent('bulk actions/bulk action applied', {
              'user ids': candidates,
              'job opening id': jobOpeningId,
              'action type': 'add_tags',
              'action origin': jobOpeningId ? 'pipeline' : 'all candidates',
              'action value': selectedTagIds,
            });
          }}
          disabled={!selectedTagIds.length}
          loading={bulkAddTagsMutation.isLoading}
        >
          Add tags
        </DSButton>
      </BulkActionModal.Footer>
    </BulkActionModal>
  );
};
