import React, { ReactElement, ReactText } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { getShareTestUpsellTooltipAttributes } from '@/components/upsell_tooltip/utils';
import { copyText } from '@/components/utils/copy-text';
import { Icon } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useJobOpeningByIdQuery } from '@/features/job-opening';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';

import { useShareModal } from '../hooks';

interface IProps {
  context?: string;
  showUpsell?: boolean;
  color?: 'primary' | 'secondary';
  children?: ReactElement | ReactText;
  className?: string;
}

const JobOpeningShareLink = (props: IProps) => {
  const { jobOpeningIdSelected, categorySelected } = useShareModal();

  let url = '';
  const { showFlashMessage } = useFlashMessages();

  const { data: jobOpeningData } = useJobOpeningByIdQuery({
    jobOpeningId: jobOpeningIdSelected ?? '',
  });

  url = jobOpeningData
    ? getJobOpeningUrlForCandidate({
        jobOpeningSlug: jobOpeningData.slug,
        hashedTestId: categorySelected?.test?.hashedTestId,
      })
    : '';

  const logClickEvent = () => {
    if (props.context) {
      amplitude?.logEvent('sourcing/click job opening link', {
        context: props.context,
      });
    }
  };

  return (
    <Wrapper
      {...(!(
        !!jobOpeningIdSelected &&
        !!categorySelected &&
        !!categorySelected.test
      ) && {
        'data-rh': 'There’s no test added to the selected pipeline stage.',
        'data-rh-at': 'top',
      })}
      {...(props.showUpsell &&
        getShareTestUpsellTooltipAttributes(categorySelected?.test!, 'top'))}
    >
      <DSButton
        className={props.className}
        variant="secondary"
        Icon={<Icon icon={regular('link-simple')} color="purple-60" />}
        onClick={() => {
          copyText(url);
          logClickEvent();
          showFlashMessage({
            type: 'link_copied',
          });
        }}
        disabled={
          props.showUpsell ||
          !(
            !!jobOpeningIdSelected &&
            !!categorySelected &&
            !!categorySelected.test
          )
        }
      >
        {props.children}
      </DSButton>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default JobOpeningShareLink;
