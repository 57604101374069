import React from 'react';
import styled from '@emotion/styled';

import { ICandidateAnswerAttachment } from '@/features/candidate/types';

interface AnswerAttachmentsProps {
  attachments?: ICandidateAnswerAttachment[];
}

export function AnswerAttachments({ attachments }: AnswerAttachmentsProps) {
  return (
    <Attachments>
      {attachments?.map(attachment => (
        <li key={attachment.id}>
          <a href={attachment.url} target="_blank" rel="noreferrer noopener">
            {attachment.fileName}
          </a>
        </li>
      ))}
    </Attachments>
  );
}

const Attachments = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;
