import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import { useUpdateJobOpeningMutation } from '@/features/job-opening';
import { useJobOpeningPermission } from '@/features/permissions';
import { useHistory } from '@/hooks/router';
import { queryClient } from '@/utils/reactQuery';

interface Props {
  id: string;
  jobOpeningName: string;
}

export const JobOpeningName = ({ id, jobOpeningName }: Props) => {
  const history = useHistory();
  const disableEditing = !!history.location?.state?.disableEditing;
  const canUpdate = useJobOpeningPermission()('job opening', 'update');

  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState(jobOpeningName);
  const [editMode, setEditMode] = useState(
    disableEditing && jobOpeningName === 'New custom test'
  );
  const [invalid, setInvalid] = useState(false);

  const jobOpeningMutation = useUpdateJobOpeningMutation({
    jobOpeningId: id,
    onSuccess: () =>
      queryClient.invalidateQueries(DASHBOARD_JOB_OPENINGS_QUERY_KEY),
  });

  useEffect(() => {
    if (disableEditing) {
      inputRef.current?.select();
    }
  }, [disableEditing]);

  useEffect(() => {
    if (jobOpeningName) {
      setName(jobOpeningName);
    }
  }, [jobOpeningName]);

  function checkErrors(wasSubmitted?: boolean) {
    if (name === '') {
      if (wasSubmitted === true) {
        inputRef.current?.focus();
      }
      setInvalid(true);
      return true;
    }
    return false;
  }

  async function handleSave() {
    if (checkErrors(true)) {
      return;
    }
    setEditMode(false);

    if (disableEditing) {
      history.replace(history.location.pathname, {
        ...history.location.state,
        disableEditing: false,
      });
    }

    await jobOpeningMutation.mutateAsync({
      jobOpeningId: id,
      settings: { name },
    });
  }

  return editMode ? (
    <>
      <Input
        ref={inputRef}
        type="text"
        invalid={invalid}
        placeholder="Name your test"
        aria-label="Test name"
        aria-invalid={invalid ? 'true' : undefined}
        value={name}
        onChange={e => {
          setInvalid(false);
          setName(e.currentTarget.value);
        }}
        onKeyDown={e => e.key === 'Enter' && handleSave()}
        onBlur={() => handleSave()}
        autoFocus
      />
    </>
  ) : (
    <Container>
      <Name> {name}</Name>
      {canUpdate && (
        <DSButton
          variant="tertiary"
          size="xsmall"
          aria-label="edit"
          onClick={() => setEditMode(true)}
        >
          <Icon icon={regular('pen-to-square')} color="purple-60" />
        </DSButton>
      )}
    </Container>
  );
};

const Input = styled.input<{ invalid?: boolean }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  border: none;
  width: 269px;
  border-radius: 8px;
  padding: 6px 8px;
  outline: none;
  background-color: ${props => props.theme.colors.purple[5]};

  ${props =>
    props.invalid &&
    css`
      outline: 2px solid #f87171;
      color: #dc2626;
    `}
`;

const Container = styled.div`
  display: flex;
  gap: 10px;
`;

const Name = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
`;
