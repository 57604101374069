import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ArrowIconWrapper } from './job-opening-analytics-sort-arrow';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  height: 40px;

  &:last-child td {
    border-bottom: none;
  }
`;

export const TableHeader = styled.th<{ align?: 'left' | 'right' }>`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  color: ${props => props.theme.colors.purple[60]};
  width: 130px;
  padding-left: ${props => (props.align === 'right' ? 0 : '16px')};
  padding-right: ${props => (props.align === 'right' ? '16px' : 0)};
  cursor: pointer;
  text-align: ${props => props.align ?? 'left'};
  user-select: none;
  position: relative;

  ${props =>
    props.align === 'right' &&
    css`
      ${ArrowIconWrapper} {
        position: absolute;
        top: -1px;
        right: 2px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    `}
`;

export const TableCell = styled.td<{ hasEmptyValue?: boolean }>`
  text-align: right;
  font-size: 14px;
  padding: 0 16px;
  border: 1px solid ${props => props.theme.colors.purple[20]};
  color: ${props => props.theme.colors.github};
  opacity: ${props => (props.hasEmptyValue ? 0.25 : 1)};

  &:last-child {
    border-right: none;
  }
`;
