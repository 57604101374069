import configuration from '@/configuration/runtime';

export const togglAccountsUrl =
  configuration.togglAccountsUrl || 'https://accounts.toggl.space';

export const getTogglAccountsLoginUrl = (returnTo?: string): string => {
  const loginUrl = new URL('/hire/login', togglAccountsUrl);
  if (returnTo) {
    loginUrl.searchParams.set('returnTo', returnTo);
  }

  return loginUrl.toString();
};
