import * as amplitude from '@amplitude/analytics-browser';

export function logCTAClick(templateName: string, source: 'header' | 'footer') {
  amplitude?.logEvent(`test overview/${source} CTA click`, {
    'template name': templateName,
  });
}

export function logArrowNavigation() {
  amplitude?.logEvent(`test overview/sample question arrow click`);
}
