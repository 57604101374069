import api from '@/api/api';
import { TId } from '@/features/common';

export async function bulkAddTags(
  candidateIds: TId[],
  tagsIds: TId[]
): Promise<void> {
  let ids: number[] = candidateIds.map(id => Number(id));
  await api.patch(
    `/test-takers/bulk`,
    {
      ids: ids,
      action: 'add_tags',
      test_taker_data: {
        tags_id: tagsIds.map(Number),
      },
    },
    { captchaAction: 'bulk_tags' }
  );
}
