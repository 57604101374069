import React from 'react';
import styled from '@emotion/styled';
import { DSCheckbox } from '@hundred5/design-system';

export function Checkbox({ selected, onSelect, blured = false, ...rest }) {
  return (
    <Container blured={blured}>
      <StyledCheckbox
        checked={selected}
        onChange={onSelect}
        onClick={event => event.stopPropagation()}
        {...rest}
      />
    </Container>
  );
}

const Container = styled.div<{ blured?: boolean }>`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  visibility: ${({ blured }) => (blured ? 'hidden' : 'visible')};
`;

export const StyledCheckbox = styled(DSCheckbox)`
  width: 12px;
  height: 12px;
  border-color: ${({ theme }) => theme.colors.purple[10]};

  &:checked {
    background-size: contain;
  }
`;
