import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { copyText } from '@/components/utils/copy-text';
import { Icon } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { IJobOpening } from '@/features/job-opening';
import { getJobOpeningPreviewUrl } from '@/utils/urls';

export function JobOpeningReviewLinkButton({
  jobOpening,
}: {
  jobOpening: IJobOpening;
}) {
  const { showFlashMessage } = useFlashMessages();

  return (
    <DSButton
      variant="secondary"
      Icon={<Icon icon={regular('link-simple')} color="purple-60" />}
      onClick={() => {
        copyText(jobOpening ? getJobOpeningPreviewUrl(jobOpening) : '');
        showFlashMessage({
          type: 'link_copied',
        });
      }}
    >
      Copy preview link
    </DSButton>
  );
}
