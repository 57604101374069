import { decamelizeKeys } from 'humps';
import qs from 'qs';

import { ICandidateSearchParams } from '../types';

export const stringifyParams = (
  searchParams: Partial<ICandidateSearchParams>
) =>
  qs.stringify(decamelizeKeys(searchParams), {
    encode: false,
    arrayFormat: 'comma',
  });
