import React from 'react';
import styled from '@emotion/styled';

interface SocialNetworkProps {
  hasLinkedin: string;
  hasGithub: string;
}

export const SocialNetwork = ({
  hasLinkedin,
  hasGithub,
}: SocialNetworkProps) => {
  return (
    <SocialNetworkContainer>
      <IconWrapper> {hasLinkedin && Linkedin}</IconWrapper>
      <IconWrapper> {hasGithub && Github}</IconWrapper>
    </SocialNetworkContainer>
  );
};

const SocialNetworkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const IconWrapper = styled.div`
  border-radius: 8px;
  opacity: 0.4;
`;

const Linkedin = (
  <svg width="24" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.703 21.513V9.903h3.61v11.61h-3.61Zm3.844-15.19c0 1.104-.781 2.008-2.047 2.008h-.016c-1.218 0-2-.904-2-2.008 0-1.137.813-2.007 2.047-2.007 1.235 0 2 .87 2.016 2.007Zm9.14 15.19v-6.207c0-1.572-.515-2.626-1.812-2.626-1 0-1.594.72-1.86 1.405-.093.251-.109.603-.109.937v6.49h-3.61c.048-10.521 0-11.609 0-11.609h3.61v1.69h-.031c.469-.803 1.328-1.957 3.266-1.957 2.375 0 4.156 1.656 4.156 5.219v6.658h-3.61ZM24 4.818C24 2.158 21.984 0 19.5 0h-15C2.016 0 0 2.158 0 4.818v16.06c0 2.659 2.016 4.817 4.5 4.817h15c2.484 0 4.5-2.158 4.5-4.818V4.817Z"
      fill="#2C1338"
    />
  </svg>
);

const Github = (
  <svg width="24" height="26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.11 17.933c-.047.05-.141.033-.22-.034-.062-.067-.077-.167-.046-.217.047-.05.14-.034.218.033.063.067.079.168.047.218Zm-.438-.686c.047.084.047.184 0 .218-.047.033-.125 0-.188-.084-.046-.067-.046-.168 0-.2.063-.034.141 0 .188.066Zm-.64-.67c-.016.051-.079.051-.141.034-.063-.033-.094-.083-.063-.134.016-.05.078-.05.125-.033.063.034.094.1.078.134Zm.327.386c-.046.05-.125.016-.171-.034-.047-.067-.063-.134-.016-.184.031-.033.11-.017.156.05.063.05.063.134.031.168Zm1.344 1.254c-.031.067-.11.1-.203.067-.094-.033-.156-.117-.14-.184.03-.067.109-.1.203-.067.093.034.156.117.14.184Zm.656.05c0 .067-.078.134-.171.134-.094 0-.172-.067-.172-.134 0-.066.062-.133.156-.133.11 0 .187.067.187.133Zm.61-.117c0 .067-.063.15-.156.168-.094.016-.172-.034-.188-.1-.016-.068.047-.135.14-.151.094-.017.188.016.204.083ZM20 12.847c0 3.781-2.297 6.993-5.469 8.13-.406.084-.547-.184-.547-.418 0-.284.016-1.204.016-2.342 0-.803-.266-1.321-.547-1.589 1.781-.217 3.656-.937 3.656-4.232 0-.937-.312-1.69-.828-2.292.078-.217.36-1.087-.078-2.275-.672-.218-2.203.887-2.203.887a6.663 6.663 0 0 0-2-.285c-.688 0-1.36.084-2 .285 0 0-1.531-1.105-2.203-.887-.438 1.188-.156 2.058-.078 2.275-.516.602-.828 1.355-.828 2.292 0 3.278 1.875 4.015 3.656 4.232-.235.218-.438.602-.516 1.154-.453.218-1.625.602-2.328-.719-.437-.82-1.219-.887-1.219-.887-.78 0-.062.536-.062.536.531.25.89 1.238.89 1.238.47 1.539 2.688 1.02 2.688 1.02.016.72.016 1.389.016 1.59 0 .233-.141.501-.547.417C6.297 19.84 4 16.628 4 12.847c0-4.734 3.578-8.565 8-8.565 4.422 0 8 3.831 8 8.565Zm4-8.03C24 2.159 21.984 0 19.5 0h-15C2.016 0 0 2.158 0 4.818v16.06c0 2.659 2.016 4.817 4.5 4.817h15c2.484 0 4.5-2.158 4.5-4.818V4.817Z"
      fill="#2C1338"
    />
  </svg>
);
