import React, { useState } from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';

import { TableColumn, TableRow, ConfirmModal } from '@/features/common';
import {
  useDeleteWorkspaceInvitationMutation,
  useWorkspaceInvitationsQuery,
} from '@/features/workspace/queries/workspace-invitations';
import { TWorkspaceInvitation } from '@/features/workspace/types';
import { useWorkspaceId } from '@/hooks/router';

import { Invitation } from './';

//region Main
export const InvitationList = () => {
  const workspaceId = useWorkspaceId();
  const [deleting, setDeleting] = useState(false);
  const [deletingInvitation, setDeletingInvitation] = useState<
    TWorkspaceInvitation | undefined
  >();

  const workspaceInvitationsQuery = useWorkspaceInvitationsQuery();
  const deleteWorkspaceInvitationMutation = useDeleteWorkspaceInvitationMutation();

  const invitations = workspaceInvitationsQuery.data || [];

  if (isEmpty(invitations)) {
    return null;
  }

  const onDeleteInvitation = (invitation: TWorkspaceInvitation) => {
    setDeletingInvitation(invitation);
    setDeleting(true);
  };

  const confirmDeleteInvitation = () => {
    deleteWorkspaceInvitationMutation.mutateAsync({
      workspaceId,
      invitationId: deletingInvitation!.id,
    });
    closeDeleteInvitation();
  };

  const closeDeleteInvitation = () => {
    setDeletingInvitation(undefined);
    setDeleting(false);
  };

  return (
    <>
      <InvitationListContainer>
        <Title>Pending invites</Title>
        <TableRow header>
          <TableColumn width="33%">Email</TableColumn>
          <TableColumn width="33%">Role</TableColumn>
          <TableColumn width="16%">Date</TableColumn>
          <TableColumn width="10%"></TableColumn>
          <TableColumn width="6%"></TableColumn>
        </TableRow>
        {invitations.map(invitation => (
          <Invitation
            key={invitation.id}
            invitation={invitation}
            onDelete={onDeleteInvitation}
          />
        ))}
      </InvitationListContainer>

      <ConfirmModal
        open={deleting}
        action="Delete"
        confirmation="delete"
        onConfirm={confirmDeleteInvitation}
        onClose={closeDeleteInvitation}
      >
        <h3>Are you sure?</h3>
        <p>Invitation sent to "{deletingInvitation?.email}" will be deleted.</p>
      </ConfirmModal>
    </>
  );
};
//endregion

//region Styles
const InvitationListContainer = styled.article`
  color: ${props => props.theme.colors.purple[100]};
  padding: 0 36px 36px 36px;
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 10px;
`;
//endregion
