import { WorkspaceUser } from '@/features/workspace';

export function getCurrentMentions(
  noteContent: string,
  mentionedUsers: WorkspaceUser[]
) {
  return mentionedUsers
    .filter(user => noteContent.includes(`@${user.name}`))
    .map(user => user.id);
}
