import React, { useState } from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  CandidateContactInfoEditModal,
  getCandidateInitials,
  isCandidateAnonymizedRecommendation,
  useCandidateQuery,
} from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { useUpsellModal } from '@/hooks/upsellModal'; //region Main

//region Main
export interface AvatarProps {
  candidateId: TId;
}

export const Avatar = ({ candidateId }: AvatarProps) => {
  const { data: candidate, isLoading, isFetching } = useCandidateQuery({
    candidateId,
  });
  const { openUpsellModal } = useUpsellModal();
  const [isEditing, setIsEditing] = useState(false);
  const initials = getCandidateInitials(candidate);

  if (!candidate) {
    return null;
  }

  const isCandidateAnonymized = isCandidateAnonymizedRecommendation(candidate);

  return (
    <>
      <AvatarContainer
        disabled={isCandidateAnonymized}
        onClick={() => {
          if (isCandidateAnonymized) {
            return;
          }
          candidate?.isUnlocked
            ? setIsEditing(true)
            : openUpsellModal('premium_feature');
        }}
        data-rh={
          isCandidateAnonymized
            ? 'All actions will be unlocked after the candidate takes the test.'
            : 'Edit details'
        }
      >
        {isLoading || isFetching ? (
          <Icon icon={solid('sync')} color="white" spin />
        ) : candidate?.isUnlocked ? (
          initials
        ) : (
          <Icon icon={solid('lock')} color="white" />
        )}
      </AvatarContainer>

      <CandidateContactInfoEditModal
        candidateId={candidateId}
        open={isEditing}
        onSettled={() => setIsEditing(false)}
      />
    </>
  );
};
//endregion

//region Styles
const AvatarContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 86px;
  width: 86px;
  border-radius: 10px;
  font-size: 28px;
  color: #ffffff;
  text-transform: uppercase;
  background: #2c1338;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  transition: opacity 200ms;
  margin: 0 12px 3px 0;

  :hover {
    opacity: ${({ disabled }) => (disabled ? 1 : 0.7)};
  }
`;
//endregion
