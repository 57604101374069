import { camelizeKeys, decamelizeKeys } from 'humps';
import { isBoolean, keyBy, mapValues } from 'lodash';
import { normalize } from 'normalizr';

import configuration from '@/configuration/runtime';
import { BillingPlan } from '@/features/billing';
import { Id } from '@/types/misc';
import { Role } from '@/types/role';
import { User } from '@/types/user';
import {
  Workspace,
  WorkspaceAttributes,
  WorkspaceData,
  WorkspaceUser,
} from '@/types/workspace';
import { getCompositeKey } from '@/utils/keys';

import api from './api';
import { workspaceUser as workspaceUserSchema } from './schema';
import { ApiUser, parseUser } from './users';

export type ApiWorkspace = {
  id: number;
  name: string;
  logoUrl: string | null;
  createdAt: string;
  askInterestedInWorking: boolean;
  isSimplified: boolean;
  subscriptionPlan: BillingPlan;
  subscriptionPausedFrom: string;
  subscriptionPausedUntil: string;
  gmailEnabled: boolean;
};

export type ApiWorkspaceUser = {
  user: number;
  role: Role;
};

export const parseWorkspace = (
  {
    id,
    name,
    logoUrl,
    createdAt,
    askInterestedInWorking,
    isSimplified,
    subscriptionPlan,
    subscriptionPausedFrom,
    subscriptionPausedUntil,
    gmailEnabled,
  }: ApiWorkspace,
  workspace?: Workspace
): Workspace => ({
  id: String(id),
  name,
  logoUrl: logoUrl || null,
  createdAt: new Date(createdAt),
  preferences: {
    askCandidatesIfInterested: askInterestedInWorking,
  },
  simplified: isBoolean(isSimplified)
    ? isSimplified
    : workspace
    ? workspace.simplified
    : true,
  subscriptionPlan,
  subscriptionPausedFrom: subscriptionPausedFrom
    ? new Date(subscriptionPausedFrom)
    : null,
  subscriptionPausedUntil: subscriptionPausedUntil
    ? new Date(subscriptionPausedUntil)
    : null,
  gmailEnabled: gmailEnabled ? gmailEnabled : false,
});

const serializeData = (data: WorkspaceData) => ({
  phone_number: data.phoneNumber,
  source: data.source,
  type_of_candidates: data.typeOfCandidates,
});

const serializeWorkspace = (
  attributes: WorkspaceAttributes,
  workspace?: Workspace
) => ({
  name: attributes.name || (workspace && workspace.name),
  logoUrl: attributes.logoUrl || (workspace && workspace.logoUrl) || null,
  askInterestedInWorking:
    attributes.askCandidatesIfInterested != null
      ? attributes.askCandidatesIfInterested
      : workspace && workspace.preferences.askCandidatesIfInterested,
  data: attributes.data != null ? serializeData(attributes.data) : undefined,
});

export const parseWorkspaceUser = (
  { role, user }: ApiWorkspaceUser,
  workspaceId: Id
): WorkspaceUser => ({
  role,
  user: String(user),
  workspace: workspaceId,
});

export const parseWorkspaceUsers = (
  id: Id,
  usersData: WorkspaceUser[]
): {
  workspaceUsers: { [id: string]: WorkspaceUser };
  users: { [id: string]: User };
} => {
  const data = (camelizeKeys(usersData) as any).map(item => {
    const {
      id,
      email,
      name,
      role,
      profilePictureUrl,
      workspaceUserCreatedAt,
    } = item;
    return {
      role,
      user: {
        id,
        email,
        name,
        profilePictureUrl,
        createdAt: workspaceUserCreatedAt,
      },
    };
  });

  const {
    entities: { users, workspaceUsers },
  }: {
    entities: {
      users: { [id: string]: ApiUser };
      workspaceUsers: { [id: string]: ApiWorkspaceUser };
    };
  } = normalize(data, [workspaceUserSchema]);

  return {
    users: mapValues(users, parseUser),
    workspaceUsers: keyBy(
      mapValues(workspaceUsers, (workspaceUser: ApiWorkspaceUser) =>
        parseWorkspaceUser(workspaceUser, id)
      ),
      (workspaceUser: WorkspaceUser) =>
        getCompositeKey(workspaceUser.workspace, workspaceUser.user)
    ) as any,
  };
};

export async function createWorkspace(
  attributes: WorkspaceAttributes = {}
): Promise<Workspace> {
  const response = await api.post(
    `workspaces`,
    decamelizeKeys(serializeWorkspace(attributes))
  );

  const data = camelizeKeys(response.data) as ApiWorkspace;
  return parseWorkspace(data);
}

export async function fetchWorkspaceUsers(
  id: Id
): Promise<{
  workspaceUsers: { [id: string]: WorkspaceUser };
  users: { [id: string]: User };
}> {
  const response = await api.get(`workspaces/${id}/users`);

  return parseWorkspaceUsers(id, response.data.users);
}

export async function updateWorkspaceUser(
  workspaceId: Id,
  userId: Id,
  params: any
): Promise<void> {
  await api.put(`workspaces/${workspaceId}/users/${userId}`, params);
}

export async function deleteWorkspaceUser(
  workspaceId: Id,
  userId: Id
): Promise<void> {
  await api.delete(`workspaces/${workspaceId}/users/${userId}`);
}

export async function deleteWorkspace(workspaceId: Id): Promise<Id> {
  await api.delete(`/workspaces/${workspaceId}`);
  return workspaceId;
}

export async function exportWorkspace(workspaceId: Id): Promise<string> {
  const response = await api.post(`/workspaces/${workspaceId}/takeout`);
  const token = response.data.token;

  return `${configuration.apiUrl}/api/v1/workspaces/${workspaceId}/takeout?token=${token}`;
}

export async function fetchWorkspaceData(
  workspaceId: Id
): Promise<WorkspaceData> {
  const response = await api.get(`/workspaces/${workspaceId}/data`);
  return camelizeKeys(response.data);
}

export async function updateWorkspaceData(
  workspaceId: Id,
  data: WorkspaceData
): Promise<void> {
  await api.put(`/workspaces/${workspaceId}/data`, decamelizeKeys(data));
}
