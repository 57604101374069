import React from 'react';
import styled from '@emotion/styled';

interface NameProps {
  children: React.ReactNode;
  unseenCandidate?: boolean;
  blurred?: boolean;
}

export function Name({
  children,
  unseenCandidate = false,
  blurred,
}: NameProps) {
  return (
    <Container blurred={blurred}>
      {unseenCandidate ? <UnseenCandidate /> : null}
      <Heading>{children}</Heading>
    </Container>
  );
}

const Container = styled.div<{ blurred?: boolean }>`
  align-items: center;
  display: flex;
  gap: 4px;
  min-width: 0;
  filter: ${({ blurred }) => (blurred ? 'blur(3px)' : 'none')};
  grid-area: name;
`;

export const Heading = styled.h3`
  color: ${({ theme }) => theme.colors.purple[100]};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UnseenCandidate = styled.span`
  background: ${({ theme }) => theme.colors.orange[100]};
  border-radius: 50%;
  display: inline-flex;
  flex-shrink: 0;
  height: 6px;
  width: 6px;
`;
