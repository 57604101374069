import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import {
  BulkAddTags,
  BulkDownloadPDF,
  BulkInviteToNewJob,
  BulkMoveTo,
  BulkReject,
  BulkSendEmail,
} from '@/features/bulk-actions/components/ui';
import {
  BulkActionProps,
  TBulkActionCandidate,
} from '@/features/bulk-actions/types';
import { CustomContextMenu, TId } from '@/features/common';
import { SelectedCandidatesCount } from '@/features/common/components/selected-candidates-count';
import { useJobOpeningPermission } from '@/features/permissions';
import { IWorkspaceCandidate } from '@/features/workspace';
import { usePlanLimits } from '@/hooks/planLimits';
import { useHistory, useWorkspaceId } from '@/hooks/router';

type TBulkAction =
  | 'download_pdf'
  | 'add_tags'
  | 'invite_to_new_job'
  | 'send_email'
  | 'reject'
  | 'move_to';

const BulkActionsComponents: {
  [key in TBulkAction]: (props: BulkActionProps) => JSX.Element;
} = {
  download_pdf: BulkDownloadPDF,
  add_tags: BulkAddTags,
  invite_to_new_job: BulkInviteToNewJob,
  send_email: BulkSendEmail,
  reject: BulkReject,
  move_to: BulkMoveTo,
};

interface BulkActionsProps {
  selectedCandidates: TBulkActionCandidate[];
  showCandidateCount?: boolean;
  onCloseModal?: () => void;
  onActionDone?: () => void;
  actions: TBulkAction[];
  originCategoryId?: TId;
  testId?: TId;
}

const restrictedAccessTooltip =
  'Please contact workspace admin to get access to this feature';

export const BulkActions = ({
  selectedCandidates,
  onCloseModal,
  onActionDone,
  showCandidateCount = false,
  actions,
  originCategoryId,
  testId,
}: BulkActionsProps) => {
  const canAccessFeature = usePlanLimits();
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const [isUpsellOpen, setIsUpsellOpen] = useState(false);

  const userHasAccessToBulkActions = canAccessFeature('bulk_actions') || false;

  const changeUpsellVisibility = useCallback(
    (visible: boolean) => {
      if (!userHasAccessToBulkActions) {
        setIsUpsellOpen(visible);
      }
    },
    [userHasAccessToBulkActions]
  );

  const getJobOpeningPermission = useJobOpeningPermission();

  const canEditCandidates = useMemo(
    () =>
      selectedCandidates.every(candidate => {
        const workspaceCandidate = candidate as IWorkspaceCandidate;
        return getJobOpeningPermission(
          'candidate',
          'update',
          workspaceCandidate.jobOpenings && {
            id: workspaceCandidate?.jobOpenings[0].id,
            visibility: workspaceCandidate.jobOpenings[0].visibility,
          }
        );
      }),
    [getJobOpeningPermission, selectedCandidates]
  );

  const props = React.useMemo(
    () => ({
      disabled: !userHasAccessToBulkActions && canEditCandidates,
      selectedCandidates: selectedCandidates,
      onActionDone,
      onCloseModal,
      tooltip: !canEditCandidates ? restrictedAccessTooltip : undefined,
      originCategoryId,
      testId,
    }),
    [
      userHasAccessToBulkActions,
      selectedCandidates,
      onActionDone,
      onCloseModal,
      canEditCandidates,
      originCategoryId,
      testId,
    ]
  );

  return (
    <BulkActionsContainer
      onMouseEnter={() => changeUpsellVisibility(true)}
      onMouseLeave={() => changeUpsellVisibility(false)}
    >
      {showCandidateCount ? (
        <SelectedCandidatesCount width="150px">
          {`${selectedCandidates.length} selected`}
        </SelectedCandidatesCount>
      ) : null}

      <ActionsButtonsWrapper>
        {actions.map(action => {
          const Component = BulkActionsComponents[action];
          return <Component key={action} {...props} />;
        })}
        <ContextMenuWrapper>
          <ContextMenu open={isUpsellOpen}>
            <Content>
              <Paragraph>
                Upgrade to effortlessly perform bulk actions like
                <br />
                rejecting or archiving candidates with one click.
              </Paragraph>
              <UpgradeButton
                onClick={() =>
                  history.push(`/admin/${workspaceId}/settings/billing`)
                }
              >
                Upgrade to Business
              </UpgradeButton>
            </Content>
          </ContextMenu>
        </ContextMenuWrapper>
      </ActionsButtonsWrapper>
    </BulkActionsContainer>
  );
};

const BulkActionsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 12px 0 8px;
  height: 100%;
  width: 100%;
`;

const ActionsButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ContextMenuWrapper = styled.div`
  position: absolute;
  padding-top: 10px;
  bottom: -16px;
  left: 0;
  transform: translate(0%, 90%);
  z-index: 100;
`;

const UpgradeButton = styled(DSButton)`
  width: 100%;
`;

const Content = styled.div`
  padding: 16px;
  width: 310px;
`;

const Paragraph = styled.p`
  margin: 0 0 16px;
  font-size: 12px;
`;

const ContextMenu = styled(CustomContextMenu)`
  &::after {
    left: 18px;
  }
`;
