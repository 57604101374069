import * as React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import classNames from 'classnames';
import { noop } from 'lodash';
import ReactModal from 'react-modal';

import { Icon } from '@/features/common';

import './Modal.css';

const getZIndex = (layer: number | undefined): number =>
  layer != null ? layer + 2000 : 2000;

const Modal = ({
  open,
  fixed = false,
  fluid = false,
  layer,
  contentStyle,
  children,
  onClose = noop,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  extraLarge = false,
}: {
  open: boolean;
  fixed?: boolean;
  fluid?: boolean;
  layer?: number;
  contentStyle?: {};
  children: React.ReactNode;
  onClose?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  extraLarge?: boolean;
}) => {
  return (
    <ReactModal
      isOpen={open}
      className={{
        base: classNames('beta-modal', {
          'beta-modal--fixed': fixed,
          'beta-modal--fluid': fluid,
          'beta-modal--extra-large': extraLarge,
        }),
        afterOpen: 'beta-modal--after-open',
        beforeClose: 'beta-modal--before-close',
      }}
      overlayClassName={{
        base: 'beta-modal-overlay',
        afterOpen: 'beta-modal-overlay--after-open',
        beforeClose: 'beta-modal-overlay--before-close',
      }}
      portalClassName="beta-modal-portal"
      bodyOpenClassName="beta-modal-open"
      closeTimeoutMS={200}
      onRequestClose={() => {
        onClose();
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      style={{
        overlay: { zIndex: getZIndex(layer) },
      }}
    >
      <div className={'beta-modal__content'} style={contentStyle}>
        {children}
      </div>
    </ReactModal>
  );
};

Modal.CloseButton = ({
  fixed = false,
  onClick = noop,
  color = '#BDBDBD',
  padding = '10px',
}: {
  fixed?: boolean;
  onClick?: () => void;
  color?: string;
  padding?: string;
}) => (
  <DSButton
    variant="tertiary"
    size="xsmall"
    aria-label="save"
    onClick={onClick}
    className={classNames('beta-modal-close-button', {
      'beta-modal-close-button--fixed': fixed,
    })}
  >
    <Icon icon={regular('xmark')} color={color} size="lg" />
  </DSButton>
);

Modal.TabNavigation = ({ children }: { children: React.ReactNode }) => (
  <div className="beta-modal-tab-navigation">{children}</div>
);

Modal.Section = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => (
  <div className="beta-modal-section">
    <div className="beta-modal-section__title">{title}</div>
    <div className="beta-modal-section__content">{children}</div>
  </div>
);

Modal.Separator = () => <div className="beta-modal-separator" />;

Modal.EdgeContent = ({ children }: { children: React.ReactNode }) => (
  <div className="beta-modal-edge-content">{children}</div>
);

Modal.Footer = ({
  children,
  float,
}: {
  children: React.ReactNode;
  float?: 'right' | 'left';
}) => (
  <div
    className={classNames(
      'beta-modal-footer',
      float ? `beta-modal-footer--${float}` : null
    )}
  >
    {children}
  </div>
);

Modal.SideContent = ({
  children,
}: {
  children: React.ReactNode;
  float?: 'right' | 'left';
}) => <div className={classNames('beta-modal-side-content')}>{children}</div>;

export default Modal;
