import styled from '@emotion/styled';

import { Actions } from './actions';
import { Box } from './box';
import { StyledCheckbox } from './checkbox';

export const CandidateContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const CandidateContainerBody = styled.div<{ blured?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  gap: 8px;
  filter: ${({ blured }) => (blured ? 'blur(3px)' : 'none')};
`;

export const CandidateContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  align-items: center;
  gap: 14px;
  height: 16px;
  justify-content: space-between;
`;

export const CandidateContainer = styled(Box)`
  &.actionable:hover {
    ${Actions} {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s, visibility 0.3s;
    }
  }
  &:hover {
    ${StyledCheckbox} {
      border-color: ${({ theme }) => theme.colors.purple[40]};
    }
  }

  &.locked {
    user-select: none;
  }
`;

export const CandidateContainerNotes = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${props => props.theme.typography.colorSecondary};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;
