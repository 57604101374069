import { useEffect } from 'react';
import { Location } from 'history';
import { match as RouterMatch } from 'react-router-dom';

import configuration from '@/configuration/runtime';

interface RouteParams {
  slug: string;
  hashedTestId?: string;
}

interface CandidateAppRedirectProps {
  match: RouterMatch<RouteParams>;
  location: Location;
}

export function CandidateAppRedirect(props: CandidateAppRedirectProps) {
  useEffect(() => {
    const { slug, hashedTestId } = props.match.params;
    const base = `${configuration.candidateAppUrl}/${slug}`;
    const search = props.location.search;

    if (hashedTestId) {
      location.replace(`${base}/${hashedTestId}${search}`);
    } else {
      location.replace(`${base}${search}`);
    }
  }, [props.location.search, props.match.params]);

  return null;
}
