export const permissionsFactory = <
  Role extends string,
  Resource extends string,
  Action
>() => {
  return new PermissionsFactory<Role, Resource, Action>();
};

class PermissionsFactory<Role extends string, Resource extends string, Action> {
  roles: Partial<Record<Role, Role[]>> = {};
  resources: Partial<Record<Resource, true>> = {};
  allows: { resource: Resource; action: Action; role: Role }[] = [];

  role(role: Role, parent?: Role) {
    this.roles[role] = parent != null ? [parent] : [];
  }

  resource(resource: Resource) {
    this.resources[resource] = true;
  }

  allow(role: Role, resource: Resource, actions: Action[]) {
    this._checkRoleExists(role);
    this._checkResourceExists(resource);

    for (const action of actions) {
      this.allows.push({ role, resource, action });
    }
  }

  can(role: Role, resource: Resource, action: Action) {
    this._checkRoleExists(role);
    this._checkResourceExists(resource);

    const chain = this.chain(role);

    for (const role of chain) {
      if (this._checkAllowed(role, resource, action)) {
        return true;
      }
    }

    return false;
  }

  chain(role: Role) {
    const chain = [role];

    for (const parent of this.roles[role] || []) {
      chain.push(...this.chain(parent));
    }

    return chain;
  }

  _checkAllowed(role: Role, resource: Resource, action: Action) {
    for (const rule of this.allows) {
      if (
        rule.role === role &&
        rule.resource === resource &&
        rule.action === action
      ) {
        return true;
      }
    }

    return false;
  }

  _checkRoleExists(role: Role) {
    if (this.roles[role] == null) {
      throw new Error(`Role ${role} does not exist`);
    }
  }

  _checkResourceExists(resource: Resource) {
    if (this.resources[resource] == null) {
      throw new Error(`Resource ${resource} does not exist`);
    }
  }
}
