import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { getCandidateRedirectUrl } from '@/features/candidate';
import { Icon, TId, useClipboard } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

export function ShareButton({ candidateId }: { candidateId: TId }) {
  const { showFlashMessage } = useFlashMessages();
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const candidateUrl = getCandidateRedirectUrl(
    workspaceId,
    jobOpeningId,
    candidateId
  );
  const { copyButtonRef, valueRef } = useClipboard();
  valueRef.current = candidateUrl;

  return (
    <DSButton
      data-rh="Copy candidate link to clipboard"
      ref={copyButtonRef}
      variant="tertiary"
      size="xsmall"
      Icon={<Icon icon={regular('copy')} color="purple-60" />}
      onClick={e => {
        e.stopPropagation();
        showFlashMessage({ type: 'link_copied' });
      }}
    />
  );
}
