import api from '@/api/api';
import { IOwnershipTransferRequest } from '@/features/workspace';
import { Id } from '@/types/misc';
import { parseAPIData } from '@/utils/parsers';

export async function requestOwnershipTransfer(params: {
  workspaceId: Id;
  receiverId: Id;
}): Promise<IOwnershipTransferRequest> {
  const response = await api.post(
    `/workspaces/${params.workspaceId}/transfer-ownership-requests`,
    { receiver_id: parseInt(params.receiverId, 10) }
  );

  return parseAPIData(response.data);
}

export async function cancelOwnershipTransfer(params: {
  requestId: Id;
}): Promise<void> {
  await api.delete(`/transfer-ownership-requests/${params.requestId}`);
}

export async function fetchOwnershipTransferRequests(params: {
  workspaceId: Id;
}): Promise<IOwnershipTransferRequest[]> {
  const response = await api.get(
    `/workspaces/${params.workspaceId}/transfer-ownership-requests`
  );

  return parseAPIData<IOwnershipTransferRequest[]>(
    response.data.requests || []
  );
}

export async function acceptOwnershipTransfer(params: {
  requestId: Id;
  workspaceId: Id;
}): Promise<IOwnershipTransferRequest> {
  const response = await api.post(
    `/transfer-ownership-requests/${params.requestId}/accept`
  );

  return parseAPIData(response.data);
}

export async function declineOwnershipTransfer(params: {
  requestId: Id;
  workspaceId: Id;
}): Promise<IOwnershipTransferRequest> {
  const response = await api.post(
    `/transfer-ownership-requests/${params.requestId}/decline`
  );

  return parseAPIData(response.data);
}
