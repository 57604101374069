import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSContextMenu } from '@hundred5/design-system';
import useOnClickOutside from 'use-onclickoutside';

import { Icon, SemanticButton } from '@/features/common';

interface DetailsFieldMenuProps {
  canEdit: boolean;
  canDelete: boolean;
  isMandatory: boolean;
  onEdit?: (newValue: boolean) => void;
  onDelete?: () => void;
}

export function DetailsFieldMenu({
  canEdit,
  canDelete,
  isMandatory,
  onEdit,
  onDelete,
}: DetailsFieldMenuProps) {
  const contextMenuRef = useRef<null | HTMLDivElement>(null);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  useOnClickOutside(contextMenuRef, () => {
    setContextMenuOpen(false);
  });

  return (
    <FieldMenuContainer ref={contextMenuRef}>
      <ContextMenuButton
        type="button"
        onClick={() => setContextMenuOpen(prev => !prev)}
        open={contextMenuOpen}
        disabled={!canEdit}
      >
        {isMandatory ? 'mandatory' : 'optional'}
        <Arrow
          fontSize="10px"
          icon={regular('angle-down')}
          color={contextMenuOpen ? 'orange-100' : 'purple-60'}
          isOpen={contextMenuOpen}
        />
      </ContextMenuButton>

      <DSContextMenu
        open={contextMenuOpen}
        position={{ top: '26px', right: '0' }}
      >
        <DSContextMenu.Item
          label="mandatory"
          selected={isMandatory}
          onClick={() => {
            onEdit?.(true);
            setContextMenuOpen(false);
          }}
        />
        <DSContextMenu.Item
          label="optional"
          selected={!isMandatory}
          onClick={() => {
            onEdit?.(false);
            setContextMenuOpen(false);
          }}
        />
        {canDelete && (
          <>
            <DSContextMenu.Delimiter />
            <DSContextMenu.Item
              onClick={() => {
                onDelete?.();
                setContextMenuOpen(false);
              }}
              label="Delete"
              icon={<Icon icon={regular('trash')} />}
              dangerous
            />
          </>
        )}
      </DSContextMenu>
    </FieldMenuContainer>
  );
}

const FieldMenuContainer = styled.div`
  position: relative;
  margin-right: auto;
`;

const Arrow = styled(Icon, {
  shouldForwardProp: prop => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  margin-left: auto;
  transform: rotate(${props => (props.isOpen ? 180 : 0)}deg);
  transition: transform 0.1s ease-in-out;
`;

const ContextMenuButton = styled(SemanticButton)<{
  open?: boolean;
  disabled?: boolean;
}>`
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.typography.colorPrimary};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  background-color: ${props =>
    props.open ? props.theme.colors.purple[10] : props.theme.colors.white};
  transition: background-color 0.1s;

  &:active,
  &:focus {
    background-color: ${props =>
      props.open ? props.theme.colors.purple[10] : props.theme.colors.white};
  }

  &:hover {
    background-color: ${props =>
      !props.disabled && props.theme.colors.purple[10]};
  }
`;
