import React from 'react';
import { DSButton, DSContentBlock } from '@hundred5/design-system';

import {
  useAcceptOwnershipTransferMutation,
  useDeclineOwnershipTransferMutation,
  useWorkspace,
  useWorkspaceOwnershipTransferRequestsQuery,
  useWorkspaceUsersQuery,
} from '@/features/workspace';

export function WorkspaceOwnershipTransfer() {
  const workspace = useWorkspace();
  const requestId = workspace?.transferOwnershipRequestId;

  const ownershipTransferRequests = useWorkspaceOwnershipTransferRequestsQuery();
  const requesterId = ownershipTransferRequests.data?.find(
    request => request.id === requestId
  )?.requesterId;

  const workspaceUsers = useWorkspaceUsersQuery();
  const requester = workspaceUsers.data?.find(user => user.id === requesterId);

  const acceptOwnershipMutation = useAcceptOwnershipTransferMutation();
  const declineOwnershipMutation = useDeclineOwnershipTransferMutation();

  const handleAccept = () => {
    if (!workspace || !requestId) return;

    acceptOwnershipMutation.mutate({
      requestId: requestId,
      workspaceId: workspace.id,
    });
  };

  const handleDecline = () => {
    if (!workspace || !requestId) return;

    declineOwnershipMutation.mutate({
      requestId: requestId,
      workspaceId: workspace.id,
    });
  };

  if (!requestId || ownershipTransferRequests.isLoading) {
    return null;
  }

  return (
    <DSContentBlock>
      <DSContentBlock.Title>Workspace ownership transfer</DSContentBlock.Title>

      <DSContentBlock.Content>
        <strong>{requester?.name}</strong> has requested you to take the
        ownership rights for the {workspace.name} workspace.
      </DSContentBlock.Content>

      <DSContentBlock.Footer>
        <DSButton
          variant="secondary"
          onClick={handleDecline}
          disabled={
            acceptOwnershipMutation.isLoading ||
            declineOwnershipMutation.isLoading
          }
        >
          Decline
        </DSButton>
        <DSButton
          onClick={handleAccept}
          disabled={
            acceptOwnershipMutation.isLoading ||
            declineOwnershipMutation.isLoading
          }
        >
          Accept
        </DSButton>
      </DSContentBlock.Footer>
    </DSContentBlock>
  );
}
