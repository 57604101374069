import api from '@/api/api';
import { ICandidateNote, ICandidateNotesResponse } from '@/features/candidate';
import { TId } from '@/features/common';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchCandidateNotes(params: {
  candidateId: TId;
}): Promise<ICandidateNotesResponse> {
  const response = await api.get(`/test-takers/${params.candidateId}/notes`);
  const data = parseAPIData<ICandidateNotesResponse>(response.data);

  // Check if read
  if (data.notes !== null) {
    data.notes.forEach(note => {
      if (data.readNotesAt === null || data.readNotesAt < note.createdAt) {
        note.unread = true;
        data.hasUnread = true;
      }
    });
  }

  return data;
}

export async function createCandidateNote(params: {
  candidateId: TId;
  note: string;
  answerId?: TId;
  userIds: TId[];
}): Promise<ICandidateNote> {
  const response = await api.post(
    `/test-takers/${params.candidateId}/notes`,
    serialiseAPIData({
      note: params.note,
      answerId: params.answerId,
      userIds: params.userIds,
    })
  );

  return parseAPIData(response.data);
}

export async function deleteCandidateNote(params: { id: TId }): Promise<void> {
  await api.delete(`/notes/${params.id}`);
}

export async function updateCandidateNote(params: {
  id: TId;
  note: string;
  userIds: TId[];
}): Promise<ICandidateNote> {
  const response = await api.put(
    `/notes/${params.id}`,
    serialiseAPIData({
      note: params.note,
      userIds: params.userIds,
    })
  );
  return parseAPIData(response.data);
}

export async function markCandidateNotesAsRead(params: {
  candidateId: TId;
}): Promise<void> {
  await api.post(`/test-takers/${params.candidateId}/notes/mark-as-read`);
}

export async function detectAiAnswerAndAddToNote(params: {
  candidateId: TId;
  answerId: TId;
  questionNumber: number;
}): Promise<ICandidateNote> {
  const response = await api.post(
    `/test-takers/${params.candidateId}/notes/ai`,
    serialiseAPIData({ ...params, ai_type: 'detection' })
  );

  return parseAPIData(response.data);
}

export async function evaluateAiAnswerAndAddToNote(params: {
  candidateId: TId;
  answerId: TId;
  questionNumber: number;
}): Promise<ICandidateNote> {
  const response = await api.post(
    `/test-takers/${params.candidateId}/notes/ai`,
    serialiseAPIData({ ...params, ai_type: 'evaluation' })
  );

  return parseAPIData(response.data);
}
