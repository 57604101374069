import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { initialized } from '@/actions/authentication';
import { setAuthToken } from '@/api/api';
import { loadAuthToken } from '@/authentication';
import { getSharedAuthEnabled } from '@/features/toggl-accounts';

export const AuthProvider = () => {
  const dispatch = useDispatch();
  const sharedAuthEnabled = getSharedAuthEnabled();

  useEffect(() => {
    if (!sharedAuthEnabled) {
      const token = loadAuthToken();
      if (token != null) {
        setAuthToken(token);
      }

      dispatch(initialized(token != null));
    }
  }, [dispatch, sharedAuthEnabled]);

  return null;
};
