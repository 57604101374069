import api from '@/api/api';
import { TId } from '@/features/common';

export async function bulkReject(params: {
  candidateIds: TId[];
  subject?: string;
  message?: string;
  sendAt?: Date;
}): Promise<void> {
  let ids: number[] = params.candidateIds.map(id => Number(id));
  await api.patch(
    `/test-takers/bulk`,
    {
      ids: ids,
      action: 'reject',
      test_taker_email:
        params.subject && params.message
          ? {
              subject: params.subject,
              message: params.message,
              send_at: params.sendAt?.toISOString(),
            }
          : undefined,
    },
    { captchaAction: 'bulk_reject' }
  );
}
