import { useEffect } from 'react';

import { TId } from '@/features/common';
import {
  useMeQuery,
  usePreferenceMutation,
  usePreferencesQuery,
} from '@/features/me';
import { isUserInSupportMode } from '@/features/me/utils/me';

interface UpdateSelectedWorkspaceProps {
  currentWorkspaceId: TId;
}

const UpdateSelectedWorkspace = ({
  currentWorkspaceId,
}: UpdateSelectedWorkspaceProps) => {
  const meQuery = useMeQuery();
  const { data: userPreferences } = usePreferencesQuery();
  const { mutate: updatePreferencesMutation } = usePreferenceMutation();

  const selectedWorkspaceId = userPreferences?.selectedWorkspace;
  // `true` is the default value to avoid mutating before query is fulfilled
  const supportMode = meQuery.isSuccess
    ? isUserInSupportMode(meQuery.data)
    : true;

  useEffect(() => {
    if (currentWorkspaceId !== selectedWorkspaceId && !supportMode) {
      updatePreferencesMutation({
        name: 'selectedWorkspace',
        value: currentWorkspaceId,
      });
    }
  }, [
    currentWorkspaceId,
    selectedWorkspaceId,
    updatePreferencesMutation,
    supportMode,
  ]);

  return null;
};

export default UpdateSelectedWorkspace;
