import api from '@/api/api';
import { TId } from '@/features/common';
import {
  IZapierApiKey,
  IZapierApiKeysByWorkspace,
} from '@/features/integrations';
import { parseAPIData } from '@/utils/parsers';

export async function fetchZapierApiKeys(): Promise<IZapierApiKeysByWorkspace> {
  const response = await api.get(`/integrations/zapier/api-key`);

  const apiKeys = parseAPIData<IZapierApiKey[]>(response.data.api_keys) || [];
  const byWorkspaceId = {};
  apiKeys.forEach(item => (byWorkspaceId[item.workspaceId] = item.apiKey));

  return byWorkspaceId;
}

export async function generateZapierApiKey(params: {
  workspaceId: TId;
}): Promise<void> {
  const response = await api.post(
    `/workspaces/${params.workspaceId}/integrations/zapier/api-key/generate`
  );

  return parseAPIData(response.data);
}

export async function deleteZapierApiKey(params: {
  workspaceId: TId;
}): Promise<void> {
  await api.delete(
    `/workspaces/${params.workspaceId}/integrations/zapier/api-key`
  );
}
