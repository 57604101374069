import React, { ChangeEvent } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSPictureOption } from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { useImageUpload } from '@/hooks/images';

import { DragIcon } from './drag-icon';

interface PictureChoiceOptionProps {
  id: string;
  sortable: boolean;
  removable?: boolean;
  value: string;
  isCorrect: boolean;
  onSelectionChange: () => void;
  onOptionChange: (value: string) => void;
  onRemoveOption: () => void;
  dragging?: boolean;
}

export const PictureChoiceOption = ({
  id,
  sortable,
  removable = true,
  value,
  isCorrect,
  onSelectionChange,
  onOptionChange,
  onRemoveOption,
  dragging = false,
}: PictureChoiceOptionProps) => {
  const isEmptyOption = id === 'new_option' || value === '';

  if (isEmptyOption) {
    return (
      <Container>
        <DragIcon sortable={sortable} />
        <PictureContainer>
          <ImageUploader onUpload={onOptionChange} />
        </PictureContainer>
      </Container>
    );
  }

  return (
    <PictureOptionContainer>
      <DragIcon sortable={sortable} />
      <DSPictureOption
        key={id}
        name={`answer-${id}`}
        id={`option-${id}`}
        url={value}
        selected={isCorrect}
        onChange={onSelectionChange}
      />
      <Controls>
        <ReUpload onUpload={onOptionChange} />
        <DSButton
          variant="secondary"
          onClick={onRemoveOption}
          Icon={
            <Icon icon={regular('trash')} color="purple-100" fontSize={12} />
          }
        />
      </Controls>
    </PictureOptionContainer>
  );
};

interface ImageUploaderProps {
  onUpload: (url: string) => void;
}

const useImageUploader = ({ onUpload }) => {
  const upload = useImageUpload();
  const uploadImage = async (file: File): Promise<string> => {
    const [error, url] = await upload.uploadImage(
      file,
      'picture_question',
      false
    );

    if (error) throw error;
    return url;
  };
  const [uploading, setUploading] = React.useState(false);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files == null || files.length === 0) return;

    setUploading(true);

    try {
      const url = await uploadImage(files[0]);
      onUpload(url);
      setUploading(false);
    } catch (error) {
      setUploading(false);
      throw error;
    }
  };

  return { uploading, handleChange };
};

const ImageUploader = ({ onUpload }: ImageUploaderProps) => {
  const { uploading, handleChange } = useImageUploader({
    onUpload,
  });

  return (
    <ImageUpload disabled={uploading}>
      <Icon icon={regular('image')} color="purple-60" size="2x" />
      <ImageUploadLabel>Upload picture</ImageUploadLabel>
      <ImageUploadInput
        type="file"
        onChange={handleChange}
        accept="image/jpeg,image/png"
      />
    </ImageUpload>
  );
};

const ReUpload = ({ onUpload }) => {
  const { uploading, handleChange } = useImageUploader({
    onUpload,
  });

  return (
    <ImageReUpload htmlFor="picture-input" disabled={uploading}>
      <Icon icon={regular('swap')} color="purple-100" fontSize={12} />
      <ImageUploadInput
        type="file"
        id="picture-input"
        onChange={handleChange}
        accept="image/jpeg,image/png"
      />
    </ImageReUpload>
  );
};
const Container = styled.div`
  height: 200px;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 8px;
`;

const PictureContainer = styled.div<{ isEmpty?: boolean }>`
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.purple[5]};
  height: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.purple[10]};
  position: relative;
`;

const ImageUpload = styled.label<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
  width: 100%;
  height: 100%;

  &:hover {
    opacity: 0.5;
  }
`;

const ImageReUpload = styled(ImageUpload)`
  color: ${({ theme }) => theme.colors.purple[100]};
  -webkit-appearance: none;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  outline: none;
  transition: background 0.1s, transform 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.purple[40]};
  height: 36px;
  width: 36px;
  padding: 0;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:active:not(:disabled) {
    transform: scale(0.96);
  }

  &:hover {
    opacity: 1;
  }

  &:hover:not(:disabled) {
    background: ${({ theme }) => theme.hover.onWhite};
  }
`;

const ImageUploadLabel = styled.p`
  margin: 8px 0 0 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.purple[100]};
  text-decoration: underline;
`;

const ImageUploadInput = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
`;

const PictureOptionContainer = styled(Container)`
  position: relative;

  li {
    width: 100%;
    padding: 0;

    & > div {
      height: 200px;
    }
  }
`;

const Controls = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;
