import { WORKSPACE_STATS_QUERY_KEY } from '@/features/workspace';

import { queryClient } from './reactQuery';

const invalidateWorkspaceStatsAfterActions = [
  'CANDIDATES/CANDIDATE_REJECTED',
  'EMAIL_MODAL/CLOSE',
  'PIPELINE/UPDATED_CANDIDATE',
];

const invalidatePipelineCategoriesAfterActions = [
  'DELETED_CANDIDATE_CATEGORY',
  'UPDATED_CANDIDATE_CATEGORY',
];

const invalidateTestAfterActions = [
  'UPDATED_TEST',
  'CREATED_TEST_INTRO',
  'TURNED_TEST_INTO_CUSTOM',
  'DELETED_TEST_INTRO',
];

export function queryClientMiddleware() {
  return next => action => {
    const returnValue = next(action);

    // candidate updates influence totals in sidebar that use stats
    if (invalidateWorkspaceStatsAfterActions.includes(action.type)) {
      queryClient.invalidateQueries({ queryKey: WORKSPACE_STATS_QUERY_KEY });
      queryClient.invalidateQueries({ queryKey: ['workspaceCandidatesStats'] });
    }

    if (invalidatePipelineCategoriesAfterActions.includes(action.type)) {
      queryClient.invalidateQueries({
        queryKey: ['pipeline-categories', action.payload.category.jobOpening],
      });
    }

    if (invalidateTestAfterActions.includes(action.type)) {
      queryClient.invalidateQueries({
        queryKey: ['test', action.payload.test.id],
      });
    }

    return returnValue;
  };
}
