import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSCheckbox,
  DSRadio,
  DSStyledContent,
} from '@hundred5/design-system';

import Markdown from '@/components/markdown/Markdown';
import { Icon } from '@/features/common';
import { TQuestionType } from '@/features/questions';
import { StyledAutosizeTextArea } from '@/features/test/components/test-editor-question-create/ui/styled-auto-size-textarea';

import { DragIcon } from './drag-icon';

interface ChoiceOptionProps {
  id: string;
  sortable?: boolean;
  removable?: boolean;
  questionType: TQuestionType;
  value: string;
  isCorrect: boolean;
  onSingleSelectionChange: () => void;
  onMultipleSelectionChange: (checked: boolean) => void;
  onOptionCreate: () => void;
  onOptionChange: (value: string) => void;
  onRemoveOption: () => void;
}

export const ChoiceOption = ({
  id,
  sortable = true,
  removable = true,
  questionType,
  value,
  isCorrect,
  onSingleSelectionChange,
  onMultipleSelectionChange,
  onOptionCreate,
  onOptionChange,
  onRemoveOption,
}: ChoiceOptionProps) => {
  const [editing, setEditing] = React.useState(false);

  return (
    <OptionContainer>
      <SmallContainer>
        <DragIcon sortable={sortable} />
      </SmallContainer>

      <SmallContainer>
        {questionType === 'single-choice' ? (
          <DSRadio
            name={`${questionType}-options`}
            value={value}
            checked={isCorrect}
            onChange={() => onSingleSelectionChange()}
            disabled={!sortable}
          />
        ) : (
          <DSCheckbox
            name={`${questionType}-options`}
            value={value}
            checked={isCorrect}
            onChange={event => onMultipleSelectionChange(event.target.checked)}
            disabled={!sortable}
          />
        )}
      </SmallContainer>
      {editing || value === '' ? (
        <OptionTextArea
          key={id}
          autoFocus={removable}
          defaultValue={value}
          placeholder={!removable ? 'Add choice' : ''}
          onFocus={() => {
            if (id === 'new_option') {
              onOptionCreate();
            }
          }}
          onBlur={event => {
            setEditing(false);
            const newValue = event.target.value.trim();
            if (newValue !== value) {
              onOptionChange(newValue);
            }
          }}
        />
      ) : (
        <OptionContent onClick={() => setEditing(true)}>
          <Markdown source={value} />
        </OptionContent>
      )}

      {removable ? (
        <DSButton
          variant="tertiary"
          size="xsmall"
          onClick={onRemoveOption}
          Icon={<Icon icon={regular('trash')} color="purple-100" />}
        />
      ) : null}
    </OptionContainer>
  );
};

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content 1fr min-content;
  gap: 8px;
`;

const SmallContainer = styled.div`
  height: 24px;
  display: grid;
  align-items: center;
`;

const OptionContent = styled(DSStyledContent)`
  overflow: hidden;
  display: flex;
  align-items: center;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
`;

const OptionTextArea = styled(StyledAutosizeTextArea)`
  min-height: 24px;
  line-height: 24px;
`;
