import React from 'react';
import { useMedia } from 'react-use';

import { PageHeader } from '@/features/common';

interface HeaderProps {
  title: string;
  children?: React.ReactNode;
}

export function Header({ children, title }: HeaderProps) {
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <PageHeader
      title={title}
      backButton={{ to: '/test-library', tooltip: 'Go back to test library' }}
      actions={!isMobile && children}
    />
  );
}
