import React, { Component } from 'react';
import { Modal } from '@hundred5/design-system';

import Lightbox from '../lightbox/Lightbox';

import './MarkdownImage.css';

type MarkdownImageProps = {
  src: string;
  title: string | null;
  alt: string | null;
};

type MarkdownImageState = {
  lightboxOpen: boolean;
};

class MarkdownImage extends Component<MarkdownImageProps, MarkdownImageState> {
  state: MarkdownImageState = {
    lightboxOpen: false,
  };

  render() {
    const { src, alt, title } = this.props;
    const { lightboxOpen } = this.state;

    return (
      <span className="markdown-image">
        <img
          src={src}
          alt={alt || undefined}
          title={title || undefined}
          onClick={this.openLightbox}
        />

        <Modal open={lightboxOpen} size="large" onClose={this.closeLightbox}>
          <Lightbox imageUrl={src} />
        </Modal>
      </span>
    );
  }

  openLightbox = () => {
    this.setState({ lightboxOpen: true });
  };

  closeLightbox = () => {
    this.setState({ lightboxOpen: false });
  };
}

export default MarkdownImage;
