import { ITestQuestion, TTestQuestionType } from '@/features/test';

//region Constants
const DEFAULT_TITLES: { [K in TTestQuestionType]: string } = {
  'free-text': 'Free Text',
  'single-choice': 'Single Choice',
  'multiple-choice': 'Multiple Choice',
  'picture-question': 'Picture Choice',
  'numeric-input': 'Numeric Input',
  'code-input': 'Code Input',
  'video-question': 'Video',
  'placeholder-close-ended': 'Smart',
};
//endregion

//region Functions
export const formatPoints = (points: number): string => {
  if (points === 0) {
    return '0 points';
  } else if (points < 1) {
    return `${points.toFixed(2)} points`;
  } else if (points === 1) {
    return '1 point';
  } else {
    return `${Number.isInteger?.(points) ? points : points.toFixed(2)} points`;
  }
};

export const getTitle = (question: ITestQuestion): string =>
  question.title || DEFAULT_TITLES[question.questionType];
//endregion
