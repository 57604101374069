import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { isFuture } from 'date-fns';
import { matchPath } from 'react-router';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import store from 'store';

import PageLayout from '@/components/page_layout/PageLayout';
import Tooltip from '@/components/tooltip/Tooltip';
import { useMeQuery } from '@/features/me';
import { useWorkspacePermission } from '@/features/permissions';
import { useWorkspace } from '@/features/workspace';
import { planLevels, premiumPlans } from '@/hooks/planLimits';
import { useHistory, useWorkspaceIdOrNull } from '@/hooks/router';

import { JobList } from '../job-list';
import { PreconfiguredFilters } from '../preconfigured-filters';
import { Shortcuts } from '../shortcuts';
import { WorkspaceSelector } from '../workspace-selector';

import {
  Arrows,
  Cog,
  CogFilled,
  Logo,
  QuestionMark,
  Tooltip as NavigationTooltip,
  NoWorkspaceNavigation,
} from './ui';

interface NavigationProps {
  children: React.ReactNode;
}

const SETTING_STORAGE_KEY = 'toggl_hire_settings_navigation_collapsed';

export function Navigation({ children }: NavigationProps) {
  const pageLayout = useContext(PageLayout.Context);
  const workspaceId = useWorkspaceIdOrNull();
  const workspace = useWorkspace();
  const history = useHistory();
  const isMobile = useMedia('(max-width: 768px)');
  const isSmallScreen = useMedia('(max-width: 1300px)');
  const isPremiumPlan =
    workspace && premiumPlans.includes(planLevels[workspace.subscriptionPlan]);

  const canOpenWorkspaceSettings = useWorkspacePermission()(
    'workspace settings',
    'read'
  );
  const canOpenUserSettings = useWorkspacePermission()('user settings', 'read');

  const meQuery = useMeQuery();

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [temporaryExpand, setTemporaryExpand] = useState(false);

  const isPausedSubscription = useMemo(() => {
    const currentWorkspace = meQuery.data?.workspaces?.find(
      workspace => workspace.id === workspaceId
    );
    const fromInFuture =
      !!currentWorkspace?.subscriptionPausedFrom &&
      isFuture(currentWorkspace.subscriptionPausedFrom);

    const tillInFuture =
      !!currentWorkspace?.subscriptionPausedUntil &&
      isFuture(currentWorkspace.subscriptionPausedUntil);

    return !fromInFuture && tillInFuture;
  }, [meQuery.data?.workspaces, workspaceId]);

  useEffect(() => {
    if (isSmallScreen) {
      // always collapse on small screens
      setCollapsed(true);
      return;
    }

    const storedCollapse = store.get(SETTING_STORAGE_KEY);
    const isPipelinesView =
      matchPath(history.location.pathname, {
        path: '/admin/:workspaceId/openings/:jobOpeningId/pipeline',
      }) != null;

    if (storedCollapse != null) {
      // having stored setting overrides default behaviour
      setCollapsed(storedCollapse);
      return;
    }

    if (isPipelinesView) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [history.location.pathname, isSmallScreen]);

  const handleMouseEnter = useCallback(() => {
    if (collapsed) {
      setTemporaryExpand(true);
    }
  }, [collapsed]);

  const handleMouseLeave = useCallback(() => {
    if (temporaryExpand) {
      setTemporaryExpand(false);
    }
  }, [temporaryExpand]);

  const handleCollapseToggle = () => {
    store.set(SETTING_STORAGE_KEY, !collapsed);
    setCollapsed(collapsed => !collapsed);
  };

  if (!workspaceId) {
    return (
      <Container>
        <Content>{children}</Content>
        <Sidebar offsetTop={pageLayout.offsetTop}>
          <Tooltip
            delay={300}
            attribute="data-navigation-tooltip"
            onRenderContent={(target, content) => {
              return (
                <NavigationTooltip
                  title={target.dataset.title}
                  content={content}
                />
              );
            }}
          />
          <MainNavigation isSupportMode={meQuery.data?.id === '0'}>
            <Logo />
            <NavItem
              data-navigation-tooltip="User settings"
              data-navigation-tooltip-at="right"
            >
              <Link to={`/admin/profile`}>
                {history.location.pathname.includes('profile') ? (
                  <CogFilled />
                ) : (
                  <Cog />
                )}
              </Link>
            </NavItem>
            <NavItem
              data-navigation-tooltip="Advice and answers"
              data-navigation-tooltip-at="right"
            >
              <a
                href="https://support.hire.toggl.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <QuestionMark />
              </a>
            </NavItem>
          </MainNavigation>
          <CollapsibleNavigation collapsed={false} renderOnTop={false}>
            <NoWorkspaceNavigation />
          </CollapsibleNavigation>
        </Sidebar>
      </Container>
    );
  }

  return (
    <Container>
      <Content>{children}</Content>
      <Sidebar
        offsetTop={pageLayout.offsetTop}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        <Tooltip
          delay={200}
          attribute="data-navigation-tooltip"
          onRenderContent={(target, content) => {
            return (
              <NavigationTooltip
                title={target.dataset.title}
                content={content}
              />
            );
          }}
        />
        <MainNavigation isSupportMode={meQuery.data?.id === '0'}>
          <Link to={`/admin/${workspaceId}`}>
            <Logo />
          </Link>
          {!isMobile && (
            <NavItem
              key={collapsed ? '1' : '0'} // rerender to change tooltip
              data-navigation-tooltip={
                isSmallScreen
                  ? 'Open sidebar'
                  : collapsed
                  ? 'Lock sidebar open'
                  : 'Close sidebar'
              }
              data-navigation-tooltip-at="right"
            >
              <Arrows
                onClick={() => !isSmallScreen && handleCollapseToggle()}
                orientation={collapsed ? 'right' : 'left'}
              />
            </NavItem>
          )}
          {(canOpenUserSettings || canOpenWorkspaceSettings) && !isMobile ? (
            <NavItem
              data-navigation-tooltip={
                isPremiumPlan
                  ? canOpenWorkspaceSettings
                    ? 'Workspace settings'
                    : 'User settings'
                  : 'Plans and billing'
              }
              data-navigation-tooltip-at="right"
            >
              <Link
                to={
                  isPremiumPlan
                    ? canOpenWorkspaceSettings
                      ? `/admin/${workspaceId}/settings/team`
                      : `/admin/${workspaceId}/settings/profile`
                    : `/admin/${workspaceId}/settings/billing`
                }
              >
                {history.location.pathname.includes('/settings/') ? (
                  <CogFilled />
                ) : (
                  <Cog />
                )}
              </Link>
            </NavItem>
          ) : null}
          <NavItem
            data-navigation-tooltip="Advice and answers"
            data-navigation-tooltip-at="right"
          >
            <a
              href="https://support.hire.toggl.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <QuestionMark />
            </a>
          </NavItem>
        </MainNavigation>
        <CollapsibleNavigation
          collapsed={isMobile ? false : collapsed}
          renderOnTop={isMobile ? false : collapsed && temporaryExpand}
        >
          <WorkspaceSelector />
          <ScrollableContainer disabled={isPausedSubscription}>
            <Shortcuts />
            <JobList />
            <PreconfiguredFilters />
          </ScrollableContainer>
        </CollapsibleNavigation>
      </Sidebar>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 769px) {
    flex-direction: row-reverse;
  }
`;

const Sidebar = styled.aside<{ offsetTop: number }>`
  display: flex;
  flex-direction: row;
  height: ${({ offsetTop }) => `calc(100vh - ${offsetTop}px)`};
  overflow: visible;
  z-index: 100;

  @media (min-width: 769px) {
    position: sticky;
    top: ${({ offsetTop }) => offsetTop}px;
  }
`;

const MainNavigation = styled.nav<{ isSupportMode: boolean }>`
  align-items: center;
  background: ${({ theme, isSupportMode }) =>
    isSupportMode ? theme.colors.red[100] : theme.colors.purple[120]};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 0 16px;
  width: 48px;
  z-index: 20;
  & > a {
    height: 24px;
  }

  a:not(:first-of-type) {
    margin-bottom: 24px;
  }
`;

const NavItem = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;

  &:first-of-type {
    margin-top: 16px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.purple[100]};
  }
`;

const CollapsibleNavigation = styled.nav<{
  collapsed: boolean;
  renderOnTop: boolean;
}>`
  background: ${({ theme }) => theme.colors.purple[100]};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: ${({ collapsed }) => (collapsed ? 'absolute' : 'initial')};
  top: 0;
  transform: translateX(
    ${({ renderOnTop, collapsed }) =>
      renderOnTop ? '48px' : collapsed ? '-100%' : '0'}
  );
  transition: ${({ collapsed }) =>
    collapsed ? 'transform 200ms linear' : 'none'};
  width: 100%;
  z-index: 10;

  @media (min-width: 769px) {
    width: 256px;
    padding-top: 8px;
  }
`;

const ScrollableContainer = styled.div<{ disabled: boolean }>`
  overflow-y: auto;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const Content = styled.main`
  flex: 1;

  @media (min-width: 769px) {
    min-width: 0;
  }
`;
