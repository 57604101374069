import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import {
  useCandidateQuery,
  useRestoreCandidateMutation,
} from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages'; //region Main

//region Main
interface RestoreButtonProps {
  candidateId: TId;
}

export function RestoreButton(props: RestoreButtonProps) {
  const { data: candidate } = useCandidateQuery(props);
  const restoreCandidateMutation = useRestoreCandidateMutation();
  const { showFlashMessage } = useFlashMessages();

  const handleClick = async () => {
    await restoreCandidateMutation.mutateAsync(props);

    showFlashMessage({ type: 'candidate_restored' });

    amplitude?.logEvent('candidates/restore candidate', {
      'job opening id': candidate?.jobOpeningId,
      'candidate id': props.candidateId,
    });
  };

  return candidate?.hireState === 'rejected' ? (
    <DSButton
      type="button"
      variant="secondary"
      data-rh="Restore candidate"
      onClick={handleClick}
      disabled={restoreCandidateMutation.isLoading}
      Icon={<Icon icon={regular('rotate-left')} />}
    />
  ) : null;
}

//endregion
