import { camelizeKeys, decamelizeKeys } from 'humps';
import { cloneDeepWith } from 'lodash';

export function parseAPIData<T>(
  data: any,
  options?: {
    replacements?: [string, string][];
    customizer?: (value: any, key: string) => any;
  }
): T {
  let parsedData = data;

  // Apply replacements (if any)
  if (options?.replacements) {
    parsedData = applyReplacements(parsedData, options.replacements);
  }

  // List of values that should be parsed
  parsedData = cloneDeepWith(parsedData, (value, key) => {
    if (key) {
      if (value != null && /(^|_)id$/.test(key.toString())) {
        return String(value);
      }

      if (value && /(^|_)ids$/.test(key.toString())) {
        return value.map((id: number) => String(id));
      }

      if (value && /_at$|_date$|^timestamp$/.test(key.toString())) {
        return new Date(value);
      }

      if (options?.customizer) {
        return options.customizer(value, key.toString());
      }
    }
  });

  return camelizeKeys(parsedData) as T;
}

export function serialiseAPIData<T>(
  data: any,
  options?: {
    replacements?: [string, string][];
    customizer?: (value: any, key: string) => any;
  }
): T {
  let decamelizedData = decamelizeKeys(data);

  // Apply replacements (if any)
  if (options?.replacements) {
    decamelizedData = applyReplacements(decamelizedData, options.replacements);
  }

  return cloneDeepWith(decamelizedData, (value, key) => {
    if (key) {
      if (value != null && /(^|_)id$/.test(key.toString())) {
        return parseInt(value);
      }

      if (value && /(^|_)ids$/.test(key.toString())) {
        return value.map((id: string) => parseInt(id));
      }

      if (
        value &&
        /_at$|_date$|^timestamp$/.test(key.toString()) &&
        value instanceof Date
      ) {
        return value.toISOString();
      }

      if (options?.customizer) {
        return options.customizer(value, key.toString());
      }
    }
  }) as T;
}

function applyReplacements(data: any, replacements: [string, string][]) {
  let stringified = JSON.stringify(data);

  replacements.forEach(pair => {
    stringified = stringified.replace(
      new RegExp(`"${pair[0]}":`, 'g'),
      `"${pair[1]}":`
    );
  });

  return JSON.parse(stringified);
}
