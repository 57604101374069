import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useIntersection } from 'react-use';

import Markdown from '@/components/markdown/Markdown';
import { Icon, SemanticButton } from '@/features/common';
import {
  createEditorState,
  getEditorMarkdown,
} from '@/features/common/components/custom-text-editor';
import {
  QuestionAnswers,
  QuestionDescription,
} from '@/features/test/components/test-editor-question-preview';

import { ITestTemplateQuestion } from '../../types';

import { logArrowNavigation } from './test-library-details.analytics';
import { Section } from './ui/section';

interface SampleQuestionsProps {
  questions?: ITestTemplateQuestion[];
}

export function SampleQuestions({ questions }: SampleQuestionsProps) {
  const questionsRef = useRef(null);
  const firstQuestionRef = useRef(null);
  const firstQuestionIntersectionEntry = useIntersection(firstQuestionRef, {
    root: questionsRef.current,
    threshold: 0.5,
  });
  const [isAtFirstQuestion, setIsAtFirstQuestion] = useState(true);
  const lastQuestionRef = useRef(null);
  const lastQuestionIntersectionEntry = useIntersection(lastQuestionRef, {
    root: questionsRef.current,
    threshold: 0.5,
  });
  const [isAtLastQuestion, setIsAtLastQuestion] = useState(false);

  const handleChangeQuestion = (direction: 'left' | 'right') => () => {
    const container = document.querySelector<HTMLElement>(
      '#questions-scrollable-container'
    );

    if (!container) {
      return;
    }

    const targetLeftOffset =
      direction === 'right'
        ? container.scrollLeft + container.offsetWidth
        : container.scrollLeft - container.offsetWidth;
    container?.scrollTo({ left: targetLeftOffset, behavior: 'smooth' });
    logArrowNavigation();
  };

  useEffect(() => {
    if (firstQuestionIntersectionEntry?.isIntersecting) {
      setIsAtFirstQuestion(true);
    } else {
      setIsAtFirstQuestion(false);
    }

    if (lastQuestionIntersectionEntry?.isIntersecting) {
      setIsAtLastQuestion(true);
    } else {
      setIsAtLastQuestion(false);
    }
  }, [firstQuestionIntersectionEntry, lastQuestionIntersectionEntry]);

  if (!questions) {
    return null;
  }

  return (
    <Container>
      <Header>
        <h2>Example questions</h2>
        <div>
          <SemanticButton onClick={handleChangeQuestion('left')}>
            <Icon
              color={isAtFirstQuestion ? 'purple-60' : undefined}
              icon={regular('chevron-left')}
            />
          </SemanticButton>
          <SemanticButton onClick={handleChangeQuestion('right')}>
            <Icon
              color={isAtLastQuestion ? 'purple-60' : undefined}
              icon={regular('chevron-right')}
            />
          </SemanticButton>
        </div>
      </Header>
      <Questions id="questions-scrollable-container" ref={questionsRef}>
        {questions.map((question, index) => (
          <Question
            key={question.orderWeight}
            ref={
              index === 0
                ? firstQuestionRef
                : index === questions.length - 1
                ? lastQuestionRef
                : undefined
            }
          >
            <div>
              <h3>
                Question {index + 1} - {question.skill}
              </h3>
              <QuestionDescription>
                <Markdown
                  source={getEditorMarkdown(
                    createEditorState(question.description)
                  )}
                />
              </QuestionDescription>
              <QuestionAnswers
                options={question.options.map(option => ({
                  ...option,
                  isCorrect: false,
                }))}
                type={question.questionType}
              />
            </div>
          </Question>
        ))}
      </Questions>
    </Container>
  );
}

const Container = styled(Section)`
  background: ${({ theme }) => theme.colors.purple[5]};
  display: none;

  @media (min-width: 769px) {
    display: block;
  }
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 36px 36px 0 36px;

  h2 {
    font-size: 12px;
    margin: 0;
  }

  ${SemanticButton} {
    padding: 4px;
  }
`;

const Questions = styled.ul`
  display: flex;
  gap: 36px;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  padding: 36px;
  scroll-snap-type: x mandatory;
`;

const Question = styled.li`
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.purple[100]};
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  scroll-snap-align: center;

  & > div {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 8px;
    box-shadow: 0px 0px 1.44px 0px #2c13380d;
    box-shadow: 0px 5.75px 28.73px 0px #2c13380d;
    flex: 1;
    max-width: 750px;
  }

  & h3,
  & p {
    font-size: 12px;
  }

  & h3 {
    font-weight: 400;
    margin: 0;
    padding: 20px 24px 4px 24px;
  }

  & > div > div {
    max-height: 250px;
    overflow: auto;
  }
`;
