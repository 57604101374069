import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  CandidateTestStateTag,
  useCandidateQuery,
  useCandidateTest,
} from '@/features/candidate';
import { CandidateResults } from '@/features/candidate/components/candidate-card/candidate-results';
import { Icon } from '@/features/common';

export const PreviousTestResults = ({ candidateId, selectedTestId }) => {
  const [expanded, setExpanded] = useState(false);
  const { data: candidate } = useCandidateQuery(
    { candidateId },
    { enabled: !!candidateId && expanded }
  );
  const candidateTest = useCandidateTest({
    candidateId,
    testId: selectedTestId!,
  });

  if (!candidateId || !candidateTest) {
    return null;
  }

  return (
    <Container expanded={expanded}>
      <Heading onClick={() => setExpanded(prevState => !prevState)}>
        See previous test results
        <Arrow
          up={expanded}
          icon={regular('chevron-down')}
          color="purple-60"
          fontSize="12px"
        />
      </Heading>
      <ContentWrapper>
        <Content>
          <TestContent>
            {candidateTest.testType === 'quiz' ? (
              <Icon icon={solid('ruler')} />
            ) : candidateTest.testType === 'video' ? (
              <Icon icon={solid('video')} />
            ) : null}

            <TestName title={candidateTest.name}>{candidateTest.name}</TestName>

            {candidateTest.candidateState ? (
              <CandidateTestStateTag
                testState={candidateTest.candidateState}
                score={candidateTest.percent}
                scoreToPass={candidateTest.percentToPass}
              />
            ) : (
              <CandidateTestStateTag testState="not_invited" score={0} />
            )}
          </TestContent>
          <CandidateResults
            candidateId={candidateId}
            candidateTest={candidateTest}
            selectedTestId={selectedTestId}
            previousTestTakerId={candidate?.previousTestTakerId}
          />
        </Content>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div<{ expanded: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 16px;
  display: grid;
  overflow: hidden;
  grid-template-columns: 100%;
  grid-template-rows: min-content ${({ expanded }) =>
      expanded ? '1fr' : '0fr'};
  transition: grid-template-rows 0.2s;
`;

const Heading = styled.h4`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 36px;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.purple[60]};
`;

const Arrow = styled(Icon)<{ up: boolean }>`
  transform: rotate(${({ up }) => (up ? 180 : 0)}deg);
  transition: transform 100ms ease-in-out;
`;

const ContentWrapper = styled.div`
  min-height: 0;
  overflow: hidden;
`;

const Content = styled.div`
  color: ${({ theme }) => theme.colors.purple[100]};

  p {
    margin: 0;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  pre {
    white-space: pre;
    overflow-x: auto;
  }
`;

const TestContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 36px 16px;

  > * {
    margin-right: 4px;

    :last-child {
      margin-right: 0;
    }
  }
`;

const TestName = styled.span`
  color: ${props => props.theme.colors.purple[120]};
  font-size: 12px;
  line-height: 150%;

  ::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;
