import * as React from 'react';
import styled from '@emotion/styled';

import { Alert } from '@/features/common';
import { IValidationResult, TField } from '@/features/test';

interface ValidationAlertProps {
  fields: TField[];
  results: IValidationResult[];
}

const isError = (result: IValidationResult): boolean => result.type === 'error';
const isWarning = (result: IValidationResult): boolean =>
  result.type === 'warning';

const matchesField = (fields: TField[], result: IValidationResult): boolean =>
  fields.includes(result.field);

export const ValidationAlert = ({ fields, results }: ValidationAlertProps) => {
  const errors = results.filter(
    result => isError(result) && matchesField(fields, result)
  );

  const warnings = results.filter(
    result => isWarning(result) && matchesField(fields, result)
  );

  if (errors.length > 0) {
    return (
      <ValidationAlertWrapper>
        {errors.map((error, index) => (
          <Alert type="error" key={index}>
            {error.message}
          </Alert>
        ))}
      </ValidationAlertWrapper>
    );
  } else if (warnings.length > 0) {
    return (
      <ValidationAlertWrapper>
        {warnings.map((warning, index) => (
          <Alert type="warning" key={index}>
            {warning.message}
          </Alert>
        ))}
      </ValidationAlertWrapper>
    );
  }

  return null;
};

const ValidationAlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
