import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import {
  SlackIcon,
  useAddSlackIntegrationMutation,
} from '@/features/integrations';
import { useWorkspacePermission } from '@/features/permissions';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

export function SlackIntegration() {
  const { openUpsellModal } = useUpsellModal();
  const canAccessFeature = usePlanLimits();
  const canAddSlackIntegration = useWorkspacePermission()(
    'slack integration',
    'create'
  );
  const addSlackIntegrationMutation = useAddSlackIntegrationMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });

  return (
    <IntegrationRow>
      <IntegrationInfo>
        <Icon>
          <SlackIcon />
        </Icon>
        <div>
          <Title>Slack integration</Title>
          <Description>
            Be notified about new candidates when they take your Toggl Hire
            skills test.
          </Description>
        </div>
      </IntegrationInfo>
      <Action>
        {canAddSlackIntegration && (
          <DSButton
            variant="secondary"
            size="xsmall"
            onClick={() => {
              canAccessFeature('slack_integration')
                ? addSlackIntegrationMutation.mutate()
                : openUpsellModal('premium_feature');
            }}
          >
            Get started
          </DSButton>
        )}
      </Action>
    </IntegrationRow>
  );
}

const IntegrationRow = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

const IntegrationInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Icon = styled.div`
  width: 46px;
  height: 46px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1rem;
  color: ${props => props.theme.typography.colorPrimary};
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 1rem;
  color: ${props => props.theme.typography.colorSecondary};
`;

const Action = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
`;
