import { useState } from 'react';

export type SortOrder = 'asc' | 'desc';

export const useSort = <SortProperty>(
  initialSortedBy: SortProperty,
  initialSortOrder: SortOrder = 'asc'
) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>(initialSortOrder);
  const [sortedBy, setSortedBy] = useState<SortProperty>(initialSortedBy);

  const sortBy = (property: SortProperty) => {
    if (property === sortedBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedBy(property);
      setSortOrder('asc');
    }
  };

  return { sortedBy, sortOrder, sortBy };
};
