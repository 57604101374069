import * as React from 'react';

export const SignUpPage = React.lazy(() =>
  import('@/pages/authentication/signup-page')
);

export const ForgottenPasswordPage = React.lazy(() =>
  import('@/pages/authentication/forgotten-password-page')
);

export const ResetPasswordPage = React.lazy(() =>
  import('@/pages/authentication/reset-password-page')
);
