import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { Form, Formik, FormikHelpers } from 'formik';
import { object, string } from 'yup';

import { checkClientError } from '@/errors';
import { FormikInputField } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useCreateWorkspaceInvitationMutation } from '@/features/workspace/queries/workspace-invitations';
import { useApiError } from '@/hooks/api';
import { useWorkspaceUsersAndInvitesLimit } from '@/hooks/planLimits';
import { useHistory, useWorkspaceId } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';
import { Role } from '@/types/role';

import { RoleSelect } from './role-select';

//region Main
interface InviteMemberModalProps {
  open: boolean;
}

interface FormValues {
  email: string;
  role: Role;
}

export const InviteMemberModal = (props: InviteMemberModalProps) => {
  const workspaceId = useWorkspaceId();
  const { showFlashMessage } = useFlashMessages();
  const handleApiError = useApiError();
  const history = useHistory();
  const bellowLimit = useWorkspaceUsersAndInvitesLimit();
  const { openUpsellModal } = useUpsellModal();

  const close = () => {
    history.push(`/admin/${workspaceId}/settings/team`);
  };

  const createWorkspaceInvitationMutation = useCreateWorkspaceInvitationMutation(
    {
      onError: () => {},
    }
  );

  const submit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    createWorkspaceInvitationMutation.mutate(
      {
        workspaceId,
        attributes: { ...values },
      },
      {
        onSuccess: () => {
          showFlashMessage({ type: 'invite_sent' });
          close();
        },
        onError: error => {
          actions.setSubmitting(false);

          if (checkClientError(error, 'AlreadyMember')) {
            actions.setFieldError(
              'email',
              'This person has already joined your workspace!'
            );
          } else if (checkClientError(error, 'AlreadyInvited')) {
            actions.setFieldError(
              'email',
              'This person has already been invited.'
            );
          } else {
            handleApiError(error);
          }
        },
      }
    );
  };
  useEffect(() => {
    if (bellowLimit === false) {
      openUpsellModal('workspace_users_limit');
    }
  }, [bellowLimit, openUpsellModal]);
  return bellowLimit ? (
    <DSModal
      fluid
      open={props.open}
      contentStyle={{ width: '590px' }}
      onClose={close}
    >
      <Header>
        Add team member
        <DSModal.CloseButton onClick={close} small fixed />
      </Header>
      <DSModal.Separator />

      <Formik<FormValues>
        initialValues={{ email: '', role: 'admin' }}
        validationSchema={schema}
        onSubmit={submit}
      >
        {form => (
          <Form>
            <Content>
              <EmailInputWrapper data-recording-ignore="mask">
                <FormikInputField
                  name="email"
                  label="Email"
                  required
                  placeholder="example@company.com"
                />
              </EmailInputWrapper>

              <RoleSelect roles={['admin', 'manager']} />
              <Button type="submit" disabled={form.isSubmitting}>
                {form.isSubmitting ? 'Adding...' : 'Add team member'}
              </Button>
            </Content>
          </Form>
        )}
      </Formik>
    </DSModal>
  ) : null;
};
//endregion

//region Utils
const schema = object().shape({
  email: string()
    .required("This can't be blank!")
    .email('Enter a valid email')
    .trim(),
  role: string()
    .ensure()
    .required('Role cannot be empty'),
});
//endregion

//region Styles
const Header = styled.div`
  font-weight: 700;
  font-size: ${16 / 14}rem;
  color: ${props => props.theme.typography.colorPrimary};
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmailInputWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`;

const Button = styled(DSButton)`
  margin-left: auto;
  margin-top: 36px;
`;
//endregion
