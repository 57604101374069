import React, { useMemo } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import {
  isCandidateAnonymizedRecommendation,
  useCandidateQuery,
  useUnlockCandidateMutation,
} from '@/features/candidate';
import { TId, UnlockBanner } from '@/features/common';
import { useJobOpeningQuery } from '@/features/job-opening';
import { useWorkspace, useWorkspaceStatsQuery } from '@/features/workspace';
import { getPlanLimit, getPlanName } from '@/hooks/planLimits';
import {
  useHistory,
  useWorkspaceId,
  useWorkspaceIdOrNull,
} from '@/hooks/router'; //region Main

//region Main
export interface CandidateUnlockBannerProps {
  candidateId: TId;
}

export function CandidateUnlockBanner(props: CandidateUnlockBannerProps) {
  const { data: candidate } = useCandidateQuery(props);
  const mutation = useUnlockCandidateMutation();
  const workspaceId = useWorkspaceIdOrNull();
  const history = useHistory();
  const limits = useLimits();

  if (
    !candidate ||
    candidate.isUnlocked ||
    isCandidateAnonymizedRecommendation(candidate) ||
    limits?.isPremiumPlan ||
    limits?.isJobClosed
  )
    return null;

  return (
    <UnlockWrapper>
      {limits &&
        (limits.unlockableCandidatesRemaining > 0 ? (
          <UnlockBanner
            header="Unlock this candidate"
            disabled={mutation.isLoading}
            unlockLabel={mutation.isLoading ? 'Unlocking...' : 'Unlock ›'}
            onUnlock={() => {
              mutation.mutate(props);
              amplitude?.logEvent('candidates/unlock candidate', {
                'candidate id': props.candidateId,
                'workspace id': workspaceId,
                'unlockable candidates': limits?.unlockableCandidatesRemaining,
              });
            }}
            text={
              <>
                You can unlock{' '}
                {limits.unlockableCandidatesRemaining ===
                limits.unlockableCandidates
                  ? 'up to '
                  : ''}
                {limits.unlockableCandidatesRemaining}{' '}
                {limits.unlockableCandidatesRemaining <
                limits.unlockableCandidates
                  ? 'more'
                  : ''}{' '}
                candidate
                {limits.unlockableCandidatesRemaining > 1 ? 's' : ''} on{' '}
                <PlanName>{limits.planName}</PlanName> plan, or{' '}
                <Link to={`/admin/${workspaceId}/settings/billing`}>
                  upgrade
                </Link>{' '}
                to unlock all candidates and create more skills tests
                {limits.planName === 'free'
                  ? '.'
                  : ' or buy additional candidate credits.'}
              </>
            }
          />
        ) : (
          <UnlockBanner
            header="Limit reached"
            text={
              <>
                You have reached the limit for unlocked candidates on the{' '}
                <PlanName>{limits.planName}</PlanName> plan. Upgrade to unlock
                all candidates and create more tests
                {limits.planName === 'free'
                  ? '.'
                  : ' or buy additional candidate credits.'}
              </>
            }
            unlockLabel="Upgrade ›"
            onUnlock={() =>
              history.push(`/admin/${workspaceId}/settings/billing`)
            }
          />
        ))}
    </UnlockWrapper>
  );
}

//endregion

//region Hooks
const useLimits = () => {
  const workspaceId = useWorkspaceId();
  const workspace = useWorkspace();
  const { data: jobOpening } = useJobOpeningQuery();
  const { data: workspaceStats } = useWorkspaceStatsQuery({ workspaceId });

  return useMemo(() => {
    if (!workspace || !jobOpening || !workspaceStats) {
      return null;
    }

    const planName = getPlanName(workspace.subscriptionPlan);

    return {
      unlockableCandidates: getPlanLimit(
        workspace.subscriptionPlan,
        'unlockable_candidates'
      ),
      unlockableCandidatesRemaining:
        workspaceStats.unlockableTestTakersRemaining,
      planName,
      isPremiumPlan: planName !== 'free' && planName !== 'basic',
      isJobClosed: !!jobOpening.closedAt,
    };
  }, [workspace, jobOpening, workspaceStats]);
};
//endregion

//region Styles
const UnlockWrapper = styled.div`
  border-radius: 8px;
  margin-bottom: 18px;
  box-shadow: 0 1px 8px rgb(0 0 0 / 20%);
  overflow: hidden;
`;

const PlanName = styled.span`
  text-transform: capitalize;
`;
//endregion
