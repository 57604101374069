import React, { useState } from 'react';

import { useBillingEmailQuery, useBillingInfoQuery } from '@/features/billing';

import { BillingDetails, EditBillingDetailsModal } from './ui';
import {
  useBillingInfo,
  useBillingInfoUpdate,
} from './workspace-settings-billing-details.hooks';

export const WorkspaceSettingsBillingDetails = () => {
  const [editingBillingInfo, setEditingBillingInfo] = useState(false);
  const { billingInfoAttributes } = useBillingInfo();
  const { updateBillingInfo, isUpdatingBillingInfo } = useBillingInfoUpdate();

  const { data: billingInfo } = useBillingInfoQuery();

  useBillingEmailQuery();

  if (!billingInfo) return null;

  return (
    <>
      <BillingDetails
        billingInfoAttributes={billingInfoAttributes}
        onChangeClick={() => setEditingBillingInfo(true)}
      />
      <EditBillingDetailsModal
        billingInfoAttributes={billingInfoAttributes}
        onSubmit={async values => {
          await updateBillingInfo(billingInfo, values, () =>
            setEditingBillingInfo(false)
          );
        }}
        submitting={isUpdatingBillingInfo}
        open={editingBillingInfo}
        onClose={() => setEditingBillingInfo(false)}
      />
    </>
  );
};
