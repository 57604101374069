import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { useSmartlookPause } from '@/components/app/Smartlook';
import { TogglHireLogo } from '@/features/common';
import { GoogleRecaptchaPrivacyDisclaimer } from '@/features/google-recaptcha';

import { ellipses } from './ui';

interface WebAuthPageProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  form?: React.ReactNode;
  footer?: React.ReactNode;
  variant?: 'light' | 'dark';
}

export function WebAuthPage({
  title,
  subTitle,
  form,
  footer,
  variant = 'light',
}: WebAuthPageProps) {
  useSmartlookPause();
  const theme = useTheme();

  React.useEffect(() => {
    document.documentElement.style.backgroundColor =
      variant === 'dark' ? theme.colors.purple[100] : theme.colors.peach[20];

    return () => {
      document.documentElement.style.backgroundColor = theme.colors.peach[20];
    };
  }, [theme.colors.peach, theme.colors.purple, variant]);

  return (
    <WebAuthPageContainer>
      <Logo
        href="https://toggl.com/hire/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={TogglHireLogo} alt="Toggl Hire logo" />
      </Logo>
      <Content>
        <Background aria-hidden="true">
          <img src={ellipses[0]} alt="ellipse 1" />
          <img src={ellipses[1]} alt="ellipse 2" />
          <img src={ellipses[2]} alt="ellipse 3" />
        </Background>
        <Body>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          <FormContainer>{form}</FormContainer>
        </Body>

        <Footer>{footer}</Footer>
      </Content>
      <RecaptchaContainer className="web-auth-page__recaptcha-disclaimer">
        <GoogleRecaptchaPrivacyDisclaimer />
      </RecaptchaContainer>
    </WebAuthPageContainer>
  );
}

const WebAuthPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  color: ${({ theme }) => theme.colors.purple[70]};
  min-height: 100vh;
  overflow-y: hidden;
  padding-bottom: 2em;

  @media all and (max-width: 768px) {
    overflow-y: initial;
    height: initial;
  }
`;

const Logo = styled.a`
  padding: 32px;

  & img {
    height: 42px;
  }

  @media screen and (max-height: 750px) and (min-width: 490px) {
    padding-bottom: 10px;
  }
`;

const Content = styled.main`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 48px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 519px;
  padding: 30px 0 40px 0;
  align-self: center;
  position: relative;

  @media all and (max-width: 768px) {
    width: 95%;
    height: 100%;
    margin: 0;
  }

  @media screen and (max-width: 490px) {
    overflow-x: hidden;
  }

  @media screen and (max-height: 750px) and (min-width: 490px) {
    padding-top: 25px;
    padding-bottom: 30px;
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &,
  & > * {
    position: absolute;
    z-index: -1;
  }

  & img:nth-child(1) {
    top: -87.5px;
    left: 77.25%;
  }

  & img:nth-child(2) {
    top: 210px;
    left: -30.5%;
  }

  & img:nth-child(3) {
    top: 62%;
    left: 64.5%;
  }

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const Body = styled.div`
  padding-left: 80px;
  padding-right: 80px;

  & > :empty {
    display: none;
  }

  @media all and (max-width: 768px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media screen and (max-width: 490px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
const Title = styled.header`
  text-align: center;

  & > h1 {
    font-family: 'GT Haptik', sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    color: ${({ theme }) => theme.colors.orange[100]};
    margin: 0;
  }

  & > p {
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    margin-top: 4px;
  }
`;
const SubTitle = styled.div`
  margin-top: 16px;
  text-align: center;
`;

const FormContainer = styled.div`
  margin-top: 20px;
`;
const Footer = styled.footer`
  width: 75%;
  margin: 25px auto 0 auto;
  text-align: center;

  &:empty {
    display: none;
  }

  @media all and (max-width: 768px) {
    position: initial;
    margin-top: 30px;
  }
  @media screen and (max-width: 490px) {
    width: 85%;
  }

  @media screen and (max-height: 750px) and (min-width: 490px) {
    margin-top: 20px;
  }
`;
const RecaptchaContainer = styled.div`
  display: flex;
  margin: 16px auto;
`;
