import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { useCreateCandidateNoteMutation } from '@/features/candidate';
import { TId } from '@/features/common';
import { useJobOpeningIdOrNull, useWorkspaceId } from '@/hooks/router';

//region Main
interface AddNoteButtonProps {
  candidateId: TId;
  note: string;
  userIds: TId[];
  onAdded: () => void;
}

export function AddNoteButton({
  candidateId,
  note,
  userIds,
  onAdded,
}: AddNoteButtonProps) {
  const jobOpeningId = useJobOpeningIdOrNull();
  const mutation = useCreateCandidateNoteMutation();
  const workspaceId = useWorkspaceId();

  return (
    <AddButton
      size="small"
      disabled={mutation.isLoading}
      onClick={async () => {
        if (note) {
          await mutation.mutateAsync({
            note,
            candidateId,
            userIds,
          });

          amplitude?.logEvent('candidates/add note', {
            'workspace id': workspaceId,
            'job opening id': jobOpeningId,
            'candidate id': candidateId,
          });

          onAdded();
        }
      }}
    >
      {mutation.isLoading ? 'Adding...' : 'Add note'}
    </AddButton>
  );
}

//endregion

//region Styles
const AddButton = styled(DSButton)`
  margin: 15px 0 0 auto;

  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
//endregion
