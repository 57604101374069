import React from 'react';
import styled from '@emotion/styled';

import { ConfirmModal } from '@/features/common';
import { IDashboardJobOpening } from '@/features/dashboard';
import {
  IJobOpening,
  useDeleteJobOpeningMutation,
} from '@/features/job-opening';
import { useHistory } from '@/hooks/router';

interface DeleteJobOpeningModalProps {
  open: boolean;
  jobOpening: IJobOpening | IDashboardJobOpening;
  onClose: () => void;
}

export const DeleteJobOpeningModal = ({
  open,
  jobOpening,
  onClose,
}: DeleteJobOpeningModalProps) => {
  const history = useHistory();
  const deleteJobOpeningMutation = useDeleteJobOpeningMutation();

  return (
    <div
      onClick={event => {
        // This is needed because the modal is now inside the JO row on job openings list,
        // and a click on the modal would trigger a click on the row, which would open the JO details
        event.stopPropagation();
      }}
    >
      <ConfirmModal
        open={open}
        action="Delete"
        layer={10000}
        confirmation="delete"
        onConfirm={async () => {
          await deleteJobOpeningMutation.mutateAsync({
            jobOpeningId: jobOpening.id,
          });

          if (/openings\/\d+/.test(history.location.pathname)) {
            history.push('/');
          }
        }}
        onClose={onClose}
        title="You’re about to delete this job opening"
      >
        <SubTitle>Are you sure?</SubTitle>
        This job opening is going to be deleted permanently with no backups.
        Therefore be sure you really wish to do that.
      </ConfirmModal>
    </div>
  );
};

const SubTitle = styled.p`
  margin: 24px 0 12px 0;
  font-weight: 700;

  @media all and (max-width: 768px) {
    margin: 16px 0 24px 0;
  }
`;
