import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useWorkspaceId } from '@/hooks/router';

import {
  createWorkspaceWebhook,
  deleteWorkspacWebhook,
  fetchWorkspaceWebhooks,
  updateWorkspaceWebhook,
} from '../api';

const WORKSPACE_WEBHOOKS_KEY = ['workspace', 'webhooks'];

export const useWorkspaceWebhooksQuery = () => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(WORKSPACE_WEBHOOKS_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries([...WORKSPACE_WEBHOOKS_KEY, workspaceId]),
  });

  return useQuery([...WORKSPACE_WEBHOOKS_KEY, workspaceId], () =>
    fetchWorkspaceWebhooks({ workspaceId })
  );
};

export const useCreateWorkspaceWebhookMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(createWorkspaceWebhook, {
    mutationKey: WORKSPACE_WEBHOOKS_KEY,
    ...(options as any),
  });
};

export const useUpdateWebookMutation = (options?: UseMutationOptions) => {
  return useMutation(updateWorkspaceWebhook, {
    mutationKey: WORKSPACE_WEBHOOKS_KEY,
    ...(options as any),
  });
};

export const useDeleteWebhookMutation = (options?: UseMutationOptions) => {
  return useMutation(deleteWorkspacWebhook, {
    mutationKey: WORKSPACE_WEBHOOKS_KEY,
    ...(options as any),
  });
};
