import api from '@/api/api';
import { TId } from '@/features/common';
import { CandidateTag } from '@/types/candidateTag';
import { parseAPIData } from '@/utils/parsers';

export async function fetchTagsByJobOpening(
  jobOpeningId: TId
): Promise<CandidateTag[]> {
  const response = await api.get(`/openings/${jobOpeningId}/tags`);
  return parseAPIData(response.data?.tags);
}
