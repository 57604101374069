import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
// @ts-expect-error FIXME: aliased package has some issues in TS
import { format, isToday } from 'date-fns2';

import { Icon } from '@/features/common';

interface NextRefreshProps {
  date: Date;
}

export function NextRefresh({ date }: NextRefreshProps) {
  return (
    <Container>
      <p>
        <Icon icon={regular('hourglass-clock')} /> Recommendations renew{' '}
        {isToday(date) ? 'later today' : `on ${format(date, 'LLL d')}`}
      </p>
    </Container>
  );
}

const Container = styled.section`
  background: ${({ theme }) => theme.colors.purple[5]};
  border-radius: 8px;
  height: 32px;
  padding: 8px 20px;

  p {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }
`;
