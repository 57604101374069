import React, { useCallback } from 'react';
import { DSCheckbox, DSInlineField } from '@hundred5/design-system';

import {
  TFilterType,
  useCandidateSearchParams,
} from '@/features/candidate-search';

export const ACTION_REQUIRED_FILTER_TYPE: TFilterType = 'actionRequired';

export function ActionRequiredFilter() {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();

  const value = candidatesSearchParams[ACTION_REQUIRED_FILTER_TYPE];

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        addSearchParams({ [ACTION_REQUIRED_FILTER_TYPE]: 'yes' });
      } else {
        removeSearchParam(ACTION_REQUIRED_FILTER_TYPE);
      }
    },
    [addSearchParams, removeSearchParam]
  );

  return (
    <DSInlineField for={ACTION_REQUIRED_FILTER_TYPE} label="Action required">
      <DSCheckbox
        id={ACTION_REQUIRED_FILTER_TYPE}
        onChange={handleChange}
        checked={value === 'yes'}
      />
    </DSInlineField>
  );
}
