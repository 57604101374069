import { useEffect, useState } from 'react';

import {
  TSortOrder,
  useCandidateSearchParams,
} from '@/features/candidate-search';
import { getOppositeSortDirection } from '@/features/common/utils';

export const CUSTOM_SORTS: TSortOrder[] = ['stars', 'notes', 'flags', 'tags'];

export const useSort = () => {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
  } = useCandidateSearchParams();
  const sortOrder = candidatesSearchParams.sortOrder;
  const sortDirection = candidatesSearchParams.sortDirection;

  const [customSort, setCustomSort] = useState<TSortOrder>(
    sortOrder && CUSTOM_SORTS.includes(sortOrder) ? sortOrder : 'stars'
  );

  const setSort = (order: TSortOrder) =>
    addSearchParams({
      sortOrder: order,
      sortDirection:
        sortOrder === order ? getOppositeSortDirection(sortDirection!) : 'asc',
    });

  useEffect(() => {
    if (sortOrder && CUSTOM_SORTS.includes(sortOrder)) {
      setCustomSort(sortOrder);
    }
  }, [sortOrder, setCustomSort]);

  return { customSort, sortOrder, sortDirection, setSort };
};
