import styled from '@emotion/styled';

export const MenuItem = styled.li<{
  destructive?: boolean;
  isActive?: boolean;
}>`
  button {
    align-items: center;
    display: flex;
    gap: 8px;
    padding: 4px 12px;
    text-align: left;
    width: 100%;
    color: ${({ theme }) => theme.colors.purple[100]};
    background: ${({ theme, isActive }) =>
      isActive ? theme.colors.purple[5] : 'transparent'};

    & svg path {
      fill: ${({ theme }) => theme.colors.purple[60]};
    }

    &:hover {
      background: ${({ theme, destructive }) =>
        destructive ? theme.colors.red[10] : theme.colors.purple[5]};
      color: ${({ theme, destructive }) =>
        destructive ? theme.colors.red[100] : 'initial'};
      & svg path {
        fill: ${({ theme, destructive }) =>
          destructive ? theme.colors.red[100] : theme.colors.purple[60]};
      }
    }
  }
`;
