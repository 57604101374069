import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';
import { useWorkspaceId } from '@/hooks/router';

export function EvaluationButtonsFiveOptions({
  score,
  isLoading,
  onClick,
}: {
  score: number | null;
  isLoading: boolean;
  onClick: (score: number | null) => void;
}) {
  const workspaceId = useWorkspaceId();

  const onRatingClick = (rating: number | null) => {
    onClick(rating);
    amplitude?.logEvent('evaluated answer', {
      'workspace id': workspaceId,
    });
  };
  return (
    <EvaluationButtonRow>
      <EvaluationButton
        evaluationType="incorrect"
        active={score === 0}
        onClick={() => onRatingClick(0)}
        disabled={isLoading}
        data-rh="0%"
        data-rh-at="bottom"
      >
        <Icon icon={regular('face-disappointed')} fontSize="16px" />
        Incorrect
      </EvaluationButton>
      <EvaluationButton
        evaluationType="weak"
        active={score === 25}
        onClick={() => onRatingClick(25)}
        disabled={isLoading}
        data-rh="25%"
        data-rh-at="bottom"
      >
        <Icon icon={regular('face-frown-slight')} fontSize="16px" />
        Weak
      </EvaluationButton>
      <EvaluationButton
        evaluationType="average"
        active={score === 50}
        onClick={() => onRatingClick(50)}
        disabled={isLoading}
        data-rh="50%"
        data-rh-at="bottom"
      >
        <Icon icon={regular('face-meh')} fontSize="16px" />
        Average
      </EvaluationButton>
      <EvaluationButton
        evaluationType="good"
        active={score === 75}
        onClick={() => onRatingClick(75)}
        disabled={isLoading}
        data-rh="75%"
        data-rh-at="bottom"
      >
        <Icon icon={regular('face-smile')} fontSize="16px" />
        Good
      </EvaluationButton>
      <EvaluationButton
        evaluationType="excellent"
        active={score === 100}
        onClick={() => onRatingClick(100)}
        disabled={isLoading}
        data-rh="100%"
        data-rh-at="bottom"
      >
        <Icon icon={regular('face-grin-beam')} fontSize="16px" />
        Excellent
      </EvaluationButton>
      {score !== null && (
        <EvaluationButtonClear
          onClick={() => onRatingClick(null)}
          disabled={isLoading}
        >
          Clear rating
        </EvaluationButtonClear>
      )}
    </EvaluationButtonRow>
  );
}

const EvaluationButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @media (min-width: 768px) {
    align-items: center;
    gap: 12px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const EvaluationButton = styled.button<{
  evaluationType: 'incorrect' | 'weak' | 'average' | 'good' | 'excellent';
  active: boolean;
}>`
  align-items: center;
  border-radius: 8px;
  background: ${({ theme, evaluationType, active }) => {
    if (!active) {
      return theme.colors.white;
    }

    switch (evaluationType) {
      case 'incorrect':
      case 'weak':
        return theme.colors.red[10];
      case 'average':
        return theme.colors.yellow[10];
      case 'good':
      case 'excellent':
        return theme.colors.green[10];
    }
  }};
  border-color: ${({ theme, evaluationType, active }) => {
    if (!active) {
      return theme.colors.purple[40];
    }

    switch (evaluationType) {
      case 'incorrect':
      case 'weak':
        return theme.colors.red[100];
      case 'average':
        return theme.colors.yellow[120];
      case 'good':
      case 'excellent':
        return theme.colors.green[100];
    }
  }};
  border-style: solid;
  border-width: 1px;
  color: ${({ theme, evaluationType, active }) => {
    if (!active) {
      return theme.typography.colorPrimary;
    }

    switch (evaluationType) {
      case 'incorrect':
      case 'weak':
        return theme.colors.red[100];
      case 'average':
        return theme.colors.yellow[120];
      case 'good':
      case 'excellent':
        return theme.colors.green[100];
    }
  }};
  cursor: pointer;
  display: flex;
  font-weight: 500;
  height: 36px;
  padding: 0 32px 0 24px;
  gap: 8px;

  & > svg path {
    fill: ${({ theme, evaluationType, active }) => {
      if (!active) {
        return theme.typography.colorPrimary;
      }

      switch (evaluationType) {
        case 'incorrect':
        case 'weak':
          return theme.colors.red[100];
        case 'average':
          return theme.colors.yellow[120];
        case 'good':
        case 'excellent':
          return theme.colors.green[100];
      }
    }};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.purple[5]};
    border: 1px solid ${({ theme }) => theme.colors.purple[40]};
    color: ${({ theme }) => theme.typography.colorPrimary};

    & > svg path {
      fill: ${({ theme }) => theme.typography.colorPrimary};
    }
  }

  &[disabled] {
    cursor: default;
  }
`;

const EvaluationButtonClear = styled.button`
  align-items: center;
  background: none;
  border: none;
  border-radius: 3px;
  color: ${({ theme }) => theme.typography.colorSecondary};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  padding: 0 4px;

  &:hover {
    background: ${({ theme }) => theme.colors.purple[10]};
  }
`;
