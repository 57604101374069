import React from 'react';
import styled from '@emotion/styled';

export function DemoBadge() {
  return <DemoBadgeContainer>Demo</DemoBadgeContainer>;
}

const DemoBadgeContainer = styled.div`
  background: ${props => props.theme.colors.peach[100]};
  border-radius: 6px;
  color: ${props => props.theme.colors.orange[100]};
  user-select: none;
  font-size: 1rem;
  padding: 6px 12px;
`;
