import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import formatDate from 'date-fns/format';

import {
  ECancelSubscribeStep,
  ICancelModalStepProps,
  useSubscriptionQuery,
} from '@/features/billing';

import {
  CancelModalContentContainer,
  CancelModalFooterButtons,
  CancelModalHeader,
} from '.';

export const ConfirmCancelStepModal = ({
  open,
  setCurrentStep,
}: ICancelModalStepProps) => {
  const { data: billingAccount } = useSubscriptionQuery();
  const subscriptionEnd =
    billingAccount?.subscription.currentPeriodEndsAt &&
    formatDate(billingAccount.subscription.currentPeriodEndsAt, 'MMM D, YYYY');

  return (
    <>
      <DSModal
        fluid
        open={open}
        onClose={() => setCurrentStep(undefined)}
        contentStyle={{ width: '640px' }}
      >
        <div>
          <CancelModalHeader>
            You’re about to cancel your subscription
            <DSModal.CloseButton
              fixed
              small
              onClick={() => setCurrentStep(undefined)}
            />
          </CancelModalHeader>
          <DSModal.Separator />
          <CancelModalContentContainer data-testid="cancel-subscription-confirm-cancel-modal">
            {`After cancelling your subscription it will continue until it expires
            on ${subscriptionEnd}. After that, all your jobs will be automatically
            closed and access to candidates will be locked.`}
            <SubTitle>Are you sure?</SubTitle>
            <DSModal.Footer>
              <CancelModalFooterButtons>
                <DSButton
                  variant="secondary"
                  type="button"
                  onClick={() =>
                    setCurrentStep(ECancelSubscribeStep.FinalCancel)
                  }
                >
                  Cancel subscription
                </DSButton>
                <DSButton
                  variant="primary"
                  type="button"
                  onClick={() => setCurrentStep(undefined)}
                >
                  Stay on current plan
                </DSButton>
              </CancelModalFooterButtons>
            </DSModal.Footer>
          </CancelModalContentContainer>
        </div>
      </DSModal>
    </>
  );
};

const SubTitle = styled.div`
  font-weight: 700;
  margin-top: 24px;
`;
