import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import styled from '@emotion/styled';

export function CandidatesList({ children, categoryId }) {
  const { setNodeRef, isOver, active, over } = useDroppable({
    id: categoryId,
    data: { type: 'category' },
  });

  /**
   * Determine if the placeholder should be shown. It shouldn't be shown if:
   * - dragged candidate is over their own category
   * - dragged item is another pipeline stage
   */
  const showPlaceholder =
    isOver &&
    active?.data?.current?.type !== 'pipeline-stage' &&
    active?.data?.current?.candidate?.categoryId !== over?.id;

  return (
    <Container ref={setNodeRef}>
      {showPlaceholder && <DragPlaceholder />}
      {children}
    </Container>
  );
}

const Container = styled.div`
  .virtualized-infinite-scroll {
    --scrollbar-foreground: ${({ theme }) => theme.colors.purple[20]};
    --scrollbar-background: transparent;

    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
    scrollbar-gutter: stable;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-foreground);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-background);
    }
  }
`;

const DragPlaceholder = styled.div`
  background: ${({ theme }) => theme.colors.peach[80]};
  border-radius: 8px;
  border-top: 4px solid ${({ theme }) => theme.colors.orange[100]};
  height: 88px;
  margin-left: 8px;
  margin-bottom: 8px;
  width: 276px;
`;
