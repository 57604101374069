import styled from '@emotion/styled';

export const Message = styled.pre`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-line;
  margin: 16px 0 0;
  padding-bottom: 24px;
`;
