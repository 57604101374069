import React from 'react';
import styled from '@emotion/styled';
import { Route, Switch } from 'react-router';

import { useWorkspaceTreatments } from '@/components/splits';
import { NavigationMenu } from '@/features/common';
import { useJobOpeningQuery } from '@/features/job-opening';

export function JobOpeningMenuSecondary() {
  const jobOpeningQuery = useJobOpeningQuery();

  const { fe_admin_app_job_description } = useWorkspaceTreatments([
    'fe_admin_app_job_description',
  ]);
  const isJobDescriptionEnabled =
    fe_admin_app_job_description.treatment === 'on';

  return jobOpeningQuery.isSuccess ? (
    <Switch>
      <Route
        path="/admin/:workspace/openings/:opening/:page(analytics|satisfaction)"
        render={() => (
          <Navigation>
            <NavigationMenu.Item
              label="Analytics"
              to={`/admin/${jobOpeningQuery.data.workspaceId}/openings/${jobOpeningQuery.data.id}/analytics`}
            />
            <NavigationMenu.Item
              label="Candidate feedback"
              to={`/admin/${jobOpeningQuery.data.workspaceId}/openings/${jobOpeningQuery.data.id}/satisfaction`}
            />
          </Navigation>
        )}
      />

      {isJobDescriptionEnabled && (
        <>
          <Route
            path="/admin/:workspace/openings/:opening/:page(job-description|application-form)"
            render={() => (
              <Navigation>
                <NavigationMenu.Item
                  label="1. Job description"
                  to={`/admin/${jobOpeningQuery.data.workspaceId}/openings/${jobOpeningQuery.data.id}/job-description`}
                />
                <NavigationMenu.Item
                  label="2. Application form"
                  to={`/admin/${jobOpeningQuery.data.workspaceId}/openings/${jobOpeningQuery.data.id}/application-form`}
                />
              </Navigation>
            )}
          />
        </>
      )}
    </Switch>
  ) : null;
}

const Navigation = styled(NavigationMenu)`
  height: 30px;
  align-content: flex-end;
`;
