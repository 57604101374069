import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock, DSGrid } from '@hundred5/design-system';

import { useWorkspaceTreatments } from '@/components/splits';
import { ConfirmModal } from '@/features/common';
import {
  Delimiter,
  GreenhouseIcon,
  IATSIntegration,
  useAddATSIntegrationMutation,
  useATSIntegrationsQuery,
  useDeleteATSIntegrationMutation,
  WorkableIcon,
} from '@/features/integrations';
import { useWorkspacePermission } from '@/features/permissions';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

import {
  WorkspaceSettingsIntegrationAction,
  WorkspaceSettingsIntegrationContainer,
  WorkspaceSettingsIntegrationDescription,
  WorkspaceSettingsIntegrationIcon,
  WorkspaceSettingsIntegrationTitle,
} from '../workspace-settings-integrations-page';

const integrationTypes = {
  workable: {
    name: 'Workable',
    icon: <WorkableIcon />,
  },
  greenhouse: {
    name: 'Greenhouse',
    icon: <GreenhouseIcon />,
  },
};

export const WorkspaceSettingsIntegrationATS = () => {
  const { data: atsIntegrations } = useATSIntegrationsQuery();
  const { fe_admin_app_integration_enabled } = useWorkspaceTreatments([
    'fe_admin_app_integration_enabled',
  ]);
  const isATSIntegrationsEnabled =
    fe_admin_app_integration_enabled.treatment === 'on';

  const integrationTypesData = useMemo(() => {
    const findIntegration = (type: string): IATSIntegration | undefined =>
      atsIntegrations?.find(item => item.integrationType === type);

    return {
      workable: {
        id: findIntegration('workable')?.id ?? null,
        name: 'Workable',
        jobOpeningsNumber: findIntegration('workable')?.jobOpenings ?? null,
      },
      greenhouse: {
        id: findIntegration('greenhouse')?.id ?? null,
        name: 'Greenhouse',
        jobOpeningsNumber: findIntegration('greenhouse')?.jobOpenings ?? null,
      },
    };
  }, [atsIntegrations]);

  if (!isATSIntegrationsEnabled) {
    return null;
  }

  return (
    <DSContentBlock>
      <DSContentBlock.Title>ATS Integrations</DSContentBlock.Title>
      <DSContentBlock.Content>
        <DSGrid gap="16px">
          <ATSElement
            atsIntegration={{
              ...integrationTypesData['greenhouse'],
              integrationType: 'greenhouse',
              jobOpenings: integrationTypesData['greenhouse'].jobOpeningsNumber,
            }}
            icon={integrationTypes['greenhouse'].icon}
          />
          <Delimiter />
          <ATSElement
            atsIntegration={{
              ...integrationTypesData['workable'],
              integrationType: 'workable',
              jobOpenings: integrationTypesData['workable'].jobOpeningsNumber,
            }}
            icon={integrationTypes['workable'].icon}
          />
        </DSGrid>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

interface ATSElementProps {
  icon: JSX.Element;
  atsIntegration: {
    id: string | null;
    integrationType: string;
    jobOpenings: number | null;
    name: string;
  };
}

const ATSElement = ({ icon, atsIntegration }: ATSElementProps) => {
  const { openUpsellModal } = useUpsellModal();
  const canAccessFeature = usePlanLimits();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const canAddATSIntegration = useWorkspacePermission()(
    'ats integration',
    'create'
  );
  const canRemoveATSIntegration = useWorkspacePermission()(
    'ats integration',
    'delete'
  );

  const {
    mutate: addATSIntegration,
    isLoading: addingATSIntegration,
  } = useAddATSIntegrationMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });
  const {
    mutate: removeATSIntegration,
    isLoading: removingATSIntegration,
  } = useDeleteATSIntegrationMutation();

  return (
    <>
      <WorkspaceSettingsIntegrationContainer>
        <WorkspaceSettingsIntegrationIcon>
          {icon}
        </WorkspaceSettingsIntegrationIcon>
        <IntegrationInfo>
          <WorkspaceSettingsIntegrationTitle>
            {atsIntegration.name} integration
          </WorkspaceSettingsIntegrationTitle>
          <WorkspaceSettingsIntegrationDescription>
            Send candidates who apply through Toggl Hire to your{' '}
            {atsIntegration.name} jobs.
          </WorkspaceSettingsIntegrationDescription>
          {atsIntegration.jobOpenings != null && (
            <Info>
              {atsIntegration.jobOpenings}{' '}
              {atsIntegration?.jobOpenings < 2 ? 'job' : 'jobs'} connected
            </Info>
          )}
        </IntegrationInfo>
        <WorkspaceSettingsIntegrationAction>
          {atsIntegration.jobOpenings != null && !!atsIntegration.id ? (
            <DSButton
              variant="secondary"
              size="big"
              disabled={!canRemoveATSIntegration || removingATSIntegration}
              onClick={() =>
                canRemoveATSIntegration &&
                !removingATSIntegration &&
                setOpenConfirmModal(true)
              }
            >
              {removingATSIntegration ? 'Removing...' : 'Remove'}
            </DSButton>
          ) : (
            <DSButton
              variant="secondary"
              size="big"
              data-rh={
                !canAddATSIntegration
                  ? 'Only admins can add integrations'
                  : null
              }
              disabled={!canAddATSIntegration || addingATSIntegration}
              onClick={() => {
                canAccessFeature('ats_integrations')
                  ? addATSIntegration(
                      atsIntegration.integrationType as
                        | 'greenhouse'
                        | 'workable'
                    )
                  : openUpsellModal('premium_feature');
              }}
            >
              Get started
            </DSButton>
          )}
        </WorkspaceSettingsIntegrationAction>
      </WorkspaceSettingsIntegrationContainer>
      <ConfirmModal
        open={openConfirmModal}
        action="Remove the integration"
        onConfirm={() => {
          removeATSIntegration({ atsIntegrationId: atsIntegration.id! });
          setOpenConfirmModal(false);
        }}
        onClose={() => setOpenConfirmModal(false)}
        title={`Remove ${atsIntegration.name} integration`}
        footerVariant="destructive-primary"
      >
        <p>{`Are you sure you want to remove your integration with ${atsIntegration.name}?`}</p>

        <p>
          {`You have ${atsIntegration.jobOpenings} number of jobs connected and you will no longer sync
          candidates for these jobs.`}
        </p>
      </ConfirmModal>
    </>
  );
};

const IntegrationInfo = styled.div``;

const Info = styled.div`
  color: ${props => props.theme.colors.purple[100]};
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;
`;
