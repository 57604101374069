import * as React from 'react';
import styled from '@emotion/styled';

import { FormikRadioField } from '@/features/common';
import { Role } from '@/types/role';

//region Main
type RoleSelectProps = {
  roles: Role[];
  selected?: Role | null;
};

export const RoleSelect = ({ roles, selected }: RoleSelectProps) => (
  <RoleSelectContainer>
    {roles.map(role => (
      <FormikRadioField
        key={role}
        name="role"
        label={<RoleLabel role={role} />}
        value={role}
        checked={selected ? role === selected : undefined}
      />
    ))}
  </RoleSelectContainer>
);
//endregion

//region Components
const RoleLabel = ({ role }: { role: Role }) => (
  <RoleLabelContainer>
    {ROLE_NAMES[role]}
    <Description>{ROLE_DESCRIPTIONS[role]}</Description>
  </RoleLabelContainer>
);
//endregion

//region Styles
const RoleSelectContainer = styled.article`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Description = styled.div`
  font-weight: 400;
`;

const RoleLabelContainer = styled.div`
  color: ${props => props.theme.colors.purple[100]};
  font-weight: 600;
`;
//endregion

//region Utils
const ROLE_NAMES = {
  'hundred5-support': 'Hundred5 Support',
  owner: 'Owner',
  admin: 'Admin',
  manager: 'Manager',
  viewer: 'Viewer',
};

const ROLE_DESCRIPTIONS = {
  'hundred5-support': 'Can access and do everything.',
  owner: 'Can access and do everything. Workspace can have one owner only.',
  admin:
    'Can do everything except edit billing and workspace information, export information about other users, give owners rights.',
  manager: 'Can export candidates and see results.',
  viewer: 'Allows a member to view reports in the workspace.',
};
//endregion
