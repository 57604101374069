import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { FormikInputField, PromptIfDirty } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  ITest,
  useTestPage,
  useTestPageAnalytics,
  useUpdateTestMutation,
} from '@/features/test';

interface Settings {
  name: string;
}

const validationSchema = object().shape({
  name: string().required("This can't be blank!"),
});

export const VideoIntroSettings = () => {
  useTestPageAnalytics('video intro settings');
  const { showFlashMessage } = useFlashMessages();
  const canUpdate = useJobOpeningPermission()('test', 'update');
  const { test } = useTestPage();
  const updateTestMutation = useUpdateTestMutation();

  const initialValues: Settings = {
    name: test.name || '',
  };

  const handleSubmit = async (values: Settings, { resetForm }) => {
    if (!updateTestMutation.isLoading) {
      await updateTestMutation.mutateAsync(
        {
          testId: test.id,
          test: { name: values.name },
        },
        {
          onSuccess: (data: ITest) => {
            resetForm({ values: { name: data.name } });
            showFlashMessage({ type: 'saved' });
          },
        }
      );
    }
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <PromptIfDirty />
          <DSContentBlock>
            <DSContentBlock.Title>General</DSContentBlock.Title>

            <DSContentBlock.Content>
              <NameInputField
                name="name"
                label="Test title"
                required
                errorAbsolute
              />
            </DSContentBlock.Content>

            <DSContentBlock.Footer>
              <DSButton
                type="submit"
                disabled={!canUpdate || !dirty || isSubmitting}
                data-rh={!canUpdate ? 'Only admins can save changes' : null}
              >
                {isSubmitting ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
};

const NameInputField = styled(FormikInputField)`
  max-width: 312px;
`;
