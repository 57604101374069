import React from 'react';
import styled from '@emotion/styled';

interface CandidateInformationProps {
  name: string;
  country?: string;
}

export const CandidateInformation = ({
  name,
  country,
}: CandidateInformationProps) => {
  return (
    <CandidateInformationContainer>
      <Name>{name}</Name>
      <Address>{country && country}</Address>
    </CandidateInformationContainer>
  );
};

const CandidateInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Name = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple[100]};
  line-height: 1.2;
`;

const Address = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.purple[100]};
`;
