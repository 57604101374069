import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { loggedIn } from '@/actions/authentication';
import { setAuthToken } from '@/api/api';
import { storeAuthToken } from '@/authentication';
import { AuthenticationToken } from '@/types/authentication';

type SaveAuthTokenHook = (token: AuthenticationToken) => void;

export function useSaveAuthToken(): SaveAuthTokenHook {
  const dispatch = useDispatch();

  return useCallback(
    (token: AuthenticationToken) => {
      storeAuthToken(token);
      setAuthToken(token);

      dispatch(loggedIn());
    },
    [dispatch]
  );
}
