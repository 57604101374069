import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon, SemanticButton } from '@/features/common';
import {
  useRecommendationSettingsQuery,
  useRecommendedCandidatesQuery,
} from '@/features/job-opening';
import { usePreferenceMutation, usePreferencesQuery } from '@/features/me';
import { useJobOpeningId } from '@/hooks/router';

import {
  BetaTag,
  ExplanatoryText,
  Feedback,
  Filter,
  NextRefresh,
  NoRecommendations,
  Recommendations,
} from './ui';

export function TalentPool() {
  const jobOpeningId = useJobOpeningId();
  const recommendedCandidatesQuery = useRecommendedCandidatesQuery({
    jobOpeningId,
  });
  const preferencesQuery = usePreferencesQuery();
  const preferenceMutation = usePreferenceMutation();
  const { data: recommendationSettings } = useRecommendationSettingsQuery({
    jobOpeningId,
  });

  if (!recommendedCandidatesQuery.isSuccess || !preferencesQuery.isSuccess) {
    return null;
  }

  const collapsed =
    preferencesQuery.data.closedRecommendedCandidatesInJobOpenings?.[
      jobOpeningId
    ] ?? true;

  const handleToggleCollapse = function(collapsed: boolean) {
    preferenceMutation.mutate({
      name: 'closedRecommendedCandidatesInJobOpenings',
      value: {
        ...preferencesQuery.data.closedRecommendedCandidatesInJobOpenings,
        [jobOpeningId]: collapsed,
      },
    });
  };

  if (collapsed) {
    return (
      <CollapsedWrapperButton onClick={() => handleToggleCollapse(false)}>
        <ColumnCollapsed>
          <Icon icon={regular('chevron-right')} color="purple-100" size="xs" />
        </ColumnCollapsed>
      </CollapsedWrapperButton>
    );
  }

  return (
    <Column>
      <Header>
        <Title>
          Talent Pool <BetaTag>Beta</BetaTag>
        </Title>
        <CloseButton onClick={() => handleToggleCollapse(true)}>
          <Icon icon={regular('chevron-left')} size="xs" />
        </CloseButton>
      </Header>

      <Filter settings={recommendationSettings} />

      {recommendedCandidatesQuery.data.nextRefreshAt != null ? (
        <ExplanatoryText
          nextRefresh={recommendedCandidatesQuery.data.nextRefreshAt}
          allRecommendationsUsed={
            recommendedCandidatesQuery.data.candidates?.length === 0
          }
        />
      ) : (
        <NoRecommendations />
      )}
      {(recommendedCandidatesQuery.data.candidates?.length ?? 0) > 0 ? (
        <Recommendations
          candidates={recommendedCandidatesQuery.data.candidates ?? []}
        />
      ) : null}
      {recommendedCandidatesQuery.data.nextRefreshAt != null ? (
        <NextRefresh
          date={recommendedCandidatesQuery.data.nextRefreshAt || new Date()}
        />
      ) : null}

      <Feedback />
    </Column>
  );
}

const CollapsedWrapperButton = styled(SemanticButton)`
  flex-shrink: 0;
  width: 36px;
`;

const ColumnBase = styled.article`
  background: ${({ theme }) => theme.colors.purple[10]};
  border-radius: 8px;
  padding: 12px 8px;
  flex-shrink: 0;
`;

const ColumnCollapsed = styled(ColumnBase)`
  display: flex;
  flex-flow: column nowrap;
  gap: 18px;
  height: 100%;
`;

const Column = styled(ColumnBase)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 292px;
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  gap: 4px;
  height: 20px;
  padding-left: 12px;

  h2 {
    color: ${({ theme }) => theme.colors.purple[100]};
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    margin: 0;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CloseButton = styled(SemanticButton)`
  margin-left: auto;
  padding: 4px 8px;
`;
