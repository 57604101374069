import formatDate from 'date-fns/format';
import truncate from 'lodash/truncate';

import {
  ICandidateHistoryTag,
  TCandidateHireState,
} from '@/features/candidate';

export function formatContactInfoField(field: string) {
  field = field.toLowerCase();
  switch (field) {
    case 'email':
      break;
    case 'contactemail':
      return 'contact email';
    case 'fullname':
      return 'name';
    case 'lastname':
      return 'last name';
    case 'firstname':
      return 'first name';
    case 'city':
      return 'city';
    case 'country':
      return 'country';
    case 'phone':
      return 'phone number';
    case 'linkedin':
      return 'LinkedIn';
    case 'website':
      return 'URL';

    default:
      return field;
  }
}

export const percentFormat = new Intl.NumberFormat('en', { style: 'percent' });

export function formatScore(percentage: number) {
  return percentFormat.format(Math.floor(percentage) / 100);
}

export function getStateChangeTitle(
  from: TCandidateHireState,
  to: TCandidateHireState
): string | null {
  if (to === 'archived') return 'Candidate archived';
  if (to === 'rejected') return 'Candidate rejected';
  if (from === 'archived' || from === 'rejected') return 'Candidate restored';
  return null;
}

export function formatTagName(tag: ICandidateHistoryTag) {
  return truncate(tag.name, { length: 30 });
}

export const formatTimestamp = (date: Date) =>
  formatDate(date, 'MMM D, YYYY hh:mmA');
