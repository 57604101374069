import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  deleteZapierApiKey,
  fetchZapierApiKeys,
  generateZapierApiKey,
} from '@/features/integrations';
import { useWorkspaceId } from '@/hooks/router';

const ZAPIER_INTEGRATION_KEY = ['zapier-integration'];

export const useZapierApiKeysQuery = (options?: UseQueryOptions) => {
  const queryClient = useQueryClient();

  queryClient.setMutationDefaults(ZAPIER_INTEGRATION_KEY, {
    onSuccess: () => queryClient.invalidateQueries([...ZAPIER_INTEGRATION_KEY]),
  });

  return useQuery([...ZAPIER_INTEGRATION_KEY], fetchZapierApiKeys, {
    ...(options as any),
  });
};

export const useGenerateZapierApiKeyMutation = (
  options?: UseMutationOptions
) => {
  const workspaceId = useWorkspaceId();

  return useMutation(() => generateZapierApiKey({ workspaceId }), {
    mutationKey: ZAPIER_INTEGRATION_KEY,
    ...(options as any),
  });
};

export const useDeleteZapierApiKeyMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();

  return useMutation(() => deleteZapierApiKey({ workspaceId }), {
    mutationKey: ZAPIER_INTEGRATION_KEY,
    ...(options as any),
  });
};
