import React from 'react';
import styled from '@emotion/styled';
import { CandidateAnswer } from '@hundred5/design-system';

import Markdown from '@/components/markdown/Markdown';
import { ICandidateAnswer } from '@/features/candidate';
import { ITestQuestion } from '@/features/test';

//region Main
export interface ChoiceAnswerProps {
  question: ITestQuestion;
  answer?: ICandidateAnswer;
}

export function ChoiceAnswer({ question, answer }: ChoiceAnswerProps) {
  return (
    <CandidateAnswer.ChoiceAnswer
      type={question.questionType === 'picture-question' ? 'picture' : 'text'}
    >
      {question.options?.map(option => {
        const selected = !!answer?.chosenOptions?.find(
          o => o.questionOptionId === option.id
        );
        const correct = option.points > 0;

        return question.questionType === 'picture-question' ? (
          <CandidateAnswer.PictureChoice
            key={option.id}
            url={option.content}
            selected={selected}
            correct={correct}
          />
        ) : (
          <CandidateAnswer.TextChoice
            key={option.id}
            type={
              question.questionType === 'single-choice' ? 'single' : 'multi'
            }
            selected={selected}
            correct={correct}
            content={<StyledMarkdown source={option.content} />}
          />
        );
      })}
    </CandidateAnswer.ChoiceAnswer>
  );
}
//endregion

//region Styles
const StyledMarkdown = styled(Markdown)`
  & > p:first-child {
    margin-top: 0;
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`;
//endregion
