import { IWorkspaceCandidate } from '@/features/workspace';

export const dummyCandidates: IWorkspaceCandidate[] = [
  {
    id: 'dummy-1',
    createdAt: new Date(),
    latestTestFinishedAt: new Date(),
    firstName: 'James',
    lastName: 'Brown',
    fullName: 'James Brown',
    email: 'james.brown@email.com',
    name: 'James',
    hasNotes: false,
    suspicious: false,
    rating: 0,
    latestScorePercent: 21,
    latestPercentToPass: 80,
    jobOpenings: [
      {
        id: '1111',
        name: 'Digital and Social Marketing Manager',
        visibility: 'workspace',
      },
      {
        id: '1112',
        name: 'Social Network Manager Manager',
        visibility: 'workspace',
      },
      { id: '1113', name: 'Press Relations Manager', visibility: 'workspace' },
    ],
    tags: null,
    country: 'France',
    tests: [
      {
        id: 1111111,
        overallScoreAsPercents: 21,
        originalPercentToPass: 80,
        percentToPass: 80,
        state: 'completed',
        testId: 1111111,
        testName: 'skills test',
      },
      {
        id: 1111112,
        overallScoreAsPercents: 98,
        originalPercentToPass: 90,
        percentToPass: 90,
        state: 'completed',
        testId: 1111112,
        testName: 'video intro',
      },
      {
        id: 1111112,
        overallScoreAsPercents: 0,
        originalPercentToPass: 90,
        percentToPass: 90,
        state: 'completed',
        testId: 1111112,
        testName: 'homework',
      },
      {
        id: 1111112,
        overallScoreAsPercents: 0,
        originalPercentToPass: 90,
        percentToPass: 90,
        state: 'invited',
        testId: 1111112,
        testName: 'interview',
      },
    ],
    testTakerCategoryId: 'Skills Test',
    isUnlocked: true,
  },
  {
    id: 'dummy-2',
    createdAt: new Date(),
    latestTestFinishedAt: new Date(),
    firstName: 'Cameron',
    lastName: 'Williamson',
    fullName: 'Cameron Williamson',
    email: 'cameron.williamson@email.com',
    name: 'Cameron',
    hasNotes: true,
    suspicious: false,
    rating: 4,
    latestScorePercent: 72,
    latestPercentToPass: 80,
    jobOpenings: [
      { id: '2221', name: 'Frontend Developer', visibility: 'workspace' },
      { id: '2222', name: 'Javascript Developer', visibility: 'workspace' },
      { id: '2223', name: 'HTML Developer', visibility: 'workspace' },
    ],
    tags: [
      { id: 't2221', name: 'Maybe' },
      { id: 't2222', name: 'Europe' },
      { id: 't2223', name: 'Remote' },
    ],
    country: 'Belgium',
    tests: [
      {
        id: 2222221,
        overallScoreAsPercents: 72,
        originalPercentToPass: 80,
        percentToPass: 80,
        state: 'completed',
        testId: 2222221,
        testName: 'skills test',
      },
      {
        id: 2222222,
        overallScoreAsPercents: 0,
        originalPercentToPass: 0,
        percentToPass: 0,
        state: 'completed',
        testId: 2222222,
        testName: 'video intro',
      },
    ],
    testTakerCategoryId: 'Video Intro',
    isUnlocked: true,
  },
  {
    id: 'dummy-3',
    createdAt: new Date(),
    latestTestFinishedAt: new Date(),
    firstName: 'Magdalena',
    lastName: 'Johnson',
    fullName: 'Magdalena Johnson',
    email: 'magdalena.johnson@email.com',
    name: 'Magdalena',
    hasNotes: false,
    suspicious: false,
    rating: 0,
    latestScorePercent: 35,
    latestPercentToPass: 80,
    jobOpenings: [
      {
        id: '3331',
        name: 'Customer Success Specialist',
        visibility: 'workspace',
      },
    ],
    tags: null,
    country: 'Argentina',
    tests: [
      {
        id: 3333331,
        overallScoreAsPercents: 35,
        originalPercentToPass: 80,
        percentToPass: 80,
        state: 'completed',
        testId: 3333331,
        testName: 'skills test',
      },
    ],
    testTakerCategoryId: 'Skills test',
    isUnlocked: true,
  },
  {
    id: 'dummy-4',
    createdAt: new Date(),
    latestTestFinishedAt: new Date(),
    firstName: 'Silvia Melinda',
    lastName: 'da Mocha Rias',
    fullName: 'Silvia Melinda da Mocha Rias',
    email: 'silvia.rias@email.com',
    name: 'Silvia',
    hasNotes: true,
    suspicious: true,
    rating: 3,
    latestScorePercent: 88,
    latestPercentToPass: 80,
    jobOpenings: [
      {
        id: '4441',
        name: 'Digital Marketing Manager',
        visibility: 'workspace',
      },
    ],
    tags: [{ id: 't4441', name: 'Europe' }],
    country: 'Czechia',
    tests: [
      {
        id: 4444441,
        overallScoreAsPercents: 88,
        originalPercentToPass: 80,
        percentToPass: 80,
        state: 'completed',
        testId: 4444441,
        testName: 'skills test',
      },
      {
        id: 4444442,
        overallScoreAsPercents: 0,
        originalPercentToPass: 0,
        percentToPass: 0,
        state: 'completed',
        testId: 4444442,
        testName: 'video intro',
      },
    ],
    testTakerCategoryId: 'Rejected',
    isUnlocked: true,
  },
  {
    id: 'dummy-5',
    createdAt: new Date(),
    latestTestFinishedAt: new Date(),
    firstName: 'Tristan',
    lastName: 'Solos',
    fullName: 'Tristan Solos',
    email: 'tristan.solos@email.com',
    name: 'Tristan',
    hasNotes: true,
    suspicious: false,
    rating: 3,
    latestScorePercent: 96,
    latestPercentToPass: 80,
    jobOpenings: [
      {
        id: '5551',
        name: 'Senior Frontend Developer',
        visibility: 'workspace',
      },
    ],
    tags: [
      { id: 't5551', name: 'Promising' },
      { id: 't5552', name: 'Europe' },
    ],
    country: 'Netherlands',
    tests: [
      {
        id: 5555551,
        overallScoreAsPercents: 96,
        originalPercentToPass: 80,
        percentToPass: 80,
        state: 'completed',
        testId: 5555551,
        testName: 'skills test',
      },
      {
        id: 5555552,
        overallScoreAsPercents: 0,
        originalPercentToPass: 0,
        percentToPass: 0,
        state: 'completed',
        testId: 5555552,
        testName: 'video intro',
      },
      {
        id: 5555553,
        overallScoreAsPercents: 0,
        originalPercentToPass: 0,
        percentToPass: 0,
        state: 'invited',
        testId: 5555553,
        testName: 'interview',
      },
    ],
    testTakerCategoryId: 'Homework',
    isUnlocked: true,
  },
];
