import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { History } from 'history';

import configuration from './configuration/runtime';

export default function configureSentry(history: History) {
  if (configuration.sentryDsn) {
    Sentry.init({
      dsn: configuration.sentryDsn,
      environment: configuration.sentryEnvironment,
      integrations: [
        new BrowserTracing({
          tracingOrigins: [configuration.apiUrl ?? ''],
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: configuration.sentryTracesSampleRate,
      ignoreErrors: ['Loading chunk', 'Loading CSS chunk'],
    });
  }
}
