import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';
import { Form, Formik } from 'formik';

import { FormikRadioField, PlanTag, PromptIfDirty } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useJobOpeningPermission } from '@/features/permissions';
import { useTestPage, useUpdateTestMutation } from '@/features/test';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

import { TestSettingsSection } from '../test-settings-section';

interface Settings {
  isQuestionRatingDisabled?: 'true' | 'false';
}

export function SkillsTestSettingsOther() {
  const { showFlashMessage } = useFlashMessages();
  const { openUpsellModal } = useUpsellModal();
  const canAccessFeature = usePlanLimits();
  const canUpdate = useJobOpeningPermission()('test', 'update');
  const updateTestMutation = useUpdateTestMutation();
  const { test } = useTestPage();

  const initialSettings: Settings = {
    isQuestionRatingDisabled: test.isQuestionRatingDisabled ? 'true' : 'false',
  };

  const handleSubmit = async (values: Settings) => {
    const updatedTest = {
      isQuestionRatingDisabled: values.isQuestionRatingDisabled === 'true',
    };

    await updateTestMutation.mutateAsync({
      testId: test.id,
      test: updatedTest,
    });

    showFlashMessage({ type: 'saved' });
  };

  return (
    <Formik<Settings>
      enableReinitialize
      initialValues={initialSettings}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting, setFieldValue }) => (
        <Form>
          <PromptIfDirty />

          <DSContentBlock>
            <DSContentBlock.Title>Other</DSContentBlock.Title>

            <DSContentBlock.Content>
              <TestSettingsSection>
                <TestSettingsSection.Header>
                  Candidates can rate questions
                </TestSettingsSection.Header>
                <TestSettingsSection.Content>
                  <TestSettingsSection.OptionsWrapper>
                    <FormikRadioField
                      name="isQuestionRatingDisabled"
                      value="true"
                      onChange={() => {
                        if (!canAccessFeature('disable_question_rating')) {
                          openUpsellModal('premium_feature');
                          return;
                        }
                        setFieldValue('isQuestionRatingDisabled', 'true');
                      }}
                      label={
                        <TestSettingsSection.LabelWrapper>
                          Disabled <StyledPlanTag />
                        </TestSettingsSection.LabelWrapper>
                      }
                    />
                    <FormikRadioField
                      name="isQuestionRatingDisabled"
                      value="false"
                      label="Enabled"
                    />
                  </TestSettingsSection.OptionsWrapper>
                </TestSettingsSection.Content>
              </TestSettingsSection>
            </DSContentBlock.Content>

            <DSContentBlock.Footer>
              <DSButton
                type="submit"
                disabled={!canUpdate || !dirty || isSubmitting}
                data-rh={!canUpdate ? 'Only admins can save changes' : null}
              >
                {isSubmitting ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}

const StyledPlanTag = styled(PlanTag)`
  margin: 0;
`;
