import { AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { get, keyBy } from 'lodash';

import api from '@/api/api';
import { ClientError } from '@/errors';
import { TId } from '@/features/common';

import {
  BillingAccount,
  BillingBalance,
  BillingChargeInvoice,
  BillingInfo,
  BillingInfoAttributes,
  BillingPlan,
  BillingPreview,
  BillingSubscription,
  BillingSubscriptionState,
  CardInfo,
  Coupon,
  CreditsPurchase,
  CreditsPurchaseChargeInvoice,
  CreditsPurchaseItemCode,
  Invoice,
  PauseDuration,
  PauseSubscription,
  PaymentMethodToken,
} from '../types/billing';

type ApiInvoice = {
  id: number;
  createdAt: string;
  totalInCents: number;
  token: string;
};

const parseInvoice = (invoice: ApiInvoice, workspaceId: TId): Invoice => ({
  id: String(invoice.id),
  date: new Date(invoice.createdAt),
  amount: invoice.totalInCents / 100,
  token: invoice.token,
  workspace: workspaceId,
});

export async function fetchInvoices(
  workspaceId: TId,
  cursor: string | null
): Promise<{ invoices: { [id: string]: Invoice }; cursor: string | null }> {
  let response;

  try {
    response = await api.get(`/workspaces/${workspaceId}/invoices`, {
      params: { cursor: cursor },
    });
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return { invoices: {}, cursor: null };
    } else {
      throw error;
    }
  }

  const data = camelizeKeys(response.data) as any;

  const invoices = data.invoices
    ? data.invoices.map(item => parseInvoice(item, workspaceId))
    : [];

  return {
    invoices: keyBy(invoices, 'id') as any,
    cursor: data.cursor || null,
  };
}

export async function fetchSubscription(
  workspaceId: TId
): Promise<BillingAccount | null> {
  let response: AxiosResponse;

  try {
    response = await api.get(`/workspaces/${workspaceId}/subscription`);
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return null;
    } else {
      throw error;
    }
  }

  return parseSubscriptionResponse(response);
}

type ApiCardInfo = {
  lastFour: string;
  cardType: string;
  month: number;
  year: number;
};

const parseCardInfo = (info: ApiCardInfo, workspaceId: TId): CardInfo => ({
  last4: info.lastFour,
  cardType: info.cardType,
  month: info.month,
  year: info.year,
  workspace: workspaceId,
});

export async function fetchCardInfo(
  workspaceId: TId
): Promise<CardInfo | null> {
  let response;

  try {
    response = await api.get(`/workspaces/${workspaceId}/card-info`);
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return null;
    } else {
      throw error;
    }
  }
  const data = camelizeKeys(response.data) as any;
  return parseCardInfo(data, workspaceId);
}

export class CardInfoChallengeError extends ClientError {
  constructor(response: AxiosResponse) {
    super(response);
  }

  get token() {
    return get(
      this.response,
      'data.transaction_error.three_d_secure_action_token_id'
    );
  }

  toString() {
    return '3-D Secure action required';
  }
}

export class CardInfoUpdateError extends ClientError {
  constructor(response: AxiosResponse) {
    super(response);
  }

  get code() {
    return get(this.response, 'data.transaction_error.error_code');
  }

  get message() {
    return get(this.response, 'data.transaction_error.customer_message');
  }

  toString() {
    return `Card info update error: ${this.code}`;
  }
}

export async function updateCardInfo(
  workspaceId: TId,
  initialToken: PaymentMethodToken,
  challengeToken?: PaymentMethodToken
): Promise<CardInfo> {
  let response: AxiosResponse;

  try {
    response = await api.put(`/workspaces/${workspaceId}/card-info`, {
      token: initialToken,
      three_d_secure_action_result_token_id: challengeToken,
    });
  } catch (error) {
    if (error instanceof ClientError) {
      const { data } = error.response;

      if (data.transaction_error) {
        switch (data.transaction_error.error_code) {
          case 'three_d_secure_action_required':
            throw new CardInfoChallengeError(error.response);
          default:
            throw new CardInfoUpdateError(error.response);
        }
      }
    }

    throw error;
  }

  const data = camelizeKeys(response.data) as any;
  return parseCardInfo(data, workspaceId);
}

export async function removeCardInfo(workspaceId: TId): Promise<void> {
  await api.delete(`/workspaces/${workspaceId}/card-info`);
}

type ApiBillingInfo = {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  countryCode: string;
  postalCode: string;
  vatNumber: string;
};

const parseBillingInfo = (
  info: ApiBillingInfo,
  workspaceId: TId
): BillingInfo => ({
  firstName: info.firstName,
  lastName: info.lastName,
  address: [info.address1, info.address2].filter(Boolean),
  city: info.city,
  state: info.state,
  country: info.countryCode,
  postalCode: info.postalCode,
  vatNumber: info.vatNumber,
  workspace: workspaceId,
});

const serializeBillingInfo = (
  billingInfo: BillingInfo,
  attributes: BillingInfoAttributes
): ApiBillingInfo => ({
  firstName:
    attributes.firstName != null ? attributes.firstName : billingInfo.firstName,
  lastName:
    attributes.lastName != null ? attributes.lastName : billingInfo.lastName,
  address1:
    attributes.address != null ? attributes.address : billingInfo.address[0],
  address2:
    attributes.address2 != null ? attributes.address2 : billingInfo.address[1],
  city: attributes.city != null ? attributes.city : billingInfo.city,
  state: attributes.state != null ? attributes.state : billingInfo.state,
  countryCode:
    attributes.country != null ? attributes.country : billingInfo.country,
  postalCode:
    attributes.postalCode != null
      ? attributes.postalCode
      : billingInfo.postalCode,
  vatNumber:
    attributes.vatNumber != null ? attributes.vatNumber : billingInfo.vatNumber,
});

export async function fetchBillingInfo(
  workspaceId: TId
): Promise<BillingInfo | null> {
  let response;

  try {
    response = await api.get(`/workspaces/${workspaceId}/billing-info`);
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return null;
    } else {
      throw error;
    }
  }

  const data = camelizeKeys(response.data) as any;
  return parseBillingInfo(data, workspaceId);
}

export async function updateBillingInfo(
  billingInfo: BillingInfo,
  attributes: BillingInfoAttributes
): Promise<BillingInfo> {
  try {
    const response = await api.put(
      `/workspaces/${billingInfo.workspace}/billing-info`,
      decamelizeKeys(serializeBillingInfo(billingInfo, attributes), {
        split: /(?=[A-Z0-9])/,
      })
    );

    const data = camelizeKeys(response.data) as any;
    return parseBillingInfo(data, billingInfo.workspace);
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return {} as BillingInfo;
    }

    throw error;
  }
}

export async function clearBillingInfo(workspaceId: TId): Promise<Boolean> {
  try {
    const response = await api.delete(
      `/workspaces/${workspaceId}/billing-info`
    );

    return response.status >= 200 && response.status <= 299;
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return false;
    }

    throw error;
  }
}

export async function fetchBillingEmail(
  workspaceId: TId
): Promise<string | null> {
  let response;

  try {
    response = await api.get(`/workspaces/${workspaceId}/billing-info/email`);

    const data = camelizeKeys(response.data) as any;
    return data.email;
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return null;
    } else {
      throw error;
    }
  }
}

export async function updateBillingEmail(
  workspaceId: TId,
  email: string
): Promise<void> {
  await api.put(
    `/workspaces/${workspaceId}/billing-info/email`,
    decamelizeKeys({ email })
  );
}

const parseSubscriptionResponse = (response: AxiosResponse): BillingAccount => {
  const rawData: any = camelizeKeys(response.data);
  return parseAccount(rawData);
};

const parsePreviewResponse = (response: AxiosResponse): BillingPreview => {
  const rawData: any = camelizeKeys(response.data);
  return parsePreview(rawData);
};

type ApiSubscription = {
  workspaceId: number;
  subscription: {
    currentPlan: BillingPlan;
    currentPeriodStartedAt?: string;
    currentPeriodEndsAt?: string;
    expiresAt?: string;
    canceledAt?: string;
    state: BillingSubscriptionState;
    unitAmountInCents: number;
    currency: string;
    taxInCents: number;
    taxType: string;
    taxRegion: string;
    taxRate: number;
  };
  balance: {
    balanceInCents: number;
    pastDue: boolean;
  };
};

type ApiPreview = {
  workspaceId: number;
  subscriptionPreview: {
    subscription: {
      currentPlan: BillingPlan;
      currentPeriodStartedAt: string;
      currentPeriodEndsAt: string;
      expiresAt?: string;
      canceledAt?: string;
      state: BillingSubscriptionState;
      unitAmountInCents: number;
      currency: string;
      taxInCents: number;
      taxType: string;
      taxRegion: string;
      taxRate: number;
    };
    chargeInvoice: {
      currency: string;
      discountInCents: number;
      subtotalInCents: number;
      taxInCents: number;
      totalInCents: number;
      balanceInCents: number;
      taxType: string;
      taxRegion: string;
      taxRate: number;
      creditPayments:
        | {
            currency: string;
            amountInCents: number;
          }[]
        | null;
    } | null;
  };
  balance: {
    balanceInCents: number;
    pastDue: boolean;
  };
};

const parseAccount = (data: ApiSubscription): BillingAccount => {
  const workspace = String(data.workspaceId);

  let subscription: BillingSubscription;
  let balance: BillingBalance;

  subscription = {
    currentPlan: data.subscription.currentPlan,
    currentPeriodStartedAt: data.subscription.currentPeriodStartedAt
      ? new Date(data.subscription.currentPeriodStartedAt)
      : null,
    currentPeriodEndsAt: data.subscription.currentPeriodEndsAt
      ? new Date(data.subscription.currentPeriodEndsAt)
      : null,
    expiresAt:
      data.subscription.expiresAt != null
        ? new Date(data.subscription.expiresAt)
        : null,
    canceledAt:
      data.subscription.canceledAt != null
        ? new Date(data.subscription.canceledAt)
        : null,
    state: data.subscription.state,
    unitAmountInCents: data.subscription.unitAmountInCents,
    currency: data.subscription.currency,
    taxInCents: data.subscription.taxInCents,
    taxType: data.subscription.taxType,
    taxRegion: data.subscription.taxRegion,
    taxRate: data.subscription.taxRate,
  };

  balance = {
    balanceInCents: data.balance.balanceInCents,
    pastDue: data.balance.pastDue,
  };

  return { workspace, subscription, balance };
};

const parsePreview = (data: ApiPreview): BillingPreview => {
  const workspace = String(data.workspaceId);

  let subscription: BillingSubscription;
  let chargeInvoice: BillingChargeInvoice | null;
  let balance: BillingBalance | null;

  subscription = {
    currentPlan: data.subscriptionPreview.subscription.currentPlan,
    currentPeriodStartedAt: new Date(
      data.subscriptionPreview.subscription.currentPeriodStartedAt
    ),
    currentPeriodEndsAt: new Date(
      data.subscriptionPreview.subscription.currentPeriodEndsAt
    ),
    expiresAt:
      data.subscriptionPreview.subscription.expiresAt != null
        ? new Date(data.subscriptionPreview.subscription.expiresAt)
        : null,
    canceledAt:
      data.subscriptionPreview.subscription.canceledAt != null
        ? new Date(data.subscriptionPreview.subscription.canceledAt)
        : null,
    state: data.subscriptionPreview.subscription.state,
    unitAmountInCents: data.subscriptionPreview.subscription.unitAmountInCents,
    currency: data.subscriptionPreview.subscription.currency,
    taxInCents: data.subscriptionPreview.subscription.taxInCents,
    taxType: data.subscriptionPreview.subscription.taxType,
    taxRegion: data.subscriptionPreview.subscription.taxRegion,
    taxRate: data.subscriptionPreview.subscription.taxRate,
  };

  chargeInvoice =
    data.subscriptionPreview.chargeInvoice != null
      ? {
          currency: data.subscriptionPreview.chargeInvoice.currency,
          discountInCents:
            data.subscriptionPreview.chargeInvoice.discountInCents,
          subtotalInCents:
            data.subscriptionPreview.chargeInvoice.subtotalInCents,
          taxInCents: data.subscriptionPreview.chargeInvoice.taxInCents,
          totalInCents: data.subscriptionPreview.chargeInvoice.totalInCents,
          balanceInCents: data.subscriptionPreview.chargeInvoice.balanceInCents,
          taxType: data.subscriptionPreview.chargeInvoice.taxType,
          taxRegion: data.subscriptionPreview.chargeInvoice.taxRegion,
          taxRate: data.subscriptionPreview.chargeInvoice.taxRate,
          creditPayments:
            data.subscriptionPreview.chargeInvoice.creditPayments != null
              ? data.subscriptionPreview.chargeInvoice.creditPayments.map(
                  payment => ({
                    currency: payment.currency,
                    amountInCents: payment.amountInCents,
                  })
                )
              : [],
        }
      : null;

  balance = {
    balanceInCents: data.balance.balanceInCents,
    pastDue: data.balance.pastDue,
  };

  return { workspace, subscription, chargeInvoice, balance };
};

export async function changeSubscriptionPlan(
  workspaceId: TId,
  plan: BillingPlan,
  challengeToken?: string,
  couponCode?: string
): Promise<BillingAccount> {
  let response: AxiosResponse;

  try {
    response = await api.post(
      `/workspaces/${workspaceId}/subscription/change`,
      {
        plan,
        three_d_secure_action_result_token_id: challengeToken,
        coupon_code: couponCode,
      }
    );
  } catch (error) {
    if (error instanceof ClientError) {
      const { data } = error.response;

      if (
        data.transaction_error != null &&
        data.transaction_error.error_code === 'three_d_secure_action_required'
      ) {
        throw new CardInfoChallengeError(error.response);
      }
    }

    throw error;
  }

  return parseSubscriptionResponse(response);
}

export async function changeSubscriptionPlanPreview(
  workspaceId: TId,
  plan: BillingPlan,
  couponCode?: string
): Promise<BillingPreview> {
  const response = await api.post(
    `/workspaces/${workspaceId}/subscription/change/preview`,
    { plan, coupon_code: couponCode }
  );
  return parsePreviewResponse(response);
}

export async function deleteSubscription(
  workspaceId: TId
): Promise<BillingAccount> {
  const response = await api.delete(`/workspaces/${workspaceId}/subscription`);
  return parseSubscriptionResponse(response);
}

export async function pauseSubscription(
  workspaceId: TId,
  pauseDuration: PauseDuration
): Promise<PauseSubscription> {
  let response: AxiosResponse;
  response = await api.post(`workspaces/${workspaceId}/subscription/pause`, {
    pause_duration: pauseDuration,
  });
  return camelizeKeys(response.data) as PauseSubscription;
}

type ApiBillingInfoUrl = {
  billingInfoUrl: string;
};

export async function fetchRecurlyHostedPage(
  workspaceId: TId
): Promise<string> {
  const response = await api.get(`/workspaces/${workspaceId}/billing-info/url`);
  const data = camelizeKeys(response.data) as ApiBillingInfoUrl;
  return data.billingInfoUrl;
}

type ApiPurchase = {
  workspaceId: number;
  chargeInvoice: {
    currency: string;
    discountInCents: number;
    subtotalInCents: number;
    taxInCents: number;
    totalInCents: number;
    taxType: string;
    taxRegion: string;
    taxRate: number;
  };
  balance: {
    balanceInCents: number;
    pastDue: boolean;
  };
};

const parseCreditsPurchase = (apiPurchase: ApiPurchase): CreditsPurchase => {
  const workspace: TId = String(apiPurchase.workspaceId);

  const chargeInvoice: CreditsPurchaseChargeInvoice = {
    currency: apiPurchase.chargeInvoice.currency,
    discountInCents: apiPurchase.chargeInvoice.discountInCents,
    subtotalInCents: apiPurchase.chargeInvoice.subtotalInCents,
    taxInCents: apiPurchase.chargeInvoice.taxInCents,
    totalInCents: apiPurchase.chargeInvoice.totalInCents,
    taxType: apiPurchase.chargeInvoice.taxType,
    taxRegion: apiPurchase.chargeInvoice.taxRegion,
    taxRate: apiPurchase.chargeInvoice.taxRate,
  };

  const balance: BillingBalance = {
    balanceInCents: apiPurchase.balance.balanceInCents,
    pastDue: apiPurchase.balance.pastDue,
  };

  return { workspace, chargeInvoice, balance };
};

export async function purchaseCredits(
  workspaceId: TId,
  itemCode: CreditsPurchaseItemCode,
  challengeToken?: string
): Promise<CreditsPurchase> {
  let response: AxiosResponse;

  try {
    response = await api.post(`/workspaces/${workspaceId}/credits/purchase`, {
      item_code: itemCode,
      three_d_secure_action_result_token_id: challengeToken,
    });
  } catch (error) {
    if (error instanceof ClientError) {
      const { data } = error.response;

      if (
        data.transaction_error != null &&
        data.transaction_error.error_code === 'three_d_secure_action_required'
      ) {
        throw new CardInfoChallengeError(error.response);
      }
    }

    throw error;
  }

  const data = camelizeKeys(response.data) as ApiPurchase;
  return parseCreditsPurchase(data);
}

export async function purchaseCreditsPreview(
  workspaceId: TId,
  itemCode: CreditsPurchaseItemCode
): Promise<CreditsPurchase> {
  const response = await api.post(
    `/workspaces/${workspaceId}/credits/purchase/preview`,
    { item_code: itemCode }
  );

  const data = camelizeKeys(response.data) as ApiPurchase;
  return parseCreditsPurchase(data);
}

export async function fetchCoupon(
  couponCode: string,
  plan: BillingPlan | null,
  workspaceId: TId
): Promise<Coupon | null> {
  let response: AxiosResponse;
  try {
    response = await api.post(
      `/workspaces/${workspaceId}/subscription/coupons`,
      {
        coupon_code: couponCode,
        plan: plan,
      }
    );
    const data = camelizeKeys(response.data) as Coupon;

    return data;
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return null;
    } else {
      throw error;
    }
  }
}
