import styled from '@emotion/styled';

export const StagesContainer = styled.div`
  --scrollbar-foreground: ${({ theme }) => theme.colors.purple[20]};
  --scrollbar-background: transparent;

  display: flex;
  gap: 4px;
  height: calc(100% - 4px); // imitates 4px of bottom padding for scrollbar
  overflow: auto;
  padding: 12px 24px;
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
  scrollbar-gutter: stable;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-foreground);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
  }
`;
