import React from 'react';
import styled from '@emotion/styled';

//region Main
interface ScoreTagProps {
  score?: number;
  passPercentage?: number;
  'data-rh'?: string;
}

export function ScoreTag({ score, passPercentage, ...props }: ScoreTagProps) {
  if (score == null) {
    return null;
  }
  const roundedScore = Math.floor(score);

  return (
    <ScoreTagContainer
      {...props}
      highScore={roundedScore >= (passPercentage || 60)}
    >
      {roundedScore}%
    </ScoreTagContainer>
  );
}

//endregion

//region Styles
const ScoreTagContainer = styled.div<{
  highScore: boolean;
}>`
  font-size: 10px;
  font-weight: 600;
  min-width: 32px;
  height: 16px;
  border-radius: 8px;
  padding: 0 8px;
  display: inline-block;
  text-align: center;

  color: ${({ highScore, theme }) =>
    highScore ? theme.colors.green[100] : theme.colors.red[100]};
  background: ${({ highScore, theme }) =>
    highScore ? theme.colors.green[10] : theme.colors.red[10]};
`;
//endregion
