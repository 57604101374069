import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';

import { useWorkspaceId } from '@/hooks/router';

export function EvaluationButtonsThreeOptions({ score, isLoading, onClick }) {
  const workspaceId = useWorkspaceId();

  const buttonOnClick = (rating: number | null) => {
    onClick(rating);
    amplitude?.logEvent('evaluated answer', {
      'workspace id': workspaceId,
    });
  };

  return (
    <EvaluationButtonRow>
      <EvaluationButton
        evaluationType="correct"
        active={score === 100}
        onClick={() => buttonOnClick(100)}
        disabled={isLoading}
      >
        <span>Correct</span> (100%)
      </EvaluationButton>
      <EvaluationButton
        evaluationType="partially-correct"
        active={score === 50}
        onClick={() => buttonOnClick(50)}
        disabled={isLoading}
      >
        <span>Partially Correct</span> (50%)
      </EvaluationButton>
      <EvaluationButton
        evaluationType="wrong"
        active={score === 0}
        onClick={() => buttonOnClick(0)}
        disabled={isLoading}
      >
        <span>Wrong</span> (0%)
      </EvaluationButton>
    </EvaluationButtonRow>
  );
}

const EvaluationButtonRow = styled.div`
  @media all and (max-width: 768px) {
    display: flex;
    flex-direction: column;

    & button {
      margin-bottom: 10px;
    }
  }
`;

const EvaluationButton = styled.button<{
  evaluationType: 'correct' | 'partially-correct' | 'wrong';
  active: boolean;
}>`
  height: 39px;
  padding: 0 20px;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  background: transparent;
  line-height: 1.2;
  text-align: center;
  font-size: 12px;
  color: #242424;
  cursor: pointer;
  margin-right: 9px;
  transition: box-shadow 0.2s;
  -webkit-appearance: none;

  ${props =>
    props.active &&
    (props.evaluationType === 'correct'
      ? `border: 2px solid #1dc488;
         background-color: #e1f2e9;`
      : props.evaluationType === 'partially-correct'
      ? `border: 2px solid #f2c94c;
         background-color: #fff7e0;`
      : props.evaluationType === 'wrong'
      ? `border: 2px solid #eb5757;
         background-color: rgba(235, 87, 87, 0.13);`
      : '')}
  & span {
    font-weight: bold;
    color: ${props =>
      props.evaluationType === 'correct'
        ? '#1dc488'
        : props.evaluationType === 'partially-correct'
        ? '#f2c94c'
        : props.evaluationType === 'wrong'
        ? '#eb5757'
        : ''};
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.7;
  }

  &:not([disabled]):hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
`;
