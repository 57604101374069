import React from 'react';
import styled from '@emotion/styled';
import formatDate from 'date-fns/format';
import { useField } from 'formik';

import { DayPicker } from '@/features/common';
import { useDateRange } from '@/hooks/time';

export const DatePicker = () => {
  const [_, { value }, { setValue }] = useField('date');

  const { activeRange, ...dateRange } = useDateRange({
    initialRange: value
      ? {
          start: new Date(value.split('--')[0]),
          end: new Date(value.split('--')[1]),
        }
      : undefined,
    onRangeChange(range) {
      setValue(
        `${formatDate(range.start, 'YYYY-MM-DD')}--${formatDate(
          range.end,
          'YYYY-MM-DD'
        )}`
      );
    },
  });

  const selectedDays = dateRange.selectedDays[1] as
    | { from: Date; to: Date }
    | undefined;

  return (
    <DateFilterContainer>
      <DayPicker
        {...dateRange}
        modifiers={{
          start: selectedDays?.from ?? activeRange.from,
          end:
            selectedDays?.to ??
            selectedDays?.from ??
            activeRange.to ??
            activeRange.from,
        }}
      />

      {activeRange.from ? (
        <Dates>
          <PickedDate>
            <div>From:</div>{' '}
            <div>{formatDate(activeRange.from, 'MMM D, YYYY')}</div>
          </PickedDate>
          <PickedDate>
            <div>To:</div>{' '}
            <div>
              {activeRange.to
                ? formatDate(activeRange.to, 'MMM D, YYYY')
                : '\u00A0'}
            </div>
          </PickedDate>
        </Dates>
      ) : null}
    </DateFilterContainer>
  );
};

//region Styles
const DateFilterContainer = styled.div`
  margin: 8px;

  & > :first-of-type {
    border: 1px solid ${props => props.theme.colors.purple[10]};
    box-shadow: 0 1px 4px rgba(44, 19, 56, 0.1);
    border-radius: 8px;
    text-align: center;
  }

  & .DayPicker-Week .DayPicker-Day {
    padding-left: 0;
    padding-right: 0;
  }

  &
    .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    border-radius: 0;
    background-color: ${props => props.theme.colors.orange[100]};
    font-weight: 700;

    &:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
      background-color: ${props => props.theme.colors.orange[20]};
      color: ${props => props.theme.colors.orange[100]};
    }

    &.DayPicker-Day--start {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      background-color: ${props => props.theme.colors.orange[100]};
    }

    &.DayPicker-Day--end {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      background-color: ${props => props.theme.colors.orange[100]};
    }
  }
`;

const Dates = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PickedDate = styled.div`
  width: 50%;
  text-align: center;
`;
//endregion
