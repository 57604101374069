import { useJobOpeningByIdQuery } from '@/features/job-opening';
import { useMeQuery } from '@/features/me';
import { getPlanLimit } from '@/hooks/planLimits';
import { useJobOpeningIdOrNull, useWorkspaceId } from '@/hooks/router';

export const useJobOpeningTestsLimitQuery = () => {
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningIdOrNull();

  const jobOpeningQuery = useJobOpeningByIdQuery({
    jobOpeningId: jobOpeningId ?? undefined,
  });
  const meQuery = useMeQuery();

  const workspace = meQuery.data?.workspaces?.find(
    workspace => workspace.id === workspaceId
  );

  if (!jobOpeningQuery.isSuccess || !meQuery.isSuccess || !workspace) {
    return {
      isSuccess: false,
      isTestsInJobOpeningLimitReached: undefined,
    };
  }

  const testsInJobOpeningLimit = getPlanLimit(
    workspace.subscriptionPlan,
    'tests_in_job_opening'
  );

  const testIds =
    jobOpeningQuery.data?.pipelineStages?.reduce((stagesWithTestIds, stage) => {
      if (stage.testId) {
        stagesWithTestIds.push(stage.testId);
      }
      return stagesWithTestIds;
    }, [] as string[]) || [];

  return {
    isSuccess: meQuery.isSuccess && jobOpeningQuery.isSuccess,
    isTestsInJobOpeningLimitReached:
      (testIds?.length ?? 0) >= testsInJobOpeningLimit,
  };
};
