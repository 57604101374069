import React, { useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { amplitude } from '@/features/amplitude';
import { Icon, Popover, SemanticButton } from '@/features/common';
import { IJobOpening } from '@/features/job-opening';
import { useJobOpeningPermission } from '@/features/permissions';
import { IPipelineCategory } from '@/features/pipeline/types';
import { ITest, redirectToTryTheTestYourself } from '@/features/test';
import { useApiError } from '@/hooks/api';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { Menu, MenuItem, MenuSubheader } from './ui';

interface StageContextMenuProps {
  category: IPipelineCategory;
  jobOpening: IJobOpening;
  hasCandidates: boolean;
  test?: ITest;
  handleCopyTestLink: () => void;
}

export function PipelinesStageContextMenu({
  category,
  jobOpening,
  hasCandidates,
  test,
  handleCopyTestLink,
}: StageContextMenuProps) {
  const handleApiError = useApiError();
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null);

  const workspaceId = useWorkspaceId();
  const history = useHistory();

  const hasTest = Boolean(test);
  const hasInterview = !!category.interviewId;

  const canEditPipeline = !!useJobOpeningPermission()('pipeline', 'update');

  const handleAddToStage = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpening.id}/pipeline/stage/${category.id}/add`
    );
  }, [category.id, history, jobOpening.id, workspaceId]);

  const handleDeleteTest = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpening.id}/pipeline/stage/${category.id}/test/${category.testId}/delete`
    );
  }, [category.id, category.testId, history, jobOpening.id, workspaceId]);

  const handleDeleteStage = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpening.id}/pipeline/stage/${category.id}/delete`
    );
  }, [category.id, history, jobOpening.id, workspaceId]);

  const handleOpenTestSettings = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpening.id}/test/${category.testId}/questions?returnUrl=pipeline&returnName=Pipeline`
    );
    amplitude?.logEvent('pipeline/test settings opened', {
      'job opening id': jobOpening.id,
      'pipeline step': category.name,
      'test id': category.testId,
    });
  }, [history, jobOpening.id, workspaceId, category.testId, category.name]);

  const handleTryTheTestYourself = useCallback(() => {
    if (!test) {
      return;
    }
    try {
      redirectToTryTheTestYourself(jobOpening, workspaceId, test);
    } catch (error) {
      handleApiError(error);
    }
  }, [handleApiError, jobOpening, test, workspaceId]);

  const handleOpenInterviewSettings = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpening.id}/interview/${category.interviewId}/settings`
    );
  }, [category.interviewId, history, jobOpening.id, workspaceId]);

  const handleDeleteInterview = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpening.id}/pipeline/stage/${category.id}/interview/${category.interviewId}/delete`
    );
  }, [category.id, category.interviewId, history, jobOpening.id, workspaceId]);

  return (
    <Popover ref={popoverRef}>
      <Popover.Trigger>
        {({ isOpen, onClick }) => (
          <Trigger onClick={onClick}>
            <Icon icon={regular('ellipsis-vertical')} color="purple-60" />
          </Trigger>
        )}
      </Popover.Trigger>
      <Popover.Content>
        <Menu>
          <MenuSubheader>
            <span>Stage actions</span>
          </MenuSubheader>
          {hasTest ? (
            <>
              <MenuItem>
                <SemanticButton onClick={handleOpenTestSettings}>
                  <Icon icon={regular('gear')} /> Test settings
                </SemanticButton>
              </MenuItem>
              {!!test?.questionCount && (
                <MenuItem>
                  <SemanticButton onClick={handleTryTheTestYourself}>
                    <Icon icon={regular('check-to-slot')} /> Try the test
                    yourself
                  </SemanticButton>
                </MenuItem>
              )}
              <MenuItem>
                <SemanticButton
                  onClick={() => {
                    popoverRef.current?.close();
                    handleCopyTestLink();
                  }}
                >
                  <Icon icon={regular('link-simple')} /> Copy test link
                </SemanticButton>
              </MenuItem>
            </>
          ) : hasInterview ? (
            <>
              <MenuItem>
                <SemanticButton onClick={handleOpenInterviewSettings}>
                  <Icon icon={regular('gear')} /> Interview settings
                </SemanticButton>
              </MenuItem>
              {!hasCandidates && canEditPipeline ? (
                <MenuItem destructive>
                  <SemanticButton onClick={handleDeleteInterview}>
                    <Icon icon={regular('trash')} /> Delete interview
                  </SemanticButton>
                </MenuItem>
              ) : null}
            </>
          ) : (
            canEditPipeline && (
              <MenuItem>
                <SemanticButton onClick={handleAddToStage}>
                  <Icon icon={regular('plus')} /> Add interview or test
                </SemanticButton>
              </MenuItem>
            )
          )}
          {hasTest && !hasCandidates && canEditPipeline ? (
            <MenuItem destructive>
              <SemanticButton onClick={handleDeleteTest}>
                <Icon icon={regular('trash')} /> Delete test
              </SemanticButton>
            </MenuItem>
          ) : null}
          {!hasCandidates && canEditPipeline ? (
            <MenuItem destructive>
              <SemanticButton onClick={handleDeleteStage}>
                <Icon icon={regular('trash')} /> Delete stage
              </SemanticButton>
            </MenuItem>
          ) : null}
        </Menu>
      </Popover.Content>
    </Popover>
  );
}

const Trigger = styled(SemanticButton)`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.peach[100]};
  }

  &:focus {
    background-color: ${({ theme }) => theme.colors.peach[100]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.peach[100]};
  }
`;
