import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import {
  RECOMMENDED_CANDIDATES_KEY,
  fetchRecommendationSettings,
  updateRecommendationSettings,
} from '@/features/job-opening';

export const RECOMMENDATION_SETTINGS_KEY = ['recommendation-settings'];

export const useRecommendationSettingsQuery = (
  params: { jobOpeningId: TId },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...RECOMMENDATION_SETTINGS_KEY, params.jobOpeningId],
    () => fetchRecommendationSettings({ jobOpeningId: params.jobOpeningId }),
    options as any
  );
};

export const useRecommendationSettingsMutation = (
  params: { jobOpeningId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessages();

  return useMutation(updateRecommendationSettings, {
    mutationKey: RECOMMENDATION_SETTINGS_KEY,
    onSuccess() {
      showFlashMessage({ type: 'saved' });
      queryClient.invalidateQueries(RECOMMENDATION_SETTINGS_KEY);
      queryClient.invalidateQueries({
        queryKey: [...RECOMMENDED_CANDIDATES_KEY, params.jobOpeningId],
      });
    },
    ...(options as any),
  });
};
