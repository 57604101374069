import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import formatDate from 'date-fns/format';

import { Icon, TestTypeIcon } from '@/features/common';
import { IPipelineCandidate } from '@/features/pipeline/types';

import { Tags, ICandidateSystemTag } from '.';

interface CandidateSystemTagsProps {
  candidate: IPipelineCandidate;
}

export function CandidateSystemTags({ candidate }: CandidateSystemTagsProps) {
  const demoTag = candidate.isDemo
    ? [
        {
          id: 'demo_candidate',
          name: 'Example',
          type: 'demo' as const,
        },
      ]
    : [];

  const dateTag = {
    id: candidate.createdAt.toString(),
    name: formatDate(candidate.createdAt, 'MMM D, YYYY'),
    type: 'date' as const,
  };

  const suspiciousTag = candidate.isSuspicious
    ? [
        {
          id: candidate.id.toString(),
          type: 'suspicious_flag' as const,
          icon: (
            <Icon
              data-rh="Suspicious activity"
              icon={solid('flag')}
              size="xs"
              color="purple-100"
            />
          ),
        },
      ]
    : [];

  const testResultsTags: ICandidateSystemTag[] =
    candidate.tests
      ?.filter(test => test.candidateState === 'completed')
      .map(test => ({
        id: test.testId.toString(),
        name: String(Math.floor(test.percent)) + '%',
        type:
          test.percent === 0 ||
          Math.floor(test.percent) >= (test.percentToPass || 60)
            ? 'test_score_overthreshold'
            : ('test_score' as const),
        icon: <StyledTestTypeIcon testType={test.type} inverted />,
      })) || [];

  const systemTags: ICandidateSystemTag[] = [
    ...demoTag,
    dateTag,
    ...suspiciousTag,
    ...testResultsTags,
  ];

  return <Tags tags={systemTags} />;
}

const StyledTestTypeIcon = styled(TestTypeIcon)`
  padding: 0;
`;
