import { useWorkspaceTreatments } from '@/components/splits';

import {
  billingPlansDescriptions,
  billingPlansDescriptions2023V2,
  billingPlansDescriptionsExp2023,
  billingPlansDescriptions2024,
} from '..';

export const useBillingPlansConfig = () => {
  const {
    fe_billingPlans_exp2023,
    fe_billingPlans_2023v2,
    fe_billingPlans_2024,
  } = useWorkspaceTreatments([
    'fe_billingPlans_exp2023',
    'fe_billingPlans_2023v2',
    'fe_billingPlans_2024',
  ]);

  return fe_billingPlans_2024.treatment === 'on'
    ? billingPlansDescriptions2024
    : fe_billingPlans_2023v2.treatment === 'on'
    ? billingPlansDescriptions2023V2
    : fe_billingPlans_exp2023.treatment === 'on'
    ? billingPlansDescriptionsExp2023
    : fe_billingPlans_2023v2.treatment === 'on'
    ? billingPlansDescriptions2023V2
    : billingPlansDescriptions;
};
