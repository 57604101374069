import React, { SyntheticEvent } from 'react';
import styled from '@emotion/styled';
import { InlineField, Radio } from '@hundred5/design-system';
import { uniqueId } from 'lodash';

interface RadioFieldProps {
  name: string;
  label: string;
  value: string;
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
}

export const RadioField = ({
  name,
  label,
  value,
  onChange,
}: RadioFieldProps) => {
  const id = uniqueId('job-opening-feedback-modal-');

  return (
    <Field
      for={id}
      label={label}
      input={
        <Radio
          id={id}
          type="radio"
          color="black"
          name={name}
          value={value}
          onChange={onChange}
        />
      }
    />
  );
};

const Field = styled(InlineField)`
  margin: 10px 0;
`;
