import styled from '@emotion/styled';

import { Popover } from '@/features/common';

export const Trigger = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
`;

export const SubContent = styled(Popover.Content)`
  min-width: 340px;
  padding: 8px;
  top: -4px;
  right: -8px;
`;

export const FullWidthPopover = styled(Popover)`
  flex: 1;
  position: unset;
`;
