import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSIconDelete, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';

import { useMeQuery } from '@/features/me';
import { useWorkspacePermission } from '@/features/permissions';
import {
  Role,
  useUpdateWorkspaceUserMutation,
  useWorkspaceUsersQuery,
} from '@/features/workspace';
import { useHistory, useWorkspaceId } from '@/hooks/router';
import { Id } from '@/types/misc';

import { OwnershipTransfer } from './ownership-transfer';
import { RemoveMemberModal } from './remove-member-modal';
import { RoleSelect } from './role-select';

type Props = {
  open: boolean;
  userId: Id | null;
};

interface Values {
  role?: Role;
}

export const UpdateMemberModal = ({ open, userId }: Props) => {
  const history = useHistory();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const { me, user, workspaceId, canEditMemberRole, canRemoveMember } = useData(
    userId
  );

  const updateWorkspaceUserMutation = useUpdateWorkspaceUserMutation();

  const close = useCallback(
    () => history.push(`/admin/${workspaceId}/settings/team`),
    [history, workspaceId]
  );

  const submit = useCallback(
    async (values: Values) => {
      if (canEditMemberRole && userId != null && values.role != null) {
        await updateWorkspaceUserMutation.mutateAsync({
          workspaceId,
          userId,
          role: values.role,
        });
        close();
      }
    },
    [canEditMemberRole, userId, updateWorkspaceUserMutation, close, workspaceId]
  );

  useEffect(() => {
    setRemoveModalOpen(false);
  }, [open]);

  return (
    <DSModal
      fluid
      open={open}
      onClose={close}
      contentStyle={{ width: '590px' }}
    >
      <Content>
        <Header>
          Edit role
          <DSModal.CloseButton onClick={close} small fixed />
        </Header>
        <DSModal.Separator />

        <Formik<Values> initialValues={{ role: user?.role }} onSubmit={submit}>
          {form => (
            <Form>
              <Email data-recording-ignore="mask">
                <span>
                  <strong>{user ? user.name : null}</strong>
                </span>
                <span>{user ? user.email : null}</span>
              </Email>

              <RoleSelect
                roles={
                  canEditMemberRole || user == null
                    ? ['admin', 'manager']
                    : [user.role]
                }
                selected={form.values.role || (user && user.role)}
              />

              <OwnershipTransfer userId={userId} />
              <Footer>
                {canRemoveMember ? (
                  <DSButton
                    type="button"
                    variant="secondary"
                    Icon={DSIconDelete}
                    iconSize="14px"
                    onClick={() => setRemoveModalOpen(true)}
                  >
                    {'Remove'}
                  </DSButton>
                ) : null}
                {canEditMemberRole ? (
                  <DSButton
                    type="submit"
                    disabled={updateWorkspaceUserMutation.isLoading}
                  >
                    {updateWorkspaceUserMutation.isLoading
                      ? 'Saving...'
                      : ' Save'}
                  </DSButton>
                ) : null}
              </Footer>
            </Form>
          )}
        </Formik>
      </Content>

      {userId != null && me && me.id != null ? (
        <RemoveMemberModal
          workspaceId={workspaceId}
          open={removeModalOpen}
          userId={userId}
          meId={me.id}
          onClose={() => setRemoveModalOpen(false)}
          onConfirm={close}
        />
      ) : null}
    </DSModal>
  );
};

const useData = (userId: Id | null) => {
  const canUpdateMember = useWorkspacePermission()(
    'workspace members',
    'update'
  );
  const canDeleteMember = useWorkspacePermission()(
    'workspace members',
    'delete'
  );
  const meQuery = useMeQuery();
  const workspaceUsersQuery = useWorkspaceUsersQuery();
  const users = workspaceUsersQuery.data || [];
  const user = users.find(user => user.id === userId);
  const me = meQuery.data || null;
  const workspaceId = useWorkspaceId();

  const editingOwner = userId && user ? user.role === 'owner' : null;

  const editingMyself = me && me.id && userId ? me.id === userId : null;
  const canEditMemberRole = editingOwner === false && canUpdateMember === true;
  const canRemoveMember =
    editingOwner === false &&
    (editingMyself === true || canDeleteMember === true);

  return {
    me,
    user,
    workspaceId,
    canEditMemberRole,
    canRemoveMember,
  };
};

//region Styles
const Content = styled.div`
  color: ${props => props.theme.colors.purple[100]};
`;

const Header = styled.div`
  font-weight: 700;
  font-size: ${16 / 14}rem;
  color: ${props => props.theme.typography.colorPrimary};
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Email = styled.div`
  line-height: 150%;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-top: 16px;
  gap: 8px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
`;
//endregion
