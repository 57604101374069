import React from 'react';
import styled from '@emotion/styled';

import { useCandidateSearchParams } from '@/features/candidate-search';
import { CarouselNavigation } from '@/features/common/components/carousel-navigation';
import { useCarousel } from '@/hooks/carousel';

import { ClearAllFilters } from '../buttons';

import { ActionRequiredListItem } from './ui/action-required-list-item';
import { CountryListItem } from './ui/country-list-item';
import { DateListItem } from './ui/date-list-item';
import { FirstColumnListItem } from './ui/first-column-list-item';
import { NotesListItem } from './ui/notes-list-item';
import { ScoreListItem } from './ui/score-list-item';
import { StarsListItem } from './ui/stars-list-item';
import { StateListItem } from './ui/state-list-item';
import { TagsListItem } from './ui/tags-list-item';

export const FilterList = () => {
  const { hasSearchParams } = useCandidateSearchParams();
  const {
    contentRef,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    offset,
    containerRef,
  } = useCarousel({ slideStep: 200 });

  if (!hasSearchParams) {
    return null;
  }

  return (
    <>
      <Container ref={containerRef}>
        <FilterListWrapper style={offset} ref={contentRef}>
          <ActionRequiredListItem />
          <FirstColumnListItem />
          <NotesListItem />
          <DateListItem />
          <CountryListItem />
          <ScoreListItem />
          <TagsListItem />
          <StarsListItem />
          <StateListItem />
        </FilterListWrapper>
      </Container>
      <Navigation>
        {(canGoPrev || canGoNext) && (
          <CarouselNavigation
            canGoToPrev={canGoPrev}
            canGoToNext={canGoNext}
            goPrev={goPrev}
            goNext={goNext}
          />
        )}
      </Navigation>
      <ClearAllFilters />
    </>
  );
};

//region style
const Container = styled.div`
  overflow: hidden;
  margin: 0 12px;
`;
const FilterListWrapper = styled.div`
  display: flex;
  position: relative;
  gap: 12px;
  width: max-content;
  touch-action: pan-x pan-y;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Navigation = styled.div`
  align-items: center;
  display: flex;
`;

//endregion
