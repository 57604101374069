import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import Spinner from '@/components/spinner/alpha/Spinner';

import { ImageUploader } from './image-uploader';

type ProfilePictureUploadProps = {
  pictureUrl: string | null;
  disabled?: boolean;
  user?: boolean;
  onUploaded: (imageUrl: string) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  uploadLabel?: string;
  reUploadLabel?: string;
  placeholder?: string;
};

export function WorkspacePicture({
  pictureUrl,
  disabled,
  user,
  onClick,
  uploadLabel = 'Update',
  reUploadLabel = 'Update',
  onUploaded,
  placeholder,
}: ProfilePictureUploadProps) {
  const [uploading, setUploading] = useState(false);
  const onUploadedCallback = useCallback(
    imageUrl => {
      onUploaded(imageUrl);
      setUploading(false);
    },
    [onUploaded, setUploading]
  );

  return (
    <Container>
      {uploading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <Wrapper>
          <Picture url={pictureUrl}>{!pictureUrl && placeholder}</Picture>
          {!disabled ? (
            <Label>
              <ImageUploader
                type="workspace_logo"
                reUpload={pictureUrl != null}
                onUploaded={onUploadedCallback}
                onClick={onClick}
                uploadLabel={uploadLabel}
                reUploadLabel={reUploadLabel}
                onStarted={() => {
                  setUploading(true);
                }}
                onFailed={() => {
                  setUploading(false);
                }}
                user={user}
              />
            </Label>
          ) : null}
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div<{ disabled?: boolean }>`
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  bottom: 0;
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.purple[5]};
  text-decoration: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;

  &:hover ${Label} {
    opacity: 1;
  }
`;

const SpinnerWrapper = styled.div`
  min-width: 60px;
  height: 60px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Picture = styled.div<{ url: string | null }>`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  background-image: ${props => props.url && `url(${props.url})`};
  border: 1px solid ${props => props.theme.colors.purple[10]};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
`;
