import styled from '@emotion/styled';

import backgroundIllustration from './assets/background-illustration.svg';

/**
 * A full page component with background image and centered children
 */
export const FullPage = styled.div`
  background-image: url(${backgroundIllustration});
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100svh;
  overflow-y: auto;
`;
