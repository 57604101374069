import React, { CSSProperties, ReactNode } from 'react';
import { Editor, ProseMirrorView } from '@togglhire/text-editor';

import { useWorkspaceUsersQuery } from '@/features/workspace/queries/workspace-users';
import { useImageUpload } from '@/hooks/images';

import { EditorState } from './better-text-editor.utils';

interface BetterTextEditorProps {
  state: EditorState;
  onChange: (state: EditorState) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  tabIndex?: number;
  autoFocus?: boolean;
  disabled?: boolean;
  resizable?: boolean;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  enableTemplateVariables?: boolean;
  enableSchedulingLink?: boolean;
  enableMentions?: boolean;
  renderAboveEditorContent?: (proseMirrorView: ProseMirrorView) => ReactNode;
}

export function BetterTextEditor({
  resizable = true,
  ...props
}: BetterTextEditorProps) {
  const workspaceUsersQuery = useWorkspaceUsersQuery();
  const { uploadImage } = useImageUpload();

  if (!workspaceUsersQuery.isSuccess) {
    return null;
  }

  return (
    <Editor
      state={props.state}
      onChange={props.onChange}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      tabIndex={props.tabIndex}
      autoFocus={props.autoFocus}
      disabled={props.disabled}
      resizable={resizable}
      placeholder={props.placeholder}
      enableTemplateVariables={props.enableTemplateVariables}
      enableSchedulingLink={props.enableSchedulingLink}
      enableMentions={props.enableMentions}
      renderAboveEditorContent={props.renderAboveEditorContent}
      className={props.className}
      style={props.style}
      users={
        workspaceUsersQuery.data.map(user => {
          return { id: user.id, name: user.name, email: user.email };
        }) || []
      }
      uploadImage={async file => {
        const [err, url] = await uploadImage(file, null, false);

        if (err != null) {
          throw err;
        }

        return url;
      }}
    />
  );
}
