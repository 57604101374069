import React, { useMemo } from 'react';
import { DSInlineField, DSRadio } from '@hundred5/design-system';
import { useField } from 'formik';
import { uniqueId } from 'lodash';

interface FormikRadioFieldProps {
  name: string;
  label: React.ReactNode;
  id?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<any>) => void;
  centered?: boolean;
}

export function FormikRadioField(props: FormikRadioFieldProps) {
  const [field] = useField<any>({
    name: props.name,
    type: 'radio',
    value: props.value,
  });
  const id = useMemo(() => props.id || uniqueId('formik-radio-field-'), [
    props.id,
  ]);

  return (
    <DSInlineField
      for={id}
      label={props.label}
      disabled={props.disabled}
      centered={props.centered}
    >
      <DSRadio
        id={id}
        {...field}
        onChange={event => {
          if (props.onChange) props.onChange(event);
          else field.onChange(event);
        }}
        checked={props.checked ?? field.checked}
        disabled={props.disabled}
        readOnly={props.readonly}
      />
    </DSInlineField>
  );
}
