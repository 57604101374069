import React from 'react';
import styled from '@emotion/styled';

import { StateContainer } from './state-container';
import { StatePill } from './state-pill';

export function RecommendedCandidateState() {
  return (
    <StateContainer>
      <Pill>Great match</Pill>
    </StateContainer>
  );
}

const Pill = styled(StatePill)`
  background: ${({ theme }) => theme.colors.lavender[20]};
  color: ${({ theme }) => theme.colors.purple[100]};
`;
