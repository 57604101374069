import * as React from 'react';
import css from '@emotion/css';
import styled from '@emotion/styled';

export interface IBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  bold?: boolean;
  uppercase?: boolean;
  ellipsis?: boolean;
  color:
    | 'gray'
    | 'red'
    | 'pink'
    | 'light-pink'
    | 'white'
    | 'purple'
    | 'light-yellow'
    | 'light-orange';
  children: React.ReactNode;
  tooltip?: string;
  className?: string;
  fontSize?: string;
  height?: string;
}

export const Badge = ({ tooltip, children, ...props }: IBadgeProps) => (
  <Container data-rh={tooltip} {...props}>
    {children}
  </Container>
);

type ColorVariants = {
  [K in IBadgeProps['color']]: { color: string; background: string };
};
const colorVariants: ColorVariants = {
  red: { background: '#eb5757', color: '#fff' },
  gray: { background: '#f5f5f5', color: '#1a1a1a' },
  pink: { background: '#ff8a7a', color: '#ffffff' },
  'light-pink': { background: '#f6e9e7', color: '#ff897a' },
  white: { background: '#ffffff', color: '#1a1a1a' },
  purple: { background: '#f4f3f5', color: '2c1338' },
  'light-yellow': { background: '#FFF8E9', color: '#1a1a1a' },
  'light-orange': { background: '#FFF3F2', color: '#1a1a1a' },
};

const ellipsisPartial = (props: IBadgeProps) =>
  props.ellipsis &&
  css`
    padding: 2px 6px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

const Container = styled.div<IBadgeProps>`
  min-width: 19px;
  height: ${props => props.height || '19px'};
  padding: 0 6px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.fontSize || '10px'};
  font-weight: ${props => (props.bold ? 'bold' : 600)};
  position: relative;
  color: ${props => colorVariants[props.color].color};
  background: ${props => colorVariants[props.color].background};
  text-transform: ${props => props.uppercase && 'uppercase'};
  ${ellipsisPartial}
`;
