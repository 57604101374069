import React from 'react';
import styled from '@emotion/styled';
import { DSProgressBar } from '@hundred5/design-system';

import { TRecommendedCandidateSkill } from '@/features/job-opening';

export const SkillItem: React.FC<{ skillInfo: TRecommendedCandidateSkill }> = ({
  skillInfo,
}) => {
  return (
    <SkillListItemContainer>
      <Name data-recording-sensitive>{skillInfo.skill}</Name>
      <Progress>
        <StyledDSProgressBar
          value={skillInfo.score}
          backgroundColor="purple-5"
        />
        <Score>{skillInfo.score}%</Score>
      </Progress>
    </SkillListItemContainer>
  );
};

const SkillListItemContainer = styled.li`
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-content: center;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  padding: 16px 0;

  display: grid;
  grid-template-areas: 'name progress';
  grid-auto-columns: 3fr 2fr;
  grid-auto-rows: min-content;
  gap: 16px;

  &:last-of-type {
    border-bottom: none;
  }
`;

const Name = styled.div`
  color: ${({ theme }) => theme.typography.colorPrimary};
`;

const Progress = styled.div`
  grid-area: progress;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledDSProgressBar = styled(DSProgressBar)`
  height: 8px;
`;

const Score = styled.div`
  color: ${({ theme }) => theme.typography.colorPrimary};
  min-width: 36px;
  text-align: end;
`;
