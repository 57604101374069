import * as React from 'react';
import { DSButton } from '@hundred5/design-system';
import formatDate from 'date-fns/format';

import configuration from '@/configuration/runtime';
import { Invoice as InvoiceType } from '@/features/billing';
import { TableColumn, TableRow } from '@/features/common';

const getInvoicePdfUrl = (invoice: InvoiceType) =>
  [
    configuration.apiUrl,
    'api/v1/workspaces',
    invoice.workspace,
    'invoices',
    `${invoice.id}?token=${invoice.token}`,
  ].join('/');

export const Invoice = ({ invoice }: { invoice: InvoiceType }) => (
  <TableRow>
    <TableColumn width="20%">#{invoice.id}</TableColumn>

    <TableColumn width="30%">
      {formatDate(invoice.date, 'MMM D, YYYY')}
    </TableColumn>

    <TableColumn width="30%">${invoice.amount.toFixed(2)}</TableColumn>
    <TableColumn width="20%">
      <a
        href={getInvoicePdfUrl(invoice)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <DSButton size="small" variant="secondary">
          Download
        </DSButton>
      </a>
    </TableColumn>
  </TableRow>
);
