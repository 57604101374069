import * as exportApi from '@/api/candidateExport';
import { Id } from '@/types/misc';
import { CandidateExportFormat, CandidateFilter } from '@/types/reports';

export const fetchUrl = (
  jobOpeningId: Id,
  filter: CandidateFilter,
  format: CandidateExportFormat
): Promise<string> => {
  switch (format) {
    case 'pdf':
      return fetchPdfUrl(jobOpeningId, filter);
    case 'csv':
      return fetchCsvUrl(jobOpeningId, filter);
  }
};

const fetchPdfUrl = (
  jobOpeningId: Id,
  filter: CandidateFilter
): Promise<string> => {
  return exportApi.exportJobOpeningCandidatesAsPdf(jobOpeningId, filter);
};

const fetchCsvUrl = async (
  jobOpeningId: Id,
  filter: CandidateFilter
): Promise<string> => {
  const blob = await exportApi.exportJobOpeningCandidatesAsCsvBlob(
    jobOpeningId,
    filter
  );

  return URL.createObjectURL(blob);
};

export const CANDIDATES_EXPORT_FILTERS: {
  filter: CandidateFilter;
  label: string;
}[] = [
  { filter: 'all', label: 'All' },
  { filter: 'not_rejected', label: 'Not rejected' },
  { filter: 'possible_hires', label: 'Over threshold' },
  { filter: 'new', label: 'New' },
  { filter: 'notes', label: 'With notes' },
  { filter: 'suspicious', label: 'Suspicious' },
  { filter: 'rejected', label: 'Rejected' },
  { filter: 'over_80', label: 'Over 80%' },
  { filter: 'over_60', label: 'Over 60%' },
  { filter: 'over_40', label: 'Over 40%' },
];
