import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { useFlashMessages } from '@/features/flash-messages';
import { ME_QUERY_KEY } from '@/features/me';

import { emailValidationRequest } from '../api';

export const useEmailValidationRequestMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessages();

  return useMutation(emailValidationRequest, {
    onSuccess: () => {
      showFlashMessage({ type: 'verification_email_sent' });
      queryClient.invalidateQueries(ME_QUERY_KEY);
    },
    ...(options as any),
  });
};
