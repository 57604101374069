import { action, createStandardAction } from 'typesafe-actions';

import { LogInError, SignUpError } from '../types/authentication';
import { ReduxAction } from '../types/util';

export const initialized = (authenticated: boolean) =>
  action('AUTH_INITIALIZED', { authenticated });
export type InitializedAction = ReduxAction<typeof initialized>;

export type LoggedInAction = { type: 'LOGGED_IN' };
export const loggedIn: () => LoggedInAction = createStandardAction('LOGGED_IN')<
  void
>();

export const logInError = (error: LogInError) => action('LOG_IN_ERROR', error);
export type LogInErrorAction = ReduxAction<typeof logInError>;

export type LoggedOutAction = {
  type: 'LOGGED_OUT';
};
export const loggedOut = (): LoggedOutAction => ({
  type: 'LOGGED_OUT',
});

export type SignedUpAction = { type: 'SIGNED_UP' };
export const signedUp: () => SignedUpAction = createStandardAction(
  'SIGNED_UP'
)();

export const signUpError = (error: SignUpError) =>
  action('SIGN_UP_ERROR', error);
export type SignUpErrorAction = ReduxAction<typeof signUpError>;

export const emailVerified = () => action('EMAIL_VERIFIED');

export type AuthenticationAction =
  | InitializedAction
  | LoggedInAction
  | LogInErrorAction
  | LoggedOutAction
  | SignedUpAction
  | SignUpErrorAction
  | ReduxAction<typeof emailVerified>;
