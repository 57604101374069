import React from 'react';

export function PremiumIllustration() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="44" fill="none">
      <path
        fill="#FFC4BC"
        d="M36 .469c-19.882 0-36 16.15-36 36.074h72C72 16.62 55.882.469 36 .469Z"
      />
      <path
        fill="#FF897A"
        fillRule="evenodd"
        d="M36 43.531c19.882 0 36-3.129 36-6.988 0-3.86-16.118-6.988-36-6.988S0 32.683 0 36.543c0 3.86 16.118 6.988 36 6.988Z"
        clipRule="evenodd"
      />
      <rect
        width="28.023"
        height="20.791"
        fill="#fff"
        rx="2.756"
        transform="rotate(-49.043 37.714 -7.885) skewX(-.118)"
      />
      <path
        fill="#FF897A"
        d="M36.053 18.63a.956.956 0 1 0-1.004 0l-1.37 2.74a.766.766 0 0 1-1.163.257l-2.132-1.706a.957.957 0 1 0-.765.383h.017l1.093 6.013a1.531 1.531 0 0 0 1.507 1.259h6.63c.74 0 1.373-.53 1.507-1.259l1.094-6.013h.016a.957.957 0 1 0-.765-.383l-2.132 1.706a.766.766 0 0 1-1.162-.256l-1.37-2.741Z"
      />
    </svg>
  );
}
