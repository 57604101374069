import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';

import { useExportMe } from '@/hooks/me';

import { useMeQuery } from '../../queries';
import { CloseAccountModal } from '../close-account-modal';

//region Main
export const ProfileSettingsActions = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { data: me, isLoading: isLoadingMe } = useMeQuery();
  const isAnyWorkspaceOwner = !!me?.workspaces?.find(
    workspace => workspace.role === 'owner'
  );
  const [exportMe, exporting] = useExportMe();

  return (
    <DSContentBlock>
      <DSContentBlock.Title>Account actions</DSContentBlock.Title>
      <DSContentBlock.Content>
        <ContentWrapper>
          <DSButton
            onClick={() => !exporting && exportMe()}
            disabled={exporting}
            variant="secondary"
          >
            {exporting ? 'Exporting...' : 'Export profile data'}
          </DSButton>
          <DSButton
            variant="destructive-secondary"
            disabled={isLoadingMe || isAnyWorkspaceOwner}
            data-rh={
              isAnyWorkspaceOwner &&
              'You cannot close account as you are the owner of at least one workspace.'
            }
            onClick={() => setDeleteModalOpen(true)}
          >
            Close account
          </DSButton>
        </ContentWrapper>
        {!isAnyWorkspaceOwner && (
          <CloseAccountModal
            open={deleteModalOpen}
            onClose={() => {
              setDeleteModalOpen(false);
            }}
          />
        )}
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};
//endregion
const ContentWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
