import React from 'react';
import styled from '@emotion/styled';
import { BooleanValue } from 'react-values';

import { CustomContextMenu } from '@/features/common';
import { IWorkspaceCandidate } from '@/features/workspace';
import { useHistory, useWorkspaceId } from '@/hooks/router';
import { getCompositeKey } from '@/utils/keys';

interface CandidateJobOpeningsProps {
  candidate: IWorkspaceCandidate;
  disabled: boolean;
}

export function CandidateJobOpenings({
  candidate,
  disabled,
}: CandidateJobOpeningsProps) {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const mainJobOpening = candidate.jobOpenings[0];
  const extraJobOpenings = candidate.jobOpenings.slice(1);

  const goToJobOpening = React.useCallback(
    jobOpeningId => {
      !disabled &&
        history.push(
          `/redirect/candidate/${workspaceId}/${jobOpeningId}/${candidate.id}`
        );
    },
    [candidate, disabled, history, workspaceId]
  );

  return (
    <Wrapper>
      <MainJobOpening clickable={extraJobOpenings.length > 0}>
        {mainJobOpening.name}
      </MainJobOpening>
      {extraJobOpenings.length > 0 ? (
        <BooleanValue>
          {({ value, set }) => (
            <ExtraJobOpeningsWrapper
              onMouseEnter={() => !disabled && set(true)}
              onMouseLeave={() => !disabled && set(false)}
            >
              <ExtraJobOpeningsBadge
                key={getCompositeKey('job-openings', candidate.id)}
                color="purple"
                disabled={disabled}
              >{`+${candidate.jobOpenings.length - 1}`}</ExtraJobOpeningsBadge>
              <ContextMenuWrapper>
                <ContextMenu open={value}>
                  <ExtraJobOpeningsList>
                    {extraJobOpenings.map(jobOpening => (
                      <ExtraJobOpeningsItem
                        key={getCompositeKey(candidate.id, jobOpening.id)}
                        onClick={event => {
                          event.stopPropagation();
                          goToJobOpening(jobOpening.id);
                        }}
                      >
                        {jobOpening.name}
                      </ExtraJobOpeningsItem>
                    ))}
                  </ExtraJobOpeningsList>
                </ContextMenu>
              </ContextMenuWrapper>
            </ExtraJobOpeningsWrapper>
          )}
        </BooleanValue>
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const MainJobOpening = styled.span<{ clickable: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;
const ExtraJobOpeningsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const ExtraJobOpeningsBadge = styled.div<{ disabled: boolean }>`
  box-sizing: content-box;
  height: 16px;
  padding: 0 8px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.purple[100]};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.purple[40]};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? theme.colors.purple[5] : theme.colors.purple[100]};
  }
`;
const ContextMenuWrapper = styled.div`
  position: absolute;
  padding-top: 10px;
  top: 20px;
  right: 0;
  z-index: 100;
`;
const ExtraJobOpeningsList = styled.ul`
  padding: 4px 0;
  margin: 0;
  list-style: none;
`;
const ExtraJobOpeningsItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.colors.purple[5]};
  }
`;

const ContextMenu = styled(CustomContextMenu)`
  min-width: 180px;

  &::after {
    right: 12px;
  }
`;
