import { useLayoutEffect, useState } from 'react';

export function useOverflow(element: HTMLElement | null) {
  const [overflow, setOverflow] = useState({
    hasHorizontalOverflow: false,
    hasVerticalOverflow: false,
    hasLeftOverflow: false,
    hasRightOverflow: false,
    hasTopOverflow: false,
    hasBottomOverflow: false,
  });

  useLayoutEffect(() => {
    if (element) {
      const {
        scrollWidth,
        scrollHeight,
        clientWidth,
        clientHeight,
        scrollLeft,
        scrollTop,
      } = element;

      setOverflow({
        hasHorizontalOverflow: scrollWidth > clientWidth,
        hasVerticalOverflow: scrollHeight > clientHeight,
        hasLeftOverflow: scrollLeft > 0,
        hasRightOverflow: scrollWidth - scrollLeft > clientWidth,
        hasTopOverflow: scrollTop > 0,
        hasBottomOverflow: scrollHeight - scrollTop > clientHeight,
      });
    }
  }, [element]);

  return {
    hasOverflow: overflow.hasHorizontalOverflow || overflow.hasVerticalOverflow,
    ...overflow,
  };
}
