import {
  useMutation,
  UseMutationOptions,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { PIPELINE_CATEGORIES_KEY } from '@/features/pipeline';
import {
  createTest,
  fetchTest,
  ITest,
  updateTest,
  convertIntoCustomTest,
} from '@/features/test';

export const TEST_QUERY_KEY = ['test'];

export const useTestQuery = (
  params: { testId?: TId },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...TEST_QUERY_KEY, params.testId],
    () => fetchTest(params as { testId: string }),
    {
      enabled: !!params.testId,
      ...(options as any),
      meta: {
        ...options?.meta,
        skipDefaultErrorHandler:
          options?.meta?.skipDefaultErrorHandler ?? false,
      },
    }
  );
};

export const useTestsQuery = (
  params: { testIds?: TId[] },
  options?: UseQueryOptions
) => {
  return useQueries({
    queries: (params.testIds || []).map<UseQueryOptions<ITest>>(testId => {
      return {
        queryKey: [...TEST_QUERY_KEY, testId],
        queryFn: () => fetchTest({ testId }),
        ...(options as any),
      };
    }),
  });
};

export const useUpdateTestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateTest, {
    onMutate: async ({ testId, test }) => {
      await queryClient.cancelQueries([...TEST_QUERY_KEY, testId]);
      const previousTest = queryClient.getQueryData([
        ...TEST_QUERY_KEY,
        testId,
      ]);
      queryClient.setQueryData<Partial<ITest> | undefined>(
        [...TEST_QUERY_KEY, testId],
        oldTest => ({
          ...oldTest,
          ...test,
        })
      );

      return { previousTest };
    },
    onError: (_, variables, context) => {
      queryClient.setQueryData(
        [...TEST_QUERY_KEY, variables.testId],
        context?.previousTest
      );
      queryClient.invalidateQueries([...TEST_QUERY_KEY, variables.testId]);
    },
  });
};

export const useCreateTestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createTest, {
    onSuccess: test => {
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CATEGORIES_KEY, test.jobOpeningId],
      });
    },
  });
};

export const useConvertTestIntoCustomTestMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(convertIntoCustomTest, {
    onSuccess(test) {
      queryClient.invalidateQueries({
        queryKey: [...TEST_QUERY_KEY, test.id],
      });
    },
    ...(options as any),
  });
};
