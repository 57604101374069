import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock, DSGrid } from '@hundred5/design-system';

import { useWorkspaceTreatments } from '@/components/splits';
import { Loader } from '@/features/common/components/loader';
import {
  TATSIntegrationType,
  useJobOpeningATSIntegrationSettingsQuery,
  useATSIntegrationsQuery,
} from '@/features/integrations';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { ATSIntegration, SlackIntegration } from './ui';

export function JobOpeningIntegrations() {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const atsIntegrationsQuery = useATSIntegrationsQuery();
  const jobOpeningATSIntegrationSettingsQuery = useJobOpeningATSIntegrationSettingsQuery();
  const { fe_admin_app_integration_enabled } = useWorkspaceTreatments([
    'fe_admin_app_integration_enabled',
  ]);
  const isATSIntegrationsEnabled =
    fe_admin_app_integration_enabled.treatment === 'on';

  const isLoading =
    atsIntegrationsQuery.isLoading ||
    jobOpeningATSIntegrationSettingsQuery.isLoading;

  const atsIntegrationFor = (type: TATSIntegrationType) =>
    atsIntegrationsQuery.data?.find(
      integration => integration.integrationType === type
    );
  const jobOpeningATSIntegrationSettingsFor = (type: TATSIntegrationType) =>
    jobOpeningATSIntegrationSettingsQuery.data?.find(
      integration => integration.integrationType === type
    );

  return (
    <DSContentBlock>
      <DSContentBlock.Title>Integrations</DSContentBlock.Title>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <>
          <DSContentBlock.Content>
            <DSGrid gap="16px">
              {isATSIntegrationsEnabled && (
                <>
                  <ATSIntegration
                    type="greenhouse"
                    atsIntegration={atsIntegrationFor('greenhouse')}
                    jobOpeningATSIntegrationSettings={jobOpeningATSIntegrationSettingsFor(
                      'greenhouse'
                    )}
                  />
                  <Delimiter />
                  <ATSIntegration
                    type="workable"
                    atsIntegration={atsIntegrationFor('workable')}
                    jobOpeningATSIntegrationSettings={jobOpeningATSIntegrationSettingsFor(
                      'workable'
                    )}
                  />
                  <Delimiter />
                </>
              )}
              <SlackIntegration />
              {!!atsIntegrationsQuery.data?.length && <Delimiter />}
            </DSGrid>
          </DSContentBlock.Content>
          {!!atsIntegrationsQuery.data?.length && (
            <DSContentBlock.Footer>
              <DSButton
                variant="secondary"
                size="xsmall"
                onClick={() =>
                  history.push(`/admin/${workspaceId}/settings/integrations`)
                }
              >
                Manage your integrations
              </DSButton>
            </DSContentBlock.Footer>
          )}
        </>
      )}
    </DSContentBlock>
  );
}

export const Delimiter = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
