import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import formatDate from 'date-fns/format';

import { Icon, TableColumn, TableRow, UserRoleTag } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { useWorkspacePermission } from '@/features/permissions';
import {
  TWorkspaceInvitation,
  isGreaterOrEqual,
  useWorkspace,
} from '@/features/workspace';
import {
  useDeleteWorkspaceInvitationMutation,
  useResendWorkspaceInvitationMutation,
} from '@/features/workspace/queries/workspace-invitations';
import { useWorkspaceId } from '@/hooks/router';

//region Main
interface InvitationProps {
  invitation: TWorkspaceInvitation;
  onDelete: (invitation: TWorkspaceInvitation) => void;
}

export const Invitation = ({ invitation, onDelete }: InvitationProps) => {
  const workspaceId = useWorkspaceId();
  const meQuery = useMeQuery();
  const workspace = useWorkspace();

  const canDeleteInvitations = useWorkspacePermission()(
    'workspace invitations',
    'delete'
  );

  // Has rights + is inviter or has greater or equal role than invitation
  const canManageInvitation =
    canDeleteInvitations &&
    (invitation.inviterId === meQuery.data?.id ||
      (workspace && isGreaterOrEqual(workspace?.role, invitation.role)));

  const resendWorkspaceInvitationMutation = useResendWorkspaceInvitationMutation();
  const deleteWorkspaceInvitationMutation = useDeleteWorkspaceInvitationMutation();

  return (
    <Row>
      <TableColumn width="33%">{invitation.email}</TableColumn>
      <TableColumn width="33%">
        <UserRoleTag role={invitation.role} />
      </TableColumn>
      <TableColumn width="16%">
        <Date>{formatDate(invitation.invitedAt, 'MMM D, YYYY')}</Date>
      </TableColumn>

      <TableColumn width="10%">
        {canManageInvitation && (
          <DSButton
            variant="secondary"
            size="xsmall"
            disabled={resendWorkspaceInvitationMutation.isLoading}
            onClick={() =>
              resendWorkspaceInvitationMutation.mutate({
                invitationId: invitation.id,
                workspaceId,
                attributes: invitation,
              })
            }
          >
            {!resendWorkspaceInvitationMutation.isLoading
              ? 'Resend invite'
              : 'Sending...'}
          </DSButton>
        )}
      </TableColumn>
      <TableColumn width="7%">
        {canManageInvitation && (
          <DSButton
            variant="tertiary"
            size="small"
            disabled={deleteWorkspaceInvitationMutation.isLoading}
            onClick={() => {
              onDelete(invitation);
            }}
            Icon={<Icon icon={regular('trash')} />}
          />
        )}
      </TableColumn>
    </Row>
  );
};
//endregion

//region Styles
const Date = styled.div`
  color: ${props => props.theme.colors.purple[60]};
  text-transform: uppercase;
  font-size: 10px;
  margin-right: 20px;
`;

const Row = styled(TableRow)`
  &:hover {
    background: none;
  }
`;
//endregion
