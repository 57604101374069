import React from 'react';

export function NoEmailIllustration() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="63.135"
        height="46.957"
        rx="6.16075"
        transform="matrix(0.662208 -0.74932 0.759674 0.650303 2.51855 49.4636)"
        fill="#FFC4BC"
      />
      <path
        d="M47.3383 21.8516L43.616 45.6856L22.5047 51.1831"
        stroke="#FF897A"
        strokeWidth="3.85047"
        strokeLinecap="round"
      />
    </svg>
  );
}
