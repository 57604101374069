import React, { useEffect, useRef, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton, InlineField, Radio } from '@hundred5/design-system';
import { FormikProps, FormikValues } from 'formik';

import {
  CreditsPurchase,
  CreditsPurchaseItemCode,
  useBillingInfoQuery,
  useCardInfoQuery,
  usePurchaseCreditsPreviewMutation,
  useSubscriptionQuery,
} from '@/features/billing';
import * as billingApi from '@/features/billing/api/billing';
import { useFlashMessages } from '@/features/flash-messages';
import { useWorkspaceQuery } from '@/features/workspace';
import { trackPurchaseCredits } from '@/googleTagManager';
import { useApiError } from '@/hooks/api';
import { useAsync } from '@/hooks/async';
import { getPlanName } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';
import { Id } from '@/types/misc';

import Modal from '../../modal/beta/Modal';
import PaymentChallenge from '../../payment_challenge/PaymentChallenge';
import BillingInfoForm from '../billing_info_form/BillingInfoForm';
import PurchaseCreditsPayment from '../purchase_credits_payment/PurchaseCreditsPayment';
import SubscribeForm from '../subscribe_form/SubscribeForm';

import './PurchaseCreditsModal.css';

interface PurchaseCreditsModalProps {
  open: boolean;
  onClose: () => void;
}

const PurchaseCreditsModal = ({ open, onClose }: PurchaseCreditsModalProps) => {
  const handleApiError = useApiError();
  const workspaceId = useWorkspaceId();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const formRef = useRef<FormikProps<FormikValues>>();
  const [recurlyHostedPage, setRecurlyHostedPage] = useState('');
  const [challengeToken, setChallengeToken] = useState<string>('');
  const currentPlan = workspaceQuery.data?.subscriptionPlan;
  const currentItemCode = (
    numberOfCandidates: 10 | 50
  ): CreditsPurchaseItemCode => {
    const itemCodes = {
      10: {
        premium: '10-candidates-premium-2023-v2',
        basic: '10-candidates-basic-2023-v2',
      },
      50: {
        premium: '50-candidates-premium-2023-v2',
        basic: '50-candidates-basic-2023-v2',
      },
    };
    return currentPlan
      ? itemCodes[numberOfCandidates][getPlanName(currentPlan)]
      : '';
  };

  const currentItemLabel = (numberOfCandidates: 10 | 50) => {
    if (!currentPlan) return '';
    if (getPlanName(currentPlan) === 'basic') {
      return `${numberOfCandidates} candidates for $${numberOfCandidates *
        10} (1 candidate for $10)`;
    } else if (getPlanName(currentPlan) === 'premium') {
      return `${numberOfCandidates} candidates for $${numberOfCandidates *
        15} (1 candidate for $15)`;
    }
  };

  const [itemCode, setItemCode] = useState<CreditsPurchaseItemCode>(
    currentItemCode(10)
  );

  const [
    creditsPurchase,
    setCreditsPurchase,
  ] = useState<CreditsPurchase | null>(null);

  const { isLoading: isLoadingSubscription } = useSubscriptionQuery();
  const {
    data: billingInfo,
    isLoading: isLoadingBilling,
  } = useBillingInfoQuery();
  const { data: cardInfo, isLoading: isLoadingCardInfo } = useCardInfoQuery({
    onSuccess: async cardInfo => {
      if (cardInfo && itemCode) {
        try {
          const preview = await billingApi.purchaseCreditsPreview(
            workspaceId,
            itemCode
          );
          setCreditsPurchase(preview);
        } catch (error) {
          handleApiError(error);
        }
      }
    },
  });

  const initializing =
    isLoadingBilling || isLoadingSubscription || isLoadingCardInfo;
  const [submit, { loading: submitting }] = useSubmit(
    onClose,
    setChallengeToken
  );
  const {
    mutateAsync: purchaseCreditsPreview,
  } = usePurchaseCreditsPreviewMutation();

  const needsBillingInfo = cardInfo == null || billingInfo == null;
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };
  useEffect(() => {
    if (open) {
      billingApi.fetchRecurlyHostedPage(workspaceId).then(setRecurlyHostedPage);
    }
  }, [workspaceId, open]);

  return (
    <Modal fluid layer={2} open={open} onClose={onClose}>
      <div
        className="purchase-credits-modal"
        data-testid="purchase-credits-modal"
      >
        <Modal.CloseButton fixed onClick={onClose} />

        <div className="purchase-credits-modal__title">
          Buy more candidate credits
        </div>

        <Modal.Separator />

        {initializing ? (
          <div className="purchase-credits-modal__loading">Loading...</div>
        ) : (
          <React.Fragment>
            <div className="purchase-credits-modal__options">
              <InlineField
                for={currentItemCode(10)}
                label={currentItemLabel(10)}
                input={
                  <Radio
                    id={currentItemCode(10)}
                    type="radio"
                    name="candidate-credits"
                    checked={itemCode === currentItemCode(10)}
                    onChange={() => setItemCode(currentItemCode(10))}
                  />
                }
              />
              <InlineField
                for={currentItemCode(50)}
                label={currentItemLabel(50)}
                input={
                  <Radio
                    id={currentItemCode(50)}
                    type="radio"
                    name="candidate-credits"
                    checked={itemCode === currentItemCode(50)}
                    onChange={() => setItemCode(currentItemCode(50))}
                  />
                }
              />
            </div>

            <Modal.Separator />

            <div className="purchase-credits-modal__payment">
              {itemCode && creditsPurchase ? (
                <PurchaseCreditsPayment
                  itemCode={itemCode}
                  preview={creditsPurchase}
                />
              ) : null}
            </div>

            <Modal.EdgeContent>
              <div className="purchase-credits-modal__footer">
                {needsBillingInfo ? (
                  <>
                    <BillingInfoForm
                      formRef={formRef}
                      onFinish={() =>
                        purchaseCreditsPreview({ itemCode: itemCode! })
                      }
                    />
                    <FormFooter>
                      <HostedPage>
                        Having issues? Update payment info
                        <a
                          href={`https://${recurlyHostedPage}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>
                      </HostedPage>
                      <DSButton
                        type="submit"
                        onClick={() => handleSubmit()}
                        disabled={formRef.current?.isSubmitting}
                      >
                        {!formRef.current?.isSubmitting ? (
                          <>Review purchase</>
                        ) : (
                          'Saving...'
                        )}
                      </DSButton>
                    </FormFooter>
                  </>
                ) : challengeToken ? (
                  <PaymentChallenge
                    token={challengeToken}
                    onFinish={token =>
                      submit(workspaceId, itemCode!, creditsPurchase, token.id)
                    }
                    onError={() => setChallengeToken('')}
                  />
                ) : (
                  <>
                    <SubscribeForm
                      saving={submitting}
                      onSubscribe={() =>
                        submit(workspaceId, itemCode!, creditsPurchase)
                      }
                    />
                  </>
                )}
              </div>
            </Modal.EdgeContent>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};

const useSubmit = (
  onClose: () => void,
  onChallengeRequired: (token: string) => void
) => {
  const handleApiError = useApiError();
  const { showFlashMessage } = useFlashMessages();

  return useAsync(
    async (
      workspaceId: Id,
      itemCode: CreditsPurchaseItemCode,
      preview: CreditsPurchase | null,
      challengeToken?: string
    ) => {
      try {
        await billingApi.purchaseCredits(workspaceId, itemCode, challengeToken);
        showFlashMessage({ type: 'saved' });
      } catch (error) {
        if (error instanceof billingApi.CardInfoChallengeError) {
          onChallengeRequired(error.token);
        } else {
          handleApiError(error);
        }

        return;
      }

      amplitude?.logEvent('billing/purchase candidate credits', {
        'workspace id': workspaceId,
        itemCode,
      });

      if (preview != null && preview.chargeInvoice != null) {
        const total = preview.chargeInvoice.totalInCents;
        trackPurchaseCredits(itemCode, total);
      }

      onClose();
    }
  );
};

const HostedPage = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: auto;
  font-style: italic;

  a {
    color: ${props => props.theme.colors.orange[100]};
    margin-left: 3px;
  }
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
`;

export default PurchaseCreditsModal;
