import { debounce } from 'lodash';

import { trackIntercomEvent } from '@/intercom';

export function trackQueriedSkills(searchQuery: string) {
  return debounce(() => {
    const trimmedSearchQuery = searchQuery.trim();

    if (trimmedSearchQuery) {
      trackIntercomEvent('skill_searched', {
        skill: trimmedSearchQuery,
      });
    }
  }, 1500);
}
