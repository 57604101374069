import React, { useEffect } from 'react';
import { DSButton, DSModal } from '@hundred5/design-system';
import { useMedia } from 'react-use';

import { Container, Content, Footer, Header, Title } from './ui';

interface ConfirmModalProps {
  header?: string;
  message?: string;
  onClose: () => void;
  open: boolean;
  url: string;
}

export function DownloadModal({
  header = 'Almost there!',
  message = 'Your file is ready, click the button below to download it.',
  onClose,
  open,
  url,
}: ConfirmModalProps) {
  const isMobile = useMedia('(max-width: 768px)');

  useEffect(() => {
    if (open && isMobile) {
      window.scroll({ top: window.innerHeight });
    }
  }, [open, isMobile]);

  return (
    <DSModal fluid open={open} onClose={onClose}>
      <Container data-testid="download-modal">
        <Header>
          <Title>{header}</Title>
          <DSModal.Separator />
        </Header>
        {!isMobile && <DSModal.CloseButton fixed onClick={onClose} small />}
        <Content>
          <p>{message}</p>
        </Content>
        <Footer float="right">
          <DSButton
            //@ts-ignore `as` is a valid prop for styled components and works as expected
            as="a"
            data-testid="download-modal-action-button"
            download
            href={url}
            onClick={onClose}
            target="_blank"
          >
            Download
          </DSButton>
        </Footer>
      </Container>
    </DSModal>
  );
}
