import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { loggedIn } from '@/actions/authentication';
import { setAuthToken } from '@/api/api';
import { authenticate } from '@/api/authentication';
import { storeAuthToken } from '@/authentication';
import { LogInCredentials } from '@/types/authentication';

type LoginHook = (credentials: LogInCredentials) => void;

export function useLogin(): LoginHook {
  const dispatch = useDispatch();

  return useCallback(
    async (credentials: LogInCredentials) => {
      const token = await authenticate(credentials);

      storeAuthToken(token);
      setAuthToken(token);

      dispatch(loggedIn());
    },
    [dispatch]
  );
}
