import { useDashboardJobOpeningsQuery } from '@/features/dashboard';
import { useWorkspace } from '@/features/workspace';
import { isPaidPlan } from '@/hooks/planLimits'; // TODO: move this to features/common/hooks
import { useWorkspaceId } from '@/hooks/router'; // TODO: move this to features/common/hooks

export type TButtonVariant = 'primary' | 'secondary';

export const useButtonVariant = (defaultVariant: TButtonVariant) => {
  const workspace = useWorkspace();
  const workspaceId = useWorkspaceId();
  const dashboardJobOpeningsQuery = useDashboardJobOpeningsQuery({
    workspaceId,
    filter: 'open',
  });
  const openings = dashboardJobOpeningsQuery.data?.openings;
  const totalCandidates = openings
    ? openings.reduce(
        (total, jobOpening) => total + jobOpening.totalCandidates,
        0
      )
    : 0;

  return (workspace && isPaidPlan(workspace.subscriptionPlan)) ||
    totalCandidates >= 2
    ? defaultVariant
    : getOppositeVariant(defaultVariant);
};

const getOppositeVariant = (variant: TButtonVariant) =>
  variant === 'primary' ? 'secondary' : 'primary';
