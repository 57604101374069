import * as React from 'react';
import Transition, {
  ENTERED,
  ENTERING,
  EXITED,
  EXITING,
} from 'react-transition-group/Transition';

const HEIGHT_TIMEOUT = 200;
const OPACITY_TIMEOUT = 200;
const TIMEOUT = HEIGHT_TIMEOUT + OPACITY_TIMEOUT;

const ENTER_TRANSITION = [
  `opacity ${OPACITY_TIMEOUT}ms ${HEIGHT_TIMEOUT}ms`,
  `height ${HEIGHT_TIMEOUT}ms`,
  `margin ${HEIGHT_TIMEOUT}ms`,
].join(', ');

const EXIT_TRANSITION = [
  `opacity ${OPACITY_TIMEOUT}ms`,
  `height ${HEIGHT_TIMEOUT}ms ${OPACITY_TIMEOUT}ms`,
  `margin ${HEIGHT_TIMEOUT}ms ${OPACITY_TIMEOUT}ms`,
].join(', ');

const STYLES = {
  [ENTERING]: { transition: ENTER_TRANSITION },
  [ENTERED]: { display: 'block' },
  [EXITING]: { transition: EXIT_TRANSITION },
  [EXITED]: { display: 'none' },
};

type CollapseTransitionProps = {
  children: React.ReactNode;
  in?: boolean;
};

class CollapseTransition extends React.Component<CollapseTransitionProps> {
  render() {
    const { children, ...props } = this.props;

    return (
      <Transition
        {...props}
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
        onExit={this.handleExit}
        onExiting={this.handleExiting}
        timeout={TIMEOUT}
      >
        {state => <div style={STYLES[state]}>{children}</div>}
      </Transition>
    );
  }

  handleEnter = (element: HTMLElement) => {
    element.style.height = '0';
    element.style.margin = '0';
    element.style.opacity = '0';
  };

  handleEntering = (element: HTMLElement) => {
    const height = this.getElementContentHeight(element);

    element.scrollTop; // eslint-disable-line no-unused-expressions

    element.style.height = height != null ? `${height}px` : 'auto';
    element.style.margin = '';
    element.style.opacity = '';
  };

  handleEntered = (element: HTMLElement) => {
    element.style.height = '';
  };

  handleExit = (element: HTMLElement) => {
    const height = this.getElementContentHeight(element);
    element.style.height = height != null ? `${height}px` : 'auto';
  };

  handleExiting = (element: HTMLElement) => {
    element.scrollTop; // eslint-disable-line no-unused-expressions

    element.style.height = '0';
    element.style.margin = '0';
    element.style.opacity = '0';
  };

  getElementContentHeight(element: HTMLElement): number | null {
    const child = element.firstElementChild;

    if (child && child instanceof HTMLElement) {
      return child.offsetHeight;
    } else {
      return null;
    }
  }
}

export default CollapseTransition;
