import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import styled from '@emotion/styled';

export function DraggableWrapper({
  children,
  id,
  style = {},
  candidate,
  disabled = false,
}) {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
    data: {
      type: 'candidate',
      candidate,
    },
    disabled,
  });

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {isDragging ? <DragPlaceholder /> : children}
    </div>
  );
}

const DragPlaceholder = styled.div`
  background: ${({ theme }) => theme.colors.peach[80]};
  border-radius: 8px;
  height: 88px;
  margin-left: 8px;
  width: 276px;
`;
