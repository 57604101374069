import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { TCandidateHistoryItem } from '@/features/candidate';
import { Tags, TimelinePoint } from '@/features/common';

import {
  formatContactInfoField,
  formatScore,
  formatTagName,
  formatTimestamp,
  getStateChangeTitle,
} from '../candidate-history.utils';

import { EmbeddedContent, ItemIcon, JobLink } from '.';

interface ItemProps {
  item: TCandidateHistoryItem;
  first?: boolean;
  last?: boolean;
}

export function Item({ item, first, last }: ItemProps) {
  const base = {
    icon: <ItemIcon action={item.action} />,
    subtitle: formatTimestamp(item.timestamp),
    first,
    last,
  };
  const byUser = item.user?.name ? ` by ${item.user.name}` : null;

  switch (item.action) {
    case 'login':
      return (
        <TimelinePoint
          title={<strong>Logged in</strong>}
          details={
            item.source ? (
              <EmbeddedContent>Traffic source: {item.source}</EmbeddedContent>
            ) : null
          }
          {...base}
        />
      );

    case 'test-started':
      return (
        <TimelinePoint
          title={<strong>Test started</strong>}
          details={<EmbeddedContent>{item.args.name}</EmbeddedContent>}
          {...base}
        />
      );

    case 'test-finished':
      return (
        <TimelinePoint
          title={<strong>Test finished</strong>}
          details={
            <EmbeddedContent>
              {item.args.name}. Initial score: {formatScore(item.args.score)}
            </EmbeddedContent>
          }
          {...base}
        />
      );

    case 'note':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Note added</strong>
              {byUser}
            </>
          }
          details={
            item.args.note ? (
              <EmbeddedContent>{item.args.note}</EmbeddedContent>
            ) : null
          }
          {...base}
        />
      );

    case 'tag-added':
      const isInvitedTag = item.args.name === 'invited';
      return (
        <TimelinePoint
          title={
            <>
              <strong>
                {isInvitedTag ? 'Candidate invited' : 'Tag added'}
              </strong>
              {byUser}
            </>
          }
          details={
            !isInvitedTag ? (
              <Tags>
                <Tags.Item
                  label={formatTagName(item.args)}
                  textColor="purple-100"
                  backgroundColor="purple-5"
                />
              </Tags>
            ) : null
          }
          {...base}
        />
      );

    case 'tag-removed':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Tag removed</strong>
              {byUser}
            </>
          }
          details={
            <Tags>
              <Tags.Item
                label={formatTagName(item.args)}
                textColor="purple-100"
                backgroundColor="purple-5"
              />
            </Tags>
          }
          {...base}
        />
      );

    case 'tags-added':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Tags added</strong>
              {byUser}
            </>
          }
          details={
            <Tags>
              {item.args.tags.map(tag => (
                <Tags.Item
                  key={tag.id}
                  label={formatTagName(tag)}
                  textColor="purple-100"
                  backgroundColor="purple-5"
                />
              ))}
            </Tags>
          }
          {...base}
        />
      );

    case 'communication': {
      const scheduled = item.args.sendAt
        ? item.args.sendAt.setSeconds(0, 0) > item.timestamp.setSeconds(0, 0)
        : false;
      const timestamp = item.args.sendAt && formatTimestamp(item.args.sendAt);

      return (
        <TimelinePoint
          title={
            <>
              <strong>{item.user?.name}</strong>
              {scheduled
                ? ` scheduled an email for ${timestamp}`
                : ' sent an email'}
            </>
          }
          details={
            item.args.message && (
              <EmbeddedContent fullWidth>
                {ReactHtmlParser(item.args.message)}
              </EmbeddedContent>
            )
          }
          toggleDetails
          {...base}
        />
      );
    }

    case 'score-change':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Question evaluated</strong>
              {byUser}
            </>
          }
          details={
            <EmbeddedContent>
              Score changed from <strong>{formatScore(item.args.from)}</strong>{' '}
              to <strong>{formatScore(item.args.to)}</strong>
            </EmbeddedContent>
          }
          {...base}
        />
      );

    case 'state-change':
      const title = getStateChangeTitle(item.args.from, item.args.to);
      if (title == null) return null;

      return (
        <TimelinePoint
          title={
            <>
              <strong>{title}</strong>
              {byUser}
            </>
          }
          {...base}
        />
      );

    case 'pipeline-change':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Pipeline stage changed</strong>
              {byUser}
            </>
          }
          details={
            <EmbeddedContent>
              Moved{' '}
              {item.args.from ? (
                <>
                  from <strong>{item.args.from.name}</strong>
                </>
              ) : null}{' '}
              {item.args.to ? (
                <>
                  to <strong>{item.args.to.name}</strong>
                </>
              ) : null}
            </EmbeddedContent>
          }
          {...base}
        />
      );

    case 'test-feedback':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Video test feedback sent</strong>
              {byUser}
            </>
          }
          {...base}
        />
      );

    case 'contact-info-change':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Contact info updated</strong>
              {byUser}
            </>
          }
          details={
            <EmbeddedContent>
              Changed {formatContactInfoField(item.args.field)}{' '}
              {item.args.from ? (
                <>
                  from <strong>{item.args.from}</strong>
                </>
              ) : (
                <>from the default value</>
              )}{' '}
              {item.args.to ? (
                <>
                  to <strong>{item.args.to}</strong>
                </>
              ) : (
                <>to the default value</>
              )}
            </EmbeddedContent>
          }
          {...base}
        />
      );

    case 'video-intro-reset':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Video test reset</strong>
              {byUser}
            </>
          }
          {...base}
        />
      );

    case 'added-to-job-opening':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Candidate added to job opening</strong>
              {byUser}
            </>
          }
          {...base}
        />
      );

    case 'test-state-change':
      return (
        <TimelinePoint
          title={<strong>Test state change</strong>}
          details={
            <EmbeddedContent>
              {item.args.name}. Changed to:{' '}
              <strong>{(item.args.newState || '').replace(/_/g, ' ')}</strong>
            </EmbeddedContent>
          }
          {...base}
        />
      );

    case 'test-reset':
      return (
        <TimelinePoint
          title={
            <>
              <strong>Test reset</strong>
              {byUser}
            </>
          }
          details={<EmbeddedContent>{item.args.name}</EmbeddedContent>}
          {...base}
        />
      );

    case 'job-applied-for':
      return (
        <TimelinePoint
          title={
            <strong>
              Applied for{' '}
              <JobLink
                jobOpeningId={item.args.jobOpeningId}
                jobOpeningName={item.args.jobOpeningName}
              />
            </strong>
          }
          {...base}
        />
      );

    default:
      return null;
  }
}
