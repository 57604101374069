import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Form, Formik } from 'formik';

import { Icon, Popover } from '@/features/common';

import { ApplyButton } from '../buttons';
import {
  FullWidthPopover,
  IconWrapper,
  SubContent,
  Trigger,
} from '../filters-select/ui/popover';

import { DatePicker } from './ui/date-picker';

export const DATE_FILTER_TYPE = 'date';

export const DateFilter = ({ initialValues, onApply, name }) => {
  return (
    <FullWidthPopover>
      <Popover.Trigger>
        {({ onClick }) => (
          <Trigger onClick={onClick}>
            <IconWrapper>
              <Icon icon={regular('calendar-day')} color="purple-60" />
            </IconWrapper>
            {name}
          </Trigger>
        )}
      </Popover.Trigger>
      <SubContent position="right">
        {({ close }) => (
          <Formik
            initialValues={initialValues}
            onSubmit={values => {
              onApply(values);
              close();
            }}
          >
            <Form>
              <DatePicker />
              <ApplyButton />
            </Form>
          </Formik>
        )}
      </SubContent>
    </FullWidthPopover>
  );
};
