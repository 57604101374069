import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { useTogglAccountsMeQuery } from '@/features/toggl-accounts';
import { useHistory, useWorkspaceIdOrNull } from '@/hooks/router';

//region Main
export const ProfileSettings = () => {
  const history = useHistory();
  const workspaceId = useWorkspaceIdOrNull();
  const { data: togglAccountsMeData } = useTogglAccountsMeQuery();

  return (
    <Content>
      <Header>
        <Title>Personal details & preferences</Title>
        <SubTitle>
          Change details, login methods and your password in Account settings.
        </SubTitle>
      </Header>
      <DataList>
        <DataTerm>Full name</DataTerm>
        <DataDescription>{togglAccountsMeData?.displayName}</DataDescription>
        <DataTerm>Email</DataTerm>
        <DataDescription>{togglAccountsMeData?.email}</DataDescription>
        <DataTerm>Timezone</DataTerm>
        <DataDescription>{togglAccountsMeData?.timezone}</DataDescription>
        <DataTerm>Google sign-in</DataTerm>
        <DataDescription>
          {togglAccountsMeData?.authMethods.includes('google')
            ? 'Enabled'
            : 'Not enabled'}
        </DataDescription>
        <DataTerm>Apple sign-in</DataTerm>
        <DataDescription>
          {togglAccountsMeData?.authMethods.includes('apple')
            ? 'Enabled'
            : 'Not enabled'}
        </DataDescription>
      </DataList>
      <DSButton
        variant="secondary"
        onClick={() =>
          history.push(
            workspaceId ? `/admin/${workspaceId}/account` : '/admin/account'
          )
        }
        Icon={<Icon icon={regular('gear')} fontSize={12} />}
      >
        Account settings
      </DSButton>
    </Content>
  );
};
//endregion

const Content = styled.div`
  margin-bottom: 48px;
`;

const Header = styled.div`
  margin-left: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
`;
const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;
const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
const DataList = styled.dl`
  padding-left: 12px;
  align-items: baseline;
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 16px 0;
  row-gap: 4px;
`;
const DataTerm = styled.dt`
  color: ${props => props.theme.typography.colorSecondary};
  font-size: 11px;
  letter-spacing: 0.0275em;
  line-height: 16px;
  margin: 0 24px 0 0;
  text-transform: uppercase;
`;
const DataDescription = styled.dd`
  margin: 0;
`;
