import { PlanLevel } from '@/hooks/planLimits';

import { BillingPlan } from '../types';

export type PremiumPlanVariant = '300c' | '60c' | '45c';

export interface IBillingPlansDescription {
  plan: 'free' | 'basic' | 'premium' | 'business';
  planLevel: PlanLevel;
  variant?: PremiumPlanVariant;
  planCode: {
    monthly: BillingPlan;
    annual: BillingPlan;
  };
  description: string;
  price: {
    monthly: { perMonth: number; perYear: number };
    annual: { perMonth: number; perYear: number };
  };
  limits: string[];
  features: string[];
}

interface IBillingPlansConfig {
  plans: IBillingPlansDescription[];
  yearlyPlansDiscountPercentage: number;
  premiumPlanVariants?: PremiumPlanVariant[];
}

export const billingPlansDescriptionsExp2023: IBillingPlansConfig = {
  yearlyPlansDiscountPercentage: 20,
  plans: [
    {
      plan: 'business',
      planLevel: 'business-exp-2023',
      planCode: {
        monthly: 'business-1m-exp-2023',
        annual: 'business-12m-exp-2023',
      },
      description:
        'Unlimited hiring solution for bigger teams and hyper-growth scale-ups.',
      price: {
        monthly: { perMonth: 599, perYear: 599 * 12 },
        annual: { perMonth: 469, perYear: 5628 },
      },
      limits: ['Unlimited candidates', 'Unlimited jobs', 'Unlimited tests'],
      features: [
        'Unlimited usage',
        'Effortlessly perform bulk actions on selected candidates with one click',
        'Manage all of your email templates from one place (coming soon)',
      ],
    },
    {
      plan: 'premium',
      planLevel: 'premium-exp-2023',
      planCode: {
        monthly: 'premium-1m-exp-2023',
        annual: 'premium-12m-exp-2023',
      },

      description: 'Our most popular plan for startups and growing businesses.',
      price: {
        monthly: { perMonth: 379, perYear: 379 * 12 },
        annual: { perMonth: 299, perYear: 3588 },
      },
      limits: ['2000 candidates', 'Unlimited jobs', 'Unlimited tests'],
      features: [
        'Build a 100% custom test',
        'Trust the results you get with anti-cheating measures',
        'Unlimited access to the test library',
      ],
    },
    {
      plan: 'basic',
      planCode: {
        monthly: 'basic-12m-exp-2023',
        annual: 'basic-12m-exp-2023',
      },
      planLevel: 'basic-exp-2023',
      description: 'A pay as you go solution for  small businesses',
      price: {
        monthly: { perMonth: 17, perYear: 199 },
        annual: { perMonth: 17, perYear: 199 },
      },
      limits: ['20 candidates', 'Unlimited jobs', 'Unlimited tests'],
      features: [
        'Build a talent pool with customizable Candidate database',
        'Get full access to Reports & Analytics',
      ],
    },
    {
      plan: 'free',
      planCode: { monthly: 'free-exp-2023', annual: 'free-exp-2023' },
      planLevel: 'free-exp-2023',
      description:
        'Essential features for small businesses hiring occassionaly.',
      price: {
        monthly: { perMonth: 0, perYear: 0 },
        annual: { perMonth: 0, perYear: 0 },
      },
      limits: ['5 candidates', 'Unlimited jobs', 'Unlimited tests'],
      features: [
        'Put candidate screening on autopilot with a pass score',
        'Unlimited use of skills tests, video intros and homework assignments (coming soon)',
      ],
    },
  ],
};

export const billingPlansDescriptions: IBillingPlansConfig = {
  yearlyPlansDiscountPercentage: 20,
  plans: [
    {
      plan: 'business',
      planLevel: 'business',
      planCode: { monthly: 'business-1m-2021', annual: 'business-12m-2021' },

      description:
        'Complete hiring solution for remote enterprises and hyper-growth scale-ups.',
      price: {
        monthly: { perMonth: 499, perYear: 499 * 12 },
        annual: { perMonth: 469, perYear: 5628 },
      },
      limits: ['30 active jobs', 'Unlimited tests', 'Unlimited candidates'],
      features: [
        'Effortlessly perform bulk actions on selected candidates with one click',
        'Manage all of your email templates from one place',
        'Automatically redirect candidates to a chosen page with URL redirection',
        'Form your private question library (soon)',
      ],
    },
    {
      plan: 'premium',
      planLevel: 'premium',
      planCode: { monthly: 'premium-1m-2021', annual: 'premium-12m-2021' },
      description:
        'Advanced features for remote startups and growing businesses.',
      price: {
        monthly: { perMonth: 249, perYear: 249 * 12 },
        annual: { perMonth: 199, perYear: 2388 },
      },
      limits: ['10 active jobs', '5 tests per job', 'unlimited candidates'],
      features: [
        'Assess 180+ skills with full access to our ever improving test library',
        'Trust the results you get with anti-cheating measures',
        'Custom solutions with API access',
      ],
    },
    {
      plan: 'basic',
      planLevel: 'basic',
      planCode: { monthly: 'basic-12m-2021', annual: 'basic-12m-2021' },
      description: 'Compact set of features for small teams.',
      price: {
        monthly: { perMonth: 17, perYear: 199 },
        annual: { perMonth: 17, perYear: 199 },
      },
      limits: ['3 active jobs', '5 tests per job', '20 candidates'],
      features: [
        'Create a custom test with 15,000+ expert-created,qualifying questions',
        'Customize the landing pages to fit your company culture and branding',
      ],
    },
    {
      plan: 'free',
      planLevel: 'free-2021',
      planCode: { monthly: 'free-2021', annual: 'free-2021' },
      description:
        'Essential features for small businesses hiring occassionaly.',
      price: {
        monthly: { perMonth: 0, perYear: 0 },
        annual: { perMonth: 0, perYear: 0 },
      },
      limits: ['1 active jobs', '5 tests per job', '5 candidates'],
      features: [
        'Put candidate screening on autopilot with a pass score',
        'Stack different tests to create a role-specific, skills-based hiring pipeline',
      ],
    },
  ],
};

export const billingPlansDescriptions2023V2: IBillingPlansConfig = {
  premiumPlanVariants: ['60c', '300c'],
  yearlyPlansDiscountPercentage: 30,
  plans: [
    {
      plan: 'free',
      planLevel: 'free-v2-2023',
      planCode: { monthly: 'free-v2-2023', annual: 'free-v2-2023' },
      description:
        'Essential features for small businesses hiring occassionaly.',
      price: {
        monthly: { perMonth: 0, perYear: 0 },
        annual: { perMonth: 0, perYear: 0 },
      },
      limits: ['3 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Put candidate screening on autopilot with a pass score',
        'Unlimited use of skills tests, video intros and homework assignments',
        'Generate unique smart tests for each candidate',
        'Bulk invitations',
        'Instant candidate feedback',
      ],
    },
    {
      plan: 'basic',
      planLevel: 'basic-v2-2023',
      planCode: { monthly: 'basic-12m-v2-2023', annual: 'basic-12m-v2-2023' },
      description: 'Effortless hiring solution for small businesses.',
      price: {
        monthly: { perMonth: 25, perYear: 299 },
        annual: { perMonth: 25, perYear: 299 },
      },
      limits: ['20 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Everything in Free +',
        'Build a talent pool with a customizable Candidate database',
        'Full access to Reports & Analytics',
      ],
    },
    {
      plan: 'premium',
      planLevel: 'premium-v2-2023',
      variant: '60c',
      planCode: {
        monthly: 'premium-12m-60c-2023',
        annual: 'premium-12m-60c-2023',
      },
      description: 'Tailored to supercharge startups and growing businesses.',
      price: {
        monthly: { perMonth: 100, perYear: 1200 },
        annual: { perMonth: 100, perYear: 1200 },
      },
      limits: ['60 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Everything in Basic +',
        'Anti-cheating tracking and AI features',
        'Hire senior talent with Premium library of advanced questions',
        'Streamline hiring with automations',
        'Access to API and integrations',
        'Email login for candidates',
        'CSV and PDF candidate exports',
      ],
    },
    {
      plan: 'premium',
      planLevel: 'premium-v2-2023',
      variant: '300c',
      planCode: {
        monthly: 'premium-1m-v2-2023',
        annual: 'premium-12m-v2-2023',
      },
      description: 'Tailored to supercharge startups and growing businesses.',
      price: {
        monthly: { perMonth: 535, perYear: 535 * 12 },
        annual: { perMonth: 375, perYear: 4500 },
      },
      limits: ['300 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Everything in Basic +',
        'Anti-cheating tracking and AI features',
        'Hire senior talent with Premium library of advanced questions',
        'Streamline hiring with automations',
        'Access to API and integrations',
        'Email login for candidates',
        'CSV and PDF candidate exports',
      ],
    },
    {
      plan: 'business',
      planLevel: 'business-v2-2023',
      planCode: {
        monthly: 'business-1m-v2-2023',
        annual: 'business-12m-v2-2023',
      },
      description:
        'Fully customizable hiring solution for bigger teams and fast-growing organizations.',
      price: {
        monthly: { perMonth: 670, perYear: 670 * 12 },
        annual: { perMonth: 469, perYear: 5628 },
      },
      limits: [
        'Unlimited candidates',
        'Unlimited jobs and tests',
        'Unlimited users',
      ],
      features: [
        'Everything in Premium +',
        'Manage your own email template library',
        'Fully customizable candidate experience, including URL redirect',
        'Bulk actions to streamline candidate management',
      ],
    },
  ],
};

export const billingPlansDescriptions2024: IBillingPlansConfig = {
  premiumPlanVariants: ['45c', '60c', '300c'],
  yearlyPlansDiscountPercentage: 30,
  plans: [
    {
      plan: 'free',
      planLevel: 'free-v2-2023',
      planCode: { monthly: 'free-v2-2023', annual: 'free-v2-2023' },
      description:
        'Essential features for small businesses hiring occassionaly.',
      price: {
        monthly: { perMonth: 0, perYear: 0 },
        annual: { perMonth: 0, perYear: 0 },
      },
      limits: ['5 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Put candidate screening on autopilot with a pass score',
        'Unlimited use of skills tests, video intros and homework assignments',
        'Generate unique smart tests for each candidate',
        'Bulk invitations',
        'Instant candidate feedback',
      ],
    },
    {
      plan: 'basic',
      planLevel: 'basic-2024',
      planCode: { monthly: 'basic-12m-2024', annual: 'basic-12m-2024' },
      description: 'Effortless hiring solution for small businesses.',
      price: {
        monthly: { perMonth: 20, perYear: 240 },
        annual: { perMonth: 20, perYear: 240 },
      },
      limits: ['20 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Everything in Free +',
        'Build a talent pool with a customizable Candidate database',
        'Full access to Reports & Analytics',
      ],
    },
    {
      plan: 'premium',
      planLevel: 'premium-v2-2023',
      variant: '45c',
      planCode: {
        monthly: 'premium-12m-45c-2024',
        annual: 'premium-12m-45c-2024',
      },
      description: 'Tailored to supercharge startups and growing businesses.',
      price: {
        monthly: { perMonth: 75, perYear: 900 },
        annual: { perMonth: 75, perYear: 900 },
      },
      limits: ['45 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Everything in Basic +',
        'Anti-cheating tracking and AI features',
        'Hire senior talent with Premium library of advanced questions',
        'Streamline hiring with automations',
        'Access to API and integrations',
        'Email login for candidates',
        'CSV and PDF candidate exports',
      ],
    },
    {
      plan: 'premium',
      planLevel: 'premium-v2-2023',
      variant: '60c',
      planCode: {
        monthly: 'premium-12m-60c-2023',
        annual: 'premium-12m-60c-2023',
      },
      description: 'Tailored to supercharge startups and growing businesses.',
      price: {
        monthly: { perMonth: 100, perYear: 1200 },
        annual: { perMonth: 100, perYear: 1200 },
      },
      limits: ['60 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Everything in Basic +',
        'Anti-cheating tracking and AI features',
        'Hire senior talent with Premium library of advanced questions',
        'Streamline hiring with automations',
        'Access to API and integrations',
        'Email login for candidates',
        'CSV and PDF candidate exports',
      ],
    },
    {
      plan: 'premium',
      planLevel: 'premium-v2-2023',
      variant: '300c',
      planCode: {
        monthly: 'premium-1m-v2-2023',
        annual: 'premium-12m-v2-2023',
      },
      description: 'Tailored to supercharge startups and growing businesses.',
      price: {
        monthly: { perMonth: 535, perYear: 535 * 12 },
        annual: { perMonth: 375, perYear: 4500 },
      },
      limits: ['300 candidates', 'Unlimited jobs and tests', 'Unlimited users'],
      features: [
        'Everything in Basic +',
        'Anti-cheating tracking and AI features',
        'Hire senior talent with Premium library of advanced questions',
        'Streamline hiring with automations',
        'Access to API and integrations',
        'Email login for candidates',
        'CSV and PDF candidate exports',
      ],
    },
    {
      plan: 'business',
      planLevel: 'business-v2-2023',
      planCode: {
        monthly: 'business-1m-v2-2023',
        annual: 'business-12m-v2-2023',
      },
      description:
        'Fully customizable hiring solution for bigger teams and fast-growing organizations.',
      price: {
        monthly: { perMonth: 670, perYear: 670 * 12 },
        annual: { perMonth: 469, perYear: 5628 },
      },
      limits: [
        'Unlimited candidates',
        'Unlimited jobs and tests',
        'Unlimited users',
      ],
      features: [
        'Everything in Premium +',
        'Manage your own email template library',
        'Fully customizable candidate experience, including URL redirect',
        'Bulk actions to streamline candidate management',
      ],
    },
  ],
};

export const billingPlans: Partial<Record<
  BillingPlan,
  { name: string; type: 'Annually' | 'Monthly'; price: number }
>> = {
  'basic-1m-2021': {
    name: 'Basic',
    type: 'Monthly',
    price: 17,
  },
  'basic-12m-2021': {
    name: 'Basic',
    type: 'Annually',
    price: 17,
  },
  'basic-12m-exp-2023': {
    name: 'Basic',
    type: 'Annually',
    price: 17,
  },
  'basic-12m-basicexperiment-oct22': {
    name: 'Basic',
    type: 'Annually',
    price: 17,
  },
  'basic-12m-v2-2023': {
    name: 'Basic',
    type: 'Annually',
    price: 25,
  },
  'basic-12m-2024': {
    name: 'Basic',
    type: 'Annually',
    price: 20,
  },
  'premium-1m-2021': {
    name: 'Premium',
    type: 'Monthly',
    price: 249,
  },
  'premium-12m-2021': {
    name: 'Premium',
    type: 'Annually',
    price: 199,
  },
  'premium-1m-exp-2023': {
    name: 'Premium',
    type: 'Monthly',
    price: 379,
  },
  'premium-1m-v2-2023': {
    name: 'Premium',
    type: 'Monthly',
    price: 535,
  },
  'premium-12m-exp-2023': {
    name: 'Premium',
    type: 'Annually',
    price: 299,
  },
  'premium-12m-v2-2023': {
    name: 'Premium',
    type: 'Annually',
    price: 375,
  },
  'premium-12m-60c-2023': {
    name: 'Premium',
    type: 'Annually',
    price: 100,
  },
  'premium-12m-45c-2024': {
    name: 'Premium',
    type: 'Annually',
    price: 75,
  },
  'business-1m-2021': {
    name: 'Business',
    type: 'Monthly',
    price: 499,
  },
  'business-1m-v2-2023': {
    name: 'Business',
    type: 'Monthly',
    price: 670,
  },
  'business-12m-2021': {
    name: 'Business',
    type: 'Annually',
    price: 399,
  },
  'business-1m-exp-2023': {
    name: 'Business',
    type: 'Monthly',
    price: 599,
  },
  'business-12m-exp-2023': {
    name: 'Business',
    type: 'Annually',
    price: 469,
  },
  'business-12m-v2-2023': {
    name: 'Business',
    type: 'Annually',
    price: 469,
  },
};
