import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSCheckbox } from '@hundred5/design-system';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import formatDate from 'date-fns/format';

import { BulkActions } from '@/features/bulk-actions/components';
import { CandidatePagination } from '@/features/candidate/components/candidate-list/ui/candidate-pagination';
import { SortButton } from '@/features/candidate/components/candidate-list/ui/sort-button';
import { TSortOrder } from '@/features/candidate-search';
import {
  Icon,
  ScoreTag,
  ScrollableContainer,
  StarRating,
  TId,
} from '@/features/common';
import { IWorkspaceCandidate } from '@/features/workspace';
import { useHistory, useWorkspaceId } from '@/hooks/router';
import { getCompositeKey } from '@/utils/keys';

import { CandidateJobOpenings } from './candidate-job-openings';
import { CandidatePipelineStage } from './candidate-pipeline-stage';
import { CandidateScores } from './candidate-scores';
import { CandidateTags } from './candidate-tags';

export interface CandidateTableProps {
  candidates: IWorkspaceCandidate[];
  disabled?: boolean;
  onRefreshData?: () => void;
}

const columnHelper = createColumnHelper<IWorkspaceCandidate>();
const candidateNameAccessor = (candidate: IWorkspaceCandidate) =>
  candidate.isUnlocked
    ? !candidate.firstName && !candidate.lastName
      ? candidate.fullName || candidate.name || '(no name)'
      : `${candidate.firstName} ${candidate.lastName}`
    : `Candidate ${candidate.id}`;

export const CandidateTable = ({
  candidates,
  disabled = false,
  onRefreshData,
}: CandidateTableProps) => {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const [rowSelection, setRowSelection] = React.useState({});

  // Row selection = <candidate_id>;<job_opening_id>
  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <DSCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <DSCheckbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      columnHelper.accessor('createdAt', {
        id: 'date',
        cell: info => formatDate(info.getValue(), 'DD/MM/YYYY'),
        header: 'Date Applied',
        enableSorting: true,
        meta: { clickable: true },
      }),
      columnHelper.accessor(candidateNameAccessor, {
        id: 'name',
        header: 'Name',
        meta: {
          className: classNames('trimmed'),
          clickable: true,
        },
        enableSorting: true,
      }),
      columnHelper.display({
        id: 'jobOpenings',
        cell: ({ row: { original: candidate } }) => (
          <CandidateJobOpenings candidate={candidate} disabled={disabled} />
        ),
        header: 'Job',
        meta: { clickable: true },
      }),
      columnHelper.display({
        id: 'scores-states',
        cell: ({ row: { original: candidate } }) => {
          if (!candidate.isUnlocked) {
            return <Icon icon={solid('lock')} color="purple-60" />;
          }
          return candidate.tests ? (
            <CandidateScores
              candidateId={candidate.id}
              scores={candidate.tests}
              disabled={disabled}
            />
          ) : (
            <ScoreTag
              score={candidate.latestScorePercent}
              passPercentage={candidate.latestPercentToPass}
            />
          );
        },
        header: 'Scores & States',
        meta: { clickable: true, noBlurOnLocked: true },
      }),
      columnHelper.display({
        id: 'pipeline-stage',
        cell: ({ row: { original: candidate } }) =>
          !disabled ? (
            candidate.testTakerCategoryId ? (
              <CandidatePipelineStage
                jobOpeningId={candidate.jobOpenings[0].id}
                candidatePipelineStageId={candidate.testTakerCategoryId}
              />
            ) : null
          ) : (
            <span>{candidate.testTakerCategoryId}</span>
          ),
        header: 'Pipeline Stage',
        meta: { className: 'trimmed pipeline-stage', clickable: true },
      }),
      columnHelper.group({
        id: 'stars-notes-flags-tags',
        header: 'Rating/Notes/Flags/Tags',
        meta: { className: 'trimmed' },
        columns: [
          columnHelper.display({
            id: 'stars',
            cell: ({ row: { original: candidate } }) => (
              <StarRating
                data-rh={'Rating'}
                data-rh-at="top"
                size="small"
                value={candidate.isUnlocked ? candidate.rating : 0}
                first={1}
                last={5}
                readOnly
              />
            ),
            meta: { className: 'compact stars', clickable: true },
          }),
          columnHelper.display({
            id: 'notes',
            cell: ({ row: { original: candidate } }) =>
              candidate.hasNotes && candidate.isUnlocked ? (
                <Icon
                  data-rh="With notes"
                  data-rh-at="top"
                  icon={regular('file-pen')}
                  color="purple-100"
                  size="xs"
                />
              ) : null,
            meta: { className: 'compact notes', clickable: true },
          }),
          columnHelper.display({
            id: 'flags',
            cell: ({ row: { original: candidate } }) =>
              candidate.suspicious && candidate.isUnlocked ? (
                <Icon
                  data-rh="Suspicious behavior detected"
                  data-rh-at="top"
                  icon={solid('flag')}
                  color="orange-100"
                  size="xs"
                />
              ) : null,
            meta: { className: 'compact flags', clickable: true },
          }),
          columnHelper.display({
            id: 'tags',
            cell: ({ row: { original: candidate } }) =>
              candidate.isUnlocked && <CandidateTags tags={candidate.tags} />,
            meta: { className: 'compact tags', clickable: true },
          }),
        ],
      }),
      columnHelper.display({
        id: 'country',
        cell: info =>
          info.row.original.isUnlocked ? info.row.original.country : '',
        header: 'Country',
        meta: { className: 'trimmed', clickable: true },
      }),
    ],
    [disabled]
  );

  const table = useReactTable({
    data: candidates,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: row => !!row.original.isUnlocked,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row =>
      getCompositeKey(row.id, ...row.jobOpenings.map(job => job.id)),
  });

  const hasRowsSelected =
    table.getIsSomeRowsSelected() || table.getIsAllRowsSelected();

  const selectedCandidatesIds: TId[] = Object.entries(rowSelection)
    .filter(([_, value]) => value)
    .map(([key]) => key.split(';')[0]);
  const selectedCandidates = candidates.filter(candidate =>
    selectedCandidatesIds.includes(candidate.id)
  );

  return (
    <>
      <Container>
        <Wrapper>
          {hasRowsSelected ? (
            <BulkActionsWrapper>
              <BulkActions
                selectedCandidates={selectedCandidates}
                onActionDone={() => {
                  setRowSelection([]);
                  onRefreshData?.();
                }}
                showCandidateCount={false}
                actions={[
                  'send_email',
                  'invite_to_new_job',
                  'add_tags',
                  'download_pdf',
                ]}
              />
            </BulkActionsWrapper>
          ) : null}
          <PaddedContainer>
            <TableWrapper>
              <TableContainer disabled={disabled}>
                <thead>
                  <tr key={table.getHeaderGroups()[0].id}>
                    {table.getHeaderGroups()[0].headers.map(header => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        colSpan={header.colSpan}
                      >
                        {header.column.getCanSort() ? (
                          <SortButton
                            order={header.column.id as TSortOrder}
                            disabled={disabled}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </SortButton>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {table.getRowModel().rows.map(row => (
                    <TableRow key={row.id} selected={row.getIsSelected()}>
                      {row.getVisibleCells().map(cell => (
                        <td
                          key={cell.id}
                          className={classNames(
                            cell.column.columnDef.meta?.className ?? '',
                            !row.original.isUnlocked &&
                              !!!cell.column.columnDef.meta?.noBlurOnLocked
                              ? 'blured'
                              : ''
                          )}
                          onClick={() => {
                            !disabled &&
                              cell.column.columnDef.meta?.clickable &&
                              row.original.isUnlocked &&
                              history.push(
                                `/redirect/candidate/${workspaceId}/${row.original.jobOpenings[0].id}/${row.original.id}`
                              );
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </TableRow>
                  ))}
                </tbody>
              </TableContainer>
            </TableWrapper>
          </PaddedContainer>
        </Wrapper>
      </Container>
      <CandidatePagination disabled={disabled} />
    </>
  );
};

//region Styles
const Container = styled(ScrollableContainer)`
  overflow-x: auto;
  margin: 0 -24px 12px -24px;
  min-height: 160px;
`;
const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  min-width: 100%;
`;
const PaddedContainer = styled.div`
  display: inline-block;
  min-width: 100%;
  padding: 0 24px 24px 24px;
`;
const TableWrapper = styled.div`
  position: relative;
  filter: ${({ theme }) => `drop-shadow(${theme.shadow.card})`};

  border-radius: 8px;
  overflow: hidden;
  margin-top: 14px;
`;
const BulkActionsWrapper = styled.div`
  position: absolute;
  top: 14px;
  left: 84px; // 84px = 36px (padded container left padding) + 48px (first column width)
  height: 48px;
  z-index: 20;

  background: ${({ theme }) => theme.colors.white};
  width: calc(
    100% - 120px
  ); // 120px = 84px (left offset) + 36px (padded container right padding)
  border-top-right-radius: 8px;
`;
const TableContainer = styled.table<{ disabled: boolean }>`
  position: relative;
  z-index: 10;
  background: ${({ theme }) => theme.colors.white};
  min-width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  thead {
    font-weight: 500;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.purple[60]};
    text-transform: uppercase;
    user-select: none;
    height: 48px;

    th {
      white-space: nowrap;
      padding: 0 16px;
    }
  }

  tbody {
    color: ${({ theme }) => theme.colors.purple[100]};

    td {
      padding: 0 16px;
    }

    td.trimmed {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td.blured {
      filter: blur(3px);
      pointer-events: none;
      user-select: none;
    }

    td.pipeline-stage {
      max-width: 150px;
    }

    td.compact {
      padding-left: 2px;
      padding-right: 2px;
      text-align: center;
    }

    td.stars {
      padding-left: 16px;
      width: 64px;
    }

    td.notes,
    td.flags {
      width: 16px;
    }

    td.tags {
      padding-left: 8px;
      max-width: 150px;
      text-align: left;
    }

    tr:hover {
      background: ${({ theme }) => theme.colors.purple[5]};

      .tag {
        background: ${({ theme }) => theme.colors.white};
      }
    }
  }

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.purple[10]};
  }

  tr,
  th,
  td {
    height: 48px;
    text-align: left;
  }

  th:first-of-type,
  td:first-of-type {
    width: 48px;
    padding: 0 16px;

    & input {
      vertical-align: middle;
    }
  }

  th:nth-of-type(2),
  th:last-of-type {
    width: 150px;
    white-space: nowrap;
  }

  th:nth-of-type(n + 4),
  td:nth-of-type(n + 4) {
    @media all and (max-width: 600px) {
      display: none;
    }
  }
`;

const TableRow = styled.tr<{ selected?: boolean }>`
  background: ${({ theme, selected }) =>
    selected ? theme.colors.purple[5] : 'unset'};
`;
//endregion
