import api from '@/api/api';
import { TId } from '@/features/common';

export async function bulkDownloadPDF(candidateIDs: TId[]): Promise<void> {
  let ids: number[] = candidateIDs.map((id: TId) => Number(id));
  await api.patch(
    `/test-takers/bulk`,
    {
      ids: ids,
      action: 'pdf_export',
    },
    { captchaAction: 'bulk_download_pdf' }
  );
}
