import React from 'react';

import {
  useAddTagToCandidateMutation,
  useCandidateQuery,
  useRemoveTagFromCandidateMutation,
} from '@/features/candidate';
import { Tags, TId } from '@/features/common';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  useCreateWorkspaceTagMutation,
  useWorkspaceTagsQuery,
} from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

interface CandidateTagsProps {
  candidateId: TId;
}

export function CandidateTags(props: CandidateTagsProps) {
  const workspaceId = useWorkspaceId();
  const canUpdateCandidate = !!useJobOpeningPermission()('candidate', 'update');

  const { data: candidate } = useCandidateQuery(props);
  const { data: workspaceTags } = useWorkspaceTagsQuery();

  const createWorkspaceTagMutation = useCreateWorkspaceTagMutation();
  const addTagToCandidateMutation = useAddTagToCandidateMutation();
  const removeTagFromCandidateMutation = useRemoveTagFromCandidateMutation();

  const candidateTags = candidate?.tags || [];
  const filteredSuggestions = workspaceTags?.filter(
    tag => !candidateTags.find(t => t.id === tag.id)
  );

  const addOrCreateCandidateTag = async (name: string) => {
    const candidateTagAlreadyAdded = candidateTags.find(
      tag => tag.name === name
    );

    if (candidateTagAlreadyAdded) return;

    let existingTag = workspaceTags?.find(tag => tag.name === name);

    if (!existingTag) {
      const createdTag = await createWorkspaceTagMutation.mutateAsync({
        workspaceId,
        name,
      });

      if (createdTag.id) {
        existingTag = createdTag;
      }
    }

    if (existingTag) {
      await addTagToCandidateMutation.mutateAsync({
        tagId: existingTag.id,
        tagName: existingTag.name,
        candidateId: props.candidateId,
      });
    }
  };

  const removeTagFromCandidate = async (tagId: string) => {
    await removeTagFromCandidateMutation.mutateAsync({
      tagId,
      candidateId: props.candidateId,
    });
  };

  return (
    <>
      <Tags>
        {candidate?.isRecommendation && <Tags.Recommended />}

        {candidate?.previousTestTakerId ? (
          <Tags.Item label="reapplied" />
        ) : null}
        {candidate?.tags?.map(tag => (
          <Tags.Item
            key={tag.id}
            label={tag.name}
            onDelete={
              canUpdateCandidate
                ? () => removeTagFromCandidate(tag.id)
                : undefined
            }
          />
        ))}
        {canUpdateCandidate && (
          <Tags.Add
            onAdd={name => void addOrCreateCandidateTag(name)}
            tagsExist={candidateTags.length > 0}
            suggestions={filteredSuggestions}
          />
        )}
      </Tags>
    </>
  );
}
