import styled from '@emotion/styled';

export const Header = styled.header`
  padding: 0 8px 16px;
`;

export const MainHeader = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr auto 24px;
  margin-bottom: 8px;
  border-radius: 8px;
`;

export const CandidateSelectionHeader = styled.div`
  display: flex;
  max-width: 100%;
  min-height: 24px;
  flex-wrap: nowrap;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  height: 32px;
  display: flex;
  align-items: center;
  margin: 0;
  border: 0;
  background: transparent;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
`;
