import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import * as reducers from './all';

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

export default createRootReducer;

export type RootReducer = ReturnType<typeof createRootReducer>;
export type State = ReturnType<RootReducer>;
