import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { range } from 'lodash';

import { Icon } from '../icon';

type StarRatingProps = {
  value: number | null;
  first: number;
  last: number;
  size: StarSize;
  onChange?: (stars: number | null) => void;
  readOnly?: boolean;
  color?: string;
  colorMode?: StarColorMode;
  'data-rh'?: string;
  'data-rh-at'?: string;
};

const STAR_SIZES = {
  small: 14,
  medium: 21,
  large: 30,
};

const EMPTY_STAR_COLOR_MODES = {
  light: 'purple-20',
  dark: 'purple-60',
};

type StarSize = 'large' | 'medium' | 'small';
type StarColorMode = 'light' | 'dark';

export function StarRating(props: StarRatingProps) {
  const {
    size,
    first,
    last,
    readOnly,
    value: selectedValue,
    onChange,
    value,
    colorMode = 'light',
    color = 'orange-100',
  } = props;
  const [internalValue, setInternalValue] = useState<number | null>(
    selectedValue
  );

  const isSelected = (value: number): boolean =>
    internalValue ? value <= internalValue : false;

  const selectValue = (newValue: number) =>
    newValue !== value && onChange?.(newValue);

  useEffect(() => {
    setInternalValue(selectedValue);
  }, [selectedValue]);

  const onHover = (value: number | null): void => setInternalValue(value);

  return (
    <StarRatingContainer
      data-rh={props['data-rh']}
      data-rh-at={props['data-rh-at']}
      size={size}
      onMouseOut={() => !readOnly && onHover(selectedValue)}
    >
      {range(first, last + 1).map(num => {
        const selected = isSelected(num);
        return (
          <StarWrapper
            size={size}
            key={num}
            onClick={event => {
              if (!readOnly) {
                event.stopPropagation();
                selectValue(num);
              }
            }}
            onMouseOver={() => !readOnly && onHover(num)}
            readOnly={readOnly}
          >
            <SelectedStarInnerWrapper selected={selected} size={size}>
              <Icon
                icon={solid('star')}
                color={color}
                fontSize={STAR_SIZES[size]}
              />
            </SelectedStarInnerWrapper>
            <EmptyStarInnerWrapper selected={selected} size={size}>
              <Icon
                icon={regular('star')}
                color={EMPTY_STAR_COLOR_MODES[colorMode]}
                fontSize={STAR_SIZES[size]}
              />
            </EmptyStarInnerWrapper>
          </StarWrapper>
        );
      })}
    </StarRatingContainer>
  );
}

const resolveStarDimensions = (size: StarSize) => {
  switch (size) {
    case 'small':
      return {
        width: '18px',
        height: '14px',
      };
    case 'medium':
      return {
        width: '31px',
        height: '21px',
      };
    case 'large':
      return {
        width: '40px',
        height: '30px',
      };
  }
};

const StarRatingContainer = styled.div<{ size: StarSize }>`
  display: flex;
  transform: translateZ(0);
  margin-left: ${props => (props.size === 'small' ? '-2px' : '-5px')};
  margin-right: ${props => (props.size !== 'small' ? '-5px' : '0')};
  svg {
    display: block;
  }
`;
const StarWrapper = styled.div<{ size: StarSize; readOnly?: boolean }>`
  position: relative;
  display: block;
  padding: 0;
  border: none;
  background: transparent;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
  ${props => resolveStarDimensions(props.size)};
  &:focus {
    outline: none;
  }
`;

const SelectedStarInnerWrapper = styled.span<{
  selected: boolean;
  size: StarSize;
}>`
  position: absolute;
  top: 0;
  display: block;
  transition: all 0.2s;
  transform: translateZ(0);
  opacity: ${props => (props.selected ? '1' : '0')};
  left: ${props => (props.size === 'small' ? '2px' : '5px')};
`;

const EmptyStarInnerWrapper = styled.span<{
  selected: boolean;
  size: StarSize;
}>`
  position: absolute;
  top: 0;
  display: block;
  transition: all 0.2s;
  transform: translateZ(0);
  opacity: ${props => (props.selected ? '0' : '1')};
  left: ${props => (props.size === 'small' ? '2px' : '5px')};
`;
