import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { checkClientError } from '@/errors';
import {
  deleteCandidateAttachment,
  downloadCandidateAttachment,
  fetchCandidateAttachments,
  ICandidateAttachment,
  uploadCandidateAttachment,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useApiError } from '@/hooks/api';

export const CANDIDATE_ATTACHMENTS_QUERY_KEY = ['candidate', 'attachments'];

//region Queries
export const useCandidateAttachmentsQuery = (
  params: { candidateId: TId },
  options?: UseQueryOptions
) => {
  const queryClient = useQueryClient();

  // Invalidate attachments data on every mutation
  queryClient.setMutationDefaults(
    [...CANDIDATE_ATTACHMENTS_QUERY_KEY, params.candidateId],
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          ...CANDIDATE_ATTACHMENTS_QUERY_KEY,
          params.candidateId,
        ]),
    }
  );

  return useQuery(
    [...CANDIDATE_ATTACHMENTS_QUERY_KEY, params.candidateId],
    () => fetchCandidateAttachments(params),
    options as any
  );
};

export const useDownloadCandidateAttachmentQuery = (
  params: { attachmentId: TId },
  options?: UseQueryOptions
) =>
  useQuery(
    [...CANDIDATE_ATTACHMENTS_QUERY_KEY, params.attachmentId],
    () => downloadCandidateAttachment(params),
    options as any
  );
//endregion

//region Mutations
export const useUploadCandidateAttachmentMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) => {
  const { showFlashMessage } = useFlashMessages();
  const handleApiError = useApiError();
  const queryClient = useQueryClient();

  return useMutation(uploadCandidateAttachment, {
    mutationKey: [...CANDIDATE_ATTACHMENTS_QUERY_KEY, params.candidateId],

    onSuccess(data, variables) {
      showFlashMessage({
        type: 'attachment_uploaded',
      });

      const queryKey = [
        ...CANDIDATE_ATTACHMENTS_QUERY_KEY,
        variables.candidateId,
      ];

      queryClient.setQueryData(queryKey, (oldData: ICandidateAttachment[]) => [
        ...(oldData ?? []),
        data,
      ]);

      // As we are overriding the onSuccess method we should also call invalidate queries
      // to have data in sync with remote
      // if a file was added on another machine it will not appear with the setQueryData
      queryClient.invalidateQueries([
        ...CANDIDATE_ATTACHMENTS_QUERY_KEY,
        params.candidateId,
      ]);
    },

    onError(error) {
      if (checkClientError(error, 'UnsupportedFileFormat')) {
        showFlashMessage({
          type: 'attachment_upload_error',
          error: 'unsupported_file_format',
        });
      } else if (checkClientError(error, 'FileTooLarge')) {
        showFlashMessage({
          type: 'attachment_upload_error',
          error: 'file_too_large',
        });
      } else {
        handleApiError(error);
      }
    },

    ...(options as any),
  });
};

export const useDeleteCandidateAttachmentMutation = (
  params: { candidateId: TId },
  options?: UseMutationOptions
) =>
  useMutation(deleteCandidateAttachment, {
    mutationKey: [...CANDIDATE_ATTACHMENTS_QUERY_KEY, params.candidateId],
    ...(options as any),
  });
//endregion
