import React from 'react';
import styled from '@emotion/styled';
import { isEmpty, pick } from 'lodash';

import {
  DateFilter,
  DateListItem,
  FilterList,
  FiltersSelect,
} from '@/features/filters';

import { TFilterType, TypeFilter, TypeListItem } from '../..';
import { useInboxSearchParams } from '../../hooks/params';

export const DATE_FILTER_TYPE: TFilterType = 'date';

export const TYPE_FILTER_TYPE = 'types';

export const InboxFilters = () => {
  const {
    addSearchParams,
    removeSearchParam,
    searchParams,
    clearSearchParams,
    hasSearchParams,
  } = useInboxSearchParams();

  const removeType = React.useCallback(
    (star: string) => {
      const newTypes = searchParams?.types?.filter(s => s !== star);

      addSearchParams({ ['types']: newTypes });
    },
    [addSearchParams, searchParams?.types]
  );

  return (
    <Wrapper>
      <FiltersSelect>
        {({ close }) => (
          <>
            <FiltersSelect.Filter>
              <DateFilter
                name="Date"
                initialValues={pick(searchParams, DATE_FILTER_TYPE)}
                onApply={values => {
                  if (isEmpty(values)) {
                    removeSearchParam(DATE_FILTER_TYPE);
                  } else {
                    addSearchParams(values);
                  }
                  close();
                }}
              />
            </FiltersSelect.Filter>
            <FiltersSelect.Filter>
              <TypeFilter
                initialValues={pick(searchParams, TYPE_FILTER_TYPE)}
                onApply={values => {
                  if (isEmpty(values)) {
                    removeSearchParam(TYPE_FILTER_TYPE);
                  } else {
                    addSearchParams(values);
                  }
                  close();
                }}
              />
            </FiltersSelect.Filter>
          </>
        )}
      </FiltersSelect>
      <FilterList
        hasSearchParams={hasSearchParams}
        onClearFilters={() => clearSearchParams()}
      >
        <DateListItem
          date={searchParams.date}
          onDelete={() => removeSearchParam(DATE_FILTER_TYPE)}
        />
        <TypeListItem types={searchParams.types} onDelete={removeType} />
      </FilterList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-width: 0;
`;

//endregion
