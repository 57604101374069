import React, { useRef } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';

import {
  useCandidateAttachmentsQuery,
  useUploadCandidateAttachmentMutation,
} from '@/features/candidate';
import { isFileSizeValid, TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useJobOpeningIdOrNull, useWorkspaceId } from '@/hooks/router';

export const useUploadCandidateAttachment = (params: { candidateId: TId }) => {
  const jobOpeningId = useJobOpeningIdOrNull();
  const workspaceId = useWorkspaceId();
  const { showFlashMessage } = useFlashMessages();

  const query = useCandidateAttachmentsQuery(params);
  const mutation = useUploadCandidateAttachmentMutation(params);

  const candidateAttachmentUploadDisabled = query.data?.length! >= 7;
  const candidateAttachmentUploading = mutation.isLoading;

  const candidateAttachmentInputRef = useRef<HTMLInputElement>(null);

  const candidateAttachmentInput = (
    <Input
      ref={candidateAttachmentInputRef}
      type="file"
      disabled={
        candidateAttachmentUploadDisabled || candidateAttachmentUploading
      }
      accept=".doc, .docx, .xls, .xlsx, .txt, .pdf, .jpg, .jpeg, .png, .odt"
      onChange={async event => {
        const files = event.target.files!;

        if (files.length) {
          const file = files[0];

          if (!isFileSizeValid(file, 15)) {
            showFlashMessage({
              type: 'attachment_upload_error',
              error: 'file_too_large',
              meta: { maxSizeMB: 15 },
            });
          } else {
            event.target.value = '';

            const attachment = await mutation.mutateAsync({
              candidateId: params.candidateId,
              source: file,
            });

            amplitude?.logEvent('candidates/uploaded attachment', {
              'workspace id': workspaceId,
              'job opening id': jobOpeningId,
              'candidate id': params.candidateId,
              'file name': attachment.originalFileName,
            });
          }
        }
      }}
    />
  );

  return {
    candidateAttachmentInput,
    candidateAttachmentInputRef,
    candidateAttachmentUploadDisabled,
    candidateAttachmentUploading,
  };
};

const Input = styled.input`
  display: none;
`;
