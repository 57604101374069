import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

interface CandidateBoxesSkeletonUIProps {
  amount?: number;
}

export function CandidateBoxesSkeletonUI({
  amount = 3,
}: CandidateBoxesSkeletonUIProps) {
  return (
    <List>
      {Array.from({ length: amount }).map((_, index) => (
        <Box key={index}>
          <SkeletonText tall wide />
          <SkeletonText tall justifySelf="end" />
          <SkeletonText tall />
          <div />
          <SkeletonText />
          <SkeletonText justifySelf="end" />
        </Box>
      ))}
    </List>
  );
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 8px;
`;

const Box = styled.li`
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadow.card};
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: max-content auto;
  grid-template-rows: repeat(3, 1fr);
  height: 88px;
  margin-bottom: 16px;
  padding: 12px;
`;

const shimmer = keyframes`
    100% {
      transform: translateX(100%);
    }
`;

const SkeletonText = styled.div<{
  tall?: boolean;
  wide?: boolean;
  justifySelf?: 'start' | 'end';
}>`
  background: ${props => props.theme.colors.purple[10]};
  border-radius: 8px;
  height: ${props => (props.tall ? '16px' : '10px')};
  justify-self: ${({ justifySelf = 'start' }) => justifySelf};
  overflow: hidden;
  position: relative;
  width: ${props => (props.tall ? (props.wide ? '140px' : '45px') : '66px')};

  &::after {
    animation: ${shimmer} 1250ms infinite;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.3) 20%,
      rgba(255, 255, 255, 0.6) 60%,
      rgba(255, 255, 255, 0)
    );
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
  }

  @media (prefers-reduced-motion) {
    animation: unset;
  }
`;
