import React, { useEffect, useRef } from 'react';
import Autosizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, FixedSizeListProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

interface VirtualizedInfiniteScrollProps {
  children: FixedSizeListProps['children'];
  hasNextPage?: boolean;
  isNextPageLoading: boolean;
  /**
   * Height of a list item
   */
  itemSize: number;
  loadNextPage: () => void;
  /**
   * String representing data which, when changed, should reset scroll to top (sort order, filters etc.)
   */
  scrollResetKey?: string;
  /**
   * Used to determine scrollbar size and whether infinite scroll should keep fetching
   */
  total: number;
}

/**
 * Wrapper for react-window and related components providing infinite scroll with virtualization.
 * Intended for use with large amount of list items (1000+)
 */
export function VirtualizedInfiniteScroll({
  children,
  hasNextPage = false,
  isNextPageLoading,
  itemSize,
  loadNextPage,
  scrollResetKey,
  total,
}: VirtualizedInfiniteScrollProps) {
  const listRef = useRef<any>(null);
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  const isItemLoaded = index => !hasNextPage || index < total - 1;

  useEffect(() => {
    if (scrollResetKey && listRef.current) {
      listRef.current.scrollToItem(0);
    }
  }, [scrollResetKey]);

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={total}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <Autosizer>
          {({ height, width }) => (
            <FixedSizeList
              itemCount={total}
              onItemsRendered={onItemsRendered}
              ref={list => {
                ref(list);
                listRef.current = list;
              }}
              height={height as number}
              width={width as number}
              itemSize={itemSize}
              className="virtualized-infinite-scroll"
            >
              {children}
            </FixedSizeList>
          )}
        </Autosizer>
      )}
    </InfiniteLoader>
  );
}
