import React, { useState } from 'react';
import { Button } from '@hundred5/design-system';
import { isMobile } from 'react-device-detect';

import Modal from '@/components/modal/beta/Modal';
import { useWorkspaceTreatments } from '@/components/splits';
import { NavigationMenu } from '@/features/common';
import { useJobOpeningQuery } from '@/features/job-opening';

export function JobOpeningMenuPrimary() {
  const jobOpeningQuery = useJobOpeningQuery();
  const [open, setOpen] = useState(false);

  const { fe_admin_app_job_description } = useWorkspaceTreatments([
    'fe_admin_app_job_description',
  ]);
  const isJobDescriptionEnabled =
    fe_admin_app_job_description.treatment === 'on';

  return jobOpeningQuery.data ? (
    <>
      <NavigationMenu>
        <NavigationMenu.Item
          label="Candidate pipeline"
          to={`/admin/${jobOpeningQuery.data.workspaceId}/openings/${jobOpeningQuery.data.id}/pipeline`}
          activePath="/admin/:workspace/openings/:opening/pipeline"
        />
        {isJobDescriptionEnabled && (
          <NavigationMenu.Item
            label="Job description"
            to={`/admin/${jobOpeningQuery.data.workspaceId}/openings/${jobOpeningQuery.data.id}/job-description`}
            activePath="/admin/:workspace/openings/:opening/:page(job-description|application-form)"
          />
        )}
        <NavigationMenu.Item
          label="Analytics"
          onClick={event => {
            if (isMobile) {
              event.preventDefault();
              setOpen(true);
            }
          }}
          to={`/admin/${jobOpeningQuery.data.workspaceId}/openings/${jobOpeningQuery.data.id}/analytics`}
          activePath="/admin/:workspace/openings/:opening/:page(analytics|satisfaction)"
        />
        <NavigationMenu.Item
          label="Job settings"
          to={`/admin/${jobOpeningQuery.data.workspaceId}/openings/${jobOpeningQuery.data.id}/job-settings`}
          activePath="/admin/:workspace/openings/:opening/job-settings"
        />
      </NavigationMenu>

      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <strong>It’s better on desktop!</strong>
        </div>
        <br />
        <div>
          This feature is only available on the desktop version of Toggl Hire.
        </div>

        <Button
          style={{ width: '100%', marginTop: '20px' }}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </Modal>
    </>
  ) : null;
}
