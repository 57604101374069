import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSCheckbox } from '@hundred5/design-system';
import { useQueryClient } from '@tanstack/react-query';
import useOnClickOutside from 'use-onclickoutside';

import { copyText } from '@/components/utils/copy-text';
import { amplitude } from '@/features/amplitude';
import { BulkActions } from '@/features/bulk-actions/components';
import { useResetCandidateTestMutation } from '@/features/candidate';
import { useCandidateSearchParams } from '@/features/candidate-search';
import {
  ConfirmModal,
  Icon,
  SelectedCandidatesCount,
  TestTypeIcon,
  TSortDirection,
  VirtualizedInfiniteScroll,
} from '@/features/common';
import { getTestTooltipContent } from '@/features/common/utils';
import { useEmailModalContext } from '@/features/email';
import { useFlashMessages } from '@/features/flash-messages';
import { InterviewIcon, useInterviewQuery } from '@/features/interview';
import { useJobOpeningQuery } from '@/features/job-opening';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  PIPELINE_CANDIDATES_KEY,
  useInvalidateCandidatesQuery,
  usePipelineCategoryCandidatesQuery,
} from '@/features/pipeline';
import { TestName } from '@/features/pipeline/components/test-name/test-name';
import { useStoreData } from '@/features/storage';
import { useTestQuery } from '@/features/test';
import { useHistory, useJobOpeningId, useWorkspaceId } from '@/hooks/router';

import { useCandidatesCount, useSelectedCandidates } from '../../contexts';
import {
  IPipelineCandidate,
  IPipelineCategory,
  TPipelineCandidatesSortOrder,
} from '../../types';
import {
  flattenInfinitePipelineCandidatesData,
  getPipelineSortStorageKey,
} from '../../utils';
import { CandidateBox } from '../candidate-box';

import { PipelinesStageContextMenu } from './pipelines-stage-context-menu';
import {
  CandidateBoxesSkeletonUI,
  CandidateCardWrapper,
  CandidateSelectionHeader,
  CandidatesList,
  CategoryName,
  CategoryNameEdit,
  CategoryTotalCandidates,
  Container,
  CopyTestLinkButton,
  DraggableWrapper,
  EditTestButton,
  EditTestButtonContainer,
  Header,
  MainHeader,
  NewTestButton,
  PipelinesInfo,
  SelectionWrapper,
  Sort,
} from './ui';

interface PipelinesStageProps {
  category: IPipelineCategory;
  index: number;
}

export function PipelinesStage({ category, index }: PipelinesStageProps) {
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { openEmailModal } = useEmailModalContext();
  const headerRef = useRef(null);
  const { showFlashMessage } = useFlashMessages();

  const [
    candidateToResetTest,
    setCandidateToResetTest,
  ] = useState<IPipelineCandidate | null>(null);
  const [isPipelinesInfoHidden, setIsPipelinesInfoHidden] = useState(false);
  const [isCurrentlyEditingName, setIsEditingCategoryName] = useState(false);

  const [localSort] = useStoreData<{
    order: TPipelineCandidatesSortOrder | null;
    direction: TSortDirection;
  }>(getPipelineSortStorageKey(jobOpeningId, category.id));

  const [sortOrder, setSortOrder] = useState<TPipelineCandidatesSortOrder>(
    localSort?.order || 'date'
  );
  const [sortDirection, setSortDirection] = useState<TSortDirection>(
    localSort?.direction || 'desc'
  );

  const { searchParams } = useCandidateSearchParams();
  const canEditPipeline = !!useJobOpeningPermission()('pipeline', 'update');

  const jobOpeningQuery = useJobOpeningQuery();
  const testQuery = useTestQuery({ testId: category.testId ?? undefined });
  const interviewQuery = useInterviewQuery(
    { interviewId: category.interviewId! },
    { enabled: !!category.interviewId }
  );

  const queryParams = useMemo(() => {
    return {
      jobOpeningId,
      categoryId: category.id,
      searchParams:
        searchParams.firstColumn === 'yes' && index > 0
          ? {
              sortOrder: sortOrder,
              sortDirection: sortDirection,
              page: searchParams.page,
              itemsPerPage: searchParams.itemsPerPage,
            }
          : {
              ...searchParams,
              sortOrder,
              sortDirection,
            },
    };
  }, [index, jobOpeningId, category, searchParams, sortOrder, sortDirection]);
  const pipelineCategoryCandidatesQuery = usePipelineCategoryCandidatesQuery(
    queryParams
  );
  const invalidateCandidatesQuery = useInvalidateCandidatesQuery();
  const resetCandidateTestMutation = useResetCandidateTestMutation();

  const { candidates, total } = useMemo(
    () =>
      flattenInfinitePipelineCandidatesData(
        pipelineCategoryCandidatesQuery.data?.pages
      ),
    [pipelineCategoryCandidatesQuery.data]
  );

  const { updateCountByCategory } = useCandidatesCount();
  const {
    selectedCandidates: { ids: selectedCandidatesIds },
    toggleCandidateSelection,
    toggleAllCandidates,
    unselectAllCandidates,
  } = useSelectedCandidates(category.id);

  const selectedCandidates = useMemo(
    () =>
      candidates.filter(candidate =>
        selectedCandidatesIds.includes(candidate.id)
      ),
    [candidates, selectedCandidatesIds]
  );

  useEffect(() => {
    updateCountByCategory(category.id, total);
  }, [total, category.id, updateCountByCategory]);

  const showPipelinesInfo = useMemo(() => {
    if (index > 0 || isPipelinesInfoHidden) {
      return false;
    }

    return candidates.length === 0;
  }, [index, candidates, isPipelinesInfoHidden]);

  const handleAddToStage = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpeningId}/pipeline/stage/${category.id}/add`
    );
  }, [category.id, history, jobOpeningId, workspaceId]);

  const handleOpenTestSettings = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpeningId}/test/${category.testId}/questions?returnUrl=pipeline&returnName=Pipeline`
    );
  }, [history, jobOpeningId, workspaceId, category.testId]);

  const handleOpenInterviewSettings = useCallback(() => {
    history.push(
      `/admin/${workspaceId}/openings/${jobOpeningId}/interview/${category.interviewId}/settings`
    );
  }, [history, jobOpeningId, workspaceId, category.interviewId]);

  const handleCandidateClick = useCallback(
    (candidate: IPipelineCandidate) => {
      history.replace(
        `/admin/${workspaceId}/openings/${jobOpeningId}/pipeline/stage/${candidate.categoryId}/candidate/${candidate.id}/results${history.location.search}`
      );
      amplitude?.logEvent('pipeline/candidate clicked', {
        'candidate id': candidate.id,
        'job opening id': jobOpeningId,
        'pipeline step': candidate.categoryId,
      });
    },
    [history, jobOpeningId, workspaceId]
  );

  const handleInviteCandidate = useCallback(
    (candidate: IPipelineCandidate) => {
      openEmailModal({
        type: candidate.tests?.find(test => test.candidateState === 'completed')
          ? 'next-stage-invitation'
          : 'test-invitation',
        meta: {
          testId: testQuery.data?.id!,
          interviewId: category?.interviewId || null,
          candidateId: candidate.id,
          onSuccess: () => invalidateCandidatesQuery(queryParams),
        },
      });
    },
    [
      openEmailModal,
      testQuery.data?.id,
      category?.interviewId,
      invalidateCandidatesQuery,
      queryParams,
    ]
  );

  const handleResendInvitation = useCallback(
    (candidate: IPipelineCandidate) => {
      if (candidate.hireState === 'invited') {
        openEmailModal({
          type: 'invitation',
          meta: {
            jobOpeningId,
            jobOpeningSlug: jobOpeningQuery.data?.slug!,
            categoryId: candidate.categoryId!,
          },
          props: {
            header: 'Resend invitation',
            email: candidate.email,
          },
        });
      } else {
        openEmailModal({
          type:
            candidate.hireState === ''
              ? 'resend-test-invitation'
              : 'resend-next-stage-invitation',
          meta: {
            testId: testQuery.data?.id!,
            interviewId: category?.interviewId || null,
            candidateId: candidate.id,
          },
        });
      }
    },
    [
      openEmailModal,
      testQuery.data?.id,
      jobOpeningId,
      category?.interviewId,
      jobOpeningQuery.data?.slug,
    ]
  );

  const handleShowResetTestModal = useCallback(
    (candidate: IPipelineCandidate) => {
      setCandidateToResetTest(candidate);
    },
    []
  );

  const handleResetCandidateTest = useCallback(async () => {
    if (!candidateToResetTest || !testQuery.data) {
      return;
    }

    await resetCandidateTestMutation.mutateAsync({
      candidateId: candidateToResetTest.id,
      testId: testQuery.data.id,
    });

    setCandidateToResetTest(null);
  }, [candidateToResetTest, testQuery.data, resetCandidateTestMutation]);

  const handleCopyTestLink = useCallback(() => {
    if (jobOpeningQuery.data && testQuery.data) {
      copyText(
        getJobOpeningUrlForCandidate({
          jobOpeningSlug: jobOpeningQuery.data.slug,
          hashedTestId: testQuery.data.hashedTestId,
        })
      );

      showFlashMessage({
        type: 'link_copied',
      });
    }
  }, [testQuery.data, jobOpeningQuery.data, showFlashMessage]);

  useOnClickOutside(headerRef, () => setIsEditingCategoryName(false));

  if (!jobOpeningQuery.isSuccess) {
    return null;
  }

  return (
    <Container hasInfo={showPipelinesInfo}>
      <Header ref={headerRef}>
        <MainHeader>
          {!isCurrentlyEditingName && (
            <CategoryName
              onClick={() =>
                canEditPipeline
                  ? setIsEditingCategoryName(!isCurrentlyEditingName)
                  : null
              }
            >
              {category.name}
            </CategoryName>
          )}
          {isCurrentlyEditingName && canEditPipeline && (
            <CategoryNameEdit
              category={category}
              jobOpeningId={jobOpeningId}
            ></CategoryNameEdit>
          )}
          <CategoryTotalCandidates>{total}</CategoryTotalCandidates>
          <PipelinesStageContextMenu
            category={category}
            jobOpening={jobOpeningQuery.data}
            test={testQuery.data}
            hasCandidates={total > 0}
            handleCopyTestLink={handleCopyTestLink}
          />
        </MainHeader>
        {category.testId && testQuery.isSuccess ? (
          <EditTestButtonContainer>
            <EditTestButton
              onClick={() => {
                setIsEditingCategoryName(false);
                handleOpenTestSettings();
              }}
            >
              <TestTypeIcon
                testType={testQuery.data?.type}
                tooltip={getTestTooltipContent(testQuery.data)}
                inverted
              />
              <TestName
                key={testQuery.data?.name}
                name={testQuery.data?.name}
              />
            </EditTestButton>
            <CopyTestLinkButton
              onClick={handleCopyTestLink}
              data-rh="Copy test link"
              data-rh-at="right"
            >
              <Icon
                icon={regular('link-simple')}
                color="purple-100"
                width="12px"
                height="12px"
              ></Icon>{' '}
            </CopyTestLinkButton>
          </EditTestButtonContainer>
        ) : category.interviewId && interviewQuery.isSuccess ? (
          <EditTestButtonContainer>
            <EditTestButton
              onClick={() => {
                setIsEditingCategoryName(false);
                handleOpenInterviewSettings();
              }}
            >
              <InterviewIcon inverted />
              <TestName name={interviewQuery.data?.name} />
            </EditTestButton>
          </EditTestButtonContainer>
        ) : (
          canEditPipeline &&
          !isCurrentlyEditingName && (
            <NewTestButton onClick={handleAddToStage} />
          )
        )}

        {total > 0 ? (
          <CandidateSelectionHeader>
            {canEditPipeline && (
              <SelectionWrapper>
                <DSCheckbox
                  checked={total > 0 && selectedCandidatesIds.length === total}
                  indeterminate={
                    selectedCandidatesIds.length > 0 &&
                    selectedCandidatesIds.length < total
                  }
                  onChange={() => toggleAllCandidates(candidates)}
                />
                <SelectedCandidatesCount>
                  {selectedCandidatesIds.length
                    ? `${selectedCandidatesIds.length} selected`
                    : 'Select all'}
                </SelectedCandidatesCount>
              </SelectionWrapper>
            )}
            {!(selectedCandidatesIds.length > 0) && (
              <Sort
                category={category}
                hasCandidates={total > 0}
                sortOrder={sortOrder}
                onSortBy={setSortOrder}
                sortDirection={sortDirection}
                onSortDirection={setSortDirection}
              />
            )}
            {selectedCandidates.length && canEditPipeline ? (
              <BulkActions
                testId={category.testId ?? undefined}
                selectedCandidates={selectedCandidates}
                onActionDone={() => {
                  unselectAllCandidates();
                  queryClient.invalidateQueries([
                    ...PIPELINE_CANDIDATES_KEY,
                    jobOpeningId,
                    category.id,
                    { ...searchParams, sortOrder },
                  ]);
                }}
                actions={[
                  'download_pdf',
                  'send_email',
                  'move_to',
                  'add_tags',
                  'reject',
                ]}
                originCategoryId={category.id}
              />
            ) : null}
          </CandidateSelectionHeader>
        ) : null}
      </Header>
      {pipelineCategoryCandidatesQuery.isLoading ? (
        <CandidateBoxesSkeletonUI amount={index + 1} />
      ) : (
        <>
          <CandidatesList categoryId={category.id}>
            <VirtualizedInfiniteScroll
              hasNextPage={pipelineCategoryCandidatesQuery.hasNextPage}
              isNextPageLoading={
                pipelineCategoryCandidatesQuery.isFetchingNextPage
              }
              itemSize={100}
              loadNextPage={pipelineCategoryCandidatesQuery.fetchNextPage}
              scrollResetKey={JSON.stringify(searchParams)}
              total={candidates.length}
            >
              {({ index, style }) => {
                const candidate = candidates[index];

                return (
                  <DraggableWrapper
                    id={candidate.id}
                    candidate={candidate}
                    style={style}
                    disabled={!canEditPipeline || !candidate.isUnlocked}
                  >
                    <CandidateCardWrapper>
                      <CandidateBox
                        candidate={candidate}
                        jobOpening={jobOpeningQuery.data}
                        onClick={handleCandidateClick}
                        selected={selectedCandidatesIds.includes(candidate.id)}
                        onSelect={toggleCandidateSelection}
                        onInvite={handleInviteCandidate}
                        onResendInvitation={handleResendInvitation}
                        onReset={handleShowResetTestModal}
                      />
                    </CandidateCardWrapper>
                  </DraggableWrapper>
                );
              }}
            </VirtualizedInfiniteScroll>
          </CandidatesList>
          {showPipelinesInfo ? (
            <PipelinesInfo onClose={() => setIsPipelinesInfoHidden(true)} />
          ) : null}
        </>
      )}
      <ConfirmModal
        open={candidateToResetTest !== null}
        action="Reset"
        onConfirm={handleResetCandidateTest}
        onClose={() => setCandidateToResetTest(null)}
      >
        <h3>Are you sure?</h3>
        <p>
          Resetting the test will delete all answers and allow the candidate to
          repeat the test, are you sure you want to reset?
        </p>
      </ConfirmModal>
    </Container>
  );
}
