import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BooleanValue } from 'react-values';

import { CustomContextMenu, Icon } from '@/features/common';
import { Badge } from '@/features/common/components/badge';

interface CandidateTagsProps {
  tags:
    | {
        id: string;
        name: string;
      }[]
    | null;
}

export function CandidateTags({ tags }: CandidateTagsProps) {
  if (!tags) {
    return null;
  }

  const firstTag = tags[0];
  const secondTag = tags.length > 1 ? tags[1] : null;
  const extraTags = tags.slice(2);

  return (
    <TagsWrapper>
      <TagBadge color={'gray'} uppercase ellipsis className="tag">
        {firstTag.name}
      </TagBadge>
      {secondTag && (
        <TagBadge color={'gray'} uppercase ellipsis className="tag">
          {secondTag.name}
        </TagBadge>
      )}

      {extraTags.length > 0 ? (
        <BooleanValue>
          {({ value, set }) => (
            <ExtraTagsWrapper
              onMouseEnter={() => set(true)}
              onMouseLeave={() => set(false)}
            >
              <TagBadge color={'gray'} uppercase ellipsis className="tag">
                <Icon icon={regular('ellipsis')} color="purple-100" size="xs" />
              </TagBadge>
              <ContextMenuWrapper>
                <ContextMenu open={value}>
                  <TagContextMenuHeader>Tags</TagContextMenuHeader>
                  <ExtraTagsList>
                    {extraTags.map(tag => (
                      <TagBadge key={tag.id} color={'gray'} uppercase ellipsis>
                        {tag.name}
                      </TagBadge>
                    ))}
                  </ExtraTagsList>
                </ContextMenu>
              </ContextMenuWrapper>
            </ExtraTagsWrapper>
          )}
        </BooleanValue>
      ) : null}
    </TagsWrapper>
  );
}

const TagBadge = styled(Badge)`
  overflow: hidden;
  margin-right: 4px;
`;

const TagsWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  max-width: 200px;

  & > div {
    vertical-align: middle;
  }
`;

const TagContextMenuHeader = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  padding: 12px 12px 8px 12px;

  color: ${({ theme }) => theme.colors.purple[60]};
  text-transform: uppercase;

  align-items: center;
`;
const ExtraTagsList = styled.div`
  padding: 0 12px 12px 12px;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

const ExtraTagsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ContextMenuWrapper = styled.div`
  position: absolute;
  padding-top: 10px;
  top: 20px;
  right: -8px;
  z-index: 100;
`;

const ContextMenu = styled(CustomContextMenu)`
  min-width: 180px;

  &::after {
    right: 12px;
  }
`;
