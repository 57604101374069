import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import {
  CANDIDATE_QUERY_KEY,
  createCandidateInterviewNote,
  deleteCandidateInterviewNote,
  fetchCandidateInterviewNotes,
  ICandidate,
  markCandidateNotesAsRead,
  updateCandidateInterviewNote,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline/queries/pipeline-candidates';
import { WORKSPACE_STATS_QUERY_KEY } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

export const CANDIDATE_INTERVIEW_NOTES_QUERY_KEY = [
  'candidate',
  'interview',
  'notes',
];

export const useCandidateInterviewNotesQuery = (
  params: {
    candidateId: TId;
    interviewId: TId;
    jobOpeningId: TId;
    categoryId: TId;
  },
  options?: UseQueryOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  // Invalidate on successful mutations
  queryClient.setMutationDefaults(CANDIDATE_INTERVIEW_NOTES_QUERY_KEY, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...CANDIDATE_INTERVIEW_NOTES_QUERY_KEY,
        params.candidateId,
        params.interviewId,
      ]);
      queryClient.invalidateQueries([
        PIPELINE_CANDIDATES_KEY,
        params.jobOpeningId,
        params.categoryId,
      ]);
      queryClient.invalidateQueries([
        ...WORKSPACE_STATS_QUERY_KEY,
        workspaceId,
      ]);
    },
  });

  return useQuery(
    [
      ...CANDIDATE_INTERVIEW_NOTES_QUERY_KEY,
      params.candidateId,
      params.interviewId,
    ],
    () => fetchCandidateInterviewNotes(params),
    {
      onSuccess: async data => {
        queryClient.setQueryData(
          [...CANDIDATE_QUERY_KEY, params.candidateId, params.interviewId],
          (oldCandidate: ICandidate) => ({
            ...oldCandidate,
            ...data,
            unreadNotes: 0,
          })
        );

        if (data.hasUnread) {
          await markCandidateNotesAsRead(params);
          queryClient.invalidateQueries([
            PIPELINE_CANDIDATES_KEY,
            params.jobOpeningId,
            params.categoryId,
          ]);
        }
      },
      refetchOnMount: query => (query.state.data?.hasUnread ? 'always' : true),

      ...(options as any),
    }
  );
};

export const useCreateCandidateInterviewNoteMutation = (
  options?: UseMutationOptions
) =>
  useMutation(createCandidateInterviewNote, {
    mutationKey: CANDIDATE_INTERVIEW_NOTES_QUERY_KEY,
    ...(options as any),
  });

export const useUpdateCandidateInterviewNoteMutation = (
  options?: UseMutationOptions
) =>
  useMutation(updateCandidateInterviewNote, {
    mutationKey: CANDIDATE_INTERVIEW_NOTES_QUERY_KEY,
    ...(options as any),
  });

export const useDeleteCandidateInterviewNoteMutation = (
  options?: UseMutationOptions
) =>
  useMutation(deleteCandidateInterviewNote, {
    mutationKey: CANDIDATE_INTERVIEW_NOTES_QUERY_KEY,
    ...(options as any),
  });
