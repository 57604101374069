import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useSort } from '@/features/candidate/utils';
import { TSortOrder } from '@/features/candidate-search';
import { Icon } from '@/features/common';

interface SortButtonProps {
  order: TSortOrder;
  disabled: boolean;
  children: React.ReactNode;
}

export function SortButton({ order, disabled, children }: SortButtonProps) {
  const { sortOrder, sortDirection, setSort } = useSort();
  const isCurrentSort = sortOrder === order;

  return (
    <SortButtonWrapper>
      <div>{children}</div>
      <SortButtonContainer onClick={() => !disabled && setSort(order)}>
        <Icon
          icon={regular('chevron-up')}
          color={
            isCurrentSort && sortDirection === 'asc'
              ? 'orange-100'
              : 'purple-60'
          }
          size="2xs"
        />
        <Icon
          icon={regular('chevron-down')}
          color={
            isCurrentSort && sortDirection === 'desc'
              ? 'orange-100'
              : 'purple-60'
          }
          size="2xs"
        />
      </SortButtonContainer>
    </SortButtonWrapper>
  );
}

//region styles
const SortButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SortButtonContainer = styled.button`
  border: none;
  background: none;
  user-select: none;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;

  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
//endregion
