import { TId } from '@/features/common';

export const deleteSortStorage = (jobOpeningId: TId | null) => {
  if (jobOpeningId) {
    Object.keys(localStorage)
      .filter(key => key.includes('sort') && key.includes(jobOpeningId))
      .forEach(sortStorage => {
        localStorage.removeItem(sortStorage);
      });
  }
  return null;
};
