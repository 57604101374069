import React from 'react';
import { useMedia } from 'react-use';

import { PageHeader } from '@/features/common';
import { getActivePipeline } from '@/utils/pipeline';

import { useTestOnly } from '../../hooks';

export function TestLibraryHeader() {
  const isMobile = useMedia('(max-width: 768px)');
  const testOnly = useTestOnly();

  const backTo = testOnly ? `/openings/${getActivePipeline()}` : `/openings`;
  const tooltip = testOnly ? `Go back to pipeline` : `Go back to dashboard`;
  const title = testOnly ? 'Add a test' : 'Test Library';

  return (
    <PageHeader
      title={title}
      backButton={{ to: backTo, tooltip: tooltip }}
      sticky={isMobile}
    />
  );
}
