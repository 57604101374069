import React from 'react';
import { TogglThemeProvider } from '@hundred5/design-system';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import AxiosInterceptorsHandler from '@/components/app/AxiosInterceptorsHandler';
import { AuthProvider } from '@/features/authentication';
import { FlashMessagesProvider } from '@/features/flash-messages';
import { queryClient } from '@/utils/reactQuery';

interface EnvironmentProps {
  store: Store;
  history: History;
  children: React.ReactNode;
}

export function Environment(props: EnvironmentProps) {
  return (
    <Provider store={props.store}>
      <TogglThemeProvider>
        <FlashMessagesProvider>
          <AuthProvider />
          <ConnectedRouter history={props.history}>
            <QueryClientProvider client={queryClient}>
              <AxiosInterceptorsHandler>
                {props.children}
              </AxiosInterceptorsHandler>
            </QueryClientProvider>
          </ConnectedRouter>
        </FlashMessagesProvider>
      </TogglThemeProvider>
    </Provider>
  );
}
