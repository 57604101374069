import React from 'react';
import styled from '@emotion/styled';
import {
  DSPictureOption,
  DSPictureOptionsAnswer,
  DSStyledContent,
  DSTextAnswer,
  DSTextOption,
  DSTextOptionsAnswer,
} from '@hundred5/design-system';
import { uniqueId } from 'lodash';

import Markdown from '@/components/markdown/Markdown';
import { IQuestionOption, TQuestionType } from '@/features/questions';

import { CodeInputAnswerPreview, VideoAnswerPreview } from './ui';

interface QuestionAnswersProps {
  answer?: number;
  options: IQuestionOption[];
  type: TQuestionType;
}

function getOptionTypeForQuestionType(
  type: TQuestionType,
  options: IQuestionOption[]
) {
  switch (type) {
    case 'single-choice':
      return 'single';
    case 'multiple-choice':
      return 'multiple';
    case 'placeholder-close-ended':
      return options.filter(o => o.isCorrect).length > 1
        ? 'multiple'
        : 'single';
    default:
      return 'single';
  }
}

export const QuestionAnswers = ({
  answer,
  options,
  type,
}: QuestionAnswersProps) => {
  switch (type) {
    case 'free-text':
      return (
        <AnswerContainer>
          <TextAnswer placeholder="Write answer here..." disabled />
        </AnswerContainer>
      );

    case 'numeric-input':
      return (
        <AnswerContainer>
          <TextAnswer
            placeholder="Write your answer here..."
            value={String(answer!)}
            disabled
          />
        </AnswerContainer>
      );

    case 'single-choice':
    case 'multiple-choice':
    case 'placeholder-close-ended': {
      const answerId = uniqueId();
      const optionType = getOptionTypeForQuestionType(type, options);
      return (
        <AnswerContainer>
          <DSTextOptionsAnswer>
            {options.map((option, index) => (
              <DSTextOption
                key={index}
                name={`answer-${answerId}`}
                id={`option-${answerId}-${index}`}
                type={optionType}
                selected={option.isCorrect}
                disabled
              >
                <OptionContent>
                  <Markdown source={option.option} />
                </OptionContent>
              </DSTextOption>
            ))}
          </DSTextOptionsAnswer>
        </AnswerContainer>
      );
    }

    case 'picture-question': {
      const answerId = uniqueId();

      return (
        <AnswerContainer>
          <DSPictureOptionsAnswer>
            {options.map((option, index) => (
              <DSPictureOption
                key={index}
                name={`answer-${answerId}`}
                id={`option-${answerId}-${index}`}
                url={option.option!}
                selected={option.isCorrect}
                disabled
              />
            ))}
          </DSPictureOptionsAnswer>
        </AnswerContainer>
      );
    }

    case 'video-question':
      return (
        <AnswerContainer>
          <VideoAnswerPreview />
        </AnswerContainer>
      );

    case 'code-input':
      return (
        <AnswerContainer>
          <CodeInputAnswerPreview />
        </AnswerContainer>
      );

    default:
      return null;
  }
};

const AnswerContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.purple[10]};
  padding: 16px 24px 24px 24px;
`;

const TextAnswer = styled(DSTextAnswer)`
  &::placeholder {
    color: ${props => props.theme.colors.purple[60]};
  }
`;

const OptionContent = styled(DSStyledContent)`
  overflow: hidden;
  display: flex;
  align-items: center;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
`;
