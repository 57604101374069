import { useMemo } from 'react';

import { ISkill, TSkillType } from '../types';
import { orderTabs } from '../utils';

interface IUseSkillCategoryTabsArguments {
  skills: ISkill[];
  searchQuery: string;
}

export function useSkillCategoryTabs({
  skills,
  searchQuery,
}: IUseSkillCategoryTabsArguments) {
  const allTabs = useMemo(() => {
    return (
      skills.reduce((tabs, skill) => {
        if (tabs[skill.type]) {
          return {
            ...tabs,
            [skill.type]: [...tabs[skill.type], skill],
          };
        }
        return {
          ...tabs,
          [skill.type]: [skill],
        };
      }, {}) ?? {}
    );
  }, [skills]);

  const filteredTabs = useMemo(() => {
    if (searchQuery === '') {
      return allTabs;
    }

    return Object.entries(allTabs).reduce(
      (filteredTabs, [tabKey, skills]: [string, ISkill[]]) => {
        return {
          ...filteredTabs,
          [tabKey]: skills.filter(skill =>
            skill.name.toLowerCase().includes(searchQuery.toLowerCase())
          ),
        };
      },
      {}
    );
  }, [allTabs, searchQuery]);

  const allTabsKeys = useMemo(() => {
    return orderTabs(Object.keys(allTabs) as TSkillType[]);
  }, [allTabs]);

  const filteredTabsKeys = useMemo(() => {
    return orderTabs(Object.keys(filteredTabs) as TSkillType[]);
  }, [filteredTabs]);

  return {
    allTabs,
    allTabsKeys,
    filteredTabs,
    filteredTabsKeys,
  };
}
