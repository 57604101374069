import * as React from 'react';
import styled from '@emotion/styled';

interface SettingsContentBlockProps {
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export default function SettingsContentBlock({
  title,
  footer,
  children,
}: SettingsContentBlockProps) {
  return (
    <Container>
      {title && (
        <TitleContainer>
          <Label>{title}</Label>
        </TitleContainer>
      )}

      <div>{children}</div>

      {footer ? <Footer>{footer}</Footer> : null}
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow.card};
  border-radius: 8px;
  padding: 36px;

  @media all and (max-width: 768px) {
    padding: 16px;
  }
`;

const TitleContainer = styled.div`
  margin: 0 -36px 20px;
  padding: 0 36px 10px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.purple[100]};
  line-height: 19px;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
`;

const Footer = styled.div`
  padding: 16px 36px;
  margin: 16px -36px -36px -36px;
  border-top: 1px solid ${props => props.theme.colors.purple[10]};

  @media all and (max-width: 768px) {
    margin-bottom: -16px;
  }
`;

const Label = styled.h2`
  margin: 0;
`;
