import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSSelect } from '@hundred5/design-system';
import formatDate from 'date-fns/format';
import { capitalize } from 'lodash';

import {
  BillingPlan as BillingPlanType,
  IBillingPlansDescription,
  PremiumPlanVariant,
} from '@/features/billing';
import { Icon } from '@/features/common';
import {
  getPlanName,
  getPlanPeriod,
  getPlanStrenght,
  usePlanLimits,
} from '@/hooks/planLimits';

//region Main
interface IBillingPlanProps {
  planDescription: IBillingPlansDescription;
  selectedPeriod: 'monthly' | 'annual';
  currentPlan: BillingPlanType;
  subscribe?: () => void;
  purchaseCredits?: () => void;
  cancelSubsctiption?: () => void;
  downgradeDate?: Date | null;
  premiumPlanVariants?: PremiumPlanVariant[];
  onChangePremiumPlanVariant?: (planVariant: PremiumPlanVariant) => void;
}

export const BillingPlan = (props: IBillingPlanProps) => {
  const currentPlanName = getPlanName(props.currentPlan);
  const currentPeriod =
    currentPlanName === 'free'
      ? props.selectedPeriod
      : getPlanPeriod(props.currentPlan);
  const premiumPlanVariantItems = [
    { id: '300c', label: '300 candidates' },
    { id: '60c', label: '60 candidates' },
    { id: '45c', label: '45 candidates' },
  ].filter(item =>
    props.premiumPlanVariants?.includes(item.id as PremiumPlanVariant)
  );

  return props.selectedPeriod === 'monthly' &&
    props.planDescription.plan === 'basic' ? null : (
    <BillingPlanContainer
      highlighted={props.planDescription.plan === 'premium'}
    >
      <BillingPlanContent
        highlighted={props.planDescription.plan === 'premium'}
      >
        <PlanTitle highlighted={props.planDescription.plan === 'premium'}>
          {capitalize(props.planDescription.plan)}
        </PlanTitle>
        <PlanDescription>{props.planDescription.description}</PlanDescription>
        <PlanPrice>
          <span>$</span>
          {props.planDescription.price[props.selectedPeriod].perMonth}
          <span> /month</span>
        </PlanPrice>
        <PriceNote>
          {props.planDescription.plan === 'free' ? (
            <>&nbsp;</>
          ) : props.selectedPeriod === 'annual' ||
            props.planDescription.plan === 'basic' ? (
            <>
              Billed annually at{' '}
              {props.planDescription.plan === 'basic' ||
              (props.planDescription.plan === 'premium' &&
                ['60c', '45c'].includes(
                  props.planDescription.variant ?? ''
                )) ? (
                <strong>
                  $
                  {new Intl.NumberFormat('en-US').format(
                    props.planDescription.price[props.selectedPeriod].perYear
                  )}
                </strong>
              ) : (
                <>
                  <s>
                    $
                    {new Intl.NumberFormat('en-US').format(
                      props.planDescription.price.monthly.perYear
                    )}
                  </s>{' '}
                  <strong>
                    $
                    {new Intl.NumberFormat('en-US').format(
                      props.planDescription.price[props.selectedPeriod].perYear
                    )}
                  </strong>
                </>
              )}
            </>
          ) : (
            <>Recurring monthly payment</>
          )}
        </PriceNote>
        <VariantSelector>
          {props.onChangePremiumPlanVariant &&
            props.selectedPeriod === 'annual' &&
            props.planDescription.plan === 'premium' && (
              <DSSelect
                items={premiumPlanVariantItems}
                selectedItemId={props.planDescription.variant}
                onChange={selected =>
                  props.onChangePremiumPlanVariant?.(
                    selected?.id as PremiumPlanVariant
                  )
                }
              />
            )}
        </VariantSelector>
        <ButtonsContainer>
          {props.planDescription.plan === currentPlanName &&
          props.selectedPeriod === currentPeriod ? (
            <>
              {props.planDescription.planCode[props.selectedPeriod] !==
                props.currentPlan &&
                currentPlanName === 'premium' && (
                  <CTAButton variant="secondary" onClick={props.subscribe}>
                    {props.planDescription.variant === '300c'
                      ? 'Upgrade to 300 '
                      : props.planDescription.variant === '60c'
                      ? props.currentPlan === 'premium-12m-45c-2024'
                        ? 'Upgrade to 60 '
                        : 'Downgrade to 60 '
                      : 'Downgrade to 45 '}
                    candidates
                  </CTAButton>
                )}
              <CurrentPlan
                planDescription={props.planDescription}
                currentPlan={props.currentPlan}
                selectedPeriod={props.selectedPeriod}
                purchaseCredits={props.purchaseCredits}
              />
            </>
          ) : props.planDescription.plan === currentPlanName ? (
            <CTAButton onClick={props.subscribe}>
              Switch to {capitalize(props.selectedPeriod)}
            </CTAButton>
          ) : props.planDescription.plan === 'free' && props.downgradeDate ? (
            <PlanStatus highlighted={false} fontSize="12px">
              Downgrading to Free on{' '}
              {formatDate(props.downgradeDate, 'MMM D, YYYY')}
            </PlanStatus>
          ) : getPlanStrenght(props.planDescription.plan) >
            getPlanStrenght(currentPlanName) ? (
            <CTAButton
              onClick={props.subscribe}
              variant={
                props.planDescription.plan === 'premium'
                  ? 'primary'
                  : 'secondary'
              }
            >
              Upgrade to {capitalize(props.planDescription.plan)}
            </CTAButton>
          ) : getPlanStrenght(props.planDescription.plan) <
            getPlanStrenght(currentPlanName) ? (
            <CTAButton
              variant="secondary"
              onClick={
                props.planDescription.plan === 'free'
                  ? props.cancelSubsctiption
                  : props.subscribe
              }
            >
              Downgrade to {capitalize(props.planDescription.plan)}
            </CTAButton>
          ) : null}
        </ButtonsContainer>

        <LimitsList>
          {props.planDescription.limits.map((limit, index) => (
            <li key={limit}>
              {index === 0 ? (
                <>
                  <HighlightedLimit>{limit.split(' ')[0]}</HighlightedLimit>{' '}
                  {limit
                    .split(' ')
                    .slice(1)
                    .join(' ')}
                </>
              ) : (
                limit
              )}
            </li>
          ))}
        </LimitsList>
        <FeaturesContainer>
          <FeaturesTitle>Key features</FeaturesTitle>
          <FeatureList>
            {props.planDescription.features.map(feature => (
              <FeatureListItem key={feature}>{feature}</FeatureListItem>
            ))}
          </FeatureList>
        </FeaturesContainer>
      </BillingPlanContent>
    </BillingPlanContainer>
  );
};

const FeatureListItem = ({
  children,
  ...props
}: React.LiHTMLAttributes<HTMLLIElement>) => (
  <FeatureListItemWrapper
    className="billing-plans__feature_list_item"
    {...props}
  >
    <Icon icon={regular('check')} color="orange-100" />
    <span>{children}</span>
  </FeatureListItemWrapper>
);

const CurrentPlan = ({
  planDescription,
  selectedPeriod,
  currentPlan,
  purchaseCredits,
}: {
  planDescription: IBillingPlansDescription;
  selectedPeriod: 'monthly' | 'annual';
  currentPlan: BillingPlanType;
  purchaseCredits?: () => void;
}) => {
  const canPurchaseCredits = usePlanLimits()('purchase_credits');
  return (
    <>
      {canPurchaseCredits && getPlanName(currentPlan) !== 'free' ? (
        <>
          {planDescription.variant && (
            <PlanStatus highlighted={planDescription.plan === 'premium'}>
              Current Plan
            </PlanStatus>
          )}
          <CTAButton onClick={purchaseCredits}>Buy candidate credits</CTAButton>
        </>
      ) : (
        planDescription.planCode[selectedPeriod] === currentPlan && (
          <PlanStatus highlighted={planDescription.plan === 'premium'}>
            Current Plan
          </PlanStatus>
        )
      )}
    </>
  );
};
//endregion

//region Styles
const FeatureListItemWrapper = styled.li`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 400;

  svg {
    font-size: 14px;
    margin-right: 8px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureList = styled(List)`
  gap: 12px;
`;

const LimitsList = styled(List)`
  margin-top: 24px;
  margin-bottom: 12px;
  gap: 8px;
`;

const CTAButton = styled(DSButton)`
  width: 224px;
`;

const HighlightedLimit = styled.span`
  display: inline-block;
  padding: 1.5px 4px;
  font-weight: 500;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple[60]};
  border-radius: 8px;
`;

const BillingPlanContent = styled.div<{ highlighted?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${props =>
    props.highlighted
      ? props.theme.colors.peach[100]
      : props.theme.colors.purple[90]};
  border-radius: 6px;
  padding: 24px 16px;
  z-index: 1;
`;

const BillingPlanContainer = styled.div<{ highlighted?: boolean }>`
  display: flex;
  color: ${props =>
    props.highlighted
      ? props.theme.colors.purple[100]
      : props.theme.colors.white};
  position: relative;
  height: auto;
  width: 256px;

  &::before {
    ${props =>
      props.highlighted &&
      css`
        position: absolute;
        top: -41px;
        left: -4px;
        z-index: -1;
        content: 'Best value';
        font-family: GT Haptik;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px;
        height: calc(100% + 45px);
        width: calc(100% + 8px);
        box-sizing: border-box;
        text-align: center;
        padding-top: 10px;
        background-color: ${props.theme.colors.orange[50]};
      `}
  }
`;

const PlanPrice = styled.div`
  font-family: GT Haptik;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-top: 0;
    /* letter-spacing: 0.04em; */
  }
`;

const PriceNote = styled.div`
  font-size: 13px;
  margin-top: 4px;
`;

const PlanTitle = styled.h3<{ highlighted?: boolean }>`
  font-family: GT Haptik;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
  color: ${props =>
    props.highlighted
      ? props.theme.colors.orange[100]
      : props.theme.colors.white};
`;

const PlanDescription = styled.div`
  margin: 24px 0;
  height: 96px;
  font-size: 16px;
  font-weight: 400;
`;

const VariantSelector = styled.div`
  height: 36px;
  margin-top: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  height: 84px;
`;

const PlanStatus = styled.div<{ highlighted: boolean; fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  text-align: center;
  padding: 8px 0;
  height: 36px;
  width: calc(100% + 32px);
  background: ${props =>
    props.highlighted
      ? props.theme.colors.orange[50]
      : props.theme.colors.purple[60]};
`;

const FeaturesContainer = styled.div`
  margin: 24px 0 36px;
`;
const FeaturesTitle = styled.p`
  font-size: 18px;

  margin: 0 0 24px;
  font-weight: 700;
`;
//endregion
