import { ClientError } from '@/errors';
import {
  useChangeSubscriptionPlanPreviewMutation,
  useSubscribeModal,
} from '@/features/billing';
import { useFlashMessages } from '@/features/flash-messages';
import { useApiError } from '@/hooks/api';

import { BillingPreview } from '../types';

export const useChangeSubscriptionPlanPreview = () => {
  const { showFlashMessage } = useFlashMessages();
  const handleApiError = useApiError();
  const { changeSubscriptionPlanPreview } = useSubscribeModal();

  const changeSubscriptionMutation = useChangeSubscriptionPlanPreviewMutation({
    onSuccess: (preview: BillingPreview) => {
      changeSubscriptionPlanPreview(preview);
    },
    onError: error => {
      if (error instanceof ClientError && error.type === 'InvalidCouponCode') {
        showFlashMessage({ type: 'coupon_limit_reached' });
      } else {
        handleApiError(error);
      }
    },
  });

  return changeSubscriptionMutation.mutateAsync;
};
