import React from 'react';
import { CandidateAnswer } from '@hundred5/design-system';

import { ICandidateAnswer } from '@/features/candidate';

export interface NumericAnswerProps {
  answer?: ICandidateAnswer;
}

export function NumericAnswer(props: NumericAnswerProps) {
  return props.answer ? (
    <CandidateAnswer.NumericInput
      correct={props.answer.scoreAsPercents === 100}
    >
      {props.answer.freeFormAnswer}
    </CandidateAnswer.NumericInput>
  ) : null;
}
