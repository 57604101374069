import styled from '@emotion/styled';

export const EditTestButtonContainer = styled.div`
  display: flex;
`;

export const EditTestButton = styled.button`
  border: none;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.peach[100]};
  border-radius: 8px 0 0 8px;
  display: grid;
  grid-template-columns: 24px 1fr;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  align-items: center;
  margin-bottom: 8px;
  padding-right: 8px;
  height: 36px;
  width: 100%;

  &:last-child {
    border-radius: 8px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.peach[50]};
  }
`;
