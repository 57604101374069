import React from 'react';
import styled from '@emotion/styled';

import { TGmailStatus } from '@/features/me';

import { ContentLarge, ContentSmall } from './ui';

interface EmailBannerProps {
  size: 'small' | 'large';
  status?: TGmailStatus;
  onClick: () => void;
}

/**
 * Banner for pushing users connect their email to Gmail.
 * @param size - size of the banner.
 *    'small' is short, wide banner with purple5 background;
 *    'large' is rectangular banner without background.
 */
export function EmailBanner({ size, status, onClick }: EmailBannerProps) {
  return (
    <EmailBannerContainer>
      {size === 'small' && <ContentSmall status={status} onClick={onClick} />}
      {size === 'large' && <ContentLarge status={status} onClick={onClick} />}
    </EmailBannerContainer>
  );
}

const EmailBannerContainer = styled.div`
  container-name: email-banner;
  container-type: inline-size;
`;
