import React from 'react';

export const NegativeIcon = props => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" {...props}>
    <circle cx="19" cy="19" r="19" fill="#E994AA" />
    <path
      d="M31.0196 12C32.3268 12 32.3268 13.9588 31.0196 13.9588C29.7123 13.9588 29.7123 12 31.0196 12ZM6.98045 13.9588C8.28771 13.9588 8.28771 12 6.98045 12C5.67318 12 5.67318 13.9588 6.98045 13.9588ZM13.662 19.9751C13.8072 20.6047 14.8966 20.3249 14.7514 19.6953C14.0251 16.897 15.7682 13.7489 18.9637 13.679C22.3045 13.5391 24.4832 16.827 23.0307 19.6953C22.7402 20.3249 23.6844 20.8146 23.9749 20.2549C25.7179 16.897 23.5391 12.8395 19.4721 12.6296C15.3324 12.3498 12.7179 16.2674 13.662 19.9751Z"
      fill="#1A1A1A"
    />
  </svg>
);
