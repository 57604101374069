import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { CircularScore, SkillScore } from '@togglhire/candidate-components';
import { groupBy, map, mapValues } from 'lodash';

import {
  getCandidateAnswerScoreStatus,
  ICandidateTest,
  useCandidateTest,
} from '@/features/candidate';
import { formatInterval, TId } from '@/features/common';
import { useSkillsQuery } from '@/features/skills';

//region Main
export interface CandidateAnswersSkillsSummaryProps {
  candidateId: TId;
  testId: TId;
}

export function CandidateSkillsSummary(
  props: CandidateAnswersSkillsSummaryProps
) {
  const { data: skills } = useSkillsQuery({
    requestParams: { includeHidden: true },
  });
  const candidateTest = useCandidateTest(props);
  const skillsSummary = useSkillsSummary(candidateTest);

  if (!skills || !candidateTest || !skillsSummary) return null;

  return (
    <CandidateSkillsSummaryContainer>
      <Score>
        <CircularScore
          score={Math.floor(candidateTest.percent || 0)}
          color={
            candidateTest.percent
              ? candidateTest.percent >= (candidateTest.percentToPass || 60)
                ? 'green'
                : 'red'
              : 'default'
          }
          thickness={25}
          size={160}
        />
        <QuestionsAndTime>
          <div>{candidateTest.questions?.length || 0} questions</div>

          {candidateTest.finishedAt && (
            <div>
              Time:{' '}
              {formatInterval(
                candidateTest.startedAt,
                candidateTest.finishedAt
              )}
            </div>
          )}
        </QuestionsAndTime>
      </Score>
      <Skills>
        {map(skillsSummary, (percentage, skillId) => (
          <StyledSkillScore
            key={skillId}
            name={
              skills?.find(skill => skill.id === skillId)?.name ||
              'Custom question(s)'
            }
            size={percentage === -1 ? 0 : percentage / 100}
            score={
              percentage === -1 ? (
                <DSButton
                  size="xsmall"
                  id={skillId}
                  type="button"
                  onClick={() => {
                    document
                      .querySelector(
                        `[data-skill-id="${skillId}"][data-evaluation-needed="true"]`
                      )
                      ?.scrollIntoView({
                        behavior: 'smooth',
                      });
                  }}
                >
                  Evaluate answers
                </DSButton>
              ) : (
                `${Math.floor(percentage)}%`
              )
            }
            color={
              percentage >= (candidateTest.percentToPass || 60)
                ? 'green'
                : 'red'
            }
          />
        ))}
      </Skills>
    </CandidateSkillsSummaryContainer>
  );
}

//endregion

//region Hooks
const useSkillsSummary = (
  candidateTest: ICandidateTest | undefined
): Record<TId, number> | null =>
  useMemo(
    () =>
      candidateTest
        ? mapValues(
            groupBy(candidateTest.questions, 'testGenSkillId'),
            questions => {
              let questionPointsSum = 0;
              let answerPointsSum = 0;
              let needsEvaluation = false;

              questions.forEach(question => {
                if (question.points !== 0) {
                  questionPointsSum += question.points;

                  const answer = candidateTest.answers?.find(
                    answer => answer.questionId === question.id
                  );

                  const scoreStatus = getCandidateAnswerScoreStatus(
                    question,
                    answer
                  );

                  if (answer) {
                    if (scoreStatus === 'manual') {
                      needsEvaluation = true;
                    } else if (scoreStatus !== 'missing') {
                      answerPointsSum += answer.scoreAsPoints || 0;
                    }
                  }
                }
              });

              if (needsEvaluation) {
                return -1;
              } else {
                return (answerPointsSum / questionPointsSum) * 100 || 0;
              }
            }
          )
        : null,
    [candidateTest]
  );
//endregion

//region Styles
const CandidateSkillsSummaryContainer = styled.div`
  padding: 36px;
  border-bottom: none;

  display: flex;

  @media all and (max-width: 1154px) {
    flex-direction: column;
  }
`;

const Score = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 40px;

  @media all and (max-width: 1154px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const QuestionsAndTime = styled.div`
  font-size: 12px;
  margin-top: 20px;
`;

const Skills = styled.div`
  flex: 1;
`;

const StyledSkillScore = styled(SkillScore)`
  border-radius: 8px;

  & > div > div,
  & > div > div > div {
    border-radius: 8px;
  }
`;
//endregion
