import React from 'react';
import styled from '@emotion/styled';
import { DSCheckbox, DSInlineField, DSRadio } from '@hundred5/design-system';
import TextareaAutosize from 'react-textarea-autosize';

interface QuestionAnswerAddOptionProps {
  type: 'single-choice' | 'multiple-choice';
  onAdd: (value: string) => void;
}

export const QuestionAnswerAddOption = ({
  type,
  onAdd,
}: QuestionAnswerAddOptionProps) => {
  return (
    <InlineField
      for="add-option"
      label={
        <TextArea
          id="add-option"
          autoFocus={false}
          placeholder="Add choice"
          onBlur={event => {
            const value = event.target.value.trim();
            if (value) {
              onAdd(value);
              event.target.value = '';
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter' && !event.shiftKey) {
              event.preventDefault();
              const target = event.target as HTMLTextAreaElement;
              const value = target.value.trim();
              if (value) {
                onAdd(value);
                target.value = '';
              }
            }
          }}
        />
      }
    >
      {type === 'single-choice' ? (
        <DSRadio disabled />
      ) : (
        <DSCheckbox disabled />
      )}
    </InlineField>
  );
};

const InlineField = styled(DSInlineField)`
  margin: 2px 32px 0 18px;

  label {
    width: 100%;
  }
`;

const TextArea = styled(TextareaAutosize)`
  display: block;
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  resize: none;
  border: none;
  padding: 0;
  margin: 0;
  line-height: 21px;
  font-size: 14px;
  color: ${props => props.theme.typography.colorPrimary};

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: ${props => props.theme.typography.colorSecondary};
  }
`;
