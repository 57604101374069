import * as React from 'react';

import {
  CreditsPurchase,
  CreditsPurchaseItemCode,
} from '../../../features/billing/types/billing';

import './PurchaseCreditsPayment.css';

type Props = {
  itemCode: CreditsPurchaseItemCode;
  preview: CreditsPurchase;
};

const formatPrice = (price: number): string => '$' + (price / 100).toFixed(2);

const Charges = ({ preview }: { preview: CreditsPurchase }) => {
  if (preview.chargeInvoice == null) {
    return null;
  }

  const discount =
    preview.chargeInvoice.discountInCents > 0 ? (
      <div className="purchase-credits-payment__discount">
        Discount: -{formatPrice(preview.chargeInvoice.discountInCents)}
      </div>
    ) : null;

  const subtotal = (
    <div className="purchase-credits-payment__subtotal">
      Subtotal: {formatPrice(preview.chargeInvoice.subtotalInCents)}
    </div>
  );

  const tax =
    preview.chargeInvoice.taxInCents > 0 ? (
      <div className="purchase-credits-payment__tax">
        {preview.chargeInvoice.taxType.toUpperCase() || 'Tax'}:{' '}
        {formatPrice(preview.chargeInvoice.taxInCents)}
      </div>
    ) : null;

  const total = (
    <div className="purchase-credits-payment__total">
      <strong>Total: {formatPrice(preview.chargeInvoice.totalInCents)}</strong>
    </div>
  );

  return (
    <>
      {discount}
      {subtotal}
      {tax}
      {total}
    </>
  );
};

const Payment = ({ preview }: Props) => (
  <div className="purchase-credits-payment">
    <div className="purchase-credits-payment__info">
      <div className="purchase-credits-payment__notes" />
    </div>

    <div className="purchase-credits-payment__charges">
      <Charges preview={preview} />
    </div>
  </div>
);

export default Payment;
