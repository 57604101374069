import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { object, string } from 'yup';

import {
  FormikInputField,
  FormikSelectField,
  ScheduleMenu,
  TId,
} from '@/features/common';
import {
  emailMessageValidationSchema,
  emailSubjectValidationSchema,
  FormikEmailBody,
  FormikEmailForm,
  IEmailFormValuesBase,
} from '@/features/email';
import {
  useJobOpeningQuery,
  useRecommendedCandidatesInvitationMutation,
} from '@/features/job-opening';
import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { useHistory, useJobOpeningId, useWorkspaceId } from '@/hooks/router';

interface InvitationProps {
  candidateId: TId;
}

interface IInvitationFormValues extends IEmailFormValuesBase {
  jobOpeningName: string;
  pipelineStepId: string;
  message: string;
  subject: string;
}

export const Invitation = ({ candidateId }: InvitationProps) => {
  const history = useHistory();
  const jobOpeningId = useJobOpeningId();
  const workspaceId = useWorkspaceId();
  const pipelineCategoriesQuery = usePipelineCategoriesQuery({ jobOpeningId });

  const jobOpeningQuery = useJobOpeningQuery();

  const pipelineSteps = useMemo(() => {
    if (pipelineCategoriesQuery.isSuccess) {
      return pipelineCategoriesQuery.data?.map(item => ({
        id: item.id,
        label: item.name,
        testId: item.testId,
      }));
    }

    return [];
  }, [pipelineCategoriesQuery]);
  const [categoryId, setCategoryId] = useState(pipelineSteps[0]?.id);

  const jobOpeningName = jobOpeningQuery.isSuccess
    ? jobOpeningQuery.data.name ?? ''
    : '';
  const recommendedCandidatesInvitationMutation = useRecommendedCandidatesInvitationMutation(
    { jobOpeningId, categoryId }
  );

  const sendInvitation = useCallback(
    async (values: IInvitationFormValues) => {
      const results = {
        jobOpeningId: jobOpeningId,
        emailData: {
          candidateId: candidateId,
          pipelineStageId: values.pipelineStepId,
          email: {
            subject: values.subject,
            message: values.message,
            sendAt: values.sendAt,
          },
        },
      };
      setCategoryId(values.pipelineStep);

      const response = await recommendedCandidatesInvitationMutation.mutateAsync(
        results
      );

      if (response && response.candidateId) {
        history.push(
          `/admin/${workspaceId}/openings/${jobOpeningId}/pipeline/stage/${categoryId}/candidate/${response.candidateId}/results?${window.location.search}`
        );
      } else {
        history.push(`/admin/${workspaceId}/openings/${jobOpeningId}/pipeline`);
      }
    },
    [
      history,
      categoryId,
      candidateId,
      jobOpeningId,
      recommendedCandidatesInvitationMutation,
      workspaceId,
    ]
  );

  return (
    <>
      <Title>Invite Candidate</Title>

      <FormikEmailForm<IInvitationFormValues>
        onSubmit={sendInvitation}
        initialValues={{
          jobOpeningName,
          pipelineStepId: pipelineSteps[0]?.id,
          message: '',
          subject: '',
        }}
        validationSchema={getValidationSchema()}
      >
        {({ isSubmitting, setFieldValue, submitForm, values }) => (
          <FormContainer>
            <StyledFormikInputField
              label="Job"
              name="jobOpeningName"
              required
              disabled
            />
            <PipelineWrapper>
              <FormikSelectField
                label="Pipeline stage"
                name="pipelineStepId"
                items={pipelineSteps}
              />
            </PipelineWrapper>
            <StyledFormikEmailBody
              emailType="invitation"
              interviewId={null}
              testId={
                pipelineSteps.find(
                  pieplineStep => pieplineStep.id === values.pipelineStepId
                )?.testId || undefined
              }
              tip="Quick tip! Add link to your job description to your email."
            />
            <ButtonsWrapper>
              <StyledDSButton type="submit" disabled={isSubmitting}>
                {!isSubmitting ? 'Invite Candidate' : 'Invite Candidate...'}
              </StyledDSButton>
              <ScheduleMenu
                closeDateAndTimePickerModal={true}
                disabled={isSubmitting}
                onScheduleSend={time => {
                  setFieldValue('sendAt', time);
                  submitForm();
                }}
              />
            </ButtonsWrapper>
          </FormContainer>
        )}
      </FormikEmailForm>
    </>
  );
};

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple[100]};
  margin-bottom: 16px;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-auto-columns: minmax(0, 2fr);
  grid-auto-rows: min-content;
  grid-gap: 12px;
  grid-template-areas:
    'jobOpening jobOpening pipeline pipeline'
    'emailBody emailBody emailBody emailBody'
    '. . . button';
  align-items: end;
`;

const StyledFormikInputField = styled(FormikInputField)`
  grid-area: jobOpening;
`;

const PipelineWrapper = styled.div`
  grid-area: pipeline;
`;

const ButtonsWrapper = styled.div`
  grid-area: button;
  position: relative;
  display: flex;
  gap: 1px;
  padding-bottom: 28px;
  padding-top: 12px;
`;

const StyledDSButton = styled(DSButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const StyledFormikEmailBody = styled(FormikEmailBody)`
  grid-area: emailBody;
`;

export function getValidationSchema() {
  return object().shape({
    jobOpeningName: string(),
    pipelineStepId: string()
      .trim()
      .required('This field is mandatory'),
    emailTemplate: string().trim(),
    subject: emailSubjectValidationSchema,
    message: emailMessageValidationSchema,
  });
}
