import React from 'react';

import { useCandidateAttachmentsQuery } from '@/features/candidate';
import { TId } from '@/features/common';
import { useJobOpeningPermission } from '@/features/permissions';

import {
  Container,
  DeleteAttachmentButton,
  DownloadAttachmentButton,
  Header,
  List,
  ListItem,
  NoAttachments,
  NoAttachmentsIllustration,
  UploadAttachmentButton,
  UploadButtonContainer,
} from './ui';

interface CandidateAttachmentsProps {
  candidateId: TId;
}

export function CandidateAttachments({
  candidateId,
}: CandidateAttachmentsProps) {
  const canUpdateCandidate = !!useJobOpeningPermission()('candidate', 'update');
  const attachmentsQuery = useCandidateAttachmentsQuery({ candidateId });

  const validAttachments =
    attachmentsQuery.data?.filter(
      attachment => attachment.uploadedAt !== null
    ) ?? [];

  if (!attachmentsQuery.isSuccess) {
    return null;
  }

  if (validAttachments.length === 0) {
    return (
      <NoAttachments>
        <NoAttachmentsIllustration />
        <h3>Nothing to see (yet!)</h3>
        <p>
          Upload the candidate's resume or other documents. File submissions and
          email attachments will be displayed automatically.
        </p>
        {canUpdateCandidate ? (
          <UploadAttachmentButton candidateId={candidateId} />
        ) : null}
      </NoAttachments>
    );
  }

  return (
    <Container>
      <UploadButtonContainer>
        {canUpdateCandidate ? (
          <UploadAttachmentButton candidateId={candidateId} />
        ) : null}
      </UploadButtonContainer>
      <Header>Attachments</Header>
      <List>
        {validAttachments?.map(attachment => (
          <ListItem key={attachment.id}>
            <span>{attachment.originalFileName}</span>
            <DownloadAttachmentButton attachment={attachment} />

            {canUpdateCandidate ? (
              <DeleteAttachmentButton
                attachment={attachment}
                candidateId={candidateId}
              />
            ) : null}
          </ListItem>
        ))}
      </List>
    </Container>
  );
}
