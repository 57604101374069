import React from 'react';
import styled from '@emotion/styled';

import { useOverflow } from '@/features/common';

interface TestNameProps {
  name: string | undefined;
}

export const TestName = ({ name }: TestNameProps) => {
  const ref = React.useRef<HTMLSpanElement>(null);
  const { hasOverflow } = useOverflow(ref.current);

  return (
    <NameSpan ref={ref} data-rh={hasOverflow ? name : undefined}>
      {name}
    </NameSpan>
  );
};

const NameSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
