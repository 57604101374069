import React, { useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import {
  CandidateAttachments,
  CandidateContextMenu,
  CandidateHistory,
  CandidateNotes,
  CandidateEmail,
  CandidateTags,
  useCandidateQuery,
  useCandidateTest,
  useMarkCandidateAsSeenMutation,
} from '@/features/candidate';
import { CandidateResults } from '@/features/candidate/components/candidate-card/candidate-results';
import { TId } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { useJobOpeningPermission } from '@/features/permissions';
import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { useHistory } from '@/hooks/router';
import { trackSmartlookEvent } from '@/smartlook';

import { CandidateInterviewNotes } from '../candidate-interview-notes';

import {
  Avatar,
  CandidateName,
  CloseButton,
  ContactFields,
  EmailButton,
  MoveToButton,
  Rating,
  RejectButton,
  RestoreButton,
  ShareButton,
  TestTabs,
  SuspiciousTag,
  Tabs,
  TTabType,
} from './ui';

//region Main
export interface CandidateCardProps {
  candidateId: TId;
  interviewId: TId;
}

export function CandidateCard({
  candidateId,
  interviewId,
}: CandidateCardProps) {
  const params = useParams();
  const history = useHistory();
  const canUpdateCandidate = !!useJobOpeningPermission()('candidate', 'update');

  const meQuery = useMeQuery();
  const candidateQuery = useCandidateQuery({ candidateId });
  const pipelineCategoriesQuery = usePipelineCategoriesQuery({
    jobOpeningId: params.opening,
  });
  const candidateTest = useCandidateTest({
    candidateId: params.candidateId,
    testId: params.tab === 'results' ? params.tabItemId : undefined,
  });
  const markCandidateAsSeenMutation = useMarkCandidateAsSeenMutation();

  const isSupportMode = meQuery.data?.id === '0';
  const candidate = candidateQuery.data;

  const handleTabSelect = useCallback(
    (selectedTab: TTabType, tabItemId?: TId) => {
      if (tabItemId) {
        history.push(
          `/admin/${params.workspace}/openings/${params.opening}/pipeline/stage/${params.stageId}/candidate/${params.candidateId}/${selectedTab}/${tabItemId}${history.location.search}`
        );
        return;
      }
      history.push(
        `/admin/${params.workspace}/openings/${params.opening}/pipeline/stage/${params.stageId}/candidate/${params.candidateId}/${selectedTab}${history.location.search}`
      );
    },
    [
      history,
      params.candidateId,
      params.opening,
      params.stageId,
      params.workspace,
    ]
  );

  const handleResultsTabSelect = useCallback(
    (tabItemId: TId) =>
      history.push(
        `/admin/${params.workspace}/openings/${params.opening}/pipeline/stage/${params.stageId}/candidate/${params.candidateId}/results/${tabItemId}${history.location.search}`
      ),
    [
      history,
      params.candidateId,
      params.opening,
      params.stageId,
      params.workspace,
    ]
  );

  // set test id, only if tab is results and it's missing
  useEffect(() => {
    if (
      !candidateQuery.isSuccess ||
      params.tab !== 'results' ||
      params.tabItemId
    ) {
      return;
    }

    // find test id assigned to current category
    const stage = pipelineCategoriesQuery.data?.find(
      category => category.id === params.stageId
    );

    const tabId = stage?.testId ? stage.testId : stage?.interviewId;

    if (tabId) {
      handleResultsTabSelect(tabId);
    } else {
      const lastTest =
        candidateQuery.data.tests && candidateQuery.data.tests.at(-1);
      const lastInterviewId = pipelineCategoriesQuery.data
        ?.map(category => category.interviewId)
        .filter(Boolean)
        .at(-1);

      const lastId = lastTest?.id ? lastTest.id : lastInterviewId;
      if (lastId) {
        handleResultsTabSelect(lastId);
      }
    }
  }, [
    candidate,
    params.tab,
    params.tabItemId,
    params.stageId,
    pipelineCategoriesQuery.data,
    candidateQuery.isSuccess,
    candidateQuery.data?.tests,
    candidateQuery.data?.interviews,
    handleResultsTabSelect,
  ]);

  // mark candidate as seen
  useEffect(() => {
    if (
      candidate &&
      !candidate.seen &&
      !markCandidateAsSeenMutation.isLoading &&
      !isSupportMode
    ) {
      markCandidateAsSeenMutation.mutate({
        candidateId: candidate.id,
      });
    }
  }, [candidate, markCandidateAsSeenMutation, isSupportMode]);

  // analytics
  useEffect(
    () =>
      trackSmartlookEvent('candidate_card_opened', {
        candidateId,
      }),
    [candidateId]
  );

  const isTabInterviewId = pipelineCategoriesQuery.data
    ?.map(cat => cat.interviewId)
    .includes(params.tabItemId);

  return (
    <>
      <Header>
        {candidate?.isDemo && (
          <DemoCandidateMark>Example candidate</DemoCandidateMark>
        )}
        <HeaderTop isDemo={!!candidate?.isDemo}>
          <ContactInfo>
            <Avatar candidateId={candidateId} />
            <span>
              <NameRow>
                <CandidateName candidateId={candidateId} />
                <ShareButton candidateId={candidateId} />
              </NameRow>
              <ContactFields candidateId={candidateId} />
            </span>
          </ContactInfo>
          <ActionButtons>
            {canUpdateCandidate && (
              <>
                <EmailButton
                  candidateId={candidateId}
                  interviewId={interviewId}
                />
                <RejectButton candidateId={candidateId} />
                <RestoreButton candidateId={candidateId} />
                <MoveToButton candidateId={candidateId} />
                <CandidateContextMenu candidateId={candidateId} />
              </>
            )}
            <CloseButton />
          </ActionButtons>
        </HeaderTop>

        <HeaderBottom>
          <Rating candidateId={candidateId} />
          <SuspiciousTag
            candidateId={candidateId}
            isSuspicious={candidate?.isSuspicious ?? null}
          />
          <CandidateTags candidateId={candidateId} />
        </HeaderBottom>
      </Header>
      <CandidateCardContainer>
        <Tabs selectedTab={params.tab} onTabSelect={handleTabSelect} />
        {params.tab === 'results' && (
          <TestTabs
            candidateId={candidateId}
            selectedTabId={params.tabItemId}
            onSelect={handleResultsTabSelect}
          />
        )}
        {params.tab === 'email' && (
          <CandidateEmail
            candidateId={candidateId}
            interviewId={interviewId}
            gmailStatus={meQuery.data?.gmailStatus}
            candidateEmail={candidateQuery.data?.email ?? ''}
          />
        )}
        {params.tab === 'notes' && <CandidateNotes candidateId={candidateId} />}
        {params.tab === 'history' && (
          <CandidateHistory candidateId={candidateId} />
        )}
        {params.tab === 'results' && !isTabInterviewId && (
          <CandidateResults
            candidateId={candidateId}
            candidateTest={candidateTest}
            selectedTestId={params.tabItemId}
            previousTestTakerId={candidate?.previousTestTakerId}
          />
        )}
        {params.tab === 'results' && isTabInterviewId && (
          <CandidateInterviewNotes
            candidateId={candidateId}
            interviewId={params.tabItemId}
          />
        )}
        {params.tab === 'attachments' && (
          <CandidateAttachments candidateId={candidateId} />
        )}
      </CandidateCardContainer>
    </>
  );
}

//endregion

//region Styles
const CandidateCardContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadow.card};
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 0;
  overflow: hidden;
`;

const DemoCandidateMark = styled.div`
  height: 24px;
  background-color: ${props => props.theme.colors.peach[100]};
  border-radius: 8px 8px 0 0;
  color: ${props => props.theme.colors.purple[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;

const Header = styled.div`
  position: sticky;
  top: -4px;
  z-index: 10;
  background: ${props => props.theme.colors.peach[50]};
  margin-bottom: 12px;
  border-radius: 8px;
`;

const HeaderTop = styled.div<{ isDemo: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  padding: 36px 36px 12px;
  border-radius: ${props => (props.isDemo ? '0' : '8px 8px 0px 0px')};
`;

const HeaderBottom = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 0 36px 16px;
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: flex-start;
`;

const NameRow = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 8px;
  }
`;
//endregion
