import React from 'react';

import { EmptyState } from './empty-state';
import noResultsIcon from './icons/no-results.svg';

export function NoResults() {
  return (
    <EmptyState>
      <img src={noResultsIcon} />
      <h2>No results for your search</h2>
      Try searching again or remove some of the filters.
    </EmptyState>
  );
}
