import React from 'react';
import styled from '@emotion/styled';

import Tooltip from '@/components/tooltip/Tooltip';
import { CandidateTestStateTag } from '@/features/candidate';
import { TId } from '@/features/common';
import { IWorkspaceCandidateTest } from '@/features/workspace';

interface CandidateScoresProps {
  disabled: boolean;
  candidateId: TId;
  scores: IWorkspaceCandidateTest[];
}

export function CandidateScores({
  disabled = false,
  candidateId,
  scores,
}: CandidateScoresProps) {
  return (
    <CandidateTestsWrapper>
      <CandidateScoresWrapper>
        {scores.map(score => {
          const dataHintKey = `data-candidate-test-${candidateId}-${score.id}`;
          const props = {
            [dataHintKey]: !disabled ? true : undefined,
          };
          return (
            <CandidateTestStateTag
              {...props}
              key={score.id}
              score={score.overallScoreAsPercents}
              scoreToPass={score.percentToPass}
              testState={score.state}
            />
          );
        })}
      </CandidateScoresWrapper>
      {/* Need to have a separate loop to make the tooltip work. Don't know why */}
      {scores.map(score => {
        const dataHintKey = `data-candidate-test-${candidateId}-${score.id}`;
        return (
          <Tooltip
            key={score.id}
            attribute={dataHintKey}
            onRenderContent={() => {
              return (
                <CandidateTestListWrapper>
                  <span>{score.testName}</span>
                </CandidateTestListWrapper>
              );
            }}
          />
        );
      })}
    </CandidateTestsWrapper>
  );
}

const CandidateTestsWrapper = styled.div`
  position: relative;
`;
const CandidateScoresWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;

  & > :not(:first-of-type) {
    margin-left: 4px;
  }
`;
const CandidateTestListWrapper = styled.div`
  padding: 12px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  color: ${props => props.theme.colors.purple[100]};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
  max-height: 200px;
`;
