import styled from '@emotion/styled';

export const TextButton = styled.button`
  -webkit-appearance: none;
  align-items: center;
  background: none;
  border-radius: 8px;
  border: none;
  color: ${({ theme }) => theme.colors.orange[100]};
  cursor: pointer;
  display: inline-flex;
  font-size: 10px;
  font-weight: 500;
  justify-content: center;
  line-height: 15px;
  outline: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.1s, transform 0.1s;
  white-space: nowrap;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:active {
    transform: scale(0.96);
  }

  &:hover,
  &:disabled {
    background: ${({ theme }) => theme.hover.onWhite};
  }
`;
