import React, { useMemo } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { TButtonVariant, useButtonVariant } from '@/features/dashboard/utils';
import { useWorkspacePermission } from '@/features/permissions';
import { useShareModal } from '@/features/share';
import { useWorkspaceStatsQuery } from '@/features/workspace';
import { usePlanLimits } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';

interface AddCandidateButtonProps {
  variant?: TButtonVariant;
}

export function AddCandidateButton({ variant }: AddCandidateButtonProps) {
  const workspaceId = useWorkspaceId();
  const buttonVariant = useButtonVariant('primary');
  const canAddCandidate = useWorkspacePermission()('candidates', 'update');
  const canAccessFeature = usePlanLimits();
  const { openUpsellModal } = useUpsellModal();
  const workspaceStatsQuery = useWorkspaceStatsQuery({ workspaceId });
  const { openShareModal } = useShareModal();

  const workspaceStats = useMemo(() => {
    if (workspaceStatsQuery.isSuccess) {
      return workspaceStatsQuery.data;
    }
    return null;
  }, [workspaceStatsQuery.data, workspaceStatsQuery.isSuccess]);

  const addableTestTakers =
    workspaceStats?.addableUnlockedTestTakersRemaining ?? 0;

  return (
    <DSButton
      variant={variant || buttonVariant}
      Icon={<Icon icon={regular('plus')} />}
      onClick={() => {
        if (canAccessFeature('add_candidate') || addableTestTakers > 0) {
          openShareModal('');
        } else {
          openUpsellModal('addable_candidates_limit_reached');
        }
      }}
      disabled={!canAddCandidate}
      data-rh={
        !canAddCandidate
          ? 'Access restricted. Contact workspace admin to change your user rights.'
          : null
      }
      data-rh-at="left"
    >
      Add candidate
    </DSButton>
  );
}
