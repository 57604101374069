import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { getSelectorFromTarget } from './getSelectorFromTarget';

interface Options {
  limit: number;
  interval: number;
}

//based on: https://help.piwik.pro/support/collecting-data/rage-clicks-error-clicks-dead-clicks-and-mouse-shakes/
export const useDetectDeadClicks = (
  onRageClick: (target) => void,
  options: Options
) => {
  const [clickCounts, setClicksCount] = useState<{ [key: string]: number }>({});
  const { interval, limit } = options;

  useInterval(() => {
    return setClicksCount({});
  }, interval);

  const listener = useCallback(
    event => {
      var selector: string = getSelectorFromTarget(event.target);
      setClicksCount(clickCounts => {
        return {
          ...clickCounts,
          [selector]: clickCounts[selector] ? clickCounts[selector] + 1 : 1,
        };
      });
      if (clickCounts[selector] === limit) {
        onRageClick(getSelectorFromTarget(event.target));
        setClicksCount({});
      }
    },
    [clickCounts, limit, onRageClick, setClicksCount]
  );
  useEffect(() => {
    document.addEventListener('click', listener);
    return () => document.removeEventListener('click', listener);
  }, [listener]);
};
