import axios from 'axios';

import { getGoogleReCaptchaToken } from '@/features/google-recaptcha';

import configuration from '../configuration/runtime';
import { ReCaptchaError } from '../errors';

const api = axios.create({
  baseURL: configuration.apiUrl + '/api/v1',
  withCredentials: configuration.sharedAuthEnabled ? true : false,
});

const config: { authToken: string | null } = {
  authToken: null,
};

api.interceptors.request.use(request => {
  if (config.authToken) {
    request.headers = {
      Authorization: `Bearer ${config.authToken}`,
      ...request.headers,
    };
  }

  return request;
});

api.interceptors.request.use(async request => {
  if (request.captchaAction) {
    try {
      const token = await getGoogleReCaptchaToken(request.captchaAction);
      request.headers = {
        'captcha-token': token,
        ...request.headers,
      };
    } catch (error) {
      throw new ReCaptchaError(error);
    }
  }

  return request;
});

export default api;

export function setAuthToken(authToken: string) {
  config.authToken = authToken;
}

export function deleteAuthToken() {
  config.authToken = null;
}
