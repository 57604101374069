import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useCandidateSearchParams } from '@/features/candidate-search/hooks';
import { Icon, StarRating } from '@/features/common';

import { DeleteButton } from '../../buttons';
import { STARS_FILTER_TYPE } from '../../stars-filter/stars-filter';
import { FilterListItem } from '../filter-list-item';

export const StarsListItem = () => {
  const { searchParams, addSearchParams } = useCandidateSearchParams();
  const { stars } = searchParams;

  const removeTag = React.useCallback(
    (star: string) => {
      const newStars = stars?.filter(s => s !== star);

      addSearchParams({ [STARS_FILTER_TYPE]: newStars });
    },
    [addSearchParams, stars]
  );

  if (!stars) return null;

  return (
    <>
      {stars.map(star => (
        <FilterListItem key={star}>
          <Icon icon={solid('star')} color="orange-100" />
          <StarRating
            color="purple-60"
            colorMode="dark"
            size="small"
            value={Number(star)}
            first={1}
            last={5}
            readOnly
          />
          <DeleteButton onClick={() => removeTag(star)} />
        </FilterListItem>
      ))}
    </>
  );
};
