import styled from '@emotion/styled';

export const FieldRow = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr 100px;
  gap: clamp(16px, 5%, 32px);
  padding: 12px 16px;
  min-height: 48px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.typography.colorPrimary};
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.hover.onWhite};
  }

  &:last-of-type {
    border-bottom: none;
  }
`;
