import React from 'react';

export const PositiveIcon = props => (
  <svg width="38" height="38" viewBox="0 0 38 38" fill="none" {...props}>
    <circle cx="19" cy="19" r="19" fill="#1DC488" />
    <path
      d="M29.8894 16C30.7997 16 31.5 16.6863 31.5 17.5784C31.5 18.4706 30.7997 19.1569 29.8894 19.1569C28.979 19.1569 28.2787 18.4706 28.2787 17.5784C28.2087 16.6863 28.979 16 29.8894 16ZM6.5 17.5784C6.5 18.4706 7.20029 19.1569 8.11066 19.1569C9.02102 19.1569 9.72129 18.4706 9.72129 17.5784C9.72129 16.6863 9.02102 16 8.11066 16C7.27032 16 6.5 16.6863 6.5 17.5784ZM14.8333 19.1569C15.1135 21.2157 16.7941 23 19.035 23C21.2759 23 22.9566 21.2157 23.1667 19.1569C23.2367 18.5392 22.2563 18.5392 22.1863 19.1569C21.6961 22.7941 16.374 22.8627 15.8838 19.1569C15.7437 18.5392 14.7633 18.5392 14.8333 19.1569Z"
      fill="#1A1A1A"
    />
  </svg>
);
