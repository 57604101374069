import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

import { TId } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { planLevels, premiumPlans } from '@/hooks/planLimits';

import { useAddTestToPipelineModalContext } from '../../hooks';
import {
  useCreateTestFromTemplateMutation,
  useTemplateQuery,
} from '../../queries';

import { CTA } from './cta';
import { SampleQuestions } from './sample-questions';
import { TestInfo } from './test-info';
import { logCTAClick } from './test-library-details.analytics';
import { Content, Footer, Header, Methodology, PremiumBanner } from './ui';

/**
 * Displays detailed information on a given template.
 * If `jobOpeningId` and `categoryId` are present in the URL, main CTA will
 * create a new test based on that template in that JO, otherwise it creates
 * a new JO.
 */
export function TestLibraryDetails() {
  const {
    jobOpeningId,
    categoryId,
    templateId,
    workspace: workspaceId,
  } = useParams<{
    jobOpeningId?: TId;
    categoryId?: TId;
    templateId: TId;
    workspace: TId;
  }>();
  const history = useHistory();
  const meQuery = useMeQuery();
  const templateQuery = useTemplateQuery({ id: templateId });
  const {
    open: openAddTestToPipelineModal,
  } = useAddTestToPipelineModalContext();

  const createTestFromTemplateMutation = useCreateTestFromTemplateMutation();

  const isUserOnPremiumPlan = useMemo(() => {
    const workspace = meQuery.data?.workspaces?.find(
      workspace => workspace.id === workspaceId
    );

    if (!workspace) {
      return false;
    }

    return premiumPlans.includes(planLevels[workspace?.subscriptionPlan]);
  }, [meQuery.data?.workspaces, workspaceId]);

  const handleCTA = useCallback(
    async (source: 'header' | 'footer') => {
      if (!templateQuery.isSuccess) {
        return;
      }

      logCTAClick(templateQuery.data.testTemplate.name, source);

      const testAttributes = {
        templateId: templateQuery.data.testTemplate.id,
        experienceLevel: templateQuery.data.testTemplate.experienceLevel,
      };

      if (jobOpeningId) {
        // create test in existing job opening
        const test = await createTestFromTemplateMutation.mutateAsync({
          jobOpeningId,
          attributes: {
            pipelineStageId: categoryId,
            ...testAttributes,
          },
        });

        history.push(
          `/admin/${workspaceId}/openings/${jobOpeningId}/test/${test.id}/questions`
        );
      } else {
        openAddTestToPipelineModal(async (jobOpeningId, pipelineCategoryId) => {
          const test = await createTestFromTemplateMutation.mutateAsync({
            jobOpeningId,
            attributes: {
              pipelineStageId: pipelineCategoryId,
              ...testAttributes,
            },
          });

          history.push(
            `/admin/${workspaceId}/openings/${jobOpeningId}/test/${test.id}/questions`
          );
        });
      }
    },
    [
      categoryId,
      createTestFromTemplateMutation,
      history,
      jobOpeningId,
      templateQuery.data,
      templateQuery.isSuccess,
      workspaceId,
      openAddTestToPipelineModal,
    ]
  );

  if (!templateQuery.isSuccess) {
    return null;
  }

  return (
    <article>
      <Header title={templateQuery.data.testTemplate.name}>
        <CTA onClick={() => handleCTA('header')} />
      </Header>
      <Content>
        {templateQuery.data.testTemplate.isPremium && !isUserOnPremiumPlan ? (
          <PremiumBanner />
        ) : null}
        <TestInfo template={templateQuery.data} />
        <SampleQuestions
          questions={templateQuery.data.testPreview?.questions}
        />
        <Methodology />
        <Footer title={templateQuery.data.testTemplate.name}>
          <CTA onClick={() => handleCTA('footer')} />
        </Footer>
      </Content>
    </article>
  );
}
