import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import { capitalize } from 'lodash';

import { Icon } from '@/features/common';
import {
  useWorkspaceQuery,
  useWorkspaceStatsQuery,
} from '@/features/workspace';
import { getPlanName } from '@/hooks/planLimits';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { UnlockCandidatesUpsellIcon } from './icons';

export function CandidatesLimitUpsellBanner() {
  const [isOpened, setIsOpened] = React.useState(true);
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const workspaceStatsQuery = useWorkspaceStatsQuery({ workspaceId });

  if (
    !workspaceQuery.data ||
    workspaceStatsQuery.data?.unlockableTestTakersRemaining !== 0
  ) {
    return null;
  }
  return isOpened ? (
    <Container>
      <CloseButton>
        <DSButton
          size="xsmall"
          variant="tertiary"
          Icon={<Icon icon={regular('xmark')} color="purple-60" />}
          onClick={() => {
            amplitude?.logEvent('upsell/bottom banner/close', {
              'workspace id': workspaceId,
            });
            setIsOpened(false);
          }}
        />
      </CloseButton>
      <div>
        <UnlockCandidatesUpsellIcon />
      </div>
      <Text>
        <h3>{`You’ve hit the limit for candidates on the ${capitalize(
          getPlanName(workspaceQuery.data.subscriptionPlan)
        )} plan.`}</h3>
        <p>
          You're on a roll! Upgrade to unlock more candidates and access
          advanced features.
        </p>
      </Text>
      <Buttons>
        <DSButton
          variant="secondary"
          onClick={() => {
            amplitude?.logEvent('upsell/bottom banner/contact', {
              'workspace id': workspaceId,
            });

            window
              .open('https://meetings.hubspot.com/rumyana-peneva', '_blank')
              ?.focus();
          }}
        >
          Speak to us
        </DSButton>
        <DSButton
          onClick={() => {
            amplitude?.logEvent('upsell/bottom banner/upgrade', {
              'workspace id': workspaceId,
            });

            history.push(`/admin/${workspaceId}/settings/billing`);
          }}
        >
          Upgrade
        </DSButton>
      </Buttons>
    </Container>
  ) : null;
}

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  background: ${({ theme }) => theme.colors.white};
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: ${({ theme }) => theme.shadow.card};
  border-radius: 8px;
  gap: 36px;
  padding: 36px 20px;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.purple[60]};
    margin: 0;
    font-weight: 400;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;
