import { ICandidate } from '@/features/candidate';
import { Candidate } from '@/types/candidate';

export const getCandidateServiceName = (
  candidate: Candidate
): string | null => {
  const match = /^https?:\/\/([a-z.]+)/.exec(candidate.url);

  if (match) {
    const [, domain] = match;

    switch (domain) {
      case 'github.com':
        return 'GitHub';
      case 'linkedin.com':
      case 'www.linkedin.com':
        return 'LinkedIn';
      default:
        return null;
    }
  } else {
    return null;
  }
};

const getCandidateFullNameOrNull = (
  candidate: Candidate | ICandidate
): string | null => {
  if ('contact' in candidate) {
    if (
      !candidate.contact?.firstName?.trim() &&
      !candidate.contact?.lastName?.trim()
    )
      return (
        candidate.contact?.fullName?.trim() || candidate.name.trim() || null
      );

    return `${candidate.contact?.firstName?.trim()} ${candidate.contact?.lastName?.trim()}`.trim();
  }

  if (
    !candidate.contactInfo?.firstName?.trim() &&
    !candidate.contactInfo?.lastName?.trim()
  )
    return (
      candidate.contactInfo?.fullName?.trim() || candidate.name.trim() || null
    );

  return `${candidate.contactInfo?.firstName?.trim()} ${candidate.contactInfo?.lastName?.trim()}`.trim();
};

export const getCandidateName = (candidate: Candidate | ICandidate): string => {
  return getCandidateFullNameOrNull(candidate) || '(no name)';
};

export const getCandidateNameOrEmail = (candidate: Candidate): string => {
  return getCandidateFullNameOrNull(candidate) || getCandidateEmail(candidate);
};

export const getCandidateFirstName = (candidate: Candidate): string => {
  const firstName = candidate.contact && candidate.contact.firstName.trim();
  return firstName || getCandidateProvidedOriginalFirstName(candidate);
};

export const getCandidateProvidedOriginalFirstName = (
  candidate: Candidate
): string => {
  const [firstName] = splitCandidateFullName(candidate);
  return firstName;
};

export const getCandidateLastName = (candidate: Candidate): string => {
  const lastName = candidate.contact && candidate.contact.lastName?.trim();
  return lastName || getCandidateProvidedOriginalLastName(candidate);
};

export const getCandidateProvidedOriginalLastName = (
  candidate: Candidate
): string => {
  const [, lastName] = splitCandidateFullName(candidate);
  return lastName;
};

export const getCandidateEmail = (
  candidate: Candidate | ICandidate | null
): string => {
  if (!candidate) {
    return '';
  }

  if ('contact' in candidate) {
    return candidate.contact
      ? candidate.contact.email?.trim() || candidate.email.trim() || ''
      : candidate.email?.trim() || '';
  }

  return candidate.contactInfo
    ? candidate.contactInfo.contactEmail?.trim() || candidate.email.trim() || ''
    : candidate.email?.trim() || '';
};

const splitCandidateFullName = (candidate: Candidate): [string, string] => {
  const fullName = candidate.contact?.fullName || candidate.name;
  const [firstName, lastName] = fullName.split(/\s+/, 2);
  return [firstName || '', lastName || ''];
};

export const getCandidateLinkedinUrl = (linkedinUrl: string): string | null => {
  const base = 'https://linkedin.com';

  try {
    if (/^https:\/\/([a-z]{2,3}\.)?linkedin\.com/.test(linkedinUrl)) {
      const url = new URL(linkedinUrl);
      return base + url.pathname;
    }

    if (/^([a-z]{2,3}\.)?linkedin\.com/.test(linkedinUrl)) {
      const url = new URL('https://' + linkedinUrl);
      return base + url.pathname;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getCandidateGithubUrl = (githubUrl: string): string | null => {
  const base = 'https://github.com';

  try {
    if (/^https:\/\/([a-z]{2,3}\.)?github\.com/.test(githubUrl)) {
      const url = new URL(githubUrl);
      return base + url.pathname;
    }

    if (/^([a-z]{2,3}\.)?github\.com/.test(githubUrl)) {
      const url = new URL('https://' + githubUrl);
      return base + url.pathname;
    }

    return null;
  } catch (error) {
    return null;
  }
};
