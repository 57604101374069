import * as React from 'react';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import { useMedia } from 'react-use';

import './Tooltip.css';

const ReactHint = ReactHintFactory(React);

type Props = {
  delay?: { show?: number; hide?: number } | number;
  attribute?: string;
  persist?: boolean;
  onRenderContent?: (target: any, content: string) => React.ReactNode;
};

const Tooltip = ({
  delay = 100,
  attribute,
  persist,
  onRenderContent,
}: Props) => {
  const isMobile = useMedia('(max-width: 768px)');

  return !isMobile ? (
    <ReactHint
      events
      autoPosition
      delay={delay}
      attribute={attribute}
      persist={persist}
      onRenderContent={
        onRenderContent
          ? onRenderContent
          : (target, content) => {
              return (
                <div className="react-hint__content">
                  {target.dataset.title ? (
                    <div className="react-hint__content__title">
                      {target.dataset.title}
                    </div>
                  ) : null}
                  <div className="react-hint__content__text">{content}</div>
                </div>
              );
            }
      }
    />
  ) : null;
};

export default Tooltip;
