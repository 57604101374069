import React from 'react';
import { DSApplicationPage, DSCheckbox } from '@hundred5/design-system';
import { useFormikContext } from 'formik';

import { TJobOpeningApplicationFormFields } from '@/features/job-opening';

import { FieldRow, FieldRowMenu } from '.';

export function ApplicationFields() {
  const form = useFormikContext<TJobOpeningApplicationFormFields>();

  return (
    <DSApplicationPage.Section title="Application">
      <div>
        {/* APPLICATION FIELDS */}
        <FieldRow>
          <DSCheckbox
            checked={form.values.cvEnabled}
            onChange={event => {
              form.setFieldValue('cvEnabled', event.target.checked);
            }}
          />
          CV / Resume
          <FieldRowMenu
            canEdit
            isMandatory={form.values.cvRequired}
            onEdit={newValue => {
              form.setFieldValue('cvRequired', newValue);
            }}
          />
        </FieldRow>

        <FieldRow>
          <DSCheckbox
            checked={form.values.coverLetterEnabled}
            onChange={event => {
              form.setFieldValue('coverLetterEnabled', event.target.checked);
            }}
          />
          Cover letter
          <FieldRowMenu
            canEdit
            isMandatory={form.values.coverLetterRequired}
            onEdit={newValue => {
              form.setFieldValue('coverLetterRequired', newValue);
            }}
          />
        </FieldRow>

        <FieldRow>
          <DSCheckbox
            checked={form.values.portfolioEnabled}
            onChange={event => {
              form.setFieldValue('portfolioEnabled', event.target.checked);
            }}
          />
          Portfolio
          <FieldRowMenu
            canEdit
            isMandatory={form.values.portfolioRequired}
            onEdit={newValue => {
              form.setFieldValue('portfolioRequired', newValue);
            }}
          />
        </FieldRow>
      </div>
    </DSApplicationPage.Section>
  );
}
