import styled from '@emotion/styled';

export const TagsList = styled.ul`
  align-items: center;
  display: flex;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 16px;
  overflow: hidden;
  flex-wrap: wrap;
`;
