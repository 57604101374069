import { useCallback } from 'react';
import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { fetchPreferences, updatePreference } from '../api';

export const PREFERENCES_KEY = ['preferences'];

export const usePreferencesQuery = (options?: UseQueryOptions) =>
  useQuery(PREFERENCES_KEY, fetchPreferences, options as any);

export const usePrefetchPreferences = () => {
  const queryClient = useQueryClient();
  return useCallback(async () => {
    await queryClient.prefetchQuery(PREFERENCES_KEY, fetchPreferences);
  }, [queryClient]);
};

export const usePreferenceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePreference, {
    onSuccess: () => queryClient.invalidateQueries(PREFERENCES_KEY),
  });
};
