import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSContentBlock,
  DSField,
  DSGrid,
  DSInput,
} from '@hundred5/design-system';

import { ConfirmModal, Icon, useClipboard } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import {
  useDeleteZapierApiKeyMutation,
  useGenerateZapierApiKeyMutation,
  useZapierApiKeysQuery,
  ZapierIcon,
} from '@/features/integrations';
import { isDemoWorkspace, useWorkspace } from '@/features/workspace';
import { usePlanLimits } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';

import {
  WorkspaceSettingsIntegrationAction,
  WorkspaceSettingsIntegrationContainer,
  WorkspaceSettingsIntegrationDescription,
  WorkspaceSettingsIntegrationIcon,
  WorkspaceSettingsIntegrationLink,
} from '../workspace-settings-integrations-page';

export const WorkspaceSettingsIntegrationZapier = () => {
  return (
    <DSContentBlock>
      <DSContentBlock.Title>Zapier Integration</DSContentBlock.Title>
      <DSContentBlock.Content>
        <DSGrid gap="16px">
          <ZapierElement />
        </DSGrid>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const ZapierElement = () => {
  const workspace = useWorkspace();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { showFlashMessage } = useFlashMessages();

  const { copyButtonRef, valueRef } = useClipboard();
  const workspaceId = useWorkspaceId();
  const { data: zapierApiKeys } = useZapierApiKeysQuery({
    enabled: !isDemoWorkspace(workspace),
  });
  const apiKey = zapierApiKeys?.[workspaceId] || '';
  valueRef.current = apiKey;

  const {
    mutate: generateKey,
    isLoading: generating,
  } = useGenerateZapierApiKeyMutation();

  const {
    mutate: deleteKey,
    isLoading: deleting,
  } = useDeleteZapierApiKeyMutation();

  const canAccessFeature = usePlanLimits();
  const { openUpsellModal } = useUpsellModal();

  return (
    <>
      <WorkspaceSettingsIntegrationContainer>
        <WorkspaceSettingsIntegrationIcon>
          <ZapierIcon />
        </WorkspaceSettingsIntegrationIcon>
        <IntegrationInfo>
          <WorkspaceSettingsIntegrationDescription>
            Send candidate profiles to many other services supported by Zapier.
          </WorkspaceSettingsIntegrationDescription>
          <WorkspaceSettingsIntegrationLink
            href="https://support.hire.toggl.com/en/articles/4359309-zapier-integration"
            target="_blank"
          >
            Learn More
          </WorkspaceSettingsIntegrationLink>
        </IntegrationInfo>
        <WorkspaceSettingsIntegrationAction>
          <DSButton
            variant="secondary"
            size="big"
            disabled={generating || deleting}
            onClick={() => {
              canAccessFeature('zapier_integration')
                ? generateKey()
                : openUpsellModal('premium_feature');
            }}
          >
            {apiKey ? 'Regenerate API key' : 'Generate API key'}
          </DSButton>
        </WorkspaceSettingsIntegrationAction>
      </WorkspaceSettingsIntegrationContainer>
      {apiKey && (
        <ContainerKey>
          <StyledDSField label="API KEY" for="key">
            <DSInput value={apiKey} />
          </StyledDSField>
          <ButtonContent>
            <DSButton
              ref={copyButtonRef}
              variant="secondary"
              onClick={e => {
                e.stopPropagation();
                showFlashMessage({ type: 'link_copied' });
              }}
            >
              Copy
            </DSButton>
            <DSButton
              variant="secondary"
              Icon={<Icon icon={regular('trash-alt')} />}
              disabled={deleting}
              onClick={() => setOpenConfirmModal(true)}
            />
          </ButtonContent>
        </ContainerKey>
      )}
      <ConfirmModal
        open={openConfirmModal}
        action="Delete API key"
        onConfirm={() => {
          deleteKey();
          setOpenConfirmModal(false);
        }}
        onClose={() => setOpenConfirmModal(false)}
        title="Delete Zapier API key"
        footerVariant="destructive-primary"
      >
        Are you sure you want to delete this API key?
        <p>
          If you delete this API key you will not be able to sync with Zapier
          and your Zaps will no longer function.
        </p>
      </ConfirmModal>
    </>
  );
};

const IntegrationInfo = styled.div``;

const ContainerKey = styled.div`
  display: grid;
  grid-template-areas: 'input  buttons';
  grid-auto-columns: 8fr 1fr;
  gap: 8px;
  align-items: flex-end;
`;

const StyledDSField = styled(DSField)`
  grid-area: input;
`;

const ButtonContent = styled.div`
  grid-area: buttons;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
