import React from 'react';
import styled from '@emotion/styled';

export const Avatar = () => {
  const initials = 'ID';

  return <AvatarContainer>{initials}</AvatarContainer>;
};

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86px;
  width: 86px;
  border-radius: 10px;
  font-size: 28px;
  color: #ffffff;
  text-transform: uppercase;
  background: ${({ theme }) => theme.colors.purple[100]};
`;
