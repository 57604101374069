import React from 'react';

import { EmptyState } from './empty-state';
import emptyStateIcon from './icons/empty-state.svg';

export function EmptyList() {
  return (
    <EmptyState>
      <img src={emptyStateIcon} />
      <h2>No candidates (yet!)</h2>
      Any candidates you invite to your job openings or tests will be displayed
      here.
    </EmptyState>
  );
}
