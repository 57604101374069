import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { loggedOut } from '@/actions/authentication';
import { deleteAuthToken } from '@/api/api';
import { clearAuthToken } from '@/authentication';
import {
  getTogglAccountsLoginUrl,
  getSharedAuthEnabled,
} from '@/features/toggl-accounts';
import { useHistory } from '@/hooks/router';

import { useLogoutMutation } from '../queries';

interface IUseLogoutParams {
  returnTo?: string;
}
export const useLogout = ({ returnTo }: IUseLogoutParams = {}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: logout } = useLogoutMutation();
  const sharedAuthEnabled = getSharedAuthEnabled();
  const sharedAuthLoginPageUrl = getTogglAccountsLoginUrl(returnTo);
  const history = useHistory();

  const onLogout = useCallback(() => {
    logout(
      {},
      {
        onSettled: () => {
          window.location.assign(sharedAuthLoginPageUrl);
        },
      }
    );
  }, [logout, sharedAuthLoginPageUrl]);

  const legacyOnLogout = useCallback(() => {
    clearAuthToken();
    deleteAuthToken();

    dispatch(loggedOut());
    history.push('/admin');
    queryClient.clear();
  }, [dispatch, history, queryClient]);

  return sharedAuthEnabled ? onLogout : legacyOnLogout;
};
