import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { CandidateAnswer } from '@hundred5/design-system';

import Markdown from '@/components/markdown/Markdown';
import {
  getCandidateAnswerScoreStatus,
  getQuestionGroup,
  TCandidateAnswerScoreStatus,
  TQuestionGroup,
  useCandidateQuery,
  useCandidateSuspiciousActivityQuery,
} from '@/features/candidate';
import { TId, withDisabledCopy } from '@/features/common';
import { Badge } from '@/features/common/components/badge';
import { useSkillsQuery } from '@/features/skills';
import { ITestQuestion, useTestQuery } from '@/features/test';

import { useOpenEndedEvaluationVariants } from '../../hooks/open-ended-questions';

import { formatPoints, getTitle } from './candidate-answers.utils';
import {
  AnswerAttachments,
  AnswerNotes,
  ChoiceAnswer,
  CodeAnswer,
  EvaluationButtons,
  HowToEvaluate,
  NumericAnswer,
  QuestionGroupHeader,
  TextAnswer,
  VideoAnswer,
} from './ui';

export interface CandidateAnswersProps {
  candidateId: TId;
  testId: TId;
}

const ChoiceAnswerWithDisabledCopy = withDisabledCopy(ChoiceAnswer);
const MarkdownWithDisabledCopy = withDisabledCopy(Markdown);

export function CandidateAnswers(props: CandidateAnswersProps) {
  const { data: candidate } = useCandidateQuery(props);
  const candidateTest = useMemo(
    () =>
      candidate?.tests.find(test => test.testId === props.testId) || undefined,
    [candidate?.tests, props.testId]
  );
  const testQuery = useTestQuery({ testId: candidateTest?.testId });
  const { data: skills } = useSkillsQuery({});
  const { data: suspiciousActivity } = useCandidateSuspiciousActivityQuery(
    props
  );

  const evaluationOptions = useOpenEndedEvaluationVariants(testQuery.data);

  if (!candidateTest) return null;

  const questionGroups: TQuestionGroup[] = candidateTest.isSmart
    ? ['smart', 'static', 'code-input', 'free-text']
    : ['all'];

  let questionNumber = 0;

  return (
    <CandidateAnswersContainer>
      {questionGroups.map((group, groupIndex) => {
        const questions = candidateTest.questions?.filter(
          question => group === 'all' || getQuestionGroup(question) === group
        );

        return questions?.length ? (
          <React.Fragment key={groupIndex}>
            <QuestionGroupHeader group={group} />

            {questions.map((question: ITestQuestion) => {
              const answer = candidateTest.answers?.find(
                answer => answer.questionId === question.id
              );
              const scoreStatus = getCandidateAnswerScoreStatus(
                question,
                answer,
                evaluationOptions
              );
              const score =
                scoreStatus !== 'manual'
                  ? formatPoints(answer?.scoreAsPoints || 0)
                  : null;

              const copyDetected = !!suspiciousActivity?.copyEvents?.find(
                event => event.questionId === question.id
              );
              const pasteDetected = !!suspiciousActivity?.pasteEvents?.find(
                event => event.questionId === question.id
              );

              const skill = skills?.find(
                skill => skill.id === question.testGenSkillId
              );

              questionNumber++;

              const showRating = [
                'free-text',
                'code-input',
                'video-question',
              ].includes(question.questionType);

              return (
                <AnswerWrapper
                  key={questionNumber}
                  id={`question-${question.id}`}
                  scoreStatus={scoreStatus}
                  data-evaluation-needed={scoreStatus === 'manual'}
                  data-skill-id={String(question.testGenSkillId)}
                  evaluationLastSection={!showRating && !!question.notes}
                >
                  <CandidateAnswer
                    number={questionNumber}
                    variant={scoreStatus}
                    score={score}
                    title={
                      <Title>
                        {getTitle(question)}

                        {skill && <SkillBadge>{skill.name}</SkillBadge>}

                        {copyDetected && (
                          <Badge
                            color="red"
                            tooltip="Candidate copied this question"
                            ellipsis
                          >
                            Copy
                          </Badge>
                        )}

                        {pasteDetected && (
                          <Badge
                            color="red"
                            tooltip="Candidate used the paste function to answer this question"
                            ellipsis
                          >
                            Paste
                          </Badge>
                        )}
                      </Title>
                    }
                    footer={
                      answer &&
                      question.points !== 0 &&
                      ((/free-text|code-input/.test(question.questionType) &&
                        answer.freeFormAnswer) ||
                        (question.questionType === 'video-question' &&
                          answer.recordingUrl)) && (
                        <EvaluationButtons
                          candidateId={props.candidateId}
                          testId={props.testId}
                          questionId={question.id}
                          answerId={answer.id}
                          scoreAsPercents={answer.scoreAsPercents}
                          scoreStatus={scoreStatus}
                          questionNumber={questionNumber}
                          freeFormAnswer={answer.freeFormAnswer}
                        />
                      )
                    }
                  >
                    <CandidateAnswer.Description>
                      <MarkdownWithDisabledCopy source={question.description} />
                    </CandidateAnswer.Description>
                    {(() => {
                      switch (question.questionType) {
                        case 'free-text':
                          return <TextAnswer answer={answer} />;
                        case 'numeric-input':
                          return <NumericAnswer answer={answer} />;
                        case 'code-input':
                          return <CodeAnswer answer={answer} />;
                        case 'video-question':
                          return (
                            <VideoAnswer
                              recordingUrl={answer?.recordingUrl ?? undefined}
                              isRecordingProcessed={
                                answer?.isRecordingProcessed ?? undefined
                              }
                              locked={!candidate?.isUnlocked}
                            />
                          );
                        case 'single-choice':
                        case 'multiple-choice':
                        case 'picture-question':
                          return (
                            <ChoiceAnswerWithDisabledCopy
                              question={question}
                              answer={answer}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                    <AnswerAttachments attachments={answer?.attachments} />
                    <HowToEvaluate notes={question.notes} />
                  </CandidateAnswer>
                  {showRating ? (
                    <AnswerNotes
                      candidateId={candidate?.id}
                      answerId={answer?.id}
                      notes={answer?.notes}
                    />
                  ) : null}
                </AnswerWrapper>
              );
            })}
          </React.Fragment>
        ) : null;
      })}
    </CandidateAnswersContainer>
  );
}

const CandidateAnswersContainer = styled.div`
  padding: 24px 36px 36px;
`;

const AnswerWrapper = styled.div<{
  scoreStatus: TCandidateAnswerScoreStatus;
  evaluationLastSection: boolean;
}>`
  border: 1px solid ${props => props.theme.colors.purple[10]};
  border-radius: 8px;
  margin-bottom: 12px;
  overflow: hidden;

  & > div {
    padding: ${({ evaluationLastSection }) =>
      evaluationLastSection ? '0 24px' : '0 24px 12px'};
    background: initial;
    border-top: 4px solid;
    border-top-color: ${props => {
      switch (props.scoreStatus) {
        case 'correct':
        case 'excellent':
        case 'good':
          return props.theme.colors.green[100];
        case 'wrong':
        case 'weak':
        case 'incorrect':
          return props.theme.colors.red[100];
        case 'partial':
        case 'average':
          return props.theme.colors.yellow[100];
        case 'missing':
        case 'manual':
        default:
          return props.theme.colors.purple[10];
      }
    }};
  }
`;

const Title = styled.div`
  display: inline-flex;
  align-items: center;

  & > div {
    margin-left: 10px;
  }
`;

const SkillBadge = styled.div`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.purple[10]};
  font-size: 10px;
  font-weight: 500;
  padding: 2px 6px;
  border-radius: 6px;
`;
