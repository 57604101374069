import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Action } from '../actions';

import { useApiError } from './api';

interface AsyncResult<T> {
  loading: boolean;
  data: T | undefined;
  error: unknown;
}

type AsyncHook<Args extends any[], Result> = [
  (...args: Args) => Promise<Result>,
  AsyncResult<Result>
];

export const useAsync = <Args extends any[], Result>(
  fn: (...args: Args) => Promise<Result>
): AsyncHook<Args, Result> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Result | undefined>(undefined);
  const [error, setError] = useState<unknown>(undefined);

  const execute = useCallback(
    async (...args: Args): Promise<Result> => {
      setLoading(true);
      setError(undefined);

      let result: Result;

      try {
        result = await fn(...args);
      } catch (error) {
        setError(error);
        setLoading(false);
        throw error;
      }

      setData(result);
      setLoading(false);

      return result;
    },
    [fn, setLoading, setData, setError]
  );

  return [execute, { loading, data, error }];
};

export type Error = unknown;

type FetchAndDispatchHook<Args extends any[], Result> = [
  (...args: Args) => Promise<[Error, Result | undefined]>,
  boolean | undefined,
  [Error, Result | undefined]
];

/**
 * Super hook :) Covers most of the use cases where API calls are required. Basically useAsync,
 * but with a few extra options:
 * 1. `handleApiError` is called by default if API call throws an error.
 * 2. You can pass through optional `successActionCreator` method that will be dispatched after API
 * method finishes its execution.
 * @param apiFn
 * @param successActionCreator
 * @returns {Array} `[executeMethod, executingIndicator,  [error, data]]`
 */
export const useFetchAndDispatch = <Args extends any[], Result>(
  apiFn: (...args: Args) => Promise<Result>,
  successActionCreator?: ((data: Result) => Action) | null
): FetchAndDispatchHook<Args, Result> => {
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [data, setData] = useState<Result | undefined>(undefined);
  const [error, setError] = useState<Error>(undefined);
  const dispatch = useDispatch();
  const handleApiError = useApiError();

  const execute = useCallback(
    async (...args: Args): Promise<[Error, Result | undefined]> => {
      setLoading(true);
      setError(undefined);

      let result: Result | undefined = undefined;

      try {
        result = await apiFn(...args);
        setData(result);
        setLoading(false);

        if (successActionCreator) {
          dispatch(successActionCreator(result));
        }

        return [undefined, result];
      } catch (apiError) {
        setError(apiError);
        setLoading(false);
        handleApiError(apiError);

        return [apiError, undefined];
      }
    },
    [apiFn, successActionCreator, handleApiError, dispatch]
  );

  return [execute, loading, [error, data]];
};
