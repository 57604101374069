import React from 'react';
import { groupBy } from 'lodash';

import { ISkill } from '@/features/skills';
import { ITemplate } from '@/features/test-library/types';

import { EmptyState } from '../../empty-state';

import { TemplateType } from './template-type';

interface SearchResultsProps {
  templates: ITemplate[];
  skills: ISkill[] | undefined;
  createCustomTest: () => void;
  onTemplateSelected: (position?: ITemplate) => void;
}

export function SearchResults({
  templates,
  skills,
  createCustomTest,
  onTemplateSelected,
}: SearchResultsProps) {
  const templatesByType = groupBy(templates, 'positionType.name');
  const positionTypesArray = Object.keys(templatesByType).map(key => {
    if (!key) {
      return null;
    }
    return { name: key };
  });

  if (positionTypesArray.length === 0) {
    return <EmptyState createCustomTest={createCustomTest} />;
  }
  return (
    <>
      {positionTypesArray.map(
        positionType =>
          positionType && (
            <TemplateType
              key={positionType.name}
              templatesByType={templatesByType}
              templateTypeName={positionType.name}
              skills={skills}
              createCustomTest={createCustomTest}
              onTemplateSelected={onTemplateSelected}
            />
          )
      )}
    </>
  );
}
