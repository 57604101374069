import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Form, Formik } from 'formik';
import { isEmpty, pick } from 'lodash';

import { amplitude } from '@/features/amplitude';
import {
  TFilterType,
  useCandidateSearchParams,
} from '@/features/candidate-search';
import { ScoreFilterOptions } from '@/features/candidate-search/components/score-filter/ui/score-filter-options';
import { callAll, Icon, Popover } from '@/features/common';
import { useJobOpeningIdOrNull } from '@/hooks/router';

import { ApplyButton } from '../buttons';
import { FilterName } from '../candidate-filters/ui/filter-name';
import {
  FullWidthPopover,
  IconWrapper,
  SubContent,
  Trigger,
} from '../candidate-filters/ui/popover';

export const SCORE_FILTER_TYPE: TFilterType = 'score';
export const SCORE_OPTIONS = ['80', '60', '40'];

export const ScoreFilter = ({ onApply }) => {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();
  const jobOpeningId = useJobOpeningIdOrNull();

  return (
    <FullWidthPopover>
      <Popover.Trigger>
        {({ onClick }) => (
          <Trigger onClick={onClick}>
            <IconWrapper>
              <Icon icon={regular('circle-check')} color="purple-60" />
            </IconWrapper>
            Score
          </Trigger>
        )}
      </Popover.Trigger>
      <SubContent position="right">
        {({ close }) => (
          <>
            <FilterName>Score</FilterName>
            <Formik
              initialValues={pick(candidatesSearchParams, SCORE_FILTER_TYPE)}
              onSubmit={values => {
                if (isEmpty(values)) {
                  removeSearchParam(SCORE_FILTER_TYPE);
                } else {
                  addSearchParams(values);
                  amplitude?.logEvent('candidate filters/filter applied', {
                    'filter type': SCORE_FILTER_TYPE,
                    'filter value': values,
                    'filter origin': jobOpeningId
                      ? 'pipeline'
                      : 'all candidates',
                    'job opening id': jobOpeningId,
                  });
                }
                callAll(onApply, close);
              }}
            >
              <Form>
                <ScoreFilterOptions />
                <ApplyButton />
              </Form>
            </Formik>
          </>
        )}
      </SubContent>
    </FullWidthPopover>
  );
};
