import React from 'react';

import { InfoBox } from './info-box';

export function NoRecommendations() {
  return (
    <InfoBox
      heading="No matches, yet."
      body="We're searching the candidate pool for you. Come back later ✨"
    />
  );
}
