import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTheme } from '@emotion/react';

import { TId } from '@/features/common';

interface SortableProps {
  id: TId;
  children: React.ReactNode;
  disabled?: boolean;
}

export function Sortable({ id, children, disabled }: SortableProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: { type: 'pipeline-stage' },
    disabled,
  });
  const theme = useTheme();

  const draggableStyle = {
    transform: CSS.Translate.toString(transform),
    transition:
      transition + (!isDragging ? ', background-color 1000ms ease' : ''),
    backgroundColor: isDragging
      ? theme.colors.peach[50]
      : theme.colors.peach[20],
    borderRadius: '8px',
    zIndex: isDragging ? 11 : undefined,
  };
  return (
    <div ref={setNodeRef} style={draggableStyle} {...listeners} {...attributes}>
      {children}
    </div>
  );
}
