import React, { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import formatDate from 'date-fns/format';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import onClickOutside from 'react-onclickoutside';

import { useDateRange } from '@/hooks/time';
import { DateRange } from '@/types/time';

import { ArrowIcon } from '../job-opening-analytics-table';

type Layout = 'left' | 'right';

interface JobOpeningAnalyticsDateRangePickerProps {
  range: DateRange;
  onRangeChange: (range: DateRange) => void;
  publishedAt?: Date;
  closedAt?: Date;
  layout?: Layout;
}

const DateRangePicker: React.FC<JobOpeningAnalyticsDateRangePickerProps> & {
  handleClickOutside?: () => void;
} = ({ range, onRangeChange, publishedAt, closedAt, layout }) => {
  const [open, setOpen] = useState(false);

  const onRangeChangeCallback = useCallback(
    (range: DateRange) => {
      onRangeChange(range);
      setOpen(false);
    },
    [onRangeChange]
  );

  const { activeRange, ...dateRange } = useDateRange({
    initialRange: range,
    onRangeChange: onRangeChangeCallback,
  });

  const modifiers = {
    start: activeRange.from,
    end: activeRange.enteredTo,
    published: publishedAt,
    closed: closedAt,
  };

  DateRangePicker.handleClickOutside = () => setOpen(false);

  return (
    <DateRangePickerContainer open={open} layout={layout}>
      <SelectButton onClick={() => setOpen(open => !open)}>
        {formatDate(range.start, 'MMM D, YYYY')} -{' '}
        {formatDate(range.end, 'MMM D, YYYY')}
        <ArrowIcon
          icon={regular('chevron-up')}
          direction={open ? 'up' : 'down'}
          size="xs"
        />
      </SelectButton>
      <Picker>
        <DayPicker numberOfMonths={3} modifiers={modifiers} {...dateRange} />

        <Notifier>
          {!activeRange.from &&
            !activeRange.to &&
            'Please select the first day.'}
          {activeRange.from && !activeRange.to && 'Please select the last day.'}
        </Notifier>
      </Picker>
    </DateRangePickerContainer>
  );
};

const SelectButton = styled.div`
  width: 225px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.purple[20]};
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`;

const Picker = styled.div`
  background: ${props => props.theme.colors.purple[5]};
  display: none;
  position: absolute;
  left: 0;
  top: 36px;

  & .DayPicker-wrapper {
    outline: none;
  }

  & .DayPicker-NavBar {
    position: relative;
    top: 5px;
  }

  & .DayPicker-NavButton {
    outline: none;
  }

  & .DayPicker-NavButton--next.DayPicker-NavButton--interactionDisabled {
    opacity: 0.2;
    display: inline;
    cursor: default;
  }

  & .DayPicker-Caption {
    background: ${props => props.theme.colors.white};
  }

  & .DayPicker-Caption > div {
    font-size: 12px;
    color: ${props => props.theme.colors.github};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  &
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${props => props.theme.colors.orange[100]};
    color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.purple[5]};
  }

  &
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${props => props.theme.colors.orange[100]};
    color: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.purple[5]};
  }

  &
    .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: rgba(255, 137, 122, 0.01);
  }

  & .DayPicker-Weekday:nth-child(6),
  & .DayPicker-Weekday:nth-child(7) {
    color: ${props => props.theme.colors.orange[100]};
  }

  & .DayPicker-Weekday,
  & .DayPicker-Day {
    border-radius: 0;
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.github};
    font-size: 12px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    vertical-align: middle;
    text-align: center;
    border: 1px solid ${props => props.theme.colors.purple[5]};
    outline: none;
    position: relative;
  }

  & .DayPicker-Day--outside {
    opacity: 0.2;
  }

  & .DayPicker-Day.DayPicker-Day--disabled {
    color: ${props => props.theme.colors.purple[30]};
  }

  & .DayPicker-WeekdaysRow {
    border-bottom: 3px solid ${props => props.theme.colors.purple[5]};
  }

  & .DayPicker-Day--published::before,
  & .DayPicker-Day--closed::before {
    content: '';
    width: 5px;
    height: 5px;
    min-width: 5px;
    min-height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    right: 3px;
    border: 1px solid ${props => props.theme.colors.white};
  }

  & .DayPicker-Day--published::before {
    background: ${props => props.theme.colors.green[100]};
  }

  & .DayPicker-Day--closed::before {
    background: ${props => props.theme.colors.purple[30]};
  }

  & .DayPicker-Day--published::after,
  & .DayPicker-Day--closed::after {
    width: 50px;
    border-radius: 3px;
    border: 1px solid ${props => props.theme.colors.purple[5]};
    padding: 5px;
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.github};
    position: absolute;
    font-weight: normal;
    font-size: 10px;
    top: -40px;
    left: -15px;
    opacity: 0;
    z-index: 1;
  }

  & .DayPicker-Day--published::after {
    content: 'Published date';
  }

  & .DayPicker-Day--closed::after {
    content: 'Closed date';
  }

  & .DayPicker-Day--published:hover::after,
  & .DayPicker-Day--closed:hover::after {
    transition: opacity 0.2s ease;
    transition-delay: 1s;
    opacity: 1;
  }
`;

const Notifier = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;

  & button {
    margin-left: 3px;
  }
`;

const DateRangePickerContainer = styled.div<{
  open?: boolean;
  layout?: Layout;
}>`
  position: relative;
  height: 36px;
  min-width: 225px;

  ${props =>
    props.layout &&
    css`
      ${SelectButton},
      ${Picker} {
        left: auto;
        right: 0;
      }
    `}

  ${props =>
    props.open &&
    css`
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));

      ${SelectButton} {
        background: ${props.theme.colors.purple[5]};
        border: none;
      }

      ${Picker} {
        display: block;
      }
    `}
`;

const clickOutsideConfig = {
  handleClickOutside: () => DateRangePicker.handleClickOutside,
};

export const JobOpeningAnalyticsDateRangePicker = onClickOutside(
  DateRangePicker,
  clickOutsideConfig
);
