import React from 'react';

export function NoAttachmentsIllustration() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 80">
      <path
        fill="#FF897A"
        d="M37.773 4.926c2.411-2.72 6.595-2.999 9.346-.622l27.158 23.472a6.484 6.484 0 0 1 .615 9.23L41.736 74.422c-2.41 2.721-6.595 3-9.345.623L5.23 51.573a6.485 6.485 0 0 1-.614-9.23L37.773 4.925Z"
      />
      <path
        fill="#FFD0CA"
        d="M57.383 8.788 76.67 30.146a1.864 1.864 0 0 1-2.575 2.682L51.97 14.427a3.918 3.918 0 1 1 5.413-5.639Z"
      />
      <path
        fill="#FFC4BC"
        d="m11.753 49.485 20.726 24.667c2.587 3.08 7.308 3.138 9.972.124l33.608-38.033a6.582 6.582 0 0 0 .18-8.504L60.28 8.056c-2.547-3.141-7.299-3.264-10.005-.258L27.607 32.983a4.417 4.417 0 0 1-5.239 1.006 4.417 4.417 0 0 0-5.25 1.017l-5.234 5.86a6.582 6.582 0 0 0-.131 8.619Z"
      />
    </svg>
  );
}
