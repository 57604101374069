import styled from '@emotion/styled';

export const MenuSubheader = styled.li`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 4px 12px;
  text-align: left;
  width: 100%;

  span {
    color: ${({ theme }) => theme.colors.purple[60]};
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;
