import React, { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSModal } from '@hundred5/design-system';

import { Icon, SemanticButton, TId } from '@/features/common';
import { useCreateInterviewMutation } from '@/features/interview';
import { useHistory, useJobOpeningId, useWorkspaceId } from '@/hooks/router';

interface AddToStageModalProps {
  categoryId: TId;
  open: boolean;
  onClose: () => void;
}

export function AddToStageModal({
  categoryId,
  onClose,
  open,
}: AddToStageModalProps) {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const createInterviewMutation = useCreateInterviewMutation({ jobOpeningId });

  useEffect(() => {
    if (open) {
      amplitude?.logEvent('pipeline/open add to this stage modal', {
        'workspace id': workspaceId,
        'job opening id': jobOpeningId,
        'stage id': categoryId,
      });
    }
  }, [open, workspaceId, jobOpeningId, categoryId]);

  const handleAddInterview = async () => {
    const interview = await createInterviewMutation.mutateAsync({
      jobOpeningId,
      categoryId,
      name: 'New interview',
    });

    history.push(
      `/admin/${workspaceId}/openings/${jobOpeningId}/interview/${interview.id}`
    );
  };

  const handleAddTest = () => {
    history.push(
      `/admin/${workspaceId}/test-library/${jobOpeningId}/${categoryId}/`
    );
  };

  return (
    <DSModal open={open} onClose={onClose}>
      <DSModal.CloseButton small fixed onClick={onClose} />

      <Header>Add to this stage</Header>

      <Content>
        <OptionContainer onClick={handleAddInterview}>
          <IconContainer bgrColor="green">
            <Icon icon={regular('comment')} color="purple-100" />
          </IconContainer>
          <OptionTitle>Interview</OptionTitle>
          <OptionDescription>
            Arrange an interview with the candidate
          </OptionDescription>
        </OptionContainer>

        <OptionContainer onClick={handleAddTest}>
          <IconContainer bgrColor="orange">
            <Icon icon={regular('ballot-check')} color="purple-100" />
          </IconContainer>
          <OptionTitle>Test</OptionTitle>
          <OptionDescription>
            Assess candidates with skills tests or homework assignments
          </OptionDescription>
        </OptionContainer>
      </Content>
    </DSModal>
  );
}

const Header = styled.h1`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorPrimary};
  text-align: center;
`;

const Content = styled.div`
  margin-top: 52px;
  margin-bottom: 104px;
  display: flex;
  gap: 24px;
  justify-content: center;
  flex-flow: wrap;
`;

const OptionContainer = styled(SemanticButton)`
  border: 1px solid ${({ theme }) => theme.colors.purple[40]};
  background: ${({ theme }) => theme.colors.white};
  width: 210px;
  display: flex;
  padding: 36px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: background 0.1s, transform 0.1s;

  &:active {
    transform: scale(0.96);
  }
`;

const IconContainer = styled.div<{ bgrColor: 'green' | 'orange' }>`
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background-color: ${({ bgrColor, theme }) => {
    switch (bgrColor) {
      case 'green':
        return theme.colors.green[10];
      case 'orange':
        return theme.colors.orange[20];
    }
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const OptionTitle = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorPrimary};
`;

const OptionDescription = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorSecondary};
`;
