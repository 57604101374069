import { useMemo } from 'react';

import { TId } from '@/features/common';
import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { useTestQuery } from '@/features/test';
import { useJobOpeningId } from '@/hooks/router';

export function usePipelineCategoryTest(categoryId?: TId) {
  const jobOpeningId = useJobOpeningId();
  const { data: pipelineCategories } = usePipelineCategoriesQuery({
    jobOpeningId,
  });
  const pipelineCategoryTestId = useMemo(
    () =>
      pipelineCategories?.find(category => category.id === categoryId)
        ?.testId as TId,
    [pipelineCategories, categoryId]
  );

  const testQuery = useTestQuery(
    { testId: pipelineCategoryTestId },
    { enabled: !!pipelineCategoryTestId }
  );

  return testQuery.data;
}
