import { useMemo } from 'react';
import { flatten } from 'lodash';

import { TId } from '@/features/common';
import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { useTestsQuery } from '@/features/test';
import { useJobOpeningId } from '@/hooks/router';

export function usePipelineTests() {
  const jobOpeningId = useJobOpeningId();
  const pipelineCategoriesQuery = usePipelineCategoriesQuery({
    jobOpeningId,
  });
  const pipelineCategoriesTestIds = useMemo(
    () =>
      pipelineCategoriesQuery.data
        ?.map(category => category.testId)
        .filter(Boolean) as TId[],
    [pipelineCategoriesQuery]
  );

  const testsQuery = useTestsQuery(
    { testIds: pipelineCategoriesTestIds },
    { enabled: !!pipelineCategoriesTestIds?.length }
  );
  const pipelineTests = useMemo(
    () => flatten(testsQuery.map(testQuery => testQuery.data || [])),
    [testsQuery]
  );

  return {
    pipelineTests,
    isLoading:
      pipelineCategoriesQuery.isLoading ||
      testsQuery.find(item => item.isLoading),
  };
}
