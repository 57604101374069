import React, { useRef, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSContextMenu, DSContextMenuButton } from '@hundred5/design-system';
import useOnClickOutside from 'use-onclickoutside';

import {
  CandidateContactInfoEditModal,
  isCandidateAnonymizedRecommendation,
  useCandidateQuery,
  useDeleteCandidateMutation,
  useExportCandidateMutation,
} from '@/features/candidate';
import { useUploadCandidateAttachment } from '@/features/candidate/hooks/candidate-attachments';
import { ConfirmModal, DownloadModal, Icon, TId } from '@/features/common';
import { useJobOpeningPermission } from '@/features/permissions';
import { usePreviewFeatureModal } from '@/features/preview-feature/hooks';
import { usePlanLimits } from '@/hooks/planLimits';
import {
  useHistory,
  useJobOpeningIdOrNull,
  useWorkspaceIdOrNull,
} from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';

//region Main
export interface CandidateContextMenuProps {
  candidateId: TId;
}

export function CandidateContextMenu(props: CandidateContextMenuProps) {
  const { data: candidate } = useCandidateQuery(props);

  const workspaceId = useWorkspaceIdOrNull();
  const jobOpeningId = useJobOpeningIdOrNull();

  const canAccessFeature = usePlanLimits();
  const canDeleteCandidate = useJobOpeningPermission()('candidate', 'delete');

  const { openUpsellModal } = useUpsellModal();
  const showPreviewFeatureModal = usePreviewFeatureModal();
  const history = useHistory();
  const ref = useRef<null | HTMLDivElement>(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [editContactInfoOpen, setEditContactInfoOpen] = useState(false);

  const exportCandidateMutation = useExportCandidateMutation({
    onSuccess: () => {
      amplitude?.logEvent('candidates/download candidate', {
        'workspace id': workspaceId,
        'job opening id': jobOpeningId,
        'candidate id': props.candidateId,
      });
    },
  });
  const deleteCandidateMutation = useDeleteCandidateMutation({
    onSuccess: () =>
      history.push(`/admin/${workspaceId}/openings/${jobOpeningId}/pipeline`),
  });

  const {
    candidateAttachmentInput,
    candidateAttachmentInputRef,
    candidateAttachmentUploadDisabled,
  } = useUploadCandidateAttachment(props);

  useOnClickOutside(ref, () => setMenuOpen(false));

  if (!candidate) {
    return null;
  }

  return (
    <CandidateContextMenuContainer ref={ref}>
      <DSContextMenuButton
        active={menuOpen}
        onClick={() => setMenuOpen(!menuOpen)}
      />
      <DSContextMenu
        open={menuOpen}
        position={{ top: `${36 / 14}rem`, right: '0' }}
      >
        <DSContextMenu.Item
          label="Edit details"
          icon={<Icon icon={regular('pen-to-square')} color="purple-60" />}
          disabled={isCandidateAnonymizedRecommendation(candidate)}
          data-rh={
            isCandidateAnonymizedRecommendation(candidate)
              ? 'All actions will be unlocked after the candidate takes the test.'
              : undefined
          }
          onClick={() => {
            if (candidate?.isUnlocked) {
              setEditContactInfoOpen(true);
              setMenuOpen(false);
            } else {
              openUpsellModal('premium_feature');
            }
          }}
        />
        <DSContextMenu.Item
          label="Upload file"
          icon={<Icon icon={regular('upload')} color="purple-60" />}
          disabled={candidateAttachmentUploadDisabled}
          onClick={() => {
            if (canAccessFeature('candidate_attachments')) {
              candidateAttachmentInputRef.current?.click();
            } else {
              showPreviewFeatureModal.open('attachments');
            }

            setMenuOpen(false);
          }}
        />

        <DSContextMenu.Item
          label="PDF"
          icon={<Icon icon={regular('download')} color="purple-60" />}
          onClick={() =>
            canAccessFeature('export_candidates')
              ? exportCandidateMutation.mutate(props)
              : openUpsellModal('premium_feature')
          }
        />

        {canDeleteCandidate && (
          <DSContextMenu.Item
            label="Delete"
            icon={<Icon icon={regular('trash')} color="purple-60" />}
            dangerous
            onClick={() => setConfirmDeleteOpen(true)}
          />
        )}
      </DSContextMenu>

      {/*Modals*/}

      <CandidateContactInfoEditModal
        open={editContactInfoOpen}
        candidateId={props.candidateId}
        onSettled={() => setEditContactInfoOpen(false)}
      />

      <DownloadModal
        url={exportCandidateMutation.data!}
        open={!!exportCandidateMutation.data}
        onClose={() => exportCandidateMutation.reset()}
      />

      <ConfirmModal
        open={confirmDeleteOpen}
        action="Delete"
        confirmation="delete"
        onConfirm={() => {
          setConfirmDeleteOpen(false);
          deleteCandidateMutation.mutate(props);
        }}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <h3>Are you sure?</h3>
        <p>
          This candidate data is going to be deleted permanently with no
          backups. Therefore be sure you really wish to do that.
        </p>
      </ConfirmModal>

      {candidateAttachmentInput}
    </CandidateContextMenuContainer>
  );
}

//endregion

//region Styles
const CandidateContextMenuContainer = styled.div`
  position: relative;
  margin-left: 6px;
`;
//endregion
