import * as React from 'react';

import './Lightbox.css';

const Lightbox = ({
  imageUrl,
  description,
}: {
  imageUrl: string;
  description?: string;
}) => (
  <div className="lightbox">
    <img src={imageUrl} alt={description} className="lightbox__image" />
  </div>
);

export default Lightbox;
