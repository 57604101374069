import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';
import { SortOrder } from '@/hooks/sort';

interface SortArrowProps {
  sortOrder: SortOrder;
  visible?: boolean;
}

export function SortArrow({ sortOrder, visible }: SortArrowProps) {
  return visible ? (
    <ArrowIconWrapper>
      <ArrowIcon
        icon={regular('chevron-up')}
        direction={sortOrder === 'asc' ? 'up' : 'down'}
        size="xs"
      />
    </ArrowIconWrapper>
  ) : null;
}

export const ArrowIconWrapper = styled.div`
  display: inline-block;
  margin: 0 3px;
`;

export const ArrowIcon = styled(Icon)<{ direction: 'up' | 'down' }>`
  display: block;
  transition: rotate 0.2s;
  rotate: ${props => (props.direction === 'up' ? 0 : '-180deg')};
`;
