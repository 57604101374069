import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSApplicationPage,
  DSButton,
  DSCheckbox,
  DSInlineField,
  DSInput,
  DSRadio,
  DSTextArea,
} from '@hundred5/design-system';

import { Icon, TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import {
  IJobOpeningApplicationFormQuestion,
  TJobOpeningApplicationFormQuestionEdit,
  useCreateJobOpeningApplicationFormQuestionMutation,
  useDeleteJobOpeningApplicationFormQuestion,
  useJobOpeningApplicationFormQuery,
  useUpdateJobOpeningApplicationFormQuestionMutation,
} from '@/features/job-opening';
import { useJobOpeningPermission } from '@/features/permissions';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

import { AddEditQuestionModal, DeleteQuestionModal } from './ui';

export function JobOpeningApplicationFormQuestions() {
  const { showFlashMessage } = useFlashMessages();
  const workspaceId = useWorkspaceId();
  const jobOpening = useJobOpeningId();
  const canUpdate = useJobOpeningPermission()('job opening', 'update');

  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [question, setQuestion] = useState<
    IJobOpeningApplicationFormQuestion | undefined
  >(undefined);

  const { data: applicationForm } = useJobOpeningApplicationFormQuery();
  const createQuestionMutation = useCreateJobOpeningApplicationFormQuestionMutation();
  const updateQuestionMutation = useUpdateJobOpeningApplicationFormQuestionMutation();
  const deleteQuestionMutation = useDeleteJobOpeningApplicationFormQuestion();

  const questions = applicationForm?.questions;

  const handleSaveQuestion = async (
    values: TJobOpeningApplicationFormQuestionEdit
  ) => {
    if (!question) {
      await createQuestionMutation.mutateAsync({
        question: values,
      });
    } else {
      await updateQuestionMutation.mutateAsync({
        questionId: question.id,
        question: values,
      });
    }

    amplitude?.logEvent('application form/save application question', {
      'workspace id': workspaceId,
      'job opening id': jobOpening,
      'question id': question?.id,
      'question type': values.type,
    });

    showFlashMessage({ type: 'saved' });
    setOpenAddEditModal(false);
    setQuestion(undefined);
  };

  const handleDeleteQuestion = async (questionId: TId) => {
    await deleteQuestionMutation.mutateAsync({ questionId });

    showFlashMessage({ type: 'saved' });
    setOpenDeleteModal(false);
    setQuestion(undefined);
  };

  return (
    <DSApplicationPage.Section title="Application questions">
      <Content>
        {questions?.map(question => (
          <QuestionGrid key={question.id}>
            <Question>
              {question.question}
              {question.required && '*'}
            </Question>

            <Answer>
              {question.type === 'paragraph' && (
                <DSTextArea
                  placeholder="Write your answer here..."
                  height="80px"
                  disabled
                />
              )}

              {question.type === 'short-answer' && (
                <DSInput placeholder="Type here..." disabled />
              )}

              {question.type === 'single-choice' &&
                question.options?.map(option => (
                  <DSInlineField
                    key={option.id}
                    for={`option-${option.id}`}
                    label={<OptionValue>{option.option}</OptionValue>}
                  >
                    <DSRadio
                      id={`option-${option.id}`}
                      checked={option.isCorrect}
                      disabled
                    />
                  </DSInlineField>
                ))}

              {question.type === 'multiple-choice' &&
                question.options?.map(option => (
                  <DSInlineField
                    key={option.id}
                    for={`option-${option.id}`}
                    label={<OptionValue>{option.option}</OptionValue>}
                  >
                    <DSCheckbox
                      id={`option-${option.id}`}
                      checked={option.isCorrect}
                      disabled
                    />
                  </DSInlineField>
                ))}
            </Answer>

            <Actions>
              <DSButton
                variant="secondary"
                size="xsmall"
                Icon={<Icon icon={regular('pen-to-square')} />}
                onClick={() => {
                  setQuestion(question);
                  setOpenAddEditModal(true);

                  amplitude?.logEvent(
                    'application form/edit application question',
                    {
                      'workspace id': workspaceId,
                      'job opening id': jobOpening,
                      'question id': question.id,
                    }
                  );
                }}
                disabled={!canUpdate}
                data-rh={!canUpdate ? 'Only admins can make changes' : null}
              />
              <DSButton
                variant="tertiary"
                size="xsmall"
                Icon={<Icon icon={regular('trash')} color="purple-100" />}
                onClick={() => {
                  setQuestion(question);
                  setOpenDeleteModal(true);
                }}
                disabled={!canUpdate}
                data-rh={!canUpdate ? 'Only admins can make changes' : null}
              />
            </Actions>
          </QuestionGrid>
        ))}

        <DSButton
          variant="tertiary"
          size="xsmall"
          Icon={<Icon icon={regular('plus')} />}
          onClick={() => {
            setQuestion(undefined);
            setOpenAddEditModal(true);

            amplitude?.logEvent('application form/add application question', {
              'workspace id': workspaceId,
              'job opening id': jobOpening,
            });
          }}
          disabled={!canUpdate}
          data-rh={!canUpdate ? 'Only admins can make changes' : null}
        >
          Add application question
        </DSButton>
      </Content>

      <AddEditQuestionModal
        question={question}
        open={openAddEditModal}
        onClose={() => {
          setOpenAddEditModal(false);
          setQuestion(undefined);
        }}
        onSave={handleSaveQuestion}
      />

      <DeleteQuestionModal
        questionId={question?.id}
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
          setQuestion(undefined);
        }}
        onDelete={handleDeleteQuestion}
      />
    </DSApplicationPage.Section>
  );
}

const Content = styled.div`
  display: grid;
  gap: 24px;
`;

const QuestionGrid = styled.div`
  display: grid;
  grid-template-areas: 'question actions' 'answer .';
  grid-template-columns: 1fr max-content;
  grid-row-gap: 12px;
  grid-column-gap: 40px;
`;

const Question = styled.div`
  grid-area: question;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.typography.colorPrimary};
`;

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  gap: 4px;
`;

const Answer = styled.div`
  grid-area: answer;
  display: grid;
  gap: 8px;

  label {
    cursor: auto;
  }
`;

const OptionValue = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  color: ${props => props.theme.typography.colorPrimary};
`;
