import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

interface NewTestButtonProps {
  onClick: () => void;
}

export function NewTestButton({ onClick }: NewTestButtonProps) {
  return (
    <Button onClick={onClick}>
      <Icon
        icon={regular('plus')}
        color="purple-100"
        size="lg"
        width="14px"
        height="14px"
      />
      Add interview or test
    </Button>
  );
}

const Button = styled.button`
  border: none;
  background: none;
  height: 36px;
  min-width: unset;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.typography.colorPrimary};
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  gap: 4px;
  padding-left: 8px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.peach[50]};
  }

  &:focus {
    background: ${({ theme }) => theme.colors.orange[20]};
    border: ${({ theme }) => `solid 2px ${theme.colors.white}`};
  }
`;
