import { User, UserAttributes } from '../types/user';

export type ApiUser = {
  id?: number;
  email: string;
  name: string;
  phone?: string;
  street?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  country?: string;
  timezone?: string | null;
  profilePictureUrl?: string | null;
  createdAt?: string;
};

export const parseUser = ({
  id,
  email,
  name,
  phone,
  street,
  city,
  zipCode,
  state,
  country,
  timezone,
  profilePictureUrl,
  createdAt,
}: ApiUser): User => ({
  id: String(id),
  email,
  name,
  phone,
  street,
  city,
  zipCode,
  state,
  country,
  timezone,
  createdAt: createdAt ? new Date(createdAt) : null,
  pictureUrl: profilePictureUrl || null,
});

export const serializeUser = (
  user: User,
  attributes: UserAttributes
): ApiUser => ({
  email: attributes.email !== undefined ? attributes.email : user.email,
  name: attributes.name !== undefined ? attributes.name : user.name,
  profilePictureUrl:
    attributes.pictureUrl !== undefined
      ? attributes.pictureUrl
      : user.pictureUrl,
  phone: attributes.phone !== undefined ? attributes.phone : user.phone,
  street: attributes.street !== undefined ? attributes.street : user.street,
  city: attributes.city !== undefined ? attributes.city : user.city,
  zipCode: attributes.zipCode !== undefined ? attributes.zipCode : user.zipCode,
  state: attributes.state !== undefined ? attributes.state : user.state,
  country: attributes.country !== undefined ? attributes.country : user.country,
  timezone:
    attributes.timezone !== undefined ? attributes.timezone : user.timezone,
});
