import parseDate from 'date-fns/parse';

import { DailyStats } from '@/types/analytics';

export const calculateCompletionStats = (
  totalVisits: DailyStats[],
  totalCandidates: DailyStats[]
): DailyStats[] => {
  const completionPercentage: DailyStats[] = [];

  for (let i = 0; i < totalVisits.length; i++) {
    let value = 0;

    if (totalVisits[i].value > 0) {
      value = Math.round(
        (totalCandidates[i].value / totalVisits[i].value) * 100
      );
    }

    completionPercentage.push({ date: parseDate(totalVisits[i].date), value });
  }

  return completionPercentage;
};

export const parseDailyStats = ({
  date,
  value,
}: {
  date: string;
  value: number;
}): DailyStats => ({
  date: parseDate(date),
  value: Math.round(value),
});
