import React from 'react';
import '@emotion/core';
import * as Sentry from '@sentry/react';
import createHistory from 'history/createBrowserHistory';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';

import { Environment } from '@/features/common/components/environment';

import App from './components/app/App';
import createStore from './createStore';
import './external';
import { ErrorFallback } from './features/common';
import configureSentry from './sentry';
import './smartlook';

import './fonts.css';
import './index.css';

const history = createHistory();
const store = createStore(history);

configureSentry(history);

const root = document.getElementById('root');
ReactModal.setAppElement(root!);

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={({ error, eventId, resetError }) => (
      <ErrorFallback error={error} eventId={eventId} resetError={resetError} />
    )}
  >
    <Environment store={store} history={history}>
      <App />
    </Environment>
  </Sentry.ErrorBoundary>,
  root
);
