import styled from '@emotion/styled';

export const EmptyState = styled.div`
  display: flex;
  width: 377px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.typography.colorPrimary};
  margin: 96px auto;

  img {
    margin-bottom: 24px;
  }

  h2 {
    margin: 0 0 12px 0;
    font-family: 'GT Haptik';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    font-feature-settings: 'ss04' on, 'ss02' on;
    color: ${props => props.theme.typography.colorPrimary};
  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.typography.colorSecondary};
  }
`;
