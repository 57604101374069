import { isAfter } from 'date-fns';

import { ITest } from '@/features/test';

export function useOpenEndedEvaluationVariants(test?: ITest) {
  if (!test) {
    return 5;
  }

  const hasFiveEvaluationOptions = isAfter(
    test.createdAt,
    new Date('2022-12-15T00:00:00')
  );

  return hasFiveEvaluationOptions ? 5 : 3;
}
