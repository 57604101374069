import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useCandidateSearchParams } from '@/features/candidate-search/hooks';
import { Icon } from '@/features/common';

import { DeleteButton } from '../../buttons';
import { STATE_FILTER_TYPE } from '../../state-filter';
import { FilterListItem } from '../filter-list-item';

export function StateListItem() {
  const { searchParams, addSearchParams } = useCandidateSearchParams();
  const { states } = searchParams;

  const removeState = React.useCallback(
    (stateToRemove: string) => {
      const newStates = states?.filter(state => state !== stateToRemove);

      addSearchParams({ [STATE_FILTER_TYPE]: newStates });
    },
    [addSearchParams, states]
  );

  if (!states) return null;

  return (
    <>
      {states.map(state => (
        <FilterListItem key={state}>
          <Icon icon={solid('circle-ellipsis')} color="orange-100" />
          <StateLabel>{state.replaceAll('_', ' ')}</StateLabel>
          <DeleteButton onClick={() => removeState(state)} />
        </FilterListItem>
      ))}
    </>
  );
}

const StateLabel = styled.span`
  text-transform: capitalize;
`;
