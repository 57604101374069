import React from 'react';
import styled from '@emotion/styled';

import { TSkillType } from '@/features/skills';
import { TAB_LABEL_DICTIONARY } from '@/features/skills/utils';

interface SkillTabsProps {
  tabs: TSkillType[];
  activeTab: string;
  onTabClick: (tabKey: string) => void;
}

export function SkillTabs({ tabs, activeTab, onTabClick }: SkillTabsProps) {
  return (
    <Tabs>
      {tabs.map(tabKey => (
        <TabsItem key={tabKey} active={activeTab === tabKey}>
          <button
            type="button"
            data-text={tabKey}
            onClick={() => onTabClick(tabKey)}
          >
            {TAB_LABEL_DICTIONARY[tabKey] ?? tabKey}
          </button>
        </TabsItem>
      ))}
    </Tabs>
  );
}

const Tabs = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 36px;
`;

const TabsItem = styled.li<{ active: boolean }>`
  margin-right: 24px;

  button {
    background: transparent;
    border-style: none;
    border-bottom: ${({ active, theme }) =>
      active ? `1px solid ${theme.colors.orange[100]}` : 'none'};
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    font-weight: ${({ active }) => (active ? '700' : '400')};
    padding: 8px 0;

    &::after {
      content: attr(data-text) / '';
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: 700;
    }
  }
`;
