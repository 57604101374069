import { useMutation } from '@tanstack/react-query';

import { bulkDownloadPDF } from '@/features/bulk-actions/api';
import { TId } from '@/features/common';

export const BULK_DOWNLOAD_MUTATION_KEY = ['bulk-download-pdf'];

//region Mutations
export const useBulkDownloadPDF = (params: { candidateIDs: TId[] }) => {
  return useMutation([...BULK_DOWNLOAD_MUTATION_KEY, params.candidateIDs], () =>
    bulkDownloadPDF(params.candidateIDs)
  );
};
//endregion
