import { useMutation } from '@tanstack/react-query';

import { bulkSendEmail } from '@/features/bulk-actions/api/bulk-send-email';
import { TId } from '@/features/common';

export const BULK_SEND_EMAIL_QUERY_KEY = ['bulk-send-email'];

//region Mutations

interface BulkSendEmailParams {
  candidateIds: TId[];
  subject: string;
  message: string;
  sendAt?: Date;
}

export const useBulkSendEmail = () => {
  return useMutation((params: BulkSendEmailParams) => bulkSendEmail(params));
};
//endregion
