import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { useBulkActionModal } from '@/features/bulk-actions/hooks/use-bulk-action-modal';
import { BulkActionProps } from '@/features/bulk-actions/types';
import { Icon } from '@/features/common';

import { BulkDownloadPDFModal } from './bulk-download-pdf-modal';

//region Main
export const BulkDownloadPDF = ({
  disabled,
  selectedCandidates,
  onCloseModal,
}: BulkActionProps) => {
  const { isOpen, setIsOpen, handleCloseModal } = useBulkActionModal(
    onCloseModal
  );

  return (
    <>
      <DSButton
        type="button"
        variant="secondary"
        size="xsmall"
        data-rh="PDF Download"
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        Icon={<Icon icon={regular('download')} />}
      />
      <BulkDownloadPDFModal
        open={isOpen}
        onClose={handleCloseModal}
        candidates={selectedCandidates}
      />
    </>
  );
};
//endregion
