import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Bar, BarChart } from 'recharts';

import { DailyStats } from '@/types/analytics';

interface AnalyticsStatProps {
  color: string;
  value: string | null;
  dailyStats?: DailyStats[] | null;
  title: string;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function JobOpeningAnalyticsStat({
  color,
  value,
  dailyStats,
  title,
  selected = false,
  onClick,
}: AnalyticsStatProps) {
  const max = useMemo(
    () =>
      dailyStats?.length
        ? dailyStats.reduce((prev, current) => {
            return prev.value > current.value ? prev : current;
          })
        : { value: 0 },
    [dailyStats]
  );

  const data = useMemo(
    () =>
      dailyStats?.length &&
      dailyStats.map(o => {
        return {
          ...o,
          value: o.value || (max.value === 0 ? 100 : max.value * 0.1),
        };
      }),
    [dailyStats, max]
  );

  return (
    <AnalyticsStatContainer
      selected={selected}
      clickable={!!onClick}
      onClick={onClick}
    >
      <div>
        {data && data.length ? (
          <BarChart width={41} height={max.value === 0 ? 2 : 23} data={data}>
            <Bar dataKey="value" fill={color} />
          </BarChart>
        ) : null}
      </div>
      <Value>{value}</Value>
      <Label>
        {title} {selected && <Arrow>›</Arrow>}
      </Label>
    </AnalyticsStatContainer>
  );
}

const AnalyticsStatContainer = styled.div<{
  selected: boolean;
  clickable: boolean;
}>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  height: 50px;

  ${props =>
    props.selected &&
    css`
      font-weight: bold;
    `}

  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

const Value = styled.div`
  width: 90px;
  min-width: 90px;
  text-align: right;
`;

const Label = styled.div`
  margin-left: 10px;
`;

const Arrow = styled.span`
  color: ${props => props.theme.colors.orange[100]};
`;
