import React from 'react';
import styled from '@emotion/styled';
import { brands, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

export function Illustration() {
  return (
    <IllustrationContainer>
      <Column>
        <IconContainer margin="11px 0 0 0">
          <Icon icon={brands('github')} color="purple-100" fontSize={22} />
        </IconContainer>
      </Column>
      <Column>
        <IconContainer margin="0 11px 0 0">
          <Icon icon={solid('envelope')} color="purple-100" fontSize={22} />
        </IconContainer>
        <IconContainer margin="0 0 0 11px">
          <Icon icon={brands('linkedin-in')} color="purple-100" fontSize={22} />
        </IconContainer>
      </Column>
      <Column>
        <IconContainer margin="0 0 11px 0">
          <Icon icon={brands('facebook-f')} color="purple-100" fontSize={22} />
        </IconContainer>
      </Column>
    </IllustrationContainer>
  );
}

const IllustrationContainer = styled.div`
  grid-area: illustration;
  border-radius: 8px;
  background: ${props => props.theme.colors.purple[5]};
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

const IconContainer = styled.div<{ margin: string }>`
  display: flex;
  width: 58px;
  height: 58px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.white};
  margin: ${props => props.margin};
`;
