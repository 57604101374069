import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
// @ts-expect-error FIXME: aliased package has some issues in TS
import { intervalToDuration } from 'date-fns2';

import { InfoBox } from './info-box';

interface ExplanatoryTextProps {
  nextRefresh: Date;
  allRecommendationsUsed?: boolean;
}

export function ExplanatoryText({
  nextRefresh,
  allRecommendationsUsed,
}: ExplanatoryTextProps) {
  const [durationTillRefresh, setDurationTillRefresh] = useState(
    intervalToDuration({ start: Date.now(), end: nextRefresh })
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDurationTillRefresh(() =>
        intervalToDuration({
          start: new Date(),
          end: nextRefresh,
        })
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [nextRefresh]);

  if (allRecommendationsUsed) {
    return (
      <InfoBox
        heading={
          <>
            New matches in{' '}
            <Time>
              {durationTillRefresh.hours + durationTillRefresh.days * 24}h
              {durationTillRefresh.minutes}m{durationTillRefresh.seconds}s
            </Time>
          </>
        }
        body="Good job! You have contacted all recommended candidates 🙌"
      />
    );
  }

  return (
    <InfoBox
      heading="We've found some great matches for you!"
      body="Invite skilled candidates from our talent pool and start hiring today 🎉"
    />
  );
}

const Time = styled.span`
  font-weight: 700;
`;
