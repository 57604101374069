import styled from '@emotion/styled';

export const SelectionWrapper = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: min-content max-content;
  gap: 4px;
  align-items: center;
  padding-left: 8px;
`;
