import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Field } from '@hundred5/design-system';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';

import { getCurrentMentions } from '@/features/candidate';
import { TId } from '@/features/common';
import { AutocompleteTextArea } from '@/features/common/components/autocomplete-text-area/autocomplete-text-area';
import { useJobOpeningPermission } from '@/features/permissions';
import { WorkspaceUser } from '@/features/workspace';

import { useCandidateInterviewNotesQuery } from '../../queries/candidate-interview-notes';

import {
  AddInterviewNoteButton,
  InterviewNote,
  InterviewNotesEmptyState,
} from './ui';

interface ICandidateInterviewNotesProps {
  candidateId: TId;
  interviewId: TId;
}

export const CandidateInterviewNotes = ({
  candidateId,
  interviewId,
}: ICandidateInterviewNotesProps) => {
  const { tabItemId: noteId } = useParams();
  const allowCandidateNotesCreating = !!useJobOpeningPermission()(
    'candidate notes',
    'create'
  );
  const { stageId: categoryId, opening: jobOpeningId } = useParams();

  const candidateInterviewNotesQuery = useCandidateInterviewNotesQuery({
    candidateId,
    interviewId,
    jobOpeningId,
    categoryId,
  });

  const [noteContent, setNoteContent] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState<WorkspaceUser[]>([]);

  const scrollToNote = (noteId: string) => {
    const note = document.getElementById(`note-id-${noteId}`);
    if (note) {
      note.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (noteId && candidateInterviewNotesQuery.isSuccess) {
      scrollToNote(noteId);
    }
  }, [noteId, candidateInterviewNotesQuery.isSuccess]);

  return (
    <CandidateNotesContainer data-recording-ignore="mask">
      {candidateInterviewNotesQuery?.data?.notes &&
      candidateInterviewNotesQuery.data.notes.length > 0 &&
      allowCandidateNotesCreating ? (
        candidateInterviewNotesQuery.data.notes.map(note => (
          <InterviewNote key={note.id} note={note} />
        ))
      ) : (
        <InterviewNotesEmptyState />
      )}

      <Prompt
        when={noteContent !== ''}
        message="You have unsaved note! Are you sure you want to navigate away?"
      />

      {allowCandidateNotesCreating ? (
        <Footer>
          <Field
            for="candidate-note-form-add-note"
            input={
              <AutocompleteTextArea
                placeholder="Add a note about the candidate here..."
                value={noteContent}
                onChange={setNoteContent}
                onSelect={user => {
                  setMentionedUsers(prev => [...prev, user]);
                }}
              />
            }
          />
          <AddNoteButtonWrapper>
            <AddInterviewNoteButton
              candidateId={candidateId}
              content={noteContent}
              onAdded={() => {
                setNoteContent('');
                setMentionedUsers([]);
              }}
              interviewId={interviewId}
              userIds={getCurrentMentions(noteContent, mentionedUsers)}
            />
          </AddNoteButtonWrapper>
        </Footer>
      ) : null}
    </CandidateNotesContainer>
  );
};

//region Styles
const CandidateNotesContainer = styled.article`
  background-color: ${props => props.theme.colors.white};
  padding-bottom: 25px;

  @media all and (max-width: 768px) {
    padding-bottom: 20px;
  }

  > article:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const Footer = styled.div`
  padding: 20px 36px 0;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 768px) {
    padding: 20px 16px 0;
  }
`;

const AddNoteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
//endregion
