import * as React from 'react';
import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { Role } from '@/features/workspace';

//region Main
export const UserRoleTag = ({ role }: { role: Role }) => {
  const theme = useTheme();
  const bgColor = getBgColor(role, theme);
  return (
    <UserRoleContainer bgColor={bgColor}>{ROLE_LABELS[role]}</UserRoleContainer>
  );
};
//endregion

//region Utils
const ROLE_LABELS = {
  owner: 'Workspace Owner',
  admin: 'Admin',
  manager: 'Manager',
  viewer: 'Viewer',
  'hundred5-support': 'Support',
};

const getBgColor: (role: Role, theme: Theme) => string = (role, theme) => {
  const colors = {
    owner: theme.colors.red[10],
    admin: '#E8F9F3',
    manager: theme.colors.yellow[50],
    viewer: '#e0e0e0',
    'hundred5-support': '#e0e0e0',
  };
  return colors[role];
};
//endregion

//region Styles
const UserRoleContainer = styled.article<{ bgColor: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  align-items: center;
  padding: 0px 8px;
  height: 18px;

  background: ${props => props.bgColor};
  color: ${props => props.theme.colors.purple[100]};
  border-radius: 8px;
`;
//endregion
