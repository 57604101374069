import styled from '@emotion/styled';

export const CopyTestLinkButton = styled.button`
  border: none;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.peach[100]};
  border-radius: 0px 8px 8px 0px;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;

  &:hover {
    background: ${({ theme }) => theme.colors.peach[50]};
  }
`;
