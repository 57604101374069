import styled from '@emotion/styled';

export const ParagraphNoData = styled.p`
  font-size: 14px;
  color: ${props => props.theme.colors.purple[100]};
  text-align: center;
  width: 510px;
  margin: auto;
  margin-top: 15px;
`;
