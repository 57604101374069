import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import isEqual from 'lodash/isEqual';

import { Icon } from '@/features/common';
import { Loader } from '@/features/common/components/loader';
import { setActiveDashboardTab } from '@/utils/dashboard';
import { setActivePipeline } from '@/utils/pipeline';

import { useInboxItemsQuery, useMarkInboxItemsSeenMutation } from '../..';
import { useInboxSearchParams } from '../../hooks/params';
import { useInboxSearch } from '../../providers';

import { MessageRow } from './ui';

export function InboxPageContent() {
  const { addSearchParams, searchParams } = useInboxSearchParams();
  const { defaultParams } = useInboxSearch();

  const inboxItemsQuery = useInboxItemsQuery();
  const { mutate: markSeen } = useMarkInboxItemsSeenMutation();
  const messages = inboxItemsQuery.data?.items || [];
  const totalMessages = inboxItemsQuery.data?.total || 0;

  const unreadMessagesIds = useMemo(
    () =>
      inboxItemsQuery.data?.items
        ? inboxItemsQuery.data.items
            .filter(message => !!!message.seenAt)
            .map(message => message.id)
        : [],
    [inboxItemsQuery.data?.items]
  );

  const handleOnMarkAsRead = useCallback(() => {
    markSeen({
      itemsIds: unreadMessagesIds,
    });
  }, [markSeen, unreadMessagesIds]);

  setActiveDashboardTab('inbox');
  setActivePipeline(null);

  return (
    <PageContainer>
      <MessagesTable>
        <TableHeader>
          <TableNav>
            <DSButton
              variant="tertiary"
              size="small"
              Icon={<Icon icon={regular('arrow-rotate-right')} />}
              onClick={() => inboxItemsQuery.refetch()}
            />
            <DSButton
              variant="tertiary"
              size="small"
              Icon={<Icon icon={regular('envelope-open')} />}
              onClick={() => handleOnMarkAsRead()}
              disabled={unreadMessagesIds.length === 0}
              data-rh="Mark all as read"
              data-rh-at="right"
            />
          </TableNav>
          {totalMessages > 0 && (
            <PaginationNav>
              <span>
                {`${(Number(searchParams.page) - 1) * messages.length +
                  1}-${Number(searchParams.page) *
                  messages.length} of ${totalMessages}`}
              </span>

              <NavButtons>
                <NavButton
                  variant="tertiary"
                  size="xsmall"
                  Icon={<Icon icon={regular('angle-left')} />}
                  disabled={searchParams.page === '1'}
                  onClick={() =>
                    addSearchParams({
                      page: String(Number(searchParams.page) - 1),
                    })
                  }
                />
                <NavButton
                  variant="tertiary"
                  size="xsmall"
                  Icon={<Icon icon={regular('angle-right')} />}
                  disabled={
                    totalMessages -
                      Number(searchParams.page) *
                        Number(searchParams.itemsPerPage) <=
                    0
                  }
                  onClick={() =>
                    addSearchParams({
                      page: String(Number(searchParams.page) + 1),
                    })
                  }
                />
              </NavButtons>
            </PaginationNav>
          )}
        </TableHeader>
        {inboxItemsQuery.isFetching ? (
          <>
            <LoadingOverlay />
            <StyledLoader />
          </>
        ) : totalMessages > 0 ? (
          messages.map(message => <MessageRow key={message.id} {...message} />)
        ) : isEqual(searchParams, { ...defaultParams.pagination }) ? (
          <EmptyState>You don't have any messages.</EmptyState>
        ) : (
          <EmptyState>No messages matched your search.</EmptyState>
        )}
      </MessagesTable>
    </PageContainer>
  );
}

//region Styles
const PageContainer = styled.div`
  position: relative;
  flex: 1;
`;

const MessagesTable = styled.div`
  margin: 12px 24px;
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadow.card};
  overflow: hidden;
`;

const TableHeader = styled.div`
  background-color: ${props => props.theme.colors.white};
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
`;

const TableNav = styled.div`
  display: flex;
  gap: 8px;
`;

const PaginationNav = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.typography.colorSecondary};
`;

const NavButtons = styled.div`
  display: flex;
  gap: 4px;
`;

const NavButton = styled(DSButton)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: ${props => props.theme.colors.white};
  opacity: 0.5;
  z-index: 10;
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 0;
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 16px 0 280px 0;
  background-color: ${props => props.theme.colors.white};
  font-size: 12px;
`;

//endregion
