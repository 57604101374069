import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TableRow = styled.div<{ header?: boolean }>`
  display: flex;
  align-items: center;
  padding: 16px 12px;
  gap: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  background: ${props => props.theme.colors.white};
  transition: background 0.1s;

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.header
      ? css`
          font-weight: 500;
          font-size: ${10 / 14}rem;
          text-transform: uppercase;
          color: ${props.theme.typography.colorSecondary};
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `
      : css`
          font-weight: 400;
          font-size: 1rem;
          cursor: ${props.onClick && 'pointer'};
          color: ${props.theme.typography.colorPrimary};

          &:hover {
            background: ${props.onClick && props.theme.hover.onWhite};
          }
        `}
`;

export const TableColumn = styled.div<{ width: string }>`
  width: ${props => props.width};
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  align-items: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
`;
