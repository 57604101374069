import { ICandidateTest, useCandidateQuery } from '@/features/candidate';
import { TId } from '@/features/common';

// TODO: convert this into an utility function
export const useCandidateTest = (params: {
  candidateId: TId;
  testId: TId;
}): ICandidateTest | undefined => {
  const { data: candidate } = useCandidateQuery(params, {
    enabled: !!params.candidateId && !!params.testId,
  });

  return (
    candidate?.tests.find(test => test.testId === params.testId) || undefined
  );
};
