import React from 'react';
import styled from '@emotion/styled';
import { filter, orderBy } from 'lodash';

import { TableColumn, TableRow } from '@/features/common';
import { useWorkspaceUsersQuery } from '@/features/workspace';

import { TeamMember } from './team-member';

//region Main
export const TeamMemberList = ({ searchQuery }: { searchQuery: string }) => {
  const workspaceUsersQuery = useWorkspaceUsersQuery();
  const users = workspaceUsersQuery.data || [];
  const sortedWorkspaceUsers = orderBy(filter(users), ['name'], ['asc']);
  const filteredUsers = sortedWorkspaceUsers.filter(user =>
    searchQuery
      ? user.name.toLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLocaleLowerCase())
      : true
  );

  return (
    <article>
      <TableRow header>
        <TableColumn width="33%">Name</TableColumn>
        <TableColumn width="33%">Email</TableColumn>
        <TableColumn width="33%">Role</TableColumn>
      </TableRow>
      {filteredUsers.length > 0 ? (
        filteredUsers.map(user => <TeamMember user={user} key={user.id} />)
      ) : (
        <EmptyResults>No users found</EmptyResults>
      )}
    </article>
  );
};
//endregion

//region Main
const EmptyResults = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 60px;
  color: ${props => props.theme.typography.colorSecondary};
`;
//endregion
