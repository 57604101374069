import * as React from 'react';
import { DSButton } from '@hundred5/design-system';

import './SubscribeForm.css';

type Props = {
  saving: boolean;
  onSubscribe: () => void;
};

const SubscribeForm = ({ saving, onSubscribe }: Props) => (
  <div className="purchase-access-subscribe">
    <DSButton type="submit" disabled={saving} onClick={onSubscribe}>
      {!saving ? (
        <React.Fragment>Complete purchase</React.Fragment>
      ) : (
        'Completing...'
      )}
    </DSButton>
  </div>
);

export default SubscribeForm;
