import { Role } from '@/types/role';

const roleHierarchy: Record<Role, number> = {
  'hundred5-support': 5,
  owner: 4,
  admin: 3,
  manager: 2,
  viewer: 1,
};

/**
 *  Checks if mainRole is greater or equal to otherRole
 */
export function isGreaterOrEqual(mainRole: Role, otherRole: Role) {
  return roleHierarchy[mainRole] >= roleHierarchy[otherRole];
}
