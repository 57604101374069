import { useCallback, useState } from 'react';

import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';
import { CandidateExportFormat } from '@/types/reports';

import { CandidatesExportModalState } from './candidates-export-modal.types';

export function useCandidatesExportModal() {
  const canAccessFeature = usePlanLimits();
  const { openUpsellModal } = useUpsellModal();
  const [state, setState] = useState<CandidatesExportModalState>({
    open: false,
  });
  const open = useCallback(
    (format: CandidateExportFormat) => {
      if (canAccessFeature('export_candidates')) {
        setState({ open: true, format });
      } else {
        openUpsellModal('premium_feature');
      }
    },
    [canAccessFeature, openUpsellModal]
  );
  const close = useCallback(() => setState({ open: false }), []);

  return {
    state,
    open,
    close,
  };
}
