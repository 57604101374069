import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  getCandidateEmail,
  getCandidateGithubUrl,
  getCandidateLinkedinUrl,
  useCandidateQuery,
  isCandidateAnonymizedRecommendation,
} from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { useUpsellModal } from '@/hooks/upsellModal';

//region Main
export interface ContactFieldsProps {
  candidateId: TId;
}

export function ContactFields({ candidateId }: ContactFieldsProps) {
  const { data: candidate } = useCandidateQuery({ candidateId });
  const { openUpsellModal } = useUpsellModal();
  const [showCustomFields, setShowCustomFields] = useState(false);

  const { city, country, phone } = candidate?.contactInfo || {};
  const hasContactInfo = city || country || phone;

  const email = getCandidateEmail(candidate);

  const linkedInUrl =
    getCandidateLinkedinUrl(
      candidate?.contactInfo.linkedin || candidate?.url || ''
    ) || '';

  const gitHubUrl =
    getCandidateGithubUrl(
      candidate?.contactInfo.github || candidate?.url || ''
    ) || '';

  const customFields = candidate?.candidateFields?.filter(
    field => !!field.value
  );

  const handleClick = (e: React.MouseEvent) => {
    if (!candidate?.isUnlocked) {
      e.preventDefault();
      openUpsellModal('premium_feature');
    }
  };

  return candidate ? (
    <ContactInfoContainer>
      {hasContactInfo && (
        <Row>
          <Col>
            {candidate.contactInfo.city}
            {candidate.contactInfo.city &&
              candidate.contactInfo.country &&
              ', '}
            {candidate.contactInfo.country}
          </Col>
          <Col>{candidate.contactInfo.phone}</Col>
        </Row>
      )}

      {(email || linkedInUrl || gitHubUrl) &&
        !isCandidateAnonymizedRecommendation(candidate) && (
          <Row>
            {email && (
              <Col>
                <a
                  href={
                    candidate.isUnlocked
                      ? `mailto:${email}`
                      : 'https://toggl.com/hire/pricing'
                  }
                  key="email"
                  onClick={handleClick}
                >
                  Email
                </a>
              </Col>
            )}
            {linkedInUrl && (
              <Col>
                <a
                  href={linkedInUrl}
                  key="linkedin"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleClick}
                >
                  LinkedIn
                </a>
              </Col>
            )}
            {gitHubUrl && (
              <Col>
                <a
                  href={gitHubUrl}
                  key="github"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleClick}
                >
                  GitHub
                </a>
              </Col>
            )}
          </Row>
        )}

      {customFields?.length ? (
        <>
          <Row>
            <ShowCustomFieldsButton
              type="button"
              onClick={() => setShowCustomFields(!showCustomFields)}
            >
              Custom information
              <AnimatedIcon
                icon={regular('angle-down')}
                open={showCustomFields}
              />
            </ShowCustomFieldsButton>
          </Row>
          <CustomFields open={showCustomFields} count={customFields.length}>
            {customFields.map(field => (
              <Row key={field.id} data-rh={field.name}>
                {field.value}
              </Row>
            ))}
          </CustomFields>
        </>
      ) : null}
    </ContactInfoContainer>
  ) : null;
}
//endregion

//region Styles
const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme.colors.purple[100]};
  min-height: 18px;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const Col = styled.span`
  margin-right: 8px;
`;

const ShowCustomFieldsButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 12px;
  background: transparent;
  color: ${props => props.theme.colors.purple[100]};
  padding: 0;
`;

const CustomFields = styled.div<{ open: boolean; count: number }>`
  height: ${props => (props.open ? `${props.count * 21}px` : 0)};
  transition: all 0.2s;
  overflow: hidden;
`;

const AnimatedIcon = styled(Icon)<{ open: boolean }>`
  transition: all 0.2s;
  margin-left: 5px;

  ${props =>
    props.open &&
    css`
      transform: rotate(180deg);
    `}
`;
//endregion
