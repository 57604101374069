import { useTreatments } from '@splitsoftware/splitio-react';

import { useWorkspaceQuery } from '@/features/workspace';
import { useWorkspaceIdOrNull } from '@/hooks/router';

const useWorkspaceTreatments = (
  splits: string[]
): SplitIO.TreatmentsWithConfig => {
  const workspaceId = useWorkspaceIdOrNull();
  const workspaceQuery = useWorkspaceQuery({
    workspaceId: workspaceId ?? undefined,
  });

  const attributes = !!workspaceQuery.data
    ? {
        subscription_plan: workspaceQuery.data.subscriptionPlan,
        created_at: workspaceQuery.data.createdAt.getTime(),
      }
    : undefined;

  return useTreatments(splits, attributes);
};

export default useWorkspaceTreatments;
