import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  useCandidateQuery,
  useCandidateSuspiciousActivityQuery,
} from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { useFraudDetectionSettingsQuery } from '@/features/test';

import { JoinCandidates } from './ui';

//region Main
export interface CandidateSuspiciousActivityProps {
  candidateId: TId;
  testId: TId;
}

export function CandidateSuspiciousActivity(
  props: CandidateSuspiciousActivityProps
) {
  const candidateQuery = useCandidateQuery(props);
  const fraudDetectionSettingsQuery = useFraudDetectionSettingsQuery(props);
  const candidateSuspiciousActivityQuery = useCandidateSuspiciousActivityQuery(
    props
  );

  if (
    !candidateQuery.isSuccess ||
    !fraudDetectionSettingsQuery.isSuccess ||
    !candidateSuspiciousActivityQuery.isSuccess
  ) {
    return null;
  }

  const {
    isEnabled,
    isFingerprintDetectionEnabled,
    isCopyDetectionEnabled,
    isPasteDetectionEnabled,
    isQuickSubmitDetectionEnabled,
    isSimilarEmailDetectionEnabled,
  } = fraudDetectionSettingsQuery.data;

  const {
    fingerprintClashes,
    copyEvents,
    pasteEvents,
    quickTestSubmit,
    similarEmails,
  } = candidateSuspiciousActivityQuery.data;

  const candidateTest = candidateQuery.data.tests.find(
    test => test.testId === props.testId
  );

  if (!candidateTest?.questions) return null;

  const suspiciousIcon = (
    <Icon icon={solid('triangle-exclamation')} color="yellow-100" />
  );

  return (
    <CandidateSuspiciousActivityContainer isEnabled={isEnabled}>
      {isEnabled ? (
        <>
          {isFingerprintDetectionEnabled && fingerprintClashes?.length ? (
            <div data-rh="Candidate completed several tests from the same browser">
              {suspiciousIcon} Same browser fingerprint as{' '}
              <JoinCandidates clashes={fingerprintClashes} />
            </div>
          ) : null}

          {isCopyDetectionEnabled && copyEvents?.length ? (
            <div data-rh="Candidate copied test questions">
              {suspiciousIcon} Copy detected on question
              {copyEvents.length > 1 ? 's ' : ' '}
              {copyEvents
                .map(
                  event =>
                    candidateTest.questions!.findIndex(
                      question => question.id === event.questionId
                    ) + 1
                )
                .sort()
                .join(', ')}
            </div>
          ) : null}

          {isPasteDetectionEnabled && pasteEvents?.length ? (
            <div data-rh="Candidate used the paste function to answer test questions">
              {suspiciousIcon} Paste detected on question
              {pasteEvents.length > 1 ? 's ' : ' '}
              {pasteEvents
                .map(
                  event =>
                    candidateTest.questions!.findIndex(
                      question => question.id === event.questionId
                    ) + 1
                )
                .sort()
                .join(', ')}
            </div>
          ) : null}

          {isQuickSubmitDetectionEnabled && quickTestSubmit ? (
            <div>{suspiciousIcon} Completed under 5 minutes</div>
          ) : null}

          {isSimilarEmailDetectionEnabled && similarEmails?.length ? (
            <div>
              {suspiciousIcon} Similar email to{' '}
              <JoinCandidates clashes={similarEmails} />
            </div>
          ) : null}
        </>
      ) : (
        <DisabledMessage>
          Anti-cheating detection is currently disabled. Go to test settings
          page to enable it.
        </DisabledMessage>
      )}
    </CandidateSuspiciousActivityContainer>
  );
}

//endregion

//region Styles
const CandidateSuspiciousActivityContainer = styled.div<{ isEnabled: boolean }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 12px 36px;
  background-color: ${props =>
    !props.isEnabled && props.theme.colors.purple[5]};

  & > div {
    margin-bottom: 5px;
  }

  & svg {
    margin-right: 3px;
  }
`;

const DisabledMessage = styled.span`
  color: ${props => props.theme.colors.purple[60]};
`;
//endregion
