import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSApplicationPage,
  DSButton,
  DSCheckbox,
} from '@hundred5/design-system';
import { useFormikContext } from 'formik';

import { Icon } from '@/features/common';
import {
  ICustomCandidateField,
  TJobOpeningApplicationFormFields,
} from '@/features/job-opening';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

import { AddCustomFieldModal, FieldRow, FieldRowMenu } from '.';

export function CandidateFields() {
  const workspaceId = useWorkspaceId();
  const jobOpening = useJobOpeningId();

  const [openAddCustomFieldModal, setOpenAddCustomFieldModal] = useState(false);
  const form = useFormikContext<TJobOpeningApplicationFormFields>();

  const handleAddCustomField = (name: string) => {
    const newField: ICustomCandidateField = {
      title: name,
      enabled: true,
      required: false,
    };
    form.setFieldValue('customCandidateFields', [
      ...(form.values.customCandidateFields || []),
      newField,
    ]);
    setOpenAddCustomFieldModal(false);
  };

  const handleDeleteCustomField = (field: string, index: number) => {
    const newValue = [...form.values[field]];
    newValue.splice(index, 1);
    form.setFieldValue(field, newValue);
  };

  return (
    <DSApplicationPage.Section title="Candidate details">
      <div>
        {/* MANDATORY CANDIDATE FIELDS */}
        <FieldRow>
          <DSCheckbox checked disabled />
          First name
          <FieldRowMenu isMandatory />
        </FieldRow>

        <FieldRow>
          <DSCheckbox checked disabled />
          Last name
          <FieldRowMenu isMandatory />
        </FieldRow>

        <FieldRow>
          <DSCheckbox checked disabled />
          Email
          <FieldRowMenu isMandatory />
        </FieldRow>

        {/* SYSTEM CANDIDATE FIELDS */}
        <FieldRow>
          <DSCheckbox
            checked={form.values.linkedinEnabled}
            onChange={event => {
              form.setFieldValue('linkedinEnabled', event.target.checked);
            }}
          />
          LinkedIn profile
          <FieldRowMenu
            canEdit
            isMandatory={form.values.linkedinRequired}
            onEdit={newValue => {
              form.setFieldValue('linkedinRequired', newValue);
            }}
          />
        </FieldRow>

        <FieldRow>
          <DSCheckbox
            checked={form.values.githubEnabled}
            onChange={event => {
              form.setFieldValue('githubEnabled', event.target.checked);
            }}
          />
          GitHub profile
          <FieldRowMenu
            canEdit
            isMandatory={form.values.githubRequired}
            onEdit={newValue => {
              form.setFieldValue('githubRequired', newValue);
            }}
          />
        </FieldRow>

        <FieldRow>
          <DSCheckbox
            checked={form.values.cityEnabled}
            onChange={event => {
              form.setFieldValue('cityEnabled', event.target.checked);
            }}
          />
          City
          <FieldRowMenu
            canEdit
            isMandatory={form.values.cityRequired}
            onEdit={newValue => {
              form.setFieldValue('cityRequired', newValue);
            }}
          />
        </FieldRow>

        <FieldRow>
          <DSCheckbox
            checked={form.values.countryEnabled}
            onChange={event => {
              form.setFieldValue('countryEnabled', event.target.checked);
            }}
          />
          Country
          <FieldRowMenu
            canEdit
            isMandatory={form.values.countryRequired}
            onEdit={newValue => {
              form.setFieldValue('countryRequired', newValue);
            }}
          />
        </FieldRow>

        {/* CUSTOM CANDIDATE FIELDS */}
        {form.values.customCandidateFields?.map((field, index) => (
          <FieldRow key={field.title}>
            <DSCheckbox
              checked={field.enabled}
              onChange={event => {
                form.setFieldValue(
                  `customCandidateFields[${index}].enabled`,
                  event.target.checked
                );
              }}
            />
            {field.title}
            <FieldRowMenu
              canEdit
              canDelete
              isMandatory={field.required}
              onEdit={newValue => {
                form.setFieldValue(
                  `customCandidateFields[${index}].required`,
                  newValue
                );
              }}
              onDelete={() =>
                handleDeleteCustomField('customCandidateFields', index)
              }
            />
          </FieldRow>
        ))}
      </div>

      <DSButton
        variant="tertiary"
        size="xsmall"
        Icon={<Icon icon={regular('plus')} />}
        onClick={() => {
          setOpenAddCustomFieldModal(true);

          amplitude?.logEvent('application form/add field', {
            'workspace id': workspaceId,
            'job opening id': jobOpening,
          });
        }}
      >
        Add field
      </DSButton>

      <AddCustomFieldModal
        open={openAddCustomFieldModal}
        onClose={() => setOpenAddCustomFieldModal(false)}
        onSave={handleAddCustomField}
      />
    </DSApplicationPage.Section>
  );
}
