import React from 'react';
import styled from '@emotion/styled';

import { SemanticButton } from '@/features/common';

interface ManualSyncButtonProps {
  onClick: () => void;
  isLoading?: boolean;
}

export function ManualSyncButton({
  onClick,
  isLoading,
}: ManualSyncButtonProps) {
  return (
    <SyncButton type="button" onClick={onClick} disabled={isLoading}>
      Import messages with this candidate from Gmail
    </SyncButton>
  );
}

const SyncButton = styled(SemanticButton)`
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.orange[120]};

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
