import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSInlineField,
  DSModal,
  DSRadio,
} from '@hundred5/design-system';

import { amplitude } from '@/features/amplitude';
import { BulkActionModal } from '@/features/bulk-actions/components/ui/bulk-action-modal';
import { BulkActionModalProps } from '@/features/bulk-actions/types';
import { TId } from '@/features/common';
import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { useJobOpeningId } from '@/hooks/router';

import { useBulkMoveToMutation } from '../queries';

interface BulkMoveToModalProps extends BulkActionModalProps {
  originCategoryId?: TId;
}

export const BulkMoveToModal = ({
  open,
  onClose,
  onActionDone,
  candidates,
  originCategoryId,
}: BulkMoveToModalProps) => {
  const jobOpeningId = useJobOpeningId();
  const [chosenCategoryId, setChosenCategoryId] = React.useState<TId | null>(
    null
  );

  const pipelineCategoriesQuery = usePipelineCategoriesQuery({ jobOpeningId });
  const bulkMoveToMutation = useBulkMoveToMutation({
    onSettled: () => {
      onActionDone?.();
    },
  });

  const handleSubmit = useCallback(() => {
    if (!chosenCategoryId) {
      return;
    }

    bulkMoveToMutation.mutate({
      candidateIds: candidates.map(candidate => candidate.id),
      jobOpeningId,
      targetCategoryId: chosenCategoryId,
      originCategoryId,
    });
  }, [
    bulkMoveToMutation,
    candidates,
    jobOpeningId,
    chosenCategoryId,
    originCategoryId,
  ]);

  return (
    <BulkActionModal open={open} onClose={onClose} style={{ width: 442 }}>
      <BulkActionModal.Header>
        <BulkActionModal.Title>Move to another step</BulkActionModal.Title>
        <DSModal.CloseButton onClick={onClose} fixed small />
      </BulkActionModal.Header>
      <BulkActionModal.Content>
        <CategoriesList>
          {pipelineCategoriesQuery.data?.map(category => {
            const isDisabled = category.id === originCategoryId;

            return (
              <li key={category.id}>
                <DSInlineField
                  disabled={isDisabled}
                  for={`category-${category.id}`}
                  label={category.name}
                  data-rh={
                    isDisabled
                      ? 'Candidates already in this stage cannot be moved to the same stage again. Please select a different stage for the selected candidates.'
                      : undefined
                  }
                >
                  <DSRadio
                    checked={chosenCategoryId === category.id}
                    disabled={isDisabled}
                    id={`category-${category.id}`}
                    name={`category-${category.id}`}
                    onChange={() => setChosenCategoryId(category.id)}
                  />
                </DSInlineField>
              </li>
            );
          })}
        </CategoriesList>
      </BulkActionModal.Content>

      <BulkActionModal.Footer>
        <DSButton
          onClick={() => {
            handleSubmit();
            amplitude?.logEvent('bulk actions/bulk action applied', {
              'user ids': candidates,
              'job opening id': jobOpeningId,
              'pipeline step': originCategoryId,
              'action type': 'move_to',
              'action value': chosenCategoryId,
              'action origin': jobOpeningId ? 'pipeline' : 'all candidates',
            });
          }}
          disabled={!chosenCategoryId}
        >
          Move
        </DSButton>
      </BulkActionModal.Footer>
    </BulkActionModal>
  );
};

const CategoriesList = styled.ul`
  display: flex;
  flex-flow: column;
  gap: 12px;
  list-style: none;
  margin-bottom: 8px;
  margin-top: 16px;
  padding: 0;
  align-items: flex-start;
`;
