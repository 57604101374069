import React, { useCallback } from 'react';
import { DSCheckbox, DSInlineField } from '@hundred5/design-system';

import {
  TFilterType,
  useCandidateSearchParams,
} from '@/features/candidate-search';

export const FIRST_COLUMN_FILTER_TYPE: TFilterType = 'firstColumn';
export function FirstColumnFilter() {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();

  const value = candidatesSearchParams[FIRST_COLUMN_FILTER_TYPE];

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        addSearchParams({ [FIRST_COLUMN_FILTER_TYPE]: 'yes' });
      } else {
        removeSearchParam(FIRST_COLUMN_FILTER_TYPE);
      }
    },
    [addSearchParams, removeSearchParam]
  );

  return (
    <DSInlineField for={FIRST_COLUMN_FILTER_TYPE} label="First column only">
      <DSCheckbox
        id={FIRST_COLUMN_FILTER_TYPE}
        onChange={handleChange}
        checked={value === 'yes'}
      />
    </DSInlineField>
  );
}
