import * as React from 'react';
import styled from '@emotion/styled';
import { Button } from '@hundred5/design-system';
import { capitalize, upperFirst } from 'lodash';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';

import {
  BillingPlan,
  useBillingPlansConfig,
  useSubscribeModal,
} from '@/features/billing';
import { useWorkspaceQuery } from '@/features/workspace';
import {
  getNextPlanName,
  getPlanLimit,
  getPlanName,
  useGetPlanRequired,
} from '@/hooks/planLimits';
import { useHistory, useWorkspaceId } from '@/hooks/router';

interface IProps {
  text: string;
  requiredPlan: BillingPlan;
}

const ReactHint = ReactHintFactory(React);

const UpsellTooltip = () => {
  const workspaceId = useWorkspaceId();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const { plans: planDescriptions } = useBillingPlansConfig();

  const customTestMinimalPlanCode =
    useGetPlanRequired('share_custom_test', 'annual') || 'premium-1m-v2-2023';
  const premiumTestMinimalPlanMonthlyCode =
    useGetPlanRequired('share_premium_template_test', 'annual') ||
    'premium-1m-v2-2023';

  const nextPlanName =
    (workspaceQuery.data?.subscriptionPlan &&
      getNextPlanName(getPlanName(workspaceQuery.data.subscriptionPlan))) ||
    'premium';

  const nextPlanCode =
    planDescriptions.find(
      planDescription => planDescription.plan === nextPlanName
    )?.planCode.annual || 'premium-1m-v2-2023';

  const planUnlockableCandidatesLimit =
    workspaceQuery.data?.subscriptionPlan &&
    getPlanLimit(
      workspaceQuery.data?.subscriptionPlan,
      'unlockable_candidates'
    );

  return (
    <>
      <ReactHint
        events
        persist
        attribute="data-upsell-tooltip"
        delay={{ show: 0, hide: 500 }}
        onRenderContent={() => {
          return (
            <Content
              text="Upgrade to start inviting candidates to this premium test."
              requiredPlan={premiumTestMinimalPlanMonthlyCode}
            />
          );
        }}
      />
      <ReactHint
        events
        persist
        attribute="data-upsell-tooltip-custom"
        delay={{ show: 0, hide: 500 }}
        onRenderContent={() => {
          return (
            <Content
              text={`Upgrade to ${upperFirst(
                getPlanName(customTestMinimalPlanCode)
              )} Plan to start inviting candidates to this custom test.`}
              requiredPlan={customTestMinimalPlanCode}
            />
          );
        }}
      />
      <ReactHint
        events
        persist
        attribute="data-unlock-candidate-upsell-tooltip"
        delay={{ show: 0, hide: 500 }}
        onRenderContent={() => {
          return (
            <Content
              text={`You have unlocked all ${planUnlockableCandidatesLimit} candidates. Upgrading to ${capitalize(
                nextPlanName
              )} Plan will unlock more candidates.`}
              requiredPlan={nextPlanCode}
            />
          );
        }}
      />
    </>
  );
};

const Content = ({ text, requiredPlan }: IProps) => {
  const history = useHistory();
  const { openSubscribeModal } = useSubscribeModal();
  const workspaceId = useWorkspaceId();
  return (
    <Container>
      {text}
      <UpgradeButton
        onClick={() => {
          history.push(`/admin/${workspaceId}/settings/billing`);
          openSubscribeModal(requiredPlan);
        }}
      >
        Upgrade
      </UpgradeButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 17px;
  width: 309px;
  height: 123px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.25);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
`;

const UpgradeButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
`;

export default UpsellTooltip;
