import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

export const DeleteButton = props => (
  <DeleteIcon icon={regular('xmark')} fontSize="10px" {...props} />
);

const DeleteIcon = styled(Icon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.purple[40]};

  &:hover {
    color: ${({ theme }) => theme.colors.purple[100]};
  }
`;
