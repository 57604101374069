import styled from '@emotion/styled';

export const RecommendedCandidateDecoration = styled.div`
  background: ${props => props.theme.colors.lavender[100]};
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
`;
