import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import formatDate from 'date-fns/format';

import { Icon } from '@/features/common';

import { DeleteButton } from '../../buttons';
import { FilterListItem } from '../filter-list-item';

const DATE_FORMAT = 'MMM D, YYYY';
export const DateListItem = ({
  date,
  onDelete,
}: {
  date?: string;
  onDelete: () => void;
}) => {
  if (!date) return null;

  const dates = date.split('--');
  const isSameDate = dates[0] === dates[1];

  if (isSameDate) {
    return (
      <FilterListItem>
        <Icon icon={solid('calendar-day')} color="orange-100" />
        {formatDate(dates[0], DATE_FORMAT)}
        <DeleteButton onClick={() => onDelete()} />
      </FilterListItem>
    );
  }

  return (
    <FilterListItem>
      <Icon icon={solid('calendar-day')} color="orange-100" />
      {formatDate(dates[0], DATE_FORMAT)} - {formatDate(dates[1], DATE_FORMAT)}
      <DeleteButton onClick={() => onDelete()} />
    </FilterListItem>
  );
};
