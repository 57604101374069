import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface LoaderProps {
  absolute?: boolean;
}

export function Loader({ absolute }: LoaderProps) {
  return (
    //TODO: probably move this to design system, it was copied from candidate-portal
    <LoaderContainer absolute={absolute}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={100}
        height={100}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <title>Loading</title>
        <circle
          cx={50}
          cy={50}
          fill="none"
          strokeWidth={10}
          r={35}
          strokeDasharray="164.93361431346415 56.97787143782138"
          strokeLinecap="round"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          />
        </circle>
      </svg>
      {absolute && <Background />}
    </LoaderContainer>
  );
}

const LoaderContainer = styled.div<{ absolute?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.absolute &&
    css`
      position: absolute;
      top: 0;
      left: 0;
    `}

  svg {
    display: block;
    z-index: 2;

    circle {
      stroke: ${props => props.theme.colors.purple[30]};
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
  opacity: 0.7;
`;
