import React from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSColumn,
  DSContentBlock,
  DSField,
  DSGrid,
  DSRow,
} from '@hundred5/design-system';

import { useCountryList } from '@/features/common';

import { isValidBillingInfo } from '../workspace-settings-billing-details.utils';

type SummaryProps = {
  billingInfoAttributes;
  onChangeClick: () => void;
};

export const BillingDetails: React.FC<SummaryProps> = ({
  billingInfoAttributes,
  onChangeClick,
}) => {
  const { getCountryName } = useCountryList();

  return (
    <DSContentBlock>
      <DSContentBlock.Title>Billing</DSContentBlock.Title>
      <DSContentBlock.Content>
        {isValidBillingInfo(billingInfoAttributes) ? (
          <DSGrid gap="12px">
            <DSRow gap="16px" xsWrap>
              <DSColumn size={12}>
                <DSField for="country" label="Country">
                  {billingInfoAttributes?.country &&
                    getCountryName(billingInfoAttributes.country)}
                </DSField>
              </DSColumn>
              <DSColumn size={12}>
                <DSField for="name" label="Contact Name">
                  {billingInfoAttributes?.firstName}{' '}
                  {billingInfoAttributes?.lastName}
                </DSField>
              </DSColumn>
            </DSRow>
            <DSRow gap="16px" xsWrap>
              <DSColumn size={12}>
                <DSField for="street" label="Street address">
                  {billingInfoAttributes?.address}{' '}
                  {billingInfoAttributes?.address2}
                </DSField>
              </DSColumn>
              <DSColumn size={12}>
                <DSField for="city" label="City">
                  {billingInfoAttributes?.city}
                </DSField>
              </DSColumn>
            </DSRow>

            <DSRow gap="16px" xsWrap>
              <DSColumn size={12}>
                <DSField for="postal" label="Postal code">
                  {billingInfoAttributes?.postalCode}
                </DSField>
              </DSColumn>
            </DSRow>
          </DSGrid>
        ) : (
          <EmptyState> No billing information available</EmptyState>
        )}
      </DSContentBlock.Content>
      <DSContentBlock.Footer>
        <DSButton variant="secondary" size="xsmall" onClick={onChangeClick}>
          {isValidBillingInfo(billingInfoAttributes)
            ? 'Edit details'
            : 'Add billing info'}
        </DSButton>
      </DSContentBlock.Footer>
    </DSContentBlock>
  );
};

const EmptyState = styled.div`
  color: ${props => props.theme.typography.colorSecondary};
`;
