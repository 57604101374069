import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  LinkButton,
  TextArea,
  TextInput,
} from '@hundred5/design-system';
import { Formik, FormikProps } from 'formik';

import Modal from '@/components/modal/beta/Modal';
import { TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import {
  IJobOpeningFeedback,
  useSendFeedbackMutation,
} from '@/features/job-opening';

import { RadioField, RadioIconField } from './ui';

const INITIAL_FORM_STATE: IJobOpeningFeedback = {
  reason: null,
  reasonComment: null,
  experience: null,
  experienceComment: null,
  useAgain: null,
  useAgainComment: null,
};

interface JobOpeningFeedbackModalProps {
  open: boolean;
  jobOpeningId: TId;
  onClose: () => void;
}

export const JobOpeningFeedbackModal = ({
  open = false,
  jobOpeningId,
  onClose,
}: JobOpeningFeedbackModalProps) => {
  const { showFlashMessage } = useFlashMessages();
  const mutation = useSendFeedbackMutation();

  const handleSubmit = useCallback(
    (
      values: IJobOpeningFeedback,
      formikBag: FormikProps<IJobOpeningFeedback>
    ) => {
      mutation.mutate(
        { jobOpeningId, feedback: values },
        {
          onSuccess: () => {
            formikBag.resetForm({ values: INITIAL_FORM_STATE });
            showFlashMessage({ type: 'feedback_thank_you' });
            onClose();
          },
        }
      );
    },
    [jobOpeningId, mutation, onClose, showFlashMessage]
  );

  return (
    <Formik<IJobOpeningFeedback>
      initialValues={INITIAL_FORM_STATE}
      onSubmit={handleSubmit}
      onReset={onClose}
    >
      {form => (
        <Modal fluid open={open} onClose={form.handleReset}>
          <Modal.CloseButton fixed onClick={form.handleReset} />
          <Form onSubmit={form.handleSubmit}>
            <Title>Help us improve even more</Title>

            <Modal.Separator />

            <Body>
              <Question>Why did you close this role?</Question>

              <RadioField
                name="reason"
                label={`I hired one of the candidates from Toggl Hire`}
                value="hired_from_h5"
                onChange={form.handleChange}
              />
              <RadioField
                name="reason"
                label="I hired a candidate from elsewhere"
                value="hired_elsewhere"
                onChange={form.handleChange}
              />
              <RadioField
                name="reason"
                label="Other"
                value="other"
                onChange={form.handleChange}
              />

              {form.values.reason === 'other' ? (
                <TextInput
                  name="reasonComment"
                  value={form.values.reasonComment || ''}
                  onChange={form.handleChange}
                />
              ) : null}

              <Question>How was your experience?</Question>

              <ExperienceFields>
                <RadioIconField
                  name="experience"
                  value="positive"
                  onChange={form.handleChange}
                />
                <RadioIconField
                  name="experience"
                  value="negative"
                  onChange={form.handleChange}
                />
              </ExperienceFields>

              {form.values.experience ? (
                <TextArea
                  name="experienceComment"
                  value={form.values.experienceComment || ''}
                  onChange={form.handleChange}
                  placeholder={
                    form.values.experience === 'positive'
                      ? 'What in particular did you like?'
                      : 'Why? What should we change?'
                  }
                />
              ) : null}

              <Question>
                Would you use Toggl Hire again to fill future jobs?
              </Question>

              <RadioField
                name="useAgain"
                label="Yes"
                value="yes"
                onChange={form.handleChange}
              />
              <RadioField
                name="useAgain"
                label="No"
                value="no"
                onChange={form.handleChange}
              />

              {form.values.useAgain === 'no' ? (
                <TextArea
                  name="useAgainComment"
                  value={form.values.useAgainComment || ''}
                  onChange={form.handleChange}
                  placeholder="We would appreciate if you could tell us why not?"
                />
              ) : null}
            </Body>

            <Modal.Footer>
              <ButtonsWrapper>
                <Button
                  type="submit"
                  disabled={
                    mutation.isLoading || INITIAL_FORM_STATE === form.values
                  }
                >
                  {mutation.isLoading ? 'Sending...' : 'Send'}
                </Button>

                <LinkButton
                  variant="secondary"
                  onClick={form.handleReset}
                  type="reset"
                >
                  Cancel
                </LinkButton>
              </ButtonsWrapper>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

const Form = styled.form`
  width: 400px;
`;

const Title = styled.h3`
  margin: 0 20px 10px;
`;

const Body = styled.div`
  margin: 15px 20px;
`;

const Question = styled.div`
  font-weight: bold;
  margin: 10px 0;
`;

const ExperienceFields = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 20px;
`;
