import differenceInSeconds from 'date-fns/difference_in_seconds';
import formatDate from 'date-fns/format';

import { DateRange } from '@/types/time';

export function formatInterval(startDate: Date, endDate: Date): string {
  const diffInSeconds = differenceInSeconds(endDate, startDate);

  const hours = Math.floor(diffInSeconds / 3600);
  const minutes = Math.floor(diffInSeconds / 60) % 60;
  const seconds = Math.floor(diffInSeconds % 60);

  const hoursString = hours ? `${hours}h ` : '';
  const minutesString = minutes ? `${minutes}min ` : '';
  const secondsString = seconds ? `${seconds}s` : '';

  return hoursString + minutesString + secondsString;
}

export function serializeRange(range: DateRange) {
  return {
    'start-date': formatDate(range.start, 'YYYY-MM-DD'),
    'end-date': formatDate(range.end, 'YYYY-MM-DD'),
    'time-zone': formatDate(range.start, 'Z'),
  };
}
