import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { TSortDirection, Icon } from '@/features/common';

interface ISortDirection {
  direction: TSortDirection;
  fontSize?: number;
}

export const SortDirection = ({ direction, fontSize = 14 }: ISortDirection) => {
  return (
    <IconWrapper>
      {direction === 'asc' ? (
        <Icon icon={regular('arrow-up')} fontSize={fontSize} />
      ) : (
        <Icon icon={regular('arrow-down')} fontSize={fontSize} />
      )}
    </IconWrapper>
  );
};

const IconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
