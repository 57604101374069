import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import {
  DSButton,
  DSInlineField,
  DSModal,
  DSRadio,
} from '@hundred5/design-system';

import { DownloadModal } from '@/features/common';
import {
  CANDIDATES_EXPORT_FILTERS,
  fetchUrl,
} from '@/features/job-opening/components/job-opening-page-header/ui/candidates-export-modal/candidates-export-modal.utils';
import { useApiError } from '@/hooks/api';
import { useAsync } from '@/hooks/async';
import { Id } from '@/types/misc';
import { CandidateFilter } from '@/types/reports';

import { CandidatesExportModalState } from './candidates-export-modal.types';

interface CandidatesExportModalProps extends CandidatesExportModalState {
  jobOpeningId: Id;
  onClose: () => void;
}

export function CandidatesExportModal({
  open,
  jobOpeningId,
  format,
  onClose,
}: CandidatesExportModalProps) {
  const [selectedFilter, setSelectedFilter] = useState<CandidateFilter>('all');
  const [downloadData, setDownloadData] = useState<{
    open: boolean;
    url: string | null;
    filename?: string;
  }>({ open: false, url: null });
  const [hookedFetchUrl, { loading }] = useAsync(fetchUrl);
  const handleApiError = useApiError();

  const onDownload = async (filter: CandidateFilter) => {
    if (!jobOpeningId || !format) return false;

    try {
      const url = await hookedFetchUrl(jobOpeningId, filter, format);
      setDownloadData({
        open: true,
        url: url,
        filename:
          format === 'csv'
            ? 'export.csv'
            : format === 'pdf'
            ? 'export.zip'
            : undefined,
      });

      amplitude?.logEvent('candidates/export candidates', {
        'job opening id': jobOpeningId,
        format,
        filter: filter,
      });
    } catch (error) {
      handleApiError(error);
      return;
    } finally {
      onClose();
    }
  };

  return (
    <>
      <DSModal
        fluid
        open={open}
        onClose={onClose}
        layer={10000}
        contentStyle={{ width: '400px' }}
      >
        <ExportModalTitle>Which list to download?</ExportModalTitle>
        <DSModal.Separator />

        <ExportModalFilterOptions>
          {CANDIDATES_EXPORT_FILTERS.map(({ filter, label }) => (
            <DSInlineField
              for={`candidates-page-export-modal-${filter}`}
              label={label}
              key={filter}
            >
              <DSRadio
                type="radio"
                id={`candidates-page-export-modal-${filter}`}
                checked={selectedFilter === filter}
                onChange={() => setSelectedFilter(filter)}
              />
            </DSInlineField>
          ))}
        </ExportModalFilterOptions>

        <DSModal.Separator />
        <Footer>
          <CandidatesExportModalButtons>
            <DSButton
              variant="primary"
              loading={loading}
              onClick={() => onDownload(selectedFilter)}
            >
              {loading ? 'Preparing...' : 'Download'}
            </DSButton>
            <DSButton variant="tertiary" onClick={onClose}>
              Cancel
            </DSButton>
          </CandidatesExportModalButtons>
        </Footer>
      </DSModal>

      <DownloadModal
        onClose={() =>
          setDownloadData({
            ...downloadData,
            open: false,
          })
        }
        open={downloadData.open}
        url={downloadData.url!}
      />
    </>
  );
}

const ExportModalTitle = styled.h3`
  margin: 0 0 16px;
`;

const ExportModalFilterOptions = styled.div`
  margin: 24px 0;

  display: grid;
  gap: 8px;
`;

const CandidatesExportModalButtons = styled.div`
  display: flex;
  gap: 12px;
`;

const Footer = styled(DSModal.Footer)`
  padding-top: 24px;
`;
