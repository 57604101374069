import React from 'react';
import styled from '@emotion/styled';

import { SemanticButton } from '@/features/common';

interface OptionProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  onSelect: () => void;
}

export function Option({ icon, title, description, onSelect }: OptionProps) {
  return (
    <OptionContainer onClick={onSelect}>
      {icon}
      <Title>{title}</Title>
      <Description>{description}</Description>
    </OptionContainer>
  );
}

const OptionContainer = styled(SemanticButton)`
  border: 1px solid ${({ theme }) => theme.colors.purple[40]};
  background: ${({ theme }) => theme.colors.white};
  width: 210px;
  display: flex;
  padding: 36px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: background 0.1s, transform 0.1s;

  &:active {
    transform: scale(0.96);
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorPrimary};
`;

const Description = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorSecondary};
`;
