import qs from 'qs';

import api from '@/api/api';
import { TId } from '@/features/common';
import {
  IFeedbackResponse,
  IFeedbackStats,
  IPublicFeedbackStats,
} from '@/features/job-opening';
import { parseAPIData } from '@/utils/parsers';

export async function fetchJobOpeningFeedback(params: {
  jobOpeningId: TId;
  pageSize?: number;
  page?: number;
}): Promise<IFeedbackResponse> {
  const pageSize = params.pageSize || 5;
  const page = params.page || 0;

  const queryString = qs.stringify({
    offset: page * pageSize,
    limit: pageSize,
  });

  const response = await api.get(
    `/openings/${params.jobOpeningId}/feedback?${queryString}`
  );
  return parseAPIData<IFeedbackResponse>(response.data);
}

export async function fetchPublicFeedback(params: {
  pageSize?: number;
  page?: number;
}): Promise<IFeedbackResponse> {
  const pageSize = params.pageSize || 5;
  const page = params.page || 0;

  const queryString = qs.stringify({
    offset: page * pageSize,
    limit: pageSize,
  });

  const response = await api.get(`/public/feedback?${queryString}`);
  return parseAPIData<IFeedbackResponse>(response.data);
}

export async function fetchJobOpeningFeedbackStats(params: {
  jobOpeningId: TId;
}): Promise<IFeedbackStats> {
  const response = await api.get(
    `/openings/${params.jobOpeningId}/feedback/stats`
  );
  return parseAPIData<IFeedbackStats>(response.data);
}

export async function fetchPublicFeedbackStats(): Promise<
  IPublicFeedbackStats
> {
  const response = await api.get('/public/feedback/stats');
  return parseAPIData<IPublicFeedbackStats>(response.data);
}
