import React from 'react';
import { DSField, DSInput } from '@hundred5/design-system';
import { useField } from 'formik';

import { getFieldId } from '../card-token-form.utils';

export const RegularField = ({
  name,
  label,
  placeholder,
  required,
  hasRecurlyError,
}: {
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
  hasRecurlyError: boolean;
}) => {
  const [field, meta] = useField(name);
  const errorMessage = hasRecurlyError
    ? 'Invalid input'
    : meta.touched && !!meta.error
    ? meta.error
    : null;

  return (
    <DSField
      for={getFieldId(name)}
      label={label}
      required={required}
      error={errorMessage}
    >
      <DSInput
        id={getFieldId(name)}
        type="text"
        error={!!errorMessage}
        data-recurly={name}
        placeholder={placeholder}
        {...field}
      />
    </DSField>
  );
};
