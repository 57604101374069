import * as amplitude from '@amplitude/analytics-browser';

import configuration from '@/configuration/runtime';

if (configuration.amplitudeApiKey) {
  amplitude.init(configuration.amplitudeApiKey, {
    defaultTracking: true,
    minIdLength: 1, // amplitude prefers non-sequential ids and default here is like 5
  });
}

export { amplitude };
