import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { Formik, FormikHelpers } from 'formik';
import qs from 'qs';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import configuration from '@/configuration/runtime';
import { checkClientError } from '@/errors';
import { FormikInputField } from '@/features/common';
import { trackLogin } from '@/googleTagManager';
import { useApiError } from '@/hooks/api';
import { useHistory } from '@/hooks/router';

import { LinkedInButton } from '..';
import { useLogin } from '../../hooks';
import { linkedInLogin } from '../../utils';

const initialValues = {
  email: '',
  password: '',
};

type Values = typeof initialValues;

type LoginError =
  | 'invalid_email_or_password'
  | 'account_suspended'
  | 'user_not_found';

export function LoginForm() {
  const logIn = useLogin();
  const history = useHistory();
  const handleApiError = useApiError();
  const [error, setError] = useState<LoginError | null>(null);
  const { error: urlError } = qs.parse(history.location.search.slice(1)) as {
    error: string;
  };

  React.useEffect(() => {
    if (urlError === 'UserNotFound') {
      setError('user_not_found');
    }
  }, [urlError]);

  const handleSubmit = async (values: Values, form: FormikHelpers<Values>) => {
    try {
      await logIn({
        email: values.email,
        password: values.password,
      });
    } catch (error) {
      if (checkClientError(error, 'InvalidEmailOrPassword')) {
        setError('invalid_email_or_password');
      } else if (checkClientError(error, 'AccountSuspended')) {
        setError('account_suspended');
      } else {
        handleApiError(error);
      }

      form.setSubmitting(false);
      return;
    }

    trackLogin();
  };

  return (
    <LoginFormContainer>
      {error != null ? (
        <LoginErrorWrapper>
          {error === 'invalid_email_or_password' ? (
            <LoginErrorMessage>
              Sorry, you entered an incorrect email or password.
            </LoginErrorMessage>
          ) : error === 'account_suspended' ? (
            <LoginErrorMessage>
              Sorry, your account has been suspended. Please contact us at{' '}
              <a href={`mailto:${configuration.supportEmail}`}>
                {configuration.supportEmail}
              </a>
              .
            </LoginErrorMessage>
          ) : error === 'user_not_found' ? (
            <LoginErrorMessage>
              There is no user associated with this LinkedIn account. Please{' '}
              <Link to="/admin/signup">create an account</Link> first.
            </LoginErrorMessage>
          ) : null}
        </LoginErrorWrapper>
      ) : null}
      <LoginWithLinkedIn onClick={linkedInLogin} />
      <LoginFormSeparator>
        Or, log in with your business email
      </LoginFormSeparator>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={schema}
      >
        {form => (
          <form onSubmit={form.handleSubmit} data-recording-ignore="mask">
            <LoginFormField
              type="email"
              name="email"
              id="login-form-email"
              label="Business email"
            />
            <LoginFormField
              type="password"
              name="password"
              id="login-form-password"
              label="Password"
            />
            <LoginFormButton type="submit" disabled={form.isSubmitting}>
              Log in
            </LoginFormButton>
          </form>
        )}
      </Formik>
    </LoginFormContainer>
  );
}

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Business email cannot be empty')
    .email('Enter a valid email'),
  password: yup.string().required('Password cannot be empty'),
});

const LoginFormContainer = styled.div`
  margin-top: 36px;
`;

const LoginErrorWrapper = styled.div`
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: ${props => props.theme.colors.orange[10]};
  border-radius: 4px;
`;

const LoginErrorMessage = styled.p`
  line-height: 21px;
  color: ${props => props.theme.typography.colorError};
`;

const LoginWithLinkedIn = styled(LinkedInButton)`
  margin: 0 auto;
`;

const LoginFormSeparator = styled.div`
  width: 100%;
  margin: 24px 0;
  font-size: 10px;
  display: inline-flex;
  align-items: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${props => props.theme.colors.peach[10]};
  }

  &::before {
    margin-right: 0.9em;
  }

  &::after {
    margin-left: 0.9em;
  }

  @media screen and (max-height: 750px) and (min-width: 490px) {
    margin-top: 17px;
    margin-bottom: 17px;
  }
`;

const LoginFormField = styled(FormikInputField)`
  &:not(:first-of-type) {
    margin-top: 12px;
  }

  @media screen and (max-height: 750px) and (min-width: 490px) {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
`;

const LoginFormButton = styled(DSButton)`
  width: 100%;
  margin-top: 28px;

  @media screen and (max-height: 750px) and (min-width: 490px) {
    margin-top: 17px;
  }
`;
