import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { useImageUpload } from '@/hooks/images';
import { ImagePurpose } from '@/types/image';

export type ImageUploaderProps = {
  reUpload: boolean;
  onUploaded: (imageUrl: string) => void;
  onStarted?: () => void;
  onFailed?: () => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  type: ImagePurpose;
  user?: boolean;
  uploadLabel?: string;
  reUploadLabel?: string;
  uploadingLabel?: string;
  className?: string;
  disabled?: boolean;
};

export function ImageUploader({
  type,
  user = false,
  onStarted,
  onFailed,
  onUploaded,
  reUpload,
  onClick,
  uploadLabel = 'Upload',
  reUploadLabel = 'Re-upload',
  uploadingLabel = 'Uploading...',
  disabled,
}: ImageUploaderProps) {
  const { uploadImage, uploadingImage } = useImageUpload();
  const handleFileChange = useCallback(
    event => {
      const files = event.target.files!;

      if (files.length > 0) {
        uploadImage(files[0], type, user).then(([error, uploadedImage]) => {
          uploadedImage && onUploaded(uploadedImage);

          if (error && onFailed) {
            onFailed();
          }
        });

        if (onStarted) {
          onStarted();
        }
      }
    },
    [onFailed, onStarted, onUploaded, type, uploadImage, user]
  );

  return (
    <ImageUploaderWrapper>
      <span className="image-upload__label" onClick={onClick}>
        {uploadingImage
          ? uploadingLabel
          : reUpload
          ? reUploadLabel
          : uploadLabel}
      </span>
      {!disabled && (
        <ImageUploadInput type="file" onChange={handleFileChange} />
      )}
    </ImageUploaderWrapper>
  );
}

const ImageUploaderWrapper = styled.label`
  width: 100%;
  height: 100%;
  line-height: 32px;
  text-align: center;

  display: block;
  position: relative;
  cursor: pointer;
`;

const ImageUploadInput = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
`;
