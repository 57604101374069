import styled from '@emotion/styled';
import { DSContextMenu } from '@hundred5/design-system';

export const CustomContextMenu = styled(DSContextMenu)`
  overflow: visible;
  filter: drop-shadow(${props => props.theme.shadow.popup});
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: -15px;
    border: 8px solid transparent;
    border-bottom-color: ${({ theme }) => theme.colors.white};
    z-index: 100000;
  }
`;
