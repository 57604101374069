import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSContentBlock, DSGrid } from '@hundred5/design-system';
import formatDate from 'date-fns/format';

import { ConfirmModal, Icon } from '@/features/common';
import {
  ISlackIntegration,
  SlackIcon,
  useAddSlackIntegrationMutation,
  useDeleteSlackIntegrationMutation,
  useSlackIntegrationsQuery,
} from '@/features/integrations';
import { useWorkspacePermission } from '@/features/permissions';
import { isDemoWorkspace, useWorkspace } from '@/features/workspace';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

import {
  WorkspaceSettingsIntegrationAction,
  WorkspaceSettingsIntegrationContainer,
  WorkspaceSettingsIntegrationDescription,
  WorkspaceSettingsIntegrationIcon,
  WorkspaceSettingsIntegrationTable,
  WorkspaceSettingsIntegrationTitle,
} from '../workspace-settings-integrations-page';

export const WorkspaceSettingsIntegrationSlack = () => {
  return (
    <DSContentBlock>
      <DSContentBlock.Title>Slack Integration</DSContentBlock.Title>
      <DSContentBlock.Content>
        <DSGrid gap="16px">
          <SlackElement />
        </DSGrid>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const SlackElement = () => {
  const workspace = useWorkspace();
  const { openUpsellModal } = useUpsellModal();
  const canAccessFeature = usePlanLimits();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [
    selectedSlackIntegration,
    setSelectedSlackIntegration,
  ] = useState<ISlackIntegration | null>(null);
  const { data: slackIntegrations } = useSlackIntegrationsQuery({
    enabled: !isDemoWorkspace(workspace),
  });

  const addSlackIntegrationMutation = useAddSlackIntegrationMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });
  const deleteSlackIntegrationMutation = useDeleteSlackIntegrationMutation();
  const canDeleteSlackIntegration = useWorkspacePermission()(
    'slack integration',
    'delete'
  );

  return (
    <>
      <WorkspaceSettingsIntegrationContainer>
        <WorkspaceSettingsIntegrationIcon>
          <SlackIcon />
        </WorkspaceSettingsIntegrationIcon>
        <IntegrationInfo>
          <WorkspaceSettingsIntegrationTitle>
            Slack integration
          </WorkspaceSettingsIntegrationTitle>
          <WorkspaceSettingsIntegrationDescription>
            Be notified about new candidates who score above the threshold when
            they take your Toggl Hire skills test.
          </WorkspaceSettingsIntegrationDescription>
        </IntegrationInfo>
        <WorkspaceSettingsIntegrationAction>
          <DSButton
            variant="secondary"
            size="big"
            disabled={addSlackIntegrationMutation.isLoading}
            onClick={() =>
              canAccessFeature('slack_integration')
                ? addSlackIntegrationMutation.mutate()
                : openUpsellModal('premium_feature')
            }
          >
            Add more
          </DSButton>
        </WorkspaceSettingsIntegrationAction>
      </WorkspaceSettingsIntegrationContainer>
      {slackIntegrations?.length !== 0 && (
        <WorkspaceSettingsIntegrationTable>
          <thead>
            <tr>
              <th>Channel</th>
              <th></th>
              <th></th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {slackIntegrations?.map((slackIntegration: ISlackIntegration) => (
              <tr key={slackIntegration.id}>
                <td>{slackIntegration.channelName}</td>
                <td></td>
                <td></td>
                <td>{formatDate(slackIntegration.addedAt, 'MMM D, YYYY')}</td>
                <td>
                  <DSButton
                    variant="tertiary"
                    size="small"
                    onClick={() => {
                      setSelectedSlackIntegration(slackIntegration);
                      setOpenConfirmModal(true);
                    }}
                    Icon={<Icon icon={regular('trash-alt')} />}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </WorkspaceSettingsIntegrationTable>
      )}
      <ConfirmModal
        open={openConfirmModal}
        action="Disconnect Slack channel"
        onConfirm={() => {
          canDeleteSlackIntegration &&
            !deleteSlackIntegrationMutation.isLoading &&
            selectedSlackIntegration &&
            deleteSlackIntegrationMutation.mutate({
              slackIntegrationId: selectedSlackIntegration.id,
            });
          setOpenConfirmModal(false);
        }}
        onClose={() => setOpenConfirmModal(false)}
        title="Disconnect Slack channel"
        footerVariant="destructive-primary"
      >
        You will no longer receive notifications about candidates to your Slack
        channel {selectedSlackIntegration?.channelName}
      </ConfirmModal>
    </>
  );
};

const IntegrationInfo = styled.div``;
