import React, { useState } from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import Markdown from '@/components/markdown/Markdown';
import {
  createEditorState,
  getEditorMarkdown,
} from '@/features/common/components/custom-text-editor';
import { QuestionNotesContainer } from '@/features/test/components/ui';

interface QuestionNotesProps {
  notes?: string;
}

export function QuestionNotes({ notes }: QuestionNotesProps) {
  const [expanded, setExpanded] = useState<boolean>(false);

  if (!notes) {
    return null;
  }

  return (
    <QuestionNotesContainer.Wrapper expanded={expanded} variant="dark">
      <QuestionNotesContainer.Heading
        onClick={() => setExpanded(prevState => !prevState)}
      >
        Evaluation notes
        <QuestionNotesContainer.Arrow
          up={expanded}
          icon={regular('chevron-down')}
          color="purple-60"
          fontSize="12px"
        />
      </QuestionNotesContainer.Heading>
      <QuestionNotesContainer.ContentWrapper>
        <QuestionNotesContainer.Content>
          <Markdown source={getEditorMarkdown(createEditorState(notes))} />
        </QuestionNotesContainer.Content>
      </QuestionNotesContainer.ContentWrapper>
    </QuestionNotesContainer.Wrapper>
  );
}
