import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSContextMenu, DSContextMenuButton } from '@hundred5/design-system';
import { useHistory } from 'react-router';
import useOnClickOutside from 'use-onclickoutside';

import { Icon, TId } from '@/features/common';
import { DeleteInterviewModal } from '@/features/interview';
import { useJobOpeningPermission } from '@/features/permissions';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

interface ContextMenuProps {
  interviewId: TId;
}

export function InterviewPageContextMenu({ interviewId }: ContextMenuProps) {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const canDelete = useJobOpeningPermission()('test', 'delete');

  const ref = useRef<null | HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  useOnClickOutside(ref, () => setMenuOpen(false));

  return canDelete ? (
    <ContextMenuContainer ref={ref}>
      <DSContextMenuButton
        active={menuOpen}
        onClick={() => setMenuOpen(!menuOpen)}
      />
      <DSContextMenu open={menuOpen} position={{ top: '32px', right: '0' }}>
        <DSContextMenu.Item
          label="Delete interview"
          icon={<Icon icon={regular('trash')} color="purple-60" />}
          onClick={() => {
            setDeleteModalOpen(true);
            setMenuOpen(false);
          }}
        />
      </DSContextMenu>

      <DeleteInterviewModal
        interviewId={interviewId}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onDeleteSuccess={() => {
          setDeleteModalOpen(false);
          history.push(
            `/admin/${workspaceId}/openings/${jobOpeningId}/pipeline`
          );
        }}
      />
    </ContextMenuContainer>
  ) : null;
}

const ContextMenuContainer = styled.div`
  position: relative;
`;
