import React from 'react';
import styled from '@emotion/styled';

export function Feedback() {
  return (
    <Container>
      <p>
        Have feedback about the beta?{' '}
        <a
          href="https://forms.gle/MvLUyuPEMAzHDNbw8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Let us know
        </a>
      </p>
    </Container>
  );
}

const Container = styled.section`
  padding: 12px;
  margin-top: auto;

  p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    color: ${props => props.theme.colors.purple[90]};
  }

  a {
    font-size: 12px;
    font-weight: 400;
    color: ${props => props.theme.colors.purple[90]};
    text-decoration: underline;
  }
`;
