import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { Form, Formik } from 'formik';

import { PromptIfDirty } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import {
  TJobOpeningApplicationFormFields,
  useJobOpeningApplicationFormQuery,
  useUpdateJobOpeningApplicationForm,
} from '@/features/job-opening';
import { useJobOpeningPermission } from '@/features/permissions';

import { ApplicationFields, CandidateFields } from './ui';

export function JobOpeningApplicationFormFields() {
  const { showFlashMessage } = useFlashMessages();
  const canUpdate = useJobOpeningPermission()('job opening', 'update');

  const { data: applicationForm } = useJobOpeningApplicationFormQuery();
  const updateApplicationForm = useUpdateJobOpeningApplicationForm();

  const initialValues: TJobOpeningApplicationFormFields = {
    linkedinEnabled: applicationForm?.linkedinEnabled ?? true,
    linkedinRequired: applicationForm?.linkedinRequired ?? false,
    githubEnabled: applicationForm?.githubEnabled ?? false,
    githubRequired: applicationForm?.githubRequired ?? false,
    cityEnabled: applicationForm?.cityEnabled ?? false,
    cityRequired: applicationForm?.cityRequired ?? false,
    countryEnabled: applicationForm?.countryEnabled ?? true,
    countryRequired: applicationForm?.countryRequired ?? false,

    cvEnabled: applicationForm?.cvEnabled ?? true,
    cvRequired: applicationForm?.cvRequired ?? false,
    coverLetterEnabled: applicationForm?.coverLetterEnabled ?? true,
    coverLetterRequired: applicationForm?.coverLetterRequired ?? false,
    portfolioEnabled: applicationForm?.portfolioEnabled ?? false,
    portfolioRequired: applicationForm?.portfolioRequired ?? false,

    customCandidateFields: applicationForm?.customCandidateFields ?? [],
  };

  const handleSubmit = async (values: TJobOpeningApplicationFormFields) => {
    const updatedApplicationForm = {
      ...applicationForm,
      ...values,
    };

    await updateApplicationForm.mutateAsync({
      applicationForm: updatedApplicationForm,
    });

    showFlashMessage({ type: 'saved' });
  };

  return (
    <Formik<TJobOpeningApplicationFormFields>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ dirty, isSubmitting }) => (
        <StyledForm>
          <PromptIfDirty />

          <CandidateFields />
          <ApplicationFields />

          <SaveButton
            type="submit"
            disabled={!canUpdate || !dirty || isSubmitting}
            data-rh={!canUpdate ? 'Only admins can save changes' : null}
          >
            {isSubmitting ? 'Saving...' : 'Save changes'}
          </SaveButton>
        </StyledForm>
      )}
    </Formik>
  );
}

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const SaveButton = styled(DSButton)`
  float: right;
`;
