import React from 'react';
import styled from '@emotion/styled';

import { TogglLogo } from '../toggl-logo/toggl-logo';

export function Forbidden() {
  return (
    <ForbiddenContainer>
      <LogoContainer>
        <Link
          href="https://toggl.com/hire/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TogglLogo />
        </Link>
      </LogoContainer>
      <Content>
        <Title>Access Denied</Title>
        <Text>You need to be invited to this workspace for access.</Text>
      </Content>
    </ForbiddenContainer>
  );
}

const ForbiddenContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LogoContainer = styled.div`
  padding: 32px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Link = styled.a`
  display: inline-block;
`;

const Content = styled.div`
  width: 520px;
  padding: 78px 70px 100px;
  margin-left: auto;
  margin-right: auto;
  background: ${props => props.theme.colors.white};
  border-radius: 16px;
  box-shadow: 0px 1px 4px rgba(44, 19, 56, 0.1);
  text-align: center;

  @media (max-width: 768px) {
    width: auto;
    margin: 15px;
  }
`;

const Title = styled.h3`
  margin: auto;
  font-weight: 700;
  font-size: 22px;
  color: ${props => props.theme.typography.colorPrimary};
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-size: 14px;
  color: ${props => props.theme.typography.colorPrimary};
`;
