import styled from '@emotion/styled';

export const CategoryTotalCandidates = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.purple[100]};
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  min-width: 20px;
  justify-content: center;
  line-height: 18px;
  padding: 0 8px;
`;
