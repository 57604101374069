import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  ICandidateAttachment,
  useDownloadCandidateAttachmentQuery,
} from '@/features/candidate';
import { DownloadModal, Icon, SemanticButton } from '@/features/common';

interface DownloadAttachmentButtonProps {
  attachment: ICandidateAttachment;
}

export function DownloadAttachmentButton({
  attachment,
}: DownloadAttachmentButtonProps) {
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const query = useDownloadCandidateAttachmentQuery(
    { attachmentId: attachment.id },
    { enabled: false }
  );

  return (
    <>
      <Button
        onClick={() => {
          if (!query.isFetching)
            query.refetch().then(() => setDownloadModalOpen(true));
        }}
      >
        <Icon icon={regular('download')} />
      </Button>

      <DownloadModal
        onClose={() => setDownloadModalOpen(false)}
        open={downloadModalOpen}
        url={query.data?.url!}
      />
    </>
  );
}

const Button = styled(SemanticButton)`
  margin-left: auto;
  padding: 4px 8px;
  transform: scale(1);
  transition: background 0.1s, transform 0.1s;

  &:hover {
    background: ${props => props.theme.colors.white};
  }

  &:active {
    transform: scale(0.96);
  }
`;
