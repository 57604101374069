import * as React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';

import {
  CardForm,
  CardFormLayout,
  useRemoveBillingInfoMutation,
} from '@/features/billing';
import { Alert } from '@/features/common';
import { useWorkspaceId } from '@/hooks/router';

type Props = {
  open: boolean;
  onCancel: () => void;
  onFinish: () => void;
};

export const UpdateCardFormModal: React.FC<Props> = ({
  onCancel,
  onFinish,
  open,
}) => {
  const workspaceId = useWorkspaceId();
  const {
    mutate: removeBillingInfo,
    isLoading: isRemovingBillingInfo,
  } = useRemoveBillingInfoMutation();

  return (
    <DSModal fluid contentStyle={{ width: 640 }} open={open} onClose={onCancel}>
      <div data-testid="update-card-form-modal">
        <Header>
          Edit payment details{' '}
          <DSModal.CloseButton onClick={onCancel} fixed small />
        </Header>
        <DSModal.Separator />

        <CardForm
          workspaceId={workspaceId}
          onFinish={onFinish}
          renderCardForm={cardForm => (
            <>
              <Content data-recording-ignore="mask">
                {cardForm.errorMessage ? (
                  <Error>
                    <Alert type="error">{cardForm.errorMessage}</Alert>
                  </Error>
                ) : null}

                <CardFormLayout
                  fields={cardForm.fields}
                  recurlyLoadFailed={cardForm.recurlyLoadFailed}
                  recurlyHostedPage={cardForm.recurlyHostedPage}
                />
              </Content>

              <Footer>
                <DSButton
                  variant="tertiary"
                  disabled={isRemovingBillingInfo}
                  onClick={() => {
                    removeBillingInfo();
                  }}
                >
                  {!isRemovingBillingInfo ? 'Remove card' : 'Removing card...'}
                </DSButton>
                <DSButton
                  type="submit"
                  variant="primary"
                  disabled={cardForm.isSubmitting}
                >
                  {!cardForm.isSubmitting ? 'Save changes' : 'Saving...'}
                </DSButton>
              </Footer>
            </>
          )}
          renderChallenge={challenge => (
            <>
              <Content>{challenge}</Content>
              <Footer>
                <DSButton type="button" variant="primary" onClick={onCancel}>
                  Cancel
                </DSButton>
              </Footer>
            </>
          )}
        />
      </div>
    </DSModal>
  );
};

const Header = styled.div`
  font-weight: 700;
  font-size: ${16 / 14}rem;
  color: ${props => props.theme.typography.colorPrimary};
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin-top: 16px;
`;

const Error = styled.div`
  margin-bottom: 20px;
`;

const Footer = styled(DSModal.Footer)`
  gap: 8px;
`;
