import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useCandidateSearchParams } from '@/features/candidate-search/hooks';
import { Icon } from '@/features/common';

import { DeleteButton } from '../../buttons';
import { SCORE_FILTER_TYPE } from '../../score-filter/score-filter';
import { FilterListItem } from '../filter-list-item';

export const ScoreListItem = () => {
  const { searchParams, removeSearchParam } = useCandidateSearchParams();
  const { score } = searchParams;

  if (!score) return null;

  return (
    <FilterListItem>
      <Icon icon={solid('circle-check')} color="orange-100" />
      {score === 'over_threshold' ? 'Over Threshold' : `Over ${score}%`}
      <DeleteButton onClick={() => removeSearchParam(SCORE_FILTER_TYPE)} />
    </FilterListItem>
  );
};
