import api from '@/api/api';
import { ICandidateEmailsResult } from '@/features/candidate';
import { TId } from '@/features/common';
import { parseAPIData } from '@/utils/parsers';

export async function fetchCandidateEmails(params: {
  candidateId: string;
  limit: number;
  offset: number;
}): Promise<ICandidateEmailsResult> {
  const response = await api.get(`/test-takers/${params.candidateId}/emails`, {
    params: {
      limit: params.limit,
      offset: params.offset,
    },
  });

  return parseAPIData(response.data);
}

export async function syncGmailEmails(params: {
  candidateId: TId;
}): Promise<void> {
  await api.post(`/test-takers/${params.candidateId}/emails/sync`);
}

export async function sendGmailEmail(params: {
  candidateId: TId;
  subject: string;
  message: string;
  cc?: string[];
  bcc?: string[];
}): Promise<void> {
  await api.post(`/test-takers/${params.candidateId}/emails`, {
    subject: params.subject,
    message: params.message,
    cc: formatRecipients(params.cc),
    bcc: formatRecipients(params.bcc),
  });
}

export async function sendGmailEmailReply(params: {
  candidateId: TId;
  emailId: TId;
  subject: string;
  message: string;
  cc?: string[];
  bcc?: string[];
}): Promise<void> {
  await api.post(
    `/test-takers/${params.candidateId}/emails/${params.emailId}/reply`,
    {
      subject: params.subject,
      message: params.message,
      cc: formatRecipients(params.cc),
      bcc: formatRecipients(params.bcc),
    }
  );
}

const formatRecipients = (recipients?: string[]) =>
  recipients?.map(item => ({ email: item, name: '' }));

export async function deleteGmailEmail(params: {
  candidateId: TId;
  emailId: TId;
}): Promise<void> {
  await api.delete(
    `/test-takers/${params.candidateId}/emails/${params.emailId}`
  );
}
