import { useCallback } from 'react';

import { TId } from '@/features/common';
import {
  IQuestion,
  useUpdateQuestionMutation,
  useUpdateQuestionPropertiesMutation,
} from '@/features/questions';
import {
  IQuestionPropertiesUpdateParams,
  IQuestionUpdateAPIPayload,
} from '@/features/questions/api';
import { isQuestionLimitExceededError } from '@/features/test/utils/question-limit-exceeded';
import { useApiError } from '@/hooks/api';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

export const useUpdateQuestion = (testId: TId) => {
  const canAccessFeature = usePlanLimits();
  const handleApiError = useApiError();
  const { openUpsellModal } = useUpsellModal();
  const updateQuestionMutation = useUpdateQuestionMutation({
    testId,
  });

  const updateQuestionProperty = useCallback(
    async ({
      question,
      key,
      value,
      onUpdate,
    }: {
      question: IQuestion;
      key: string;
      value: string | boolean | number | null;
      onUpdate?: (question: IQuestion) => void;
    }) => {
      if (
        key === 'difficulty' &&
        value === 'hard' &&
        !canAccessFeature('hard_difficulty')
      ) {
        openUpsellModal('premium_feature');
        return;
      }

      await updateQuestionMutation.mutateAsync(
        {
          question: {
            ...question,
            skillId: question.skill?.id ?? '',
            [key]: key === 'skill' ? { id: value } : value,
          },
        },
        {
          onSuccess: onUpdate,
          onError: error => {
            if (isQuestionLimitExceededError(error)) {
              openUpsellModal('question_generation_limit', {
                meta: {
                  skillName: question.skill?.name,
                },
              });
            } else {
              handleApiError(error);
            }
          },
        }
      );
    },
    [canAccessFeature, handleApiError, openUpsellModal, updateQuestionMutation]
  );

  const updateQuestion = useCallback(
    async (
      question: IQuestionUpdateAPIPayload,
      onUpdate?: (question: IQuestion) => void
    ) => {
      await updateQuestionMutation.mutateAsync(
        {
          question,
        },
        {
          onSuccess: onUpdate,
          onError: error => {
            if (isQuestionLimitExceededError(error)) {
              openUpsellModal('question_generation_limit', {
                meta: {
                  skillName: question.skill?.name,
                },
              });
            } else {
              handleApiError(error);
            }
          },
        }
      );
    },
    [handleApiError, openUpsellModal, updateQuestionMutation]
  );

  return {
    updateQuestionProperty,
    updateQuestion,
    isUpdating: updateQuestionMutation.isLoading,
  };
};

export const useUpdateQuestionProperties = (testId: TId) => {
  const updateQuestionPropertiesMutation = useUpdateQuestionPropertiesMutation({
    testId,
  });

  const updateQuestionProperties = useCallback(
    (questionId: TId, params: IQuestionPropertiesUpdateParams) => {
      updateQuestionPropertiesMutation.mutate({
        questionId,
        params,
      });
    },
    [updateQuestionPropertiesMutation]
  );

  return {
    updateQuestionProperties,
    isUpdating: updateQuestionPropertiesMutation.isLoading,
  };
};
