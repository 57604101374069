import React from 'react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Icon } from '@/features/common';
import { useBookmarkedJobOpenings } from '@/features/dashboard';
import { useCreateJobOpeningMutation } from '@/features/job-opening';
import { useWorkspacePermission } from '@/features/permissions';
import { useHistory, useWorkspaceId } from '@/hooks/router';

export function JobList() {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const isMobile = useMedia('(max-width: 768px)');
  const { jobOpenings } = useBookmarkedJobOpenings({ filter: 'open' });
  const openingsToDisplay = jobOpenings.slice(0, 5);
  const { mutate: createJobOpeningMutation } = useCreateJobOpeningMutation();
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );
  const onAddNewJob = () => {
    createJobOpeningMutation(
      {},
      {
        onSuccess: jobOpening => {
          history.push(
            `/admin/${workspaceId}/openings/${jobOpening.id}/pipeline`
          );
        },
      }
    );
  };
  return (
    <Container>
      <Header>
        <Heading>Jobs</Heading>
        {canCreateJobOpening && (
          <AddNew
            onClick={onAddNewJob}
            data-navigation-tooltip="Create new job"
            data-navigation-tooltip-at="left"
          >
            <Icon icon={regular('plus')} color="purple-60" fontSize="16px" />
          </AddNew>
        )}
      </Header>
      {openingsToDisplay.length > 0 ? (
        <List>
          {openingsToDisplay.map(opening => {
            const selected = history.location.pathname.includes(
              `/openings/${opening.id}`
            );

            return (
              <li key={opening.id}>
                <JobOpeningLink
                  to={`/admin/${workspaceId}/openings${
                    !isMobile ? `/${opening.id}/pipeline` : ``
                  }`}
                  selected={selected}
                >
                  <Icon
                    icon={
                      selected
                        ? solid('briefcase-blank')
                        : regular('briefcase-blank')
                    }
                    color="peach-70"
                  />
                  <OpeningName>{opening.name}</OpeningName>
                  <Badge>{opening.totalCandidates}</Badge>
                </JobOpeningLink>
              </li>
            );
          })}
        </List>
      ) : (
        <Info> No job. Add your first job above.</Info>
      )}
    </Container>
  );
}

const Container = styled.section`
  margin-top: 12px;
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-left: 16px;
  padding-right: 8px;
`;

const Heading = styled.h4`
  color: ${({ theme }) => theme.typography.colorSecondary};
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
`;

const AddNew = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: center;
  padding: 0;
  width: 28px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[90]};
  }
`;

const Info = styled.p`
  color: ${({ theme }) => theme.typography.colorSecondary};
  font-size: 12px;
  padding: 0 16px;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Badge = styled.span`
  align-self: center;
  background: ${({ theme }) => theme.colors.purple[90]};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.peach[70]};
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
  padding: 0 8px;
`;

const JobOpeningLink = styled(Link)<{ selected: boolean }>`
  align-items: center;
  color: ${({ theme }) => theme.colors.peach[70]};
  display: flex;
  height: 28px;
  justify-content: space-between;
  padding: 0 16px;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.orange[110] : 'transparent'};

  &:hover {
    background: ${({ theme, selected }) =>
      selected ? theme.colors.orange[110] : theme.colors.purple[90]};

    ${Badge} {
      background: ${({ theme }) => theme.colors.purple[100]};
    }
  }
`;

const OpeningName = styled.span`
  display: inline-block;
  flex: 1;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
