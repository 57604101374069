import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { ClientError } from '@/errors';
import { TId } from '@/features/common';
import {
  IInvitationEmailParams,
  useEmailResultModalContext,
} from '@/features/email';
import { useFlashMessages } from '@/features/flash-messages';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline';
import { useApiError } from '@/hooks/api';

import { sendInvitationEmail } from '../api/emails';

interface ISendInvitationEmailMutationVariables {
  jobOpeningId: TId | null;
  invitationEmailParams: IInvitationEmailParams;
}

export const useSendInvitationEmailMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessages();
  const handleApiError = useApiError();
  const emailResultModalContext = useEmailResultModalContext();

  return useMutation(
    (variables: ISendInvitationEmailMutationVariables) =>
      sendInvitationEmail(variables.invitationEmailParams),
    {
      meta: {
        skipDefaultErrorHandler: true,
      },
      onSuccess: (result, variables) => {
        showFlashMessage({
          type: 'email_sent',
          sendAt: variables.invitationEmailParams.sendAt,
        });
        queryClient.invalidateQueries([
          ...PIPELINE_CANDIDATES_KEY,
          variables.jobOpeningId,
          variables.invitationEmailParams.testTakerCategoryId,
        ]);
        if (result?.failedRecipientEmailAddress?.length) {
          emailResultModalContext.setResultEmails(result);
        }
      },
      onError: err => {
        if (err instanceof ClientError && err.type === 'SubscriptionRequired') {
          showFlashMessage({ type: 'email_limit_exceeded' });
        } else {
          handleApiError(err);
        }
      },
      ...(options as any),
    }
  );
};
