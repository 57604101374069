import React from 'react';
import { DSButton } from '@hundred5/design-system';

import { amplitude } from '@/features/amplitude';
import { BulkActionModal } from '@/features/bulk-actions/components/ui/bulk-action-modal';
import { useBulkDownloadPDF } from '@/features/bulk-actions/queries';
import { BulkActionModalProps } from '@/features/bulk-actions/types';
import { callAll, pluralizeWithCount } from '@/features/common';
import { useJobOpeningIdOrNull } from '@/hooks/router';

export const BulkDownloadPDFModal = ({
  open,
  onClose,
  candidates,
}: BulkActionModalProps) => {
  const bulkDownloadPDFMutation = useBulkDownloadPDF({
    candidateIDs: candidates.map(candidate => candidate.id),
  });
  const jobOpeningId = useJobOpeningIdOrNull();

  return (
    <BulkActionModal open={open} onClose={onClose}>
      <BulkActionModal.Header>
        <BulkActionModal.Title>
          Download candidate profiles
        </BulkActionModal.Title>
        <BulkActionModal.CloseButton onClick={onClose} fixed small />
      </BulkActionModal.Header>
      <BulkActionModal.Content>
        <p>
          You requested to download the PDF profiles of{' '}
          {pluralizeWithCount(candidates.length, 'candidate')}. We'll email you
          the download link shortly.
        </p>
      </BulkActionModal.Content>
      <BulkActionModal.Footer>
        <DSButton
          onClick={() => {
            callAll(bulkDownloadPDFMutation.mutate, onClose);
            amplitude?.logEvent('bulk actions/bulk action applied', {
              'user ids': candidates,
              'job opening id': jobOpeningId,
              'action origin': jobOpeningId ? 'pipeline' : 'all candidates',
              'action type': 'download_pdf',
            });
          }}
        >
          Download
        </DSButton>
      </BulkActionModal.Footer>
    </BulkActionModal>
  );
};
