import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelect } from 'downshift';
import { Link } from 'react-router-dom';

import { Icon } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { useLogout } from '@/features/toggl-accounts';
import { useWorkspaceId } from '@/hooks/router';

export function WorkspaceSelector() {
  const workspaceId = useWorkspaceId();
  const meQuery = useMeQuery();
  const logout = useLogout();

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: meQuery.isSuccess ? meQuery.data.workspaces ?? [] : [],
    itemToString: workspace => workspace.name,
  });

  return (
    <div>
      <WorkspacesSelector type="button" {...getToggleButtonProps()}>
        {
          meQuery.data?.workspaces?.find(
            workspace => workspace.id === workspaceId
          )?.name
        }
        <StyledChevron
          isOpen={isOpen}
          icon={regular('chevron-down')}
          color="white"
          size="sm"
        />
      </WorkspacesSelector>
      <WorkspacesListContainer visible={isOpen} {...getMenuProps()}>
        {isOpen && (
          <>
            <WorkspacesListHeader>Workspaces</WorkspacesListHeader>
            <WorkspacesList>
              {meQuery.data?.workspaces?.map((item, index) => (
                <WorkspacesListItem
                  key={item.id}
                  {...getItemProps({ item, index })}
                >
                  <Link to={`/admin/${item.id}`}>
                    <span>{item.name}</span>
                  </Link>
                  {item.id === workspaceId ? (
                    <Icon icon={regular('check')} color="orange-100" />
                  ) : null}
                </WorkspacesListItem>
              ))}
            </WorkspacesList>
            <SignoutButton type="button" onClick={() => logout()}>
              Sign out
            </SignoutButton>
          </>
        )}
      </WorkspacesListContainer>
    </div>
  );
}

const WorkspacesSelector = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 49px;
  line-height: 19px;
  padding: 0 16px;
  text-align: left;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.colors.purple[80]};
  }
`;

const StyledChevron = styled(Icon, {
  shouldForwardProp: prop => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  margin-left: 8px;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
`;

const WorkspacesListContainer = styled.div<{ visible: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding: 4px;
  position: absolute;
  top: 40px;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  width: 213px;
  z-index: 10;
`;

const WorkspacesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const WorkspacesListHeader = styled.span`
  align-items: center;
  color: ${({ theme }) => theme.typography.colorSecondary};
  display: flex;
  font-size: 10px;
  font-weight: 500;
  height: 28px;
  padding: 0 12px;
  text-transform: uppercase;
`;

const WorkspacesListItem = styled.li`
  align-items: center;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  height: 28px;
  justify-content: space-between;
  padding: 0 12px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[5]};
  }

  a {
    color: ${({ theme }) => theme.typography.colorPrimary};
    display: flex;
    align-items: center;
  }

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 160px;
  }
`;

const SignoutButton = styled.button`
  background: transparent;
  border-radius: 6px;
  border: none;
  color: ${({ theme }) => theme.colors.red[100]};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  height: 28px;
  margin: 0;
  padding: 0 12px;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.red[10]};
  }
`;
