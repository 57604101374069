import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { CANDIDATE_QUERY_KEY, resetCandidateTest } from '@/features/candidate';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline';

export const useResetCandidateTestMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(resetCandidateTest, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        ...CANDIDATE_QUERY_KEY,
        variables.candidateId,
      ]);

      queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);
    },
    ...(options as any),
  });
};
