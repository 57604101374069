import api from '@/api/api';
import { ICandidateAnswerEvaluationResponse } from '@/features/candidate';
import { TId } from '@/features/common';
import { parseAPIData } from '@/utils/parsers';

export async function evaluateCandidateAnswer(params: {
  candidateId: TId;
  testId: TId;
  questionId: TId;
  answerId: TId;
  scoreAsPercents: number | null;
}): Promise<ICandidateAnswerEvaluationResponse> {
  const response = await api.post(
    `/test-takers/${params.candidateId}/answers/${params.answerId}/evaluate`,
    {
      score_as_percents: params.scoreAsPercents,
    }
  );

  return parseAPIData(response.data);
}
