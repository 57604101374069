import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useCandidateSearchParams } from '@/features/candidate-search/hooks';
import { Icon } from '@/features/common';

import { DeleteButton } from '../../buttons';
import {
  NOTES_FILTER_TYPE,
  NOTES_OPTIONS,
} from '../../notes-filter/notes-filter';
import { FilterListItem } from '../filter-list-item';

export const NotesListItem = () => {
  const { searchParams, removeSearchParam } = useCandidateSearchParams();
  const { notes } = searchParams;

  if (!notes) return null;

  return (
    <FilterListItem>
      <Icon icon={solid('file-pen')} color="orange-100" />
      {NOTES_OPTIONS[notes]}
      <DeleteButton onClick={() => removeSearchParam(NOTES_FILTER_TYPE)} />
    </FilterListItem>
  );
};
