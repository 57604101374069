import styled from '@emotion/styled';

export const QuestionTypeGroupTitle = styled.p`
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${props => props.theme.colors.purple[60]};
  align-self: flex-end;
`;
