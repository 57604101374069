import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useWorkspaceTreatments } from '@/components/splits';
import { amplitude } from '@/features/amplitude';
import { TId } from '@/features/common';
import { usePreviewFeatureModal } from '@/features/preview-feature/hooks';
import { usePlanLimits } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

//region Main
export type TTabType =
  | 'results'
  | 'email'
  | 'notes'
  | 'attachments'
  | 'history'
  | undefined;

export interface CandidateCardTabsProps {
  selectedTab: TTabType;
  onTabSelect: (tab: TTabType, testId?: TId) => void;
}

export type TTabListType = {
  name: string;
  value: TTabType;
};

const tabsList: TTabListType[] = [
  {
    name: 'Tests & Interviews',
    value: 'results',
  },
  {
    name: 'Email',
    value: 'email',
  },
  {
    name: 'History',
    value: 'history',
  },
  {
    name: 'Notes',
    value: 'notes',
  },
  {
    name: 'Attachments',
    value: 'attachments',
  },
];

export function Tabs({ onTabSelect, selectedTab }: CandidateCardTabsProps) {
  const canAccessFeature = usePlanLimits();
  const showPreviewFeatureModal = usePreviewFeatureModal();
  const workspaceId = useWorkspaceId();
  const { fe_admin_app_emails_tab } = useWorkspaceTreatments([
    'fe_admin_app_emails_tab',
  ]);
  const isEmailsTabVisible = fe_admin_app_emails_tab.treatment === 'on';

  function handleTabClick(tab: TTabListType) {
    switch (tab.value) {
      case 'history':
        if (canAccessFeature('candidate_history')) {
          onTabSelect('history');
          amplitude?.logEvent('candidate history viewed', {
            'workspace id': workspaceId,
          });
        } else {
          showPreviewFeatureModal.open('candidate_history');
        }
        break;
      case 'email':
        if (canAccessFeature('candidate_emails')) {
          onTabSelect('email');
        } else {
          showPreviewFeatureModal.open('candidate_emails');
        }
        break;
      default:
        onTabSelect(tab.value);
    }
  }

  return (
    <TabsContainer>
      {tabsList
        .filter(tab => (tab.value === 'email' ? isEmailsTabVisible : true))
        .map(tab => (
          <TabElement
            key={tab.value}
            active={selectedTab === tab.value}
            onClick={() => handleTabClick(tab)}
          >
            {tab.name}
          </TabElement>
        ))}
    </TabsContainer>
  );
}

//endregion

//region Styles
const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 36px;
  background: ${props => props.theme.colors.peach[50]};
`;

const TabElement = styled.button<{ active?: boolean }>`
  border-radius: 8px;
  padding: 4px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.purple[100]};
  border: 0;
  background: none;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.peach[100]};
  }

  ${props =>
    props.active &&
    css`
      background: ${props.theme.colors.white};
    `}
`;

//endregion
