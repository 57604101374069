import loadScript from 'load-script';

const RECURLY_JS_URL = 'https://js.recurly.com/v4/recurly.js';

let recurlyPromise: Promise<any> | null = null;

export const loadRecurly = () => {
  if (recurlyPromise == null) {
    recurlyPromise = new Promise((resolve, reject) => {
      // https://github.com/recurly/recurly-js/pull/549
      window['braintree'] = {};

      loadScript(RECURLY_JS_URL, (error: Error | null) => {
        if (error) {
          reject(error);
        } else {
          resolve(window['recurly']);
        }
      });
    });
  }

  return recurlyPromise;
};

export interface RecurlyToken {
  type: string;
  id: string;
}

export interface RecurlyError {
  code: string;
  message: string;
  fields?: string[];
}

export const configureRecurly = async config => {
  const recurly = await loadRecurly();

  return new Promise(resolve => {
    const instance = new recurly.Recurly();

    instance.ready(() => {
      resolve(instance);
    });

    instance.configure(config);
  });
};

export const destroyRecurly = recurly => {
  recurly.destroy();
};

export const generateRecurlyToken = async (
  recurly,
  form: HTMLFormElement
): Promise<RecurlyToken> => {
  return new Promise((resolve, reject) => {
    recurly.token(form, (error: RecurlyError, token: RecurlyToken) => {
      if (error) {
        reject(error);
      } else {
        resolve(token);
      }
    });
  });
};

export const createRecurlyChallenge = async (
  recurly,
  actionTokenId: string,
  containerElement: HTMLElement
): Promise<RecurlyToken> => {
  return new Promise((resolve, reject) => {
    const risk = recurly.Risk();
    const threeDSecure = risk.ThreeDSecure({ actionTokenId });

    threeDSecure.on('error', reject);
    threeDSecure.on('token', resolve);
    threeDSecure.attach(containerElement);
  });
};
