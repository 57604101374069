import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { TAGS_FILTER_TYPE } from '@/features/candidate-search/components/tags-filter/tags-filter';
import { useCandidateSearchParams } from '@/features/candidate-search/hooks';
import { Icon } from '@/features/common';
import { useTagsQuery } from '@/features/common/queries/tags';

import { DeleteButton } from '../../buttons';
import { FilterListItem } from '../filter-list-item';

export const TagsListItem = () => {
  const { searchParams, addSearchParams } = useCandidateSearchParams();
  const { tags: queryTags } = searchParams;
  const tags = [{ id: '0', name: 'Without tag' }, ...useTagsQuery()];

  const removeTag = React.useCallback(
    (tagId: string) => {
      const newTags = queryTags?.filter(tag => tag !== tagId);

      addSearchParams({ [TAGS_FILTER_TYPE]: newTags });
    },
    [addSearchParams, queryTags]
  );

  if (!queryTags) return null;

  return (
    <>
      {queryTags.map(tag => (
        <FilterListItem key={tag}>
          <Icon icon={solid('tag')} color="orange-100" />
          <TagName>{tags.find(({ id }) => id === tag)?.name}</TagName>
          <DeleteButton onClick={() => removeTag(tag)} />
        </FilterListItem>
      ))}
    </>
  );
};

const TagName = styled.span`
  text-transform: capitalize;
`;
