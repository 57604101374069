import React, { useState } from 'react';
import {
  Active,
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  rectSortingStrategy,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import styled from '@emotion/styled';

import { TId } from '@/features/common';

import { SortableItem } from './sortable-item';
import { SortableItemOverlay } from './sortable-item-overlay';

export interface ISortableItemBase {
  id: TId;
  sortable?: boolean;
}

interface SortableListProps<T extends ISortableItemBase> {
  items: T[];
  renderItem: (item: T, index: number, isActive: boolean) => React.ReactNode;
  onOrderChanged: (activeIndex: number, overIndex: number) => void;
  isOrderingDisabled?: boolean;
  Container?: React.ElementType;
  layout?: 'vertical' | 'grid';
}

export function SortableList<T extends ISortableItemBase>({
  items,
  renderItem,
  onOrderChanged,
  isOrderingDisabled = false,
  Container = SortableListItems,
  layout = 'vertical',
}: SortableListProps<T>) {
  const [activeItem, setActiveItem] = useState<{
    item: T;
    index: number;
  } | null>(null);

  const onActiveItemChanged = (active: Active | null) => {
    if (!active) {
      setActiveItem(null);
      return;
    }

    const index = items.findIndex(item => item.id === active?.id);
    setActiveItem({
      item: items[index],
      index: index,
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({ active }) => onActiveItemChanged(active)}
      onDragCancel={() => onActiveItemChanged(null)}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onOrderChanged(activeIndex, overIndex);
        }
        onActiveItemChanged(null);
      }}
    >
      <SortableContext
        items={items}
        strategy={
          layout === 'grid' ? rectSortingStrategy : verticalListSortingStrategy
        }
      >
        <Container>
          {items.map((item, index) => (
            <SortableItem
              key={item.id}
              id={item.id}
              isOrderingDisabled={isOrderingDisabled || item.sortable === false}
            >
              {renderItem(item, index, activeItem?.index === index)}
            </SortableItem>
          ))}
        </Container>
      </SortableContext>

      <SortableItemOverlay>
        {activeItem
          ? renderItem(activeItem.item, activeItem.index, true)
          : null}
      </SortableItemOverlay>
    </DndContext>
  );
}

export const SortableListItems = styled.div`
  display: grid;
  gap: 8px;

  :empty {
    display: none;
  }
`;
