import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { TId, ConfirmModal } from '@/features/common';
import { useDeleteInterviewMutation } from '@/features/interview';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

interface DeleteInterviewModalProps {
  interviewId: TId;
  open: boolean;
  onClose: () => void;
  onDeleteSuccess: () => void;
}

export function DeleteInterviewModal({
  interviewId,
  open,
  onClose,
  onDeleteSuccess,
}: DeleteInterviewModalProps) {
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const deleteInterviewMutation = useDeleteInterviewMutation({ jobOpeningId });

  const handleConfirm = async () => {
    await deleteInterviewMutation.mutateAsync({ interviewId });

    amplitude?.logEvent('interview/delete', {
      'workspace id': workspaceId,
      'job opening id': jobOpeningId,
      'interview id': interviewId,
    });
    onDeleteSuccess();
  };

  return (
    <ConfirmModal
      open={open}
      action="Delete"
      onConfirm={handleConfirm}
      onClose={onClose}
    >
      <h3>Are you sure?</h3>
      <p>
        This interview is going to be deleted permanently with no backups.{' '}
        <br />
        Therefore be sure you really wish to do that.
      </p>
    </ConfirmModal>
  );
}
