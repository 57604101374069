import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { TCandidateHistoryActionType } from '@/features/candidate';
import { Icon } from '@/features/common';

interface ItemIconProps {
  action: TCandidateHistoryActionType;
}

export function ItemIcon({ action }: ItemIconProps) {
  return <Icon icon={getIcon(action)} color="white" fontSize={12} fixedWidth />;
}

function getIcon(action: TCandidateHistoryActionType) {
  switch (action) {
    // note
    case 'note':
      return solid('pen');

    // email
    case 'communication':
      return solid('envelope');

    // user activity
    case 'tag-added':
    case 'tag-removed':
    case 'tags-added':
    case 'score-change':
    case 'pipeline-change':
    case 'test-feedback':
    case 'contact-info-change':
    case 'video-intro-reset':
    case 'added-to-job-opening':
    case 'test-reset':
      return solid('hand');

    // candidate activity
    case 'login':
    case 'test-started':
    case 'test-finished':
    case 'state-change':
    case 'test-state-change':
    case 'job-applied-for':
      return solid('user');
  }
}
