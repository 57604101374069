import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import { ScrollableContainer } from '@/features/common';
import { Loader } from '@/features/common/components/loader';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  IPlaceholderQuestion,
  QuestionIcon,
  TQuestionSource,
  useQuestionsQuery,
} from '@/features/questions';
import {
  CreateCustomQuestion,
  CreateLibraryQuestion,
  CustomQuestionPreview,
  LibraryQuestionPreview,
  TestEditorQuestionEdit,
  TestEditorQuestions,
  TestEditorQuestionSource,
  useTestPage,
} from '@/features/test';
import { useHistory } from '@/hooks/router';

import {
  useTestEditorQuestionId,
  useTestEditorQuestionSource,
} from './test-editor.hooks';

const PLACEHOLDER_QUESTIONS: Record<TQuestionSource, IPlaceholderQuestion> = {
  library: {
    source: 'library',
  },
  private: {
    source: 'private',
  },
  custom: {
    source: 'custom',
    title: 'Custom',
    icon: <QuestionIcon questionSource="custom" />,
  },
};

export function TestEditor() {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { test } = useTestPage();
  const canUpdateTest = !!useJobOpeningPermission()('test', 'update');
  const questionsQuery = useQuestionsQuery(
    { testId: test.id },
    { refetchOnMount: 'always' } // TODO: remove this when we have a better way to invalidate questions cache
  );

  const selectedQuestionId = useTestEditorQuestionId();
  const selectedQuestionSource = useTestEditorQuestionSource();

  const isAddingQuestion =
    matchPath(history.location.pathname, { path: `${url}/add` }) !== null;

  const [placeholderQuestion, setPlaceholderQuestion] = useState<
    IPlaceholderQuestion | undefined
  >(undefined);

  useEffect(() => {
    setPlaceholderQuestion(
      selectedQuestionSource
        ? PLACEHOLDER_QUESTIONS[selectedQuestionSource]
        : undefined
    );
  }, [selectedQuestionSource]);

  const historyReplace = useCallback(
    (relativeUrl?: string) => {
      history.replace(`${url}${relativeUrl ?? ''}${history.location.search}`);
    },
    [history, url]
  );

  const questionContainerRef = useRef<HTMLDivElement>(null);

  if (questionsQuery.isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (!questionsQuery.isSuccess) {
    return null;
  }

  return (
    <TestEditorContainer>
      <TestEditorQuestions
        testId={test.id}
        questions={questionsQuery.data}
        testTimeLimit={test.durationInSeconds}
        isEditable={canUpdateTest}
        isDisabled={canUpdateTest && isAddingQuestion}
        maxQuestionCount={test.type !== 'quiz' ? 3 : undefined}
        selectedQuestionId={selectedQuestionId}
        onQuestionSelect={question => {
          questionContainerRef.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          historyReplace(`/${question.id}`);
        }}
        onAddQuestion={() => historyReplace('/add')}
        placeholderQuestion={placeholderQuestion}
      />

      <PreviewContainer ref={questionContainerRef}>
        <Switch>
          <Route
            exact
            path={`${path}/:questionId(\\d+)`}
            render={({ match }) => {
              const questionIndex = questionsQuery.data?.findIndex(
                q => q.id === match.params.questionId
              );

              if (questionIndex === -1) {
                return (
                  <Redirect
                    exact
                    from={path}
                    to={`${url}${history.location.search}`}
                  />
                );
              }
              const question = questionsQuery.data[questionIndex];

              return (
                <>
                  {question.source === 'library' ? (
                    <LibraryQuestionPreview
                      index={questionIndex}
                      question={question}
                    />
                  ) : null}
                  {question.source === 'custom' ? (
                    <CustomQuestionPreview
                      index={questionIndex}
                      question={question}
                    />
                  ) : null}
                </>
              );
            }}
          />
          <Route
            exact
            path={`${path}/:questionId(\\d+)/edit`}
            render={({ match }) => {
              const questionIndex = questionsQuery.data?.findIndex(
                q => q.id === match.params.questionId
              );

              if (questionIndex === -1) return null;
              const question = questionsQuery.data[questionIndex];

              // Random questions are not editable
              if (question.isRandom) {
                return (
                  <Redirect
                    exact
                    from={path}
                    to={`${url}/${question.id}${history.location.search}`}
                  />
                );
              }

              return (
                <TestEditorQuestionEdit
                  index={questionIndex}
                  question={question}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/add`}
            render={() =>
              canUpdateTest && (
                <TestEditorQuestionSource
                  onSourceSelect={source => historyReplace(`/add/${source}`)}
                  onClose={() => historyReplace()}
                  questionCount={questionsQuery.data.length}
                />
              )
            }
          />

          <Route
            exact
            path={`${path}/add/:source`}
            render={({ match }) =>
              canUpdateTest && (
                <>
                  {match.params.source === 'library' ? (
                    <CreateLibraryQuestion
                      index={questionsQuery.data.length}
                      onCancel={historyReplace}
                    />
                  ) : null}
                  {match.params.source === 'custom' ? (
                    <CreateCustomQuestion
                      index={questionsQuery.data.length}
                      onCancel={historyReplace}
                    />
                  ) : null}
                </>
              )
            }
          />

          {!!questionsQuery.data?.length ? (
            // Redirect to first question in the list
            <Redirect
              exact
              from={path}
              to={`${url}/${questionsQuery.data[0].id}${history.location.search}`}
            />
          ) : (
            // With no questions redirect to add question screen
            <Redirect exact from={path} to={`${url}/add`} />
          )}
        </Switch>
      </PreviewContainer>
    </TestEditorContainer>
  );
}

const TestEditorContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(256px, 326px) minmax(830px, 1080px);
  gap: 24px;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  padding: 24px;
  text-align: center;
`;

const PreviewContainer = styled(ScrollableContainer)``;
