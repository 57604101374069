import React from 'react';
import styled from '@emotion/styled';

import {
  getCandidateEmail,
  getCandidateFirstName,
  getCandidateLastName,
  useCandidateQuery,
} from '@/features/candidate';
import { TId } from '@/features/common';

export function CandidateName({ candidateId }: { candidateId: TId }) {
  const { data: candidate } = useCandidateQuery({ candidateId });

  const fullName = `${getCandidateFirstName(candidate)} ${getCandidateLastName(
    candidate
  )}`.trim();

  const email = getCandidateEmail(candidate);

  return candidate ? (
    <Name>
      {candidate.hireState === 'invited' || !fullName ? email : fullName}
    </Name>
  ) : null;
}

const Name = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${props => props.theme.colors.purple[100]};
`;
