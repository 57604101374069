import api from '@/api/api';
import { ICandidateSuspiciousActivity } from '@/features/candidate';
import { TId } from '@/features/common';
import { parseAPIData } from '@/utils/parsers';

export async function fetchCandidateSuspiciousActivity(params: {
  candidateId: TId;
  testId: TId;
}): Promise<ICandidateSuspiciousActivity> {
  const response = await api.get(
    `/test-takers/${params.candidateId}/fraud-detection/${params.testId}`
  );

  return parseAPIData(response.data);
}
