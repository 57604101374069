import api from '@/api/api';
import { TId } from '@/features/common';
import { IWorkspaceTag } from '@/features/workspace/types/workspace-tags';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchWorkspaceTags(params: {
  workspaceId: TId;
}): Promise<IWorkspaceTag[]> {
  const response = await api.get(`/workspaces/${params.workspaceId}/tags`);

  return parseAPIData(response.data.tags);
}

export async function createWorkspaceTag(params: {
  workspaceId: TId;
  name: string;
}): Promise<IWorkspaceTag> {
  const response = await api.post(
    `/workspaces/${params.workspaceId}/tags`,
    serialiseAPIData(params)
  );

  return parseAPIData(response.data);
}
