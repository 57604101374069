import React from 'react';
import styled from '@emotion/styled';

import { PremiumIllustration } from './premium-illustration';
import { Section } from './section';

export function PremiumBanner() {
  return (
    <Container>
      <div>
        <PremiumIllustration />
      </div>
      <div>
        <h2>Great choice!</h2>
        <p>
          You’ve chosen a Premium test. You can try the test yourself but you
          will need to upgrade before you can invite candidates.
        </p>
      </div>
    </Container>
  );
}

const Container = styled(Section)`
  align-items: center;
  background: ${({ theme }) => theme.colors.orange[20]};
  display: flex;
  gap: 24px;
  padding: 12px 36px;

  h2 {
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 0;
  }

  p {
    margin: 0;
  }
`;
