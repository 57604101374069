import api from '@/api/api';
import { ClientError } from '@/errors';
import { TId } from '@/features/common';
import { ISlackIntegration } from '@/features/integrations';
import { parseAPIData } from '@/utils/parsers';

export async function fetchSlackIntegrations(params: {
  workspaceId: TId;
}): Promise<ISlackIntegration[]> {
  try {
    const response = await api.get(
      `/workspaces/${params.workspaceId}/integrations/slack`
    );

    return parseAPIData<ISlackIntegration[]>(response.data.integrations) || [];
  } catch (error) {
    if (error instanceof ClientError && error.type === 'NotFound') {
      return [];
    } else {
      throw error;
    }
  }
}

export async function addSlackIntegration(params: {
  workspaceId: TId;
}): Promise<string | null> {
  const response = await api.get(
    `/workspaces/${params.workspaceId}/integrations/slack/add`
  );
  return response.data.redirect_url;
}

export async function deleteSlackIntegration(params: {
  slackIntegrationId: TId;
}): Promise<void> {
  await api.delete(`/integrations/slack/${params.slackIntegrationId}`);
}
