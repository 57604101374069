import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Form, Formik } from 'formik';
import { isEmpty, pick } from 'lodash';

import { amplitude } from '@/features/amplitude';
import { ICandidateTestState } from '@/features/candidate/types';
import {
  TFilterType,
  useCandidateSearchParams,
} from '@/features/candidate-search';
import { callAll, FormikCheckboxField, Icon, Popover } from '@/features/common';
import { useJobOpeningIdOrNull } from '@/hooks/router';

import { ApplyButton, SelectAllButton, UnselectAllButton } from '../buttons';
import {
  FieldWrapper,
  HoverableFieldWrapper,
} from '../candidate-filters/ui/field-wrapper';
import { FilterName } from '../candidate-filters/ui/filter-name';
import {
  FullWidthPopover,
  IconWrapper,
  SubContent,
  Trigger,
} from '../candidate-filters/ui/popover';

export const STATE_FILTER_TYPE: TFilterType = 'states';
export const STATES: ICandidateTestState[] = [
  'not_invited',
  'invited',
  'interested',
  'in_progress',
  'out_of_time',
  'completed',
];

export const StateFilter = ({ onApply }) => {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();
  const jobOpeningId = useJobOpeningIdOrNull();

  return (
    <FullWidthPopover>
      <Popover.Trigger>
        {({ onClick }) => (
          <Trigger onClick={onClick}>
            <IconWrapper>
              <Icon icon={regular('circle-ellipsis')} color="purple-60" />
            </IconWrapper>
            State
          </Trigger>
        )}
      </Popover.Trigger>
      <SubContent position="right">
        {({ close }) => (
          <>
            <Formik
              initialValues={pick(candidatesSearchParams, STATE_FILTER_TYPE)}
              onSubmit={values => {
                if (isEmpty(values)) {
                  removeSearchParam(STATE_FILTER_TYPE);
                } else {
                  addSearchParams(values);
                  amplitude?.logEvent('candidate filters/filter applied', {
                    'filter type': STATE_FILTER_TYPE,
                    'filter value': values,
                    'filter origin': jobOpeningId
                      ? 'pipeline'
                      : 'all candidates',
                    'job opening id': jobOpeningId,
                  });
                }
                callAll(onApply, close);
              }}
            >
              <Form>
                <SelectionFieldWrapper>
                  <FullWidthFilterName>State</FullWidthFilterName>
                  <SelectAllButton name={STATE_FILTER_TYPE} values={STATES} />
                  <UnselectAllButton name={STATE_FILTER_TYPE} />
                </SelectionFieldWrapper>
                {STATES.map(state => (
                  <HoverableFieldWrapper key={state}>
                    <StyledFormikCheckboxField
                      name={STATE_FILTER_TYPE}
                      value={state}
                      label={(
                        state[0].toUpperCase() + state.slice(1)
                      ).replaceAll('_', ' ')}
                    />
                  </HoverableFieldWrapper>
                ))}
                <ApplyButton />
              </Form>
            </Formik>
          </>
        )}
      </SubContent>
    </FullWidthPopover>
  );
};

//region Styles
const FullWidthFilterName = styled(FilterName)`
  flex: 1;
  padding-left: 0;
  padding-right: 0;
`;
const SelectionFieldWrapper = styled(FieldWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
const StyledFormikCheckboxField = styled(FormikCheckboxField)`
  align-items: center;
`;

//endregion
