import React from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';

interface NoCardProps {
  onClick: () => void;
}

export const NoCard = (props: NoCardProps) => {
  return (
    <DSContentBlock>
      <DSContentBlock.Title>Payment</DSContentBlock.Title>
      <DSContentBlock.Content>
        <Description>No payment information available</Description>
      </DSContentBlock.Content>
      <DSContentBlock.Footer>
        <DSButton variant="secondary" size="xsmall" onClick={props.onClick}>
          Add payment info
        </DSButton>
      </DSContentBlock.Footer>
    </DSContentBlock>
  );
};

const Description = styled.div`
  color: ${props => props.theme.typography.colorSecondary};
`;
