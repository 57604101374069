import { useCallback, useEffect, useMemo } from 'react';
import { camelizeKeys } from 'humps';
import { isEmpty, isEqual, omit, omitBy } from 'lodash';
import qs from 'qs';

import {
  getInitialParams,
  ICandidateSearchBaseProps,
  ICandidateSearchParams,
  stringifyParams,
  TCandidateSearchStoreParams,
  TParamKey,
} from '@/features/candidate-search';
import { useCandidateSearch } from '@/features/candidate-search/providers';
import { useStoreData } from '@/features/storage';
import { useHistory, useLocation } from '@/hooks/router';

const PROTECTED_PARAMS = ['page', 'itemsPerPage', 'sortOrder', 'sortDirection'];

export function useDefaultCandidateSearchParams(
  defaultParams: ICandidateSearchBaseProps,
  storeParams: TCandidateSearchStoreParams | null
) {
  const location = useLocation();
  const { replace } = useHistory();

  useEffect(() => {
    const params = getInitialParams(defaultParams, storeParams);

    if (!isEmpty(params) && !location.search) {
      replace({
        search: `?${stringifyParams(params)}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useCandidateSearchParams() {
  const location = useLocation();
  const { push } = useHistory();
  const { defaultParams, storageKey } = useCandidateSearch();
  const [storeParams, setStoreParams] = useStoreData<
    TCandidateSearchStoreParams
  >(storageKey);

  const searchParams: ICandidateSearchParams = useMemo(() => {
    const currentParams = camelizeKeys(
      qs.parse(location.search.substring(1), { comma: true })
    ) as ICandidateSearchParams;

    // get the default params if there aren't present, but there is an additional
    currentParams.page = currentParams.page || defaultParams.pagination.page;
    currentParams.itemsPerPage =
      currentParams.itemsPerPage || defaultParams.pagination.itemsPerPage;
    currentParams.sortDirection =
      currentParams.sortDirection || defaultParams.sort.sortDirection;
    currentParams.sortOrder =
      currentParams.sortOrder || defaultParams.sort.sortOrder;

    return location.search
      ? currentParams
      : getInitialParams(defaultParams, storeParams);
  }, [location, defaultParams, storeParams]);

  const removeSearchParam = useCallback(
    (param: TParamKey) => {
      if (PROTECTED_PARAMS.includes(param)) return;

      const nextParams = { ...searchParams };
      delete nextParams[param];

      if (!isEqual(searchParams, nextParams)) {
        push({ ...location, search: `?${stringifyParams(nextParams)}` });
        setStoreParams(omit(nextParams, ['page']));
      }
    },
    [push, searchParams, location, setStoreParams]
  );

  const addSearchParams = useCallback(
    (params: Partial<ICandidateSearchParams>) => {
      const filteredParams = omitBy(params, value => !Boolean(value));

      const nextParams: ICandidateSearchParams = {
        ...searchParams,
        ...filteredParams,
      };

      if (!isEqual(searchParams, nextParams)) {
        push({ ...location, search: `?${stringifyParams(nextParams)}` });
        setStoreParams(omit(nextParams, ['page']));
      }
    },
    [push, searchParams, location, setStoreParams]
  );

  const clearSearchParams = useCallback(() => {
    const nextParams: ICandidateSearchParams = {
      ...defaultParams.pagination,
      ...defaultParams.sort,
    };
    if (!isEqual(searchParams, nextParams)) {
      push({ ...location, search: `?${stringifyParams(nextParams)}` });
      setStoreParams(omit(nextParams, ['page']));
    }
  }, [
    defaultParams.pagination,
    defaultParams.sort,
    searchParams,
    push,
    location,
    setStoreParams,
  ]);

  const hasSearchParams = useMemo(() => {
    const defaultParamsKeys = Object.keys({
      ...defaultParams.pagination,
      ...defaultParams.sort,
    });
    defaultParamsKeys.push('q'); // also ignore search field

    return Object.keys(searchParams).some(
      key => !defaultParamsKeys.includes(key)
    );
  }, [defaultParams, searchParams]);

  return {
    searchParams,
    hasSearchParams,
    addSearchParams,
    removeSearchParam,
    clearSearchParams,
  };
}
