import styled from '@emotion/styled';

export const Container = styled.article<{ hasInfo: boolean }>`
  border-radius: 8px;
  cursor: grab;
  display: grid;
  flex-shrink: 0;
  grid-template-rows: ${({ hasInfo }) =>
    hasInfo ? 'repeat(3, min-content)' : 'min-content auto min-content'};
  height: 100%;
  padding: 8px 0 12px 0;
  width: 292px;
`;
