import { useCallback } from 'react';

import { useFlashMessages } from '@/features/flash-messages';

import * as imagesApi from '../api/images';
import { ImagePurpose } from '../types/image';

import { useApiError } from './api';
import { useAsync } from './async';
import { useWorkspaceIdOrNull } from './router';

export const useImageUpload = () => {
  const { showFlashMessage } = useFlashMessages();
  const handleApiError = useApiError();
  const workspaceId = useWorkspaceIdOrNull();
  const [uploadImageApi, { loading: uploadingImage }] = useAsync(
    imagesApi.uploadImage
  );

  const uploadImage = useCallback(
    async (source: File, type: ImagePurpose | null, user: boolean) => {
      if (source.size / 1024 / 1024 > 5) {
        showFlashMessage({
          type: 'image_upload_error',
          error: 'file_too_large',
        });
        return ['file_too_large', undefined];
      }

      let url;

      try {
        url = await uploadImageApi(source, type, user ? null : workspaceId);

        return [undefined, url];
      } catch (error) {
        if (error.type === 'UnsupportedFileFormat') {
          showFlashMessage({
            type: 'image_upload_error',
            error: 'unsupported_file_format',
          });
        } else {
          handleApiError(error);
        }

        return [error, undefined];
      }
    },
    [workspaceId, handleApiError, showFlashMessage, uploadImageApi]
  );

  return { uploadImage, uploadingImage };
};
