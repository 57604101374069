import { useEffect } from 'react';

import { useDemo } from '@/features/authentication';
import { useApiError } from '@/hooks/api';
import { useHistory } from '@/hooks/router';

const StartDemo = () => {
  const logIn = useDemo();
  const history = useHistory();
  const handleApiError = useApiError();

  useEffect(() => {
    const startDemo = async () => {
      try {
        await logIn();
      } catch (error) {
        handleApiError(error);
      }

      history.replace('/admin');
    };

    startDemo();
  }, [handleApiError, history, logIn]);

  return null;
};

export default StartDemo;
