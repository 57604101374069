import * as React from 'react';

import './Spinner.css';

const Spinner = () => (
  <div className="alpha-spinner">
    <div className="alpha-spinner__dots">
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
      <div>
        <div />
      </div>
    </div>
  </div>
);

export default Spinner;
