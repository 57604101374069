import React from 'react';
import styled from '@emotion/styled';

import { IFlashMessage } from '../types/flash-message';
import { useFlashMessages } from '../utils';

import { FlashMessageContent } from './flash-message-content';

interface FlashMessageProps {
  message: IFlashMessage;
}

export const FlashMessage = ({ message }: FlashMessageProps) => {
  const { closeFlashMessage } = useFlashMessages();

  return (
    <Wrapper>
      <FlashMessageContent
        flashMessage={message.content}
        onClose={() => {
          closeFlashMessage(message.id);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media all and (max-width: 768px) {
    & > div {
      width: 100%;
    }
  }
`;
