import styled from '@emotion/styled';

export const CategoryName = styled.button`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  height: 24px;
  display: inline-block;
  align-items: center;
  margin: 0;
  border: 0;
  background: transparent;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  padding-left: 8px;

  &:hover {
    background: ${({ theme }) => theme.colors.peach[50]};
  }
`;
