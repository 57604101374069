import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

export function Ellipsis() {
  return (
    <Wrapper>
      <Icon icon={regular('ellipsis')} size="xs" />
    </Wrapper>
  );
}

const Wrapper = styled.li`
  align-items: center;
  display: flex;
  height: 16px;
`;
