import { DynamicConfiguration } from './types';

function getConfiguration(): DynamicConfiguration {
  const config: DynamicConfiguration | undefined = window['_thconfig'];

  if (config == null) {
    throw new Error('App configuration object is missing');
  }

  return config;
}

export default getConfiguration();
