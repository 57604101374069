import api from '@/api/api';
import { TId } from '@/features/common';

export async function bulkInviteToNewJob(
  candidateIds: TId[],
  pipelineStageId: number,
  subject: string,
  message: string
): Promise<void> {
  let ids: number[] = candidateIds.map(id => Number(id));
  await api.patch(
    `/test-takers/bulk`,
    {
      ids: ids,
      action: 'invite',
      test_taker_data: {
        category_id: pipelineStageId,
      },
      test_taker_email: {
        subject,
        message,
      },
    },
    { captchaAction: 'bulk_invite' }
  );
}
