import { array, object, string } from 'yup';

export const emailSubjectValidationSchema = string()
  .trim()
  .required();

export const emailMessageValidationSchema = string()
  .trim()
  .required()
  .notOneOf([
    '<p></p>',
    '<pre><code></code></pre>',
    '<h1></h1>',
    '<h2></h2>',
    '<h3></h3>',
    '<h4></h4>',
    '<h5></h5>',
    '<h6></h6>',
  ]);

export const emailListValidationSchema = array()
  .of(string().trim())
  .required();

export const emailValidationSchema = object().shape({
  subject: emailSubjectValidationSchema,
  message: emailMessageValidationSchema,
  emails: emailListValidationSchema,
});
