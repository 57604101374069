import { BillingPlan, PauseDuration } from '@/features/billing';

export const getQuestionCountLimit = (subscriptionPlan: BillingPlan | '') => {
  if (/free/.test(subscriptionPlan)) {
    return '5';
  } else if (/standard|basic/.test(subscriptionPlan)) {
    return '10';
  } else if (/premium/.test(subscriptionPlan)) {
    return '20';
  } else if (/business/.test(subscriptionPlan)) {
    return '30';
  } else {
    return '';
  }
};

export const pauseDurationMonthsValue: Record<PauseDuration, number> = {
  ['pause-1-month']: 1,
  ['pause-3-months']: 3,
  ['pause-6-months']: 6,
};
