import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { BulkActionProps } from '@/features/bulk-actions/types';
import { Icon } from '@/features/common';
import { useEmailModalContext } from '@/features/email';
import { useJobOpeningIdOrNull } from '@/hooks/router';

//region Main
export const BulkReject = ({
  disabled,
  selectedCandidates,
  onActionDone,
  testId,
  interviewId,
}: BulkActionProps) => {
  const { openEmailModal } = useEmailModalContext();
  const jobOpeningId = useJobOpeningIdOrNull();
  const candidatesIds = selectedCandidates.map(candidate => candidate.id);
  const candidatesEmails = selectedCandidates.map(candidate => candidate.email);

  return (
    <>
      <DSButton
        type="button"
        variant="secondary"
        size="xsmall"
        data-rh="Reject"
        onClick={() => {
          openEmailModal({
            type: 'bulk-rejection',
            props: {
              emails: candidatesEmails,
            },
            meta: {
              testId,
              interviewId,
              candidateIds: candidatesIds,
              onSuccess: () => {
                onActionDone?.();
                amplitude?.logEvent('bulk actions/bulk action applied', {
                  'user ids': candidatesIds,
                  'job opening id': jobOpeningId,
                  'action origin': jobOpeningId ? 'pipeline' : 'all candidates',
                  'action type': 'reject',
                });
              },
            },
          });
        }}
        disabled={disabled}
        Icon={<Icon icon={regular('thumbs-down')} />}
      />
    </>
  );
};
//endregion
