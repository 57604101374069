import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { DSSearchInput } from '@hundred5/design-system';

import { useInboxSearchParams } from '../../hooks/params';

interface IProps {
  className?: string;
  placeholder?: string;
}
function useDebounce<T>(funct: (value: T) => void, timeout: number) {
  const timerRef = useRef<NodeJS.Timer>();

  const setValue = (value: any) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => funct(value), timeout);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return setValue;
}
export const InboxSearch = ({
  className,
  placeholder = 'Search in messages',
}: IProps) => {
  const {
    searchParams,
    addSearchParams,
    removeSearchParam,
  } = useInboxSearchParams();
  const [value, setValue] = useState(searchParams.q || '');
  const updateSearchQuery = useDebounce(addSearchParams, 750);
  useEffect(() => {
    searchParams.q && setValue(searchParams.q);
  }, [searchParams.q]);

  return (
    <SearchContainer className={className}>
      <DSSearchInput
        placeholder={placeholder}
        background={'purple'}
        value={value}
        showResetButton
        reset={() => {
          setValue('');
          removeSearchParam('q');
        }}
        onChange={event => {
          const value = event.target.value;
          setValue(value);

          if (!value) {
            removeSearchParam('q');
          } else {
            updateSearchQuery({ q: value });
          }
        }}
      />
    </SearchContainer>
  );
};

//region Styles
const SearchContainer = styled.div`
  width: 300px;
  flex-shrink: 0;
`;
//endregion
