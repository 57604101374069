import React, { useRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DSContentBlock, DSSearchInput } from '@hundred5/design-system';
import { Route } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import { useWorkspacePermission } from '@/features/permissions';
import { useWorkspace } from '@/features/workspace';
import { Id } from '@/types/misc';

import {
  InvitationList,
  InviteButton,
  InviteMemberModal,
  TeamMemberList,
  UpdateMemberModal,
} from './ui';

//region Main
export const WorkspaceSettingsTeam = () => {
  const prevUserId = useRef<Id | null>(null);
  const canCreateInvitations = useWorkspacePermission()(
    'workspace invitations',
    'create'
  );
  const canReadInvitations = useWorkspacePermission()(
    'workspace invitations',
    'read'
  );
  const [searchQuery, setSearchQuery] = React.useState('');
  let { path } = useRouteMatch();
  const workspace = useWorkspace();

  return (
    <ContentBlock disabled={!!workspace?.transferOwnershipRequestId}>
      <DSContentBlock.Title>Workspace team</DSContentBlock.Title>
      <DSContentBlock.Content>
        <Wrapper>
          <SearchInputWrapper>
            <DSSearchInput
              placeholder="Search members"
              value={searchQuery}
              showResetButton={true}
              reset={() => {
                setSearchQuery('');
              }}
              onChange={event => {
                const { value } = event.target;
                setSearchQuery(value);
              }}
            />
          </SearchInputWrapper>
          {canCreateInvitations ? <InviteButton /> : null}
        </Wrapper>

        <TeamMemberList searchQuery={searchQuery} />
      </DSContentBlock.Content>

      {canReadInvitations ? <InvitationList /> : null}

      {canCreateInvitations && (
        <Route path={`${path}/invite`}>
          {({ match }) => <InviteMemberModal open={match != null} />}
        </Route>
      )}

      <Route path={`${path}/user/:user`}>
        {({ match }) => {
          prevUserId.current = match ? match.params.user : prevUserId.current;

          return (
            <UpdateMemberModal
              userId={prevUserId.current}
              open={!!(match && match.params.user)}
            />
          );
        }}
      </Route>
    </ContentBlock>
  );
};
//endregion

//region Styles
const ContentBlock = styled(DSContentBlock)<{ disabled: boolean }>`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 12px;
`;

const SearchInputWrapper = styled.div`
  width: 280px;
`;
//endregion
