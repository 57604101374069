import React, { useEffect, useRef } from 'react';

import configuration from '../../configuration/runtime';
import {
  configureRecurly,
  createRecurlyChallenge,
  destroyRecurly,
  RecurlyToken,
} from '../../recurly';

import './PaymentChallenge.css';

interface Props {
  token: string;
  onFinish: (token: RecurlyToken) => void;
  onError: (error: any) => void;
}

const PaymentChallenge = (props: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const onFinish = useRef(props.onFinish);
  const onError = useRef(props.onError);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (containerElement == null) {
      throw new Error('Container ref is empty');
    }

    const recurly = configureRecurly({
      publicKey: configuration.recurlyPublicKey,
    });

    recurly
      .then(instance =>
        createRecurlyChallenge(instance, props.token, containerElement)
      )
      .then(onFinish.current, onError.current);

    return () => {
      recurly.then(instance => {
        destroyRecurly(instance);
      });
    };
  }, [props.token]);

  useEffect(() => {
    onFinish.current = props.onFinish;
    onError.current = props.onError;
  });

  return <div className="payment-challenge" ref={containerRef} />;
};

export default PaymentChallenge;
