import React from 'react';
import styled from '@emotion/styled';
import { DSColumn, DSGrid, DSRow } from '@hundred5/design-system';

import ScrollToTopOnMount from '@/components/scroll_to_top_on_mount/ScrollToTopOnMount';
import { useDocumentTitle } from '@/features/common';
import { Loader } from '@/features/common/components/loader';

export function JobOpeningSettingsPageContent({
  title,
  loading,
  children,
}: {
  title: string;
  loading?: boolean;
  children: React.ReactNode;
}) {
  useDocumentTitle(title);

  return (
    <>
      <ScrollToTopOnMount />

      <DSGrid padding="24px">
        <DSRow centered>
          <Content size={20} sm={24} gap="24px">
            {loading ? <Loader /> : children}
          </Content>
        </DSRow>
      </DSGrid>
    </>
  );
}

const Content = styled(DSColumn)`
  width: 100%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
`;
