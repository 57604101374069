import React from 'react';
import { Link } from 'react-router-dom';

import { ICandidateSuspiciousActivityClash } from '@/features/candidate';
import { useWorkspaceIdOrNull } from '@/hooks/router';

export interface JoinCandidatesProps {
  clashes: ICandidateSuspiciousActivityClash[];
}

export function JoinCandidates(props: JoinCandidatesProps) {
  const workspaceId = useWorkspaceIdOrNull();

  return (
    <>
      {props.clashes.map((clash, index, array) => (
        <React.Fragment key={index}>
          <Link
            replace
            to={`/redirect/candidate/${workspaceId}/${clash.jobOpeningId}/${clash.candidateId}`}
          >
            {!!clash.firstName && !!clash.lastName
              ? `${clash.firstName} ${clash.lastName}`
              : clash.fullName || clash.name || 'no name'}
          </Link>
          {array.length > index + 1 ? ', ' : ''}
        </React.Fragment>
      ))}
    </>
  );
}
