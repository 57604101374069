import React from 'react';
import styled from '@emotion/styled';

import {
  TCandidateAnswerScoreStatus,
  useEvaluateCandidateAnswerMutation,
} from '@/features/candidate';
import { useOpenEndedEvaluationVariants } from '@/features/candidate/hooks/open-ended-questions';
import { PlanTag, TId } from '@/features/common';
import { useTestQuery } from '@/features/test';
import { usePlanLimits } from '@/hooks/planLimits';

import { DetectAiButton } from './detect-ai-button';
import { EvaluateAiButton } from './evaluate-ai-button';
import { EvaluationButtonsFiveOptions } from './evaluation-buttons-five-options';
import { EvaluationButtonsThreeOptions } from './evaluation-buttons-three-options';

//region Main
export interface EvaluationButtonsProps {
  candidateId: TId;
  testId: TId;
  questionId: TId;
  answerId: TId;
  scoreAsPercents: number | null;
  scoreStatus: TCandidateAnswerScoreStatus;
  questionNumber: number;
  freeFormAnswer: string | null | undefined;
}

export function EvaluationButtons(props: EvaluationButtonsProps) {
  const mutation = useEvaluateCandidateAnswerMutation();
  const testQuery = useTestQuery({ testId: props.testId });
  const evaluationOptions = useOpenEndedEvaluationVariants(testQuery.data);
  const canAccessAiFeatures = !!usePlanLimits()('candidate_answer_ai_features');

  return (
    <>
      <EvaluationLabel>Rate this answer:</EvaluationLabel>
      <RatingContainer>
        {evaluationOptions === 5 ? (
          <EvaluationButtonsFiveOptions
            score={props.scoreAsPercents}
            isLoading={mutation.isLoading}
            onClick={(score: number | null) =>
              mutation.mutate({ ...props, scoreAsPercents: score })
            }
          />
        ) : (
          <EvaluationButtonsThreeOptions
            score={props.scoreAsPercents}
            isLoading={mutation.isLoading}
            onClick={(score: number | null) =>
              mutation.mutate({ ...props, scoreAsPercents: score })
            }
          />
        )}
      </RatingContainer>
      {props.freeFormAnswer && (
        <>
          <EvaluationLabel>or</EvaluationLabel>
          <AIButtons>
            <EvaluateAiButton
              candidateId={props.candidateId}
              answerId={props.answerId}
              questionNumber={props.questionNumber}
            />
            <DetectAiButton
              candidateId={props.candidateId}
              answerId={props.answerId}
              questionNumber={props.questionNumber}
            />
            {!canAccessAiFeatures && <StyledPlanTag />}
          </AIButtons>
        </>
      )}
    </>
  );
}

//endregion

//region Styles
const EvaluationLabel = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${props => props.theme.typography.colorPrimary};
  margin-bottom: 10px;
`;

const AIButtons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const RatingContainer = styled.div`
  margin-bottom: 10px;
`;
const StyledPlanTag = styled(PlanTag)`
  margin: 0;
`;
//endregion
