import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useCandidateSearchParams } from '@/features/candidate-search/hooks';
import { Icon } from '@/features/common';

import { DeleteButton } from '../../buttons';
import { COUNTRY_FILTER_TYPE } from '../../country-filter';
import { FilterListItem } from '../filter-list-item';

export const CountryListItem = () => {
  const { searchParams, addSearchParams } = useCandidateSearchParams();
  const { country: queryCountries } = searchParams;

  const removeCountry = React.useCallback(
    (removedCountry: string) => {
      const newCountries = queryCountries?.filter(
        country => country !== removedCountry
      );

      addSearchParams({ [COUNTRY_FILTER_TYPE]: newCountries });
    },
    [addSearchParams, queryCountries]
  );

  if (!queryCountries) return null;

  return (
    <>
      {queryCountries.map(country => (
        <FilterListItem key={country}>
          <Icon icon={solid('earth-americas')} color="orange-100" />
          <CountryName>{country}</CountryName>
          <DeleteButton onClick={() => removeCountry(country)} />
        </FilterListItem>
      ))}
    </>
  );
};

const CountryName = styled.span`
  text-transform: capitalize;
  white-space: nowrap;
`;
