import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSContentBlock,
  DSIconRuler,
  DSIconVideo,
} from '@hundred5/design-system';

import { Icon } from '@/features/common';
import { Loader } from '@/features/common/components/loader';
import { useDashboardJobOpeningsQuery } from '@/features/dashboard';
import { usePipelineTests } from '@/features/pipeline';
import { TTestType } from '@/features/test';
import { useHistory, useJobOpeningId, useWorkspaceId } from '@/hooks/router';

import { TestContextMenu } from './ui';

export function JobOpeningSettingsTests() {
  const history = useHistory();

  const { pipelineTests, isLoading } = usePipelineTests();
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const dashboardJobOpeningsQuery = useDashboardJobOpeningsQuery({
    workspaceId,
  });
  const jobOpenings = dashboardJobOpeningsQuery.data?.openings;
  const dashboardJobOpening = jobOpenings?.find(jo => jo.id === jobOpeningId);

  if (pipelineTests.length === 0) {
    return null;
  }

  return (
    <DSContentBlock>
      <DSContentBlock.Title>Tests</DSContentBlock.Title>
      <DSContentBlock.Content>
        <TestTableRow header>
          <NameColumn>Name</NameColumn>
          <DataColumn>Test type</DataColumn>
          <DataColumn>Questions</DataColumn>
          <DataColumn>Time limit</DataColumn>
          <ActionColumn />
        </TestTableRow>
        {isLoading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          pipelineTests.map(
            test =>
              test && (
                <TestTableRow
                  key={test.id}
                  onClick={() =>
                    history.push(
                      `/admin/${workspaceId}/openings/${jobOpeningId}/test/${test.id}/questions?returnUrl=job-settings&returnName=Job Settings`
                    )
                  }
                >
                  <NameColumn>
                    <IconWrapper>{getTestTypeIcon(test.type)}</IconWrapper>
                    {test.name}
                  </NameColumn>
                  <DataColumn>{getTestType(test.type)}</DataColumn>
                  <DataColumn>{test.questionCount}</DataColumn>
                  <DataColumn>
                    {getTimeLimit(test.durationInSeconds)}
                  </DataColumn>
                  <ActionColumn>
                    <TestContextMenu
                      test={test}
                      jobOpening={dashboardJobOpening!}
                      onClickTestSettings={() =>
                        history.push(
                          `/admin/${workspaceId}/openings/${jobOpeningId}/test/${test.id}/questions?returnUrl=job-settings&returnName=Job Settings`
                        )
                      }
                    />
                  </ActionColumn>
                </TestTableRow>
              )
          )
        )}
      </DSContentBlock.Content>
    </DSContentBlock>
  );
}

const getTestTypeIcon = (type: TTestType) => {
  switch (type) {
    case 'quiz':
      return <DSIconRuler variant="primary" />;
    case 'video':
      return <DSIconVideo variant="primary" />;
    case 'homework':
      return <Icon icon={regular('file-lines')} />;
    default:
      return <></>;
  }
};

const getTestType = (type: TTestType) => {
  switch (type) {
    case 'quiz':
      return 'Skills test';
    case 'video':
      return 'Video intro';
    case 'homework':
      return 'Homework';
    default:
      return '';
  }
};

const getTimeLimit = (timeLimit: number) => {
  if (timeLimit === 0) return 'Auto';

  const hours = Math.floor(timeLimit / 3600);
  const minutes = Math.floor((timeLimit % 3600) / 60);

  return `${hours > 0 ? `${hours}h ` : ''}${
    minutes > 0 ? `${minutes}min` : ''
  }`;
};

const TestTableRow = styled.div<{ header?: boolean }>`
  display: flex;
  align-items: flex-start;
  padding: 16px 12px;
  gap: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  background: ${props => props.theme.colors.white};
  transition: background 0.1s;

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.header
      ? css`
          font-weight: 500;
          font-size: ${10 / 14}rem;
          text-transform: uppercase;
          color: ${props.theme.typography.colorSecondary};

          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        `
      : css`
          font-weight: 400;
          font-size: 1rem;
          cursor: pointer;
          color: ${props.theme.typography.colorPrimary};

          &:hover {
            background: ${props.theme.hover.onWhite};
          }
        `}
`;

const NameColumn = styled.div`
  width: 46%;
  display: flex;
  gap: 8px;

  @media all and (max-width: 600px) {
    width: 100%;
  }
`;

const DataColumn = styled.div`
  width: 18%;

  @media all and (max-width: 600px) {
    display: none;
  }
`;

const ActionColumn = styled.div`
  min-width: 50px;
  display: flex;
  justify-content: end;
`;

const IconWrapper = styled.div`
  height: 1.5rem;
  display: flex;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
