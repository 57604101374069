import React from 'react';
import styled from '@emotion/styled';
import formatDate from 'date-fns/format';

import { isJobOpeningPaused } from '@/features/job-opening';

interface JobOpeningPausedTagProps {
  pausedAt?: Date | null;
  showInfo?: boolean;
}

export function JobOpeningPausedTag({
  pausedAt,
  showInfo,
}: JobOpeningPausedTagProps) {
  return isJobOpeningPaused(pausedAt) ? (
    <TagWithInfo>
      <Tag
        data-rh-at="bottom"
        data-rh="New candidates won’t be able to apply. You can still invite existing candidates to the next stages."
      >
        Paused
      </Tag>
      {showInfo && pausedAt && (
        <Info>
          This job is paused from {formatDate(pausedAt, 'MMMM DD, YYYY')}
        </Info>
      )}
    </TagWithInfo>
  ) : null;
}

const TagWithInfo = styled.span`
  display: flex;
  margin-right: auto;
`;

const Tag = styled.span`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.orange[120]};
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  padding: 0 8px;
  border-radius: 8px;
`;

const Info = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.typography.colorPrimary};
  margin-left: 8px;
`;
