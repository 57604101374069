import React, { useRef, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { amplitude } from '@/features/amplitude';
import {
  Icon,
  Popover,
  SemanticButton,
  TSortDirection,
  getOppositeSortDirection,
} from '@/features/common';
import {
  IPipelineCategory,
  TPipelineCandidatesSortOrder,
} from '@/features/pipeline/types';
import { useStoreData } from '@/features/storage';
import { useJobOpeningIdOrNull } from '@/hooks/router';

import { getPipelineSortStorageKey } from '../../../utils';

import { Menu } from './menu';
import { MenuItem } from './menu-item';
import { MenuSubheader } from './menu-subheader';
import { SortDirection } from './sort-direction';

interface SortProps {
  category: IPipelineCategory;
  hasCandidates: boolean;
  sortOrder: TPipelineCandidatesSortOrder | null;
  onSortBy: (sortOrder: TPipelineCandidatesSortOrder) => void;
  sortDirection: TSortDirection;
  onSortDirection: (sortDirection: TSortDirection) => void;
}

export const Sort = ({
  category,
  hasCandidates,
  sortOrder,
  onSortBy,
  sortDirection,
  onSortDirection,
}: SortProps) => {
  const popoverRef = useRef<React.ElementRef<typeof Popover>>(null);
  const jobOpeningId = useJobOpeningIdOrNull();
  const [, setLocalSort] = useStoreData<{
    order: TPipelineCandidatesSortOrder | null;
    direction: TSortDirection;
  }>(getPipelineSortStorageKey(jobOpeningId ?? '', category.id));

  const handleSortDirection = useCallback(
    (sortDirectionValue: TSortDirection) => {
      onSortDirection(sortDirectionValue);

      amplitude?.logEvent('pipeline/sort by applied', {
        'job opening id': jobOpeningId,
        'pipeline step': category.name,
        'sort by type': sortOrder,
        'sort by direction': sortDirectionValue,
      });
      setLocalSort({
        order: sortOrder,
        direction: sortDirectionValue,
      });
    },
    [setLocalSort, onSortDirection, category.name, jobOpeningId, sortOrder]
  );

  const handleSortBy = useCallback(
    (sortOrderValue: TPipelineCandidatesSortOrder) => {
      if (sortOrder === sortOrderValue) {
        handleSortDirection(getOppositeSortDirection(sortDirection));
      } else {
        onSortBy(sortOrderValue);

        setLocalSort({
          order: sortOrderValue,
          direction: sortDirection,
        });
        amplitude?.logEvent('pipeline/sort by applied', {
          'job opening id': jobOpeningId,
          'pipeline step': category.name,
          'sort by type': sortOrderValue,
          'sort by direction': sortDirection,
        });
      }
    },
    [
      setLocalSort,
      onSortBy,
      category.name,
      jobOpeningId,
      sortDirection,
      sortOrder,
      handleSortDirection,
    ]
  );

  const resetSortToDefault = () => {
    onSortBy('date');
    onSortDirection('desc');
    setLocalSort({
      order: 'date',
      direction: 'desc',
    });
  };

  const sortName = useMemo(() => {
    switch (sortOrder) {
      case 'name':
        return 'Name';
      case 'overallScore':
        return 'Score';
      case 'stars':
        return 'Rating';
      case 'date':
        return 'Date';
      default:
        return 'Sort';
    }
  }, [sortOrder]);

  return (
    <StyledPopover ref={popoverRef}>
      <Popover.Trigger>
        {({ isOpen, onClick }) => (
          <SortButtonWrapper>
            <SortButton
              onClick={() =>
                handleSortDirection(getOppositeSortDirection(sortDirection))
              }
            >
              <SortDirection direction={sortDirection} fontSize={8} />
            </SortButton>
            <SortButton onClick={onClick}>
              {sortName}
              <Icon icon={regular('chevron-down')} fontSize={8} />
            </SortButton>
          </SortButtonWrapper>
        )}
      </Popover.Trigger>
      <PopoverContent>
        <Menu>
          <MenuSubheader>
            <span>Sort by</span>
          </MenuSubheader>
          {hasCandidates ? (
            <>
              <MenuItem isActive={sortOrder === 'date'}>
                <SemanticButton onClick={() => handleSortBy('date')}>
                  <IconWrapper>
                    <Icon icon={regular('calendar')} fontSize={14} />
                  </IconWrapper>
                  <span>Date</span>
                  {sortOrder === 'date' && (
                    <SortDirection direction={sortDirection} />
                  )}
                </SemanticButton>
              </MenuItem>
              <MenuItem isActive={sortOrder === 'name'}>
                <SemanticButton onClick={() => handleSortBy('name')}>
                  <IconWrapper>
                    <Icon icon={regular('a')} fontSize={14} />
                  </IconWrapper>
                  <span>Name</span>
                  {sortOrder === 'name' && (
                    <SortDirection direction={sortDirection} />
                  )}
                </SemanticButton>
              </MenuItem>
              <MenuItem isActive={sortOrder === 'overallScore'}>
                <SemanticButton onClick={() => handleSortBy('overallScore')}>
                  <IconWrapper>
                    <Icon icon={regular('circle-check')} fontSize={14} />
                  </IconWrapper>
                  <span>Score</span>
                  {sortOrder === 'overallScore' && (
                    <SortDirection direction={sortDirection} />
                  )}
                </SemanticButton>
              </MenuItem>
              <MenuItem isActive={sortOrder === 'stars'}>
                <SemanticButton onClick={() => handleSortBy('stars')}>
                  <IconWrapper>
                    <Icon icon={regular('star')} fontSize={14} />
                  </IconWrapper>
                  <span>Rating</span>
                  {sortOrder === 'stars' && (
                    <SortDirection direction={sortDirection} />
                  )}
                </SemanticButton>
              </MenuItem>
              <ClearItem>
                <SemanticButton onClick={() => resetSortToDefault()}>
                  <span>Clear</span>
                </SemanticButton>
              </ClearItem>
            </>
          ) : null}
        </Menu>
      </PopoverContent>
    </StyledPopover>
  );
};

const SortButtonWrapper = styled.div`
  font-size: 12px;
  padding: 0 4px;
  height: 20px;
  border: 0;
  display: flex;
  align-items: center;
`;

const SortButton = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  background: transparent;
  border: 0;
  padding: 0 4px;
  color: ${({ theme }) => theme.colors.purple[60]};
  outline: 0;
  cursor: pointer;
`;

const StyledPopover = styled(Popover)`
  margin-left: auto;
`;

const PopoverContent = styled(Popover.Content)`
  left: auto;
  right: 0;
`;

const IconWrapper = styled.div`
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearItem = styled(MenuItem)`
  border-top: 1px solid ${({ theme }) => theme.colors.purple[20]};
  margin-top: 4px;
  padding-top: 4px;
`;
