import styled from '@emotion/styled';

import { Box } from './box';

export const RecommendedCandidateContainer = styled(Box)`
  display: grid;
  padding: 12px;
  grid-template-areas:
    'name name name state'
    'tags tags tags tags'
    'fomo fomo fomo fomo';
  grid-template-columns: 1fr 1fr 1fr auto;
`;
