import React from 'react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';

import { amplitude } from '@/features/amplitude';
import { Icon, useCountryList } from '@/features/common';
import { IRecommendationSettings } from '@/features/job-opening';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

interface FilterProps {
  settings?: IRecommendationSettings;
}

export function Filter({ settings }: FilterProps) {
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();
  const { getCountryName } = useCountryList();

  const isEnabled = settings?.enabled ?? false;
  const countryNames = settings?.countryCodes?.map(getCountryName);

  return (
    <Container>
      <Content
        enabled={isEnabled}
        data-rh={isEnabled ? countryNames?.join(', ') : null}
        data-rh-at="bottom"
      >
        <IconWrapper>
          <Icon
            icon={isEnabled ? solid('filter') : regular('filter')}
            color="purple-60"
            fontSize={12}
          />
        </IconWrapper>
        <Label>
          {isEnabled
            ? countryNames?.map((name, index) => (
                <React.Fragment key={index}>
                  <span>{name}</span>
                  {countryNames.length > index + 1 ? ', ' : ''}
                </React.Fragment>
              ))
            : 'Worldwide, remote'}
        </Label>
      </Content>

      <EditLink
        to={`/admin/${workspaceId}/openings/${jobOpeningId}/job-settings`}
        onClick={() => {
          amplitude?.logEvent('recommendations filter/edit', {
            'workspace id': workspaceId,
            'job opening id': jobOpeningId,
          });
        }}
      >
        Edit
      </EditLink>
    </Container>
  );
}

const Container = styled.div`
  height: 28px;
  padding: 0 12px;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 8px;
`;

const Content = styled.div<{ enabled: boolean }>`
  height: 20px;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: transparent;
  transition: background 0.1s;

  &:hover {
    background: ${({ enabled, theme }) =>
      enabled ? theme.hover.onWhite : 'transparent'};
  }
`;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 0 4px;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.typography.colorSecondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span {
    display: inline-block;
    max-width: 100%;
    overflow: clip;
    text-overflow: ellipsis;
  }
`;

const EditLink = styled(Link)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.typography.colorPrimary};
  text-decoration-line: underline;
`;
