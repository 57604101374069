import * as React from 'react';
import styled from '@emotion/styled';

import { useSmartlookPause } from '@/components/app/Smartlook';

import { AuthPageLayout } from '../auth-page-layout';

interface AuthPageProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  form?: React.ReactNode;
  footer?: React.ReactNode;
}

export function AuthPage({ title, subTitle, form, footer }: AuthPageProps) {
  useSmartlookPause();

  return (
    <AuthPageLayout>
      <Content>
        <Body>
          {title ? <Title>{title}</Title> : null}
          {subTitle ? <Subtitle>{subTitle}</Subtitle> : null}

          {form ? <div>{form}</div> : null}
        </Body>

        {footer ? <Footer>{footer}</Footer> : null}
      </Content>
    </AuthPageLayout>
  );
}

const Content = styled.div`
  width: 436px;
  padding: 0 50px 0 50px;

  @media all and (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 50px 20px;
  }
`;

const Body = styled.div`
  margin-top: 20px;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.github};
`;

const Subtitle = styled.div`
  margin-bottom: 20px;
  line-height: 21px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -42px;
  left: 0;
  margin: 0 -10px;

  @media all and (max-width: 768px) {
    position: initial;
    margin-top: 30px;
  }
`;
