import { ICandidateTestState } from '@/features/candidate';

type TCandidateTestStateDetails = {
  label: string;
  backgroundColor: string;
  borderColor: string;
  borderColorSecondary?: string;
};

const candidateTestStateMap: { [id: string]: TCandidateTestStateDetails } = {
  invited: {
    label: 'Invited',
    backgroundColor: '#FFEEC8',
    borderColor: '#FFDE91',
  },
  not_invited: {
    label: 'Not invited',
    backgroundColor: '#EAE7EB',
    borderColor: '#817187',
  },
  interested: {
    label: 'Interested',
    backgroundColor: '#FFEEC8',
    borderColor: '#FFDE91',
  },
  out_of_time: {
    label: 'Out of time',
    backgroundColor: '#FDEEEE',
    borderColor: '#EB5757',
  },
  in_progress: {
    label: 'In progress',
    backgroundColor: '#FFEEC8',
    borderColor: '#FFDE91',
  },
  completed: {
    label: 'Completed',
    backgroundColor: '#E8F9F3',
    borderColor: '#1DC488',
    borderColorSecondary: '#EB5757',
  },
};

export const getCandidateTestStateDetails = (
  testState: ICandidateTestState | null
): TCandidateTestStateDetails | null =>
  testState && candidateTestStateMap[testState];

export const getCandidateTestStateBorderColor = (
  testState: ICandidateTestState | null,
  score: number,
  scoreToPass?: number,
  rejected?: boolean
): string => {
  if (rejected) return '#EB5757';
  if (!testState) return 'transparent';
  if (testState === 'completed' && score < (scoreToPass || 60))
    return candidateTestStateMap[testState].borderColorSecondary!;
  return candidateTestStateMap[testState].borderColor;
};
