import api from '@/api/api';
import { ICandidateAttachment } from '@/features/candidate';
import { TId } from '@/features/common';
import { parseAPIData } from '@/utils/parsers';

export async function fetchCandidateAttachments(params: {
  candidateId: TId;
}): Promise<ICandidateAttachment[] | null> {
  const response = await api.get(
    `/test-takers/${params.candidateId}/attachments`
  );

  return parseAPIData(response.data.attachments);
}

export async function downloadCandidateAttachment(params: {
  attachmentId: TId;
}): Promise<ICandidateAttachment> {
  const response = await api.get(`/attachments/${params.attachmentId}`);

  return parseAPIData(response.data);
}

export async function deleteCandidateAttachment(params: {
  attachmentId: TId;
}): Promise<void> {
  await api.delete(`/attachments/${params.attachmentId}`);
}

export async function uploadCandidateAttachment(params: {
  candidateId: TId;
  source: File;
}): Promise<ICandidateAttachment> {
  const requestData = new FormData();
  requestData.append('upload', params.source);

  const response = await api.post(
    `/test-takers/${params.candidateId}/attachments`,
    requestData
  );

  return parseAPIData(response.data);
}
