import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Form, Formik } from 'formik';
import { isEmpty, pick } from 'lodash';

import { amplitude } from '@/features/amplitude';
import {
  TFilterType,
  useCandidateSearchParams,
} from '@/features/candidate-search';
import {
  callAll,
  FormikCheckboxField,
  Icon,
  Popover,
  StarRating,
} from '@/features/common';
import { useJobOpeningIdOrNull } from '@/hooks/router';

import { ApplyButton, SelectAllButton, UnselectAllButton } from '../buttons';
import {
  FieldWrapper,
  HoverableFieldWrapper,
} from '../candidate-filters/ui/field-wrapper';
import { FilterName } from '../candidate-filters/ui/filter-name';
import {
  FullWidthPopover,
  IconWrapper,
  SubContent,
  Trigger,
} from '../candidate-filters/ui/popover';

export const STARS_FILTER_TYPE: TFilterType = 'stars';
const STARS = ['0', '1', '2', '3', '4', '5'];
export const StarsFilter = ({ onApply }) => {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();
  const jobOpeningId = useJobOpeningIdOrNull();

  return (
    <FullWidthPopover>
      <Popover.Trigger>
        {({ onClick }) => (
          <Trigger onClick={onClick}>
            <IconWrapper>
              <Icon icon={regular('star')} color="purple-60" />
            </IconWrapper>
            Rating
          </Trigger>
        )}
      </Popover.Trigger>
      <SubContent position="right">
        {({ close }) => (
          <>
            <Formik
              initialValues={pick(candidatesSearchParams, STARS_FILTER_TYPE)}
              onSubmit={values => {
                if (isEmpty(values)) {
                  removeSearchParam(STARS_FILTER_TYPE);
                } else {
                  addSearchParams(values);
                  amplitude?.logEvent('candidate filters/filter applied', {
                    'filter type': STARS_FILTER_TYPE,
                    'filter value': values,
                    'filter origin': jobOpeningId
                      ? 'pipeline'
                      : 'all candidates',
                    'job opening id': jobOpeningId,
                  });
                }
                callAll(onApply, close);
              }}
            >
              <Form>
                <SelectionFieldWrapper>
                  <FullWidthFilterName>Rating</FullWidthFilterName>
                  <SelectAllButton name={STARS_FILTER_TYPE} values={STARS} />
                  <UnselectAllButton name={STARS_FILTER_TYPE} />
                </SelectionFieldWrapper>
                {STARS.map(star => (
                  <HoverableFieldWrapper key={star}>
                    <StyledFormikCheckboxField
                      name={STARS_FILTER_TYPE}
                      value={star}
                      label={
                        <StarRating
                          size="small"
                          value={Number(star)}
                          first={1}
                          last={5}
                          readOnly
                        />
                      }
                    />
                  </HoverableFieldWrapper>
                ))}
                <ApplyButton />
              </Form>
            </Formik>
          </>
        )}
      </SubContent>
    </FullWidthPopover>
  );
};

//region Styles
const FullWidthFilterName = styled(FilterName)`
  flex: 1;
  padding-left: 0;
  padding-right: 0;
`;
const SelectionFieldWrapper = styled(FieldWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
const StyledFormikCheckboxField = styled(FormikCheckboxField)`
  align-items: center;
`;

//endregion
