import React from 'react';
import { DSButton, DSIconPlus } from '@hundred5/design-system';

import { useHistory, useWorkspaceId } from '@/hooks/router';

export const InviteButton = () => {
  const workspaceId = useWorkspaceId();
  const history = useHistory();

  return (
    <DSButton
      variant="secondary"
      Icon={DSIconPlus}
      onClick={() => history.push(`/admin/${workspaceId}/settings/team/invite`)}
    >
      Add member
    </DSButton>
  );
};
