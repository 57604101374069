import styled from '@emotion/styled';

import { withDisabledCopy } from '@/features/common';

export const Container = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  grid-auto-rows: auto;

  background-color: ${props => props.theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.purple[10]};
  box-shadow: ${({ theme }) => theme.shadow.card};
  width: 100%;
`;

export const DisabledCopyContainer = withDisabledCopy(Container);
