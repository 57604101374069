import styled from '@emotion/styled';

export const FilterListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 8px;
  font-size: 12px;

  background: ${({ theme }) => theme.colors.purple[10]};
  border-radius: 8px;
`;
