import React from 'react';
import styled from '@emotion/styled';

import { TRecommendedCandidateSkill } from '@/features/job-opening';

import { SkillItem } from './skill-item';

export const SkillsList: React.FC<{ skills: TRecommendedCandidateSkill[] }> = ({
  skills,
}) => {
  return (
    <SkillsListContainer>
      <Title>Skills</Title>
      {skills?.map(skill => (
        <SkillItem key={skill.id} skillInfo={skill} />
      ))}
    </SkillsListContainer>
  );
};

const SkillsListContainer = styled.ul`
  padding: 24px 0;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple[100]};
  margin-bottom: 16px;
`;
