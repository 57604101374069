import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { useCreateCandidateInterviewNoteMutation } from '@/features/candidate/queries/candidate-interview-notes';
import { TId } from '@/features/common';
import { useJobOpeningIdOrNull, useWorkspaceId } from '@/hooks/router';

//region Main
interface AddInterviewNoteButtonProps {
  candidateId: TId;
  interviewId: TId;
  content: string;
  userIds: TId[];
  onAdded: () => void;
}

export function AddInterviewNoteButton({
  candidateId,
  content,
  userIds,
  interviewId,
  onAdded,
}: AddInterviewNoteButtonProps) {
  const jobOpeningId = useJobOpeningIdOrNull();
  const mutation = useCreateCandidateInterviewNoteMutation();
  const workspaceId = useWorkspaceId();

  return (
    <AddButton
      size="small"
      disabled={mutation.isLoading}
      onClick={async () => {
        if (content) {
          await mutation.mutateAsync({
            interviewId,
            candidateId,
            content,
            userIds,
          });

          amplitude?.logEvent('candidates/add inteview note', {
            'workspace id': workspaceId,
            'job opening id': jobOpeningId,
            'candidate id': candidateId,
            'interview id': interviewId,
          });

          onAdded();
        }
      }}
    >
      {mutation.isLoading ? 'Adding...' : 'Add note'}
    </AddButton>
  );
}

//endregion

//region Styles
const AddButton = styled(DSButton)`
  margin: 15px 0 0 auto;

  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
//endregion
