import React, { useContext } from 'react';

import { ShareModalContext } from './components/share-modal-context';

export const useFileDownload = () => {
  let textFile = React.useRef<string | null>(null);

  return (text: string): string => {
    const data = new Blob([text], { type: 'text/plain' });

    if (textFile.current !== null) {
      window.URL.revokeObjectURL(textFile.current!);
    }

    textFile.current = window.URL.createObjectURL(data);

    return textFile.current;
  };
};

export const useShareModal = () => {
  const context = useContext(ShareModalContext);

  if (!context) {
    throw new Error('useShareModal must be used within a ModalProvider');
  }

  const {
    isOpen,
    jobOpeningIdSelected,
    categorySelected,
    selectJobOpening,
    selectCategory,
    open,
    close,
  } = context;

  return {
    isShareModalOpen: isOpen,
    jobOpeningIdSelected,
    categorySelected,
    selectJobOpening,
    selectCategory,
    openShareModal: open,
    closeShareModal: close,
  };
};
