import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  deleteApiKey,
  fetchApiKeys,
  generateApiKey,
  updateApiKey,
} from '@/features/integrations';
import { useWorkspaceId } from '@/hooks/router';

const API_KEY_INTEGRATION_KEY = ['api-key-integration'];

export const useApiKeysQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  queryClient.setMutationDefaults(API_KEY_INTEGRATION_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries([...API_KEY_INTEGRATION_KEY, workspaceId]),
  });

  return useQuery(
    [...API_KEY_INTEGRATION_KEY, workspaceId],
    () => fetchApiKeys({ workspaceId }),
    {
      ...(options as any),
    }
  );
};

export const useGenerateApiKeyMutation = (options?: UseMutationOptions) => {
  const workspaceId = useWorkspaceId();

  return useMutation((name?: string) => generateApiKey({ workspaceId, name }), {
    mutationKey: API_KEY_INTEGRATION_KEY,
    ...(options as any),
  });
};

export const useUpdateApiKeyMutation = (options?: UseMutationOptions) => {
  return useMutation(updateApiKey, {
    mutationKey: API_KEY_INTEGRATION_KEY,
    ...(options as any),
  });
};

export const useDeleteApiKeyMutation = (options?: UseMutationOptions) => {
  return useMutation(deleteApiKey, {
    mutationKey: API_KEY_INTEGRATION_KEY,
    ...(options as any),
  });
};
