import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import DOMPurify from 'dompurify';

import {
  useCandidateEmailQuery,
  useDeleteGmailEmailMutation,
  useSyncGmailEmailsMutation,
} from '@/features/candidate';
import {
  Icon,
  Pagination,
  TId,
  Timeline,
  TimelinePoint,
} from '@/features/common';
import { useEmailModalContext } from '@/features/email';
import {
  useEnableGmailMutation,
  EmailBanner,
  useEmailBanner,
  TGmailStatus,
} from '@/features/me';
import { useWorkspace } from '@/features/workspace';
import { useHistory } from '@/hooks/router';

import { formatTimestamp } from '../candidate-history/candidate-history.utils';

import {
  Container,
  ManualSyncButton,
  Message,
  NoEmail,
  NoEmailIllustration,
  Wrapper,
} from './ui';

interface CandidateEmailProps {
  candidateId: string;
  interviewId: TId;
  gmailStatus?: TGmailStatus;
  candidateEmail: string;
}

export function CandidateEmail({
  candidateId,
  interviewId,
  gmailStatus,
  candidateEmail,
}: CandidateEmailProps) {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const history = useHistory();
  const workspace = useWorkspace();
  const { showBanner } = useEmailBanner();
  const { openEmailModal } = useEmailModalContext();

  const { data: candidateEmails, isLoading } = useCandidateEmailQuery({
    candidateId: candidateId,
    limit: pagination.pageSize,
    offset: (pagination.page - 1) * pagination.pageSize,
  });

  // We don't need to call the useEnableGmailCallbackMutation, since after auth the user will be rediretected to the profile page
  const enableGmailMeMutation = useEnableGmailMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });

  const syncGmailEmailsMutation = useSyncGmailEmailsMutation({ candidateId });
  const deleteGmailEmailMutation = useDeleteGmailEmailMutation({ candidateId });

  const showPagination = (candidateEmails?.total ?? 0) > pagination.pageSize;
  const isGmailIntegrationEnabled =
    workspace?.gmailEnabled && gmailStatus === 'enabled';

  if (isLoading) return null;

  if ((candidateEmails?.total ?? 0) === 0) {
    return (
      <Container>
        <NoEmail>
          <NoEmailIllustration />
          <h3>Nothing to see (yet!)</h3>
          <p>
            Emails you send to candidates will be displayed here. To view emails
            from the candidate, you can connect to Gmail.
          </p>
          <DSButton
            variant="secondary"
            onClick={() => {
              openEmailModal({
                type: isGmailIntegrationEnabled
                  ? 'gmail-candidate'
                  : 'contact-candidate',
                meta: {
                  candidateId: candidateId,
                  interviewId: interviewId,
                },
              });
            }}
          >
            Email candidate
          </DSButton>
        </NoEmail>
        {isGmailIntegrationEnabled && (
          <ManualSyncButton
            isLoading={syncGmailEmailsMutation.isLoading}
            onClick={() => {
              syncGmailEmailsMutation.mutate();
            }}
          />
        )}
      </Container>
    );
  }

  return (
    <>
      {showBanner && (
        <Wrapper>
          <EmailBanner
            size="small"
            onClick={() =>
              history.push(`/admin/${workspace?.id}/settings/profile`)
            }
          />
        </Wrapper>
      )}

      {gmailStatus === 'disconnected' && (
        <Wrapper>
          <EmailBanner
            size="small"
            status={gmailStatus}
            onClick={() => {
              enableGmailMeMutation.mutate();
            }}
          />
        </Wrapper>
      )}

      <Container>
        <Timeline>
          {candidateEmails?.data?.map((email, index) => {
            const first = pagination.page === 1 && index === 0;
            const last =
              index + 1 === candidateEmails?.data.length &&
              Math.ceil(candidateEmails?.data.length / pagination.pageSize) ===
                pagination.page;

            const candidateReply = email.from?.email === candidateEmail;
            const sanitizedHTML = DOMPurify.sanitize(email.message);

            return (
              <TimelinePoint
                key={email.id}
                icon={
                  <Icon
                    icon={solid('envelope')}
                    color="white"
                    fontSize={12}
                    fixedWidth
                  />
                }
                title={
                  candidateReply ? (
                    <>
                      <strong>Candidate replied</strong> from {candidateEmail}
                    </>
                  ) : (
                    <>
                      <strong>{email.from?.name}</strong> sent an email
                    </>
                  )
                }
                subtitle={
                  email.timestamp && formatTimestamp(new Date(email.timestamp))
                }
                details={
                  <Message
                    dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                  />
                }
                actions={
                  <>
                    {isGmailIntegrationEnabled && candidateReply && (
                      <DSButton
                        variant="secondary"
                        size="xsmall"
                        Icon={
                          <Icon
                            icon={regular('reply')}
                            fontSize={12}
                            fixedWidth
                          />
                        }
                        onClick={() => {
                          openEmailModal({
                            type: 'gmail-candidate',
                            meta: {
                              candidateId: candidateId,
                              emailId: email.id,
                            },
                            props: {
                              initialSubject: email.subject,
                            },
                          });
                        }}
                      >
                        Reply
                      </DSButton>
                    )}
                    {email.source === 'user' && (
                      <DSButton
                        variant="tertiary"
                        size="xsmall"
                        Icon={<Icon icon={regular('trash')} />}
                        disabled={deleteGmailEmailMutation.isLoading}
                        onClick={() => {
                          deleteGmailEmailMutation.mutate({
                            candidateId: candidateId,
                            emailId: email.id,
                          });
                        }}
                      />
                    )}
                  </>
                }
                first={first}
                last={last}
              />
            );
          })}

          {showPagination ? (
            <PaginationContainer>
              <Pagination
                total={candidateEmails?.total}
                current={pagination.page}
                pageSize={pagination.pageSize}
                onChange={(newPage, newPageSize) => {
                  setPagination({
                    page: newPageSize !== pagination.pageSize ? 1 : newPage,
                    pageSize: newPageSize,
                  });
                }}
              />
            </PaginationContainer>
          ) : null}
        </Timeline>

        {isGmailIntegrationEnabled && (
          <ManualSyncButton
            isLoading={syncGmailEmailsMutation.isLoading}
            onClick={() => {
              syncGmailEmailsMutation.mutate();
            }}
          />
        )}
      </Container>
    </>
  );
}

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
`;
