import configuration from '@/configuration/runtime';

const enabled = !!configuration.hubspotAccountId;
const supportEmail = configuration.supportEmail;

const isRealUser = (email?: string): boolean => {
  return !!email && email !== supportEmail;
};

interface ContactProperties {
  email: string;
}

const pushIdentify = (params: Partial<ContactProperties>) => {
  window._hsq.push(['identify', params]);
};

const pushSetPage = (path: string) => {
  window._hsq.push(['setPath', path]);
};

const pushPageView = () => {
  window._hsq.push(['trackPageView']);
};

export const identifyContact = (email: string) => {
  if (!isRealUser(email)) return;

  if (enabled) {
    pushIdentify({ email });
  }
};

export const trackPageView = (path: string, userEmail?: string) => {
  if (!isRealUser(userEmail)) return;

  if (enabled) {
    pushSetPage(path);
    pushPageView();
  }
};
