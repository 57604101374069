import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { CandidateTestStateTag, ICandidateTest } from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { CarouselNavigation } from '@/features/common/components/carousel-navigation';
import { useInterviewQuery } from '@/features/interview';
import { ITest, TTestType } from '@/features/test';
import { useCarousel } from '@/hooks/carousel';

import { useSortedCategories } from '../candidate-card.hooks';

//region Main

export interface CandidateCardTestTabsProps {
  candidateId: TId;
  selectedTabId?: TId | null;
  onSelect: (id: TId) => void;
}

export function TestTabs({
  candidateId,
  onSelect,
  selectedTabId,
}: CandidateCardTestTabsProps) {
  const sortedCategories = useSortedCategories(candidateId);

  const {
    containerRef,
    contentRef,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    offset,
  } = useCarousel({ slideStep: 100 });

  return (
    <TabsContainer>
      <TestsContainer ref={containerRef}>
        <TestsTabs style={offset} ref={contentRef}>
          {/* Candidate tests + current category test if not invited to it */}

          {sortedCategories?.map(category => {
            const isActive =
              selectedTabId === category.interviewId ||
              selectedTabId === category.testId;
            if (category.interviewId) {
              return (
                <InterviewTab
                  key={category.id}
                  active={isActive}
                  onSelect={onSelect}
                  interviewId={category.interviewId}
                />
              );
            } else if (category.test) {
              return (
                <TestTab
                  key={category.id}
                  test={category.test}
                  active={isActive}
                  onSelect={onSelect}
                />
              );
            }
          })}
        </TestsTabs>
      </TestsContainer>
      <CarouselNavigationWrapper>
        {canGoPrev || canGoNext ? (
          <CarouselNavigation
            canGoToPrev={canGoPrev}
            canGoToNext={canGoNext}
            goPrev={goPrev}
            goNext={goNext}
          />
        ) : null}
      </CarouselNavigationWrapper>
    </TabsContainer>
  );
}

interface TestTabProps {
  test: ICandidateTest | ITest;
  active: boolean;
  onSelect: (id: TId) => void;
}

function TestTab({ test, active, onSelect }: TestTabProps) {
  const testType = 'testType' in test ? test.testType : test.type;

  return (
    <TabElement
      key={test.id}
      active={active}
      onClick={() => onSelect(test.id)}
      data-rh={getTestTypeTooltip(testType)}
    >
      <TestContent>
        {getTestTypeIcon(testType, active)}

        <TestName title={test.name}>{test.name}</TestName>

        {'candidateState' in test ? (
          <CandidateTestStateTag
            testState={test.candidateState}
            score={test.percent}
            scoreToPass={test.percentToPass}
          />
        ) : (
          <CandidateTestStateTag testState="not_invited" score={0} />
        )}
      </TestContent>
    </TabElement>
  );
}

interface InterviewTabProps {
  interviewId: TId;
  active: boolean;
  onSelect: (id: TId) => void;
}

function InterviewTab({ active, onSelect, interviewId }: InterviewTabProps) {
  const { data: interview } = useInterviewQuery({ interviewId });

  return (
    <TabElement
      key={interview?.name}
      active={active}
      onClick={() => onSelect(interviewId)}
    >
      <TestContent>
        <Icon icon={active ? solid('comment') : regular('comment')} />

        <TestName title={interview?.name}>{interview?.name}</TestName>
      </TestContent>
    </TabElement>
  );
}

const getTestTypeTooltip = (testType: TTestType) => {
  switch (testType) {
    case 'quiz':
      return 'Skills test';
    case 'video':
      return 'Video intro';
    case 'homework':
      return 'Homework';
    default:
      return null;
  }
};

const getTestTypeIcon = (testType: TTestType, active: boolean) => {
  switch (testType) {
    case 'quiz':
      return <Icon icon={active ? solid('ruler') : regular('ruler')} />;
    case 'video':
      return <Icon icon={active ? solid('video') : regular('video')} />;
    case 'homework':
      return (
        <Icon icon={active ? solid('file-lines') : regular('file-lines')} />
      );
    default:
      return null;
  }
};

//endregion

//region Styles
const TabsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 36px;
  margin-top: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
`;

const TabElement = styled.div<{ active?: boolean }>`
  cursor: pointer;
  margin-right: 12px;
  padding-bottom: 8px;
  white-space: nowrap;

  :last-child {
    margin-right: 0;
  }

  ${props =>
    props.active &&
    css`
      border-bottom: 1px solid ${props.theme.colors.orange[100]};

      ${TestName} {
        color: ${props.theme.colors.purple[100]};
        font-weight: 700;
      }
    `}
`;

const TestsContainer = styled.div`
  overflow: hidden;
`;
const TestsTabs = styled.div`
  display: flex;
  position: relative;
  transition: 0.6s;
  transition-timing-function: ease-in-out;
  width: max-content;
  font-size: 12px;

  @media all and (max-width: 768px) {
    overflow-x: scroll;
    width: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }
  }
`;

const TestContent = styled.div`
  display: flex;
  align-items: center;
  height: 24px;

  > * {
    margin-right: 4px;

    :last-child {
      margin-right: 0;
    }
  }
`;

const TestName = styled.span`
  color: ${props => props.theme.colors.purple[120]};
  font-size: 12px;
  line-height: 150%;

  ::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

const CarouselNavigationWrapper = styled.div`
  margin-left: 24px;
  position: relative;
  width: 48px;

  @media all and (max-width: 768px) {
    display: none;
  }
`;
//endregion
