import React, { useCallback } from 'react';

import { useEmailModalContext } from '@/features/email';
import {
  IJobOpening,
  JobOpeningReviewLinkButton,
} from '@/features/job-opening';
import { useMeQuery } from '@/features/me';
import { useWorkspaceUsersQuery } from '@/features/workspace';
import { getJobOpeningPreviewUrl } from '@/utils/urls';

export function useSendForReview(jobOpening: IJobOpening) {
  const { openEmailModal } = useEmailModalContext();
  const { subject, message } = useInitialData(jobOpening);

  return useCallback(
    () =>
      openEmailModal({
        type: 'send-for-review',
        props: {
          initialSubject: subject,
          initialMessage: message,
          actionButton: <JobOpeningReviewLinkButton jobOpening={jobOpening} />,
        },
      }),
    [openEmailModal, jobOpening, subject, message]
  );
}

function useInitialData(jobOpening: IJobOpening) {
  const workspaceUsersQuery = useWorkspaceUsersQuery();
  const meQuery = useMeQuery();
  const myName = workspaceUsersQuery.data?.find(
    user => user.id === meQuery.data?.id
  )?.name;

  const previewUrl = jobOpening ? getJobOpeningPreviewUrl(jobOpening) : '';
  const subject = `Toggl Hire job opening review`;
  const message = `Hi,

We’re testing a new hiring tool and would love your feedback on this job opening. Here’s the link: 

${previewUrl}

Best,
${myName}`;

  return { subject, message };
}
