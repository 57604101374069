import React from 'react';
import styled from '@emotion/styled';

import { useCandidateSearchParams } from '@/features/candidate-search';
import { Pagination } from '@/features/common';
import { useWorkspaceCandidatesQuery } from '@/features/workspace/queries';

export const CandidatePagination = ({ disabled = false }) => {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
  } = useCandidateSearchParams();
  const query = useWorkspaceCandidatesQuery();
  const page = candidatesSearchParams.page
    ? parseInt(candidatesSearchParams.page)
    : 1;
  const itemsPerPage = candidatesSearchParams.itemsPerPage
    ? parseInt(candidatesSearchParams.itemsPerPage)
    : 10;

  const showPagination = (query.data?.total ?? 0) > itemsPerPage;

  if (!showPagination) {
    return null;
  }

  return (
    <Container>
      <Pagination
        total={query.data?.total}
        current={page}
        pageSize={itemsPerPage}
        onChange={(currentPage, currentItemsPerPage) => {
          addSearchParams({
            page:
              currentItemsPerPage !== itemsPerPage
                ? '1'
                : currentPage.toString(),
            itemsPerPage: currentItemsPerPage.toString(),
          });
        }}
        disabled={disabled}
      />
    </Container>
  );
};

//region Styles
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
//endregion
