import styled from '@emotion/styled';

import { SemanticButton } from '@/features/common';

export const LinkButton = styled(SemanticButton)`
  margin-top: 16px;
  text-decoration: underline;
  color: ${props => props.theme.typography.colorPrimary};
  font-size: 14px;
  font-weight: 400;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
