import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import {
  fetchJobOpeningFeedbackStats,
  fetchJobOpeningFeedback,
  fetchPublicFeedback,
  fetchPublicFeedbackStats,
} from '@/features/job-opening';

export const JOB_OPENING_FEEDBACK_QUERY_KEY = ['job-opening', 'feedback'];
export const JOB_OPENING_FEEDBACK_STATS_QUERY_KEY = [
  'job-opening',
  'feedback-stats',
];
export const PUBLIC_FEEDBACK_QUERY_KEY = ['public-feedback'];
export const PUBLIC_FEEDBACK_STATS_QUERY_KEY = ['public-feedback-stats'];

export const useJobOpeningFeedbackQuery = (
  params: {
    jobOpeningId: TId;
    pageSize?: number;
  },
  options?: UseInfiniteQueryOptions
) => {
  return useInfiniteQuery(
    [...JOB_OPENING_FEEDBACK_QUERY_KEY, params.jobOpeningId, params.pageSize],
    ({ pageParam }) =>
      fetchJobOpeningFeedback({
        jobOpeningId: params.jobOpeningId,
        pageSize: params.pageSize,
        page: pageParam,
      }),
    {
      initialPageParam: 0,
      getNextPageParam(lastPage, allPages) {
        const total = lastPage.total;
        const fetched = allPages.reduce(
          (sum, page) => sum + (page.feedback?.length || 0),
          0
        );
        return fetched < total ? allPages.length : undefined;
      },
      ...(options as any),
    }
  );
};

export const usePublicFeedbackQuery = (
  params: {
    pageSize?: number;
  },
  options?: UseInfiniteQueryOptions
) => {
  return useInfiniteQuery(
    [...PUBLIC_FEEDBACK_QUERY_KEY, params.pageSize],
    ({ pageParam }) =>
      fetchPublicFeedback({
        pageSize: params.pageSize,
        page: pageParam,
      }),
    {
      initialPageParam: 0,
      getNextPageParam(lastPage, allPages) {
        const total = lastPage.total;
        const fetched = allPages.reduce(
          (sum, page) => sum + (page.feedback?.length || 0),
          0
        );
        return fetched < total ? allPages.length : undefined;
      },
      ...(options as any),
    }
  );
};

export const useJobOpeningFeedbackStatsQuery = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...JOB_OPENING_FEEDBACK_STATS_QUERY_KEY, params.jobOpeningId],
    () => fetchJobOpeningFeedbackStats(params),
    options as any
  );
};

export const usePublicFeedbackStatsQuery = (options?: UseQueryOptions) => {
  return useQuery(
    [...PUBLIC_FEEDBACK_STATS_QUERY_KEY],
    () => fetchPublicFeedbackStats(),
    options as any
  );
};
