import api from '@/api/api';
import { TId } from '@/features/common';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

import {
  IEmailTemplate,
  IEmailTemplateVariable,
  TEmailTemplateForSave,
} from '../types';

export async function fetchEmailTemplates(
  workspaceId: TId
): Promise<IEmailTemplate[]> {
  const response = await api.get(`/workspaces/${workspaceId}/email_templates`);

  return parseAPIData<IEmailTemplate[]>(response.data.templates);
}

export async function saveEmailTemplate(
  emailTemplate: TEmailTemplateForSave
): Promise<IEmailTemplate> {
  const response = await api.post(
    `/workspaces/${emailTemplate.workspaceId}/email_templates`,
    serialiseAPIData(emailTemplate)
  );

  return parseAPIData<IEmailTemplate>(response.data);
}

export async function updateEmailTemplate(
  workspaceId: TId,
  emailTemplate: IEmailTemplate
): Promise<IEmailTemplate> {
  const response = await api.put(
    `/workspaces/${workspaceId}/email_templates//${emailTemplate.id}`,
    serialiseAPIData(emailTemplate)
  );

  return parseAPIData<IEmailTemplate>(response.data);
}

export async function deleteEmailTemplate(
  workspaceId: TId,
  emailTemplateId: TId
): Promise<TId> {
  await api.delete(
    `/workspaces/${workspaceId}/email_templates/${emailTemplateId}`
  );

  return emailTemplateId;
}

export async function fetchEmailTemplateVariables(): Promise<
  IEmailTemplateVariable[]
> {
  const response = await api.get(`/email_templates/variables`);

  return parseAPIData(response.data.variables) || [];
}

export async function sendEmailTemplatePreview(
  workspaceId: TId,
  emailTemplateId: TId,
  emailTemplateDomain: 'workspace' | 'system'
): Promise<void> {
  await api.post(
    `/workspaces/${workspaceId}/email_templates/${emailTemplateId}/preview`,
    serialiseAPIData({ emailTemplateDomain }),
    { captchaAction: 'email_template_preview' }
  );
}
