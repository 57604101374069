import configuration from '@/configuration/runtime';

export const getGoogleReCaptchaToken = (action: string): Promise<string> => {
  return new Promise((res, rej) => {
    window.grecaptcha.ready(() => {
      try {
        window.grecaptcha
          .execute(configuration.recaptchaSiteKey, { action })
          .then(token => {
            return res(token);
          })
          .catch(error => {
            return rej(error);
          });
      } catch (error) {
        rej(error);
      }
    });
  });
};
