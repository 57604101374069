import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSContentBlock, DSField } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import {
  FormikInputField,
  FormikRadioField,
  Icon,
  pluralizeWithCount,
  PromptIfDirty,
  TimeInput,
} from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useJobOpeningPermission } from '@/features/permissions';
import {
  HomeworkTimeUnit,
  useTestPage,
  useTestPageAnalytics,
  useUpdateTestMutation,
} from '@/features/test';

import { TestSettingsSection } from '../test-settings-section';

interface Settings {
  name: string;
  timeSetting: 'auto' | 'manual';
  duration: number;
}

/**
 * Only name is validated as required on client, minimal time is validated on BE side after submit
 */
const validationSchema = yup.object().shape({
  name: yup.string().required("This can't be blank!"),
  duration: yup
    .number()
    // validate duration only when timeSetting is manual
    .when('timeSetting', {
      is: 'manual',
      then: yup
        .number()
        .integer('Duration must be a whole number')
        .min(1, 'Minimum time is 1 hour')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
});

export const HomeworkTestSettings = () => {
  useTestPageAnalytics('homework test settings');
  const { showFlashMessage } = useFlashMessages();
  const canUpdate = useJobOpeningPermission()('test', 'update');
  const { test } = useTestPage();
  const updateTestMutation = useUpdateTestMutation();

  const initialValues: Settings = {
    name: test.name,
    timeSetting: test.durationInSeconds === 0 ? 'auto' : 'manual',
    duration:
      test.durationInSeconds === 0
        ? test.approximatedDurationInSeconds / HomeworkTimeUnit.inSeconds
        : test.durationInSeconds / HomeworkTimeUnit.inSeconds,
  };

  const handleSubmit = async (values: Settings, { resetForm }) => {
    const updatedTest = {
      name: values.name,
      durationInSeconds:
        values.timeSetting === 'auto'
          ? 0
          : values.duration * HomeworkTimeUnit.inSeconds,
    };

    await updateTestMutation.mutateAsync(
      {
        testId: test.id,
        test: updatedTest,
      },
      {
        onSuccess: () => {
          showFlashMessage({ type: 'saved' });
        },
      }
    );
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, dirty }) => (
        <Form>
          <PromptIfDirty />

          <DSContentBlock>
            <DSContentBlock.Title>General</DSContentBlock.Title>

            <DSContentBlock.Content>
              <TestSettingsSection>
                <TestSettingsSection.Content>
                  <NameInputField name="name" label="Test title" required />
                  <DSField label="Time limit" for="timeSetting">
                    <TestSettingsSection.OptionsWrapper>
                      <FormikRadioField
                        name="timeSetting"
                        value="auto"
                        label={
                          <TestSettingsSection.LabelWrapper>
                            Auto
                            <Icon
                              icon={regular('circle-info')}
                              color="purple-60"
                              fontSize="12px"
                              data-rh={`The Auto time limit allocates ${pluralizeWithCount(
                                1,
                                HomeworkTimeUnit.unit
                              )} per question.`}
                              data-rh-at="right"
                            />
                          </TestSettingsSection.LabelWrapper>
                        }
                      />
                      <FormikRadioField
                        name="timeSetting"
                        value="manual"
                        label={
                          <TimeInput
                            duration={values.duration}
                            timeUnit={HomeworkTimeUnit.unit}
                          >
                            <DurationInputField
                              name="duration"
                              disabled={values.timeSetting === 'auto'}
                              errorAbsolute
                            />
                          </TimeInput>
                        }
                        centered
                      />
                    </TestSettingsSection.OptionsWrapper>
                  </DSField>
                </TestSettingsSection.Content>
              </TestSettingsSection>
            </DSContentBlock.Content>

            <DSContentBlock.Footer>
              <DSButton
                type="submit"
                disabled={!canUpdate || !dirty || isSubmitting}
                data-rh={!canUpdate ? 'Only admins can save changes' : null}
              >
                {isSubmitting ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSContentBlock.Footer>
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
};

const NameInputField = styled(FormikInputField)`
  max-width: 312px;
`;

const DurationInputField = styled(FormikInputField)`
  width: 116px;
`;
