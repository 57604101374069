import React from 'react';

import {
  CandidateAnswers,
  CandidateAnswersSummary,
  CandidateSkillsSummary,
  CandidateSuspiciousActivity,
  CandidateTestStateMessages,
} from '@/features/candidate';
import { PreviousTestResults } from '@/features/candidate/components/candidate-card/previous-test-results';

export const CandidateResults = ({
  candidateId,
  candidateTest,
  previousTestTakerId,
  selectedTestId,
}) => {
  return (
    <>
      <CandidateTestStateMessages
        candidateId={candidateId}
        testId={selectedTestId!}
      />

      {candidateTest?.candidateState === 'completed' && (
        <>
          <CandidateAnswersSummary
            candidateId={candidateId}
            testId={candidateTest.id}
          />

          {candidateTest.testType === 'quiz' && (
            <>
              <CandidateSkillsSummary
                candidateId={candidateId}
                testId={candidateTest.id}
              />
              <CandidateSuspiciousActivity
                candidateId={candidateId}
                testId={candidateTest.id}
              />
            </>
          )}

          <CandidateAnswers
            candidateId={candidateId}
            testId={candidateTest.id}
          />
        </>
      )}

      <PreviousTestResults
        candidateId={previousTestTakerId}
        selectedTestId={selectedTestId}
      />
    </>
  );
};
