import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSField, DSInput, DSModal } from '@hundred5/design-system';
import { Form, Formik } from 'formik';

import { useClipboard } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { IAPIKey, useUpdateApiKeyMutation } from '@/features/integrations';

interface EditApiKeysProps {
  apiKey: IAPIKey | null;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onRevokeApiKey: () => void;
}

export const EditApiKeysModal = ({
  apiKey,
  open,
  onClose,
  onConfirm,
  onRevokeApiKey,
}: EditApiKeysProps) => {
  const [apiKeyInputValue, setApiKeyInputValue] = useState('');
  const { showFlashMessage } = useFlashMessages();

  const { copyButtonRef, valueRef } = useClipboard();
  valueRef.current = apiKey?.apiKey!;

  const {
    mutateAsync: updateApiKey,
    isLoading: updating,
  } = useUpdateApiKeyMutation();

  return (
    <DSModal
      fluid
      open={open}
      onClose={() => onClose()}
      contentStyle={{ width: '600px' }}
    >
      <Header>
        <HeaderTitle>Api key</HeaderTitle>
        <DSModal.Separator />
      </Header>
      <DSModal.CloseButton fixed onClick={onClose} small />
      <Formik
        initialValues={{ name: apiKey?.name ?? '' }}
        onSubmit={async () => {
          await updateApiKey({
            apiKeyId: apiKey?.id!,
            name: apiKeyInputValue,
          });
          onConfirm();
          onClose();
        }}
      >
        <Form>
          <ContainerKey>
            <StyledDSName label="Name" for="name">
              <DSInput
                name="name"
                value={apiKeyInputValue || apiKey?.name}
                placeholder="Name Your API"
                onChange={event => {
                  setApiKeyInputValue(event.target.value);
                }}
              />
            </StyledDSName>
            <StyledDSField label="API KEY" for="key">
              <DSInput value={apiKey?.apiKey} />
            </StyledDSField>
            <WrapperDSButton>
              <DSButton
                ref={copyButtonRef}
                variant="secondary"
                onClick={e => {
                  e.stopPropagation();
                  showFlashMessage({ type: 'link_copied' });
                }}
              >
                Copy
              </DSButton>
            </WrapperDSButton>
          </ContainerKey>
          <DSModal.Footer>
            <Wrapper>
              <DSButton
                variant="destructive-secondary"
                type="button"
                disabled={updating}
                onClick={() => {
                  onClose();
                  onRevokeApiKey();
                }}
              >
                Revoke this API key
              </DSButton>
            </Wrapper>
            <DSButton
              disabled={!apiKey?.apiKey || !apiKeyInputValue || updating}
              type="submit"
            >
              Save changes
            </DSButton>
          </DSModal.Footer>
        </Form>
      </Formik>
    </DSModal>
  );
};

const Header = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${props => props.theme.typography.colorPrimary};
  margin-bottom: 16px;
  @media all and (max-width: 768px) {
    padding-top: 8px;
  }
`;

const HeaderTitle = styled.div`
  padding-bottom: 16px;
  line-height: 19px;
`;

const ContainerKey = styled.div`
  display: grid;
  grid-template-areas:
    'input . .'
    'inputKey inputKey button';
  grid-auto-columns: 8fr 1fr 1fr;
  gap: 8px;
  align-items: flex-end;
  padding-bottom: 100px;
`;

const StyledDSName = styled(DSField)`
  grid-area: input;
`;

const StyledDSField = styled(DSField)`
  grid-area: inputKey;
`;

const WrapperDSButton = styled.div`
  grid-area: button;
`;

const Wrapper = styled.div`
  margin-right: auto;
`;
