import React from 'react';

import { SemanticButton } from '@/features/common';
import { IRecommendedCandidate } from '@/features/job-opening';

import {
  FOMO,
  Name,
  RecommendedCandidateContainer,
  RecommendedCandidateDecoration,
  RecommendedCandidateState,
  Skills,
} from './ui';

interface RecommendedCandidateBoxProps {
  candidate: IRecommendedCandidate;
  active?: boolean;
  onClick?: () => void;
}

export function RecommendedCandidateBox({
  candidate,
  active,
  onClick,
}: RecommendedCandidateBoxProps) {
  return (
    <SemanticButton onClick={onClick}>
      <RecommendedCandidateContainer active={active}>
        <RecommendedCandidateDecoration />
        <Name>
          {candidate.firstName} {candidate.lastName}
        </Name>
        <RecommendedCandidateState />
        <Skills skills={candidate.skills} />
        <FOMO />
      </RecommendedCandidateContainer>
    </SemanticButton>
  );
}
