import React from 'react';
import styled from '@emotion/styled';
import { DSApplicationPage } from '@hundred5/design-system';

import {
  JobOpeningApplicationFormFields,
  JobOpeningApplicationFormQuestions,
  useJobOpeningQuery,
} from '@/features/job-opening';
import { useWorkspace } from '@/features/workspace';

export function JobOpeningApplicationForm() {
  const { data: jobOpening } = useJobOpeningQuery();
  const workspace = useWorkspace();

  if (!workspace || !jobOpening) {
    return null;
  }

  return (
    <Container>
      <DSApplicationPage
        embedded
        titleImageUrl={jobOpening.titleImageUrl ?? undefined}
        titleImagePosition={jobOpening.titleImagePosition ?? undefined}
      >
        <DSApplicationPage.Header
          title={jobOpening.name}
          subtitle={workspace.name}
          logoUrl={workspace.logoUrl}
        />

        <JobOpeningApplicationFormFields />

        <DSApplicationPage.Separator />

        <JobOpeningApplicationFormQuestions />
      </DSApplicationPage>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${props => props.theme.colors.purple[10]};
  border-radius: 8px;
  flex: 1;
  padding: 16px;

  @media (min-width: 768px) {
    padding: 0 0 16px;
  }
`;
