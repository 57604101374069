import React, { useState } from 'react';
import styled from '@emotion/styled';

import { useBillingPlansConfig } from '@/features/billing';

//region Main
interface PeriodSelectorProps {
  selectedPeriod: 'monthly' | 'annual';
  setSelectedPeriod: React.Dispatch<React.SetStateAction<'monthly' | 'annual'>>;
}

export const PeriodSelector = ({
  selectedPeriod,
  setSelectedPeriod,
}: PeriodSelectorProps) => {
  const [hoveredPeriod, setHoveredPeriod] = useState<'monthly' | 'annual'>();
  const { yearlyPlansDiscountPercentage } = useBillingPlansConfig();

  return (
    <PeriodSelectorContainer data-testid="toggl-period">
      <PromoBadge> {`Save ${yearlyPlansDiscountPercentage}%`}</PromoBadge>
      <PeriodSelectorButtonBg
        selectedPeriod={selectedPeriod}
        hoveredPeriod={hoveredPeriod}
      />
      <PeriodSelectorButton
        active={selectedPeriod === 'annual'}
        differentButtonHoverActive={hoveredPeriod === 'monthly'}
        onClick={() => setSelectedPeriod('annual')}
        onMouseEnter={() => setHoveredPeriod('annual')}
        onMouseLeave={() => setHoveredPeriod(undefined)}
        data-testid="toggl-period-annual"
      >
        Annual
      </PeriodSelectorButton>
      <PeriodSelectorButton
        active={selectedPeriod === 'monthly'}
        differentButtonHoverActive={hoveredPeriod === 'annual'}
        onClick={() => setSelectedPeriod('monthly')}
        onMouseEnter={() => setHoveredPeriod('monthly')}
        onMouseLeave={() => setHoveredPeriod(undefined)}
        data-testid="toggl-period-monthly"
      >
        Monthly
      </PeriodSelectorButton>
    </PeriodSelectorContainer>
  );
};
//endregion

//region Styles
const PeriodSelectorContainer = styled.div`
  display: flex;
  position: relative;
  width: 330px;
  height: 52px;
  background: ${props => props.theme.colors.peach[100]};
  border-radius: 30px;
  padding: 3px 4px;
  gap: 4px;
`;

const PeriodSelectorButtonBg = styled.div<{
  selectedPeriod: 'monthly' | 'annual';
  hoveredPeriod?: 'monthly' | 'annual';
}>`
  position: absolute;
  z-index: 0;
  width: 159px;
  height: 46px;
  border-radius: 30px;
  background: ${props =>
    props.hoveredPeriod
      ? props.theme.colors.purple[60]
      : props.theme.colors.orange[100]};
  border-radius: 30px;
  transition: all 0.4s ease 0s;
  transform: translateX(
    ${props =>
      props.hoveredPeriod === 'annual'
        ? '0'
        : props.hoveredPeriod === 'monthly'
        ? '163px'
        : props.selectedPeriod === 'annual'
        ? '0'
        : '163px'}
  );
`;

const PeriodSelectorButton = styled.button<{
  active: boolean;
  differentButtonHoverActive: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 159px;
  height: 100%;
  border: none;
  border-radius: 30px;
  background-color: transparent;
  color: ${props =>
    !props.active || props.differentButtonHoverActive
      ? props.theme.colors.purple[100]
      : props.theme.colors.white};
  transition: color 150ms linear;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

const PromoBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.purple[100]};
  display: flex;
  align-items: center;
  position: absolute;
  transform: rotate(-12deg);
  padding: 5px 12px;
  border-radius: 8px;
  left: -34px;
  top: -32px;
  &::after {
    content: ' ';
    bottom: -4px;
    height: 8px;
    width: 8px;
    background-color: ${props => props.theme.colors.purple[100]};
    transform: rotate(45deg);
    position: absolute;
  }
`;
//endregion
