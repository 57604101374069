import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { SourceType } from '@/types/analytics';

interface JobOpeningAnalyticsTrafficSourceTabsProps {
  sourceType: SourceType;
  onChange: (sourceType: SourceType) => void;
}

export function JobOpeningAnalyticsTrafficSourceTabs({
  sourceType,
  onChange,
}: JobOpeningAnalyticsTrafficSourceTabsProps) {
  return (
    <TabsContainer className="traffic-sources-tabs">
      <Tab active={sourceType === 'url'} onClick={() => onChange('url')}>
        Regular
      </Tab>
      <Tab
        active={sourceType === 'source-medium'}
        onClick={() => onChange('source-medium')}
      >
        UTM source / medium
      </Tab>
      <Tab
        active={sourceType === 'campaign'}
        onClick={() => onChange('campaign')}
      >
        UTM campaign
      </Tab>
    </TabsContainer>
  );
}

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  height: 45px;
  margin-top: -36px;
`;

const Tab = styled.div<{ active: boolean }>`
  font-size: 12px;
  text-transform: capitalize;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  position: relative;
  bottom: -1px;

  ${props =>
    props.active &&
    css`
      font-weight: bold;
      border-bottom: 1px solid ${props.theme.colors.orange[100]};
    `}
`;
