import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Form, Formik } from 'formik';
import { isEmpty, pick } from 'lodash';

import { amplitude } from '@/features/amplitude';
import {
  TFilterType,
  useCandidateSearchParams,
} from '@/features/candidate-search';
import { callAll, Icon, Popover } from '@/features/common';
import { useJobOpeningIdOrNull } from '@/hooks/router';

import { ApplyButton } from '../buttons';
import {
  FullWidthPopover,
  IconWrapper,
  SubContent,
  Trigger,
} from '../candidate-filters/ui/popover';

import { DatePicker } from './ui/date-picker';

export const DATE_FILTER_TYPE: TFilterType = 'date';
export const DateFilter = ({ onApply }) => {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();
  const jobOpeningId = useJobOpeningIdOrNull();

  return (
    <FullWidthPopover>
      <Popover.Trigger>
        {({ onClick }) => (
          <Trigger onClick={onClick}>
            <IconWrapper>
              <Icon icon={regular('calendar-day')} color="purple-60" />
            </IconWrapper>
            Date applied
          </Trigger>
        )}
      </Popover.Trigger>
      <SubContent position="right">
        {({ close }) => (
          <Formik
            initialValues={pick(candidatesSearchParams, DATE_FILTER_TYPE)}
            onSubmit={values => {
              if (isEmpty(values)) {
                removeSearchParam(DATE_FILTER_TYPE);
              } else {
                addSearchParams(values);
                amplitude?.logEvent('candidate filters/filter applied', {
                  'filter type': DATE_FILTER_TYPE,
                  'filter value': values,
                  'filter origin': jobOpeningId ? 'pipeline' : 'all candidates',
                  'job opening id': jobOpeningId,
                });
              }
              callAll(onApply, close);
            }}
          >
            <Form>
              <DatePicker />
              <ApplyButton />
            </Form>
          </Formik>
        )}
      </SubContent>
    </FullWidthPopover>
  );
};
