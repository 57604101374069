import React from 'react';
import { DSButton } from '@hundred5/design-system';

import { useUploadCandidateAttachment } from '@/features/candidate/hooks/candidate-attachments';
import { TId } from '@/features/common';
import { usePreviewFeatureModal } from '@/features/preview-feature/hooks';
import { usePlanLimits } from '@/hooks/planLimits';

interface UploadAttachmentButtonProps {
  candidateId: TId;
}

export function UploadAttachmentButton({
  candidateId,
}: UploadAttachmentButtonProps) {
  const canAccessFeature = usePlanLimits();
  const showPreviewFeatureModal = usePreviewFeatureModal();
  const {
    candidateAttachmentInput,
    candidateAttachmentInputRef,
    candidateAttachmentUploadDisabled,
    candidateAttachmentUploading,
  } = useUploadCandidateAttachment({ candidateId });

  return (
    <>
      <DSButton
        variant="secondary"
        disabled={candidateAttachmentUploadDisabled}
        data-rh={
          candidateAttachmentUploadDisabled
            ? 'You can upload a maximum of 7 files'
            : null
        }
        onClick={() => {
          if (canAccessFeature('candidate_attachments')) {
            candidateAttachmentInputRef.current?.click();
          } else {
            showPreviewFeatureModal.open('attachments');
          }
        }}
      >
        {candidateAttachmentUploading ? 'Uploading...' : 'Upload file'}
      </DSButton>
      {candidateAttachmentInput}
    </>
  );
}
