import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import Markdown from '@/components/markdown/Markdown';
import { Icon } from '@/features/common';

interface HowToEvaluateProps {
  notes?: string;
}

export function HowToEvaluate({ notes }: HowToEvaluateProps) {
  const [expanded, setExpanded] = useState(false);

  if (!notes) {
    return null;
  }

  return (
    <Container expanded={expanded}>
      <Heading onClick={() => setExpanded(prevState => !prevState)}>
        How to evaluate?
        <Arrow
          up={expanded}
          icon={regular('chevron-down')}
          color="purple-60"
          fontSize="12px"
        />
      </Heading>
      <ContentWrapper>
        <Content>
          <Markdown source={notes} />
        </Content>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div<{ expanded: boolean }>`
  background: ${({ theme }) => theme.colors.purple[5]};
  margin: 20px -24px 0 -24px;
  display: grid;
  overflow: hidden;
  grid-template-columns: 100%;
  grid-template-rows: min-content ${({ expanded }) =>
      expanded ? '1fr' : '0fr'};
  transition: grid-template-rows 0.2s;
`;

const Heading = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  margin: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.purple[60]};
`;

const Arrow = styled(Icon)<{ up: boolean }>`
  transform: rotate(${({ up }) => (up ? 180 : 0)}deg);
  transition: transform 100ms ease-in-out;
`;

const ContentWrapper = styled.div`
  min-height: 0;
`;

const Content = styled.div`
  color: ${({ theme }) => theme.colors.purple[100]};
  padding: 0 24px 16px;

  p {
    margin: 0;
  }
  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  pre {
    white-space: pre;
    overflow-x: auto;
  }
`;
