import styled from '@emotion/styled';

import { BetterTextEditor } from './better-text-editor';

interface TextEditorProps {
  minHeight?: string;
}

export const CustomTextEditor = styled(BetterTextEditor)<TextEditorProps>`
  color: ${({ theme }) => theme.typography.colorPrimary};
  font-size: 14px;
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.purple[40]};
  height: auto;
  min-height: ${({ minHeight }) => minHeight || '360px'};
  overflow: auto;

  [contenteditable='false'] {
    color: ${({ theme }) => theme.colors.purple[60]};
  }

  & > :first-child {
    padding: 10px;
    background-color: ${props => props.theme.hover.onWhite};
  }

  & > :nth-child(2) {
    padding: 0 10px 10px 10px;
  }

  > :nth-child(2) > div.ProseMirror-widget {
    left: 10px;
  }

  @media all and (max-width: 768px) {
    & > div:first-child {
      display: none;
    }

    & > div:last-child {
      margin-top: 0;
    }
  }
`;
