import api from '@/api/api';
import { TId } from '@/features/common';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

import { TJobOpeningAccessRightType, IJobOpeningAccessRight } from '../types';

export async function fetchJobOpeningAccessRights(
  jobOpeningId: TId
): Promise<IJobOpeningAccessRight[]> {
  const response = await api.get(`/openings/${jobOpeningId}/access-rights`);
  return parseAPIData(response.data.access_rights);
}

export async function updateJobOpeningAccessRights(params: {
  jobOpeningId: TId;
  accessRights: {
    userId: TId;
    accessRight: TJobOpeningAccessRightType;
  }[];
}): Promise<IJobOpeningAccessRight[]> {
  const response = await api.put(
    `/openings/${params.jobOpeningId}/access-rights`,
    serialiseAPIData({ accessRights: params.accessRights })
  );

  return parseAPIData(response.data.access_rights);
}
