import React, { useCallback, useMemo } from 'react';
import { DSComboboxMultiple, DSField } from '@hundred5/design-system';

import { TId } from '@/features/common';
import {
  useCreateWorkspaceTagMutation,
  useWorkspaceTagsQuery,
} from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

interface TagsSelectorProps {
  selectedTagIds: TId[];
  onChange: (selectedTagIds: TId[]) => void;
}

export const TagsSelector = ({
  selectedTagIds,
  onChange,
}: TagsSelectorProps) => {
  const workspaceId = useWorkspaceId();
  const tagsQuery = useWorkspaceTagsQuery();
  const tagsMutation = useCreateWorkspaceTagMutation();
  const tags = useMemo(
    () =>
      tagsQuery.data?.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      ) || [],
    [tagsQuery.data]
  );

  const handleCreateTag = useCallback(
    async (value: string) => {
      const newTag = await tagsMutation.mutateAsync({
        workspaceId,
        name: value,
      });
      onChange([...selectedTagIds, newTag.id]);
    },
    [tagsMutation, workspaceId, onChange, selectedTagIds]
  );

  if (tagsQuery.isLoading) {
    return null;
  }

  return (
    <DSField for="tag-selector" label="Tags" required>
      <DSComboboxMultiple
        id="tag-selector"
        placeholder="Search or add"
        items={tags.map(tag => ({
          id: tag.id,
          label: tag.name,
        }))}
        selectedItemIds={selectedTagIds}
        onChange={selectedItemIds => onChange(selectedItemIds || [])}
        onCreate={handleCreateTag}
      />
    </DSField>
  );
};
