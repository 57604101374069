import styled from '@emotion/styled';

export const Container = styled.section`
  padding: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  flex: 1;
`;
