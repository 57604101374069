import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon, isTagRestricted, useColor } from '@/features/common';

interface TagItemProps {
  label: string;
  textColor?: string;
  backgroundColor?: string;
  onDelete?: (() => void) | null;
}

export function TagItem(props: TagItemProps) {
  const canDelete = !!props.onDelete && !isTagRestricted(props.label);
  const textColor = useColor(props.textColor, 'white');
  const backgroundColor = useColor(props.backgroundColor, 'purple-100');

  return (
    <TagItemWrapper
      canDelete={canDelete}
      textColor={textColor}
      backgroundColor={backgroundColor}
    >
      {props.label}

      {canDelete ? (
        <TagDeleteButton
          onClick={event => {
            event.stopPropagation();
            props.onDelete?.();
          }}
        >
          <Icon icon={regular('xmark')} color={textColor} fontSize="10px" />
        </TagDeleteButton>
      ) : null}
    </TagItemWrapper>
  );
}

//region Styles
const TagDeleteButton = styled.button`
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.1s;
`;

const TagItemWrapper = styled.div<{
  canDelete: boolean;
  textColor?: string;
  backgroundColor?: string;
}>`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.textColor};
  padding-left: 8px;
  padding-right: ${props => (props.canDelete ? '0' : '8px')};
  background: ${props => props.backgroundColor};
  border-radius: 8px;
  min-height: 24px;
  height: 24px;
  display: flex;
  overflow: hidden;
  align-items: center;

  &:hover ${TagDeleteButton} {
    opacity: 1;
  }
`;
//endregion
