import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { TId } from '@/features/common';
import { useWorkspaceId } from '@/hooks/router';

interface JobLinkProps {
  jobOpeningId: TId;
  jobOpeningName: string;
}

export function JobLink({ jobOpeningId, jobOpeningName }: JobLinkProps) {
  const workspaceId = useWorkspaceId();

  return (
    <StyledLink to={`/admin/${workspaceId}/openings/${jobOpeningId}`}>
      {jobOpeningName}
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: underline;
`;
