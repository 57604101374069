import styled from '@emotion/styled';

export const Title = styled.div`
  grid-area: title;
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.typography.colorPrimary};

  & strong {
    font-weight: 700;
  }
`;
