import React from 'react';

import SettingsContentBlock from '@/components/settings_content_block/SettingsContentBlock';
import { Loader } from '@/features/common';

export const LoadingCard = () => {
  return (
    <SettingsContentBlock title="Payment">
      <Loader />
    </SettingsContentBlock>
  );
};
