import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSContentBlock, DSGrid } from '@hundred5/design-system';
import formatDate from 'date-fns/format';

import { amplitude } from '@/features/amplitude';
import { ConfirmModal, Icon, useClipboard, useColor } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import {
  IAPIKey,
  useApiKeysQuery,
  useDeleteApiKeyMutation,
  useGenerateApiKeyMutation,
} from '@/features/integrations';
import { usePlanLimits } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';

import {
  WorkspaceSettingsIntegrationAction,
  WorkspaceSettingsIntegrationContainer,
  WorkspaceSettingsIntegrationDescription,
  WorkspaceSettingsIntegrationIcon,
  WorkspaceSettingsIntegrationLink,
  WorkspaceSettingsIntegrationTable,
} from '../workspace-settings-integrations-page';

import { EditApiKeysModal } from './ui';

export const WorkspaceSettingsIntegrationAPIKeys = () => {
  return (
    <DSContentBlock>
      <DSContentBlock.Title>API keys</DSContentBlock.Title>
      <DSContentBlock.Content>
        <DSGrid gap="16px">
          <APIKeysElement />
        </DSGrid>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const APIKeysElement = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const canAccessFeature = usePlanLimits();
  const [selectedApiKey, setSelectedApiKey] = useState<IAPIKey | null>(null);
  const { openUpsellModal } = useUpsellModal();
  const workspaceId = useWorkspaceId();

  const { data: apiKeys } = useApiKeysQuery();

  const {
    mutateAsync: generateApiKey,
    isLoading: generating,
  } = useGenerateApiKeyMutation();

  const { mutateAsync: deleteApiKey } = useDeleteApiKeyMutation();

  return (
    <>
      <WorkspaceSettingsIntegrationContainer>
        <WorkspaceSettingsIntegrationIcon>
          <IconText> API</IconText>
        </WorkspaceSettingsIntegrationIcon>
        <IntegrationInfo>
          <WorkspaceSettingsIntegrationDescription>
            Our API allows you to export candidate data for custom integrations
            or analytics.
          </WorkspaceSettingsIntegrationDescription>
          <WorkspaceSettingsIntegrationLink
            href="https://developers.hire.toggl.com/"
            target="_blank"
          >
            API documentation
          </WorkspaceSettingsIntegrationLink>
        </IntegrationInfo>
        <WorkspaceSettingsIntegrationAction>
          <DSButton
            variant="secondary"
            size="big"
            disabled={generating}
            onClick={async () => {
              if (canAccessFeature('api_access')) {
                await generateApiKey(undefined);

                amplitude?.logEvent('integrations/generate api key', {
                  'workspace id': workspaceId,
                });
              } else {
                openUpsellModal('premium_feature');
              }
            }}
          >
            {generating ? 'Generating API key...' : 'Generate API key'}
          </DSButton>
        </WorkspaceSettingsIntegrationAction>
      </WorkspaceSettingsIntegrationContainer>
      {apiKeys?.length !== 0 && (
        <WorkspaceSettingsIntegrationTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>API KEY</th>
              <th></th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {apiKeys?.map((apiKey: IAPIKey) => (
              <TableRow
                key={apiKey.id}
                apiKey={apiKey}
                setOpenEditModal={data => setOpenEditModal(data)}
                setSelectedApiKey={data => setSelectedApiKey(data)}
              />
            ))}
          </tbody>
        </WorkspaceSettingsIntegrationTable>
      )}

      <EditApiKeysModal
        apiKey={selectedApiKey}
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        onConfirm={() => setSelectedApiKey(null)}
        onRevokeApiKey={() => {
          setOpenConfirmModal(true);
        }}
      ></EditApiKeysModal>
      <ConfirmModal
        open={openConfirmModal}
        action="Revoke API Key"
        onConfirm={() => {
          deleteApiKey({ apiKeyId: selectedApiKey?.id! });
          setOpenConfirmModal(false);
        }}
        onClose={() => {
          setOpenConfirmModal(false);
        }}
        title="Revoke API Key"
        footerVariant="destructive-primary"
        contentStyle={{ width: '600px' }}
      >
        Are you sure you want to revoke this API key?
        <p>Any integrations using this API key will no longer function.</p>
      </ConfirmModal>
    </>
  );
};

const TableRow = ({ key, apiKey, setSelectedApiKey, setOpenEditModal }) => {
  const colorPrimary = useColor('purple-100', 'purple-100');
  const colorSecondary = useColor('purple-60', 'purple-100');
  const { showFlashMessage } = useFlashMessages();
  const { copyButtonRef, valueRef } = useClipboard();
  valueRef.current = apiKey.apiKey;

  return (
    <tr
      key={key}
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setSelectedApiKey(apiKey);
        setOpenEditModal(true);
      }}
    >
      <td
        style={{
          color: !apiKey.name ? colorSecondary : colorPrimary,
        }}
      >
        {apiKey.name || '(no name)'}
      </td>
      <td>{apiKey.apiKey}</td>
      <td>
        <DSButton
          variant="tertiary"
          data-rh="Copy API Key"
          size="small"
          ref={copyButtonRef}
          onClick={e => {
            e.stopPropagation();
            showFlashMessage({ type: 'link_copied' });
          }}
          Icon={<Icon icon={regular('link-simple')} />}
        />
      </td>
      <td>{formatDate(apiKey.createdAt, 'MMM D, YYYY')}</td>
      <td></td>
    </tr>
  );
};

const IconText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.purple[100]};
  text-transform: uppercase;
  text-align: center;
  width: 46px;
  height: 46px;
  background: ${props => props.theme.colors.orange[60]};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IntegrationInfo = styled.div``;
