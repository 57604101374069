import React from 'react';
import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import _isEqual from 'lodash/isEqual';

import { useCandidateSearchParams } from '@/features/candidate-search';
import { useCandidateSearch } from '@/features/candidate-search/providers';
import { Loader } from '@/features/common/components/loader';
import {
  useWorkspaceCandidatesQuery,
  WORKSPACE_CANDIDATES_KEY,
} from '@/features/workspace';

import { CandidateTable, EmptyList, NoResults } from './ui';

export function CandidateList() {
  const queryClient = useQueryClient();
  const query = useWorkspaceCandidatesQuery();
  const { searchParams: candidatesSearchParams } = useCandidateSearchParams();
  const { defaultParams: defaultSearchParams } = useCandidateSearch();
  const isDefaultSearch = _isEqual(candidatesSearchParams, {
    ...defaultSearchParams.pagination,
    ...defaultSearchParams.sort,
  });
  const hasNoResults = query.data?.total === 0;
  // isFetching because we don't want to display the table header
  // while the request in performing
  if (!query.data) {
    return null;
  }
  const NoResultsComponent =
    !query.isFetching && (isDefaultSearch ? <EmptyList /> : <NoResults />);
  const SearchSubtitle = !query.isFetching && !isDefaultSearch && (
    <SearchResultsSubtitile>Search results</SearchResultsSubtitile>
  );

  return (
    <Wrapper>
      {SearchSubtitle}
      {hasNoResults ? (
        NoResultsComponent
      ) : (
        <>
          <CandidateTable
            candidates={query.data?.candidates ?? []}
            onRefreshData={() =>
              queryClient.invalidateQueries({
                queryKey: WORKSPACE_CANDIDATES_KEY,
              })
            }
          />
        </>
      )}
      {query.isFetching && (
        <>
          <LoadingOverlay />
          <StyledLoader />
        </>
      )}
    </Wrapper>
  );
}

//region Styles
const Wrapper = styled.div`
  position: relative;
  padding: 0 24px 140px;

  @media all and (max-width: 768px) {
    padding: 0 16px 16px;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: ${props => props.theme.colors.white};
  opacity: 0.5;
  z-index: 100;
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 0;
  z-index: 200;
  left: 50%;
  transform: translateX(-50%);
`;

const SearchResultsSubtitile = styled.div`
  color: ${props => props.theme.typography.colorPrimary};
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 12px 12px 0 12px;
`;
//endregion
