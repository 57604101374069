import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface MarkerProps {
  icon?: React.ReactNode;
  first?: boolean;
  last?: boolean;
}

export function Marker({ icon, first, last }: MarkerProps) {
  return (
    <MarkerContainer hasPrev={!first} hasNext={!last}>
      <Point>{icon}</Point>
    </MarkerContainer>
  );
}

const MarkerContainer = styled.div<{ hasPrev?: boolean; hasNext?: boolean }>`
  grid-area: marker;
  position: relative;
  display: flex;
  justify-content: center;

  ${props =>
    props.hasPrev &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: -12px;
        height: 12px;
        width: 1px;
        background: ${props.theme.colors.purple[10]};
      }
    `};

  ${props =>
    props.hasNext &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 24px;
        bottom: -12px;
        width: 1px;
        background: ${props.theme.colors.purple[10]};
      }
    `};
`;

const Point = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.theme.colors.purple[100]};
`;
