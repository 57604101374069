import api from '@/api/api';
import { TId } from '@/features/common';
import { ITest } from '@/features/test';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function createTestFromTemplate(params: {
  jobOpeningId: TId;
  attributes: any;
}): Promise<ITest> {
  const response = await api.post(
    `/openings/${params.jobOpeningId}/tests/from-template`,
    serialiseAPIData(params.attributes)
  );

  const test: ITest = parseAPIData(response.data);

  return test;
}
