import styled from '@emotion/styled';

export const QuestionSettings = styled.div`
  display: grid;
  grid-template-columns:
    minmax(225px, 1fr)
    minmax(auto, 225px)
    minmax(auto, 150px)
    minmax(max-content, auto)
    68px;
  gap: 8px;
  background-color: ${props => props.theme.colors.purple[5]};
  padding: 16px 24px;

  > :last-child {
    grid-column: -2 / span 1;
    justify-self: end;
  }
`;
