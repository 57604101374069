import React from 'react';
import styled from '@emotion/styled';

import {
  getCandidateTestStateDetails,
  ICandidateTestState,
} from '@/features/candidate';
import { ScoreTag } from '@/features/common';

type CandidateTestStateTagProps = {
  testState: ICandidateTestState | null;
  score: number;
  scoreToPass?: number;
  selected?: boolean;
  'data-rh'?: string;
};

export function CandidateTestStateTag({
  testState,
  score,
  scoreToPass,
  selected,
  ...props
}: CandidateTestStateTagProps) {
  const stateDetails = getCandidateTestStateDetails(testState);
  if (!stateDetails) return null;

  switch (testState) {
    case 'invited':
    case 'interested':
    case 'out_of_time':
    case 'in_progress':
      return (
        <State {...props} backgroundColor={stateDetails.backgroundColor}>
          {stateDetails.label}
        </State>
      );

    case 'not_invited':
      return (
        <State
          {...props}
          data-rh={'This candidate is not yet invited to the test.'}
          data-rh-at="bottom"
          backgroundColor={selected ? '#ffffff' : stateDetails.backgroundColor}
        >
          {stateDetails.label}
        </State>
      );

    case 'completed':
      if (score > 0) {
        return (
          <ScoreTag {...props} score={score} passPercentage={scoreToPass} />
        );
      } else {
        return (
          <State {...props} backgroundColor={stateDetails.backgroundColor}>
            {stateDetails.label}
          </State>
        );
      }

    default:
      return null;
  }
}

const State = styled.div<{ backgroundColor: string }>`
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
  padding: 0 8px;
  border-radius: 8px;
  background: ${props => props.backgroundColor};
  color: ${props => props.theme.typography.colorPrimary};
  height: 16px;
  display: inline-block;
  text-align: center;
`;
