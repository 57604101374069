import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { orderBy } from 'lodash';

import { DEFAULT_TITLES } from '@/features/questions';
import { useSort } from '@/hooks/sort';
import { QuestionStats } from '@/types/analytics';

import {
  SortArrow,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeader as BaseTableHeader,
  TableRow,
} from '../job-opening-analytics-table';

interface QuestionPerformanceTableProps {
  questionStats?: QuestionStats[];
}

export function JobOpeningAnalyticsQuestionPerformance({
  questionStats,
}: QuestionPerformanceTableProps) {
  const { sortOrder, sortedBy, sortBy } = useSort<keyof QuestionStats>(
    'questionNumber',
    'asc'
  );

  if (!questionStats) {
    return <LoadingWrapper>Loading...</LoadingWrapper>;
  }

  return (
    <Table>
      <thead>
        <TableRow>
          <TableHeader onClick={() => sortBy('questionNumber')}>
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'questionNumber'}
            />
          </TableHeader>
          <TableHeader onClick={() => sortBy('questionType')}>
            type
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'questionType'}
            />
          </TableHeader>
          <TableHeader
            onClick={() => sortBy('description')}
            data-type="question"
          >
            question
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'description'}
            />
          </TableHeader>
          <TableHeader onClick={() => sortBy('answered')} align="right">
            answered
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'answered'}
            />
          </TableHeader>
          <TableHeader onClick={() => sortBy('correct')} align="right">
            correct
            <SortArrow sortOrder={sortOrder} visible={sortedBy === 'correct'} />
          </TableHeader>
          <TableHeader onClick={() => sortBy('partiallyCorrect')} align="right">
            partially correct
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'partiallyCorrect'}
            />
          </TableHeader>
          <TableHeader onClick={() => sortBy('incorrect')} align="right">
            incorrect
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'incorrect'}
            />
          </TableHeader>
          <TableHeader onClick={() => sortBy('scoreCorrelation')} align="right">
            score correlation
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'scoreCorrelation'}
            />
          </TableHeader>
        </TableRow>
      </thead>

      <tbody>
        {orderBy(questionStats, sortedBy, sortOrder).map(el => (
          <TableRow key={el.id}>
            <TableCell>{el.questionNumber}</TableCell>
            <TableCell data-type="type">
              {DEFAULT_TITLES[el.questionType]}
            </TableCell>
            <TableCell data-type="question" data-rh={el.description}>
              {el.description}
            </TableCell>
            <TableCell hasEmptyValue={el.answered === null}>
              {el.answered ? `${el.answered}%` : 'N/A'}
            </TableCell>
            <TableCell hasEmptyValue={el.correct === null}>
              {el.correct ? `${el.correct}%` : 'N/A'}
            </TableCell>
            <TableCell hasEmptyValue={el.partiallyCorrect === null}>
              {el.partiallyCorrect ? `${el.partiallyCorrect}%` : 'N/A'}
            </TableCell>
            <TableCell hasEmptyValue={el.incorrect === null}>
              {el.incorrect ? `${el.incorrect}%` : 'N/A'}
            </TableCell>
            <TableCell hasEmptyValue={el.scoreCorrelation === null}>
              {el.scoreCorrelation ? `${el.scoreCorrelation}` : 'N/A'}
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}

const LoadingWrapper = styled.div`
  font-style: italic;
  color: ${props => props.theme.colors.purple[20]};
  margin-top: 15px;
`;

const QuestionCellPartial = () =>
  css`
    &[data-type='question'] {
      width: auto;
      max-width: 260px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `;

const Table = styled(BaseTable)`
  margin-top: -36px;
`;

const TableHeader = styled(BaseTableHeader)`
  &:first-child {
    padding: 0;
    width: 16px;
    text-align: center;
  }

  ${QuestionCellPartial}
`;

const TableCell = styled(BaseTableCell)`
  &:nth-child(-n + 2) {
    border-left: none;
    border-right: none;
    text-align: left;
  }

  &:first-child {
    padding: 0;
    text-align: center;
  }

  &[data-type='type'] {
    font-size: 10px;
    text-transform: uppercase;
  }

  ${QuestionCellPartial}
`;
