import styled from '@emotion/styled';

export const Actions = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0; // shows on hover controlled by parent element
  transition: opacity 0.3s, visibility 0.3s;
  visibility: collapse;
  grid-area: actions;
  justify-content: flex-end;
`;
