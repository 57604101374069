import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { capitalize } from 'lodash';

import { Icon } from '@/features/common';
import { DeleteButton, FilterListItem } from '@/features/filters';

export const TypeListItem = ({
  types,
  onDelete,
}: {
  types?: string[];
  onDelete: (string) => void;
}) => {
  if (!types) return null;
  return (
    <>
      {types.map(type => (
        <FilterListItem key={type}>
          <Icon icon={solid('tag')} color="orange-100" />
          {capitalize(type)}
          <DeleteButton onClick={() => onDelete(type)} />
        </FilterListItem>
      ))}
    </>
  );
};
