import * as React from 'react';

const MarkdownLink = ({
  href,
  title,
  children,
}: {
  href: string;
  title: string | null;
  children: React.ReactNode;
}) => (
  <a
    href={href}
    title={title || undefined}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

export default MarkdownLink;
