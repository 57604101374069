import React from 'react';
import { DSCheckbox } from '@hundred5/design-system';

import { useCandidateSearchParams } from '@/features/candidate-search/hooks';

import { DeleteButton } from '../../buttons';
import { FIRST_COLUMN_FILTER_TYPE } from '../../first-column-filter';
import { FilterListItem } from '../filter-list-item';

export function FirstColumnListItem() {
  const { searchParams, removeSearchParam } = useCandidateSearchParams();
  const { firstColumn } = searchParams;

  if (!firstColumn) return null;

  return (
    <FilterListItem>
      <DSCheckbox checked />
      First column only
      <DeleteButton
        onClick={() => removeSearchParam(FIRST_COLUMN_FILTER_TYPE)}
      />
    </FilterListItem>
  );
}
