import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from '@/utils/reactQuery';

import {
  closeAccount,
  fetchTogglAccountsMe,
  updateTogglAccountsMe,
} from '../api';

const TOGGL_ACCOUNTS_ME_KEY = ['toggl-accounts', 'me'];

export const useTogglAccountsMeQuery = (options?: UseQueryOptions) => {
  return useQuery([...TOGGL_ACCOUNTS_ME_KEY], fetchTogglAccountsMe, {
    ...(options as any),
  });
};

export const useCloseAccountMutation = (options?: UseQueryOptions) => {
  return useMutation(closeAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries([...TOGGL_ACCOUNTS_ME_KEY]);
    },
    ...(options as any),
  });
};

export const useUpdateTogglAccountsMeMutation = (options?: UseQueryOptions) => {
  return useMutation(updateTogglAccountsMe, {
    onSuccess: () => {
      queryClient.invalidateQueries([...TOGGL_ACCOUNTS_ME_KEY]);
    },
    ...(options as any),
  });
};
