import React from 'react';
import { TogglThemeProvider } from '@hundred5/design-system';
import { Mutation, Query } from '@tanstack/react-query';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminApp } from '@/features/admin-app';
import { Callback } from '@/features/authentication';
import { CandidateAppRedirect } from '@/features/candidate';
import { CandidateRedirect } from '@/features/candidate/components/candidate-redirect';
import { Forbidden } from '@/features/common';
import { FlashMessages } from '@/features/flash-messages';
import { GoogleReCaptcha } from '@/features/google-recaptcha';
import { getSharedAuthEnabled } from '@/features/toggl-accounts';
import { useHubspotTracking } from '@/features/tracking';
import { useApiError } from '@/hooks/api';
import { queryClient } from '@/utils/reactQuery';

import RedirectUrls from '../redirect_urls/RedirectUrls';
import AnonymousSplit from '../splits/AnonymousSplit';
import Tooltip from '../tooltip/Tooltip';

import Intercom from './Intercom';
import * as pages from './pages';
import Sentry from './Sentry';
import Smartlook from './Smartlook';
import StartDemo from './StartDemo';
import StartSupport from './StartSupport';

import './App.css';

const App = () => {
  useQueryClientWithAutoErrorHandling();
  const sharedAuthEnabled = getSharedAuthEnabled();

  useHubspotTracking();

  return (
    <TogglThemeProvider>
      <div className="app">
        <FlashMessages />
        <AnonymousSplit>
          <React.Suspense fallback={null}>
            <Switch>
              <Redirect from="/" to="/admin" exact />

              <Redirect from="/admin/login" to="/admin" />
              <Route path="/admin/signup" component={pages.SignUpPage} />

              {!sharedAuthEnabled && (
                <Route
                  path="/admin/forgotten-password/reset"
                  component={pages.ResetPasswordPage}
                />
              )}
              {!sharedAuthEnabled && (
                <Route
                  path="/admin/forgotten-password"
                  component={pages.ForgottenPasswordPage}
                />
              )}

              {!sharedAuthEnabled && (
                <Route path="/login-callback" component={Callback} />
              )}

              <Route path="/admin" component={AdminApp} />

              <Route path="/demo" component={StartDemo} />
              <Route path="/support-auth" component={StartSupport} />
              <Route
                path="/redirect/candidate/:workspaceId/:jobOpeningId/:candidateId/:tab(results|notes|history)/:tabItemId/:noteId?"
                component={CandidateRedirect}
              />
              <Route
                path="/redirect/candidate/:workspaceId/:jobOpeningId/:candidateId/:testId?"
                component={CandidateRedirect}
              />
              <Route path="/redirect" component={RedirectUrls} />
              <Route
                path="/c/:slug/:hashedTestId?"
                component={CandidateAppRedirect}
              />
              <Route path="/forbidden" component={Forbidden} />
              <Route path="/:slug" component={CandidateAppRedirect} />
            </Switch>
          </React.Suspense>
        </AnonymousSplit>
        <Sentry />
        <Intercom />
        <Tooltip />
        <Smartlook />
        <GoogleReCaptcha />
      </div>
    </TogglThemeProvider>
  );
};

const useQueryClientWithAutoErrorHandling = () => {
  const handleApiError = useApiError();

  const defaultQueryErrorHandler = (error: unknown, query: Query) => {
    if (!query.options?.meta?.skipDefaultErrorHandler) {
      handleApiError(error);
    }
  };

  const defaultMutationErrorHandler = (
    error: unknown,
    variables: unknown,
    context: unknown,
    mutation: Mutation
  ) => {
    if (!mutation.options?.meta?.skipDefaultErrorHandler) {
      handleApiError(error);
    }
  };

  queryClient.getQueryCache().config.onError = defaultQueryErrorHandler;
  queryClient.getMutationCache().config.onError = defaultMutationErrorHandler;
};

export default App;
