import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { sendVerificationEmail } from '@/api/authentication';
import { TogglLogo } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { GoogleRecaptchaPrivacyDisclaimer } from '@/features/google-recaptcha';
import { useMeQuery } from '@/features/me';
import {
  getSharedAuthEnabled,
  useEmailValidationRequestMutation,
} from '@/features/toggl-accounts';
import { useApiError } from '@/hooks/api';

import emailIllustration from './assets/email-illustration.svg';

export function EmailVerification() {
  const { showFlashMessage } = useFlashMessages();
  const handleApiError = useApiError();
  const sharedAuthEnabled = getSharedAuthEnabled();
  const meQuery = useMeQuery();
  const emailValidationRequestMutation = useEmailValidationRequestMutation();

  const [sendingVerificationEmail, setSendingVerificationEmail] = useState<
    boolean
  >(false);

  const handleSendEmailVerification = useCallback(async () => {
    if (meQuery.data?.email) {
      try {
        setSendingVerificationEmail(true);
        await sendVerificationEmail(meQuery.data.email);
        showFlashMessage({ type: 'verification_email_sent' });
        setSendingVerificationEmail(false);
      } catch (apiError) {
        handleApiError(apiError);
      }
    }
  }, [handleApiError, meQuery.data?.email, showFlashMessage]);

  if (!meQuery.data) {
    return null;
  }

  const handleResendVerificationEmail = () => {
    if (sharedAuthEnabled) {
      emailValidationRequestMutation.mutate({ email: meQuery.data.email });
    } else {
      handleSendEmailVerification();
    }
  };

  const isSendingVerificationEmail = sharedAuthEnabled
    ? emailValidationRequestMutation.isLoading
    : sendingVerificationEmail;

  return (
    <Container>
      <a
        href="https://toggl.com/hire/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <TogglLogo height={46} width={205} />
      </a>

      <img src={emailIllustration} alt="Email illustration" height={106} />

      <div>
        <Heading>Verify your email address</Heading>
        <Description>
          A verification link has been sent to{' '}
          <Email href={`mailto::${meQuery.data.email}`}>
            {meQuery.data.email}
          </Email>
          .
          <br />
          Click on the link to get started.
        </Description>
      </div>

      <ButtonWrapper>
        <DSButton
          variant="secondary"
          onClick={() => handleResendVerificationEmail()}
          disabled={isSendingVerificationEmail}
        >
          {isSendingVerificationEmail ? 'Sending...' : 'Send again'}
        </DSButton>
      </ButtonWrapper>

      <RecaptchaDisclaimerWrapper>
        <GoogleRecaptchaPrivacyDisclaimer />
      </RecaptchaDisclaimerWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 32px;
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
  width: 344px;
  max-width: 100%;
`;

const Heading = styled.h1`
  margin: 0;
  text-align: center;
  font-family: 'GT Haptik';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: ${props => props.theme.colors.purple[80]};
`;

const Description = styled.p`
  margin: 8px 0 0 0;
  color: ${props => props.theme.colors.purple[80]};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

const Email = styled.a`
  text-decoration: none;
  display: inline-block;
  color: ${props => props.theme.colors.orange[120]};
`;

const ButtonWrapper = styled.div`
  width: 100%;

  button {
    width: 100%;
  }
`;

const RecaptchaDisclaimerWrapper = styled.div`
  text-align: center;
`;
