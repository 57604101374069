import styled from '@emotion/styled';

export const Header = styled.h3`
  color: ${({ theme }) => theme.colors.purple[60]};
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-left: 16px;
  text-transform: uppercase;
`;
