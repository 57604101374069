import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';

import {
  useCandidateQuery,
  useUpdateCandidateMutation,
} from '@/features/candidate';
import { StarRating, TId } from '@/features/common';
import { useJobOpeningPermission } from '@/features/permissions';
import { useWorkspaceId } from '@/hooks/router'; //region Main

//region Main
interface RatingProps {
  candidateId: TId;
}

export function Rating(props: RatingProps) {
  const { data: candidate } = useCandidateQuery(props);
  const updateCandidate = useUpdateCandidateMutation();
  const allowCandidateRatingsUpdate = useJobOpeningPermission()(
    'candidate ratings',
    'update'
  );
  const workspaceId = useWorkspaceId();

  return (
    <RatingContainer>
      <StarRating
        size="small"
        first={1}
        last={5}
        value={candidate?.rating || null}
        onChange={rating => {
          updateCandidate.mutate({
            candidateId: props.candidateId,
            attributes: {
              rating: rating || 0,
            },
          });

          amplitude?.logEvent('candidates/use star rating', {
            'workspace id': workspaceId,
            'job opening id': candidate?.jobOpeningId,
            'candidate id': props.candidateId,
          });
        }}
        readOnly={!allowCandidateRatingsUpdate}
        colorMode="dark"
      />
    </RatingContainer>
  );
}

//endregion

//region Styles
const RatingContainer = styled.div`
  position: relative;
  top: 6px;
`;
//endregion
