import React from 'react';
import styled from '@emotion/styled';
import ReactHintFactory from 'react-hint';

const ReactHint = ReactHintFactory(React);

//region Main
const UploadHint = () => {
  return (
    <ReactHint
      events
      persist
      delay={100}
      autoPosition
      attribute="data-info-icon-file-upload"
      onRenderContent={() => {
        return (
          <Content>
            <Text>
              <Description>
                Upload a CSV file with all email addresses of candidates you
                would like to send the test invitation to. Your CSV should
                contain one column with one valid email address per line. The
                limit is 10 emails per day on the Free plan, 50 emails at a time
                on paid plans. For example:
              </Description>

              <FileContainer>emails.csv</FileContainer>
              <FileExample>
                kris.nico@yahoo.com
                <LineBreak />
                sven.towne@hotmail.com
                <LineBreak />
                thompson.nayeli@gmail.com
                <LineBreak />
                lindgren.ulices@pouros.com
                <LineBreak />
                harvey56@hotmail.com
                <LineBreak />
                nschmidt@daniel.com
                <LineBreak />
              </FileExample>
            </Text>
          </Content>
        );
      }}
    />
  );
};
//endregion

//region Components

//endregion

//region Styles
const Content = styled.div`
  padding: 10px 20px;
  font-size: 12px;
  line-height: 1.4;
  background-color: #fff;
  border-radius: 8px;
  color: ${props => props.theme.colors.purple[100]};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  min-height: 300px;
`;
const Text = styled.div`
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;
const FileExample = styled.div`
  border: 1px solid #e0e0e0;
  padding: 7px 9px;
  margin-top: 3px;
`;
const Description = styled.p``;

const LineBreak = styled.br``;

const FileContainer = styled.div`
  font-weight: bold;
`;

//endregion

export default UploadHint;
