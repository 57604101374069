import { Action } from '../actions';

export type AuthenticationState = {
  authenticated: boolean | null;
};

const initialState: AuthenticationState = {
  authenticated: null,
};

export default (
  state: AuthenticationState = initialState,
  action: Action
): AuthenticationState => {
  switch (action.type) {
    case 'AUTH_INITIALIZED':
      return { ...state, authenticated: action.payload.authenticated };
    case 'LOGGED_IN':
      return { ...state, authenticated: true };
    case 'LOGGED_OUT':
      return { ...state, authenticated: false };
    default:
      return state;
  }
};
