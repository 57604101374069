import styled from '@emotion/styled';

import { Section } from './section';

export const Footer = styled(Section)`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: flex-end;
  padding: 36px;
  position: fixed;
  bottom: 0;
  width: 100%;

  @media (min-width: 769px) {
    position: initial;
  }

  & button,
  & > div {
    width: 100%;

    @media (min-width: 769px) {
      width: unset;
    }
  }
`.withComponent('footer');
