import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon, TId } from '@/features/common';

export interface SuspiciousTagProps {
  candidateId: TId;
  isSuspicious: boolean | null;
}

export function SuspiciousTag(props: SuspiciousTagProps) {
  if (!props.isSuspicious) return null;

  return (
    <SuspiciousTagWrapper
      data-rh="Suspicious behavior detected"
      data-rh-at="right"
    >
      <Icon icon={solid('flag')} fontSize={12} color="yellow-100" />
    </SuspiciousTagWrapper>
  );
}

const SuspiciousTagWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
