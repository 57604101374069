import React from 'react';
import styled from '@emotion/styled';

interface FomoProps {
  state?: boolean;
  active?: boolean;
}

export const Fomo = ({ state, active }: FomoProps) => {
  const fomoState = state ? 'lavender' : active ? 'green' : 'orange';

  return (
    <FomoWrapper state={fomoState}>
      {active && <span>Active</span>}
      {state && <span>Great match</span>}
    </FomoWrapper>
  );
};

const FomoWrapper = styled.div<{ state: 'lavender' | 'green' | 'orange' }>`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.purple[100]};
  background: ${({ theme, state }) => {
    switch (state) {
      case 'lavender':
        return theme.colors.lavender[20];
      case 'green':
        return theme.colors.green[10];
      case 'orange':
        return theme.colors.orange[20];
    }
  }};
`;
