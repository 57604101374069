import { useEffect, useRef } from 'react';
import Clipboard from 'clipboard';

export const useClipboard = () => {
  const clipboardRef = useRef<Clipboard>();
  const copyButtonRef = useRef<HTMLButtonElement>(null);
  const valueRef = useRef<string>('');

  useEffect(() => {
    if (copyButtonRef.current && !clipboardRef.current) {
      clipboardRef.current = new Clipboard(copyButtonRef.current, {
        text: () => valueRef.current,
      });
    }
  });

  useEffect(
    () => () => clipboardRef.current && clipboardRef.current.destroy(),
    []
  );

  return { copyButtonRef, valueRef };
};
