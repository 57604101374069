import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  addSlackIntegration,
  deleteSlackIntegration,
  fetchSlackIntegrations,
} from '@/features/integrations';
import { useWorkspaceId } from '@/hooks/router';

const SLACK_INTEGRATION_KEY = ['slack-integration'];

export const useSlackIntegrationsQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();

  queryClient.setMutationDefaults(SLACK_INTEGRATION_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries([...SLACK_INTEGRATION_KEY, workspaceId]),
  });

  return useQuery(
    [...SLACK_INTEGRATION_KEY, workspaceId],
    () => fetchSlackIntegrations({ workspaceId }),
    {
      ...(options as any),
    }
  );
};

export const useAddSlackIntegrationMutation = (
  options?: UseMutationOptions
) => {
  const workspaceId = useWorkspaceId();

  return useMutation(() => addSlackIntegration({ workspaceId }), {
    mutationKey: SLACK_INTEGRATION_KEY,
    ...(options as any),
  });
};

export const useDeleteSlackIntegrationMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(deleteSlackIntegration, {
    mutationKey: SLACK_INTEGRATION_KEY,
    ...(options as any),
  });
};
