/**
 * Create a function to call all passed functions at the same time with the same arguments
 * @param fns - functions to be called
 * @returns a function that calls all functions with the same arguments
 */
export const composeCall = (...fns) => (...args) => {
  fns.forEach(fn => fn && fn(...args));
};

/**
 * Call all functions without arguments
 * @param fns - functions to be called
 */
export const callAll = (...fns) => composeCall(...fns)();
