import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

import { useCloseCandidateCard } from '../candidate-card.hooks';

//region Main
export function CloseButton() {
  const closeCandidateCard = useCloseCandidateCard();

  return (
    <Button onClick={closeCandidateCard}>
      <Icon icon={regular('xmark')} color="purple-60" size="xl" />
    </Button>
  );
}
//endregion

//region Styles
const Button = styled.button`
  -webkit-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  transition: background 0.1s;

  :hover {
    background: ${props => props.theme.colors.purple[5]};
  }
`;
//endregion
