import styled from '@emotion/styled';

export const Sidebar = styled.aside<{ isFloating: boolean; offsetTop: number }>`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: ${props =>
    `calc(100vh - ${props.offsetTop + 1}px - ${
      props.isFloating ? '12px' : '166px'
    })`};
  padding: 8px 0 12px 0;
  position: sticky;
  top: ${({ offsetTop }) => offsetTop + 1}px;
  width: 297px;
  z-index: 100;
`;
