import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Form, Formik } from 'formik';
import { isEmpty, pick } from 'lodash';

import { amplitude } from '@/features/amplitude';
import {
  TFilterType,
  useCandidateSearchParams,
} from '@/features/candidate-search';
import { callAll, FormikRadioField, Icon, Popover } from '@/features/common';
import { useJobOpeningIdOrNull } from '@/hooks/router';

import { ApplyButton } from '../buttons';
import { HoverableFieldWrapper } from '../candidate-filters/ui/field-wrapper';
import { FilterName } from '../candidate-filters/ui/filter-name';
import { FormFields } from '../candidate-filters/ui/form-fields';
import {
  FullWidthPopover,
  IconWrapper,
  SubContent,
  Trigger,
} from '../candidate-filters/ui/popover';

export const NOTES_FILTER_TYPE: TFilterType = 'notes';
export const NOTES_OPTIONS = {
  yes: 'With notes',
  no: 'Without notes',
};

export const NotesFilter = ({ onApply }) => {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();
  const jobOpeningId = useJobOpeningIdOrNull();

  return (
    <FullWidthPopover>
      <Popover.Trigger>
        {({ onClick }) => (
          <Trigger onClick={onClick}>
            <IconWrapper>
              <Icon icon={regular('file-pen')} color="purple-60" size="sm" />
            </IconWrapper>
            Notes
          </Trigger>
        )}
      </Popover.Trigger>
      <SubContent position="right">
        {({ close }) => (
          <>
            <FilterName>Notes</FilterName>
            <Formik
              initialValues={pick(candidatesSearchParams, NOTES_FILTER_TYPE)}
              onSubmit={values => {
                if (isEmpty(values)) {
                  removeSearchParam(NOTES_FILTER_TYPE);
                } else {
                  addSearchParams(values);
                  amplitude?.logEvent('candidate filters/filter applied', {
                    'filter type': NOTES_FILTER_TYPE,
                    'filter value': values,
                    'filter origin': jobOpeningId
                      ? 'pipeline'
                      : 'all candidates',
                    'job opening id': jobOpeningId,
                  });
                }
                callAll(onApply, close);
              }}
            >
              <Form>
                <FormFields>
                  {Object.entries(NOTES_OPTIONS).map(([value, label]) => (
                    <HoverableFieldWrapper key={value}>
                      <FormikRadioField
                        name={NOTES_FILTER_TYPE}
                        value={value}
                        label={label}
                      />
                    </HoverableFieldWrapper>
                  ))}
                </FormFields>
                <ApplyButton />
              </Form>
            </Formik>
          </>
        )}
      </SubContent>
    </FullWidthPopover>
  );
};
