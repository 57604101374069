import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { bulkUpdate } from '@/features/bulk-actions/api';
import { TId } from '@/features/common';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline';

export const BULK_MOVE_TO_MUTATION_KEY = ['bulk-move-to'];

export const useBulkMoveToMutation = (options: UseMutationOptions) => {
  const queryClient = useQueryClient();
  return useMutation(
    BULK_MOVE_TO_MUTATION_KEY,
    ({
      candidateIds,
      jobOpeningId,
      targetCategoryId,
      originCategoryId,
    }: {
      candidateIds: TId[];
      jobOpeningId: TId;
      targetCategoryId: TId;
      originCategoryId?: TId;
    }) => bulkUpdate(candidateIds, { categoryId: targetCategoryId }),
    {
      ...(options as any),
      onSettled: (data, error, variables, context) => {
        // @ts-ignore FIXME: TS complains about variables for some reason
        options?.onSettled?.(data, error, variables, context);

        if (variables.originCategoryId) {
          queryClient.invalidateQueries([
            ...PIPELINE_CANDIDATES_KEY,
            variables.jobOpeningId,
            variables.originCategoryId,
          ]);
        }
        queryClient.invalidateQueries([
          ...PIPELINE_CANDIDATES_KEY,
          variables.jobOpeningId,
          variables.targetCategoryId,
        ]);
      },
    }
  );
};
