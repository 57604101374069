import React from 'react';
import styled from '@emotion/styled';

import { TScreenType } from '@/features/test';

interface FormDescriptionProps {
  screenType: TScreenType;
}

export function FormDescription({ screenType }: FormDescriptionProps) {
  switch (screenType) {
    case TScreenType.Passed: {
      return (
        <Description>
          This is what the candidates will see if they pass the test.
        </Description>
      );
    }
    case TScreenType.NotPassed: {
      return (
        <Description>
          This is what the candidates will see if they fail the test.
        </Description>
      );
    }
    case TScreenType.NoThreshold: {
      return (
        <Description>
          This is what the candidates will see at the end of the test.
        </Description>
      );
    }
    default:
      return null;
  }
}

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.typography.colorPrimary};
`;
