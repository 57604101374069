interface IOrderable {
  orderWeight: number;
}

/**
 * Returns the order weight that would place an item between its neighbours in the collection.
 * @param targetIndex index that the item would be inserted at
 * @param collection collection of items without the item that would be inserted
 */
export function getOrderWeightBetweenNeighbours(
  targetIndex: number,
  collection: IOrderable[]
) {
  if (targetIndex === 0) {
    return collection[targetIndex].orderWeight - 1;
  }

  if (targetIndex === collection.length) {
    return collection[collection.length - 1].orderWeight + 1;
  }

  const previousItem = collection[targetIndex - 1];
  const nextItem = collection[targetIndex];

  return parseFloat(
    ((previousItem.orderWeight + nextItem.orderWeight) / 2).toFixed(3)
  );
}
