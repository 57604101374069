import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { DSButton, LinkButton } from '@hundred5/design-system';
import formatDate from 'date-fns/format';

import { useMeQuery } from '@/features/me';
import { useWorkspacePermission } from '@/features/permissions';
import {
  IOwnershipTransferRequest,
  useWorkspaceUsersQuery,
  WorkspaceUser,
  useWorkspaceQuery,
  IWorkspace,
} from '@/features/workspace';
import {
  useCancelOwnershipTransferMutation,
  useRequestOwnershipTransferMutation,
  useWorkspaceOwnershipTransferRequestsQuery,
} from '@/features/workspace/queries/workspace-ownership-transfer';
import { useWorkspaceId } from '@/hooks/router';
import { Id } from '@/types/misc';

//region Main
type OwnershipTransferProps = {
  userId: Id | null;
};

export const OwnershipTransfer = ({ userId }: OwnershipTransferProps) => {
  const {
    me,
    user,
    workspace,
    ownershipTransferRequest,
    allowOwnershipTransfer,
  } = useOwnershipTransfer(userId);

  if (!allowOwnershipTransfer || !me || !user || !workspace) {
    return null;
  }

  return (
    <OwnershipTransferContainer>
      {userId === me.id ? (
        <Myself workspace={workspace} />
      ) : ownershipTransferRequest && user ? (
        <ExistingRequest
          user={user}
          ownershipTransferRequest={ownershipTransferRequest}
          workspace={workspace}
        />
      ) : user ? (
        <NewRequest user={user} workspace={workspace} />
      ) : null}
    </OwnershipTransferContainer>
  );
};
//endregion

//region Components
const Myself = ({ workspace }: { workspace: IWorkspace }) => {
  return (
    <div>
      You are the only owner of <strong>{workspace.name}</strong> workspace. In
      case you wish to have a different role at this workspace, you need to give
      this role over to somebody else from your team by sending the request.
    </div>
  );
};

const ExistingRequest = ({
  user,
  workspace,
  ownershipTransferRequest,
}: {
  user: WorkspaceUser;
  workspace: IWorkspace;
  ownershipTransferRequest: IOwnershipTransferRequest;
}) => {
  const requestId = ownershipTransferRequest.id;

  const cancelOwnershipTransferMutation = useCancelOwnershipTransferMutation();

  const { resendOwnershipTransfer, resending } = useResendOwnershipTransfer(
    ownershipTransferRequest
  );

  return (
    <>
      <Header>Owner</Header>
      <div>
        You have requested <strong>{user.name}</strong> to become{' '}
        <strong>{workspace.name}</strong> workspace owner.
      </div>
      <Footer>
        <Date>
          pending, request sent:{' '}
          {formatDate(ownershipTransferRequest.requestedAt, 'MMM D, YYYY')}
        </Date>
        <DSButton
          size="small"
          variant="tertiary"
          onClick={() => resendOwnershipTransfer()}
          type="button"
          disabled={resending}
        >
          Resend request
        </DSButton>
        <CancelButton
          onClick={() => cancelOwnershipTransferMutation.mutate({ requestId })}
          type="button"
          disabled={cancelOwnershipTransferMutation.isLoading}
        >
          Cancel request
        </CancelButton>
      </Footer>
    </>
  );
};

const NewRequest = ({
  user,
  workspace,
}: {
  user: WorkspaceUser;
  workspace: IWorkspace;
}) => {
  const requestOwnershipTransferMutation = useRequestOwnershipTransferMutation();

  return user ? (
    <>
      <Header>Owner</Header>
      <div>
        Request <strong>{user.name}</strong> to become{' '}
        <strong>{workspace.name}</strong> workspace owner. There can be only one
        owner at the workspace.
      </div>
      <Footer>
        <DSButton
          size="small"
          variant="tertiary"
          disabled={requestOwnershipTransferMutation.isLoading}
          onClick={() =>
            requestOwnershipTransferMutation.mutate({
              workspaceId: workspace.id,
              receiverId: user.id,
            })
          }
          type="button"
        >
          {requestOwnershipTransferMutation.isLoading
            ? 'Sending...'
            : 'Send request'}
        </DSButton>
      </Footer>
    </>
  ) : null;
};
//endregion

//region Hooks
const useOwnershipTransfer = (userId: Id | null) => {
  const workspaceId = useWorkspaceId();
  const workspaceUsersQuery = useWorkspaceUsersQuery();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });
  const users = workspaceUsersQuery.data || [];
  const user = users.find(user => user.id === userId) || null;
  const meQuery = useMeQuery();
  const me = meQuery.data;
  const requests = useWorkspaceOwnershipTransferRequestsQuery();

  const ownershipTransferRequest = requests.data?.find(
    request => request.receiverId === userId
  );

  const allowOwnershipTransfer = useWorkspacePermission()(
    'workspace ownership transfer',
    'create'
  );

  return {
    user,
    me,
    workspace: workspaceQuery.data,
    ownershipTransferRequest,
    allowOwnershipTransfer,
  };
};

const useResendOwnershipTransfer = (
  ownershipTransferRequest: IOwnershipTransferRequest
) => {
  const workspaceId = useWorkspaceId();

  const cancelOwnershipTransferMutation = useCancelOwnershipTransferMutation();
  const requestOwnershipTransferMutation = useRequestOwnershipTransferMutation();

  const resendOwnershipTransfer = useCallback(async () => {
    await cancelOwnershipTransferMutation.mutateAsync({
      requestId: ownershipTransferRequest.id,
    });

    await requestOwnershipTransferMutation.mutateAsync({
      workspaceId,
      receiverId: ownershipTransferRequest.receiverId,
    });
  }, [
    ownershipTransferRequest,
    cancelOwnershipTransferMutation,
    requestOwnershipTransferMutation,
    workspaceId,
  ]);

  return {
    resendOwnershipTransfer,
    resending:
      requestOwnershipTransferMutation.isLoading ||
      cancelOwnershipTransferMutation.isLoading,
  };
};
//endregion

//region Styles
const Header = styled.div`
  color: ${props => props.theme.colors.purple[100]};
  font-weight: bold;
`;

const OwnershipTransferContainer = styled.div`
  background-color: ${props => props.theme.colors.purple[5]};
  padding: 16px;
  line-height: 23px;
  margin-top: 36px;
  border-radius: 8px;
`;

const Footer = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Date = styled.div`
  color: ${props => props.theme.colors.purple[60]};
  text-transform: uppercase;
  font-size: 10px;
  margin-right: 20px;
`;

const CancelButton = styled(LinkButton)`
  color: ${props => props.theme.colors.purple[100]};
  line-height: 150%;
`;
//endregion
