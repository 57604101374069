import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  ICandidateAttachment,
  useDeleteCandidateAttachmentMutation,
} from '@/features/candidate';
import { ConfirmModal, Icon, SemanticButton, TId } from '@/features/common';

//region Main
interface DeleteAttachmentButtonProps {
  attachment: ICandidateAttachment;
  candidateId: TId;
}

export function DeleteAttachmentButton({
  attachment,
  candidateId,
}: DeleteAttachmentButtonProps) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const mutation = useDeleteCandidateAttachmentMutation({ candidateId });

  return (
    <>
      <Button onClick={() => setConfirmModalOpen(true)}>
        <Icon icon={regular('trash')} />
      </Button>

      <ConfirmModal
        open={confirmModalOpen}
        action="Delete"
        onConfirm={async () => {
          await mutation.mutateAsync({ attachmentId: attachment.id });
          setConfirmModalOpen(false);
        }}
        onClose={() => setConfirmModalOpen(false)}
        contentStyle={{ minWidth: 350 }}
        title="Are you sure?"
      >
        Do you really want to delete{' '}
        {attachment.name || attachment.originalFileName}?
      </ConfirmModal>
    </>
  );
}

const Button = styled(SemanticButton)`
  padding: 4px 8px;
  transform: scale(1);
  transition: background 0.1s, transform 0.1s;

  &:hover {
    background: ${props => props.theme.colors.white};
  }

  &:active {
    transform: scale(0.96);
  }
`;
