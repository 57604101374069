import React from 'react';
import styled from '@emotion/styled';
import { TextInput } from '@hundred5/design-system';
import { useField } from 'formik';

import { HoverableFieldWrapper } from '@/features/candidate-search/components/candidate-filters/ui/field-wrapper';
import { FormFields } from '@/features/candidate-search/components/candidate-filters/ui/form-fields';
import {
  SCORE_FILTER_TYPE,
  SCORE_OPTIONS,
} from '@/features/candidate-search/components/score-filter';
import { FormikRadioField } from '@/features/common';

export const ScoreFilterOptions = () => {
  const [_, { value: customValue }, { setValue }] = useField('score');

  return (
    <FormFields>
      <HoverableFieldWrapper>
        <FormikRadioField
          name={SCORE_FILTER_TYPE}
          value="over_threshold"
          label="Over threshold"
        />
      </HoverableFieldWrapper>
      <HoverableFieldWrapper>
        <FormikRadioField
          name={SCORE_FILTER_TYPE}
          checked={isCustomValue(customValue)}
          onChange={() => setValue('')}
          label={
            <InputWrapper>
              Over
              <TextInput
                type="number"
                value={isCustomValue(customValue) ? customValue : ''}
                onChange={event => {
                  const newValue = event.target.value;

                  if (parseInt(newValue) <= 100 || newValue === '') {
                    setValue(newValue);
                  }
                }}
                onFocus={() => setValue('')}
              />
              %
            </InputWrapper>
          }
        />
      </HoverableFieldWrapper>
      {SCORE_OPTIONS.map(value => (
        <HoverableFieldWrapper key={value}>
          <FormikRadioField
            name={SCORE_FILTER_TYPE}
            value={value}
            label={`Over ${value}%`}
          />
        </HoverableFieldWrapper>
      ))}
    </FormFields>
  );
};

//region Utils
const isCustomValue = value =>
  value !== undefined &&
  !SCORE_OPTIONS.includes(value) &&
  value !== 'over_threshold';
//endregion

//region Styles
const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  ${TextInput} {
    width: 44px;
    height: 24px;
    margin: 0 4px 0 8px;
    border-radius: 8px;
    -moz-appearance: textfield;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;
//endregion
