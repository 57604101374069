import { isLegacyReduxTest } from '@/features/common';
import { IDashboardJobOpening } from '@/features/dashboard';
import { IJobOpening } from '@/features/job-opening';
import { ITest } from '@/features/test';

import { Test } from '../../types/h5test';

export const getShareTestUpsellTooltipAttributes = (
  test: Test | ITest,
  position = 'bottom'
) =>
  test.type === 'quiz' &&
  (isLegacyReduxTest(test) ? !test.smart : !test.isSmart)
    ? {
        'data-upsell-tooltip-custom': true,
        'data-upsell-tooltip-custom-at': position,
      }
    : {
        'data-upsell-tooltip': true,
        'data-upsell-tooltip-at': position,
      };

export const getShareJobUpsellTooltipAttributes = (
  jobOpening: IDashboardJobOpening | IJobOpening,
  position = 'bottom'
) => ({
  'data-upsell-tooltip': true,
  'data-upsell-tooltip-at': position,
});
