import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';

import { TId } from '@/features/common';

import { IPipelineCandidate } from '../types';

interface ISelectedCandidatesContext {
  selectedCandidates: {
    ids: TId[];
    entities: IPipelineCandidate[];
  };
  toggleCandidateSelection: (candidate: IPipelineCandidate) => void;
  toggleAllCandidates: (
    candidates: IPipelineCandidate[],
    categoryId?: TId
  ) => void;
  unselectAllCandidates: (categoryId?: TId) => void;
}

const SelectedCandidatesContext = createContext<ISelectedCandidatesContext>({
  selectedCandidates: {
    ids: [],
    entities: [],
  },
  toggleCandidateSelection: () => {},
  toggleAllCandidates: () => {},
  unselectAllCandidates: () => {},
});

interface SelectedCandidatesContextProps {
  children: React.ReactNode;
}

export function SelectedCandidatesProvider(
  props: SelectedCandidatesContextProps
) {
  const [selectedCandidates, setSelectedCandidates] = useState<
    ISelectedCandidatesContext['selectedCandidates']
  >({
    ids: [],
    entities: [],
  });

  const selectCandidate = useCallback((candidate: IPipelineCandidate) => {
    setSelectedCandidates(prev => ({
      ids: [...new Set([...prev.ids, candidate.id])],
      entities: [...new Set([...prev.entities, candidate])],
    }));
  }, []);

  const unselectCandidate = useCallback((candidate: IPipelineCandidate) => {
    setSelectedCandidates(prev => {
      const filteredCandidates = prev.entities.filter(
        candidateEntity => candidateEntity.id !== candidate.id
      );

      return {
        ids: filteredCandidates.map(candidateEntity => candidateEntity.id),
        entities: filteredCandidates,
      };
    });
  }, []);

  const toggleCandidateSelection = useCallback(
    (candidate: IPipelineCandidate) => {
      if (selectedCandidates.ids.includes(candidate.id)) {
        unselectCandidate(candidate);
      } else {
        selectCandidate(candidate);
      }
    },
    [selectedCandidates.ids, selectCandidate, unselectCandidate]
  );

  const selectAllCandidates = useCallback(
    (candidates: IPipelineCandidate[]) =>
      setSelectedCandidates(prev => ({
        ids: [...prev.ids, ...candidates.map(candidate => candidate.id)],
        entities: [...prev.entities, ...candidates],
      })),
    []
  );

  const unselectAllCandidates = useCallback((categoryId?: TId) => {
    if (categoryId) {
      setSelectedCandidates(prev => {
        const filteredCandidates = prev.entities.filter(
          candidate => candidate.categoryId !== categoryId
        );

        return {
          ids: filteredCandidates.map(candidate => candidate.id),
          entities: filteredCandidates,
        };
      });
    } else {
      setSelectedCandidates({
        ids: [],
        entities: [],
      });
    }
  }, []);

  const toggleAllCandidates = useCallback(
    (candidates: IPipelineCandidate[], categoryId?: TId) => {
      if (
        selectedCandidates.entities.some(
          candidate => candidate.categoryId === categoryId
        )
      ) {
        unselectAllCandidates(categoryId);
      } else {
        selectAllCandidates(candidates);
      }
    },
    [selectedCandidates.entities, selectAllCandidates, unselectAllCandidates]
  );

  const contextValue = useMemo(() => {
    return {
      selectedCandidates,
      toggleCandidateSelection,
      toggleAllCandidates,
      unselectAllCandidates,
    };
  }, [
    selectedCandidates,
    toggleCandidateSelection,
    toggleAllCandidates,
    unselectAllCandidates,
  ]);

  return <SelectedCandidatesContext.Provider value={contextValue} {...props} />;
}

export function useSelectedCandidates(categoryId?: TId) {
  const context = useContext(SelectedCandidatesContext);

  if (context === undefined) {
    throw new Error(
      'useSelectedCandidates must be used within a SelectedCandidatesProvider'
    );
  }

  if (!categoryId) {
    return context;
  }

  const candidatesInCategory = context.selectedCandidates.entities.filter(
    candidate => candidate.categoryId === categoryId
  );

  return {
    ...context,
    unselectAllCandidates: () => context.unselectAllCandidates(categoryId),
    toggleAllCandidates: (candidates: IPipelineCandidate[]) =>
      context.toggleAllCandidates(candidates, categoryId),
    selectedCandidates: {
      ids: candidatesInCategory.map(candidate => candidate.id),
      entities: candidatesInCategory,
    },
  };
}
