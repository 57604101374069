import React from 'react';

export function TestInfoIllustration() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 134" fill="none">
      <path
        fill="#6B5A74"
        fillRule="evenodd"
        d="M158.039 68.8v63.532c0 .624.506 1.13 1.13 1.13h54.813a1.13 1.13 0 0 0 1.13-1.13V86.563a1.13 1.13 0 0 0-1.13-1.13h-16.708a1.13 1.13 0 0 1-1.13-1.13V71.908a1.13 1.13 0 0 0-1.13-1.13h-16.553a1.13 1.13 0 0 1-1.13-1.13V56.711a1.13 1.13 0 0 0-1.13-1.13h-17.032a1.13 1.13 0 0 0-1.13 1.13V68.8Z"
        clipRule="evenodd"
      />
      <path
        fill="#43F6B7"
        fillRule="evenodd"
        d="m213.144 133.462-55.105-63.72 55.105 63.72Z"
        clipRule="evenodd"
      />
      <path
        fill="#D5D0D7"
        fillRule="evenodd"
        d="M87.747 131.766a1.13 1.13 0 0 0 .978 1.696h67.354c.87 0 1.414-.943.978-1.696L123.38 73.612a1.13 1.13 0 0 0-1.956 0l-33.677 58.154Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFC4BC"
        d="M155.319 57.731 92.071 29.265a1.13 1.13 0 0 0-1.495.566l-9.717 21.59a1.13 1.13 0 0 0 .567 1.495l63.249 28.466a1.13 1.13 0 0 0 1.494-.566l9.717-21.59a1.13 1.13 0 0 0-.567-1.495Z"
      />
      <path
        fill="#FFA195"
        fillRule="evenodd"
        d="M2.019 55.582h83.325c.624 0 1.13.506 1.13 1.13v75.644a1.13 1.13 0 0 1-1.13 1.13H59.576a.579.579 0 0 0 .004-.067v-39.51a.008.008 0 0 0-.008-.008.008.008 0 0 1-.008-.007l-.023-.318C58.97 85.67 52.11 79.49 43.811 79.49s-15.159 6.18-15.73 14.086l-.023.318-.002.005a.007.007 0 0 1-.006.002.008.008 0 0 0-.008.008v39.51a.58.58 0 0 0 .004.067H2.02a1.13 1.13 0 0 1-1.13-1.13V56.712c0-.624.506-1.13 1.13-1.13Z"
        clipRule="evenodd"
      />
      <path
        fill="#D5D0D7"
        fillRule="evenodd"
        d="M126.047 42.437c-11.549 0-20.912-9.385-20.912-20.962 0-11.576 9.363-20.961 20.912-20.961 11.55 0 20.913 9.385 20.913 20.961 0 11.577-9.363 20.962-20.913 20.962Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
