import React from 'react';
import styled from '@emotion/styled';
import { NavLink, matchPath } from 'react-router-dom';

interface NavigationItemProps {
  label: string;
  to: string;
  activePath?: string;
  onClick?: React.MouseEventHandler;
}

export const NavigationItem = ({
  label,
  to,
  activePath,
  onClick,
}: NavigationItemProps) => (
  <Item>
    <NavLink
      onClick={onClick}
      to={to}
      isActive={
        activePath
          ? (_match, location) =>
              !!matchPath(location.pathname, { path: activePath })
          : undefined
      }
      data-label={label}
    >
      {label}
    </NavLink>
  </Item>
);

const Item = styled.li`
  display: flex;
  align-items: flex-end;

  a {
    padding: 0 0 4px 0;
    font-size: 12px;
    font-weight: 400;
    color: ${props => props.theme.typography.colorPrimary};
    border-bottom: 1px solid transparent;

    &.active {
      font-weight: 700;
      border-bottom: 1px solid ${props => props.theme.colors.orange[100]};
    }

    &:hover {
      opacity: 0.8;
    }

    &::before {
      display: block;
      content: attr(data-label);
      font-weight: 700;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
`;
