import styled from '@emotion/styled';

export const BetaTag = styled.span`
  align-items: center;
  background: ${({ theme }) => theme.colors.purple[5]};
  border-radius: 8px;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  height: 16px;
  padding: 0 8px;
`;
