import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import Modal from '@/components/modal/beta/Modal';
import { useResetCandidateTestMutation } from '@/features/candidate';
import { TId } from '@/features/common';

//region Main
interface CandidateTestResetModalProps {
  candidateId: TId;
  testId: TId;
  open: boolean;
  onClose: () => void;
}

export function CandidateTestResetModal(props: CandidateTestResetModalProps) {
  const resetCandidateTestMutation = useResetCandidateTestMutation();

  return (
    <Modal fluid open={props.open} onClose={props.onClose}>
      <Modal.CloseButton fixed onClick={props.onClose} />
      <h3>Are you sure?</h3>
      <p>
        Resetting the test will delete all recorded answers, are you sure you
        want to proceed?
      </p>
      <Buttons>
        <DSButton
          type="button"
          disabled={resetCandidateTestMutation.isLoading}
          onClick={async () => {
            await resetCandidateTestMutation.mutateAsync(props);
            props.onClose();
          }}
        >
          Reset{resetCandidateTestMutation.isLoading && '...'}
        </DSButton>
        <DSButton type="button" variant="secondary" onClick={props.onClose}>
          Cancel
        </DSButton>
      </Buttons>
    </Modal>
  );
}
//endregion

//region Styles
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;
//endregion
