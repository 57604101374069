import React from 'react';

import { TableColumn, TableRow, UserRoleTag } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { useWorkspacePermission } from '@/features/permissions';
import { WorkspaceUser } from '@/features/workspace';
import { useHistory, useWorkspaceId } from '@/hooks/router';

//region Main
type TeamMemberProps = {
  user: WorkspaceUser;
  isNarrow?: boolean;
};

export const TeamMember = ({ user, isNarrow }: TeamMemberProps) => {
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  const { canUpdateTeamMember } = useCanUpdateTeamMember(user);

  return (
    <TableRow
      onClick={() => {
        canUpdateTeamMember &&
          history.push(`/admin/${workspaceId}/settings/team/user/${user.id}`);
      }}
    >
      {!isNarrow && <TableColumn width="33%">{user.name}</TableColumn>}
      <TableColumn width={isNarrow ? '80%' : '33%'}>{user.email}</TableColumn>
      <TableColumn width="33%">
        <UserRoleTag role={user.role} />
      </TableColumn>
    </TableRow>
  );
};
//endregion

//region hooks
const useCanUpdateTeamMember = (user: WorkspaceUser) => {
  const meQuery = useMeQuery();

  const canUpdateMyself = meQuery?.data?.id && meQuery?.data?.id === user.id;

  const canUpdateTeamMemberPermission = useWorkspacePermission()(
    'workspace members',
    'update'
  );

  const canUpdateTeamMember =
    canUpdateTeamMemberPermission || canUpdateMyself || false;

  return {
    user,
    canUpdateTeamMember,
  };
};
//endregion
