import styled from '@emotion/styled';

export const StatePill = styled.span`
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 10px;
  font-weight: 500;
  height: 16px;
  padding: 0 8px;
  white-space: nowrap;
`;
