import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

export const ClearAllFilters = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button variant="tertiary" onClick={onClick}>
      Clear all filters
    </Button>
  );
};

const Button = styled(DSButton)`
  flex-shrink: 0;
`;
