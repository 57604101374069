import { useMutation, useQuery } from '@tanstack/react-query';

import {
  fetchWorkspaceCandidatesStats,
  fetchWorkspaceStats,
  markStatsAsSeen,
} from '@/features/workspace';
import { Id } from '@/types/misc';

import { WorkspaceStats } from '../types';

export const WORKSPACE_STATS_QUERY_KEY = ['workspaceStats'];

export const useWorkspaceStatsQuery = ({
  workspaceId,
  onSuccess,
}: {
  workspaceId?: Id;
  onSuccess?: (data: WorkspaceStats) => void;
}) => {
  return useQuery(
    [...WORKSPACE_STATS_QUERY_KEY, workspaceId],
    () => fetchWorkspaceStats(workspaceId!),
    {
      enabled: !!workspaceId,
      onSuccess: data => {
        if (onSuccess) {
          onSuccess(data);
        }
      },
    }
  );
};

export const WORKSPACE_CANDIDATES_STATS_QUERY_KEY = [
  'workspaceCandidatesStats',
];

export const useWorkspaceCandidatesStatsQuery = ({
  workspaceId,
  onSuccess,
}: {
  workspaceId: Id;
  onSuccess?: (data: { total: number }) => void;
}) =>
  useQuery(
    [...WORKSPACE_CANDIDATES_STATS_QUERY_KEY, workspaceId],
    () => fetchWorkspaceCandidatesStats(workspaceId),
    { enabled: !!workspaceId, onSuccess }
  );

export const useMarkStatsAsSeenMutation = ({
  workspaceId,
}: {
  workspaceId: Id;
}) => {
  return useMutation(() => markStatsAsSeen({ workspaceId }));
};
