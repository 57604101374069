import { useTheme } from '@emotion/react';

/**
 * Support for colors from our design system.
 * To use colors from desing system, color string should follow this format -> $name-$value (e.g. purple-100, purple-60 ...).
 * This also supports regular color names (green, blue...) or hash values.
 */
export const useColor = (
  color: string | undefined,
  defaultColor: string
): string => {
  const theme = useTheme();
  const colorValues = (color || defaultColor).split('-');

  if (colorValues.length === 1) {
    return colorValues[0];
  } else {
    return theme.colors[colorValues[0]][colorValues[1]];
  }
};
