import { useCallback, useMemo, useState } from 'react';
import { DateUtils } from 'react-day-picker';

import { DateRange } from '../types/time';

export const useDateRange = ({
  initialRange,
  onRangeChange,
}: {
  initialRange?: DateRange;
  onRangeChange?: (range: DateRange) => void;
} = {}) => {
  const [activeRange, setActiveRange] = useState<{
    from: Date | undefined;
    to: Date | undefined;
    enteredTo: Date | undefined;
  }>({
    from: initialRange?.start,
    to: initialRange?.end,
    enteredTo: initialRange?.end,
  });
  const today = useMemo(() => {
    const today = new Date();
    today.setHours(12, 0, 0, 0);
    return today;
  }, []);

  const isSelectingFirstDay = useCallback((from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }, []);

  const handleReset = useCallback(() => {
    setActiveRange({ from: undefined, to: undefined, enteredTo: undefined });
  }, []);

  const onDayClick = useCallback(
    day => {
      const { from, to } = activeRange;

      if (DateUtils.isFutureDay(day)) {
        return;
      } else if (from && to && day >= from && day <= to) {
        handleReset();
        setActiveRange({ from: day, to: undefined, enteredTo: undefined });
      } else if (isSelectingFirstDay(from, to, day)) {
        setActiveRange({ from: day, to: undefined, enteredTo: undefined });
      } else {
        const newRange = {
          from: activeRange.from,
          to: day,
          enteredTo: day,
        };
        setActiveRange(newRange);
        onRangeChange?.({ start: newRange.from!, end: newRange.to });
      }
    },
    [activeRange, handleReset, isSelectingFirstDay, onRangeChange]
  );

  const onDayMouseEnter = useCallback(
    day => {
      const { from, to } = activeRange;

      if (DateUtils.isFutureDay(day)) {
        return;
      }

      if (!isSelectingFirstDay(from, to, day)) {
        setActiveRange(range => ({ ...range, enteredTo: day }));
      }
    },
    [isSelectingFirstDay, activeRange]
  );

  const disabledDays = { after: today };
  const selectedDays = [
    activeRange.from,
    activeRange.from && activeRange.enteredTo
      ? { from: activeRange.from, to: activeRange.enteredTo }
      : undefined,
  ];
  const firstDayOfWeek = 1;
  const toMonth = today;

  return {
    activeRange,
    disabledDays,
    selectedDays,
    firstDayOfWeek,
    toMonth,
    onDayClick,
    onDayMouseEnter,
  };
};
