import React, { createContext, useCallback, useState } from 'react';

import { TEmailModal } from '@/features/email';

export const EmailModalContext = createContext<{
  isOpen: boolean;
  emailModalData: TEmailModal | null;
  open: (emailModal: TEmailModal) => void;
  close: () => void;
}>({
  isOpen: false,
  emailModalData: null,
  open: (_: TEmailModal) => {},
  close: () => {},
});

export function EmailModalContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [emailModalData, setEmailModalData] = useState<TEmailModal | null>(
    null
  );
  const open = useCallback(
    emailModalData => {
      setEmailModalData(emailModalData);
      setIsOpen(true);
    },
    [setIsOpen]
  );
  const close = useCallback(() => {
    setEmailModalData(null);

    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <EmailModalContext.Provider value={{ isOpen, emailModalData, open, close }}>
      {children}
    </EmailModalContext.Provider>
  );
}
