import React from 'react';
import { CandidateAnswer } from '@hundred5/design-system';

import { ICandidateAnswer } from '@/features/candidate';

export interface CodeAnswerProps {
  answer?: ICandidateAnswer;
}

export function CodeAnswer(props: CodeAnswerProps) {
  return props.answer ? (
    <CandidateAnswer.CodeAnswer>
      {props.answer.freeFormAnswer}
    </CandidateAnswer.CodeAnswer>
  ) : null;
}
