import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';
import { Formik } from 'formik';

import { FormikInputField, PromptIfDirty } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useWorkspacePermission } from '@/features/permissions';
import { useUpdateWorkspaceMutation, useWorkspace } from '@/features/workspace';

import { WorkspacePicture } from './ui';
import { TWorkspaceAttributes } from './workspace-settings-general.types';
import {
  createWorkspaceAttributes,
  schema,
} from './workspace-settings-general.utils';

export const WorkspaceSettingsGeneral = () => {
  const { showFlashMessage } = useFlashMessages();
  const workspace = useWorkspace();
  const initialValues = useMemo(() => createWorkspaceAttributes(workspace), [
    workspace,
  ]);
  const updateWorkspaceMutation = useUpdateWorkspaceMutation({
    onSuccess: () => showFlashMessage({ type: 'saved' }),
  });

  const canUpdateWorkspaceSettings = useWorkspacePermission()(
    'workspace settings',
    'update'
  );

  const onSubmit = async (values: TWorkspaceAttributes) => {
    if (!workspace) {
      throw new Error('Workspace not loaded');
    }

    await updateWorkspaceMutation.mutateAsync({
      workspaceId: workspace.id,
      workspace: {
        ...workspace,
        name: values.name,
        logoUrl: values.logoUrl,
      },
    });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={schema}
    >
      {form => (
        <form onSubmit={form.handleSubmit}>
          <PromptIfDirty />
          <ContentBlock disabled={!!workspace?.transferOwnershipRequestId}>
            <DSContentBlock.Title>Workspace</DSContentBlock.Title>
            <DSContentBlock.Content>
              <Container>
                <WorkspacePicture
                  disabled={!canUpdateWorkspaceSettings}
                  pictureUrl={form.values.logoUrl}
                  placeholder={workspace?.name}
                  onUploaded={imageUrl =>
                    form.setFieldValue('logoUrl', imageUrl)
                  }
                />
                <InputWrapper>
                  <FormikInputField
                    name="name"
                    id="workspace-settings-name"
                    label="Workspace name"
                    required
                  />
                </InputWrapper>
              </Container>
            </DSContentBlock.Content>
            <DSContentBlock.Footer>
              <DSButton
                variant="primary"
                type="submit"
                disabled={
                  !form.dirty ||
                  form.isSubmitting ||
                  !canUpdateWorkspaceSettings
                }
                data-rh={
                  !canUpdateWorkspaceSettings
                    ? 'Only admins can save changes'
                    : null
                }
              >
                {form.isSubmitting ? 'Saving...' : 'Save changes'}
              </DSButton>
            </DSContentBlock.Footer>
          </ContentBlock>
        </form>
      )}
    </Formik>
  );
};

const ContentBlock = styled(DSContentBlock)<{ disabled: boolean }>`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
`;

const InputWrapper = styled.div`
  flex-grow: 1;
  min-width: 200px;
  max-width: 355px;
  margin-right: auto;
`;
