import React from 'react';

export const CreateQuestionInfoIcon = props => {
  return (
    <svg
      width="106"
      height="79"
      viewBox="0 0 106 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.429 7.80097C45.8017 7.73563 35.4777 17.9453 35.396 30.5726C35.3143 43.3632 45.5076 53.6872 58.2656 53.7526C70.7949 53.8016 81.2659 43.5919 81.3476 31.275C81.413 18.1903 71.3503 7.86631 58.429 7.80097Z"
        fill="#D5D0D7"
      />
      <path
        d="M78.7344 63.0319L78.7677 62.9819L78.7843 62.9986L78.7344 63.0319Z"
        fill="#DEDEDE"
      />
      <path
        d="M84.125 68.2592C84.1416 68.2425 84.1416 68.2259 84.1583 68.2092C84.1416 68.2259 84.125 68.2425 84.125 68.2592Z"
        fill="#DEDEDE"
      />
      <path
        d="M78.2448 30.6707C73.3442 23.4341 66.6957 19.4646 59.8675 19.3012C52.8269 19.1379 45.5739 23.0094 39.8075 31.5201C39.6932 31.6835 39.4318 32.1899 39.3828 32.2879C48.3183 48.313 68.4109 48.7377 78.5062 33.6601C78.5062 33.6601 78.9309 33.0557 79.2086 32.3696C79.127 32.1245 78.3592 30.8177 78.2448 30.6707Z"
        fill="#FDFDFD"
      />
      <path
        d="M79.0777 32.6472C78.3263 32.6472 77.9342 32.2061 77.5585 31.6017C68.721 16.1974 49.8209 16.2137 41.1631 31.7487C40.3137 33.5293 37.8307 32.1571 39.0069 30.5072C39.2192 29.7231 40.8854 28.4979 40.0033 27.8282C38.0104 26.6194 39.4316 23.5483 41.4735 25.1492C41.8166 25.3942 42.1433 26.162 42.5843 25.5902C44.0218 23.9894 45.59 22.5355 47.3869 21.3103C48.3507 20.5753 45.2307 18.762 47.4033 17.3898C48.1057 16.9978 48.8735 17.2265 49.3635 17.9779C50.115 19.7095 50.3273 19.7748 51.9609 18.8274C53.7251 18.1249 55.5547 17.7329 57.4169 17.5369C59.0505 17.4715 56.9105 14.1391 59.5242 13.9104C61.027 13.8941 61.1087 15.691 60.9944 16.8508C60.978 17.3082 61.1087 17.5532 61.5988 17.6022C63.8694 17.8472 66.0257 18.4353 68.0676 19.4644C68.8191 19.8075 68.9987 18.272 69.4561 17.8799C70.4199 16.3117 72.7232 17.6839 71.9881 19.2848C71.7758 19.8892 71.008 20.4936 71.1714 21.0817C71.3184 21.5227 71.9881 21.8167 72.4455 22.1598C73.736 23.1563 74.9449 24.2507 76.0393 25.4759C76.3987 25.8843 76.6274 25.8353 76.9051 25.4596C77.4932 24.6918 78.5877 24.4141 79.2411 25.1002C80.6786 26.9134 78.3753 27.5178 78.457 28.3509C79.0287 29.6904 81.5117 31.716 79.0777 32.6472Z"
        fill="#3F2F46"
      />
      <path
        d="M59.5568 40.9293C64.4195 40.9293 68.3616 36.9873 68.3616 32.1245C68.3616 27.2617 64.4195 23.3197 59.5568 23.3197C54.694 23.3197 50.752 27.2617 50.752 32.1245C50.752 36.9873 54.694 40.9293 59.5568 40.9293Z"
        fill="#ABA0AF"
      />
      <path
        d="M59.5893 36.3062C61.9259 36.3062 63.8202 34.412 63.8202 32.0754C63.8202 29.7387 61.9259 27.8445 59.5893 27.8445C57.2526 27.8445 55.3584 29.7387 55.3584 32.0754C55.3584 34.412 57.2526 36.3062 59.5893 36.3062Z"
        fill="#3F2F46"
      />
      <path
        d="M62.6768 30.8176C63.9669 30.8176 65.0128 29.7717 65.0128 28.4816C65.0128 27.1915 63.9669 26.1456 62.6768 26.1456C61.3867 26.1456 60.3408 27.1915 60.3408 28.4816C60.3408 29.7717 61.3867 30.8176 62.6768 30.8176Z"
        fill="#FDFDFD"
      />
      <path
        d="M21.9847 29.9518C19.3221 26.015 15.7119 23.8587 11.9875 23.7607C8.14863 23.6627 4.21178 25.7863 1.07538 30.4092C1.01003 30.4909 0.863015 30.7686 0.84668 30.8176C5.69831 39.5244 16.6267 39.7694 22.1154 31.569C22.1154 31.569 22.3441 31.2423 22.4911 30.8666C22.4748 30.7359 22.0501 30.0335 21.9847 29.9518Z"
        fill="#FDFDFD"
      />
      <path
        d="M22.4424 31.0298C22.034 31.0298 21.8217 30.7848 21.6256 30.458C16.823 22.078 6.54803 22.0943 1.84342 30.5397C1.38602 31.5035 0.0301829 30.7684 0.667265 29.87C0.781613 29.4453 1.68006 28.7755 1.20634 28.4161C0.128196 27.7627 0.895961 26.0801 2.00677 26.9623C2.2028 27.0929 2.36615 27.5177 2.61118 27.2073C3.39529 26.3252 4.24473 25.5411 5.22485 24.8877C5.74759 24.4956 4.0487 23.4991 5.22485 22.7477C5.60057 22.5354 6.02529 22.666 6.28666 23.0744C6.69504 24.0219 6.80939 24.0546 7.70784 23.5482C8.67163 23.1724 9.65176 22.9601 10.6809 22.8457C11.5793 22.8131 10.4032 20.9998 11.8244 20.8691C12.6411 20.8691 12.6738 21.8329 12.6248 22.47C12.6085 22.715 12.6902 22.8457 12.9515 22.8784C14.1767 23.0091 15.3528 23.3358 16.4636 23.8912C16.872 24.0872 16.97 23.2541 17.2151 23.0254C17.7378 22.176 18.9956 22.9111 18.5872 23.7932C18.4729 24.1199 18.0482 24.4466 18.1462 24.7733C18.2279 25.0183 18.5872 25.1654 18.8323 25.3614C19.5347 25.9005 20.1881 26.5049 20.7762 27.1583C20.9722 27.387 21.0866 27.3543 21.2499 27.1583C21.5766 26.7499 22.1647 26.5865 22.5241 26.9623C23.3082 27.9424 22.0504 28.2691 22.0994 28.7265C22.4097 29.4126 23.7656 30.5234 22.4424 31.0298Z"
        fill="#3F2F46"
      />
      <path
        d="M11.8244 35.5383C14.4678 35.5383 16.6107 33.3954 16.6107 30.752C16.6107 28.1086 14.4678 25.9657 11.8244 25.9657C9.18098 25.9657 7.03809 28.1086 7.03809 30.752C7.03809 33.3954 9.18098 35.5383 11.8244 35.5383Z"
        fill="#ABA0AF"
      />
      <path
        d="M11.8404 33.0226C13.1125 33.0226 14.1437 31.9914 14.1437 30.7193C14.1437 29.4472 13.1125 28.416 11.8404 28.416C10.5683 28.416 9.53711 29.4472 9.53711 30.7193C9.53711 31.9914 10.5683 33.0226 11.8404 33.0226Z"
        fill="#3F2F46"
      />
      <path
        d="M13.5232 30.0332C14.2269 30.0332 14.7974 29.4627 14.7974 28.759C14.7974 28.0553 14.2269 27.4849 13.5232 27.4849C12.8195 27.4849 12.249 28.0553 12.249 28.759C12.249 29.4627 12.8195 30.0332 13.5232 30.0332Z"
        fill="#FDFDFD"
      />
      <path
        d="M104.005 68.9609L83.2916 48.1985C84.4514 46.5486 85.4479 44.8007 86.2646 42.9385C87.8982 39.2303 88.7313 35.3098 88.764 31.2913C88.813 23.0092 85.7256 15.2499 80.0735 9.46715C74.3888 3.65173 66.7274 0.43365 58.478 0.384644C58.429 0.384644 58.3637 0.384644 58.3147 0.384644C54.2472 0.384644 50.294 1.18508 46.5695 2.75328C42.9757 4.27248 39.7413 6.4451 36.9643 9.20579C34.1872 11.9665 31.9983 15.1846 30.4464 18.7784C28.8455 22.5028 28.0288 26.456 27.9961 30.5235C27.9634 34.6564 28.7475 38.6586 30.3157 42.4321C31.8186 46.0749 34.0075 49.3583 36.7846 52.168C39.5616 54.9777 42.796 57.183 46.4225 58.7185C50.1633 60.3194 54.1492 61.1362 58.2493 61.1525H58.38C62.4149 61.1525 66.3681 60.3521 70.0926 58.7839C72.2325 57.8854 74.2418 56.7583 76.104 55.4188L96.801 76.1485C97.7975 77.1449 99.1043 77.6513 100.411 77.6513C101.718 77.6513 103.008 77.1612 104.005 76.1648C105.998 74.1719 105.998 70.9538 104.005 68.9609ZM58.38 53.7525C58.3474 53.7525 58.3147 53.7525 58.282 53.7525C45.524 53.7035 35.3307 43.3632 35.4124 30.5726C35.4941 17.9943 45.7527 7.80094 58.3147 7.80094C58.3474 7.80094 58.3964 7.80094 58.4454 7.80094C71.3667 7.86628 81.4294 18.1903 81.364 31.2586C81.3313 36.0286 79.7631 40.4718 77.1005 44.131C76.398 45.1111 75.6139 46.0259 74.7645 46.8753L74.7482 46.8917C74.3398 47.3164 73.8987 47.7084 73.4576 48.1005C73.4413 48.1005 73.4413 48.1168 73.425 48.1168C73.2126 48.3128 72.9839 48.4925 72.7552 48.6722C72.7389 48.6886 72.7226 48.6886 72.7226 48.7049C72.4939 48.8846 72.2652 49.0643 72.0201 49.244C68.1813 52.07 63.444 53.7525 58.38 53.7525Z"
        fill="#3F2F46"
      />
      <path
        d="M103.891 58.9146C100.575 52.7235 92.7178 44.7844 85.1054 46.8917C82.4101 48.1005 79.5024 51.7107 76.758 54.0303C76.1209 54.6347 73.9157 56.1212 73.4746 58.0815C73.4583 58.1142 73.4583 58.1468 73.4583 58.1632C72.6742 61.3649 76.0719 63.7009 78.9143 62.4104L78.947 62.3614L78.9633 62.3941L78.9143 62.4267C77.6238 66.0042 80.4498 69.1243 84.305 67.6541C82.5571 71.1009 86.1182 74.5803 89.5324 72.8814C88.5849 74.9397 89.2056 77.488 91.4763 78.3211C93.9266 79.5626 96.3932 76.5569 97.7164 75.2991C102.878 70.3004 108.106 67.0333 103.891 58.9146Z"
        fill="#D5D0D7"
      />
    </svg>
  );
};
