import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { fetchCandidateSuspiciousActivity } from '@/features/candidate';
import { TId } from '@/features/common';

export const CANDIDATE_SUSPICIOUS_ACTIVITY_QUERY_KEY = [
  'candidate',
  'suspicious-activity',
];

export const useCandidateSuspiciousActivityQuery = (
  params: {
    candidateId: TId;
    testId: TId;
  },
  options?: UseQueryOptions
) =>
  useQuery(
    [
      ...CANDIDATE_SUSPICIOUS_ACTIVITY_QUERY_KEY,
      params.candidateId,
      params.testId,
    ],
    () => fetchCandidateSuspiciousActivity(params),
    options as any
  );
