import React from 'react';

export const InterviewNotesEmptyStateIcon = props => (
  <svg
    width="79"
    height="57"
    viewBox="0 0 79 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="29.3123"
      cy="28.5"
      r="20.1774"
      transform="rotate(-47.8434 29.3123 28.5)"
      fill="#FFC4BC"
    />
    <path
      d="M4.30163 47.066C13.0993 47.3233 18.0248 40.0106 19.8605 35.1469C20.2279 34.1736 20.7526 33.2563 21.5357 32.5715C27.0993 27.7066 35.825 22.3452 33.7721 33.5667C31.6197 45.3316 13.6486 47.4669 4.30163 47.066C4.08059 47.0595 3.8571 47.0483 3.63115 47.0321C3.84924 47.0449 4.07285 47.0562 4.30163 47.066Z"
      fill="#FFC4BC"
    />
    <circle
      cx="20.1774"
      cy="20.1774"
      r="20.1774"
      transform="matrix(-0.671159 -0.741314 -0.741314 0.671159 78.8118 29.9155)"
      fill="#FF9587"
    />
    <path
      d="M75.3224 47.066C66.5247 47.3233 61.5993 40.0106 59.7635 35.1469C59.3962 34.1736 58.8714 33.2563 58.0883 32.5715C52.5247 27.7066 43.799 22.3452 45.8519 33.5667C48.0043 45.3316 65.9754 47.4669 75.3224 47.066C75.5434 47.0595 75.7669 47.0483 75.9929 47.0321C75.7748 47.0449 75.5512 47.0562 75.3224 47.066Z"
      fill="#FF9587"
    />
  </svg>
);
