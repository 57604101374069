import React from 'react';
import { useFormikContext } from 'formik';

import { TextButton } from '@/features/common';

export const UnselectAllButton = ({ name }) => {
  const form = useFormikContext();

  return (
    <TextButton
      type="button"
      onClick={() => form.setFieldValue(name, undefined)}
    >
      None
    </TextButton>
  );
};
