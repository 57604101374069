import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';
import { orderBy } from 'lodash';

import { Invoice as InvoiceType, useInvoicesQuery } from '@/features/billing';
import { TableColumn, TableRow } from '@/features/common';

import { Invoice } from './ui';

export const WorkSpaceSettingsInvoices = () => {
  const [invoiceCursor, setInvoiceCursor] = useState<string | null>(null);
  const { data, isLoading } = useInvoicesQuery({ cursor: invoiceCursor });

  if (!data) return null;

  const { invoices, cursor } = data;

  if (Object.keys(invoices).length === 0) return null;
  return (
    <DSContentBlock>
      <DSContentBlock.Title>Invoices</DSContentBlock.Title>
      <DSContentBlock.Content>
        <TableRow header>
          <TableColumn width="20%">Number</TableColumn>
          <TableColumn width="30%">Date</TableColumn>
          <TableColumn width="30%">Amount</TableColumn>
          <TableColumn width="20%"></TableColumn>
        </TableRow>
        {orderBy(invoices, ['date'], ['desc']).map((invoice: InvoiceType) => (
          <Invoice key={invoice.id} invoice={invoice} />
        ))}

        {invoiceCursor != null ? (
          <ShowMoreButton
            variant="secondary"
            size="xsmall"
            onClick={() => setInvoiceCursor(cursor)}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Show more invoices'}
          </ShowMoreButton>
        ) : null}
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const ShowMoreButton = styled(DSButton)`
  display: flex;
  margin: 12px auto auto;
`;
