import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { object } from 'yup';

import { TId } from '@/features/common';
import {
  EmailDownloadModal,
  EmailList,
  emailMessageValidationSchema,
  emailSubjectValidationSchema,
  FormikEmailBody,
  FormikEmailForm,
  FormikInputMultipleEmailsField,
  IEmailFormValuesBase,
  TEmailTemplateType,
} from '@/features/email';
import { useWorkspaceId } from '@/hooks/router';
import { trackIntercomEvent } from '@/intercom';

import { SubmitButtons } from './ui/submit-buttons';

interface SendEmailFormProps {
  onSend: (
    emails: string[],
    subject: string,
    message: string,
    sendAt: Date | undefined,
    action: string,
    cc?: string[],
    bcc?: string[]
  ) => Promise<any>;
  testId?: TId;
  interviewId?: TId | null;
  emailTemplatesType?: TEmailTemplateType;
  onSubmit?: () => void;
  emails?: string[];
  initialSubject?: string;
  initialMessage?: string;
  showUpsell?: boolean;
  actionButton?: React.ReactNode;
}

export interface IEmailModalFormValues extends IEmailFormValuesBase {
  sendAt?: Date;
  emails: string[];
  action: 'reject-without-email' | 'send';
  cc?: string[];
  bcc?: string[];
}

const schema = object().shape({
  subject: emailSubjectValidationSchema,
  message: emailMessageValidationSchema,
});

export function SendEmailForm({
  emails = [],
  onSubmit,
  onSend,
  emailTemplatesType,
  testId,
  interviewId,
  initialSubject,
  initialMessage,
  showUpsell,
  actionButton,
}: SendEmailFormProps) {
  const workspaceId = useWorkspaceId();
  const [sending, setSending] = useState(false);

  const handleSubmit = async (values: IEmailModalFormValues) => {
    if (showUpsell) {
      return;
    }

    setSending(true);

    await onSend(
      values.emails,
      // Converts [job name] to [jobName]
      values.subject.replace(/\[(.*?)\]/g, (match, p1) => {
        const capitalized = p1.replace(/\s+(\w)/g, (_, cap) =>
          cap.toUpperCase()
        );
        return `[${capitalized.replace(/\s+/g, '')}]`;
      }),
      values.message,
      values.sendAt,
      values.action,
      values.cc,
      values.bcc
    );

    setSending(false);

    amplitude?.logEvent('emails/send emails', {
      'workspace id': workspaceId,
      type: emailTemplatesType || '',
      'email count': values.emails.length,
    });

    if (emailTemplatesType) {
      trackIntercomEvent(
        emailTemplatesType === 'invitation'
          ? 'invited_candidates'
          : emailTemplatesType === 'rejection'
          ? 'rejected_candidate'
          : ''
      );
    }

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <>
      <FormikEmailForm<IEmailModalFormValues>
        initialValues={{
          emails: emails,
          message: initialMessage || '<p></p>',
          cc: [],
          bcc: [],
          subject: initialSubject || '',
          action: 'send',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, isValid, errors }) => (
          <>
            <Wrapper>
              {emails ? (
                <EmailList
                  emails={emails}
                  cc={values.cc}
                  bcc={values.bcc}
                  addCCToEmails={emailTemplatesType === 'gmail-candidate'}
                />
              ) : (
                <FormikInputMultipleEmailsField
                  name="emails"
                  label="Email"
                  placeholder="Add one or more emails"
                  required
                  disableFileInput={true}
                />
              )}
              <FormikEmailBody
                testId={testId || undefined}
                interviewId={interviewId || null}
                emailType={
                  (interviewId ? 'interview-invitation' : emailTemplatesType) ||
                  'invitation'
                }
              />
            </Wrapper>
            <Footer>
              <ActionButtonWrapper>{actionButton}</ActionButtonWrapper>
              <SubmitButtons
                sending={sending}
                disabled={
                  !isValid ||
                  sending ||
                  (values.emails.length === 0 && emails.length === 0)
                }
                {...((errors.subject || errors.message) && {
                  'data-rh': 'An e-mail must have subject and body.',
                  'data-rh-at': 'top',
                })}
              />
            </Footer>
          </>
        )}
      </FormikEmailForm>
      <EmailDownloadModal />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ActionButtonWrapper = styled.div`
  margin-right: auto;
`;

const Footer = styled.div`
  padding-top: 36px;
  display: flex;
  justify-content: space-between;
`;
