import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSContextMenu, DSContextMenuButton } from '@hundred5/design-system';
import useOnClickOutside from 'use-onclickoutside';

import { getShareTestUpsellTooltipAttributes } from '@/components/upsell_tooltip/utils';
import { copyText } from '@/components/utils/copy-text';
import { Icon } from '@/features/common';
import { IDashboardJobOpening } from '@/features/dashboard';
import { useFlashMessages } from '@/features/flash-messages';
import { getJobOpeningUrlForCandidate } from '@/features/job-opening/utils/job-opening-url';
import { ITest, redirectToTryTheTestYourself } from '@/features/test';
import { useApiError } from '@/hooks/api';
import { useCanShareTest } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

interface IProps {
  test: ITest;
  jobOpening: IDashboardJobOpening;
  onClickTestSettings: () => void;
}

export const TestContextMenu = (props: IProps) => {
  const handleApiError = useApiError();
  const ref = useRef<null | HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const workspaceId = useWorkspaceId();
  const { showFlashMessage } = useFlashMessages();
  const canShareTest = useCanShareTest()(props.test);

  useOnClickOutside(ref, () => setMenuOpen(false));

  return (
    <TestContextMenuContainer ref={ref} onClick={e => e.stopPropagation()}>
      <DSContextMenuButton
        active={menuOpen}
        onClick={() => setMenuOpen(!menuOpen)}
      />
      <DSContextMenu
        open={menuOpen}
        position={{ top: `${36 / 14}rem`, right: '0' }}
      >
        <DSContextMenu.Item
          label="Test settings"
          icon={<Icon icon={regular('gear')} color="purple-60" />}
          onClick={() => {
            props.onClickTestSettings();
            setMenuOpen(false);
          }}
        />
        {!!props.test.questionCount && (
          <DSContextMenu.Item
            label="Try the test yourself"
            icon={<Icon icon={regular('check-to-slot')} color="purple-60" />}
            onClick={() => {
              try {
                redirectToTryTheTestYourself(
                  props.jobOpening,
                  workspaceId,
                  props.test
                );
              } catch (error) {
                handleApiError(error);
              }
            }}
          />
        )}
        <div
          {...(!canShareTest &&
            getShareTestUpsellTooltipAttributes(props.test, 'right'))}
        >
          <DSContextMenu.Item
            label="Copy test link"
            icon={<Icon icon={regular('link-simple')} color="purple-60" />}
            disabled={!canShareTest}
            onClick={() => {
              copyText(
                getJobOpeningUrlForCandidate({
                  jobOpeningSlug: props.jobOpening.slug,
                  hashedTestId: props.test.hashedTestId,
                })
              );
              setMenuOpen(false);
              showFlashMessage({
                type: 'link_copied',
              });
            }}
          />
        </div>
      </DSContextMenu>
    </TestContextMenuContainer>
  );
};

const TestContextMenuContainer = styled.div`
  position: relative;
`;
