import React from 'react';
import styled from '@emotion/styled';
import formatDate from 'date-fns/format';
import { sortBy } from 'lodash';

import {
  getCandidateAnswerScoreStatus,
  getQuestionGroup,
  TCandidateAnswerScoreStatus,
  TQuestionGroup,
  useCandidateQuery,
  useCandidateTest,
} from '@/features/candidate';
import { CarouselNavigation, formatInterval, TId } from '@/features/common';
import { useCarousel } from '@/hooks/carousel';

//region Main
export interface CandidateAnswersSummaryProps {
  candidateId: TId;
  testId: TId;
}

export function CandidateAnswersSummary(props: CandidateAnswersSummaryProps) {
  const { data: candidate } = useCandidateQuery(props);
  const candidateTest = useCandidateTest(props);

  const {
    containerRef,
    contentRef,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    offset,
  } = useCarousel({ slideStep: 32 });

  if (!candidate || !candidateTest) return null;

  const questionGroups: TQuestionGroup[] = candidateTest.isSmart
    ? ['smart', 'static', 'code-input', 'free-text']
    : ['all'];

  const sortedQuestions = sortBy(candidateTest.questions, question =>
    questionGroups.indexOf(getQuestionGroup(question))
  );

  return (
    <CandidateAnswersSummaryContainer>
      <Content>
        <AnswersWrapper ref={containerRef}>
          <Answers
            style={offset}
            ref={contentRef}
            answerCount={sortedQuestions.length}
          >
            {sortedQuestions?.map((question, index) => {
              const answer = candidateTest?.answers?.find(
                answer => answer.questionId === question.id
              );
              const scoreStatus = getCandidateAnswerScoreStatus(
                question,
                answer
              );

              return (
                <Answer
                  key={index}
                  scoreStatus={scoreStatus}
                  data-rh={getTooltipLabel(scoreStatus)}
                  onClick={() =>
                    document
                      .getElementById(`question-${question.id}`)
                      ?.scrollIntoView({ behavior: 'smooth' })
                  }
                >
                  {index + 1}
                </Answer>
              );
            })}
          </Answers>
        </AnswersWrapper>

        {(canGoPrev || canGoNext) && (
          <AnswersCarouselNavigation>
            <CarouselContainer>
              <CarouselNavigation
                canGoToPrev={canGoPrev}
                canGoToNext={canGoNext}
                goPrev={goPrev}
                goNext={goNext}
                iconSize="16px"
              />
            </CarouselContainer>
          </AnswersCarouselNavigation>
        )}

        <TimeAndPoints>
          {candidateTest.finishedAt &&
            `Date: ${formatDate(
              Number(candidateTest.finishedAt),
              'MMM D, YYYY'
            )} / `}
          {candidateTest.finishedAt &&
            `Time: ${formatInterval(
              candidateTest.startedAt,
              candidateTest.finishedAt
            )} / `}
          Points: {candidateTest.points}
        </TimeAndPoints>
      </Content>
    </CandidateAnswersSummaryContainer>
  );
}
//endregion

//region Utils
const getTooltipLabel = (scoreStatus: TCandidateAnswerScoreStatus): string => {
  switch (scoreStatus) {
    case 'excellent':
      return 'Excellent';
    case 'good':
      return 'Good';
    case 'average':
      return 'Average';
    case 'weak':
      return 'Weak';
    case 'incorrect':
      return 'Incorrect';
    case 'correct':
      return 'Correct';
    case 'partial':
      return 'Partially correct';
    case 'wrong':
      return 'Wrong';
    case 'manual':
      return 'Evaluate manually';
    case 'missing':
      return 'No answer';
    case 'no_points':
      return 'No points assigned';
  }
};
//endregion

//region Styles
const CandidateAnswersSummaryContainer = styled.div`
  padding: 24px 36px 10px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${props => props.theme.colors.purple[10]};
  border-radius: 8px;
  background: white;
`;

const AnswersWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

const Answers = styled.div<{
  answerCount: number;
}>`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  padding: 4px;
  gap: 4px;
  transition: 0.6s;
  transition-timing-function: ease-in-out;

  @media all and (min-width: 769px) {
    flex: no-wrap;
    min-width: ${props =>
      props.answerCount > 0
        ? `${props.answerCount * 24 + (props.answerCount - 1) * 4 + 8}px` // (number of answers * width of box) + gap + padding left/right
        : 'auto'};
  }
`;

const Answer = styled.div<{
  scoreStatus: TCandidateAnswerScoreStatus;
}>`
  text-align: center;
  flex: 0 0 24px;
  max-width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
  color: ${props => {
    switch (props.scoreStatus) {
      case 'correct':
      case 'excellent':
      case 'good':
        return props.theme.colors.green[100];
      case 'wrong':
      case 'weak':
      case 'incorrect':
        return props.theme.colors.red[100];
      case 'partial':
      case 'average':
        return props.theme.colors.yellow[100];
      case 'manual':
      case 'missing':
        return '#bdbdbd';
    }
  }};
  transition: background 0.1s;
  border-radius: 3px;

  :hover {
    background-color: ${props => props.theme.hover.onWhite};
  }
`;

const TimeAndPoints = styled.div`
  color: #8c8c8c;
  display: flex;
  font-size: 10px;
  padding: 0 10px;
  text-align: right;
  white-space: nowrap;
  margin-left: auto;
`;

const AnswersCarouselNavigation = styled.div`
  margin-left: 24px;
  position: relative;
  width: 48px;

  @media all and (max-width: 768px) {
    display: none;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 40px;
`;
//endregion
