import { routerMiddleware } from 'connected-react-router';
import { createMemoryHistory, History } from 'history';
import { applyMiddleware, createStore, Middleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import createReducer, { State } from './reducers';
import { logMiddleware } from './tests/helpers/redux';
import { queryClientMiddleware } from './utils/redux';

export default (history: History) => {
  const store = createStore(
    createReducer(history),
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), queryClientMiddleware)
    )
  );

  return store;
};

type CreatedStore = { store: Store<State>; history: History };

interface TestStoreOptions {
  logActions?: boolean;
}

export const createTestStore = (
  initialPath: string,
  options?: TestStoreOptions
): CreatedStore => {
  const history = createMemoryHistory({
    initialEntries: initialPath ? [initialPath] : [],
  });

  let middlewares: Middleware[] = [];

  if (options?.logActions ?? false) middlewares.push(logMiddleware);
  middlewares.push(routerMiddleware(history));

  const reducer = createReducer(history);
  const enhancer = applyMiddleware(...middlewares);
  const store = createStore(reducer, enhancer);

  return { history, store };
};
