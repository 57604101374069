import styled from '@emotion/styled';

export const ListItem = styled.li`
  --list-item-border: 1px solid ${({ theme }) => theme.colors.purple[10]};

  align-items: center;
  border-top: var(--list-item-border);
  display: flex;
  height: 48px;
  padding: 0 16px;

  &:last-child {
    border-bottom: var(--list-item-border);
  }

  &:hover {
    background: ${({ theme }) => theme.colors.purple[5]};
  }
`;
