import React from 'react';

import { TId } from '@/features/common';
import {
  IPipelineCategory,
  usePipelineCategoriesQuery,
} from '@/features/pipeline';

interface CandidatePipelineStageProps {
  jobOpeningId: TId;
  candidatePipelineStageId: TId;
}

export function CandidatePipelineStage({
  jobOpeningId,
  candidatePipelineStageId,
}: CandidatePipelineStageProps) {
  const { data: categories } = usePipelineCategoriesQuery({ jobOpeningId });
  const candidateCategory: IPipelineCategory | undefined = categories?.find(
    (category: IPipelineCategory) => category.id === candidatePipelineStageId
  );

  return candidateCategory ? <span>{candidateCategory.name}</span> : null;
}
