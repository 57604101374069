import * as React from 'react';

export const ProfileSettingsPage = React.lazy(() =>
  import('@/pages/profile-settings-page')
);

export const TeamSettingsPage = React.lazy(() =>
  import('@/pages/workspace-team-settings-page')
);

export const WorkspaceSettingsIntegration = React.lazy(() =>
  import('@/pages/workspace/workspace-settings-integrations-page')
);

export const BillingPage = React.lazy(() =>
  import('@/pages/settings/billing-page')
);

export const DashboardPage = React.lazy(() =>
  import('@/pages/dashboard-page/dashboard-page')
);

export const JobOpeningPage = React.lazy(() =>
  import('@/pages/openings/job-opening-page')
);

export const JobOpeningSharePage = React.lazy(() =>
  import('@/pages/openings/job-opening-share-page')
);

export const JobOpeningAnalyticsPage = React.lazy(() =>
  import('@/pages/job-opening-analytics-page')
);

export const CandidateSatisfactionPage = React.lazy(() =>
  import('@/pages/candidate-satisfaction-page')
);

export const PipelinePage = React.lazy(() =>
  import('@/pages/pipeline/pipelines-page')
);

export const PipelineRecommendedSingleStagePage = React.lazy(() =>
  import('@/pages/pipeline/pipeline-recommended-single-stage-page')
);

export const PipelineSingleStagePage = React.lazy(() =>
  import('@/pages/pipeline/pipeline-single-stage-page')
);

export const JobOpeningSettingsPage = React.lazy(() =>
  import('@/pages/job-opening-settings-page')
);

export const SettingsEmailTemplatePage = React.lazy(() =>
  import('@/pages/settings/email-template-page')
);

export const HomePage = React.lazy(() => import('@/pages/home-page'));

export const InboxPage = React.lazy(() => import('@/pages/inbox-page'));

export const TestPage = React.lazy(() => import('@/pages/openings/test-page'));

export const TestLibraryDetailsPage = React.lazy(() =>
  import('@/pages/test-library-details-page')
);

export const AccountSettingsPage = React.lazy(() =>
  import('@/pages/account-settings-page')
);

export const OnboardingWizardPage = React.lazy(() =>
  import('@/pages/onboarding-wizard-page')
);

export const JobOpeningDescriptionPage = React.lazy(() =>
  import('@/pages/job-opening-description-page')
);

export const JobOpeningApplicationFormPage = React.lazy(() =>
  import('@/pages/job-opening-application-form-page')
);

export const InterviewPage = React.lazy(() =>
  import('@/pages/openings/interview-page')
);
