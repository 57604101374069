import { ICandidate } from '@/features/candidate';
import { IPipelineCandidate } from '@/features/pipeline';

export const getCandidateServiceName = (
  candidate: ICandidate | undefined
): 'github' | 'linkedin' | null => {
  if (!candidate) return null;

  const match = /^https?:\/\/([a-z.]+)/.exec(candidate.url);

  if (match) {
    const [, domain] = match;

    switch (domain) {
      case 'github.com':
        return 'github';
      case 'linkedin.com':
      case 'www.linkedin.com':
        return 'linkedin';
      default:
        return null;
    }
  } else {
    return null;
  }
};

export const getCandidateFirstName = (
  candidate: ICandidate | IPipelineCandidate | undefined
): string => {
  if (!candidate) return '';

  return (
    candidate.contactInfo.firstName.trim() ||
    getCandidateProvidedOriginalFirstName(candidate)
  );
};

export const getCandidateInitials = (
  candidate: ICandidate | undefined
): string => {
  if (!candidate) return '';

  const firstName = getCandidateFirstName(candidate);
  const lastName = getCandidateLastName(candidate);
  const email = getCandidateEmail(candidate);

  return (
    `${firstName.charAt(0)}${lastName.charAt(0)}` ||
    (email && `${email.charAt(0)}`) ||
    ''
  );
};

export const getCandidateProvidedOriginalFirstName = (
  candidate: ICandidate | IPipelineCandidate | undefined
): string => {
  if (!candidate) return '';

  const [firstName] = splitCandidateFullName(candidate);
  return firstName;
};

export const getCandidateLastName = (
  candidate: ICandidate | IPipelineCandidate | undefined
): string => {
  if (!candidate) return '';

  return (
    candidate.contactInfo.lastName.trim() ||
    getCandidateProvidedOriginalLastName(candidate)
  );
};

export const getCandidateProvidedOriginalLastName = (
  candidate: ICandidate | IPipelineCandidate | undefined
): string => {
  if (!candidate) return '';

  const [, lastName] = splitCandidateFullName(candidate);
  return lastName;
};

export const getCandidateEmail = (
  candidate: ICandidate | IPipelineCandidate | undefined
): string => {
  if (!candidate) return '';

  return (
    candidate.contactInfo.contactEmail.trim() || candidate.email.trim() || ''
  );
};

export const getCandidateFullName = (
  candidate: ICandidate | IPipelineCandidate | undefined
): string => {
  if (!candidate) return '';

  return `${getCandidateFirstName(candidate)} ${getCandidateLastName(
    candidate
  )}`.trim();
};

const splitCandidateFullName = (
  candidate: ICandidate | IPipelineCandidate
): [string, string] => {
  const fullName =
    candidate.contactInfo.fullName.trim() || candidate.name.trim();
  const [firstName, ...lastName] = fullName.split(/\s+/);
  return [firstName || '', lastName.join(' ') || ''];
};

export const getCandidateLinkedinUrl = (linkedinUrl: string): string | null => {
  const base = 'https://linkedin.com';

  try {
    if (/^https:\/\/([a-z]{2,3}\.)?linkedin\.com/.test(linkedinUrl)) {
      const url = new URL(linkedinUrl);
      return base + url.pathname;
    }

    if (/^([a-z]{2,3}\.)?linkedin\.com/.test(linkedinUrl)) {
      const url = new URL('https://' + linkedinUrl);
      return base + url.pathname;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const getCandidateGithubUrl = (githubUrl: string): string | null => {
  const base = 'https://github.com';

  try {
    if (/^https:\/\/([a-z]{2,3}\.)?github\.com/.test(githubUrl)) {
      const url = new URL(githubUrl);
      return base + url.pathname;
    }

    if (/^([a-z]{2,3}\.)?github\.com/.test(githubUrl)) {
      const url = new URL('https://' + githubUrl);
      return base + url.pathname;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export function getCandidateNameOrEmail(candidate: IPipelineCandidate): string {
  return getCandidateFullName(candidate) || getCandidateEmail(candidate);
}

/**
 * Checks if candidate is a recommended one that haven't done the test yet so they are still anonymized and some actions should be disabled.
 */
export function isCandidateAnonymizedRecommendation(
  candidate: ICandidate | IPipelineCandidate
) {
  return candidate.isRecommendation && candidate.hireState === 'invited';
}
