import React from 'react';
import qs from 'qs';

import { useFlashMessages } from '@/features/flash-messages';
import { useHistory } from '@/hooks/router';

import { useSaveAuthToken } from '../../hooks';

import { Query } from './callback.utils';

export const useHandleCallback = () => {
  const history = useHistory();
  const { showFlashMessage } = useFlashMessages();
  const saveAuthToken = useSaveAuthToken();

  return React.useCallback(
    async (query: Query) => {
      if (query.token != null) {
        saveAuthToken(query.token);
        history.replace('/admin');
      } else if (query.errorType != null) {
        if (query.errorType === 'UserNotFound') {
          history.replace({
            pathname: '/admin',
            search: qs.stringify({
              error: 'UserNotFound',
              provider: query.provider,
            }),
          });
        } else {
          showFlashMessage({
            type: 'third_party_login_error',
            error: query.errorType,
            meta: { provider: query.provider },
          });
          history.replace('/admin');
        }
      } else {
        showFlashMessage({
          type: 'third_party_login_error',
          error: 'StatusNotOK',
          meta: { provider: query.provider },
        });
        history.replace('/admin');
      }
    },
    [history, saveAuthToken, showFlashMessage]
  );
};
