import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import {
  isCandidateAnonymizedRecommendation,
  useCandidateQuery,
} from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { useEmailModalContext } from '@/features/email';
import { useMeQuery } from '@/features/me';
import { useWorkspace } from '@/features/workspace';

interface EmailButtonProps {
  candidateId: TId;
  interviewId: TId;
}

export function EmailButton(props: EmailButtonProps) {
  const { data: candidate } = useCandidateQuery(props);
  const { openEmailModal } = useEmailModalContext();
  const workspace = useWorkspace();
  const { data: me } = useMeQuery();

  const handleClick = () => {
    openEmailModal({
      type:
        workspace?.gmailEnabled && me?.gmailStatus === 'enabled'
          ? 'gmail-candidate'
          : 'contact-candidate',
      meta: {
        candidateId: props.candidateId,
        interviewId: props.interviewId,
      },
    });

    amplitude?.logEvent('candidates/email candidate', {
      'job opening id': candidate?.jobOpeningId,
      'candidate id': props.candidateId,
    });
  };

  if (!candidate) {
    return null;
  }

  const isCandidateAnonymized = isCandidateAnonymizedRecommendation(candidate);

  return (
    <DSButton
      type="button"
      data-rh={
        isCandidateAnonymized
          ? 'All actions will be unlocked after the candidate takes the test.'
          : 'Email candidate'
      }
      variant="secondary"
      onClick={handleClick}
      Icon={<Icon icon={regular('envelope')} />}
      disabled={isCandidateAnonymized}
    />
  );
}
