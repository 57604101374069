import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { PIPELINE_CATEGORIES_KEY } from '@/features/pipeline';

import {
  fetchRecommendedCandidates,
  sendInvitationRecommendedCandidate,
} from '../api/recommended-candidates';

export const RECOMMENDED_CANDIDATES_KEY = ['recommended-candidates'];

export const useRecommendedCandidatesQuery = (
  params: { jobOpeningId: TId },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...RECOMMENDED_CANDIDATES_KEY, params.jobOpeningId],
    () => fetchRecommendedCandidates({ jobOpeningId: params.jobOpeningId }),
    options as any
  );
};

export const useRecommendedCandidatesInvitationMutation = (
  params: { jobOpeningId: TId; categoryId: TId },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const { showFlashMessage } = useFlashMessages();

  return useMutation(sendInvitationRecommendedCandidate, {
    mutationKey: RECOMMENDED_CANDIDATES_KEY,
    onSuccess() {
      showFlashMessage({
        type: 'email_sent',
      });
      queryClient.invalidateQueries(RECOMMENDED_CANDIDATES_KEY);
      queryClient.invalidateQueries({
        queryKey: [
          ...PIPELINE_CATEGORIES_KEY,
          params.jobOpeningId,
          params.categoryId,
        ],
      });
    },
    ...(options as any),
  });
};
