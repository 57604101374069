import styled from '@emotion/styled';

export const NoAttachments = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 36px 0 24px;

  & > svg {
    height: 80px;
    margin-bottom: 24px;
    width: 80px;
  }

  h3 {
    font-family: GT Haptik;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
    margin-bottom: 24px;
    margin-top: 0;
    text-align: center;
  }

  p {
    color: ${({ theme }) => theme.colors.purple[60]};
    margin-top: 0;
    max-width: 480px;
    text-align: center;
  }
`;
