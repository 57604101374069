import React from 'react';
import styled from '@emotion/styled';
import { Route } from 'react-router';

import {
  CandidateFilters,
  CandidatesSearch,
} from '@/features/candidate-search/components';
import { DemoBadge, PageHeader } from '@/features/common';
import {
  JobOpeningArchivedTag,
  JobOpeningMenuPrimary,
  JobOpeningMenuSecondary,
  JobOpeningName,
  JobOpeningPausedTag,
  useJobOpeningQuery,
} from '@/features/job-opening';
import { useHistory } from '@/hooks/router';
import { getActiveDashboardTab } from '@/utils/dashboard';
import { getActivePipeline } from '@/utils/pipeline';

import { SideMenu } from './ui';

export function JobOpeningPageHeader() {
  const jobOpeningQuery = useJobOpeningQuery();
  const history = useHistory();
  const backToPath =
    history?.location.pathname.includes('candidate') &&
    getActivePipeline() !== null
      ? `/openings/${getActivePipeline()}`
      : `/${getActiveDashboardTab()}`;

  const tooltipInfo =
    history?.location.pathname.includes('candidate') &&
    getActivePipeline() !== null
      ? 'Go back to pipeline'
      : getActiveDashboardTab() === 'candidates'
      ? 'Go back to candidates'
      : 'Go back to dashboard';

  return (
    <PageHeader
      title={
        <>
          {jobOpeningQuery.data && (
            <JobOpeningName
              id={jobOpeningQuery.data.id}
              jobOpeningName={jobOpeningQuery.data.name}
            />
          )}
          <JobOpeningPausedTag pausedAt={jobOpeningQuery.data?.pausedAt} />
          <JobOpeningArchivedTag
            closedAt={jobOpeningQuery.data?.closedAt}
            tooltipPosition="bottom"
          />
          {jobOpeningQuery.data?.isDemo && <DemoBadge />}
        </>
      }
      backButton={{
        to: backToPath,
        tooltip: tooltipInfo,
      }}
      actions={<SideMenu />}
    >
      <JobOpeningMenuPrimary />
      <JobOpeningMenuSecondary />

      <Route
        path="/admin/:workspace/openings/:opening/pipeline"
        render={() => (
          <SearchFilters>
            <CandidatesSearch placeholder="Search name or email" />
            {/* TODO: remove hiddenFilters when BE allows filtering by country */}
            <CandidateFilters hiddenFilters={['country', 'actionRequired']} />
          </SearchFilters>
        )}
      />
    </PageHeader>
  );
}

const SearchFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
`;
