import React from 'react';
import styled from '@emotion/styled';
import { DSTestResultPage } from '@hundred5/design-system';

interface PoweredByProps {
  displayOn: 'mobile' | 'desktop';
}

export const PoweredBy = ({ displayOn }: PoweredByProps) => {
  return (
    <PoweredByContainer displayOn={displayOn}>
      <DSTestResultPage.PoweredBy
        label="Powered by"
        linkUrl="https://togglhire.com"
        labelColor={displayOn === 'mobile' ? 'purple-60' : 'peach-20'}
        logoColor={displayOn === 'mobile' ? 'purple-60' : 'peach-20'}
        logoHeight="18px"
      />
    </PoweredByContainer>
  );
};

const PoweredByContainer = styled.div<{ displayOn?: 'mobile' | 'desktop' }>`
  display: ${props => (props.displayOn === 'mobile' ? 'block' : 'none')};
  align-self: center;

  @media (min-width: 768px) {
    display: ${props => (props.displayOn === 'mobile' ? 'none' : 'block')};
  }
`;
