import React from 'react';
import { CandidateAnswer } from '@hundred5/design-system';
import linkify from 'markdown-linkify';
import { NodeType } from 'react-markdown';

import Markdown from '@/components/markdown/Markdown';
import { ICandidateAnswer } from '@/features/candidate';

const ALLOWED_MARKDOWN_TYPES: NodeType[] = [
  'paragraph',
  'heading',
  'break',
  'link',
  'emphasis',
  'strong',
  'inlineCode',
  'code',
  'blockquote',
  'list',
  'listItem',
  'thematicBreak',
  'text',
];

export interface TextAnswerProps {
  answer?: ICandidateAnswer;
}

export function TextAnswer(props: TextAnswerProps) {
  return props.answer ? (
    <CandidateAnswer.TextAnswer>
      <Markdown
        escapeHtml
        unwrapDisallowed
        allowedTypes={ALLOWED_MARKDOWN_TYPES}
        source={linkify(props.answer.freeFormAnswer)}
      />
    </CandidateAnswer.TextAnswer>
  ) : null;
}
