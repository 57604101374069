import { BillingPlan } from '@/features/billing';
import { useGetPlanRequired } from '@/hooks/planLimits';

import attachmentsPreview from '../components/icons/attachments_preview.svg';
import bulkActionsPreview from '../components/icons/bulk_actions_preview.svg';
import candidateEmailsPreview from '../components/icons/candidate_emails_preview.svg';
import candidateHistoryPreview from '../components/icons/candidate_history_preview.svg';
import redirectUrlPreview from '../components/icons/redirect_preview.svg';
import { PreviewFeatureModalType } from '../types';

export const titles: Record<PreviewFeatureModalType, string> = {
  candidate_history: 'Candidate history',
  candidate_emails: 'Email integration',
  bulk_actions: 'Bulk actions',
  custom_test: 'Custom test',
  redirect_url: 'Redirect URL',
  attachments: 'Attachments',
};

export const subTitles: Record<PreviewFeatureModalType, string> = {
  candidate_history:
    'See an overview of everything that has happened with a candidate - in one place.',
  candidate_emails: 'Never lose a thread',
  bulk_actions:
    'Effortlessly perform bulk actions like rejecting or achiving, on selected candidates with one click.',
  custom_test:
    'Create a test from scratch or mix and match your own questions with our test library',
  redirect_url:
    'Automatically redirect candidates to a chosen page upon completion of the test.',
  attachments:
    'Enable up to 10 attachments to collect additional files, such as candidate’s work sample or portfolio.',
};

export const pros: Record<PreviewFeatureModalType, string[]> = {
  candidate_history: [
    'Know what’s going on',
    'Collaborate effectively',
    'Make informed hiring decisions',
  ],

  candidate_emails: [
    'Know what’s going on',
    'Collaborate effectively',
    'Make informed hiring decisions',
  ],
  bulk_actions: [
    'Speed up repetitive tasks',
    'Organize your talent pool',
    'Assess candidates faster',
  ],
  custom_test: [
    'Be in charge of the process',
    'Create your own questions',
    'Choose from 15 000+ questions ',
  ],
  redirect_url: [
    'Use any website',
    'Seamless integration to your hiring process',
    'Be in charge of the process',
  ],
  attachments: [
    'Shortlist candidates faster',
    'Keep everything about a candidate in one place',
    'Boost efficiency',
  ],
};

export const upsells: Record<PreviewFeatureModalType, string> = {
  candidate_history: 'Join the digital teams who are always a step ahead',
  candidate_emails: 'Join the digital teams who are always a step ahead',
  bulk_actions: 'Unlock to automate your hiring process effortlessly. ',
  custom_test:
    'You can create a custom test on a free plan, just need to upgrade before sharing it with candidates.',
  redirect_url: 'Unlock to automate your hiring process effortlessly.',
  attachments: 'Unlock to automate your hiring process effortlessly.',
};

export const previewIcons: Partial<Record<PreviewFeatureModalType, string>> = {
  candidate_history: candidateHistoryPreview,
  candidate_emails: candidateEmailsPreview,
  bulk_actions: bulkActionsPreview,
  redirect_url: redirectUrlPreview,
  attachments: attachmentsPreview,
};

export const useRequiredPlans: () => Record<
  PreviewFeatureModalType,
  BillingPlan
> = () => {
  const candidateHistoryRequiredPlan =
    useGetPlanRequired('candidate_history', 'monthly') || 'premium-1m-v2-2023';
  const candidateEmailsRequiredPlan =
    useGetPlanRequired('candidate_history', 'monthly') || 'premium-1m-v2-2023';
  const bulkActionsRequiredPlan =
    useGetPlanRequired('bulk_actions', 'monthly') || 'business-1m-v2-2023';
  const redirectUrlRequiredPlan =
    useGetPlanRequired('candidate_redirect_url', 'monthly') ||
    'business-1m-v2-2023';
  const attachmentsRequiredPlan =
    useGetPlanRequired('candidate_attachments', 'monthly') ||
    'premium-1m-v2-2023';
  const customTestRequiredPlan =
    useGetPlanRequired('create_custom_test', 'monthly') || 'premium-1m-v2-2023';

  return {
    candidate_history: candidateHistoryRequiredPlan,
    candidate_emails: candidateEmailsRequiredPlan,
    bulk_actions: bulkActionsRequiredPlan,
    custom_test: customTestRequiredPlan,
    redirect_url: redirectUrlRequiredPlan,
    attachments: attachmentsRequiredPlan,
  };
};
