import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSField, DSInput, DSSelect } from '@hundred5/design-system';
import { noop, pick } from 'lodash';
import { Prompt } from 'react-router-dom';

import { ConfirmModal, Icon } from '@/features/common';
import {
  createEditorState,
  EditorState,
  getEditorMarkdown,
} from '@/features/common/components/custom-text-editor';
import { IQuestion, TQuestionType } from '@/features/questions';
import { ATTACHMENTS_OPTIONS } from '@/features/questions/utils/attachments-options';
import {
  IValidationResult,
  TEditableQuestion,
  useTestPage,
} from '@/features/test';
import { QuestionNotesEditor } from '@/features/test/components/test-editor-question-create/ui/question-notes-editor';
import { QuestionSettings } from '@/features/test/components/test-editor-question-preview/ui';
import { CUSTOM_QUESTION_TYPE_OPTIONS } from '@/features/test/utils/question-type-options';
import { runValidation } from '@/features/test/utils/validation';

import {
  QuestionAnswerEditor,
  QuestionDescriptionEditor,
} from '../test-editor-question-create/ui';
import { Container, PreviewHeader } from '../ui';

interface CustomQuestionEditorProps {
  index: number;
  question: TEditableQuestion;
  onCancel: () => void;
  onSave: (question: IQuestion) => void;
}

function getQuestionTypeFromRandomQuestion(
  question: TEditableQuestion
): TQuestionType {
  // Custom and static questions keep the same type as the original question
  if (question.source === 'custom' || !question.isRandom)
    return question.questionType;

  // Library questions need to be mapped to custom question types
  if (question.questionType === 'placeholder-close-ended') {
    if (question.answer) {
      return 'numeric-input';
    }
    if (question.options?.some(option => option.option.includes('http'))) {
      return 'picture-question';
    }
    if (
      question.options &&
      question.options.filter(option => option.isCorrect).length > 1
    ) {
      return 'multiple-choice';
    }

    return 'single-choice';
  } else if (question.questionType === 'video-question') {
    return 'video-question';
  } else {
    return 'free-text';
  }
}

const getAttachmentFieldLabelFromValue = (value: boolean | null) => {
  switch (value) {
    case true:
      return 'mandatory';
    case false:
      return 'optional';
    case null:
    default:
      return 'off';
  }
};

const getAttachmentValueFromLabel = (label: string) => {
  switch (label) {
    case 'mandatory':
      return true;
    case 'optional':
      return false;
    case 'off':
    default:
      return null;
  }
};

export const CustomQuestionEditor = ({
  index,
  question: initialQuestion,
  onCancel,
  onSave,
}: CustomQuestionEditorProps) => {
  const { test } = useTestPage();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [validationResults, setValidationResults] = React.useState<
    IValidationResult[]
  >([]);
  const [description, setDescription] = React.useState<EditorState>(
    createEditorState(initialQuestion.question ?? '')
  );
  const [notes, setNotes] = React.useState<EditorState>(
    createEditorState(initialQuestion.notes ?? '')
  );
  const [question, setQuestion] = React.useState<TEditableQuestion>({
    ...initialQuestion,
    source: 'custom',
    questionType: getQuestionTypeFromRandomQuestion(initialQuestion),
  });
  const updateDescription = useCallback((state: EditorState) => {
    setQuestion(oldQuestion => ({
      ...oldQuestion,
      question: getEditorMarkdown(state),
    }));
    setDescription(state);
  }, []);

  const updateNotes = useCallback((state: EditorState) => {
    setQuestion(oldQuestion => ({
      ...oldQuestion,
      notes: getEditorMarkdown(state),
    }));
    setNotes(state);
  }, []);

  const isVideoQuestion = test.type === 'video';
  const isHomework = test.type === 'homework';
  const hasChanges = useMemo(
    () =>
      question.question !== initialQuestion.question ||
      question.notes !== initialQuestion.notes ||
      question.answer !== initialQuestion.answer ||
      question.options !== initialQuestion.options ||
      question.points !== initialQuestion.points ||
      question.questionType !==
        getQuestionTypeFromRandomQuestion(initialQuestion),
    [question, initialQuestion]
  );

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setIsConfirmationOpen(true);
    } else {
      onCancel();
    }
  }, [hasChanges, onCancel]);

  const handleDiscard = useCallback(() => {
    setSaved(true);
    onCancel();
  }, [setSaved, onCancel]);

  const handleSave = useCallback(() => {
    const validationResults = runValidation(test, question);
    setValidationResults(validationResults);

    if (validationResults.length === 0) {
      setSaved(true);
      onSave({
        ...(question as IQuestion),
        questionType: isHomework ? 'free-text' : question.questionType,
        options:
          question?.options?.map(option =>
            pick(option, ['option', 'isCorrect'])
          ) ?? [],
        question: getEditorMarkdown(description),
      });
    }
  }, [description, isHomework, onSave, question, test]);

  return (
    <QuestionContainer>
      <Prompt
        when={hasChanges && !saved && !isConfirmationOpen}
        message={
          'You have unsaved changes! Are you sure you want to leave this page?'
        }
      />
      <PreviewHeader.Wrapper>
        <PreviewHeader.QuestionName>
          Question {index + 1}
        </PreviewHeader.QuestionName>
        <PreviewHeader.Actions>
          <DSButton variant="secondary" size="small" onClick={handleCancel}>
            <span>Cancel</span>
          </DSButton>
          <DSButton
            variant="primary"
            size="small"
            onClick={handleSave}
            disabled={!hasChanges}
          >
            <span>Save</span>
          </DSButton>
        </PreviewHeader.Actions>
      </PreviewHeader.Wrapper>
      <QuestionSettings>
        <DSField for="skill" label="Skill">
          <DSSelect
            items={[{ id: 'custom', label: 'Custom' }]}
            selectedItemId="custom"
            onChange={noop}
            disabled
          />
        </DSField>
        {!isVideoQuestion && !isHomework ? (
          <DSField for="type" label="Question type">
            <DSSelect
              items={CUSTOM_QUESTION_TYPE_OPTIONS}
              selectedItemId={question.questionType}
              onChange={option => {
                if (option) {
                  const type = option.id as TQuestionType;
                  setQuestion(oldQuestion => {
                    return {
                      ...oldQuestion,
                      questionType: type,
                      // reset option when handling picture questions
                      options:
                        type === 'picture-question' ||
                        oldQuestion.questionType === 'picture-question'
                          ? []
                          : oldQuestion.options,
                    };
                  });
                  setValidationResults([]);
                }
              }}
              placeholder="Select a question type"
            />
          </DSField>
        ) : null}
        {isHomework ? (
          <AttachmentsField
            for="attachments"
            label={
              <>
                Attachments
                <Icon
                  style={{ marginLeft: '4px' }}
                  icon={regular('circle-info')}
                  color="purple-60"
                  fontSize="12px"
                  data-rh="Accepted file types: doc, docx, xls, xlsx, odt, pdf, txt, jpg, jpeg, png"
                  data-rh-position="top"
                />
              </>
            }
          >
            <DSSelect
              id="attachments"
              items={ATTACHMENTS_OPTIONS}
              selectedItemId={getAttachmentFieldLabelFromValue(
                question.requireAttachment ?? null
              )}
              onChange={selectedItem =>
                setQuestion(oldQuestion => ({
                  ...oldQuestion,
                  requireAttachment: getAttachmentValueFromLabel(
                    selectedItem?.id ?? 'off'
                  ),
                }))
              }
            />
          </AttachmentsField>
        ) : null}
        <DSField for="points" label="Points">
          <DSInput
            type="number"
            min={1}
            value={question.points}
            onChange={event => {
              if (isNaN(+event.target.value)) return;
              setQuestion(oldQuestion => ({
                ...oldQuestion,
                points: +event.target.value,
              }));
            }}
          />
        </DSField>
      </QuestionSettings>
      <QuestionDescriptionEditor
        description={description}
        validationResults={validationResults}
        onChange={updateDescription}
      />
      <QuestionAnswerEditor
        questionType={question.questionType}
        answer={question.answer}
        onNumericAnswerChange={value =>
          setQuestion(oldQuestion => ({
            ...oldQuestion,
            answer: value,
          }))
        }
        options={question.options ?? []}
        onOptionsChange={options =>
          setQuestion(oldQuestion => ({
            ...oldQuestion,
            options: options,
          }))
        }
        validationResults={validationResults}
        isHomework={isHomework}
      />
      {question.questionType === 'free-text' ||
      question.questionType === 'code-input' ||
      question.questionType === 'video-question' ? (
        <QuestionNotesEditor notes={notes} onChange={updateNotes} />
      ) : null}
      <ConfirmModal
        open={isConfirmationOpen}
        action="Discard"
        onConfirm={handleDiscard}
        onClose={() => setIsConfirmationOpen(false)}
        title="You have unsaved changes!"
        contentStyle={{ minWidth: '500px' }}
      >
        <p>Are you sure you want to discard them?</p>
      </ConfirmModal>
    </QuestionContainer>
  );
};

const QuestionContainer = styled(Container)`
  overflow: visible;
`;

const AttachmentsField = styled(DSField)`
  grid-column: -3 / span 1;
  min-width: 130px;
`;
