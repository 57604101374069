import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock, DSGrid } from '@hundred5/design-system';
import formatDate from 'date-fns/format';

import { amplitude } from '@/features/amplitude';
import { ConfirmModal } from '@/features/common';
import { WebhooksIcon } from '@/features/integrations';
import { usePlanLimits } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';
import { useUpsellModal } from '@/hooks/upsellModal';

import {
  useDeleteWebhookMutation,
  useWorkspaceWebhooksQuery,
} from '../../queries';
import { IWebhook } from '../../types';
import {
  WorkspaceSettingsIntegrationAction,
  WorkspaceSettingsIntegrationContainer,
  WorkspaceSettingsIntegrationDescription,
  WorkspaceSettingsIntegrationIcon,
  WorkspaceSettingsIntegrationLink,
  WorkspaceSettingsIntegrationTable,
} from '../workspace-settings-integrations-page';

import { AddEditWebhookModal } from './ui';

export const WorkspaceSettingsIntegrationWebhooks = () => {
  return (
    <DSContentBlock>
      <DSContentBlock.Title>Webhooks</DSContentBlock.Title>
      <DSContentBlock.Content>
        <DSGrid gap="16px">
          <WebhooksElement />
        </DSGrid>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const WebhooksElement = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState<{
    open: boolean;
    variant: 'add' | 'edit';
  }>({
    open: false,
    variant: 'add',
  });

  const canAccessFeature = usePlanLimits();
  const [selectedWebhook, setSelectedWebhook] = useState<IWebhook | null>(null);
  const { openUpsellModal } = useUpsellModal();
  const workspaceId = useWorkspaceId();

  const { data } = useWorkspaceWebhooksQuery();
  const webhooks: IWebhook[] = data?.webhooks ?? [];

  const { mutateAsync: deleteWorkspaceWebhook } = useDeleteWebhookMutation();

  return (
    <>
      <WorkspaceSettingsIntegrationContainer>
        <WorkspaceSettingsIntegrationIcon>
          <WebhooksIcon />
        </WorkspaceSettingsIntegrationIcon>
        <IntegrationInfo>
          <WorkspaceSettingsIntegrationDescription>
            Use Webhooks to send alerts and synchronize actions between your
            systems and Toggl Hire.
          </WorkspaceSettingsIntegrationDescription>
          <WorkspaceSettingsIntegrationLink
            href="https://developers.hire.toggl.com/"
            target="_blank"
          >
            API documentation
          </WorkspaceSettingsIntegrationLink>
        </IntegrationInfo>
        <WorkspaceSettingsIntegrationAction>
          <DSButton
            variant="secondary"
            size="big"
            onClick={async () => {
              if (canAccessFeature('api_access')) {
                setOpenAddEditModal({
                  open: true,
                  variant: 'add',
                });
                amplitude?.logEvent('integrations/add webhooks', {
                  'workspace id': workspaceId,
                });
              } else {
                openUpsellModal('premium_feature');
              }
            }}
          >
            Add Weebhook
          </DSButton>
        </WorkspaceSettingsIntegrationAction>
      </WorkspaceSettingsIntegrationContainer>
      {!!webhooks && webhooks?.length !== 0 && (
        <WorkspaceSettingsIntegrationTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>URL</th>
              <th></th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {webhooks?.map((webhook: IWebhook) => (
              <TableRow
                key={webhook.id}
                webhook={webhook}
                setOpenEditModal={setOpenAddEditModal}
                setSelectedWebhook={setSelectedWebhook}
              />
            ))}
          </tbody>
        </WorkspaceSettingsIntegrationTable>
      )}

      <AddEditWebhookModal
        variant={openAddEditModal.variant}
        webhook={selectedWebhook}
        open={openAddEditModal.open}
        onClose={() =>
          setOpenAddEditModal({
            open: false,
            variant: 'add',
          })
        }
        onConfirm={() => setSelectedWebhook(null)}
        onRemove={() => {
          setOpenConfirmModal(true);
        }}
      />
      <ConfirmModal
        open={openConfirmModal}
        action="Remove Webhook"
        onConfirm={() => {
          deleteWorkspaceWebhook({
            webhookId: selectedWebhook?.id.toString()!,
          });
          setSelectedWebhook(null);
          setOpenConfirmModal(false);
        }}
        onClose={() => {
          setOpenConfirmModal(false);
        }}
        title="Revoke Webhook"
        footerVariant="destructive-primary"
        contentStyle={{ width: '600px' }}
      >
        Are you sure you want to remove this Webhook?
        <p>You will no longer receive updates via this Webhook. </p>
      </ConfirmModal>
    </>
  );
};

const TableRow = ({ key, webhook, setSelectedWebhook, setOpenEditModal }) => {
  return (
    <tr
      key={key}
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setSelectedWebhook(webhook);
        setOpenEditModal({ open: true, variant: 'edit' });
      }}
    >
      <td>{webhook.name}</td>
      <td>{webhook.url}</td>
      <td></td>
      <td>{formatDate(webhook.createdAt, 'MMM D, YYYY')}</td>
      <td></td>
    </tr>
  );
};

const IntegrationInfo = styled.div``;
