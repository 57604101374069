import React from 'react';
import { useFormikContext } from 'formik';

import { TextButton } from '@/features/common';

interface SelectAllButtonProps {
  name: string;
  values: string[];
}

export const SelectAllButton = ({ name, values }: SelectAllButtonProps) => {
  const form = useFormikContext();

  return (
    <TextButton type="button" onClick={() => form.setFieldValue(name, values)}>
      All
    </TextButton>
  );
};
