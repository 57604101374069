import { useMemo } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { ICandidateTest, useCandidateQuery } from '@/features/candidate';
import { TId } from '@/features/common';
import {
  IPipelineCategory,
  usePipelineCategoriesQuery,
  usePipelineCategoryTest,
} from '@/features/pipeline';
import { ITest } from '@/features/test';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

export const useCloseCandidateCard = () => {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const jobOpeningId = useJobOpeningId();

  return () => {
    history.push(`/admin/${workspaceId}/openings/${jobOpeningId}/pipeline`);
  };
};

interface IPipelineCategoryExtended extends IPipelineCategory {
  test: ITest | ICandidateTest | undefined;
}

/**
 * Candidate tests sorted by categories in the pipeline + current category test if candidate is not invited to it
 */
export const useSortedCategories = (candidateId: TId) => {
  const { stageId: categoryId, opening: jobOpeningId } = useParams();
  const { data: candidate } = useCandidateQuery({
    candidateId,
  });

  const { data: pipelineCategories } = usePipelineCategoriesQuery({
    jobOpeningId,
  });

  const pipelineCategoryTest = usePipelineCategoryTest(categoryId);

  return useMemo<IPipelineCategoryExtended[] | undefined>(() => {
    if (!candidate?.tests || !pipelineCategories) return;

    // Candidate tests + current category test if candidate is not invited to it
    const tests =
      pipelineCategoryTest &&
      !candidate?.tests.find(test => test.id === pipelineCategoryTest.id)
        ? [...candidate.tests, pipelineCategoryTest]
        : candidate.tests;

    const pipelineCategoriesExtended = pipelineCategories
      .map(category => {
        return {
          ...category,
          test: tests.find(test => test.id === category.testId),
        };
      })
      .filter(Boolean);

    return pipelineCategoriesExtended;
  }, [candidate?.tests, pipelineCategoryTest, pipelineCategories]);
};
