import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import {
  CandidateTestResetModal,
  getCandidateEmail,
  getCandidateFullName,
  useCandidateQuery,
  useCandidateTest,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { useEmailModalContext } from '@/features/email';
import { useJobOpeningPermission } from '@/features/permissions';
import { useUpsellModal } from '@/hooks/upsellModal';

//region Main
export interface CandidateTestStateMessagesProps {
  candidateId: TId;
  testId: TId;
}

export function CandidateTestStateMessages(
  props: CandidateTestStateMessagesProps
) {
  const candidateQuery = useCandidateQuery(props);
  const candidate = candidateQuery.data;
  const candidateTest = useCandidateTest(props);

  const { openEmailModal } = useEmailModalContext();
  const { openUpsellModal } = useUpsellModal();
  const [showResetTestModal, setShowResetTestModal] = useState(false);
  const canUpdateCandidate = !!useJobOpeningPermission()('candidate', 'update');

  const candidateNameOrEmail =
    getCandidateFullName(candidate) || getCandidateEmail(candidate);

  if (!candidate) return null;

  if (!candidateTest || candidateTest.candidateState === 'not_invited') {
    return (
      <CandidateTestStateMessageContainer>
        <span>Invite {candidateNameOrEmail} to take this test.</span>
        {canUpdateCandidate && (
          <DSButton
            onClick={() => {
              if (!candidate?.isUnlocked) {
                openUpsellModal('premium_feature');
              } else {
                openEmailModal({
                  type:
                    candidate.hireState === ''
                      ? 'test-invitation'
                      : 'next-stage-invitation',
                  meta: {
                    ...props,
                    onSuccess: () => candidateQuery.refetch(),
                  },
                });
              }
            }}
          >
            Invite candidate
          </DSButton>
        )}
      </CandidateTestStateMessageContainer>
    );
  }

  if (candidateTest.candidateState === 'out_of_time') {
    return (
      <CandidateTestStateMessageContainer>
        <span>{candidateNameOrEmail} is out of time.</span>
        {canUpdateCandidate && (
          <>
            <DSButton onClick={() => setShowResetTestModal(true)}>
              Reset test
            </DSButton>
            <CandidateTestResetModal
              candidateId={props.candidateId}
              testId={props.testId}
              open={showResetTestModal}
              onClose={() => setShowResetTestModal(false)}
            />
          </>
        )}
      </CandidateTestStateMessageContainer>
    );
  }

  if (['invited', 'interested'].includes(candidateTest.candidateState || '')) {
    return (
      <CandidateTestStateMessageContainer>
        <span>
          {candidateNameOrEmail} has been invited to complete this test.
        </span>
        {canUpdateCandidate && (
          <DSButton
            onClick={() =>
              openEmailModal({
                type:
                  candidate.hireState === ''
                    ? 'resend-test-invitation'
                    : 'resend-next-stage-invitation',
                meta: props,
              })
            }
          >
            Resend invitation
          </DSButton>
        )}
      </CandidateTestStateMessageContainer>
    );
  }

  if (['in_progress'].includes(candidateTest.candidateState || '')) {
    return (
      <CandidateTestStateMessageContainer>
        <span>
          {candidateNameOrEmail} is currently taking the test. Test results will
          soon be available.
        </span>
      </CandidateTestStateMessageContainer>
    );
  }

  if (
    ['completed'].includes(candidateTest.candidateState || '') &&
    candidateTest.testType === 'video'
  ) {
    return (
      <VideoFeedbackMessage>
        <span>Evaluate candidate's video responses below</span>
        <div>
          <DSButton
            onClick={() => {
              if (!candidate?.isUnlocked) {
                openUpsellModal('premium_feature');
              } else {
                openEmailModal({
                  type: 'video-feedback',
                  meta: props,
                });
              }
            }}
          >
            Give feedback
          </DSButton>
          {canUpdateCandidate && (
            <>
              <DSButton
                variant="secondary"
                onClick={() => setShowResetTestModal(true)}
                data-rh="Resetting the Video Intro will allow candidate to repeat the Video Intro and retake all their answers."
              >
                Reset
              </DSButton>
              <CandidateTestResetModal
                candidateId={props.candidateId}
                testId={props.testId}
                open={showResetTestModal}
                onClose={() => setShowResetTestModal(false)}
              />
            </>
          )}
        </div>
      </VideoFeedbackMessage>
    );
  }

  return null;
}
//endregion

//region Styles
const CandidateTestStateMessageContainer = styled.div`
  width: 100%;
  padding: 70px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const VideoFeedbackMessage = styled.div`
  width: 100%;
  padding: 70px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
  }

  button {
    margin-left: 12px;
  }
`;
//endregion
