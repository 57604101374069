import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Form, Formik } from 'formik';
import { capitalize } from 'lodash';

import { FieldWrapper } from '@/features/candidate-search/components/candidate-filters/ui/field-wrapper';
import { FormikCheckboxField, Icon, Popover } from '@/features/common';
import {
  ApplyButton,
  FullWidthPopover,
  IconWrapper,
  SelectAllButton,
  SubContent,
  Trigger,
  UnselectAllButton,
} from '@/features/filters';
import { HoverableFieldWrapper } from '@/features/filters/components/filters-select/ui/field-wrapper';
import { FilterName } from '@/features/filters/components/filters-select/ui/filter-name';

const TYPE_FILTER_TYPE = 'types';

export const TYPES = ['candidate', 'note'] as const;

export const TypeFilter = ({ initialValues, onApply }) => {
  return (
    <FullWidthPopover>
      <Popover.Trigger>
        {({ onClick }) => (
          <Trigger onClick={onClick}>
            <IconWrapper>
              <Icon icon={regular('tag')} color="purple-60" />
            </IconWrapper>
            Types
          </Trigger>
        )}
      </Popover.Trigger>
      <SubContent position="right">
        {({ close }) => (
          <>
            <Formik
              initialValues={initialValues}
              onSubmit={values => {
                onApply(values);
                close();
              }}
            >
              <Form>
                <SelectionFieldWrapper>
                  <FullWidthFilterName>Types</FullWidthFilterName>
                  <SelectAllButton
                    name={TYPE_FILTER_TYPE}
                    values={Object.keys(TYPES)}
                  />
                  <UnselectAllButton name={TYPE_FILTER_TYPE} />
                </SelectionFieldWrapper>
                {TYPES.map(type => (
                  <HoverableFieldWrapper key={type}>
                    <StyledFormikCheckboxField
                      name={TYPE_FILTER_TYPE}
                      value={type}
                      label={capitalize(type)}
                    />
                  </HoverableFieldWrapper>
                ))}
                <ApplyButton />
              </Form>
            </Formik>
          </>
        )}
      </SubContent>
    </FullWidthPopover>
  );
};

//region Styles
const FullWidthFilterName = styled(FilterName)`
  flex: 1;
  padding-left: 0;
  padding-right: 0;
`;
const SelectionFieldWrapper = styled(FieldWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
const StyledFormikCheckboxField = styled(FormikCheckboxField)`
  align-items: center;
`;

//endregion
