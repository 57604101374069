import React, { useMemo } from 'react';
import { DSSelect } from '@hundred5/design-system';

import { useDashboardJobOpeningsQuery } from '@/features/dashboard';
import {
  IJobOpeningPermissionRecord,
  useJobOpeningPermission,
} from '@/features/permissions';
import { useWorkspaceId } from '@/hooks/router';

import { useShareModal } from '../hooks';

interface JobOpeningListItem {
  id: string;
  label: string;
  labelText: string;
  disabled: boolean;
}

//region Main
const JobOpeningSelector = () => {
  const { jobOpeningIdSelected, selectJobOpening } = useShareModal();

  const workspaceId = useWorkspaceId();
  const dashboardJobOpeningsQuery = useDashboardJobOpeningsQuery({
    workspaceId,
    filter: 'open',
  });
  const initializing = dashboardJobOpeningsQuery.isLoading;
  const jobOpenings = dashboardJobOpeningsQuery.data?.openings;
  const canAddCandidate = useJobOpeningPermission();

  const items: JobOpeningListItem[] = useMemo(() => {
    if (jobOpenings) {
      const jobOpeningItems: JobOpeningListItem[] | undefined = jobOpenings.map(
        jobOpening => {
          const jobOpeningPermissionRecord: IJobOpeningPermissionRecord = {
            id: jobOpening.id,
            visibility: jobOpening.visibility,
          };

          const canAddCandidateToJobOpening = canAddCandidate(
            'candidate',
            'update',
            jobOpeningPermissionRecord
          );

          return {
            id: jobOpening.id,
            label: jobOpening.name,
            labelText: jobOpening.name,
            disabled: !canAddCandidateToJobOpening,
          };
        }
      );
      return jobOpeningItems;
    }
    return [];
  }, [jobOpenings, canAddCandidate]);

  if (!jobOpenings || initializing) {
    return null;
  }

  return (
    <DSSelect
      items={items}
      disabled={!!jobOpeningIdSelected}
      placeholder="Select job"
      selectedItemId={jobOpeningIdSelected}
      onChange={selected => {
        if (selected) {
          selectJobOpening(selected.id);
        }
      }}
    />
  );
};
//endregion

//region Components

//endregion

//region Styles

//endregion

export default JobOpeningSelector;
