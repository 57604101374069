import styled from '@emotion/styled';

export const FieldWrapper = styled.div`
  padding: 4px 8px;
`;

export const HoverableFieldWrapper = styled(FieldWrapper)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.purple[5]};
    border-radius: 4px;
  }
`;
