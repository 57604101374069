import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { TId } from '@/features/common/types';
import { CandidateTag } from '@/types/candidateTag';

import { fetchTagsByJobOpening } from '../api/job-opening-tags';

const JOB_OPENING_TAGS_QUERY_KEY = ['job-opening', 'tags'];

export const useJobOpeningTagsQuery = (
  jobOpeningId: TId,
  options?: UseQueryOptions<CandidateTag[], Error, CandidateTag[]>
) => {
  return useQuery(
    [...JOB_OPENING_TAGS_QUERY_KEY, jobOpeningId],
    () => fetchTagsByJobOpening(jobOpeningId!),
    options
  );
};
