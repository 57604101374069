import React from 'react';
import styled from '@emotion/styled';

import { CarouselNavigation } from '@/features/common/components/carousel-navigation';
import { useCarousel } from '@/hooks/carousel';

import { ClearAllFilters } from '../buttons';

interface Iprops {
  onClearFilters: () => void;
  hasSearchParams: boolean;
}

export const FilterList: React.FC<Iprops> = ({
  children,
  onClearFilters,
  hasSearchParams,
}) => {
  const {
    contentRef,
    goNext,
    goPrev,
    canGoNext,
    canGoPrev,
    offset,
    containerRef,
  } = useCarousel({ slideStep: 200 });

  if (!hasSearchParams) {
    return null;
  }

  return (
    <>
      <Container ref={containerRef}>
        <FilterListWrapper style={offset} ref={contentRef}>
          {children}
        </FilterListWrapper>
      </Container>
      <Navigation>
        {(canGoPrev || canGoNext) && (
          <CarouselNavigation
            canGoToPrev={canGoPrev}
            canGoToNext={canGoNext}
            goPrev={goPrev}
            goNext={goNext}
          />
        )}
      </Navigation>
      <ClearAllFilters onClick={() => onClearFilters()} />
    </>
  );
};

//region style
const Container = styled.div`
  overflow: hidden;
  margin: 0 12px;
`;

const FilterListWrapper = styled.div`
  display: flex;
  position: relative;
  gap: 12px;
  width: max-content;
  touch-action: pan-y;
  touch-action: pan-x;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Navigation = styled.div`
  align-items: center;
  display: flex;
`;

//endregion
