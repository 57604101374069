import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { useEvaluateAiAnswerAndAddNoteMutation } from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

interface EvaluateAiButtonProps {
  candidateId: TId;
  answerId: TId;
  questionNumber: number;
}

export function EvaluateAiButton(props: EvaluateAiButtonProps) {
  const evaluateAiAnswerAndAddNoteMutation = useEvaluateAiAnswerAndAddNoteMutation();
  const canAccessAiFeatures = !!usePlanLimits()('candidate_answer_ai_features');
  const { openUpsellModal } = useUpsellModal();

  return (
    <DSButton
      type="button"
      variant="secondary"
      size="big"
      data-rh="Evaluate this answer on a scale of 0% to 100%. The result will be added to the candidate's notes."
      disabled={evaluateAiAnswerAndAddNoteMutation.isLoading}
      onClick={() =>
        canAccessAiFeatures
          ? evaluateAiAnswerAndAddNoteMutation.mutate(props)
          : openUpsellModal('premium_feature')
      }
      Icon={<Icon icon={regular('message-bot')} size="xs" />}
    >
      {evaluateAiAnswerAndAddNoteMutation.isLoading
        ? 'In progress, please wait...'
        : 'Auto-grade with AI'}
    </DSButton>
  );
}
