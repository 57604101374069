import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { orderBy } from 'lodash';

import { useSort } from '@/hooks/sort';
import { TrafficStats } from '@/types/analytics';

import {
  SortArrow,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeader as BaseTableHeader,
  TableRow,
} from '../job-opening-analytics-table';

type SortProperty = keyof TrafficStats | 'conversion';

const getConversionRate = (
  totalFinished: number,
  totalVisits: number
): string => {
  let conversion = (totalFinished / totalVisits) * 100;
  return (conversion < 1 ? conversion.toFixed(2) : conversion.toFixed(0)) + '%';
};

interface JobOpeningAnalyticsTrafficSourceTableProps {
  stats: TrafficStats[];
  locked?: boolean;
}

export function JobOpeningAnalyticsTrafficSourceTable({
  stats,
  locked,
}: JobOpeningAnalyticsTrafficSourceTableProps) {
  const { sortOrder, sortedBy, sortBy } = useSort<SortProperty>(
    'totalFinished',
    'desc'
  );

  return (
    <Table locked={locked}>
      <thead>
        <TableRow>
          <TableHeader onClick={() => sortBy('source')}>
            Source
            <SortArrow sortOrder={sortOrder} visible={sortedBy === 'source'} />
          </TableHeader>
          <TableHeader onClick={() => sortBy('totalVisits')} align="right">
            Test Visits
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'totalVisits'}
            />
          </TableHeader>
          <TableHeader onClick={() => sortBy('totalFinished')} align="right">
            Applicants
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'totalFinished'}
            />
          </TableHeader>
          <TableHeader onClick={() => sortBy('conversion')} align="right">
            Conversion
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'conversion'}
            />
          </TableHeader>
          <TableHeader onClick={() => sortBy('totalBest')} align="right">
            Best Candidates
            <SortArrow
              sortOrder={sortOrder}
              visible={sortedBy === 'totalBest'}
            />
          </TableHeader>
        </TableRow>
      </thead>

      <tbody>
        {orderBy(
          stats,
          sortedBy === 'conversion'
            ? stat => getConversionRate(stat.totalFinished, stat.totalVisits)
            : sortedBy,
          sortOrder
        ).map((stat: TrafficStats, index: number) => (
          <TableRow key={index}>
            <TableCell>
              {stat.source === 'unknown' ? 'Direct' : stat.source || 'Other'}
            </TableCell>
            <TableCell>{locked ? 'Hidden' : stat.totalVisits}</TableCell>
            <TableCell>{locked ? 'Hidden' : stat.totalFinished}</TableCell>
            <TableCell>
              {locked
                ? 'Hidden'
                : getConversionRate(stat.totalFinished, stat.totalVisits)}
            </TableCell>
            <TableCell>{locked ? 'Hidden' : stat.totalBest}</TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled(BaseTable)<{ locked?: boolean }>`
  ${props =>
    props.locked &&
    css`
      ${TableCell}:nth-child(n + 2) {
        color: ${props.theme.colors.purple[50]};
      }
    `}
`;

const TableHeader = styled(BaseTableHeader)`
  &:first-child {
    width: auto;
    padding-left: 0;
    text-align: left;
  }
`;

const TableCell = styled(BaseTableCell)`
  &:first-child {
    font-weight: bold;
    border-left: none;
    padding-left: 0;
    text-align: left;
    text-transform: uppercase;
    font-size: 12px;
  }
`;
