import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { useCandidateSearchParams } from '@/features/candidate-search';

export const ClearAllFilters = () => {
  const { clearSearchParams } = useCandidateSearchParams();

  return (
    <Button variant="tertiary" onClick={clearSearchParams}>
      Clear all filters
    </Button>
  );
};

const Button = styled(DSButton)`
  flex-shrink: 0;
`;
