import React from 'react';
import styled from '@emotion/styled';

interface ArrowsProps {
  arrowsColor?: string;
  orientation?: 'left' | 'right';
  className?: string;
  onClick: () => void;
}

export function Arrows({
  arrowsColor = '#ABA0AF',
  orientation = 'left',
  className,
  onClick,
}: ArrowsProps) {
  return (
    <Button type="button" onClick={onClick} className={className}>
      <ArrowsContainer
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        orientation={orientation}
      >
        <path
          d="M2.38087 7.99985L8.34652 1.76219C8.68653 1.4047 8.67606 0.838842 8.31857 0.497522C7.96108 0.156202 7.39559 0.168871 7.0565 0.525096L0.498391 7.3813C0.167691 7.72833 0.167691 8.27112 0.498391 8.61803L7.0565 15.4742C7.39768 15.8306 7.96346 15.8434 8.32118 15.5022C8.50227 15.3256 8.59543 15.0908 8.59543 14.8561C8.59543 14.6336 8.51274 14.4112 8.34741 14.2379L2.38087 7.99985ZM15.5001 14.2375L9.53443 7.99985L15.5001 1.76219C15.8401 1.4047 15.8296 0.838842 15.4721 0.497522C15.1146 0.156202 14.5488 0.169132 14.2075 0.525468L7.64934 7.38168C7.31864 7.7287 7.31864 8.27149 7.64934 8.6184L14.2075 15.4746C14.5486 15.8309 15.1144 15.8438 15.4721 15.5026C15.6566 15.3256 15.7497 15.0908 15.7497 14.8561C15.7497 14.6325 15.6678 14.4126 15.5001 14.2375Z"
          fill={arrowsColor}
        />
      </ArrowsContainer>
    </Button>
  );
}

const ArrowsContainer = styled.svg<{ orientation: 'left' | 'right' }>`
  margin: 16px;
  transform: rotate(${props => (props.orientation === 'left' ? 0 : 180)}deg);
`;

const Button = styled.button`
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
`;
