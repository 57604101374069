import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSModal } from '@hundred5/design-system';
import { useLocation } from 'react-router-dom';

import { useHistory } from '@/hooks/router';

import icon from './images/more_on_desktop.png';

export function MoreOnDesktopModal() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const desktopOnly = searchParams.get('desktopOnly');
  const history = useHistory();

  const handleClose = useCallback(() => {
    searchParams.delete('desktopOnly');
    history.replace({
      search: searchParams.toString(),
    });
  }, [searchParams, history]);

  return (
    <DSModal
      contentStyle={{
        padding: 0,
        margin: 'auto',
        width: 'calc(100% - 23px)',
        maxWidth: '500px',
        borderRadius: '8px',
        position: 'static',
        transform: 'none',
      }}
      open={desktopOnly === 'true'}
      onClose={handleClose}
    >
      <Container>
        <Image src={icon} />
        <Header>Discover more on desktop! </Header>
        <Text>
          Switch to our desktop web app for advanced features and real-time
          collaboration.
        </Text>
        <DSButton onClick={handleClose}>OK</DSButton>
      </Container>
    </DSModal>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 16px 36px 16px;
  background-color: ${props => props.theme.colors.peach[100]};
  gap: 8px;
  color: ${props => props.theme.typography.colorPrimary};
  text-align: center;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

const Text = styled.div`
  color: ${props => props.theme.typography.colorSecondary};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;
`;
const Image = styled.img`
  width: 250px;
  height: auto;
  margin: auto;
`;
