import { decamelizeKeys } from 'humps';
import { flow } from 'lodash';
import qs from 'qs';

/** valid query param keys */
type CandidateAppUrlQueryParamKey =
  | 'candidateToken'
  | 'backHref'
  | 'skipConfirmStart';

/** Returns a link to candidate app that can be sent to candidates */
export function getJobOpeningUrlForCandidate({
  jobOpeningSlug,
  hashedTestId,
  queryParams,
}: {
  jobOpeningSlug: string;
  hashedTestId?: string;
  queryParams?: {
    [key in CandidateAppUrlQueryParamKey]?: any;
  };
}): string {
  const base = `${window.location.origin}/c/${jobOpeningSlug}`;
  const params = flow([
    decamelizeKeys,
    keys => qs.stringify(keys, { addQueryPrefix: true }),
  ])(queryParams);

  if (hashedTestId) {
    return `${base}/${hashedTestId}${params}`;
  }

  return base + params;
}
