import React, { SyntheticEvent } from 'react';
import styled from '@emotion/styled';
import { Radio } from '@hundred5/design-system';
import { uniqueId } from 'lodash';

import { NegativeIcon } from './negative-icon';
import { PositiveIcon } from './positive-icon';

interface RadioIconFieldProps {
  name: string;
  value: 'positive' | 'negative';
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void;
}

export const RadioIconField = ({
  name,
  value,
  onChange,
}: RadioIconFieldProps) => {
  const id = uniqueId('job-opening-feedback-modal-');

  return (
    <StyledLabel>
      <StyledRadio
        className="job-opening-feedback-modal-radio-icon-field__radio"
        id={id}
        type="radio"
        color="black"
        name={name}
        value={value}
        onChange={onChange}
      />
      <ImageWrapper value={value}>
        {value === 'positive' ? <PositiveIcon /> : <NegativeIcon />}
      </ImageWrapper>
    </StyledLabel>
  );
};

const StyledLabel = styled.label`
  margin-right: 30px;
`;

const ImageWrapper = styled.div<{ value: 'positive' | 'negative' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 76px;
  cursor: pointer;
  border-radius: 50%;
  background: ${props =>
    props.value === 'positive'
      ? 'rgba(29, 196, 136, 0.15)'
      : 'rgba(233, 148, 170, 0.15)'};

  &:hover {
    background-color: ${props =>
      props.value === 'positive' ? 'rgb(29, 196, 136)' : 'rgb(233, 148, 170);'};
  }
`;

const StyledRadio = styled(Radio)`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + ${ImageWrapper} {
    background-color: ${props =>
      props.value === 'positive' ? 'rgb(29, 196, 136)' : 'rgb(233, 148, 170);'};
  }
`;
