import api from '@/api/api';
import { IEmailResponse, IInvitationEmailParams } from '@/features/email';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function sendInvitationEmail(
  emailParams: IInvitationEmailParams
): Promise<IEmailResponse> {
  const response = await api.post(
    '/emails/invite',
    serialiseAPIData(emailParams, {
      replacements: [['job_opening_slug', 'slug']],
    }),
    {
      captchaAction: 'email_invite',
    }
  );
  return parseAPIData(response.data);
}
