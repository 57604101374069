import * as React from 'react';
import {
  DSButton,
  DSColumn,
  DSContentBlock,
  DSField,
  DSGrid,
  DSRow,
} from '@hundred5/design-system';

import { CardInfo } from '@/features/billing';

interface ExistingCardProps {
  cardInfo: CardInfo;
  onEdit: () => void;
}

export const ExistingCard = ({ cardInfo, onEdit }: ExistingCardProps) => (
  <DSContentBlock>
    <DSContentBlock.Title>Payment</DSContentBlock.Title>
    <DSContentBlock.Content>
      <DSGrid gap="12px">
        <DSRow gap="16px" xsWrap>
          <DSColumn size={12}>
            <DSField for="card" label="Card">
              {cardInfo.cardType} •••• •••• •••• {cardInfo.last4}
            </DSField>
          </DSColumn>

          <DSColumn size={12}>
            <DSField for="expiration" label="Expiration">
              {cardInfo.month}/{cardInfo.year}
            </DSField>
          </DSColumn>
        </DSRow>
      </DSGrid>
    </DSContentBlock.Content>
    <DSContentBlock.Footer>
      <DSButton
        variant="secondary"
        size="xsmall"
        onClick={onEdit}
        data-testid="edit-card-details"
      >
        Edit details
      </DSButton>
    </DSContentBlock.Footer>
  </DSContentBlock>
);
