import React from 'react';
import styled from '@emotion/styled';

interface InfoBoxProps {
  heading: string | React.ReactNode;
  body: string;
}

export function InfoBox({ heading, body }: InfoBoxProps) {
  return (
    <Container>
      <h3>{heading}</h3>
      <p>{body}</p>
    </Container>
  );
}

const Container = styled.section`
  background: ${({ theme }) => theme.colors.purple[5]};
  border-radius: 8px;
  padding: 24px 12px;

  h3 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 16px;
    margin-top: 0;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
  }
`;
