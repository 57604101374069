import api from '@/api/api';
import { TId } from '@/features/common';
import { CandidateTag, CandidateTagAttributes } from '@/types/candidateTag';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function createTag(params: {
  workspaceId: TId;
  attributes: CandidateTagAttributes;
}): Promise<CandidateTag> {
  const response = await api.post(
    `/workspaces/${params.workspaceId}/tags`,
    serialiseAPIData(params.attributes)
  );
  return parseAPIData(response);
}

export async function addTagToCandidate(params: {
  tagId: TId;
  tagName: string;
  candidateId: TId;
}): Promise<void> {
  await api.post(
    `/test-takers/${params.candidateId}/tags`,
    serialiseAPIData(params)
  );
}

export async function removeTagFromCandidate(params: {
  tagId: TId;
  candidateId: TId;
}): Promise<void> {
  await api.delete(`/test-takers/${params.candidateId}/tags/${params.tagId}`);
}
