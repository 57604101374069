import React from 'react';
import { DSButton } from '@hundred5/design-system';

import { useWorkspacePermission } from '@/features/permissions';

interface CTAProps {
  onClick: () => void;
}

export function CTA({ onClick }: CTAProps) {
  const canCreateJobOpening = useWorkspacePermission()(
    'job openings',
    'create'
  );

  return (
    <DSButton
      onClick={onClick}
      disabled={!canCreateJobOpening}
      data-rh={
        !canCreateJobOpening
          ? 'Access restricted. Contact workspace admin to change your user rights.'
          : null
      }
    >
      Use this test
    </DSButton>
  );
}
