import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import { BulkInviteToNewJobModal } from '@/features/bulk-actions/components/ui/bulk-invite-to-new-job-modal';
import { useBulkActionModal } from '@/features/bulk-actions/hooks/use-bulk-action-modal';
import { BulkActionProps } from '@/features/bulk-actions/types';
import { Icon } from '@/features/common';

//region Main
export const BulkInviteToNewJob = ({
  disabled,
  selectedCandidates,
  onActionDone,
  onCloseModal,
  tooltip,
}: BulkActionProps) => {
  const { isOpen, setIsOpen, handleCloseModal } = useBulkActionModal(
    onCloseModal
  );

  return (
    <>
      <DSButton
        type="button"
        variant="secondary"
        size="xsmall"
        data-rh={tooltip ?? 'Invite candidates to a new job'}
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        Icon={<Icon icon={regular('briefcase-blank')} />}
      />
      <BulkInviteToNewJobModal
        open={isOpen}
        onClose={handleCloseModal}
        onActionDone={onActionDone}
        candidates={selectedCandidates}
      />
    </>
  );
};
//endregion
