import { find } from 'lodash';

import { TId } from '@/features/common';
import {
  TJobOpeningAccessRightType,
  TJobOpeningVisibility,
  useJobOpeningByIdQuery,
} from '@/features/job-opening';
import { useMeQuery } from '@/features/me';
import {
  TJobOpeningResources,
  TPermissionsAction,
  TWorkspaceRole,
  permissions,
} from '@/features/permissions';
import { IWorkspace } from '@/features/workspace';
import { useJobOpeningIdOrNull, useWorkspaceId } from '@/hooks/router';

const jobOpeningAccesRightToRoleMap: Record<
  TJobOpeningAccessRightType,
  TWorkspaceRole
> = {
  read: 'job_opening_viewer',
  note: 'job_opening_notes_only',
  write: 'job_opening_editor',
};

export interface IJobOpeningPermissionRecord {
  id: TId;
  visibility: TJobOpeningVisibility;
}

export const useJobOpeningPermission = (): ((
  resource: TJobOpeningResources,
  action: TPermissionsAction,
  jobOpeningPermissionRecord?: IJobOpeningPermissionRecord
) => boolean | null) => {
  const jobOpeningId = useJobOpeningIdOrNull();
  const { data: me } = useMeQuery();
  const workspaceId = useWorkspaceId();
  const { data: jobOpening } = useJobOpeningByIdQuery(
    { jobOpeningId: String(jobOpeningId) },
    { enabled: !!jobOpeningId }
  );

  return (resource, action, jobOpeningPermissionRecord) => {
    if (!me) return null;
    if (!jobOpening && !jobOpeningPermissionRecord) return null;

    const record = jobOpeningPermissionRecord || {
      id: jobOpeningId,
      visibility: jobOpening!.visibility,
    };
    const member: IWorkspace = find(
      me.workspaces,
      (workspace: IWorkspace) => workspace.id === workspaceId
    )!;

    if (!member) return null;

    if (record.visibility === 'invite-only') {
      const jobOpeningAccessRight = me.jobOpeningAccessRights?.find(
        jobOpeningAccessRight =>
          jobOpeningAccessRight.jobOpeningId === record.id
      )?.accessRight;

      if (jobOpeningAccessRight === undefined) {
        return null;
      } else {
        return permissions.can(
          jobOpeningAccesRightToRoleMap[jobOpeningAccessRight],
          resource,
          action
        );
      }
    }
    return permissions.can(member.role, resource, action);
  };
};
