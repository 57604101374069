import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { useHistory } from 'react-router';

import Tooltip from '@/components/tooltip/Tooltip';
import { useWorkspaceId } from '@/hooks/router';

/**
 * TODO: must be merged with all other upsell tooltips and moved to features/common
 */
export function UpsellTooltip() {
  const history = useHistory();
  const workspaceId = useWorkspaceId();

  return (
    <Tooltip
      delay={{ show: 0, hide: 500 }}
      attribute="data-test-result-page-settings-upsell-tooltip"
      persist
      onRenderContent={(_, content) => {
        return (
          <TooltipContent>
            {content}
            <DSButton
              onClick={() => {
                history.push(`/admin/${workspaceId}/settings/billing`);
              }}
            >
              Upgrade
            </DSButton>
          </TooltipContent>
        );
      }}
    />
  );
}

const TooltipContent = styled.div`
  width: 240px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  font-size: 12px;
  color: ${props => props.theme.typography.colorPrimary};
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow.popup};

  button {
    width: 100%;
  }
`;
