import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const AuthLink = styled(Link)`
  font-size: inherit;
  color: ${({ theme }) => theme.colors.orange[100]};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
