import { TQuestionType } from '@/features/questions';

type QuestionType = Exclude<TQuestionType, 'placeholder-open-ended'>;

export const DEFAULT_TITLES: { [K in QuestionType]: string } = {
  'free-text': 'Free Text',
  'single-choice': 'Single Choice',
  'multiple-choice': 'Multiple Choice',
  'picture-question': 'Picture Choice',
  'numeric-input': 'Numeric Input',
  'code-input': 'Code Input',
  'video-question': 'Video',
  'placeholder-close-ended': 'Smart',
};
