import { useMeQuery } from '@/features/me';
import { useWorkspace } from '@/features/workspace';
import { getPlanName } from '@/hooks/planLimits';

export function useEmailBanner() {
  const { data: me } = useMeQuery();
  const workspace = useWorkspace();
  const planName = getPlanName(workspace?.subscriptionPlan!);

  const showBanner =
    (planName === 'business' || planName === 'premium') &&
    workspace?.gmailEnabled &&
    me?.gmailStatus === 'disabled';

  return { showBanner };
}
