import React from 'react';
import styled from '@emotion/styled';

import { InterviewNotesEmptyStateIcon } from './candidate-interview-notes-empty-state-icon';

export function InterviewNotesEmptyState() {
  return (
    <Container>
      <InterviewNotesEmptyStateIcon />
      <Text>Add notes about the candidate</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  padding: 36px 32px 36px 32px;
`;

const Text = styled.div`
  font-family: GT Haptik;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.purple[100]};
`;
