import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ME_QUERY_KEY } from '@/features/me';
import {
  acceptOwnershipTransfer,
  cancelOwnershipTransfer,
  declineOwnershipTransfer,
  fetchOwnershipTransferRequests,
  requestOwnershipTransfer,
  WORKSPACE_USERS_QUERY_KEY,
} from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

const WORKSPACE_OWNERSHIP_TRANSFER_QUERY_KEY = [
  'workspace',
  'ownership-transfer',
];

export const useWorkspaceOwnershipTransferRequestsQuery = () => {
  const workspaceId = useWorkspaceId();
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(WORKSPACE_OWNERSHIP_TRANSFER_QUERY_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries([
        ...WORKSPACE_OWNERSHIP_TRANSFER_QUERY_KEY,
        workspaceId,
      ]),
  });

  return useQuery(
    [...WORKSPACE_OWNERSHIP_TRANSFER_QUERY_KEY, workspaceId],
    () => fetchOwnershipTransferRequests({ workspaceId })
  );
};

export const useRequestOwnershipTransferMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(requestOwnershipTransfer, {
    mutationKey: WORKSPACE_OWNERSHIP_TRANSFER_QUERY_KEY,
    ...(options as any),
  });
};

export const useCancelOwnershipTransferMutation = (
  options?: UseMutationOptions
) => {
  return useMutation(cancelOwnershipTransfer, {
    mutationKey: WORKSPACE_OWNERSHIP_TRANSFER_QUERY_KEY,
    ...(options as any),
  });
};

export const useAcceptOwnershipTransferMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(acceptOwnershipTransfer, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
      queryClient.invalidateQueries([
        ...WORKSPACE_OWNERSHIP_TRANSFER_QUERY_KEY,
        workspaceId,
      ]);
      queryClient.invalidateQueries([
        ...WORKSPACE_USERS_QUERY_KEY,
        workspaceId,
      ]);
    },
    ...(options as any),
  });
};

export const useDeclineOwnershipTransferMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(declineOwnershipTransfer, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME_QUERY_KEY);
      queryClient.invalidateQueries([
        ...WORKSPACE_OWNERSHIP_TRANSFER_QUERY_KEY,
        workspaceId,
      ]);
    },
    ...(options as any),
  });
};
