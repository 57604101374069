import React, { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { DSButton, DSContentBlock } from '@hundred5/design-system';

import { ConfirmModal } from '@/features/common';
import { useMeQuery } from '@/features/me';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { useDeleteWorkspaceMutation } from '../../queries';

export const WorkspaceSettingsActions = () => {
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const meQuery = useMeQuery();
  const deleteWorkspaceMutation = useDeleteWorkspaceMutation({
    onSuccess: () => {
      history.push('/admin');
    },
  });

  const isWorkspaceOwner = useMemo(
    () =>
      meQuery.data?.workspaces?.find(workspace => workspace.id === workspaceId)
        ?.role === 'owner',
    [meQuery.data?.workspaces, workspaceId]
  );

  if (!isWorkspaceOwner) {
    return null;
  }

  return (
    <DSContentBlock>
      <DSContentBlock.Title>Workspace actions</DSContentBlock.Title>
      <DSContentBlock.Content>
        <DeleteButton
          variant="secondary"
          onClick={() => setDeleteModalOpen(true)}
        >
          Delete workspace
        </DeleteButton>
        <ConfirmModal
          open={deleteModalOpen}
          action="Delete"
          confirmation="delete"
          onConfirm={() => {
            deleteWorkspaceMutation.mutate({ workspaceId });
          }}
          onClose={() => setDeleteModalOpen(false)}
        >
          <h3>Are you sure?</h3>
          <p>
            This workspace is going to be deleted permanently with no backups.
            Therefore be sure you really wish to do that.
          </p>
        </ConfirmModal>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const DeleteButton = styled(DSButton)`
  color: ${({ theme }) => theme.colors.red[100]};

  &:hover {
    background: ${({ theme }) => theme.colors.red[10]};
  }
`;
