import { useCallback, useMemo } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { debounce } from 'lodash';

import {
  AuthorizationError,
  ClientError,
  MultiClientError,
  NetworkError,
  ReCaptchaError,
  ServerError,
} from '@/errors';
import {
  TFlashMessageContent,
  useFlashMessages,
} from '@/features/flash-messages';

export const useApiError = () => {
  const { showFlashMessage } = useFlashMessages();
  const debouncedFlashMessage = useMemo(
    () =>
      debounce((content: TFlashMessageContent) => {
        showFlashMessage(content);
      }, 1000),
    [showFlashMessage]
  );

  return useCallback(
    error => {
      if (error instanceof ServerError) {
        amplitude?.logEvent('errors/server error');
        debouncedFlashMessage({ type: 'server_error', error });
      } else if (error instanceof NetworkError) {
        amplitude?.logEvent('errors/network error');
        debouncedFlashMessage({ type: 'network_error' });
      } else if (error instanceof AuthorizationError) {
        amplitude?.logEvent('errors/authorization error');
      } else if (error instanceof ReCaptchaError) {
        debouncedFlashMessage({ type: 'recaptcha_error' });
      } else if (error instanceof MultiClientError) {
        debouncedFlashMessage({ type: 'multi_client_error', error });
      } else if (error instanceof ClientError) {
        if (error.type === 'ForbiddenInSupportMode') return;

        amplitude?.logEvent('errors/client error', {
          type: error.type,
          error: error.message,
          errorId: error.errorId,
        });

        debouncedFlashMessage({ type: 'client_error', error });
      }
    },
    [debouncedFlashMessage]
  );
};
