import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import {
  CANDIDATE_QUERY_KEY,
  createCandidateNote,
  deleteCandidateNote,
  detectAiAnswerAndAddToNote,
  evaluateAiAnswerAndAddToNote,
  fetchCandidateNotes,
  ICandidate,
  markCandidateNotesAsRead,
  updateCandidateNote,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline/queries/pipeline-candidates';
import { WORKSPACE_STATS_QUERY_KEY } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

export const CANDIDATE_NOTES_QUERY_KEY = ['candidate', 'notes'];

export const useCandidateNotesQuery = (
  params: { candidateId: TId },
  options?: UseQueryOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  // Invalidate on successful mutations
  queryClient.setMutationDefaults(CANDIDATE_NOTES_QUERY_KEY, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...CANDIDATE_NOTES_QUERY_KEY,
        params.candidateId,
      ]);
      queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);
      queryClient.invalidateQueries([
        ...WORKSPACE_STATS_QUERY_KEY,
        workspaceId,
      ]);
    },
  });

  return useQuery(
    [...CANDIDATE_NOTES_QUERY_KEY, params.candidateId],
    () => fetchCandidateNotes(params),
    {
      onSuccess: async data => {
        queryClient.setQueryData(
          [...CANDIDATE_QUERY_KEY, params.candidateId],
          (oldCandidate: ICandidate) => ({
            ...oldCandidate,
            ...data,
            unreadNotes: 0,
          })
        );

        if (data.hasUnread) {
          await markCandidateNotesAsRead(params);
          queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);
        }
      },
      refetchOnMount: query => (query.state.data?.hasUnread ? 'always' : true),

      ...(options as any),
    }
  );
};

export const useCreateCandidateNoteMutation = (options?: UseMutationOptions) =>
  useMutation(createCandidateNote, {
    mutationKey: CANDIDATE_NOTES_QUERY_KEY,
    ...(options as any),
  });

export const useUpdateCandidateNoteMutation = (options?: UseMutationOptions) =>
  useMutation(updateCandidateNote, {
    mutationKey: CANDIDATE_NOTES_QUERY_KEY,
    ...(options as any),
  });

export const useDeleteCandidateNoteMutation = (options?: UseMutationOptions) =>
  useMutation(deleteCandidateNote, {
    mutationKey: CANDIDATE_NOTES_QUERY_KEY,
    ...(options as any),
  });

export const useDetectAiAnswerAndAddNoteMutation = (
  options?: UseMutationOptions
) => {
  const { showFlashMessage } = useFlashMessages();
  const queryClient = useQueryClient();

  return useMutation(detectAiAnswerAndAddToNote, {
    mutationKey: CANDIDATE_NOTES_QUERY_KEY,
    onSuccess: async (candidateNote, { candidateId }) => {
      queryClient.invalidateQueries([...CANDIDATE_QUERY_KEY, candidateId]);
      showFlashMessage({
        type: 'detect_ai_answer_and_add_note',
        meta: { note: candidateNote.note },
      });
    },
    ...(options as any),
  });
};

export const useEvaluateAiAnswerAndAddNoteMutation = (
  options?: UseMutationOptions
) => {
  const { showFlashMessage } = useFlashMessages();
  const queryClient = useQueryClient();

  return useMutation(evaluateAiAnswerAndAddToNote, {
    mutationKey: CANDIDATE_NOTES_QUERY_KEY,
    onSuccess: async (candidateNote, { candidateId }) => {
      queryClient.invalidateQueries([...CANDIDATE_QUERY_KEY, candidateId]);

      showFlashMessage({
        type: 'evaluate_ai_answer_and_add_note',
        meta: { note: candidateNote.note },
      });
    },
    ...(options as any),
  });
};
