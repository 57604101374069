import React from 'react';

export const UnlockCandidatesUpsellIcon = props => (
  <svg
    width="130"
    height="78"
    viewBox="0 0 130 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M64.9999 0C29.1015 0 0 29.1614 0 65.1337H130C130 29.1614 100.898 0 64.9999 0Z"
      fill="#FFC4BC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.0001 77.7507C100.899 77.7507 130 72.1016 130 65.1332C130 58.1647 100.899 52.5156 65.0001 52.5156C29.1016 52.5156 0.00012207 58.1647 0.00012207 65.1332C0.00012207 72.1016 29.1016 77.7507 65.0001 77.7507Z"
      fill="#FF897A"
    />
    <rect
      width="41.5187"
      height="53.5519"
      rx="4.97608"
      transform="matrix(0.751858 0.659325 -0.657791 0.7532 66.7847 5.29297)"
      fill="white"
    />
    <path
      d="M61.3943 38.2523V33.843C61.3943 31.9829 62.8133 30.3654 64.6962 30.2307C66.7429 30.0959 68.4621 31.7133 68.4621 33.7082V34.3552C68.4621 34.7056 68.7349 34.9752 69.0897 34.9752H71.3546C71.7094 34.9752 71.9823 34.7056 71.9823 34.3552V33.9239C71.9823 30.1767 69.0624 26.9418 65.2693 26.7531C61.2033 26.5644 57.8468 29.7454 57.8468 33.7082V38.2523H61.3943Z"
      fill="#2C1338"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.7658 37.9424H55.9921C55.3099 37.9424 54.7368 38.4816 54.7368 39.1825V51.0439C54.7368 51.7178 55.2826 52.2839 55.9921 52.2839H73.6478C74.33 52.2839 74.9031 51.7448 74.9031 51.0439V39.1825C74.9303 38.4816 74.3846 37.9424 73.7024 37.9424H61.3134H57.7658ZM65.7887 48.5907C66.198 48.5907 66.4982 48.2133 66.389 47.8089L65.816 45.5984C66.4982 45.2479 66.9894 44.5201 66.9894 43.7114C66.9894 42.5522 66.0343 41.6087 64.8609 41.6087C63.6875 41.6087 62.7324 42.5522 62.7324 43.7114C62.7324 44.547 63.2236 45.2479 63.9058 45.5984L63.3327 47.8089C63.2236 48.2133 63.5237 48.5907 63.933 48.5907H65.7887Z"
      fill="#2C1338"
    />
  </svg>
);
