import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { DSButtonGroup, DSContentBlock, DSGrid } from '@hundred5/design-system';

import { ConfirmModal } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { GmailIcon } from '@/features/integrations';
import { useWorkspacePermission } from '@/features/permissions';
import { useWorkspaceId } from '@/hooks/router';

import { useUpdateWorkspaceMutation, useWorkspaceQuery } from '../../queries';
import {
  WorkspaceSettingsIntegrationAction,
  WorkspaceSettingsIntegrationContainer,
  WorkspaceSettingsIntegrationDescription,
  WorkspaceSettingsIntegrationIcon,
  WorkspaceSettingsIntegrationTitle,
} from '../workspace-settings-integrations-page';

export const WorkspaceSettingsIntegrationEmail = () => {
  return (
    <DSContentBlock>
      <DSContentBlock.Title>Email Integration</DSContentBlock.Title>
      <DSContentBlock.Content>
        <DSGrid gap="16px">
          <GmailElement />
        </DSGrid>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const GmailElement = () => {
  const workspaceId = useWorkspaceId();
  const { showFlashMessage } = useFlashMessages();
  const { data: workspace } = useWorkspaceQuery({ workspaceId: workspaceId });
  const updateWorkspaceMutation = useUpdateWorkspaceMutation({
    onSuccess: () =>
      showFlashMessage({
        type: 'saved',
      }),
  });
  const checkIfYouCanActivateEmail = useWorkspacePermission()(
    'gmail integration',
    'update'
  );

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [activateEmail, setActivateEmail] = useState(
    workspace?.gmailEnabled ? 'on' : 'off'
  );

  const updateWorkspace = useCallback(
    async activeteEmail => {
      await updateWorkspaceMutation.mutateAsync({
        workspaceId: workspaceId,
        workspace: {
          ...workspace!,
          gmailEnabled: activeteEmail,
        },
      });
    },
    [updateWorkspaceMutation, workspace, workspaceId]
  );

  return (
    <>
      <WorkspaceSettingsIntegrationContainer>
        <WorkspaceSettingsIntegrationIcon>
          <IconWrapper>
            <GmailIcon />
          </IconWrapper>
        </WorkspaceSettingsIntegrationIcon>
        <IntegrationInfo>
          <WorkspaceSettingsIntegrationTitle>
            Gmail integration
          </WorkspaceSettingsIntegrationTitle>
          <WorkspaceSettingsIntegrationDescription>
            Connect to Gmail to send and receive emails in Toggl Hire. View all
            communication with a candidate in one place.
          </WorkspaceSettingsIntegrationDescription>
        </IntegrationInfo>
        <WorkspaceSettingsIntegrationAction
          data-rh={
            !checkIfYouCanActivateEmail
              ? 'Ask your workspace Admin to enable the integration'
              : null
          }
          data-rh-at={!checkIfYouCanActivateEmail ? 'top' : null}
        >
          <DSButtonGroup
            variant="primary"
            disabled={!checkIfYouCanActivateEmail}
            items={[
              { id: 'off', label: 'Off' },
              { id: 'on', label: 'On' },
            ]}
            selectedId={activateEmail}
            onChange={() => {
              if (activateEmail === 'on') {
                setOpenConfirmModal(true);
              } else {
                setActivateEmail(activateEmail === 'off' ? 'on' : 'off');
                updateWorkspace(true);
              }
            }}
          />
        </WorkspaceSettingsIntegrationAction>
      </WorkspaceSettingsIntegrationContainer>
      {activateEmail === 'on' && (
        <EmailInfo>
          All team members can now connect their email accounts and sync their
          communication with the candidates.
        </EmailInfo>
      )}

      <ConfirmModal
        open={openConfirmModal}
        action="Remove integration"
        onConfirm={() => {
          updateWorkspace(false);
          setActivateEmail('off');
          setOpenConfirmModal(false);
        }}
        onClose={() => setOpenConfirmModal(false)}
        title="Disconnect Gmail integration"
        footerVariant="destructive-primary"
      >
        <p>Are you sure you want to remove your integration with Gmail?</p>
        <p>
          This will remove email syncing for <strong>all members</strong> of
          your team. You will no longer see candidate communication in Toggl
          Hire.
        </p>
      </ConfirmModal>
    </>
  );
};

const IntegrationInfo = styled.div``;

const EmailInfo = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.purple[100]};
  background-color: ${props => props.theme.colors.purple[5]};
  padding: 12px;
  border-radius: 8px;
  width: calc(100% - 120px);
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.purple[10]};
`;
