import { useJobOpeningTagsQuery } from '@/features/job-opening/queries';
import { useWorkspaceTagsQuery } from '@/features/workspace/queries';
import { useJobOpeningIdOrNull } from '@/hooks/router';

/**
 * Returns the tags for the current job opening if any,
 * or for the workspace if any,
 * or an empty array.
 */
export const useTagsQuery = () => {
  const jobOpeningId = useJobOpeningIdOrNull();

  const jobOpeningTagsQuery = useJobOpeningTagsQuery(jobOpeningId!, {
    enabled: jobOpeningId !== null,
  });

  const workspaceTagsQuery = useWorkspaceTagsQuery({
    enabled: jobOpeningId === null,
  });

  if (jobOpeningTagsQuery.isFetching || workspaceTagsQuery.isFetching) {
    return [];
  }
  return jobOpeningTagsQuery.data || workspaceTagsQuery.data || [];
};
