import { IPipelineCandidate } from '../types';

export function flattenInfinitePipelineCandidatesData(
  pages?: { candidates: IPipelineCandidate[]; total: number }[]
) {
  const candidates =
    pages?.reduce((accumulatedCandidates, page) => {
      return [...accumulatedCandidates, ...page.candidates];
    }, []) ?? [];

  const total =
    pages?.reduce((total, page) => {
      if (page.total > total) {
        return page.total;
      }

      return total;
    }, 0) ?? 0;

  return { candidates, total };
}
