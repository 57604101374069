import React, { useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ICandidateTag } from '@/features/candidate';
import { Autocomplete, Icon, isTagRestricted } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { useWorkspaceId } from '@/hooks/router';

interface AddTagProps {
  suggestions: ICandidateTag[] | undefined;
  tagsExist: boolean;
  onAdd: (name: string) => void;
}

export function AddTag(props: AddTagProps) {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState('');
  const { showFlashMessage } = useFlashMessages();
  const workspaceId = useWorkspaceId();

  const handleActivate = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }

    setValue('');
    setIsActive(!isActive);

    amplitude?.logEvent('added tag', {
      'workspace id': workspaceId,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setValue(event.target.value);
    }
  };

  const handleClickOutside = () => {
    setIsActive(false);
  };

  const addTag = (tagValue?: string) => {
    const cleanValue = tagValue ? tagValue.trim() : value.trim();

    if (cleanValue) {
      if (cleanValue.length > 20) {
        showFlashMessage({
          type: 'tag_length_error',
          meta: { maxLength: 20 },
        });
      } else if (isTagRestricted(cleanValue)) {
        showFlashMessage({
          type: 'restricted_tag_error',
          meta: {
            tag: cleanValue,
          },
        });
      } else {
        props.onAdd(cleanValue);
      }
    }

    setIsActive(false);
    setValue('');
  };

  const suggestionsWithRestrictedItems = props.suggestions?.map(tag => ({
    id: tag.id,
    value: tag.name,
    locked: isTagRestricted(tag.name.trim()),
  }));

  return (
    <>
      <AddTagContainer>
        {isActive && suggestionsWithRestrictedItems && (
          <Autocomplete
            suggestions={suggestionsWithRestrictedItems}
            onSuggestionSelect={addTag}
            isActive={isActive}
            value={value}
            onChange={handleInputChange}
            onClickOutside={handleClickOutside}
            maxInputLength={20}
          />
        )}

        <AddTagButton
          isActive={isActive}
          hideTitle={props.tagsExist}
          onClick={handleActivate}
        >
          <Icon icon={regular('plus')} />
          <AddTagButtonLabel>Add Tag</AddTagButtonLabel>
        </AddTagButton>
      </AddTagContainer>
    </>
  );
}

//region Styles
const AddTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
`;

const AddTagButton = styled.button<{ isActive: boolean; hideTitle: boolean }>`
  font-weight: 500;
  font-size: 12px;
  padding: 3px 12px;
  background: #ffffff;
  border: 1px solid #aba0af;
  border-radius: 8px;
  min-height: 24px;
  height: 24px;
  cursor: pointer;
  transition: background-color 0.1s;

  :hover {
    background: ${props => props.theme.hover.onWhite};
  }

  ${props =>
    props.hideTitle === true &&
    `
      padding-left: 6px;
      padding-right: 6px;

      ${AddTagButtonLabel} {
        display: none;
      }
  `}

  ${props =>
    props.isActive &&
    css`
      display: none;
    `}
`;

const AddTagButtonLabel = styled.span`
  display: inline-block;
  margin-left: 5px;
`;
//endregion
