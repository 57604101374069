import React from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';

import configuration from '../../configuration/runtime';
import { useWorkspaceId } from '../../hooks/router';

interface Props {
  children: React.ReactNode;
}

const WorkspaceSplit: React.FC<Props> = props => {
  const workspaceId = useWorkspaceId();

  const splitConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: configuration.splitApiKey || 'localhost',
      trafficType: 'workspace',
      key: workspaceId,
    },
    startup: {
      readyTimeout: 1,
    },
    storage: {
      type: 'LOCALSTORAGE',
    },
  };

  return (
    <SplitFactory config={splitConfig} updateOnSdkReady updateOnSdkTimedout>
      {attrs =>
        attrs.isReady || attrs.isTimedout ? <>{props.children}</> : null
      }
    </SplitFactory>
  );
};

export default WorkspaceSplit;
