import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import {
  createJobOpeningApplicationFormQuestion,
  deleteJobOpeningApplicationFormQuestion,
  fetchJobOpeningApplicationForm,
  TJobOpeningApplicationFormQuestionEdit,
  TJobOpeningApplicationFormFields,
  updateJobOpeningApplicationForm,
  updateJobOpeningApplicationFormQuestion,
} from '@/features/job-opening';
import { useJobOpeningId } from '@/hooks/router';

export const JOB_OPENING_APPLICATION_FORM_QUERY_KEY = [
  'job-opening',
  'application-form',
];

export const useJobOpeningApplicationFormQuery = (
  options?: UseQueryOptions
) => {
  const jobOpeningId = useJobOpeningId();

  return useQuery(
    [...JOB_OPENING_APPLICATION_FORM_QUERY_KEY, jobOpeningId],
    () => fetchJobOpeningApplicationForm({ jobOpeningId }),
    options as any
  );
};

export const useUpdateJobOpeningApplicationForm = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const jobOpeningId = useJobOpeningId();

  return useMutation(
    ({
      applicationForm,
    }: {
      applicationForm: TJobOpeningApplicationFormFields;
    }) =>
      updateJobOpeningApplicationForm({
        jobOpeningId,
        applicationForm,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ...JOB_OPENING_APPLICATION_FORM_QUERY_KEY,
          jobOpeningId,
        ]);
      },
      ...(options as any),
    }
  );
};

export const useCreateJobOpeningApplicationFormQuestionMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const jobOpeningId = useJobOpeningId();

  return useMutation(
    ({ question }: { question: TJobOpeningApplicationFormQuestionEdit }) =>
      createJobOpeningApplicationFormQuestion({
        jobOpeningId,
        question,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ...JOB_OPENING_APPLICATION_FORM_QUERY_KEY,
          jobOpeningId,
        ]);
      },
      ...(options as any),
    }
  );
};

export const useUpdateJobOpeningApplicationFormQuestionMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const jobOpeningId = useJobOpeningId();

  return useMutation(
    ({
      questionId,
      question,
    }: {
      questionId: TId;
      question: TJobOpeningApplicationFormQuestionEdit;
    }) =>
      updateJobOpeningApplicationFormQuestion({
        jobOpeningId,
        questionId,
        question,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ...JOB_OPENING_APPLICATION_FORM_QUERY_KEY,
          jobOpeningId,
        ]);
      },
      ...(options as any),
    }
  );
};

export const useDeleteJobOpeningApplicationFormQuestion = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const jobOpeningId = useJobOpeningId();

  return useMutation(
    ({ questionId }: { questionId: TId }) =>
      deleteJobOpeningApplicationFormQuestion({
        jobOpeningId,
        questionId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          ...JOB_OPENING_APPLICATION_FORM_QUERY_KEY,
          jobOpeningId,
        ]);
      },
      ...(options as any),
    }
  );
};
