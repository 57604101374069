import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '../icon/icon';

interface UnlockBannerProps {
  header: React.ReactNode;
  text: React.ReactNode;
  onUnlock: () => void;
  unlockLabel?: string;
  disabled?: boolean;
}

export function UnlockBanner({
  header,
  text,
  unlockLabel = 'Unlock ›',
  disabled,
  onUnlock,
}: UnlockBannerProps) {
  return (
    <UnlockBannerContainer>
      <PadlockContainer>
        <Padlock icon={solid('unlock-keyhole')} color="yellow-100" size="3x" />
      </PadlockContainer>

      <Content>
        <Header>{header}</Header>
        <div>{text}</div>
      </Content>

      <Button onClick={onUnlock} disabled={disabled}>
        {unlockLabel}
      </Button>
    </UnlockBannerContainer>
  );
}

const UnlockBannerContainer = styled.div`
  background: ${props => props.theme.colors.yellow[100]};
  display: flex;
  align-items: center;
  padding: 30px 40px;
  color: ${props => props.theme.colors.github};

  @media all and (max-width: 992px) {
    flex-direction: column;
  }
`;

const PadlockContainer = styled.div`
  min-width: 97px;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.github};
  border-radius: 50%;
  margin-right: 37px;

  @media all and (max-width: 1200px) {
    display: none;
  }
`;

const Padlock = styled(Icon)`
  rotate: -15deg;
`;

const Content = styled.div`
  margin-right: 27px;

  @media all and (max-width: 992px) {
    text-align: center;
    margin-right: 0;
  }
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;

  @media all and (max-width: 992px) {
    margin-bottom: 5px;
  }
`;

const Button = styled.button`
  -webkit-appearance: none;
  border: none;
  border-radius: 3px;
  padding: 0;
  background: ${props => props.theme.colors.github};
  color: ${props => props.theme.colors.white};
  font-size: 12px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  min-width: 158px;
  height: 39px;
  margin-left: auto;

  @media all and (max-width: 992px) {
    margin-left: 0;
    margin-top: 15px;
  }
`;
