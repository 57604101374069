import api from '@/api/api';
import { TId } from '@/features/common';
import { IAPIKey } from '@/features/integrations';
import { parseAPIData } from '@/utils/parsers';

export async function fetchApiKeys(params: {
  workspaceId: TId;
}): Promise<IAPIKey[]> {
  const response = await api.get(`/workspaces/${params.workspaceId}/api-keys`);
  return parseAPIData<IAPIKey[]>(response.data.api_keys) || [];
}

export async function generateApiKey(params: {
  workspaceId: TId;
  name?: string;
}): Promise<IAPIKey> {
  const response = await api.post(
    `/workspaces/${params.workspaceId}/api-keys`,
    {
      name: params.name,
    }
  );

  return parseAPIData(response.data);
}

export async function updateApiKey(params: {
  apiKeyId: TId;
  name: string;
}): Promise<IAPIKey> {
  const response = await api.put(`/api-keys/${params.apiKeyId}`, {
    name: params.name,
  });

  return parseAPIData(response.data);
}

export async function deleteApiKey(params: { apiKeyId: TId }): Promise<void> {
  await api.delete(`/api-keys/${params.apiKeyId}`);
}
