import React, { useCallback } from 'react';

import { ConfirmModal, TId } from '@/features/common';

interface DeleteQuestionModalProps {
  questionId?: TId;
  open: boolean;
  onClose: () => void;
  onDelete: (questionId: TId) => void;
}

export function DeleteQuestionModal({
  questionId,
  open,
  onClose,
  onDelete,
}: DeleteQuestionModalProps) {
  const handleConfirm = useCallback(() => {
    if (!questionId) {
      return;
    }

    onDelete(questionId);
  }, [questionId, onDelete]);

  return (
    <ConfirmModal
      open={open}
      action="Delete"
      onClose={onClose}
      onConfirm={handleConfirm}
    >
      <h3>Are you sure?</h3>
      <p>
        This application question is going to be deleted permanently with no
        backups. Therefore be sure you really wish to do that.
      </p>
    </ConfirmModal>
  );
}
