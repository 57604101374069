import React from 'react';

import { CardForm, CardFormLayout } from '@/features/billing';
import { Alert } from '@/features/common';
import { useWorkspaceId } from '@/hooks/router';

import './BillingInfoForm.css';

const BillingInfoForm = (props: { onFinish: () => void; formRef: any }) => {
  const workspaceId = useWorkspaceId();

  return (
    <CardForm
      workspaceId={workspaceId}
      onFinish={props.onFinish}
      formRef={props.formRef}
      renderCardForm={cardForm => (
        <div
          className="purchase-access-billing-info"
          data-recording-ignore="mask"
        >
          {cardForm.errorMessage ? (
            <div className="purchase-access-billing-info__error">
              <Alert type="error">{cardForm.errorMessage}</Alert>
            </div>
          ) : null}

          <CardFormLayout
            fields={cardForm.fields}
            recurlyHostedPage={cardForm.recurlyHostedPage}
            recurlyLoadFailed={cardForm.recurlyLoadFailed}
          />
        </div>
      )}
      renderChallenge={challenge => challenge}
    />
  );
};

export default BillingInfoForm;
