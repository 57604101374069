import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';
import {
  createInterview,
  deleteInterview,
  fetchInterview,
  updateInterview,
} from '@/features/interview';
import { PIPELINE_CATEGORIES_KEY } from '@/features/pipeline';

export const INTERVIEW_QUERY_KEY = ['interview'];

export const useInterviewQuery = (
  params: {
    interviewId: TId;
  },
  options?: UseQueryOptions
) => {
  return useQuery(
    [...INTERVIEW_QUERY_KEY, params.interviewId],
    () => fetchInterview({ interviewId: params.interviewId }),
    options as any
  );
};

export const useCreateInterviewMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(createInterview, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...PIPELINE_CATEGORIES_KEY,
        params.jobOpeningId,
      ]);
    },
    ...(options as any),
  });
};

export const useUpdateInterviewMutation = (
  params: {
    interviewId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(updateInterview, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...INTERVIEW_QUERY_KEY,
        params.interviewId,
      ]);
    },
    ...(options as any),
  });
};

export const useDeleteInterviewMutation = (
  params: {
    jobOpeningId: TId;
  },
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(deleteInterview, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        ...PIPELINE_CATEGORIES_KEY,
        params.jobOpeningId,
      ]);
    },
    ...(options as any),
  });
};
