import React from 'react';
import styled from '@emotion/styled';

import { IRecommendedCandidate } from '@/features/job-opening';

import { Ellipsis } from './ellipsis';
import { Tag } from './tag';
import { TagsList } from './tags-list';

const SKILLS_CHAR_LIMIT = 24;

type SkillsProps = Pick<IRecommendedCandidate, 'skills'>;

export function Skills({ skills }: SkillsProps) {
  const skillsToDisplay =
    skills?.reduce((acc, skill) => {
      const newCharsLength = [...acc, skill].map(skill => skill.skill).join('')
        .length;

      if (newCharsLength < SKILLS_CHAR_LIMIT) {
        return [...acc, skill];
      }

      return acc;
    }, [] as typeof skills) ?? [];

  const displayEllipsis =
    skills?.length && skills?.length > skillsToDisplay.length;

  return (
    <SkillsTagsList>
      {skillsToDisplay.map(skill => (
        <Tag key={skill.id}>
          <span>{skill.skill}</span>
        </Tag>
      ))}
      {displayEllipsis ? <Ellipsis /> : null}
    </SkillsTagsList>
  );
}

const SkillsTagsList = styled(TagsList)`
  grid-area: tags;
`;
