import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import produce from 'immer';

import { CANDIDATE_QUERY_KEY, ICandidate } from '@/features/candidate';
import { evaluateCandidateAnswer } from '@/features/candidate/api/candidate-answers';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline/queries/pipeline-candidates';
import { WORKSPACE_STATS_QUERY_KEY } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

export const useEvaluateCandidateAnswerMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(evaluateCandidateAnswer, {
    onSuccess: (data, variables) => {
      // Expensive rerender, do manual update
      queryClient.setQueryData(
        [...CANDIDATE_QUERY_KEY, variables.candidateId],
        (oldCandidate: ICandidate) =>
          produce(oldCandidate, newCandidate => {
            const test = newCandidate.tests.find(
              test => test.testId === variables.testId
            );

            if (test) {
              test.percent = data.percent;
              test.points = data.points;
              test.isEvaluated = data.isEvaluated;

              const answer = test.answers?.find(
                answer => answer.id === variables.answerId
              );

              if (answer) {
                answer.scoreAsPercents = data.scoreAsPercents;
                answer.scoreAsPoints = data.scoreAsPoints;
              }
            }
          })
      );

      queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);

      queryClient.invalidateQueries([
        ...WORKSPACE_STATS_QUERY_KEY,
        workspaceId,
      ]);
    },
    ...(options as any),
  });
};
