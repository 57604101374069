import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';

import { useEnableGmailMutation } from '@/features/me';

export function EmailDisconnectedBanner() {
  // We don't need to call the useEnableGmailCallbackMutation, since after auth the user will be rediretected to the profile page
  const enableGmailMeMutation = useEnableGmailMutation({
    onSuccess: (result: string) => result && (window.location.href = result),
  });

  return (
    <ContentDisconnectedWrapper>
      <span>
        Gmail token expired. We are unable to show any new email communication
        between you and the candidates.
      </span>
      <DSButton
        variant="tertiary"
        size="xsmall"
        onClick={() => enableGmailMeMutation.mutate()}
      >
        Reconnect
      </DSButton>
    </ContentDisconnectedWrapper>
  );
}

const ContentDisconnectedWrapper = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-height: 36px;
  gap: 12px;
  background: ${({ theme }) => theme.colors.purple[5]};
`;
