import api from '@/api/api';
import { ICandidateNote } from '@/features/candidate';
import { TId } from '@/features/common';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

import { ICandidateInterviewNotesResponse } from '../types/candidate-interview-notes';

export async function fetchCandidateInterviewNotes(params: {
  candidateId: TId;
  interviewId: TId;
}): Promise<ICandidateInterviewNotesResponse> {
  const response = await api.get(
    `/test-takers/${params.candidateId}/interviews/${params.interviewId}/notes`
  );
  const data = parseAPIData<ICandidateInterviewNotesResponse>(response.data);

  // Check if read
  if (data.notes !== null) {
    data.notes.forEach(note => {
      if (data.readNotesAt === null || data.readNotesAt < note.createdAt) {
        note.unread = true;
        data.hasUnread = true;
      }
    });
  }

  return data;
}

export async function createCandidateInterviewNote(params: {
  interviewId: TId;
  candidateId: TId;
  content: string;
  userIds: TId[];
}): Promise<ICandidateNote> {
  const response = await api.post(
    `/test-takers/${params.candidateId}/interviews/${params.interviewId}/notes`,
    serialiseAPIData({
      content: params.content,
      userIds: params.userIds,
    })
  );

  return parseAPIData(response.data);
}

export async function deleteCandidateInterviewNote(params: {
  id: TId;
}): Promise<void> {
  await api.delete(`/interviews/notes/${params.id}`);
}

export async function updateCandidateInterviewNote(params: {
  id: TId;
  content: string;
  userIds: TId[];
}): Promise<ICandidateNote> {
  const response = await api.put(
    `/interviews/notes/${params.id}`,
    serialiseAPIData({
      content: params.content,
      userIds: params.userIds,
    })
  );
  return parseAPIData(response.data);
}
