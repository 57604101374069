import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import CollapseTransition from '@/components/transition/collapse/CollapseTransition';
import { Icon } from '@/features/common';

import {
  Actions,
  Details,
  DetailsContent,
  Marker,
  Subtitle,
  Title,
} from './ui';

interface TimelinePointProps {
  icon?: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  actions?: React.ReactNode;
  details?: React.ReactNode;
  toggleDetails?: boolean;
  first?: boolean;
  last?: boolean;
}

export function TimelinePoint(props: TimelinePointProps) {
  const [showDetails, setShowDetails] = useState(!props.toggleDetails);

  return (
    <TimelinePointGrid>
      <Marker icon={props.icon} first={props.first} last={props.last} />
      <Title>{props.title}</Title>
      <Subtitle>{props.subtitle}</Subtitle>

      {(props.actions || props.toggleDetails) && (
        <Actions>
          {props.actions}
          {props.toggleDetails && (
            <DSButton
              size="small"
              variant="secondary"
              onClick={() => setShowDetails(!showDetails)}
            >
              <Icon
                icon={showDetails ? regular('eye-slash') : regular('eye')}
              />
              {showDetails ? 'Hide' : 'View'}
            </DSButton>
          )}
        </Actions>
      )}

      {props.details && (
        <Details>
          <CollapseTransition in={showDetails}>
            <DetailsContent>{props.details}</DetailsContent>
          </CollapseTransition>
        </Details>
      )}
    </TimelinePointGrid>
  );
}

const TimelinePointGrid = styled.li`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-areas:
    'marker title'
    'marker subtitle';
  grid-column-gap: 12px;

  &:has(${Details}) {
    grid-template-areas:
      'marker title'
      'marker subtitle'
      'marker details';
  }

  &:has(${Actions}) {
    grid-template-columns: max-content 1fr max-content;
    grid-template-areas:
      'marker title    actions'
      'marker subtitle actions';
  }

  &:has(${Details}):has(${Actions}) {
    grid-template-columns: max-content 1fr max-content;
    grid-template-areas:
      'marker title    actions'
      'marker subtitle actions'
      'marker details  details';
  }
`;
