import React from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';

import {
  SelectAllButton,
  UnselectAllButton,
} from '@/features/candidate-search/components';
import {
  FieldWrapper,
  HoverableFieldWrapper,
} from '@/features/candidate-search/components/candidate-filters/ui/field-wrapper';
import { FilterName } from '@/features/candidate-search/components/candidate-filters/ui/filter-name';
import { FormFields } from '@/features/candidate-search/components/candidate-filters/ui/form-fields';
import { FormikCheckboxField } from '@/features/common';

import { COUNTRY_FILTER_TYPE } from '../country-filter';

interface TagsOptionsProps {
  countries: string[];
  query?: string;
}

export const CountriesOptions = ({ countries, query }: TagsOptionsProps) => {
  if (isEmpty(countries) && !query) return null;

  if (isEmpty(countries) && query)
    return <NoResults>No matching items</NoResults>;

  return (
    <>
      <SelectionFieldWrapper>
        <FullWidthFilterName>Countries</FullWidthFilterName>
        <SelectAllButton
          name={COUNTRY_FILTER_TYPE}
          values={['0', ...countries]}
        />
        <UnselectAllButton name={COUNTRY_FILTER_TYPE} />
      </SelectionFieldWrapper>
      <TagsContainer>
        <TagsWrapper>
          <FormFields>
            {countries.map(country => (
              <HoverableFieldWrapper key={country}>
                <FormikCheckboxField
                  key={country}
                  name={COUNTRY_FILTER_TYPE}
                  value={country}
                  label={country}
                />
              </HoverableFieldWrapper>
            ))}
          </FormFields>
        </TagsWrapper>
      </TagsContainer>
    </>
  );
};

//region styles
const TagsContainer = styled.div`
  position: relative;
`;

const TagsWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
  gap: 8px;
`;

const FullWidthFilterName = styled(FilterName)`
  flex: 1;
  padding-left: 0;
  padding-right: 0;
`;

const SelectionFieldWrapper = styled(FieldWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const NoResults = styled.p`
  color: ${props => props.theme.colors.purple[60]};
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
`;
//endregion
