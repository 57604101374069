import styled from '@emotion/styled';

import { pluralize } from '@/features/common';

import { UnitInputWrapper } from './ui';

export const MinutesInput = styled(UnitInputWrapper)<{
  duration: number;
  timeUnit: string;
}>`
  & > div:first-of-type::after {
    content: ${props => `"${pluralize(+props.duration, props.timeUnit)}"`};
  }
  input {
    padding-right: 64px; // add space so the input does not override the time unit
  }
`;
