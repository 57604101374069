import React, { useRef, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, DSContextMenu } from '@hundred5/design-system';
import useOnClickOutside from 'use-onclickoutside';

import {
  useCandidateQuery,
  useUpdateCandidateMutation,
} from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { usePipelineCategoriesQuery } from '@/features/pipeline';
import { useJobOpeningId, useWorkspaceId } from '@/hooks/router';

export interface MoveToButtonProps {
  candidateId: TId;
}

export function MoveToButton(props: MoveToButtonProps) {
  const jobOpeningId = useJobOpeningId();
  const { data: candidate } = useCandidateQuery(props);
  const { data: pipelineCategories } = usePipelineCategoriesQuery({
    jobOpeningId,
  });
  const updateCandidateMutation = useUpdateCandidateMutation();
  const workspaceId = useWorkspaceId();

  const ref = useRef<null | HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useOnClickOutside(ref, () => setMenuOpen(false));

  return (
    <MoveToButtonContainer ref={ref}>
      <DSButton onClick={() => setMenuOpen(!menuOpen)}>
        <Icon icon={regular('chevron-right')} color="white" />
        Move to
      </DSButton>

      <DSContextMenu
        open={menuOpen}
        position={{ top: `${42 / 14}rem`, right: '0' }}
      >
        {pipelineCategories?.map(category => (
          <DSContextMenu.Item
            disabled={category.id === candidate?.categoryId}
            key={category.id}
            label={category.name}
            onClick={() => {
              updateCandidateMutation.mutate({
                candidateId: props.candidateId,
                attributes: { categoryId: category.id },
              });

              setMenuOpen(false);

              amplitude?.logEvent('candidate moved to other stage', {
                'workspace id': workspaceId,
              });
            }}
          />
        ))}
      </DSContextMenu>
    </MoveToButtonContainer>
  );
}

const MoveToButtonContainer = styled.div`
  position: relative;
`;
