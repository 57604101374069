import React from 'react';
import { DSTestResultPage } from '@hundred5/design-system';
import { useFormikContext } from 'formik';

import { FormikCheckboxField } from '@/features/common';
import { usePlanLimits } from '@/hooks/planLimits';

import { TResultPageSettingsForm } from '../result-page-settings-form.types';

export function Experience() {
  const canAccessFeature = usePlanLimits();
  const canDisableFeedback = canAccessFeature('disable_candidate_feedback');
  const form = useFormikContext<TResultPageSettingsForm>();

  return (
    <DSTestResultPage.DataSection title="Test experience">
      <FormikCheckboxField
        name="userExperience"
        label="Ask candidates about their test experience"
        data-test-result-page-settings-upsell-tooltip={
          canDisableFeedback
            ? null
            : 'Upgrade to Premium or Business to disable Test experience.'
        }
        data-test-result-page-settings-upsell-tooltip-at="bottom"
        onChange={e => {
          if (canDisableFeedback) {
            form.setFieldValue('userExperience', e.target.checked);
          }
        }}
      />
    </DSTestResultPage.DataSection>
  );
}
