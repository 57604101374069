import React, { useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSSearchInput } from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { isEmpty, pick } from 'lodash';

import { amplitude } from '@/features/amplitude';
import {
  TFilterType,
  useCandidateSearchParams,
} from '@/features/candidate-search';
import { callAll, Icon, Popover } from '@/features/common';
import { useTagsQuery } from '@/features/common/queries/tags';
import { useJobOpeningIdOrNull } from '@/hooks/router';

import { ApplyButton } from '../buttons';
import { FieldWrapper } from '../candidate-filters/ui/field-wrapper';
import {
  FullWidthPopover,
  IconWrapper,
  SubContent,
  Trigger,
} from '../candidate-filters/ui/popover';

import { TagsOptions } from './ui/tags-options';

export const TAGS_FILTER_TYPE: TFilterType = 'tags';

export const TagsFilter = ({ onApply }) => {
  const tags = useTagsQuery();
  const [searchQuery, setSearchQuery] = useState('');
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();
  const filteredTags = tags.filter(tag =>
    tag.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
  );
  const jobOpeningId = useJobOpeningIdOrNull();

  return (
    <FullWidthPopover>
      <Popover.Trigger>
        {({ onClick }) => (
          <Trigger onClick={onClick}>
            <IconWrapper>
              <Icon icon={regular('tag')} color="purple-60" />
            </IconWrapper>
            Tags
          </Trigger>
        )}
      </Popover.Trigger>
      <SubContent position="right">
        {({ close }) => (
          <>
            <Formik
              initialValues={pick(candidatesSearchParams, TAGS_FILTER_TYPE)}
              onSubmit={values => {
                if (isEmpty(values)) {
                  removeSearchParam(TAGS_FILTER_TYPE);
                } else {
                  addSearchParams(values);

                  amplitude?.logEvent('candidate filters/filter applied', {
                    'filter type': TAGS_FILTER_TYPE,
                    'filter value': values,
                    'filter origin': jobOpeningId
                      ? 'pipeline'
                      : 'all candidates',
                    'job opening id': jobOpeningId,
                  });
                }
                callAll(onApply, close);
              }}
            >
              <Form>
                <SearchWrapper>
                  <DSSearchInput
                    placeholder="Find tag..."
                    background={'gray'}
                    value={searchQuery}
                    onChange={event => setSearchQuery(event.target.value)}
                  />
                </SearchWrapper>
                <TagsOptions tags={filteredTags} query={searchQuery} />
                <ApplyButton />
              </Form>
            </Formik>
          </>
        )}
      </SubContent>
    </FullWidthPopover>
  );
};

//region Styles

const SearchWrapper = styled(FieldWrapper)`
  margin-bottom: 16px;
`;
//endregion
