import React from 'react';
import styled from '@emotion/styled';
import { DSColumn } from '@hundred5/design-system';

import ScrollToTopOnMount from '@/components/scroll_to_top_on_mount/ScrollToTopOnMount';
import { useDocumentTitle } from '@/features/common';
import { Loader } from '@/features/common/components/loader';
import { WorkspaceSettingsPageHeader } from '@/features/workspace';

export function WorkspaceSettingsPage({
  title,
  loading,
  children,
}: {
  title: string;
  loading?: boolean;
  children: React.ReactNode;
}) {
  useDocumentTitle(title);

  return (
    <>
      <ScrollToTopOnMount />

      <WorkspaceSettingsPageHeader />

      <Content>{loading ? <Loader /> : children}</Content>
    </>
  );
}

const Content = styled(DSColumn)`
  width: 100%;
  display: flex;
  padding: 24px;
  gap: 24px;
  margin-left: auto;
  margin-right: auto;
`;
