import { useEffect } from 'react';

import configuration from '@/configuration/runtime';

import { cleanGoogleRecaptcha, injectGoogleReCaptchaScript } from '.';

export function GoogleReCaptcha() {
  useEffect(() => {
    if (!configuration.recaptchaSiteKey) {
      throw new Error('<GoogleReCaptcha /> recaptcha key not provided');
    }

    const onLoad = () => {
      if (!window || !window.grecaptcha) {
        throw Error(`<GoogleRecaptcha /> Recaptcha script is not available`);
      }
    };

    injectGoogleReCaptchaScript({
      onLoad,
    });

    return () => {
      cleanGoogleRecaptcha();
    };
  }, []);

  return null;
}
