import React, { createContext, useCallback, useContext } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { reject, uniqueId } from 'lodash';

import { TId } from '@/features/common';
import { IFlashMessage, TFlashMessageContent } from '@/features/flash-messages';

interface FlashMessagesContextProps {
  flashMessages: IFlashMessage[];
  showFlashMessage: (flashMessage: TFlashMessageContent) => void;
  closeFlashMessage: (flashMessageId: TId) => void;
}

const FlashMessagesContext = createContext<FlashMessagesContextProps>({
  flashMessages: [],
  showFlashMessage: (_: TFlashMessageContent) => {},
  closeFlashMessage: (_: TId) => {},
});

interface FlashMessagesProviderProps {
  children: React.ReactNode;
}

export function FlashMessagesProvider({
  children,
}: FlashMessagesProviderProps) {
  const [flashMessages, setFlashMessages] = React.useState<IFlashMessage[]>([]);

  const showFlashMessage = useCallback((content: TFlashMessageContent) => {
    setFlashMessages(prevNotifications => [
      ...prevNotifications,
      { id: uniqueId('flash'), content },
    ]);
    amplitude?.logEvent('flash message/show', { content });
  }, []);

  const closeFlashMessage = useCallback((notificationId: TId) => {
    setFlashMessages(prevNotifications =>
      reject(
        prevNotifications,
        notification => notification.id === notificationId
      )
    );
  }, []);

  return (
    <FlashMessagesContext.Provider
      value={{
        flashMessages: flashMessages,
        showFlashMessage,
        closeFlashMessage,
      }}
    >
      {children}
    </FlashMessagesContext.Provider>
  );
}

export function useFlashMessages(): FlashMessagesContextProps {
  const context = useContext(FlashMessagesContext);
  if (context === undefined) {
    throw new Error(
      'useFlashMessages must be used within a FlashMessagesContextProvider'
    );
  }
  return context;
}
