import React from 'react';
import styled from '@emotion/styled';

import { TFilterType } from '@/features/candidate-search';
import { Popover } from '@/features/common';

import {
  ActionRequiredFilter,
  CountryFilter,
  DateFilter,
  FilterList,
  NotesFilter,
  ScoreFilter,
  StarsFilter,
  TagsFilter,
  StateFilter,
  FirstColumnFilter,
} from '../';
import { FiltersButton } from '../buttons';

interface FiltersSelectProps {
  hiddenFilters: TFilterType[];
}

export const CandidateFilters = ({
  hiddenFilters = [],
}: Partial<FiltersSelectProps>) => {
  return (
    <Wrapper>
      <FiltersSelect hiddenFilters={hiddenFilters} />
      <FilterList />
    </Wrapper>
  );
};

const FiltersSelect = ({ hiddenFilters }: FiltersSelectProps) => {
  return (
    <Popover>
      <Popover.Trigger>
        {({ isOpen, onClick }) => (
          <FiltersButton onClick={onClick} isOpen={isOpen} />
        )}
      </Popover.Trigger>
      <Popover.Content>
        {({ close }) => (
          <>
            {!hiddenFilters?.includes('actionRequired') ? (
              <>
                <Filter>
                  <ActionRequiredFilter />
                </Filter>
                <FilterSeparator />
              </>
            ) : null}
            {!hiddenFilters?.includes('firstColumn') ? (
              <>
                <Filter>
                  <FirstColumnFilter />
                </Filter>
                <FilterSeparator />
              </>
            ) : null}
            {!hiddenFilters?.includes('date') ? (
              <Filter>
                <DateFilter onApply={close} />
              </Filter>
            ) : null}
            {!hiddenFilters?.includes('country') ? (
              <Filter>
                <CountryFilter onApply={close} />
              </Filter>
            ) : null}
            {!hiddenFilters?.includes('score') ? (
              <Filter>
                <ScoreFilter onApply={close} />
              </Filter>
            ) : null}
            {!hiddenFilters?.includes('notes') ? (
              <Filter>
                <NotesFilter onApply={close} />
              </Filter>
            ) : null}
            {!hiddenFilters?.includes('tags') ? (
              <Filter>
                <TagsFilter onApply={close} />
              </Filter>
            ) : null}
            {!hiddenFilters?.includes('states') ? (
              <Filter>
                <StateFilter onApply={close} />
              </Filter>
            ) : null}
            {!hiddenFilters?.includes('stars') ? (
              <Filter>
                <StarsFilter onApply={close} />
              </Filter>
            ) : null}
          </>
        )}
      </Popover.Content>
    </Popover>
  );
};

const Wrapper = styled.div`
  display: flex;
  min-width: 0;
  flex: 1;
`;

const Filter = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex: 1;
  align-items: center;
  padding: 4px 12px;
  gap: 8px;
  position: relative;
  border-radius: 6px;

  &:hover {
    background: ${({ theme }) => theme.colors.purple[5]};
    cursor: pointer;
  }
`;

const FilterSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.purple[10]};
  height: 1px;
  margin: 4px 0;
  width: 100%;
`;
