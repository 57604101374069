import React, { useState } from 'react';
import styled from '@emotion/styled';

import { useCandidateHistoryQuery } from '@/features/candidate';
import { Pagination, TId, Timeline } from '@/features/common';

import { Item } from './ui';

//region Main
export interface CandidateHistoryProps {
  candidateId: TId;
}

export function CandidateHistory(props: CandidateHistoryProps) {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const { data: candidateHistory } = useCandidateHistoryQuery({
    candidateId: props.candidateId,
    limit: pagination.pageSize,
    offset: (pagination.page - 1) * pagination.pageSize,
  });

  const showPagination = (candidateHistory?.total ?? 0) > pagination.pageSize;

  return (
    <CandidateHistoryContainer>
      <Timeline>
        {candidateHistory?.items.map((item, index) => {
          const first = pagination.page === 1 && index === 0;
          const last =
            index + 1 === candidateHistory?.items.length &&
            Math.ceil(candidateHistory.total / pagination.pageSize) ===
              pagination.page;
          return <Item key={item.id} item={item} first={first} last={last} />;
        })}
      </Timeline>

      {showPagination ? (
        <PaginationContainer>
          <Pagination
            total={candidateHistory?.total}
            current={pagination.page}
            pageSize={pagination.pageSize}
            onChange={(newPage, newPageSize) => {
              setPagination({
                page: newPageSize !== pagination.pageSize ? 1 : newPage,
                pageSize: newPageSize,
              });
            }}
          />
        </PaginationContainer>
      ) : null}
    </CandidateHistoryContainer>
  );
}

//endregion

//region Styles
const CandidateHistoryContainer = styled.article`
  padding: 36px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 56px;
`;
//endregion
