import { TId } from '@/features/common';

export type TJobOpeningStatus = 'draft' | 'published' | 'closed';

export type TJobOpeningLoginMethod =
  | 'facebook'
  | 'github'
  | 'linkedin'
  | 'test'
  | 'email';

export interface IJobOpeningAttachment {
  name: string;
  isEnabled: boolean;
}

export type TJobOpeningReapplyAfterUnit = 'days' | 'months';
export type TJobOpeningVisibility = 'workspace' | 'invite-only';
export type TJobOpeningPipelineType = 'remote' | 'classic';

interface IPipelineStage {
  id: TId;
  name: string;
  order: number;
  testId: TId | null;
  interviewId: TId | null;
}

export interface IJobOpening {
  id: TId;
  name: string;
  contactEmail: string;
  slug: string;
  previewToken: string;
  workspaceId: TId;
  createdAt: Date;
  userUpdatedAt: Date;
  closedAt: Date | null;
  isDemo: boolean;
  isAttachmentsEnabled: boolean;
  locale: string | null;
  loginMethods: TJobOpeningLoginMethod[] | null;
  attachments: IJobOpeningAttachment[] | null;
  pausedAt: Date | null;
  visibility: TJobOpeningVisibility;
  reapplyAfter: number | null;
  reapplyAfterUnit: TJobOpeningReapplyAfterUnit | null;
  pipelineStages: IPipelineStage[];
  description: string;
  workplaceType: TWorkplaceType | null;
  /**
   * Job opening description page title image and its position from the center in percents
   */
  titleImageUrl: string | null;
  titleImagePosition: { x: number; y: number };
}

export interface IJobOpeningFeedback {
  reason: 'hired_from_h5' | 'hired_elsewhere' | 'other' | null;
  reasonComment: string | null;
  experience: 'positive' | 'negative' | null;
  experienceComment: string | null;
  useAgain: 'yes' | 'no' | null;
  useAgainComment: string | null;
}

export enum TWorkplaceType {
  Remote = 'remote',
  Hybrid = 'hybrid',
  Onsite = 'onsite',
}
