import { serialiseAPIData } from '@/utils/parsers';

import togglAccountsApi from './toggl-accounts-api';

export async function emailValidationRequest(params: {
  email: string;
}): Promise<void> {
  await togglAccountsApi.post(
    `/email_validation/hire/request`,
    serialiseAPIData({
      email: params.email,
    })
  );
}
