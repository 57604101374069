import * as React from 'react';

import { ConfirmModal } from '@/features/common';
import { useDeleteWorkspaceUserMutation } from '@/features/workspace';
import { useHistory } from '@/hooks/router';
import { Id } from '@/types/misc';

type Props = {
  workspaceId: Id;
  userId: Id;
  meId: Id;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const RemoveMemberModal = ({
  workspaceId,
  userId,
  meId,
  open,
  onClose,
  onConfirm,
}: Props) => {
  const deleteWorkspaceUserMutation = useDeleteWorkspaceUserMutation();
  const history = useHistory();

  return (
    <ConfirmModal
      open={open}
      action="Remove"
      disableActionButton={deleteWorkspaceUserMutation.isLoading}
      onConfirm={async () => {
        await deleteWorkspaceUserMutation.mutateAsync({ workspaceId, userId });
        if (meId === userId) {
          history.push('/admin');
        } else {
          onConfirm();
        }
      }}
      onClose={onClose}
    >
      <h3>Are you sure?</h3>
      <p>
        {meId === userId
          ? 'You will no longer be able to access this workspace, but you can be invited by others again.'
          : 'This person will no longer be able to access this workspace, but you can invite them again.'}
      </p>
    </ConfirmModal>
  );
};
