import styled from '@emotion/styled';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) {
    gap: 12px;
    padding: 12px 24px;
  }
`;
