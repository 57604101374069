import { camelizeKeys, decamelizeKeys } from 'humps';

import configuration from '../configuration/runtime';
import { Id } from '../types/misc';
import { Role } from '../types/role';
import { User, UserAttributes } from '../types/user';
import { Workspace, WorkspaceUser } from '../types/workspace';
import { getCompositeKey } from '../utils/keys';

import api from './api';
import { ApiUser, parseUser, serializeUser } from './users';
import { ApiWorkspace, parseWorkspace } from './workspaces';

type ApiUserWorkspace = ApiWorkspace & {
  role: Role;
  receivedOwnershipRequest: boolean;
  transferOwnershipRequestId: number;
};

type ApiMe = ApiUser & {
  workspaces: ApiUserWorkspace[];
  isEmailVerified: boolean | null;
  intercomUserHash: string;
  isSurveyCompleted: boolean | null;
};

export type FetchMeData = {
  user: User;
  workspaces: { [id: string]: Workspace };
  workspaceUsers: { [id: string]: WorkspaceUser };
  ownershipTransferRequests: { [id: string]: Id };
  supportMode: boolean;
  emailVerified: boolean | null;
  intercomUserHash: string;
  surveyCompleted: boolean | null;
};

export async function fetchMe(): Promise<FetchMeData> {
  const response = await api.get('/me');
  const data = camelizeKeys(response.data) as ApiMe;

  const user = parseUser(data);

  const workspaces: { [id: string]: Workspace } = {};
  const workspaceUsers: { [id: string]: WorkspaceUser } = {};
  const ownershipTransferRequests: { [id: string]: Id } = {};

  if (data.workspaces) {
    for (const item of data.workspaces) {
      const workspace = parseWorkspace(item);
      workspaces[workspace.id] = workspace;

      const key = getCompositeKey(workspace.id, user.id);

      workspaceUsers[key] = {
        workspace: workspace.id,
        user: user.id,
        role: item.role,
      };

      if (item.receivedOwnershipRequest) {
        ownershipTransferRequests[key] = String(
          item.transferOwnershipRequestId
        );
      }
    }
  }

  const supportMode = data.id === 0;

  return {
    user,
    workspaces,
    workspaceUsers,
    ownershipTransferRequests,
    supportMode,
    emailVerified: data.isEmailVerified,
    intercomUserHash: data.intercomUserHash,
    surveyCompleted: data.isSurveyCompleted,
  };
}

export async function updateMe(
  user: User,
  attributes: UserAttributes
): Promise<User> {
  const response = await api.put(
    '/me',
    decamelizeKeys(serializeUser(user, attributes))
  );

  const data = camelizeKeys(response.data) as ApiUser;

  return parseUser(data);
}

export async function deleteMe(): Promise<void> {
  await api.delete('me');
}

export async function exportMe(): Promise<string> {
  const response = await api.post('/me/takeout');
  const token = response.data.token;

  return `${configuration.apiUrl}/api/v1/me/takeout?token=${token}`;
}
