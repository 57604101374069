import React from 'react';
import styled from '@emotion/styled';
import { DSModal } from '@hundred5/design-system';

export interface BulkActionModalBaseProps {
  open: boolean;
  onClose: () => void;
  onActionDone?: () => void;
}

interface BulkActionModalProps extends BulkActionModalBaseProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const BulkActionModal = ({
  children,
  style,
  ...props
}: BulkActionModalProps) => {
  return (
    <DSModal
      contentStyle={{
        width: '644px',
        padding: 0,
        borderRadius: '8px',
        ...style,
      }}
      fluid
      {...props}
    >
      {/* despite DSModal being rendered inside react portal, it still propagates events to its React tree ancestors which in this case is draggable pipeline stage  
       https://github.com/facebook/react/issues/11387 */}
      <div onPointerDown={e => e.stopPropagation()}>{children}</div>
    </DSModal>
  );
};

//region Styles
const Header = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  padding: 36px 36px 16px 36px;
`;

const Content = styled.div<{ dark?: boolean }>`
  padding: 16px 36px;

  background: ${({ dark = false, theme }) =>
    dark ? theme.colors.purple[5] : theme.colors.white};
`;

const Footer = styled.div`
  padding: 36px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.theme.colors.purple[100]};
  margin: 0;
`;
//endregion

BulkActionModal.Header = Header;
BulkActionModal.Content = Content;
BulkActionModal.Footer = Footer;
BulkActionModal.Title = Title;
BulkActionModal.CloseButton = DSModal.CloseButton;
