import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import formatDate from 'date-fns/format';
import parse from 'date-fns/parse';
import markdownToTxt from 'markdown-to-txt';

import { amplitude } from '@/features/amplitude';
import { Badge, IBadgeProps } from '@/features/common/components/badge';
import {
  IInboxItem,
  TInboxItemType,
  useMarkInboxItemsSeenMutation,
} from '@/features/inbox';
import { useHistory, useWorkspaceId } from '@/hooks/router';

type TBadgeType = 'Update' | 'Email' | 'Note';

const messageTypeMap: Record<TInboxItemType, TBadgeType> = {
  candidate_note_mention: 'Note',
  interview_note_mention: 'Note',
  candidate_notification: 'Update',
  answer_note_mention: 'Note',
  new_skill: 'Update',
  new_template: 'Update',
};

const badgeColorMap: Record<TBadgeType, IBadgeProps['color']> = {
  Note: 'light-orange',
  Update: 'light-yellow',
  Email: 'gray',
};

export const MessageRow = (props: IInboxItem) => {
  const history = useHistory();
  const markSeen = useMarkInboxItemsSeenMutation();
  const workspaceId = useWorkspaceId();

  const handleOnClick = useCallback(() => {
    markSeen.mutate({ itemsIds: [props.id] });
    amplitude?.logEvent('my inbox/open notification', {
      'workspace id': workspaceId,
    });
    history.push(props.url);
  }, [markSeen, history, props.id, props.url, workspaceId]);

  return (
    <Row onClick={() => props.url && handleOnClick()} seen={!!props.seenAt}>
      <div>
        <TagBadge
          color={badgeColorMap[messageTypeMap[props.type]]}
          fontSize="12px"
        >
          {messageTypeMap[props.type]}
        </TagBadge>
      </div>
      <div>{props.author}</div>
      <MessageAndTitleColumn>
        <Title>{props.subject} </Title>
      </MessageAndTitleColumn>
      <MessageAndTitleColumn>
        {props.body && <Message>{markdownToTxt(props.body)}</Message>}
      </MessageAndTitleColumn>
      <DateColumn>
        <DateWrapper seen={!!props.seenAt}>
          {props.createdAt &&
          props.createdAt.getFullYear() === new Date().getFullYear()
            ? formatDate(parse(props.createdAt), 'MMM DD')
            : formatDate(parse(props.createdAt), 'DD/MM/YY')}
        </DateWrapper>
        {props.url && (
          <ActionButtonWrapper>
            <DSButton
              variant="secondary"
              size="xsmall"
              onClick={() => handleOnClick()}
            >
              Open candidate
            </DSButton>
          </ActionButtonWrapper>
        )}
      </DateColumn>
    </Row>
  );
};

const DateWrapper = styled.div<{ seen: boolean }>`
  color: ${props =>
    props.seen
      ? props.theme.typography.colorSecondary
      : props.theme.typography.colorPrimary};
`;
const ActionButtonWrapper = styled.div`
  display: none;
`;
const Row = styled.div<{ onClick?: () => void; seen: boolean }>`
  display: grid;
  grid-template-columns: 83px 180px auto 1fr auto;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  background: ${props => props.theme.colors.white};
  transition: background 0.1s;

  &:last-child {
    border-bottom: none;
  }
  font-weight: ${props => (props.seen ? '400' : '700')};
  font-size: 1rem;
  cursor: ${props => props.onClick && 'pointer'};

  color: ${props => props.theme.typography.colorPrimary};

  &:hover {
    background: ${props => props.onClick && props.theme.hover.onWhite};
  }

  &:hover {
    ${DateWrapper} {
      display: none;
    }
    ${ActionButtonWrapper} {
      display: initial;
    }
  }
`;

const MessageAndTitleColumn = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DateColumn = styled.div`
  font-size: 12px;
  color: ${props => props.theme.typography.colorSecondary};
`;

const Title = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

const Message = styled.span`
  color: ${props => props.theme.typography.colorSecondary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:before {
    content: ' - ';
  }
`;

const TagBadge = styled(Badge)`
  font-weight: 400;
  text-transform: capitalize;
`;
