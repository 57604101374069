import React from 'react';
import styled from '@emotion/styled';

import { useCountryList } from '@/features/common/hooks/country-list';
import { IRecommendedCandidate } from '@/features/job-opening';

import {
  Avatar,
  CandidateContainer,
  CandidateInformation,
  CandidateHeader,
  CloseButton,
  CandidateSeperator,
  InfromationWrapper,
  SocialNetwork,
  Fomo,
  SkillsList,
  Invitation,
} from './ui';

interface CandidateRecommendedCardProps {
  candidate: IRecommendedCandidate;
}

export function CandidateRecommendedCard({
  candidate,
}: CandidateRecommendedCardProps) {
  const { getCountryName } = useCountryList(true);

  return (
    <CandidateContainer>
      <CandidateHeader>
        <Avatar />
        <InfromationWrapper>
          <CandidateInformation
            name={`${candidate.firstName} ${candidate.lastName}`}
            country={candidate.countryCodes
              ?.map(code => getCountryName(code) ?? code)
              ?.join(', ')}
          />
          <SocialNetwork
            hasGithub={candidate.github}
            hasLinkedin={candidate.linkedin}
          />
        </InfromationWrapper>
        <CloseButton />
      </CandidateHeader>
      <FomoWrapper>
        <Fomo active />
        <Fomo state />
      </FomoWrapper>
      <CandidateSeperator />

      <SkillsList skills={candidate.skills ?? []} />
      <Invitation candidateId={candidate.id} />
    </CandidateContainer>
  );
}

const FomoWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-bottom: 24px;
`;
