import { useWorkspaceTreatments } from '@/components/splits';

export function useCustomTestCTA() {
  const { turn_all_tests_into_custom } = useWorkspaceTreatments([
    'turn_all_tests_into_custom',
  ]);
  const isTreatmentOn = turn_all_tests_into_custom.treatment === 'on';

  return {
    createCustomTestCTA: isTreatmentOn
      ? 'Create from scratch'
      : 'Create a custom test',
    showCustomBadge: !isTreatmentOn,
  };
}
