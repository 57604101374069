import * as React from 'react';
import ReactMarkdown, { ReactMarkdownProps } from 'react-markdown';

import MarkdownImage from './MarkdownImage';
import MarkdownLink from './MarkdownLink';

const Markdown = (props: ReactMarkdownProps) => (
  <ReactMarkdown
    {...props}
    parserOptions={{ commonmark: true }}
    escapeHtml={false}
    renderers={{
      Link: MarkdownLink,
      Image: MarkdownImage,
    }}
  />
);

export default Markdown;
