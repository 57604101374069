import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton, Field } from '@hundred5/design-system';
import formatDate from 'date-fns/format';

import Markdown from '@/components/markdown/Markdown';
import { getCurrentMentions } from '@/features/candidate';
import {
  useDeleteCandidateInterviewNoteMutation,
  useUpdateCandidateInterviewNoteMutation,
} from '@/features/candidate/queries/candidate-interview-notes';
import { ICandidateInterviewNote } from '@/features/candidate/types/candidate-interview-notes';
import { Icon } from '@/features/common';
import { AutocompleteTextArea } from '@/features/common/components/autocomplete-text-area/autocomplete-text-area';
import { useMeQuery } from '@/features/me';
import { useJobOpeningPermission } from '@/features/permissions';
import { WorkspaceUser } from '@/features/workspace';

//region Main
interface InterviewNoteProps {
  note: ICandidateInterviewNote;
}

export function InterviewNote({
  note: { id, content, userName, createdAt, unread },
}: InterviewNoteProps) {
  const [editing, setEditing] = useState(false);
  const [noteContent, setNoteContent] = useState(content);
  const [mentionedUsers, setMentionedUsers] = useState<WorkspaceUser[]>([]);
  const allowUpdating = !!useJobOpeningPermission()(
    'candidate notes',
    'update'
  );

  const { data: me } = useMeQuery();
  const updateNoteMutation = useUpdateCandidateInterviewNoteMutation();
  const deleteNoteMutation = useDeleteCandidateInterviewNoteMutation();

  const authoredByMe = me?.name === userName;

  return (
    <NoteContainer
      block={editing}
      unread={unread && !editing && !authoredByMe}
      data-recording-sensitive
    >
      {editing ? (
        <>
          <Field
            for="candidate-note-box-edit-note"
            input={
              <AutocompleteTextArea
                placeholder="Write stuff here..."
                value={noteContent}
                onChange={setNoteContent}
                onSelect={user => {
                  setMentionedUsers(prev => [...prev, user]);
                }}
              />
            }
          />

          <Buttons>
            <DSButton
              size="small"
              onClick={() => setEditing(false)}
              variant="secondary"
            >
              Cancel
            </DSButton>
            <DSButton
              size="small"
              variant="primary"
              disabled={updateNoteMutation.isLoading}
              onClick={async () => {
                await updateNoteMutation.mutateAsync({
                  id,
                  content: noteContent,
                  userIds: getCurrentMentions(noteContent, mentionedUsers),
                });

                setEditing(false);
                setMentionedUsers([]);
              }}
            >
              {updateNoteMutation.isLoading ? 'Updating...' : 'Update'}
            </DSButton>
          </Buttons>
        </>
      ) : (
        <>
          <NoteContent id={`note-id-${id}`}>
            {/* NOTE CONTENT */}
            <Header>
              <Text>
                {unread && !authoredByMe && <UnreadMark />}
                <div>
                  <Markdown source={content} />
                </div>
              </Text>
            </Header>
            <Footer unread={unread && !authoredByMe}>
              <PostDate>{formatDate(createdAt, 'MMM D, YYYY, HH:mm')}</PostDate>
              {userName && <Author>{userName}</Author>}
            </Footer>
          </NoteContent>

          {/* NOTE ACTION BUTTONS */}
          {allowUpdating && authoredByMe && (
            <NoteActions>
              <DSButton
                variant="secondary"
                size="xsmall"
                Icon={<Icon icon={regular('pen-to-square')} />}
                onClick={() => {
                  setEditing(true);
                }}
              />
              <DSButton
                variant="secondary"
                size="xsmall"
                Icon={<Icon icon={regular('trash')} />}
                disabled={deleteNoteMutation.isLoading}
                onClick={() => {
                  deleteNoteMutation.mutate({ id });
                }}
              />
            </NoteActions>
          )}
        </>
      )}
    </NoteContainer>
  );
}

//endregion

//region Styles
const NoteContainer = styled.article<{
  block?: boolean;
  unread: boolean | undefined;
}>`
  display: ${props => (props.block ? 'block' : 'flex')};
  align-items: center;
  margin: 20px 36px;
  padding-bottom: 11px;
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  background-color: ${props => props.theme.colors.white};
`;

const NoteContent = styled.div`
  flex-grow: 1;
`;
const NoteActions = styled.div`
  display: flex;
  gap: 8px;
`;

const Header = styled.header`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  line-height: 23px;
  margin-bottom: 8px;
`;

const Text = styled.div`
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-right: 40px;
  word-break: break-word;
  font-size: 14px;
  line-height: 150%;

  color: ${({ theme }) => theme.colors.purple[100]};

  p {
    margin: 0;
  }

  img {
    max-width: 100%;
  }
`;

const Buttons = styled.div`
  text-align: right;
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const Footer = styled.footer<{ unread?: boolean }>`
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.purple[60]};
  display: flex;
  align-items: center;

  ${props =>
    props.unread &&
    css`
      margin-left: 16px;
    `}
`;

const UnreadMark = styled.span`
  min-width: 6px;
  width: 6px;
  height: 6px;
  background: #eb5757;
  border-radius: 50%;

  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: auto;
`;

const PostDate = styled.span`
  margin-right: 5px;
`;

const Author = styled.span`
  &:before {
    display: inline-block;
    margin-right: 5px;
    content: '/';
  }
`;
//endregion
