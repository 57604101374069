import React from 'react';
import styled from '@emotion/styled';

export const GoogleRecaptchaPrivacyDisclaimer = () => {
  return (
    <Container>
      This app is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </Container>
  );
};

const Container = styled.div`
  color: ${props => props.theme.typography.colorSecondary};
  font-size: 10px;
  a {
    color: ${props => props.theme.typography.colorSecondary};
    text-decoration: underline;
  }
`;
