import { useMutation } from '@tanstack/react-query';

import { bulkAddTags } from '@/features/bulk-actions/api';
import { TId } from '@/features/common';

export const BULK_ADD_TAGS_MUTATION_KEY = ['bulk-add-tags'];

//region Mutations
export const useBulkAddTags = (params: {
  candidateIds: TId[];
  tagsIds: TId[];
  onSuccess?: () => void;
}) => {
  return useMutation(
    [...BULK_ADD_TAGS_MUTATION_KEY, params.candidateIds, params.tagsIds],
    () => bulkAddTags(params.candidateIds, params.tagsIds),
    { onSuccess: params.onSuccess }
  );
};
//endregion
