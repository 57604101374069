import styled from '@emotion/styled';

export const Count = styled.div`
  background: rgba(234, 231, 235, 0.5);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 700;
  margin-left: 8px;
  padding: 0 6px;
`;
