import React from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import { useFormikContext } from 'formik';

export const ApplyButton = () => {
  const { dirty } = useFormikContext();

  return (
    <Button type="submit" disabled={!dirty}>
      Apply filter
    </Button>
  );
};

const Button = styled(DSButton)`
  margin-top: 24px;
  width: 100%;
`;
