import React from 'react';

import {
  useBillingEmailQuery,
  useBillingInfoQuery,
  useUpdateBillingEmailMutation,
  useUpdateBillingInfoMutation,
} from '@/features/billing';
import { useWorkspacePermission } from '@/features/permissions';

import { createBillingInfoAttributes } from './workspace-settings-billing-details.utils';

export const useBillingInfo = () => {
  const { data: billingInfo = null } = useBillingInfoQuery();
  const { data: billingEmail = null } = useBillingEmailQuery();

  const canEditBillingInfo = useWorkspacePermission()('billing info', 'update');

  const billingInfoAttributes = createBillingInfoAttributes(
    billingInfo,
    billingEmail
  );

  return {
    billingInfo,
    billingInfoAttributes,
    canEditBillingInfo,
  };
};

export const useBillingInfoUpdate = () => {
  const updateBillingInfoMutation = useUpdateBillingInfoMutation();
  const updateBillingEmailMutation = useUpdateBillingEmailMutation();

  const updateBillingInfo = React.useCallback(
    async (billingInfo, attributes, onSuccess) => {
      if (billingInfo) {
        await updateBillingInfoMutation.mutateAsync({
          billingInfo,
          attributes,
        });
        await updateBillingEmailMutation.mutateAsync(attributes.email!);
        onSuccess?.();
      }
    },
    [updateBillingEmailMutation, updateBillingInfoMutation]
  );

  return {
    updateBillingInfo,
    isUpdatingBillingInfo:
      updateBillingInfoMutation.isLoading ||
      updateBillingEmailMutation.isLoading,
  };
};
