import React from 'react';
import styled from '@emotion/styled';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

import { ITestTemplate } from '../../types';

import { Section, TestInfoIllustration } from './ui';

interface TestInfoProps {
  template: ITestTemplate;
}

export function TestInfo({ template }: TestInfoProps) {
  const questionsAmount = template.testTemplate.skills.reduce(
    (sum, skill) => sum + skill.questionCount,
    0
  );

  if (!template.testPreview) {
    return null;
  }

  return (
    <Container>
      <div>
        <h2>About this test</h2>
        <p>{template.testTemplate.description}</p>
        <ul>
          <li>
            <Badge color="purple">
              <Icon icon={solid('list')} color="white" />
              {questionsAmount} questions
            </Badge>
          </li>
          <li>
            <Badge color="purple">
              <Icon icon={solid('clock')} color="white" />~
              {template.testPreview?.timeLimit / 60} minutes
            </Badge>
          </li>
          <li>
            <Badge color="purple">
              <Icon icon={solid('chart-simple')} color="white" />
              {template.testTemplate.experienceLevel === 'senior'
                ? 'Advanced'
                : 'Standard'}
            </Badge>
          </li>
          <li>
            <Badge color="peach">
              <Icon icon={solid('wrench')} color="purple-100" />
              Customizable
            </Badge>
          </li>
        </ul>
        <h3>Skills</h3>
        <ul>
          {template.testTemplate.skills.map(skill => (
            <li key={skill.id}>
              <Badge>{skill.name}</Badge>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <TestInfoIllustration />
      </div>
    </Container>
  );
}

const Container = styled(Section)`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 36px;

  h2 {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
    margin-top: 0;
  }
  p {
    margin-bottom: 24px;
  }

  h3 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 24px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & > div {
    flex: 1;

    &:last-child {
      align-items: center;
      display: flex;
      display: none;
      justify-content: center;
      padding: 16px;

      @media (min-width: 769px) {
        display: flex;
      }

      & > svg {
        height: 100%;
        max-height: 134px;
        max-width: 216px;
        width: 100%;
      }
    }
  }
`;

const Badge = styled.span<{ color?: 'purple' | 'peach' }>`
  align-items: center;
  background: ${({ color, theme }) => {
    switch (color) {
      case 'purple':
        return theme.colors.purple[90];
      case 'peach':
        return theme.colors.peach[100];
      default:
        return theme.colors.purple[5];
    }
  }};
  border-radius: 8px;
  color: ${({ color, theme }) => {
    switch (color) {
      case 'purple':
        return theme.colors.white;
      case 'peach':
      default:
        return theme.colors.purple[100];
    }
  }};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  gap: 4px;
  height: 24px;
  justify-content: center;
  padding: 8px 12px;
  text-wrap: nowrap;
`;
