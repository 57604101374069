import React, { memo } from 'react';
import styled from '@emotion/styled';
import { CandidateAnswer } from '@hundred5/design-system';
import { Player } from '@togglhire/video';

export interface VideoAnswerProps {
  recordingUrl?: string;
  isRecordingProcessed?: boolean;
  locked?: boolean;
}

export const VideoAnswer = memo(
  function({ recordingUrl, isRecordingProcessed, locked }: VideoAnswerProps) {
    return recordingUrl ? (
      <CandidateAnswer.VideoAnswer>
        <VideoAnswerContainer>
          <Player src={!locked ? recordingUrl : ''} rotate={true} />

          {isRecordingProcessed === false && (
            <Placeholder>
              The video answer is being processed, please come back later
            </Placeholder>
          )}

          {locked && <Placeholder>The video answer is locked</Placeholder>}
        </VideoAnswerContainer>
      </CandidateAnswer.VideoAnswer>
    ) : null;
  },
  (prevProps, nextProps) =>
    prevProps.recordingUrl === nextProps.recordingUrl &&
    prevProps.isRecordingProcessed === nextProps.isRecordingProcessed &&
    prevProps.locked === nextProps.locked
);

const VideoAnswerContainer = styled.section`
  position: relative;
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${props => props.theme.colors.purple[50]};
`;
