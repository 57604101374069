import { useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import qs from 'qs';
import { useDispatch } from 'react-redux';

import * as actions from '@/actions/authentication';
import * as api from '@/api/authentication';
import { checkClientError } from '@/errors';
import { amplitude } from '@/features/amplitude';
import { useFlashMessages } from '@/features/flash-messages';
import { ME_QUERY_KEY, useMeQuery } from '@/features/me';
import { trackVerifyEmail } from '@/googleTagManager';
import { useApiError } from '@/hooks/api';
import { useHistory } from '@/hooks/router';

export function useVerification() {
  const history = useHistory();
  const search = history.location.search.slice(1);
  const { token } = qs.parse(search) as { token: string };
  const handleApiError = useApiError();
  const { showFlashMessage } = useFlashMessages();
  const dispatch = useDispatch();
  const queryQlient = useQueryClient();

  const meQuery = useMeQuery();

  const verify = useCallback(
    async (token: string, verified: boolean | null) => {
      try {
        await api.verifyEmail(token);
      } catch (error) {
        if (checkClientError(error, 'InvalidToken')) {
          showFlashMessage({
            type: 'email_verification',
            error: 'invalid_token',
          });
        } else if (checkClientError(error, 'AlreadyVerified')) {
          showFlashMessage({
            type: 'email_verification',
            error: 'already_verified',
          });
        } else {
          handleApiError(error);
        }

        history.push(`/admin`);
        return;
      } finally {
        queryQlient.invalidateQueries(ME_QUERY_KEY);
      }

      if (verified === false) {
        amplitude?.logEvent('onboarding/verify email address');
        trackVerifyEmail();
      }

      dispatch(actions.emailVerified());
      showFlashMessage({ type: 'email_verification' });

      history.push(`/admin`);
    },
    [handleApiError, history, showFlashMessage, dispatch, queryQlient]
  );

  useEffect(() => {
    void verify(token, meQuery.data?.isEmailVerified ?? null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
