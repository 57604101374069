import React from 'react';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';

import {
  SelectAllButton,
  UnselectAllButton,
} from '@/features/candidate-search/components';
import {
  FieldWrapper,
  HoverableFieldWrapper,
} from '@/features/candidate-search/components/candidate-filters/ui/field-wrapper';
import { FilterName } from '@/features/candidate-search/components/candidate-filters/ui/filter-name';
import { FormFields } from '@/features/candidate-search/components/candidate-filters/ui/form-fields';
import { TAGS_FILTER_TYPE } from '@/features/candidate-search/components/tags-filter';
import { FormikCheckboxField } from '@/features/common';
import { IWorkspaceTag } from '@/features/workspace';

interface TagsOptionsProps {
  tags: IWorkspaceTag[];
  query?: string;
}

export const TagsOptions = ({ tags, query }: TagsOptionsProps) => {
  if (isEmpty(tags) && !query) return null;

  if (isEmpty(tags) && query) return <NoResults>No matching items</NoResults>;

  return (
    <>
      <HoverableFieldWrapper>
        <FormikCheckboxField
          name={TAGS_FILTER_TYPE}
          value="0"
          label="Without tag"
        />
      </HoverableFieldWrapper>
      <SelectionFieldWrapper>
        <FullWidthFilterName>Tags</FullWidthFilterName>
        <SelectAllButton
          name={TAGS_FILTER_TYPE}
          values={['0', ...tags.map(tag => tag.id)]}
        />
        <UnselectAllButton name={TAGS_FILTER_TYPE} />
      </SelectionFieldWrapper>
      <TagsContainer>
        <TagsWrapper>
          <FormFields>
            {tags.map(tag => (
              <HoverableFieldWrapper key={tag.id}>
                <FormikCheckboxField
                  key={tag.id}
                  name={TAGS_FILTER_TYPE}
                  value={tag.id}
                  label={tag.name.toLocaleUpperCase()}
                />
              </HoverableFieldWrapper>
            ))}
          </FormFields>
        </TagsWrapper>
      </TagsContainer>
    </>
  );
};

//region styles
const TagsContainer = styled.div`
  position: relative;
`;
const TagsWrapper = styled.div`
  max-height: 200px;
  overflow-y: auto;
  gap: 8px;
`;

const FullWidthFilterName = styled(FilterName)`
  flex: 1;
  padding-left: 0;
  padding-right: 0;
`;
const SelectionFieldWrapper = styled(FieldWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
const NoResults = styled.p`
  color: ${props => props.theme.colors.purple[60]};
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
`;
//endregion
