import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon } from '@/features/common';

export function FOMO() {
  return (
    <Container>
      <Icon icon={regular('arrow-trend-up')} color="purple-60" size="xs" />
      <Text>Actively looking</Text>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  grid-area: fomo;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.colors.purple[60]};
  font-size: 10px;
  font-weight: 500;
  line-height: 150%;
`;
