import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Button } from '@hundred5/design-system';

import { BillingPlan, useSubscribeModal } from '@/features/billing';
import { useHistory, useWorkspaceId } from '@/hooks/router';

interface UpgradeBannerProps {
  icon: React.ReactNode;
  title: string;
  caption: string;
  requiredPlan?: BillingPlan;
  upgradeButtonVisible?: boolean;
  onUpgrade?: () => void;
}

const UpgradeBanner = ({
  icon,
  title,
  caption,
  onUpgrade,
  requiredPlan,
  upgradeButtonVisible = true,
}: UpgradeBannerProps) => {
  const history = useHistory();
  const workspaceId = useWorkspaceId();
  const { openSubscribeModal } = useSubscribeModal();

  const handleOnClick = useCallback(() => {
    history.push(`/admin/${workspaceId}/settings/billing`);
    requiredPlan && openSubscribeModal(requiredPlan);
  }, [history, openSubscribeModal, requiredPlan, workspaceId]);

  return (
    <Container>
      <Icon>{icon}</Icon>
      <div>
        <h3>{title}</h3>
        {caption}
      </div>
      {upgradeButtonVisible && (
        <Button
          variant="primary"
          type="button"
          onClick={() => onUpgrade?.() || handleOnClick()}
        >
          Upgrade
        </Button>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 21.75px 36px;
  background: #ffeec8;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #1a1a1a;
  z-index: 100;

  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  button {
    margin-left: auto;
  }

  @media all and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 20px;
    padding: 21.75px 12px;

    button {
      margin: auto;
    }
  }
`;

const Icon = styled.div`
  @media all and (min-width: 769px) {
    margin-right: 36px;
  }
`;

export default UpgradeBanner;
