import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';
import { useQueryClient } from '@tanstack/react-query';
import formatDate from 'date-fns/format';
import { useParams } from 'react-router-dom';

import {
  CANDIDATE_QUERY_KEY,
  useCreateCandidateNoteMutation,
  useDeleteCandidateNoteMutation,
  useUpdateCandidateNoteMutation,
} from '@/features/candidate';
import { IAnswerNote } from '@/features/candidate/types';
import { Icon, TId } from '@/features/common';
import { AutocompleteTextArea } from '@/features/common/components/autocomplete-text-area/autocomplete-text-area';
import { useMeQuery } from '@/features/me';
import { WorkspaceUser } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

interface AnswerNotesProps {
  candidateId?: TId;
  answerId?: TId;
  notes?: IAnswerNote[];
}

export function AnswerNotes({
  notes,
  candidateId,
  answerId,
}: AnswerNotesProps) {
  const params = useParams();
  const queryClient = useQueryClient();
  const meQuery = useMeQuery();
  const workspaceId = useWorkspaceId();
  const selection = useRef<[number, number]>([0, 0]);

  const successHandler = useCallback(() => {
    queryClient.invalidateQueries([...CANDIDATE_QUERY_KEY, candidateId]);
  }, [candidateId, queryClient]);

  const createCandidateNoteMutation = useCreateCandidateNoteMutation({
    onSuccess: successHandler,
  });
  const updateNoteMutation = useUpdateCandidateNoteMutation({
    onSuccess: successHandler,
  });
  const deleteNoteMutation = useDeleteCandidateNoteMutation({
    onSuccess: successHandler,
  });

  const [newNote, setNewNote] = useState('');
  const [editedNote, setEditedNote] = useState('');
  const [editingNoteId, setEditingNoteId] = useState(null);

  const [mentionedUsers, setMentionedUsers] = useState<WorkspaceUser[]>([]);

  const handleUpdateNote = async (
    event: React.FormEvent<HTMLFormElement>,
    note: IAnswerNote
  ) => {
    event.preventDefault();

    await updateNoteMutation.mutateAsync({
      id: note.id,
      note: editedNote,
      userIds: getMentions(editedNote).map(user => user.id),
    });

    setEditedNote('');
    setEditingNoteId(null);
  };

  const onEditNote = useCallback(note => {
    setEditingNoteId(note.id);
    setEditedNote(note.note);
  }, []);

  const selectionChanged = (e: HTMLTextAreaElement) => {
    selection.current = [e.selectionStart, e.selectionEnd];
  };

  const handleNewNote = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!candidateId || newNote.trim() === '') {
      return;
    }

    await createCandidateNoteMutation.mutateAsync({
      candidateId,
      note: newNote,
      answerId,
      userIds: getMentions(newNote).map(user => user.id),
    });

    setNewNote('');
    setMentionedUsers([]);
  };

  const onUserSelect = (user: WorkspaceUser) => {
    setMentionedUsers(prev => [...prev, user]);
  };

  const getMentions = (note: string) => {
    return mentionedUsers.filter(user => note.includes(`@${user.name}`));
  };

  const scrollToNote = (noteId: string) => {
    const note = document.getElementById(`note-id-${noteId}`);
    if (note) {
      note.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (params.noteId) {
      scrollToNote(params.noteId);
    }
  }, [params.noteId]);

  if (!candidateId || !answerId || !meQuery.isSuccess) {
    return null;
  }

  return (
    <Container>
      <Notes>
        {notes?.map(note => (
          <Note key={note.id} id={`note-id-${note.id}`}>
            {editingNoteId === note.id ? (
              <NoteEditing onSubmit={event => handleUpdateNote(event, note)}>
                <AutocompleteTextArea
                  value={editedNote}
                  onChange={value => setEditedNote(value)}
                  onSelect={user => {
                    onUserSelect(user);
                  }}
                  onKeyUp={e => selectionChanged(e.target)}
                  onMouseUp={e => selectionChanged(e.target)}
                />
                <EditingButtons>
                  <DSButton
                    variant="secondary"
                    onClick={() => setEditingNoteId(null)}
                  >
                    Cancel
                  </DSButton>
                  <DSButton type="submit">Update</DSButton>
                </EditingButtons>
              </NoteEditing>
            ) : (
              <>
                <Content>
                  {note.note}
                  <Footer>
                    <span>
                      {formatDate(note.createdAt, 'MMM D, YYYY, HH:mm')} /{' '}
                      {note.isAiNote ? 'AI generated' : note.author}{' '}
                    </span>
                  </Footer>
                </Content>
                {meQuery.data.id === note.authorId ? (
                  <Actions>
                    <DSButton
                      variant="secondary"
                      size="xsmall"
                      Icon={<Icon icon={regular('pen-to-square')} />}
                      onClick={() => onEditNote(note)}
                    />
                    <DSButton
                      variant="secondary"
                      size="xsmall"
                      Icon={<Icon icon={regular('trash')} />}
                      onClick={() => {
                        deleteNoteMutation.mutate({ id: note.id });
                      }}
                    />
                  </Actions>
                ) : null}
              </>
            )}
          </Note>
        ))}
      </Notes>
      <form onSubmit={handleNewNote}>
        <AutocompleteTextArea
          placeholder="Add notes about this answer here. Use @ to mention your colleagues."
          value={newNote}
          onChange={value => setNewNote(value)}
          onSelect={slug => {
            onUserSelect(slug);
          }}
          onKeyUp={e => selectionChanged(e.target as HTMLTextAreaElement)}
          onMouseUp={e => selectionChanged(e.target as HTMLTextAreaElement)}
        />
        <EditingButtons>
          <DSButton
            type="submit"
            disabled={newNote === ''}
            size={'small'}
            variant="secondary"
            onClick={() => {
              amplitude?.logEvent('added note', {
                'workspace id': workspaceId,
              });
            }}
          >
            Add note
          </DSButton>
        </EditingButtons>
      </form>
    </Container>
  );
}

const Container = styled.div`
  border-top: none !important;
  padding: 20px 24px !important;
`;

const Notes = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NoteEditing = styled.form`
  width: 100%;
`;

const Note = styled.li`
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.purple[10]};
  display: flex;
  padding-top: 16px;
  padding-bottom: 12px;
`;

const Content = styled.div`
  flex-grow: 1;
`;
const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

const Footer = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.purple[60]};
  margin-top: 8px;
`;

const EditingButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
