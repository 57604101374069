import qs from 'qs';

import configuration from '../configuration/runtime';
import { Id } from '../types/misc';
import { CandidateFilter } from '../types/reports';

import api from './api';
import { getCandidateFilterQueryParams } from './candidates';

const baseUrl = configuration.apiUrl + '/api/v1';

export async function exportJobOpeningCandidatesAsPdf(
  jobOpeningId: Id,
  filter: CandidateFilter | null
): Promise<string> {
  const response = await api.post(
    `/openings/${jobOpeningId}/test-takers/export`
  );

  let params = { token: response.data.token };

  if (filter != null) {
    params = { ...params, ...getCandidateFilterQueryParams(filter) };
  }

  const query = qs.stringify(params);
  return `${baseUrl}/openings/${jobOpeningId}/test-takers/export?${query}`;
}

export async function exportJobOpeningCandidatesAsCsv(
  jobOpeningId: Id,
  filter: CandidateFilter | null
): Promise<string> {
  let params = { format: 'csv' };

  if (filter != null) {
    params = { ...params, ...getCandidateFilterQueryParams(filter) };
  }

  const response = await api.get(`/openings/${jobOpeningId}/test-takers`, {
    params,
  });

  return response.data;
}

export async function exportJobOpeningCandidatesAsCsvBlob(
  jobOpeningId: Id,
  filter: CandidateFilter | null
): Promise<Blob> {
  let params = { format: 'csv' };

  if (filter != null) {
    params = { ...params, ...getCandidateFilterQueryParams(filter) };
  }

  const response = await api.get(`/openings/${jobOpeningId}/test-takers`, {
    responseType: 'blob',
    params,
  });

  return response.data;
}

export async function exportCandidate(candidateId: Id): Promise<string> {
  const response = await api.post(`/test-takers/${candidateId}/export`);
  const token = response.data.token;

  return `${baseUrl}/test-takers/${candidateId}/export?token=${token}`;
}
