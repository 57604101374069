import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import PurchaseCreditsModal from '@/components/purchase_access/purchase_credits_modal/PurchaseCreditsModal';
import {
  BillingPlan as BillingPlanType,
  CancelSubscribeModal,
  ECancelSubscribeStep,
  PremiumPlanVariant,
  useBillingPlansConfig,
  useSubscribeModal,
  useSubscriptionQuery,
} from '@/features/billing';
import { Icon } from '@/features/common';
import { getPlanPeriod } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

import { useWorkspaceQuery } from '../../queries';

import { BillingPlan, PeriodSelector } from './ui';

//region Main
export const WorkspaceSettingsBillingPlans = () => {
  const workspaceId = useWorkspaceId();
  const billingPlansDescriptions = useBillingPlansConfig();
  const premiumPlanDefaultVariant = '60c';
  const [selectedPeriod, setSelectedPeriod] = useState<'monthly' | 'annual'>(
    'annual'
  );
  const [selectedPremiumPlanVariant, setSelectedPremiumPlanVariant] = useState<
    PremiumPlanVariant
  >(premiumPlanDefaultVariant);
  const [activePlan, setActivePlan] = useState<BillingPlanType>('free-v2-2023');
  const [cancelSubscribeStep, setCancelSubscribeStep] = useState<
    ECancelSubscribeStep | undefined
  >();
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState<boolean>(
    false
  );

  const plans = billingPlansDescriptions.premiumPlanVariants?.length
    ? billingPlansDescriptions.plans.filter(plan =>
        plan.plan === 'premium'
          ? selectedPeriod === 'annual'
            ? plan.variant === selectedPremiumPlanVariant
            : plan.variant === '300c'
          : plan
      )
    : billingPlansDescriptions.plans;

  const { data: billingAccount } = useSubscriptionQuery();
  const { openSubscribeModal } = useSubscribeModal();
  const workspaceQuery = useWorkspaceQuery({ workspaceId });

  const currentPlan = billingAccount
    ? billingAccount.subscription.currentPlan
    : null;
  const subscriptionActive = billingAccount
    ? billingAccount.subscription.state === 'active'
    : false;

  useEffect(() => {
    if (currentPlan) {
      setSelectedPeriod(getPlanPeriod(currentPlan));
      setActivePlan(currentPlan);
    } else {
      setActivePlan(workspaceQuery.data?.subscriptionPlan || 'free-v2-2023');
    }
  }, [currentPlan, workspaceQuery.data]);

  return (
    <BillingPlansContainer>
      {/free|basic/.test(activePlan) ? (
        <>
          <CTA>
            <h1>Something for everyone</h1>
            Get the most out of your time and the best out of your team with our
            paid plans
          </CTA>
        </>
      ) : null}
      <PeriodSelectorWrapper>
        Choose your billing
        <PeriodSelector
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
        />
      </PeriodSelectorWrapper>
      <PlansContainer>
        <PlansContent>
          {plans.map(billingPlan => (
            <BillingPlan
              planDescription={billingPlan}
              currentPlan={activePlan}
              selectedPeriod={selectedPeriod}
              downgradeDate={
                !subscriptionActive
                  ? billingAccount?.subscription.currentPeriodEndsAt
                  : undefined
              }
              subscribe={() => {
                openSubscribeModal(
                  selectedPeriod === 'monthly'
                    ? billingPlan.planCode.monthly
                    : billingPlan.planCode.annual
                );
              }}
              cancelSubsctiption={() => {
                setCancelSubscribeStep(ECancelSubscribeStep.Initial);
              }}
              purchaseCredits={() => setIsPurchaseModalOpen(true)}
              key={billingPlan.planCode.monthly}
              premiumPlanVariants={billingPlansDescriptions.premiumPlanVariants}
              onChangePremiumPlanVariant={
                billingPlansDescriptions.premiumPlanVariants?.length
                  ? setSelectedPremiumPlanVariant
                  : undefined
              }
            />
          ))}
        </PlansContent>
      </PlansContainer>
      <Comparison>
        There's more!{' '}
        <a href="https://toggl.com/hire/pricing">
          See full plan comparison{' '}
          <Icon icon={regular('arrow-up-right')} color="orange-100" size="lg" />
        </a>
      </Comparison>
      <CancelSubscribeModal
        currentStep={cancelSubscribeStep}
        setCurrentStep={setCancelSubscribeStep}
      />
      <PurchaseCreditsModal
        open={isPurchaseModalOpen}
        onClose={() => setIsPurchaseModalOpen(false)}
      />
    </BillingPlansContainer>
  );
};
//endregion

//region Styles
const BillingPlansContainer = styled.div`
  color: ${props => props.theme.typography.colorPrimary};
`;

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 42px;
  overflow-x: auto;

  @media all and (max-width: 1400px) {
    justify-content: start;
  }
`;

const PlansContent = styled.div`
  display: flex;
  padding-top: 41px;
  padding-bottom: 4px;
  gap: 24px;
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 12px;
  margin: auto;
  text-align: center;

  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
`;

const PeriodSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin: 24px 0 60px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
`;

const Comparison = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 36px;

  a {
    color: ${props => props.theme.colors.orange[100]};
  }
`;
//endregion
