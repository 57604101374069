import {
  ICandidateAnswer,
  TCandidateAnswerScoreStatus,
  TQuestionGroup,
} from '@/features/candidate';
import { ITestQuestion } from '@/features/test';

export const getCandidateAnswerScoreStatus = (
  question: ITestQuestion,
  answer?: ICandidateAnswer,
  evaluationOptions?: number
): TCandidateAnswerScoreStatus => {
  if (!answer) {
    return 'missing';
  }

  if (question.points === 0) {
    return 'no_points';
  }

  // Checks for missing answer by question type
  switch (question.questionType) {
    case 'free-text':
    case 'code-input':
    case 'numeric-input': {
      if (!answer.freeFormAnswer) {
        return 'missing';
      }
      break;
    }
    case 'video-question': {
      if (!answer.recordingUrl) {
        return 'missing';
      }
      break;
    }
    case 'single-choice':
    case 'multiple-choice':
    case 'picture-question': {
      if (!answer.chosenOptions) {
        return 'missing';
      }
      break;
    }
  }

  // Answer present, check the score next
  if (answer.scoreAsPercents !== null) {
    if (
      ['free-text', 'code-input', 'video-question'].includes(
        question.questionType
      ) &&
      evaluationOptions === 5
    ) {
      switch (answer.scoreAsPercents) {
        case 100:
          return 'excellent';
        case 75:
          return 'good';
        case 50:
          return 'average';
        case 25:
          return 'weak';
        case 0:
          return 'incorrect';
        default:
          return 'average';
      }
    } else {
      if (answer.scoreAsPercents === 100) {
        return 'correct';
      } else if (answer.scoreAsPercents > 0) {
        return 'partial';
      } else {
        return 'wrong';
      }
    }
  } else {
    return 'manual';
  }
};

export const getQuestionGroup = (question: ITestQuestion): TQuestionGroup => {
  if (question.isStatic) {
    if (
      question.questionType === 'free-text' ||
      question.questionType === 'code-input' ||
      question.questionType === 'video-question'
    ) {
      return question.questionType;
    } else {
      return 'static';
    }
  } else {
    return 'smart';
  }
};
