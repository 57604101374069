import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { DSSearchInput } from '@hundred5/design-system';

import { amplitude } from '@/features/amplitude';
import { useCandidateSearchParams } from '@/features/candidate-search';
import { useJobOpeningIdOrNull } from '@/hooks/router';

type Props = {
  className?: string;
  placeholder?: string;
};

/**
 * TODO: temporary solution (left here because the pipeline will get refactored soon anyway)
 *
 * Created a debounce function to store and clear timeout reference.
 * Lodash debounce returned function that got recreated on each setCandidatesSearchParams change, that caused weird behaviour.
 */
function useDebounce<T>(funct: (value: T) => void, timeout: number) {
  const timerRef = useRef<NodeJS.Timer>();

  const setValue = (value: any) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => funct(value), timeout);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return setValue;
}

export const CandidatesSearch = ({
  className,
  placeholder = 'Search name, email or job',
}: Props) => {
  const {
    searchParams: candidatesSearchParams,
    addSearchParams,
    removeSearchParam,
  } = useCandidateSearchParams();
  const jobOpeningId = useJobOpeningIdOrNull();
  const [value, setValue] = useState(candidatesSearchParams.q || '');
  const updateSearchQuery = useDebounce(addSearchParams, 750);
  useEffect(() => {
    candidatesSearchParams.q && setValue(candidatesSearchParams.q);
  }, [candidatesSearchParams.q]);

  return (
    <SearchContainer className={className}>
      <DSSearchInput
        placeholder={placeholder}
        background={'purple'}
        value={value}
        showResetButton
        reset={() => {
          setValue('');
          removeSearchParam('q');
        }}
        onChange={event => {
          const value = event.target.value;
          setValue(value);

          if (!value) {
            removeSearchParam('q');
          } else {
            updateSearchQuery({ q: value });
            amplitude?.logEvent('candidate search/candidate search', {
              'search value': value,
              'filter origin': jobOpeningId ? 'pipeline' : 'all candidates',
              'job opening id': jobOpeningId,
            });
          }
        }}
      />
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  width: 332px;
  flex-shrink: 0;
`;
