import React, { useCallback } from 'react';

import { TId, ConfirmModal } from '@/features/common';

interface DeleteTestModalProps {
  onClose: () => void;
  onDelete: (categoryId: TId) => void;
  open: boolean;
  testId?: TId;
}

export function DeleteTestModal({
  onClose,
  onDelete,
  open,
  testId,
}: DeleteTestModalProps) {
  const handleConfirm = useCallback(() => {
    if (!testId) {
      return;
    }

    onDelete(testId);
    onClose();
  }, [testId, onDelete, onClose]);

  return (
    <ConfirmModal
      open={open}
      action="Delete"
      onConfirm={handleConfirm}
      onClose={onClose}
    >
      <h3>Are you sure?</h3>
      <p>
        This test is going to be deleted permanently with no backups. <br />
        Therefore be sure you really wish to do that.
      </p>
    </ConfirmModal>
  );
}
