import React from 'react';
import styled from '@emotion/styled';
import { AlphaButton } from '@hundred5/design-system';

import configuration from '../../../../configuration/runtime';

import ErrorImage from './ErrorImage.svg';

interface ErrorFallbackProps {
  error: Error;
  eventId: string | null;
  resetError: () => void;
}

export const ErrorFallback = ({
  error,
  eventId,
  resetError,
}: ErrorFallbackProps) => {
  const supportEmail = configuration.supportEmail;

  return (
    <ErrorFallbackWrapper>
      <img src={ErrorImage} alt="error" />

      <ErrorFallbackContent>
        Oops! Something didn't go as planned. To help us fix this quickly,
        please refresh the page. If the issue persists, contact us at{' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        {eventId
          ? ` with this error
        ID: ${eventId}`
          : ''}
      </ErrorFallbackContent>

      <div>
        <AlphaButton
          color="gray"
          onClick={() => {
            resetError();
          }}
        >
          Reload page
        </AlphaButton>
      </div>
    </ErrorFallbackWrapper>
  );
};

const ErrorFallbackWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorFallbackContent = styled.div`
  width: 450px;
  margin: 20px 0;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: #000;
`;
