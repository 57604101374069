import axios from 'axios';

import configuration from '@/configuration/runtime';

const togglAccountsApi = axios.create({
  withCredentials: true,
  baseURL: configuration.togglAccountsUrl + '/api',
});

export default togglAccountsApi;
