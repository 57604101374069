import { useEffect } from 'react';
import * as SentryLib from '@sentry/react';

import { useMeQuery } from '@/features/me';

const Sentry = () => {
  const meQuery = useMeQuery();

  useEffect(() => {
    if (meQuery.isSuccess) {
      SentryLib.setUser({
        id: meQuery.data.id,
        email: meQuery.data.email,
      });
    } else {
      SentryLib.setUser(null);
    }
  }, [meQuery.data, meQuery.isSuccess]);

  return null;
};

export default Sentry;
