import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { loggedIn } from '@/actions/authentication';
import { setAuthToken } from '@/api/api';
import { createDemoWorkspace } from '@/api/demo';
import { storeAuthToken } from '@/authentication';

type DemoHook = () => void;

export function useDemo(): DemoHook {
  const dispatch = useDispatch();

  return useCallback(async () => {
    const token = await createDemoWorkspace();

    storeAuthToken(token);
    setAuthToken(token);

    dispatch(loggedIn());
  }, [dispatch]);
}
