import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';

import {
  IPipelineCategory,
  useUpdatePipelineCategoryMutation,
} from '@/features/pipeline';

interface CategoryNameEditProps {
  category: IPipelineCategory;
  jobOpeningId: string;
}

export const CategoryNameEdit = ({
  category,
  jobOpeningId,
}: CategoryNameEditProps) => {
  const [categoryName, setCategoryName] = useState(category.name);

  const updatePipelineCategoryMutation = useUpdatePipelineCategoryMutation();

  const handleRenameStage = useCallback(
    (newName: string, category: IPipelineCategory) => {
      // update category name
      updatePipelineCategoryMutation.mutateAsync({
        jobOpeningId,
        category: {
          ...category,
          name: newName,
        },
      });
    },
    [jobOpeningId, updatePipelineCategoryMutation]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (categoryName !== category.name && categoryName !== '') {
        handleRenameStage(categoryName, category);
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [category, categoryName, handleRenameStage]);

  return (
    <CategoryNameEditInput
      value={categoryName}
      name="name"
      autoFocus
      onChange={event => {
        setCategoryName(event.target.value);
      }}
      autoComplete="off"
      data-1p-ignore
    ></CategoryNameEditInput>
  );
};

const CategoryNameEditInput = styled.input`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  height: 24px;
  border: 0;
  background: transparent;
  padding-left: 8px;
  outline: 0;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.peach[50]};
  min-width: 0px;

  &:focus {
    outline: none;
  }
`;
