import React, { useCallback, useMemo, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSCombobox,
  DSContextMenu,
  DSContextMenuButton,
  DSField,
  DSInput,
  DSSelect,
  DSSwitch,
} from '@hundred5/design-system';
import { camelize } from 'humps';
import { generatePath, useRouteMatch } from 'react-router-dom';

import Markdown from '@/components/markdown/Markdown';
import { Icon, TId } from '@/features/common';
import {
  createEditorState,
  getEditorMarkdown,
} from '@/features/common/components/custom-text-editor';
import { IQuestion, TQuestionType } from '@/features/questions';
import {
  useDeleteQuestion,
  useRegenerateQuestion,
  useUpdateQuestion,
  useUpdateQuestionProperties,
} from '@/features/questions/hooks';
import { ATTACHMENTS_OPTIONS } from '@/features/questions/utils/attachments-options';
import { QUESTION_DIFFICULTY_OPTIONS } from '@/features/questions/utils/question-difficulty-options';
import { ISkill } from '@/features/skills';
import { TTestType, useTestPage } from '@/features/test';
import { DeleteQuestionModal } from '@/features/test/components/test-editor-question-preview/ui/delete-question-modal';
import {
  DisabledCopyContainer,
  PreviewHeader,
} from '@/features/test/components/ui';
import { useSkills } from '@/features/test/hooks/use-skills';
import { LIBRARY_QUESTION_TYPE_OPTIONS } from '@/features/test/utils/library-question-options';
import {
  getQuestionTypesForSkill,
  skillHasQuestionsForTestType,
} from '@/features/test/utils/skills';
import { useHistory } from '@/hooks/router';

import { QuestionAnswers } from './question-answers';
import { QuestionDescription } from './question-description';
import { TestEditorQuestionPreviewProps } from './test-editor-question-preview.types';
import { QuestionNotes, QuestionSettings } from './ui';

/**
 * Returns the type of question that should be used in the library for a given question type.
 * @param questionType
 */
function getLibraryQuestionTypeForQuestionType(
  questionType: TQuestionType
): TQuestionType {
  if (
    questionType === 'single-choice' ||
    questionType === 'multiple-choice' ||
    questionType === 'numeric-input'
  ) {
    return 'placeholder-close-ended';
  }
  return questionType;
}

const getAttachmentFieldLabelFromValue = (value: boolean | null) => {
  switch (value) {
    case true:
      return 'mandatory';
    case false:
      return 'optional';
    case null:
    default:
      return 'off';
  }
};

const getAttachmentValueFromLabel = (label: string) => {
  switch (label) {
    case 'mandatory':
      return true;
    case 'optional':
      return false;
    case 'off':
    default:
      return null;
  }
};

const useQuestionSettingsOptions = (
  question: IQuestion,
  testType: TTestType
) => {
  const { skills } = useSkills(testType, true);

  const skillsOptions = useMemo(
    () =>
      skills
        .filter(s => !s.isHidden || s.id === question.skill?.id)
        .map(s => ({
          ...s,
          disabled: !skillHasQuestionsForTestType(s, testType),
        })),
    [skills, testType, question.skill?.id]
  );

  const selectedSkill = useMemo(
    () => skillsOptions.find(skill => skill.id === question.skill?.id),
    [question.skill, skillsOptions]
  );

  const questionTypeOptions = useMemo(
    () =>
      LIBRARY_QUESTION_TYPE_OPTIONS
        // Disable free-text and code-input for random questions
        .map(type => {
          // grouping header keep their settings
          if (type.id === 'auto-evaluated' || type.id === 'manually-evaluated')
            return type;

          // only close-ended questions can be randomized
          let disabled =
            question.isRandom && type.id !== 'placeholder-close-ended';

          // disable if skill does not have this question type
          if (selectedSkill) {
            const questionTypes = getQuestionTypesForSkill(
              selectedSkill,
              testType
            );
            // type needs to be camel-case because api data is also camel-case
            disabled =
              disabled ||
              !questionTypes.includes(camelize(type.id) as TQuestionType);
          }

          return {
            id: type.id,
            label: type.label,
            disabled,
          };
        }),
    [question.isRandom, selectedSkill, testType]
  );

  const questionDifficultyOptions = useMemo(
    () =>
      QUESTION_DIFFICULTY_OPTIONS.map(type => {
        const questionType = camelize(
          getLibraryQuestionTypeForQuestionType(question.questionType)
        );

        return {
          id: type.id,
          label: type.label,
          disabled: !selectedSkill?.questions[testType][questionType][type.id],
        };
      }),
    [question.questionType, selectedSkill?.questions, testType]
  );

  return {
    selectedSkill,
    skillsOptions,
    questionTypeOptions,
    questionDifficultyOptions,
  };
};

export const LibraryQuestionPreview = ({
  question,
  index,
}: TestEditorQuestionPreviewProps) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { workspace, jobOpening, test } = useTestPage();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  const isVideoQuestion = test.type === 'video';
  const isHomework = test.type === 'homework';
  const isOpenEndedQuestion =
    question.questionType === 'free-text' ||
    question.questionType === 'code-input';
  const { isDeleting } = useDeleteQuestion(
    workspace.id,
    jobOpening.id,
    test.id,
    question.id
  );
  const {
    updateQuestionProperty,
    updateQuestion,
    isUpdating,
  } = useUpdateQuestion(test.id);
  const {
    updateQuestionProperties,
    isUpdating: isUpdatingProperties,
  } = useUpdateQuestionProperties(test.id);
  const { regenerateQuestion, isRegenerating } = useRegenerateQuestion(
    jobOpening.id,
    test.id
  );

  const {
    selectedSkill,
    skillsOptions,
    questionTypeOptions,
    questionDifficultyOptions,
  } = useQuestionSettingsOptions(question, test.type);

  const description = useMemo(() => createEditorState(question.question), [
    question.question,
  ]);

  const isChangingQuestion =
    isDeleting || isUpdating || isRegenerating || isUpdatingProperties;

  // When skill changes reset question type, difficulty and randomness
  const handleSkillChange = useCallback(
    async (skillId: TId, onChange?: () => void) => {
      const { skill, ...rest } = question;
      await updateQuestion(
        {
          ...rest,
          skillId,
          questionType: isVideoQuestion
            ? 'video-question'
            : isHomework
            ? 'placeholder-open-ended'
            : 'placeholder-close-ended',
          difficulty: 'standard',
          isRandom: false,
        },
        (newQuestion: IQuestion) => {
          onChange?.();
          history.replace(
            path.replace('/:questionId(\\d+)', `/${newQuestion.id}`)
          );
        }
      );
    },
    [history, isVideoQuestion, isHomework, path, question, updateQuestion]
  );

  // When question type changes, reset difficulty and randomness
  // Also check whether skill has standard question or not
  const handleQuestionTypeChange = useCallback(
    async (questionType: TQuestionType, onChange?: () => void) => {
      if (questionType) {
        const difficulty = !selectedSkill
          ? 'standard'
          : selectedSkill.questions[test.type][camelize(questionType)].standard
          ? 'standard'
          : 'hard';

        await updateQuestion(
          {
            ...question,
            skillId: question.skill!.id,
            questionType,
            difficulty,
            isRandom: questionType === 'placeholder-close-ended',
          },
          (newQuestion: IQuestion) => {
            onChange?.();
            history.replace(
              path.replace('/:questionId(\\d+)', `/${newQuestion.id}`)
            );
          }
        );
      }
    },
    [history, path, question, selectedSkill, test.type, updateQuestion]
  );

  const handlePropertyChange = useCallback(
    (
      key: string,
      value: string | boolean | number | null,
      onChange?: () => void
    ) => {
      updateQuestionProperty({
        question,
        key,
        value,
        onUpdate: (newQuestion: IQuestion) => {
          onChange?.();
          history.replace(
            path.replace('/:questionId(\\d+)', `/${newQuestion.id}`)
          );
        },
      });
    },
    [history, path, question, updateQuestionProperty]
  );

  const handleEditQuestion = useCallback(() => {
    history.replace(`${url}/edit${history.location.search}`);
  }, [history, url]);

  const handleRegenerateQuestion = useCallback(() => {
    regenerateQuestion(question, (newQuestion: IQuestion) => {
      history.replace(generatePath(path, { questionId: newQuestion.id }));
    });
  }, [regenerateQuestion, question, path, history]);

  const showRandomMessage =
    question.isRandom && !isVideoQuestion && !isOpenEndedQuestion;

  return (
    <DisabledCopyContainer>
      <PreviewHeader.Wrapper>
        <PreviewHeader.QuestionName>
          Question {index + 1}
        </PreviewHeader.QuestionName>
        <PreviewHeader.Actions>
          <DSButton
            variant="secondary"
            size="small"
            disabled={
              isChangingQuestion || (question.isRandom && !isOpenEndedQuestion)
            }
            onClick={handleRegenerateQuestion}
            data-rh={
              question.isRandom && !isOpenEndedQuestion
                ? 'To choose a specific question, turn off randomize.'
                : undefined
            }
          >
            <Icon icon={regular('arrows-rotate')} />
            <span>Regenerate</span>
          </DSButton>
          <DSButton
            variant="secondary"
            size="small"
            onClick={() => {
              setIsConfirmModalOpen(true);
            }}
            disabled={isChangingQuestion}
          >
            <Icon icon={regular('trash')} />
            <span>Remove</span>
          </DSButton>
          {!question.isRandom ? (
            <ContextMenuWrapper>
              <DSContextMenuButton
                active={contextMenuOpen}
                onClick={() => {
                  setContextMenuOpen(prev => !prev);
                }}
              />

              <DSContextMenu
                open={contextMenuOpen}
                position={{ top: '32px', right: '0' }}
              >
                <DSContextMenu.Item
                  label="Edit question"
                  icon={<Icon icon={regular('pen-to-square')} />}
                  onClick={() => {
                    handleEditQuestion();
                    setContextMenuOpen(false);
                  }}
                />
              </DSContextMenu>
            </ContextMenuWrapper>
          ) : null}
        </PreviewHeader.Actions>
      </PreviewHeader.Wrapper>
      <QuestionSettings>
        <DSField for="skill" label="Skill">
          <DSCombobox
            items={skillsOptions}
            filterBy="name"
            labelField="name"
            groupBy="type"
            selectedItemId={question.skill?.id}
            onChange={async (option: ISkill) => {
              if (option?.id) {
                await handleSkillChange(option.id, () => {
                  amplitude?.logEvent('question creation/change skill', {
                    'job opening id': jobOpening.id,
                    'test id': test.id,
                    skill: option.name,
                  });
                });
              }
            }}
            onSearchChange={(query: string | undefined) => {
              amplitude?.logEvent('question creation/search for skill', {
                'job opening id': jobOpening.id,
                'test id': test.id,
                query,
              });
            }}
            placeholder="Search for a skill"
            disabled={isChangingQuestion}
            noResultsMessage="No skills found"
            maxDropdownHeight="290px"
          />
        </DSField>
        {!isVideoQuestion && !isHomework ? (
          <DSField for="questionType" label="Question type">
            <DSSelect
              id="questionType"
              items={questionTypeOptions}
              onChange={async option => {
                await handleQuestionTypeChange(
                  (option?.id as TQuestionType) ?? '',
                  () => {
                    if (option) {
                      amplitude?.logEvent(
                        'question creation/change question type',
                        {
                          'job opening id': jobOpening.id,
                          'test id': test.id,
                          'question type': option.label,
                        }
                      );
                    }
                  }
                );
              }}
              selectedItemId={getLibraryQuestionTypeForQuestionType(
                question.questionType
              )}
              disabled={isChangingQuestion}
            />
          </DSField>
        ) : null}
        {!isHomework ? (
          <DSField for="difficulty" label="Difficulty">
            <DSSelect
              id="difficulty"
              items={questionDifficultyOptions}
              onChange={option =>
                handlePropertyChange('difficulty', option?.id ?? 'standard')
              }
              selectedItemId={question.difficulty}
              disabled={isChangingQuestion}
            />
          </DSField>
        ) : null}
        {!isVideoQuestion && !isHomework ? (
          <DSField
            for="isRandom"
            label="Randomize"
            data-rh={
              isOpenEndedQuestion
                ? 'Manually evaluated questions cannot be randomized.'
                : undefined
            }
            disabled={isOpenEndedQuestion}
          >
            <DSSwitch
              id="isRandom"
              onChange={event => {
                handlePropertyChange('isRandom', event.target.checked, () => {
                  amplitude?.logEvent('question creation/toggle random', {
                    'job opening id': jobOpening.id,
                    'test id': test.id,
                    random: event.target.checked,
                  });
                });
              }}
              checked={isOpenEndedQuestion ? false : question.isRandom}
              disabled={isChangingQuestion || isOpenEndedQuestion}
            />
          </DSField>
        ) : null}
        {isHomework ? (
          <AttachmentsField
            for="attachments"
            label={
              <>
                Attachments
                <Icon
                  style={{ marginLeft: '4px' }}
                  icon={regular('circle-info')}
                  color="purple-60"
                  fontSize="12px"
                  data-rh="Accepted file types: doc, docx, xls, xlsx, odt, pdf, txt, jpg, jpeg, png"
                  data-rh-position="top"
                />
              </>
            }
          >
            <DSSelect
              id="attachments"
              items={ATTACHMENTS_OPTIONS}
              selectedItemId={getAttachmentFieldLabelFromValue(
                question.requireAttachment
              )}
              onChange={selectedItem =>
                updateQuestionProperties(question.id, {
                  requireAttachment: getAttachmentValueFromLabel(
                    selectedItem?.id ?? 'off'
                  ),
                })
              }
              disabled={isChangingQuestion}
            />
          </AttachmentsField>
        ) : null}
        <DSField
          for="points"
          label="Points"
          disabled={isChangingQuestion || question.isRandom}
        >
          <DSInput
            id="points"
            type="number"
            min={1}
            value={question.points}
            onChange={event => {
              if (isNaN(+event.target.value)) return;
              updateQuestionProperties(question.id, {
                points: +event.target.value,
              });
              amplitude?.logEvent('question creation/change points', {
                'job opening id': jobOpening.id,
                'test id': test.id,
                points: +event.target.value,
              });
            }}
            disabled={
              isChangingQuestion || (question.isRandom && !isOpenEndedQuestion)
            }
          />
        </DSField>
      </QuestionSettings>
      {showRandomMessage ? (
        <RandomQuestionMessage>
          <p>
            Randomized question: this is an example of a question that would be
            shown for this skill and difficulty
          </p>
          <Icon
            icon={regular('info-circle')}
            color="purple-60"
            fontSize="12px"
            data-rh="While maintaining the same difficulty, candidates are shown different questions to prevent cheating"
          />
        </RandomQuestionMessage>
      ) : null}
      <QuestionWrapper>
        <QuestionDescription>
          <Markdown source={getEditorMarkdown(description)} />
        </QuestionDescription>
        <QuestionAnswers
          answer={question.answer}
          options={question.options}
          type={question.questionType}
        />
        <QuestionNotes notes={question.notes} key={question.id} />
        <DeleteQuestionModal
          open={isConfirmModalOpen}
          onClose={() => {
            setIsConfirmModalOpen(false);
          }}
          questionId={question.id}
        />
      </QuestionWrapper>
    </DisabledCopyContainer>
  );
};
const QuestionWrapper = styled.div`
  overflow: auto;
  max-height: 100%;
`;

const RandomQuestionMessage = styled.div`
  padding: 12px 24px;
  background-color: ${props => props.theme.colors.orange[10]};
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
  }
`;

const ContextMenuWrapper = styled.div`
  position: relative;
`;

const AttachmentsField = styled(DSField)`
  grid-column: -3 / span 1;
  min-width: 130px;
`;
