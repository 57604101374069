import * as React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ScrollToTopOnMount from '@/components/scroll_to_top_on_mount/ScrollToTopOnMount';
import { useDocumentTitle } from '@/features/common';
import { Loader } from '@/features/common/components/loader/loader';

import { JobOpeningPageContentBlock } from './job-opening-page-content-block';

interface JobOpeningPageContentProps extends React.HTMLAttributes<any> {
  title: string;
  loading?: boolean;
  children: React.ReactNode;
}

export const JobOpeningPageContent = ({
  title,
  loading,
  children,
  ...props
}: JobOpeningPageContentProps) => {
  useDocumentTitle(title);

  return (
    <>
      <ScrollToTopOnMount />

      <Wrapper loading={loading} {...props}>
        {loading ? <Loader /> : children}
      </Wrapper>
    </>
  );
};

JobOpeningPageContent.Block = JobOpeningPageContentBlock;

const Wrapper = styled.div<{ loading?: boolean }>`
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  @media all and (max-width: 768px) {
    padding: 16px;
  }

  ${props =>
    props.loading &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;
