import React from 'react';
import styled from '@emotion/styled';

import { SemanticButton } from '@/features/common';
import { useJobOpeningPermission } from '@/features/permissions';

interface NewPipelineStageButtonProps {
  onClick: () => void;
}

export function NewPipelineStageButton({
  onClick,
}: NewPipelineStageButtonProps) {
  const canUpdate = useJobOpeningPermission()('job opening', 'update');

  if (!canUpdate) {
    return null;
  }

  return (
    <Button onClick={onClick} type="button">
      + New stage
    </Button>
  );
}

const Button = styled(SemanticButton)`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.purple[100]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: flex-start;
  padding: 0 8px;
  margin-top: 8px;
  text-align: left;
  width: 292px;
  align-items: center;

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.peach[50]};
  }
`;
