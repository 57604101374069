import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DSButton } from '@hundred5/design-system';

import {
  useCandidateQuery,
  useRejectCandidateMutation,
  isCandidateAnonymizedRecommendation,
} from '@/features/candidate';
import { Icon, TId } from '@/features/common';
import { useEmailModalContext } from '@/features/email';
import { usePlanLimits } from '@/hooks/planLimits';
import { useUpsellModal } from '@/hooks/upsellModal';

//region Main
interface RejectButtonProps {
  candidateId: TId;
}

export function RejectButton(props: RejectButtonProps) {
  const { data: candidate } = useCandidateQuery(props);
  const rejectCandidateMutation = useRejectCandidateMutation();
  const canAccessFeature = usePlanLimits();
  const { openEmailModal } = useEmailModalContext();
  const { openUpsellModal } = useUpsellModal();

  const handleClick = () => {
    if (!canAccessFeature('reject_candidates') || !candidate?.isUnlocked) {
      openUpsellModal('premium_feature');
    } else {
      openEmailModal({
        type: 'rejection',
        meta: {
          candidateId: candidate.id,
        },
      });
    }
  };

  if (!candidate) {
    return null;
  }

  return candidate.hireState !== 'archived' &&
    candidate.hireState !== 'rejected' ? (
    <DSButton
      type="button"
      variant="secondary"
      data-rh={
        isCandidateAnonymizedRecommendation(candidate)
          ? 'All actions will be unlocked after the candidate takes the test.'
          : 'Reject candidate'
      }
      disabled={
        rejectCandidateMutation.isLoading ||
        isCandidateAnonymizedRecommendation(candidate)
      }
      onClick={handleClick}
      Icon={<Icon icon={regular('thumbs-down')} />}
    />
  ) : null;
}

//endregion
