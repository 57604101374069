import {
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { TId } from '@/features/common';

import {
  fetchJobOpeningAccessRights,
  updateJobOpeningAccessRights,
} from '../api';

export const JOB_OPENING_ACCESS_RIGHTS_QUERY_KEY = [
  'job-opening-access-rights',
];

export const useJobOpeningAccessRightsQuery = (
  jobOpeningId: TId,
  options?: UseQueryOptions
) => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(JOB_OPENING_ACCESS_RIGHTS_QUERY_KEY, {
    onSuccess: () =>
      queryClient.invalidateQueries(JOB_OPENING_ACCESS_RIGHTS_QUERY_KEY),
  });

  return useQuery(
    [...JOB_OPENING_ACCESS_RIGHTS_QUERY_KEY],
    () => fetchJobOpeningAccessRights(jobOpeningId),
    options as any
  );
};

export const useUpdateJobOpeningAccessRightsMutation = () => {
  return useMutation(updateJobOpeningAccessRights, {
    mutationKey: JOB_OPENING_ACCESS_RIGHTS_QUERY_KEY,
  });
};
