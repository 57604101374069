import React, { useState } from 'react';

export const useBulkActionModal = (onCloseModal?: () => void) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = React.useCallback(() => {
    setIsOpen(false);
    onCloseModal?.();
  }, [setIsOpen, onCloseModal]);

  return { isOpen, setIsOpen, handleCloseModal };
};
