import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { PIPELINE_CATEGORIES_KEY } from '@/features/pipeline';

import { createTestFromTemplate } from '../api';

export const useCreateTestFromTemplateMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(createTestFromTemplate, {
    onSuccess(test) {
      queryClient.invalidateQueries({
        queryKey: [...PIPELINE_CATEGORIES_KEY, test.jobOpeningId],
      });
    },
    ...(options as any),
  });
};
