import React from 'react';
import styled from '@emotion/styled';
import {
  DSColumn,
  DSContentBlock,
  DSField,
  DSGrid,
  DSRow,
} from '@hundred5/design-system';
import formatDate from 'date-fns/format';
import isFuture from 'date-fns/is_future';
import { capitalize } from 'lodash';

import { useSubscriptionQuery } from '@/features/billing';
import { useWorkspace, useWorkspaceStatsQuery } from '@/features/workspace';
import { getPlanLimit, getPlanName, getPlanPeriod } from '@/hooks/planLimits';
import { useWorkspaceId } from '@/hooks/router';

import { getNextPaymentTotalAmount } from './workspace-settings-billing-my-plan.utils';

export const WorkspaceSettingsBillingMyPlan = () => {
  const workspace = useWorkspace();
  const workspaceId = useWorkspaceId();
  const workspaceStatsQuery = useWorkspaceStatsQuery({ workspaceId });
  const unlockedTestTakers = workspaceStatsQuery.data?.unlockedTestTakers || 0;
  const testTakersCredits = workspaceStatsQuery.data?.credits || 0;
  const { data: billingAccount } = useSubscriptionQuery();

  if (!billingAccount) return null;

  const currentPlan = billingAccount
    ? billingAccount.subscription.currentPlan
    : null;
  const nextPaymentAmount = new Intl.NumberFormat('en-US').format(
    getNextPaymentTotalAmount(billingAccount) / 100
  );

  const activePlan =
    currentPlan || workspace?.subscriptionPlan || 'free-v2-2023';

  const candidatesLimit = getPlanLimit(activePlan, 'unlockable_candidates');

  const subscriptionActive = billingAccount
    ? billingAccount.subscription.state === 'active'
    : false;
  const subscriptionEnd = billingAccount?.subscription.currentPeriodEndsAt
    ? formatDate(billingAccount.subscription.currentPeriodEndsAt, 'MMM D, YYYY')
    : null;

  const subscriptionPausedFrom =
    workspace?.subscriptionPausedFrom &&
    formatDate(workspace.subscriptionPausedFrom, 'MMM D, YYYY');
  const subscriptionPausedUntil =
    workspace?.subscriptionPausedUntil &&
    formatDate(workspace.subscriptionPausedUntil, 'MMM D, YYYY');
  const subscriptionPausedInFuture =
    workspace?.subscriptionPausedFrom &&
    isFuture(workspace.subscriptionPausedFrom);

  let subscriptionInfo = 'Your subscription ';
  if (subscriptionPausedUntil) {
    subscriptionInfo += subscriptionPausedInFuture
      ? 'will be paused'
      : 'is paused';
    subscriptionInfo += ` from ${subscriptionPausedFrom} and will
        automatically resume on ${subscriptionPausedUntil}. `;
  } else {
    subscriptionInfo +=
      (subscriptionActive ? 'automatically renews' : 'ends') +
      ` on ${subscriptionEnd}.`;
  }

  return (
    <DSContentBlock>
      <DSContentBlock.Title>
        My plan
        {!/free/.test(activePlan) && <PlanInfo>{subscriptionInfo}</PlanInfo>}
      </DSContentBlock.Title>
      <DSContentBlock.Content>
        <DSGrid>
          <DSRow gap="16px" xsWrap>
            <DSColumn size={12} gap="16px">
              <DSField for="plan" label="Plan">
                {`${capitalize(getPlanName(activePlan))} ${getPlanPeriod(
                  activePlan
                )}`}
              </DSField>
              <DSField for="candidates" label="Candidates">
                {candidatesLimit === Number.MAX_VALUE
                  ? 'unlimited'
                  : `${unlockedTestTakers}/${testTakersCredits}`}
              </DSField>
            </DSColumn>
            {subscriptionActive && !/free/.test(activePlan) && (
              <DSColumn size={12} gap="16px">
                <DSField for="payment" label="Next payment due">
                  {subscriptionEnd}
                </DSField>
                <DSField for="price" label="Total amount">
                  ${nextPaymentAmount}
                </DSField>
              </DSColumn>
            )}
          </DSRow>
        </DSGrid>
      </DSContentBlock.Content>
    </DSContentBlock>
  );
};

const PlanInfo = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.typography.colorSecondary};
`;
