import React from 'react';
import { DSCheckbox } from '@hundred5/design-system';

import { useCandidateSearchParams } from '@/features/candidate-search/hooks';

import { ACTION_REQUIRED_FILTER_TYPE } from '../../action-required-filter';
import { DeleteButton } from '../../buttons';
import { FilterListItem } from '../filter-list-item';

export function ActionRequiredListItem() {
  const { searchParams, removeSearchParam } = useCandidateSearchParams();
  const { actionRequired } = searchParams;

  if (!actionRequired) return null;

  return (
    <FilterListItem>
      <DSCheckbox checked />
      Action required
      <DeleteButton
        onClick={() => removeSearchParam(ACTION_REQUIRED_FILTER_TYPE)}
      />
    </FilterListItem>
  );
}
