import { schema } from 'normalizr';

export const workspace = new schema.Entity('workspaces');

export const user = new schema.Entity('users', {
  workspaces: [workspace],
});

export const workspaceUser = new schema.Entity(
  'workspaceUsers',
  { user },
  {
    idAttribute: value => value.user.id,
  }
);

export const invitation = new schema.Entity('invitations', {
  workspace,
  inviter: user,
});

export const questionOption = new schema.Entity(
  'questionOptions',
  {},
  {
    idAttribute(value, parent) {
      if (!parent.isStatic) {
        return `${value.id}:${parent.id}`;
      }
      return value.id;
    },
    processStrategy(value, parent) {
      return { ...value, question: parent.id };
    },
  }
);

export const question = new schema.Entity('questions', {
  options: [questionOption],
  sampleQuestion: {
    options: [questionOption],
  },
});

export const answer = new schema.Entity(
  'answers',
  {},
  {
    idAttribute(value, parent) {
      return `${parent.candidateId}-${value.questionId}`;
    },
    processStrategy(value, parent) {
      return {
        ...value,
        candidateId: parent.candidateId,
        testId: parent.testId,
      };
    },
  }
);

export const tag = new schema.Entity('tags');

export const candidateQuestionOption = new schema.Entity(
  'candidateQuestionOptions',
  {},
  {
    idAttribute: 'id',
    processStrategy(value, parent) {
      return { ...value, questionId: parent.id };
    },
  }
);

export const candidateQuestion = new schema.Entity(
  'candidateQuestions',
  {
    options: [candidateQuestionOption],
  },
  {
    idAttribute: 'id',
    processStrategy(value, parent) {
      return {
        ...value,
        candidateId: parent.candidateId,
        testId: parent.testId,
      };
    },
  }
);

export const candidateTest = new schema.Entity(
  'candidateTests',
  {
    answers: [answer],
    questions: [candidateQuestion],
  },
  {
    idAttribute(value, parent) {
      return `${parent.id}-${value.testId}`;
    },
    processStrategy(value, parent) {
      return {
        ...value,
        candidateId: parent.id,
        jobOpeningId: parent.jobOpeningId,
      };
    },
  }
);

export const candidate = new schema.Entity('candidates', {
  tests: [candidateTest],
  tags: [tag],
});

export const emailVariant = new schema.Entity(
  'emailVariants',
  {},
  {
    idAttribute(value, parent) {
      return value.variantId;
    },
    processStrategy(value, parent) {
      return { ...value, id: value.variantId, emailsId: parent.id };
    },
  }
);

export const emails = new schema.Entity('emails', {
  variants: [emailVariant],
});
