import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import {
  addTagToCandidate,
  CANDIDATE_HISTORY_QUERY_KEY,
  CANDIDATE_QUERY_KEY,
  ICandidate,
  removeTagFromCandidate,
} from '@/features/candidate';
import { PIPELINE_CANDIDATES_KEY } from '@/features/pipeline/queries/pipeline-candidates';

export const CANDIDATE_TAG_QUERY_KEY = ['candidate', 'tag'];

export const useAddTagToCandidateMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(addTagToCandidate, {
    onSuccess: (data, { tagId, tagName, candidateId }) => {
      queryClient.setQueryData(
        [...CANDIDATE_QUERY_KEY, candidateId],
        (oldCandidate: ICandidate) => ({
          ...oldCandidate,
          tags: [
            ...(oldCandidate.tags || []),
            {
              id: tagId,
              name: tagName,
            },
          ],
        })
      );

      queryClient.invalidateQueries(CANDIDATE_HISTORY_QUERY_KEY);
      queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);
    },
    ...(options as any),
  });
};

export const useRemoveTagFromCandidateMutation = (
  options?: UseMutationOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(removeTagFromCandidate, {
    onSuccess: (data, { tagId, candidateId }) => {
      queryClient.setQueryData(
        [...CANDIDATE_QUERY_KEY, candidateId],
        (oldCandidate: ICandidate) => ({
          ...oldCandidate,
          tags: oldCandidate.tags?.filter(tag => tag.id !== tagId) || [],
        })
      );

      queryClient.invalidateQueries(CANDIDATE_HISTORY_QUERY_KEY);
      queryClient.invalidateQueries(PIPELINE_CANDIDATES_KEY);
    },
    ...(options as any),
  });
};
