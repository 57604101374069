import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { createWorkspaceTag, fetchWorkspaceTags } from '@/features/workspace';
import { useWorkspaceId } from '@/hooks/router';

export const WORKSPACE_TAGS_QUERY_KEY = ['workspace', 'tags'];

export const useWorkspaceTagsQuery = (options?: UseQueryOptions) => {
  const workspaceId = useWorkspaceId();

  return useQuery(
    [...WORKSPACE_TAGS_QUERY_KEY, workspaceId],
    () => fetchWorkspaceTags({ workspaceId }),
    options as any
  );
};

export const useCreateWorkspaceTagMutation = (options?: UseMutationOptions) => {
  const queryClient = useQueryClient();
  const workspaceId = useWorkspaceId();

  return useMutation(createWorkspaceTag, {
    onSuccess: () => {
      queryClient.invalidateQueries([...WORKSPACE_TAGS_QUERY_KEY, workspaceId]);
    },
    ...(options as any),
  });
};
