import api from '@/api/api';
import { TId } from '@/features/common';
import {
  IJobOpeningApplicationForm,
  TJobOpeningApplicationFormQuestionEdit,
  IJobOpeningApplicationFormQuestion,
  TJobOpeningApplicationFormFields,
} from '@/features/job-opening';
import { parseAPIData, serialiseAPIData } from '@/utils/parsers';

export async function fetchJobOpeningApplicationForm(params: {
  jobOpeningId: TId;
}): Promise<IJobOpeningApplicationForm> {
  const response = await api.get(
    `/openings/${params.jobOpeningId}/applicationform`
  );
  return parseAPIData<IJobOpeningApplicationForm>(response.data);
}

export async function updateJobOpeningApplicationForm(params: {
  jobOpeningId: TId;
  applicationForm: TJobOpeningApplicationFormFields;
}): Promise<IJobOpeningApplicationForm> {
  const response = await api.put(
    `/openings/${params.jobOpeningId}/applicationform`,
    serialiseAPIData(params.applicationForm)
  );
  return parseAPIData<IJobOpeningApplicationForm>(response.data);
}

export async function createJobOpeningApplicationFormQuestion(params: {
  jobOpeningId: TId;
  question: TJobOpeningApplicationFormQuestionEdit;
}): Promise<IJobOpeningApplicationFormQuestion> {
  const response = await api.post(
    `/openings/${params.jobOpeningId}/applicationquestions`,
    serialiseAPIData(params.question)
  );
  return parseAPIData<IJobOpeningApplicationFormQuestion>(response.data);
}

export async function updateJobOpeningApplicationFormQuestion(params: {
  jobOpeningId: TId;
  questionId: TId;
  question: TJobOpeningApplicationFormQuestionEdit;
}): Promise<IJobOpeningApplicationFormQuestion> {
  const response = await api.put(
    `/openings/${params.jobOpeningId}/applicationquestions/${params.questionId}`,
    serialiseAPIData(params.question)
  );
  return parseAPIData<IJobOpeningApplicationFormQuestion>(response.data);
}

export async function deleteJobOpeningApplicationFormQuestion(params: {
  jobOpeningId: TId;
  questionId: TId;
}): Promise<void> {
  await api.delete(
    `/openings/${params.jobOpeningId}/applicationquestions/${params.questionId}`
  );
}
