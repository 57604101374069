import React from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import formatDate from 'date-fns/format';

import { useCandidateSearchParams } from '@/features/candidate-search';
import { DATE_FILTER_TYPE } from '@/features/candidate-search/components';
import { Icon } from '@/features/common';

import { DeleteButton } from '../../buttons';
import { FilterListItem } from '../filter-list-item';

const DATE_FORMAT = 'MMM D, YYYY';
export const DateListItem = () => {
  const { searchParams, removeSearchParam } = useCandidateSearchParams();
  const { date } = searchParams;

  if (!date) return null;

  const dates = date.split('--');
  const isSameDate = dates[0] === dates[1];

  if (isSameDate) {
    return (
      <FilterListItem>
        <Icon icon={solid('calendar-day')} color="orange-100" />
        {formatDate(dates[0], DATE_FORMAT)}
        <DeleteButton onClick={() => removeSearchParam(DATE_FILTER_TYPE)} />
      </FilterListItem>
    );
  }

  return (
    <FilterListItem>
      <Icon icon={solid('calendar-day')} color="orange-100" />
      {formatDate(dates[0], DATE_FORMAT)} - {formatDate(dates[1], DATE_FORMAT)}
      <DeleteButton onClick={() => removeSearchParam(DATE_FILTER_TYPE)} />
    </FilterListItem>
  );
};
