import React from 'react';
import {
  DSButton,
  DSColumn,
  DSContentBlock,
  DSRow,
} from '@hundred5/design-system';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { FormikInputField, PromptIfDirty } from '@/features/common';
import { DASHBOARD_JOB_OPENINGS_QUERY_KEY } from '@/features/dashboard';
import { useFlashMessages } from '@/features/flash-messages';
import {
  useJobOpeningQuery,
  useUpdateJobOpeningMutation,
} from '@/features/job-opening';
import { useJobOpeningPermission } from '@/features/permissions';
import { useJobOpeningId } from '@/hooks/router';
import { queryClient } from '@/utils/reactQuery';

type Settings = { name: string; contactEmail: string };

const validationSchema = object().shape({
  name: string().required("This can't be blank!"),
  contactEmail: string()
    .required("This can't be blank!")
    .email('Enter a valid email!'),
});

export function JobOpeningSettingsGeneral() {
  const jobOpeningId = useJobOpeningId();
  const canUpdate = useJobOpeningPermission()('job opening', 'update');
  const { showFlashMessage } = useFlashMessages();

  const jobOpeningQuery = useJobOpeningQuery();
  const jobOpeningMutation = useUpdateJobOpeningMutation({
    jobOpeningId,
    onSuccess: () => {
      showFlashMessage({ type: 'saved' });
      queryClient.invalidateQueries(DASHBOARD_JOB_OPENINGS_QUERY_KEY);
    },
  });

  const initialSettings: Settings = {
    name: jobOpeningQuery.data?.name || '',
    contactEmail: jobOpeningQuery.data?.contactEmail || '',
  };

  const handleSubmit = async (values: Settings) => {
    await jobOpeningMutation.mutateAsync({ jobOpeningId, settings: values });
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialSettings}
      validationSchema={validationSchema}
    >
      {({ dirty }) => (
        <Form>
          <PromptIfDirty />
          <DSContentBlock>
            <DSContentBlock.Title>General</DSContentBlock.Title>
            <DSContentBlock.Content>
              <DSRow gap="12px" xsWrap>
                <DSColumn size={12} xs={24}>
                  <FormikInputField
                    name="name"
                    label="Job title"
                    required
                    errorAbsolute
                    disabled={!canUpdate}
                  />
                </DSColumn>
                <DSColumn size={12} xs={24}>
                  <FormikInputField
                    name="contactEmail"
                    label="Contact email"
                    required
                    data-recording-ignore="mask"
                    errorAbsolute
                    disabled={!canUpdate}
                  />
                </DSColumn>
              </DSRow>
            </DSContentBlock.Content>
            {canUpdate && (
              <DSContentBlock.Footer>
                <DSButton type="submit" disabled={!dirty}>
                  Save changes
                </DSButton>
              </DSContentBlock.Footer>
            )}
          </DSContentBlock>
        </Form>
      )}
    </Formik>
  );
}
