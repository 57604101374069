import React from 'react';
import styled from '@emotion/styled';

import Tooltip from '@/components/tooltip/Tooltip';
import { useWorkspaceId } from '@/hooks/router';

export function EmailTooltip() {
  const workspaceId = useWorkspaceId();

  return (
    <Tooltip
      delay={{ show: 0, hide: 500 }}
      attribute="data-test-email-tooltip"
      persist
      onRenderContent={(_, content) => {
        return (
          <TooltipContent>
            To connect your email, first{' '}
            <Link href={`/admin/${workspaceId}/settings/integrations`}>
              enable the Gmail integration
            </Link>{' '}
            for your workspace.
          </TooltipContent>
        );
      }}
    />
  );
}

const TooltipContent = styled.div`
  width: 462spx;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 12px;
  color: ${props => props.theme.typography.colorPrimary};
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow.popup};
`;

const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.typography.colorPrimary};
`;
