import React, { useEffect, useState } from 'react';

import { useCardInfoQuery, useSubscribeModal } from '@/features/billing';

import { ExistingCard, LoadingCard, NoCard, UpdateCardFormModal } from './ui';

export const CardDetails = () => {
  const [editingCardInfo, setEditingCardInfo] = useState(false);
  const { isSubscribeModalOpen } = useSubscribeModal();

  const { data: cardInfo, isLoading } = useCardInfoQuery();

  const startEditing = () => setEditingCardInfo(true);
  const finishEditing = () => setEditingCardInfo(false);

  useEffect(() => void (isSubscribeModalOpen && finishEditing()), [
    isSubscribeModalOpen,
  ]);

  return (
    <>
      {isLoading ? (
        <LoadingCard />
      ) : !cardInfo ? (
        <NoCard onClick={startEditing} />
      ) : (
        <ExistingCard cardInfo={cardInfo} onEdit={startEditing} />
      )}
      <UpdateCardFormModal
        open={editingCardInfo}
        onCancel={finishEditing}
        onFinish={finishEditing}
      />
    </>
  );
};
