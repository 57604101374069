import api from '@/api/api';
import { TId } from '@/features/common';
import { parseAPIData } from '@/utils/parsers';

import { IQuestion } from '../types';

export async function fetchQuestions({
  testId,
}: {
  testId: TId;
}): Promise<IQuestion[]> {
  const response = await api.get(`/tests/${testId}/questions`);

  if (response.data.questions === null) {
    return [];
  }

  return parseAPIData(response.data);
}

export async function sortQuestions({
  testId,
  questionIds,
}: {
  testId: TId;
  questionIds: TId[];
}) {
  await api.put(
    `/tests/${testId}/questions/order`,
    questionIds.map(id => +id)
  );
}
