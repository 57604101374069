import React from 'react';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Icon, SemanticButton } from '@/features/common';

interface IPipelinesInfoProps {
  onClose: () => void;
}

export function PipelinesInfo({ onClose }: IPipelinesInfoProps) {
  return (
    <Container>
      <SemanticButton onClick={onClose}>
        <Icon icon={regular('xmark')} color="purple-60" />
      </SemanticButton>
      <h3>This is your hiring pipeline</h3>
      <p>
        You can add a test or interview to each stage and keep track of
        candidates during the hiring process
      </p>
    </Container>
  );
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.peach[50]};
  border-radius: 8px;
  margin: 12px 8px 0 8px;
  padding: 12px;
  position: relative;

  h3 {
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 0;
  }

  p {
    color: ${({ theme }) => theme.colors.purple[60]};
    font-size: 14px;
    margin: 0;
  }

  ${SemanticButton} {
    padding: 4px;
    position: absolute;
    right: 8px;
    top: 4px;
  }
`;
