import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DSButton } from '@hundred5/design-system';
import differenceInDays from 'date-fns/difference_in_days';
import { useMedia } from 'react-use';

import * as candidatesApi from '@/api/candidates';
import { ConfirmModal, TId } from '@/features/common';
import {
  JobOpeningFeedbackModal,
  useCloseJobOpeningMutation,
} from '@/features/job-opening';
import { useMeQuery } from '@/features/me';
import { deleteSortStorage } from '@/features/pipeline/utils/storage';
import { useIsPaidPlan } from '@/hooks/planLimits';
import { useHistory, useWorkspaceId } from '@/hooks/router';

import { RockIcon } from './ui';

interface CloseJobOpeningModalProps {
  jobOpeningId: TId;
  open: boolean;
  onClose: () => void;
}

export const CloseJobOpeningModal = ({
  jobOpeningId,
  open,
  onClose,
}: CloseJobOpeningModalProps) => {
  const isMobile = useMedia('(max-width: 768px)');
  const isPaidPlan = useIsPaidPlan();
  const [skipConfirmation, setSkipConfirmation] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [askFeedback, setAskFeedback] = useState(true);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const meQuery = useMeQuery();
  const closeJobOpeningMutation = useCloseJobOpeningMutation({ jobOpeningId });

  const handleCloseJobOpening = useCallback(async () => {
    await closeJobOpeningMutation.mutateAsync({ jobOpeningId });
    deleteSortStorage(jobOpeningId);
    setShowConfirmationModal(false);
    setShowFeedbackModal(askFeedback);

    if (!askFeedback) {
      onClose();
    }
  }, [closeJobOpeningMutation, jobOpeningId, askFeedback, onClose]);

  useEffect(() => {
    if (open) {
      candidatesApi
        .fetchJobOpeningCandidates(jobOpeningId, {
          candidateFilter: 'all',
          sortOrder: 'weight',
          sortDirection: 'asc',
          excludeDemoTestTakers: true,
          excludeUserTestTakers: true,
        })
        .then(res => {
          const isUser7daysAfterSignUp =
            meQuery.data?.createdAt &&
            differenceInDays(new Date(meQuery.data?.createdAt), Date.now()) >=
              7;
          if (res.totalCandidates >= 3) {
            setAskFeedback(true);
          }
          if (isUser7daysAfterSignUp === false && res.totalCandidates === 0) {
            setSkipConfirmation(true);
          } else {
            setSkipConfirmation(false);
            setShowConfirmationModal(true);
          }
        })
        .catch(() => {
          setSkipConfirmation(true);
          setShowConfirmationModal(false);
        });
    }
  }, [jobOpeningId, open, meQuery.data?.createdAt]);

  useEffect(() => {
    if (open && skipConfirmation) {
      handleCloseJobOpening();
      setSkipConfirmation(false);
    }
  }, [handleCloseJobOpening, open, skipConfirmation]);

  return (
    <div
      onClick={event => {
        // This is needed because the modal is now inside the JO row on job openings list,
        // and a click on the modal would trigger a click on the row, which would open the JO details
        event.stopPropagation();
      }}
    >
      <ConfirmModal
        open={showConfirmationModal}
        action="Close"
        confirmation="close"
        onConfirm={() => {
          handleCloseJobOpening();
        }}
        onClose={() => {
          setShowConfirmationModal(false);
          onClose();
        }}
        contentStyle={{ display: 'flex' }}
        sideContent={!isPaidPlan && !isMobile && <UpsellContent />}
        title="You’re about to close this job opening"
      >
        <SubTitle>Are you sure?</SubTitle>
        You won’t be able to access the candidate info under this test.
        Candidates will no longer be able to apply for this job opening. And you
        cannot reopen it.
      </ConfirmModal>
      <JobOpeningFeedbackModal
        open={showFeedbackModal}
        jobOpeningId={jobOpeningId}
        onClose={() => {
          setShowFeedbackModal(false);
          onClose();
        }}
      />
    </div>
  );
};

const UpsellContent = () => {
  const workspaceId = useWorkspaceId();
  const history = useHistory();
  return (
    <UpsellContentContainer>
      <RockIcon />
      <span>Want to keep access?</span>
      Upgrade to Basic plan to keep access to all your candidates for 199$ per
      year.
      <DSButton
        variant="secondary"
        type="button"
        onClick={() => history.push(`/admin/${workspaceId}/settings/billing`)}
      >
        Upgrade
      </DSButton>
    </UpsellContentContainer>
  );
};

const UpsellContentContainer = styled.div`
  display: flex;
  width: 152px;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  line-height: 150%;
  padding-top: 16px;
  text-align: center;

  span {
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  button {
    margin-top: auto;
  }
`;

const SubTitle = styled.p`
  margin: 24px 0 12px 0;
  font-weight: 700;
`;
