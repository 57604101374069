import React from 'react';
import { DragOverlay } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import styled from '@emotion/styled';
import { createPortal } from 'react-dom';

import { TId } from '@/features/common';
import { IPipelineCandidate } from '@/features/pipeline/types';

import { CandidateBox } from '../candidate-box';

interface CandidateDragOverlayProps {
  currentlyDraggedCandidate?: IPipelineCandidate | null;
  selectedCandidatesIds: TId[];
}

export function CandidateDragOverlay({
  currentlyDraggedCandidate,
  selectedCandidatesIds,
}: CandidateDragOverlayProps) {
  return createPortal(
    <DragOverlay modifiers={[snapCenterToCursor]} dropAnimation={null}>
      <OverlayInnerContainer style={{ transform: 'rotate(-12deg)' }}>
        {currentlyDraggedCandidate ? (
          <>
            <CandidateBox
              candidate={currentlyDraggedCandidate}
              onClick={() => {}}
              onInvite={() => {}}
              onResendInvitation={() => {}}
              onReset={() => {}}
            />
            {selectedCandidatesIds.includes(currentlyDraggedCandidate.id) ? (
              <Counter>{selectedCandidatesIds.length}</Counter>
            ) : null}
          </>
        ) : null}
      </OverlayInnerContainer>
    </DragOverlay>,
    document.body
  );
}

const OverlayInnerContainer = styled.div`
  position: relative;
  transform: rotate(-12deg);
`;

const Counter = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.orange[100]};
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: -12px;
  width: 24px;
  z-index: 100;
`;
