import styled from '@emotion/styled';

export const CandidateContainer = styled.div`
  padding: 36px 36px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${props => props.theme.shadow.card};
`;
