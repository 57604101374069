import styled from '@emotion/styled';

export const DetailsContent = styled.div`
  padding-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.typography.colorPrimary};

  & strong {
    font-weight: 700;
  }
`;
