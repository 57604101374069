import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Field } from '@hundred5/design-system';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';

import {
  getCurrentMentions,
  useCandidateNotesQuery,
} from '@/features/candidate';
import { TId } from '@/features/common';
import { AutocompleteTextArea } from '@/features/common/components/autocomplete-text-area/autocomplete-text-area';
import { useJobOpeningPermission } from '@/features/permissions';
import { WorkspaceUser } from '@/features/workspace';

import { AddNoteButton, Note } from './ui';

export interface CandidateNotesProps {
  candidateId: TId;
}

export function CandidateNotes({ candidateId }: CandidateNotesProps) {
  const { tabItemId: noteId } = useParams();
  const allowCandidateNotesCreating = !!useJobOpeningPermission()(
    'candidate notes',
    'create'
  );

  const [noteContent, setNoteContent] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState<WorkspaceUser[]>([]);

  const candidateNotesQuery = useCandidateNotesQuery({ candidateId });

  const scrollToNote = (noteId: string) => {
    const note = document.getElementById(`note-id-${noteId}`);
    if (note) {
      note.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (noteId && candidateNotesQuery.isSuccess) {
      scrollToNote(noteId);
    }
  }, [noteId, candidateNotesQuery.isSuccess]);

  return (
    <CandidateNotesContainer data-recording-ignore="mask">
      {!!!candidateNotesQuery.data?.notes?.length &&
      !allowCandidateNotesCreating ? (
        <EmptyState>There are no candidate notes yet.</EmptyState>
      ) : (
        candidateNotesQuery.data?.notes?.map(note => (
          <Note key={note.id} note={note} />
        ))
      )}

      <Prompt
        when={noteContent !== ''}
        message="You have unsaved note! Are you sure you want to navigate away?"
      />

      {allowCandidateNotesCreating ? (
        <Footer>
          <Field
            for="candidate-note-form-add-note"
            input={
              <AutocompleteTextArea
                placeholder="Add a note about the candidate here. Use @ to mention your colleagues"
                value={noteContent}
                onChange={setNoteContent}
                onSelect={user => {
                  setMentionedUsers(prev => [...prev, user]);
                }}
              />
            }
          />

          <AddNoteButtonWrapper>
            <AddNoteButton
              candidateId={candidateId}
              note={noteContent}
              onAdded={() => {
                setNoteContent('');
                setMentionedUsers([]);
              }}
              userIds={getCurrentMentions(noteContent, mentionedUsers)}
            />
          </AddNoteButtonWrapper>
        </Footer>
      ) : null}
    </CandidateNotesContainer>
  );
}

//region Styles
const CandidateNotesContainer = styled.article`
  background-color: ${props => props.theme.colors.white};
  padding-bottom: 25px;

  @media all and (max-width: 768px) {
    padding-bottom: 20px;
  }

  > article:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const EmptyState = styled.div`
  padding-top: 20px;
  text-align: center;
  color: ${props => props.theme.typography.colorSecondary};
`;

const Footer = styled.div`
  padding: 20px 36px 0;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 768px) {
    padding: 20px 16px 0;
  }
`;

const AddNoteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
//endregion
