import styled from '@emotion/styled';

export const Icon = styled.div`
  grid-area: icon;
  width: 46px;
  height: 46px;
  display: flex;
  align-self: center;
  justify-self: flex-start;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.purple[10]};
  background: ${props => props.theme.colors.white};
`;
