import configuration from '../configuration/runtime';

const accountId = configuration.hubspotAccountId;

if (accountId) {
  window._hsq = window._hsq || [];

  const script = document.createElement('script');
  script.src = `https://js.hs-scripts.com/${accountId}.js`;
  script.id = 'hs-script-loader';
  script.async = true;

  document.head.appendChild(script);
}
