import React from 'react';
import styled from '@emotion/styled';
import {
  DSButton,
  DSColumn,
  DSGrid,
  DSModal,
  DSRow,
} from '@hundred5/design-system';
import { Form, Formik } from 'formik';

import {
  useRemoveBillingInfoMutation,
  useSubscriptionQuery,
} from '@/features/billing';
import {
  FormikInputField,
  FormikSelectField,
  useCountryList,
} from '@/features/common';
import { useWorkspacePermission } from '@/features/permissions';
import { getPlanName } from '@/hooks/planLimits';

import { schema } from '../workspace-settings-billing-details.utils';

interface IEditBillingDetailsModalProps {
  open: boolean;
  submitting: boolean;
  billingInfoAttributes: any;
  onSubmit: (values: any) => void;
  onClose: () => void;
}

export const EditBillingDetailsModal = ({
  open,
  onClose,
  onSubmit,
  submitting,
  billingInfoAttributes,
}: IEditBillingDetailsModalProps) => {
  const { countries } = useCountryList();
  const { data: billingAccount } = useSubscriptionQuery();
  const isFreePlan =
    billingAccount?.subscription.currentPlan &&
    getPlanName(billingAccount?.subscription.currentPlan) === 'free';
  const canDeleteBillingInfo =
    isFreePlan || billingAccount?.subscription.state === 'active';
  const canEditBillingInfo = useWorkspacePermission()('billing info', 'update');
  const {
    mutate: removeBillingInfo,
    isLoading: isRemovingBillingInfo,
  } = useRemoveBillingInfoMutation();

  return (
    <DSModal fluid contentStyle={{ width: 640 }} open={open} onClose={onClose}>
      <Header>
        Edit billing details{' '}
        <DSModal.CloseButton onClick={onClose} fixed small />
      </Header>
      <DSModal.Separator />
      <Formik
        initialValues={billingInfoAttributes}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Form data-recording-ignore="mask">
          <Content data-recording-ignore="mask">
            <DSGrid gap="16px">
              <DSRow gap="16px" xsWrap>
                <DSColumn size={12}>
                  <FormikInputField
                    label="First name"
                    name="firstName"
                    required
                  />
                </DSColumn>
                <DSColumn size={12}>
                  <FormikInputField
                    label="Last name"
                    name="lastName"
                    required
                  />
                </DSColumn>
              </DSRow>
              <DSRow gap="16px" xsWrap>
                <DSColumn size={12}>
                  <FormikInputField label="VAT nuber" name="vatNumber" />
                </DSColumn>
                <DSColumn size={12}>
                  <FormikInputField label="Email" name="email" required />
                </DSColumn>
              </DSRow>

              <DSRow gap="16px" xsWrap>
                <DSColumn size={12}>
                  <FormikInputField
                    label="Street address name"
                    name="address"
                    required
                  />
                </DSColumn>
                <DSColumn size={12}>
                  <FormikInputField label="Apartment, suite" name="address2" />
                </DSColumn>
              </DSRow>

              <DSRow gap="16px" xsWrap>
                <DSColumn size={12}>
                  <FormikInputField label="City" name="city" required />
                </DSColumn>
                <DSColumn size={12}>
                  <FormikInputField label="State" name="state" />
                </DSColumn>
              </DSRow>
              <DSRow gap="16px" xsWrap>
                <DSColumn size={12}>
                  <FormikInputField
                    label="Postal code"
                    name="postalCode"
                    required
                  />
                </DSColumn>
                <DSColumn size={12}>
                  <FormikSelectField
                    label="Country"
                    name="country"
                    items={countries.map(country => ({
                      id: country.code,
                      label: country.name,
                      labelText: country.name,
                    }))}
                    required
                  />
                </DSColumn>
              </DSRow>
            </DSGrid>
          </Content>

          <Footer>
            <DSButton
              variant="tertiary"
              disabled={isRemovingBillingInfo || !canEditBillingInfo}
              data-rh={
                !canEditBillingInfo
                  ? 'Only owners can remove billing info'
                  : !canDeleteBillingInfo
                  ? 'Subscription needs to be canceled before clearing billing info'
                  : null
              }
              onClick={() => {
                removeBillingInfo();
              }}
            >
              {!isRemovingBillingInfo ? 'Remove info' : 'Removing info...'}
            </DSButton>
            <DSButton
              variant="primary"
              type="submit"
              disabled={submitting || !canEditBillingInfo}
              data-rh={
                !canEditBillingInfo ? 'Only owners can save changes' : null
              }
            >
              {submitting ? 'Saving...' : 'Save changes'}
            </DSButton>
          </Footer>
        </Form>
      </Formik>
    </DSModal>
  );
};

const Header = styled.div`
  font-weight: 700;
  font-size: ${16 / 14}rem;
  color: ${props => props.theme.typography.colorPrimary};
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Content = styled.div`
  margin-top: 16px;
`;

const Footer = styled(DSModal.Footer)`
  gap: 8px;
`;
