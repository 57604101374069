import React, { useRef, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import styled from '@emotion/styled';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  DSButton,
  DSContextMenu,
  DSContextMenuButton,
} from '@hundred5/design-system';
import { useMedia } from 'react-use';
import useOnClickOutside from 'use-onclickoutside';

import { useWorkspaceTreatments } from '@/components/splits';
import { getShareJobUpsellTooltipAttributes } from '@/components/upsell_tooltip/utils';
import { Icon } from '@/features/common';
import {
  CloseJobOpeningModal,
  DeleteJobOpeningModal,
  IJobOpening,
  useCloneJobOpeningMutation,
  useJobOpeningQuery,
  useSendForReview,
} from '@/features/job-opening';
import { useJobOpeningPermission } from '@/features/permissions';
import { useShareModal } from '@/features/share';
import { useTestsQuery } from '@/features/test';
import { useCanShareJobOpening, useJobOpeningsLimit } from '@/hooks/planLimits';
import { useHistory } from '@/hooks/router';
import { getJobOpeningPreviewUrl } from '@/utils/urls';

import {
  CandidatesExportModal,
  useCandidatesExportModal,
} from './candidates-export-modal';

export function SideMenu() {
  const { data: jobOpening } = useJobOpeningQuery();

  const { openShareModal } = useShareModal();
  const canAddCandidate = useJobOpeningPermission()('candidate', 'update');
  const showUpsell = !useCanShareJobOpening(jobOpening);
  const testIds =
    jobOpening?.pipelineStages?.reduce((stagesWithTestIds, stage) => {
      if (stage.testId) {
        stagesWithTestIds.push(stage.testId);
      }
      return stagesWithTestIds;
    }, [] as string[]) || [];
  const tests = useTestsQuery(
    {
      testIds,
    },
    {
      enabled: !!jobOpening,
    }
  );

  if (!jobOpening || !tests.every(test => test.data)) {
    return null;
  }

  return (
    <Container>
      {canAddCandidate && (
        <DSButton
          disabled={showUpsell || !!jobOpening.closedAt}
          onClick={() => {
            openShareModal(jobOpening.id);
            amplitude?.logEvent('job openings/invite', {
              'workspace id': jobOpening.workspaceId,
              'job opening id': jobOpening.id,
            });
          }}
          {...(!!jobOpening.closedAt
            ? {
                'data-rh':
                  "It's not possible to add candidates to an archived job.",
                'data-rh-at': 'bottom',
              }
            : showUpsell
            ? getShareJobUpsellTooltipAttributes(jobOpening, 'left')
            : null)}
          Icon={<Icon icon={regular('plus')} />}
        >
          Add candidate
        </DSButton>
      )}
      <HeaderContextMenu jobOpening={jobOpening} />
    </Container>
  );
}

const HeaderContextMenu = ({ jobOpening }: { jobOpening: IJobOpening }) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const { openShareModal } = useShareModal();
  const {
    state: exportModalState,
    open: openExportModal,
    close: closeExportModal,
  } = useCandidatesExportModal();
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const sendForReview = useSendForReview(jobOpening);
  const isMobile = useMedia('(max-width: 768px)');

  const belowLimit = useJobOpeningsLimit();
  const canCloneJobOpening =
    useJobOpeningPermission()('job opening', 'create') && belowLimit;
  const canCloseJobOpening = useJobOpeningPermission()('job opening', 'close');
  const canDeleteJobOpening = useJobOpeningPermission()(
    'job opening',
    'delete'
  );
  const cloneJobOpeningMutation = useCloneJobOpeningMutation();
  const hasTests = jobOpening.pipelineStages?.some(stage => stage.testId);

  const { fe_admin_app_job_description } = useWorkspaceTreatments([
    'fe_admin_app_job_description',
  ]);
  const isJobDescriptionEnabled =
    fe_admin_app_job_description.treatment === 'on';

  useOnClickOutside(ref, () => setIsOpen(false));

  return canCloneJobOpening || canCloseJobOpening || canDeleteJobOpening ? (
    <Wrapper ref={ref}>
      <DSContextMenuButton
        data-testid="job-opening-header-context-button"
        active={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      <DSContextMenu open={isOpen} position={{ top: '32px', right: '0' }}>
        {isMobile && !jobOpening.closedAt && (
          <DSContextMenu.Item
            label="Add candidate"
            icon={<Icon icon={regular('plus')} color="purple-60" />}
            onClick={() => openShareModal(jobOpening.id)}
          />
        )}
        {(hasTests || isJobDescriptionEnabled) && (
          <>
            <DSContextMenu.Item
              label="Preview Job"
              icon={<Icon icon={regular('check-to-slot')} color="purple-60" />}
              href={getJobOpeningPreviewUrl(
                jobOpening,
                undefined,
                window.location.href
              )}
              onClick={() =>
                amplitude?.logEvent('job openings/preview', {
                  'workspace id': jobOpening.workspaceId,
                  'job opening id': jobOpening.id,
                })
              }
            />
            <DSContextMenu.Item
              label="Send for review"
              icon={<Icon icon={regular('envelope')} color="purple-60" />}
              onClick={sendForReview}
            />
            <DSContextMenu.Delimiter />
          </>
        )}

        <DSContextMenu.Item
          label="Candidates CSV"
          icon={<Icon icon={regular('download')} color="purple-60" />}
          onClick={() => openExportModal('csv')}
        />

        <DSContextMenu.Item
          label="Candidates PDF"
          icon={<Icon icon={regular('download')} color="purple-60" />}
          onClick={() => openExportModal('pdf')}
        />

        <DSContextMenu.Delimiter />

        {canCloneJobOpening ? (
          <DSContextMenu.Item
            label={
              cloneJobOpeningMutation.isLoading
                ? 'Duplicating...'
                : 'Duplicate job'
            }
            disabled={cloneJobOpeningMutation.isLoading}
            icon={<Icon icon={regular('clone')} color="purple-60" />}
            onClick={async () => {
              const clonedJobOpening = await cloneJobOpeningMutation.mutateAsync(
                {
                  jobOpeningId: jobOpening.id,
                  name: `${jobOpening.name} copy`,
                }
              );

              setIsOpen(false);

              history.push(
                `/admin/${clonedJobOpening.workspaceId}/openings/${clonedJobOpening.id}/job-settings`
              );
            }}
          />
        ) : null}
        {canCloseJobOpening && (
          <DSContextMenu.Item
            label="Close job"
            icon={<Icon icon={regular('box-archive')} color="purple-60" />}
            onClick={() => {
              setIsCloseModalOpen(true);
              setIsOpen(false);
            }}
          />
        )}
        {canDeleteJobOpening && (
          <DSContextMenu.Item
            label="Delete job"
            icon={<Icon icon={regular('trash')} color="purple-60" />}
            dangerous={true}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setIsOpen(false);
            }}
          />
        )}
      </DSContextMenu>
      <CandidatesExportModal
        open={exportModalState.open}
        jobOpeningId={jobOpening.id}
        format={exportModalState.format}
        onClose={closeExportModal}
      />
      <CloseJobOpeningModal
        jobOpeningId={jobOpening.id}
        open={isCloseModalOpen}
        onClose={() => setIsCloseModalOpen(false)}
      />
      <DeleteJobOpeningModal
        open={isDeleteModalOpen}
        jobOpening={jobOpening}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </Wrapper>
  ) : null;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
`;
