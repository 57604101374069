export interface IFeedback {
  createdAt: Date;
  isPositive: boolean;
  area: string;
  feedback: string;
}

export interface IFeedbackResponse {
  feedback: IFeedback[] | null;
  total: number;
}

export enum TFeedbackArea {
  ThatATestIsPreferredOverResumes = 'That a test is preferred over resumes',
  TestQuestions = 'Test questions',
  GettingInstantFeedback = 'Getting instant feedback',
  OverallLookAndFeel = 'Overall look and feel',
  Other = 'Other',
}

export interface IFeedbackArea {
  area: TFeedbackArea;
  percentage: number;
}

export interface IFeedbackStats {
  positivePercentage: number;
  negativePercentage: number;
  positiveAreas: IFeedbackArea[] | null;
  negativeAreas: IFeedbackArea[] | null;
}

export interface IPublicFeedbackStats extends IFeedbackStats {
  totalTestsFinished: number;
}
