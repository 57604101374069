import { BillingAccount } from '@/features/billing';

export const getNextPaymentTotalAmount = (
  account: BillingAccount | null
): number => {
  if (
    account == null ||
    account.subscription == null ||
    account.subscription.state !== 'active'
  )
    return 0;

  const unitAmount = account.subscription.unitAmountInCents;
  const taxMultiplier = account.subscription.taxRate + 1;

  return unitAmount * taxMultiplier;
};
