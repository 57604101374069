import * as amplitude from '@amplitude/analytics-browser';

import { TId } from '@/features/common';

export function logOpenCandidateCard(workspaceId: TId, candidateId: TId) {
  amplitude?.logEvent('clicking open candidate card', {
    'workspace id': workspaceId,
    'candidate id': candidateId,
  });
}
