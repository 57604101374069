import styled from '@emotion/styled';

export const FilterName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  padding: 8px;

  color: ${({ theme }) => theme.colors.purple[60]};
  text-transform: uppercase;

  align-items: center;
`;
