import React, { useCallback, useMemo } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import styled from '@emotion/styled';

import { SortableList, TId } from '@/features/common';
import {
  IApplicationQuestionOption,
  TApplicationQuestionOptionEdit,
} from '@/features/job-opening';

import { QuestionAnswerAddOption, QuestionAnswerOption } from '.';

interface QuestionAnswerOptionsProps {
  type: 'single-choice' | 'multiple-choice';
  options: TApplicationQuestionOptionEdit[] | null;
  onChange: (options: TApplicationQuestionOptionEdit[]) => void;
}

const generateTempId = (): string => {
  return `new-${Date.now()}-${Math.random()
    .toString(36)
    .substring(2, 9)}`;
};

export const QuestionAnswerOptions = ({
  type,
  options,
  onChange,
}: QuestionAnswerOptionsProps) => {
  const optionsList: IApplicationQuestionOption[] = useMemo(() => {
    return (options || []).map<IApplicationQuestionOption>(option => ({
      ...option,
      id: option.id || generateTempId(),
    }));
  }, [options]);

  const handleOptionChanges = useCallback(
    (newOptions: IApplicationQuestionOption[]) => {
      // Remove temporary ids
      const updatedOtions =
        newOptions.map(option => {
          if (option.id?.startsWith('new-')) {
            const { id, ...rest } = option;
            return rest;
          }
          return option;
        }) ?? [];

      onChange(updatedOtions);
    },
    [onChange]
  );

  const handleOptionAdd = useCallback(
    (value: string) => {
      const newOptions = [
        ...optionsList,
        {
          id: generateTempId(),
          option: value,
          isCorrect: false,
          orderWeight: optionsList.length + 1,
        },
      ];
      newOptions.forEach((option, i) => (option.orderWeight = i + 1));
      handleOptionChanges(newOptions);
    },
    [optionsList, handleOptionChanges]
  );

  const handleOptionRemove = useCallback(
    (id: TId) => {
      const newOptions = optionsList.filter(option => option.id !== id);
      newOptions.forEach((option, i) => (option.orderWeight = i + 1));
      handleOptionChanges(newOptions);
    },
    [optionsList, handleOptionChanges]
  );

  const handleOrderChange = useCallback(
    (activeIndex: number, overIndex: number) => {
      const newOptions = arrayMove(optionsList, activeIndex, overIndex);
      newOptions.forEach((option, i) => (option.orderWeight = i + 1));
      handleOptionChanges(newOptions);
    },
    [optionsList, handleOptionChanges]
  );

  const handleValueChange = useCallback(
    (id: TId, value: string) => {
      const newOptions = optionsList.map(option =>
        option.id === id ? { ...option, option: value } : option
      );
      handleOptionChanges(newOptions);
    },
    [optionsList, handleOptionChanges]
  );

  const handleOptionSelect = useCallback(
    (id: TId, checked: boolean) => {
      const newOptions = optionsList.map(option => ({
        ...option,
        isCorrect:
          option.id === id
            ? checked
            : type !== 'single-choice' && option.isCorrect,
      }));
      handleOptionChanges(newOptions);
    },
    [optionsList, handleOptionChanges, type]
  );

  return (
    <QuestionAnswerOptionsContainer>
      <SortableList<IApplicationQuestionOption>
        items={optionsList}
        renderItem={option => {
          return (
            <QuestionAnswerOption
              key={option.id}
              id={option.id}
              type={type}
              value={option.option}
              isCorrect={option.isCorrect}
              onValueChange={value => handleValueChange(option.id, value)}
              onOptionRemove={() => handleOptionRemove(option.id)}
              onOptionSelect={checked => handleOptionSelect(option.id, checked)}
            />
          );
        }}
        onOrderChanged={handleOrderChange}
      />
      <QuestionAnswerAddOption type={type} onAdd={handleOptionAdd} />
    </QuestionAnswerOptionsContainer>
  );
};

const QuestionAnswerOptionsContainer = styled.div`
  display: grid;
  gap: 8px;
`;
