import { useCallback, useMemo } from 'react';
import { find, orderBy } from 'lodash';

import { TId } from '@/features/common';
import { useFlashMessages } from '@/features/flash-messages';
import { usePreferenceMutation, usePreferencesQuery } from '@/features/me';

import {
  useEmailTemplateVariablesQuery,
  useEmailTemplatesQuery,
} from '../queries';
import { IEmailTemplate, TEmailTemplateType } from '../types';
import { isSystemTemplate } from '../utils';

export const useEmailTemplates = () => {
  const { data: emailTemplates } = useEmailTemplatesQuery();

  const sortedEmailTemplates = emailTemplates?.length
    ? orderBy(
        emailTemplates.filter(template => template.status === 'active'),
        [
          template => {
            if (isSystemTemplate(template)) return 1; // system
            return 2; // others
          },
          template => template.name.toLowerCase(),
        ],
        ['asc']
      )
    : [];

  return sortedEmailTemplates;
};

export const useSelectedEmailTemplatesByTypeAndTestId = (
  testId: TId | undefined,
  templateType: TEmailTemplateType | undefined
) => {
  const { data: emailTemplates } = useEmailTemplatesQuery();
  const { data: userPreferences } = usePreferencesQuery();
  const updateUserPreference = usePreferenceMutation();

  /* 
  Get last used by the user email template for the given test and template type, select system template if user has not selected any.
  For 'contact-candidate' (and 'gmail-candidate') by default we don't select any system template. 
  */
  const currentEmailTemplate: IEmailTemplate | null = useMemo(
    () =>
      (userPreferences &&
        find(emailTemplates, {
          id: userPreferences.selectedEmailTemplatesByTypeAndTestId[
            `${templateType}_${testId!}`
          ]!,
        })) ||
      (templateType !== 'contact-candidate' &&
      templateType !== 'gmail-candidate' &&
      emailTemplates?.length
        ? emailTemplates.find(
            template =>
              isSystemTemplate(template) &&
              template.status === 'active' &&
              template.type === templateType
          ) || null
        : null),
    [userPreferences, emailTemplates, templateType, testId]
  );

  const setCurrentEmailTemplate = useCallback(
    (emailTemplateId: TId | null) => {
      userPreferences?.selectedEmailTemplatesByTypeAndTestId &&
        updateUserPreference.mutate({
          name: 'selectedEmailTemplatesByTypeAndTestId',
          value: {
            ...userPreferences.selectedEmailTemplatesByTypeAndTestId,
            [`${templateType}_${testId}`]: emailTemplateId,
          },
        });
    },
    [
      updateUserPreference,
      userPreferences?.selectedEmailTemplatesByTypeAndTestId,
      templateType,
      testId,
    ]
  );
  return {
    setCurrentEmailTemplate,
    currentEmailTemplate,
  };
};

export const useEmailTemplateVariables = () => {
  const { showFlashMessage } = useFlashMessages();

  const { data: emailTemplateVariables } = useEmailTemplateVariablesQuery();

  const emailTemplateVariablesUsedAreValid = useCallback(
    (textWithVariables: string) => {
      const variableRegex = /\[.*?\]/g;
      const usedVariables = textWithVariables.match(variableRegex);

      const invalidVariable = usedVariables?.find(
        usedVariable =>
          !emailTemplateVariables?.find(
            variable => `[${variable.placeholder}]` === usedVariable
          )
      );

      if (invalidVariable) {
        showFlashMessage({
          type: 'email_template_variable_error',
          variable: invalidVariable,
        });
        return false;
      }
      return true;
    },
    [emailTemplateVariables, showFlashMessage]
  );

  return {
    emailTemplateVariables: emailTemplateVariables || [],
    emailTemplateVariablesUsedAreValid,
  };
};
