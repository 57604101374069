import React from 'react';

export const PremiumUpsellIcon = props => (
  <svg
    width="214"
    height="128"
    viewBox="0 0 214 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M107 0C47.9013 0 -0.0078125 48.0077 -0.0078125 107.228H214.008C214.008 48.0077 166.099 0 107 0Z"
      fill="#FFC4BC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107 128.001C166.099 128.001 214.008 118.701 214.008 107.229C214.008 95.7569 166.099 86.457 107 86.457C47.9013 86.457 -0.0078125 95.7569 -0.0078125 107.229C-0.0078125 118.701 47.9013 128.001 107 128.001Z"
      fill="#FF897A"
    />
    <rect
      width="83.2974"
      height="61.7985"
      rx="2.75598"
      transform="matrix(0.655494 -0.755201 0.753851 0.657045 56.3145 75.1943)"
      fill="white"
    />
    <path
      d="M107.16 53.9822C107.97 53.4845 108.511 52.5815 108.511 51.5647C108.511 49.9934 107.238 48.7207 105.666 48.7207C104.095 48.7207 102.822 49.9934 102.822 51.5647C102.822 52.5886 103.363 53.4845 104.173 53.9822L100.099 62.1303C99.4523 63.4243 97.7743 63.7941 96.6438 62.8911L90.3087 57.8216C90.6642 57.3452 90.8775 56.7551 90.8775 56.1152C90.8775 54.5439 89.6048 53.2712 88.0335 53.2712C86.4622 53.2712 85.1895 54.5439 85.1895 56.1152C85.1895 57.6865 86.4622 58.9592 88.0335 58.9592C88.0477 58.9592 88.069 58.9592 88.0833 58.9592L91.3326 76.834C91.7236 78.9954 93.6078 80.5739 95.8119 80.5739H115.521C117.718 80.5739 119.602 79.0025 120 76.834L123.25 58.9592C123.264 58.9592 123.285 58.9592 123.299 58.9592C124.871 58.9592 126.144 57.6865 126.144 56.1152C126.144 54.5439 124.871 53.2712 123.299 53.2712C121.728 53.2712 120.455 54.5439 120.455 56.1152C120.455 56.7551 120.669 57.3452 121.024 57.8216L114.689 62.8911C113.559 63.7941 111.881 63.4243 111.234 62.1303L107.16 53.9822Z"
      fill="#FF897A"
    />
  </svg>
);
