import * as React from 'react';

const Context = React.createContext({ offsetTop: 0 });

export const Provider = ({ offsetTop, children }) => (
  <Context.Provider value={{ offsetTop }}>{children}</Context.Provider>
);

export const Consumer = Context.Consumer;

export default { Context, Provider, Consumer };
