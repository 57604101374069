import styled from '@emotion/styled';

export const CandidatesList = styled.div`
  flex: 1;

  .virtualized-infinite-scroll {
    width: 292px;
    --scrollbar-foreground: ${({ theme }) => theme.colors.purple[20]};
    --scrollbar-background: transparent;

    scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
    scrollbar-gutter: stable;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-foreground);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-background);
    }
  }
`;
