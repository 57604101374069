import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DSField } from '@hundred5/design-system';

import { getFieldId } from '../card-token-form.utils';

export const RecurlyField = ({
  name,
  label,
  required,
  hasRecurlyError,
}: {
  name: string;
  label: string;
  required?: boolean;
  hasRecurlyError: boolean;
}) => (
  <DSField
    for={getFieldId(name)}
    label={label}
    required={required}
    error={hasRecurlyError}
  >
    <StyledField
      id={getFieldId(name)}
      data-recurly={name}
      hasError={hasRecurlyError}
    />
  </DSField>
);

const StyledField = styled.div<{ hasError?: boolean }>`
  & .recurly-hosted-field {
    width: 100%;
    height: 2.5714285714285716rem;
    padding: 0 12px;
    font-size: 1rem;
    font-weight: 400;
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.purple[100]};
    border: 1px solid ${props => props.theme.colors.purple[40]};
    border-radius: 8px;
    -webkit-appearance: none;
    transition: background 0.1s, box-shadow 0.1s, border 0.1s;
  }

  & .recurly-hosted-field:hover {
    background: ${props => props.theme.hover.onWhite};
  }

  & .recurly-hosted-field:focus {
    outline: none;
    box-shadow: 0 0 0 4px ${props => props.theme.colors.purple[10]};
  }

  & .recurly-hosted-field::placeholder {
    color: ${props => props.theme.colors.purple[60]};
  }

  & .recurly-hosted-field-focus {
    outline: none;
    box-shadow: 0 0 0 4px ${props => props.theme.colors.purple[10]};
  }

  ${props =>
    props.hasError &&
    css`
      & .recurly-hosted-field,
      & .recurly-hosted-field-focus {
        border-color: ${props.theme.colors.red[100]};
      }
    `}
`;
